import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Styles/filterpages.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Fakedata from "./FakeData/FakeData";
import { Tooltip } from "antd";
import { Button, Modal } from "antd";
import ReactPaginate from "react-paginate";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useLocation } from "react-router-dom";


function Products() {
  function valuetext(value) {
    return `${value}°C`;
  }


  const [value, setValue] = React.useState([0, 10000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const location = useLocation();

  const [products, setproducts] = useState([]);
  const [newproducts, setnewproducts] = useState([]);
  const getproduct = async () => {
    try {
      let response = await axios.get(
        "https://spilso.in/api/admin/letestProduct"
      );

      if (response.status == 200) {
        setproducts(response.data.data1);
        setnewproducts(response.data.data1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [search1, setsearch1] = useState("");
  const [am, setAm] = useState(false);
  const getProductBySearch = async () => {
    try {
      if (am == false) {
        let response = await axios.get(
          "https://spilso.in/api/admin/getProductBySearch/" + search1
        );

        if (response.status == 200) {
          setAm(true);
          setproducts(response.data.success);
          setnewproducts(response.data.success);

          console.log("Am", am);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (location?.state?.search) {
    if (am == false) {
      getProductBySearch();
    }
  }
  console.log("abc", am);

  const newdata = products
    ?.filter((ele) => ele?.remainingstock > ele?.minimumStock)
    ?.filter(
      (items) =>
        Math.round(
          Number(items?.productprice) +
            Math.round(items?.productprice * (items?.tax / 100)) -
            (Number(items?.productprice) +
              Math.round(items?.productprice * (items?.tax / 100))) *
              (items.customerdiscountpercentage / 100)
        ) >= value[0] &&
        Math.round(
          Number(items?.productprice) +
            Math.round(items?.productprice * (items?.tax / 100)) -
            (Number(items?.productprice) +
              Math.round(items?.productprice * (items?.tax / 100))) *
              (items.customerdiscountpercentage / 100)
        ) <= value[1]
    );

  //   const displayPage = newdata.slice(visitedPage, visitedPage + productPerPage);
  // console.log("display",displayPage);
  //   const pageCount = Math.ceil(newdata.length / productPerPage);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //   Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 12;
  const visitedPage = pageNumber * productPerPage;

  let displayPage = newdata?.slice(visitedPage, visitedPage + productPerPage);
  const displayPage1 = newdata?.slice(
    visitedPage,
    visitedPage + productPerPage
  );

  const pageCount = Math.ceil(newdata.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [categories, setCotegory] = useState([]);
  const getCatory = async () => {
    try {
      const config = {
        url: "/getcategory",
        method: "get",
        baseURL: "https://spilso.in/api/",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setCotegory(res.data.category);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const user = JSON.parse(sessionStorage.getItem("customer"));
  const [showMore, setShowMore] = useState(false);
  const [viewData, setViewdeata] = useState({});
  const [selPrice, setslePrice] = useState(0);
  const [selQuntity, setselQuntity] = useState(0);
  console.log("seleted price==>", selQuntity, selPrice);
  const [image, setImage] = useState("");
  const [quantity, setQuntity] = useState(1);
  const incQuntity = () => {
    setQuntity(quantity + 1);
  };
  const decQuntity = () => {
    if (quantity > 1) {
      setQuntity(quantity - 1);
    }
  };
  const removewishlist = async (item) => {
    if (!user) {
      alert("Need to Login");
    } else {
      axios({
        method: "delete",
        url:
          "https://spilso.in/api/removeWishlistbcustomeryId/" +
          user.id +
          "/" +
          item?._id,
      })
        .then(function (response) {
          //handle success
          console.log(response);
          alert("Product removed from wishlist");
          getwislist();
          window.location.reload();
        })
        .catch(function (error) {
          //handle error
          console.log(error.response.data);
        });
    }
  };
  const addwishlist = async (item) => {
    if (user) {
      try {
        const config = {
          url: "/addWhishList",
          method: "post",
          baseURL: "https://spilso.in/api",
          data: {
            productId: item._id,
            customerId: user.id,
            quantity: quantity,
            price: selPrice ? selPrice : Number(item?.productprice),
            Size: selQuntity
              ? selQuntity + item?.productvolumetype
              : "1" + item?.productvolumetype,
            totalPrice: selPrice
              ? Math.round(
                  (Number(selPrice) +
                    Math.round(selPrice * (item?.tax / 100)) -
                    (Number(selPrice) +
                      Math.round(selPrice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                )
              : Math.round(
                  (Number(item?.productprice) +
                    Math.round(item?.productprice * (item?.tax / 100)) -
                    (Number(item?.productprice) +
                      Math.round(item?.productprice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                ),
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            console.log("success");
            alert("Added to wishlist");
            window.location.reload();
          }
        });
      } catch (error) {
        console.log(error);
        alert("product not added");
      }
    } else {
      alert("Need to Login");
    }
  };
  const addCart = async (item) => {
    if (user) {
      try {
        const config = {
          url: "/addToCartCustomer",
          method: "post",
          baseURL: "https://spilso.in/api",
          data: {
            productId: item._id,
            customerId: user.id,
            quantity: quantity,
            price: selPrice ? selPrice : Number(item?.productprice),
            Size: selQuntity
              ? selQuntity + item?.productvolumetype
              : "1" + item?.productvolumetype,
            totalPrice: selPrice
              ? Math.round(
                  (Number(selPrice) +
                    Math.round(selPrice * (item?.tax / 100)) -
                    (Number(selPrice) +
                      Math.round(selPrice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                )
              : Math.round(
                  (Number(item?.productprice) +
                    Math.round(item?.productprice * (item?.tax / 100)) -
                    (Number(item?.productprice) +
                      Math.round(item?.productprice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                ),
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            console.log("success");
            alert("Added to Cart");
            window.location.reload();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    } else {
      alert("Need to Login");
    }
  };

  const [wishlist, setwishlist] = useState([]);
  const getwislist = () => {
    axios
      .get("https://spilso.in/api/getWishlistByCustomerId/" + user?.id)
      .then(function (response) {
        console.log(response.data);
        setwishlist(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [Carts, setCarts] = useState([]);

  const getCatrDeatils = () => {
    axios
      .get("https://spilso.in/api/getAllCartProductsByCustomer/" + user?.id)
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const removewCart = async (item) => {
    if (!user) {
      alert("Need to Login");
    } else {
      try {
        const config = {
          url: "/removeInCartOfCustomer",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            productId: item?._id,
            customerId: user?.id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Product removed from wishlist");
          window.location.reload();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };
  const [ourbrand, setourbrand] = useState([]);
  const [selectbrand, setselectBrand] = useState("");
  useEffect(() => {
    if (location?.state?.brand) {
      setselectBrand(location?.state?.brand);
    }
    if (location?.state?.search) {
      setsearch1(location?.state?.search);
    }
  }, []);
  const getAllBrand = async () => {
    try {
      let res = await axios.get("https://spilso.in/api/getAllBrand");
      if (res.status == 200) {
        setourbrand(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [SelectedCategory, setSelectedCategory] = useState("");
  // const [selectOurBrand, setselectOurBrand] = useState("");
  const [FilterAtoZ, setFilterAtoZ] = useState();
  useEffect(() => {
    if (FilterAtoZ == "AtoZ") {
      const AtoZ = newdata.sort(function (a, b) {
        const nameA = a.productname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.productname.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      setproducts(AtoZ);
    } else if (FilterAtoZ == "ZtoA") {
      const AtoZ = newdata.sort(function (a, b) {
        const nameA = a.productname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.productname.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }

        return 0;
      });

      setproducts(AtoZ);
    } else if (FilterAtoZ == "hightolow") {
      const Hightolow = newdata.sort((p1, p2) =>
        p1.productprice < p2.productprice
          ? 1
          : p1.productprice > p2.productprice
          ? -1
          : 0
      );
      if (Hightolow.length > 0) {
        setproducts(Hightolow);
      }
    } else if (FilterAtoZ == "lowtohigh") {
      const Hightolow = newdata.sort((p1, p2) =>
        p1.productprice > p2.productprice
          ? 1
          : p1.productprice < p2.productprice
          ? -1
          : 0
      );
      if (Hightolow.length > 0) {
        setproducts(Hightolow);
      }
    }
  }, [FilterAtoZ]);

  const fillter = async (brand) => {
    if (!brand) {
      setproducts(newproducts);
    } else {
      setproducts(newproducts.filter((ele) => ele?.productbrand == brand));
    }
  };

  console.log("location===>", location, selectbrand);

  useEffect(() => {
    getCatory();

    getproduct();
    getAllBrand();

    if (location?.state?.category) {
      setSelectedCategory(location?.state?.category);
    }
    if (user) {
      getCatrDeatils();
      getwislist();
    }
  }, []);

  

  return (
    <>
      <div className="filter">
        <Container>
          <div className="filter_he">
            <Row>
              <Col lg={3} md={3}>
                <div className="hero_product_filter">
                  <div className="pages-filter pb-2">
                   
                    <h5 className="fill_h">Filter</h5>
                    <div className="filter_pro">
                      Reset
                      <span className="px-2">
                        <i
                          class="fa fa-refresh"
                          aria-hidden="true"
                          onClick={() => window.location.reload()}
                        ></i>
                      </span>
                    </div>
                  </div>
                  {/* Category Filter */}
                  <div className="pages-filter pt-2 ">
                    <h5 className="fill_h">Category</h5>
                  </div>
                  <div className="product-count pt-2 pb-3">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      {location?.state?.category ? (
                        <option value={SelectedCategory}>
                          {SelectedCategory}
                        </option>
                      ) : (
                        <></>
                      )}
                      <option value="">All Category</option>

                      {categories?.map((item) => {
                        return (
                          <option value={item?.categoryName}>
                            {item?.categoryName}
                          </option>
                        );
                      })}

                      {/* <option value="2">Two</option>
                      <option value="3">Three</option> */}
                    </Form.Select>
                  </div>
                  {/* Price Filter */}
                  <div className="pages-filter pt-2 ">
                    <h5 className="fill_h">Price</h5>
                  </div>
                  <div className="product-count pt-2 pb-3">
                    <Box sx={{ width: 270 }}>
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        min={0}
                        max={10000}
                      />
                    </Box>
                  </div>
                  {/* Brand Filter */}
                  <div className="pages-filter pt-2 ">
                    <h5 className="fill_h">Brands</h5>
                  </div>
                  <div className="product-count pt-2 pb-3">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => {
                        setselectBrand(e.target.value);
                      }}
                    >
                      {location?.state?.brand ? (
                        <option value={selectbrand}>{selectbrand}</option>
                      ) : (
                        <></>
                      )}
                      <option value="">All Brands</option>
                      {ourbrand?.map((items) => {
                        return (
                          <option value={items?.BrandName}>
                            {items?.BrandName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  {/* Offers */}
                  <div className="pages-filter pt-2 pb-2">
                    <h5 className="fill_h">Offers</h5>
                  </div>

                  <div className="product-count">
                    <ul className="multiple-filters scrollbar">
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Combo</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="Free"
                          />
                          <label>Free</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="Buy 1 Get 1"
                          />
                          <label>Buy 1 Get 1</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Deals of the day</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                    </ul>
                  </div>
                  <div className="pages-filter pt-2 pb-2">
                    <h5 className="fill_h">Discount</h5>
                    <div className="filter_pro">
                      <span className="px-2">(0)</span> Select
                    </div>
                  </div>

                  <div className="product-count">
                    <ul className="multiple-filters scrollbar">
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Up to 10%</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Up to 20%</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Up to 30%</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Up to 40%</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Up to 50%</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Above 50%</label>
                        </div>
                        <div className="count-check">(0)</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9}>
                <div className="banana-filter">
                  <div className="filter_search">
                    <div className="search-filter mb-2">
                      <label for="SortBy">Sort by:</label>
                      <select
                        className="select-sort"
                        data-option="option1"
                        id="product-select-qv-option-0"
                        onChange={(e) => setFilterAtoZ(e.target.value)}
                      >
                        <option value="AtoZ">Alphabetically, A-Z</option>
                        <option value="ZtoA">Alphabetically, Z-A</option>
                        <option value="lowtohigh">Price, low to high</option>
                        <option value="hightolow">Price, high to low</option>
                      </select>
                    </div>
                    <div className="sidebar-search-input">
                      <div
                        className="search-bar site-header__search"
                        role="search"
                        style={{ position: "relative" }}
                      >
                        <div className="form-search">
                          <input
                            type="search"
                            name="q"
                            value={search1}
                            placeholder="Search our store"
                            id="search"
                            onChange={(e) => setsearch1(e.target.value)}
                            className="input-text"
                            autocomplete="off"
                          />
                          <button
                            className="search-btn"
                            type="submit"
                            onClick={() => {
                              setAm(false);
                              getProductBySearch();
                            }}
                          >
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="products mt-3">
                    <Row>
                      {SelectedCategory ? (
                        <>
                          {" "}
                          {displayPage
                            ?.filter((ele) =>
                              selectbrand
                                ? ele?.productbrand == selectbrand
                                : ele
                            )
                            ?.filter((data, index) =>
                              SelectedCategory
                                ? data.productcategory == SelectedCategory
                                : index
                            )
                            ?.map((items, id) => {
                              return (
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6"  key={id}>
                                  <div className="single-product-wrap mb-3">
                                    <div className="product-image">
                                      <a href="#" className="pro-img">
                                        <img
                                          className="img-fluid img1"
                                          src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                                          alt=""
                                          loading="lazy"
                                          style={{
                                            width: "100%",
                                            height: "157px",
                                          }}
                                        />
                                      </a>
                                      <div className="product-label">
                                        <span className="sale-title">
                                          {items?.customerdiscountpercentage}%
                                        </span>
                                        <div className="product-action">
                                          {/* <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                                    <Tooltip title="WISHLIST">
                                      {" "}
                                      <span
                                        class="add-wishlist"
                                        id="app-title"
                                      >
                                        <i class="fa fa-heart"></i>
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="ADD TO CART">
                                      {" "}
                                      <span class="add-wishlist">
                                        <i class="fa fa-shopping-bag"></i>
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="QUICKVIEW">
                                      {" "}
                                      <span
                                        class="add-wishlist"
                                        onClick={showModal}
                                      >
                                        <i class="fa fa-eye"></i>
                                      </span>
                                    </Tooltip>
                                  </div> */}
                                          <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                                            {wishlist?.filter(
                                              (item) =>
                                                item?.productId?._id ===
                                                items._id
                                            )?.length ? (
                                              <Tooltip
                                                title="REMOVE-WISHLIST"
                                               
                                              >
                                                <span><span
                                                  class="add-wishlist"
                                                  id="app-title"
                                                  onClick={() =>
                                                    removewishlist(items)
                                                  }
                                                >
                                                  <i
                                                    class="fa fa-heart"
                                                    style={{ color: "Green" }}
                                                  ></i>
                                                </span></span>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="WISHLIST"
                                               
                                              >
                                               <span>
                                               <span
                                                  class="add-wishlist"
                                                  id="app-title"
                                                  onClick={() =>
                                                    addwishlist(items)
                                                  }
                                                >
                                                  <i class="fa fa-heart"></i>
                                                </span>
                                               </span>
                                              </Tooltip>
                                            )}
                                            {Carts?.filter(
                                              (item) =>
                                                item?.productId?._id ===
                                                items._id
                                            )?.length ? (
                                              <Tooltip
                                                title="REMOVE TO CART"
                                                
                                              >
                                                <span>
                                                <span class="add-wishlist" onClick={() =>
                                                  removewCart(items)
                                                }>
                                                  <i
                                                    class="fa fa-shopping-bag"
                                                    style={{ color: "red" }}
                                                  ></i>
                                                </span>
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="ADD TO CART"
                                               
                                              >
                                                <span>
                                                <span class="add-wishlist">
                                                  <i class="fa fa-shopping-bag"  onClick={() => addCart(items)}></i>
                                                </span>
                                                </span>
                                              </Tooltip>
                                            )}
                                            <Tooltip title="QUICKVIEW">
                                              <span>
                                              <span
                                                class="add-wishlist"
                                                onClick={() => {
                                                  showModal();
                                                  setViewdeata(items);
                                                  setQuntity(1);
                                                  setslePrice(0);
                                                  setselQuntity(0);
                                                  setImage(
                                                    items?.productimage[0]
                                                  );
                                                }}
                                              >
                                                <i class="fa fa-eye"></i>
                                              </span>
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="product-content mt-4">
                                      <div className="product-title mb-1">
                                        <a href={`/single-pages/${items?._id}`}>
                                          {items?.productname}
                                        </a>
                                      </div>
                                      {/* <div className="product-ratting mb-1">
                                <span
                                  className="spr-badge"
                                  id="spr_badge_6820894441621"
                                  data-rating="2.0"
                                >
                                  <span class="spr-starrating spr-badge-starrating">
                                    <i
                                      class="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                              </div> */}
                                      <div className="price-box mb-2">
                                        <span
                                          className="new-price"
                                          id="ProductPrice"
                                        >
                                          ₹
                                          {Math.round(
                                            Number(items?.productprice) +
                                              Math.round(
                                                items?.productprice *
                                                  (items?.tax / 100)
                                              ) -
                                              (Number(items?.productprice) +
                                                Math.round(
                                                  items?.productprice *
                                                    (items?.tax / 100)
                                                )) *
                                                (items.customerdiscountpercentage /
                                                  100)
                                          )}
                                        </span>
                                        <span
                                          className="old-price"
                                          id="ComparePrice"
                                        >
                                          ₹
                                          {Number(items?.productprice) +
                                            Math.round(
                                              items?.productprice *
                                                (items?.tax / 100)
                                            )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {" "}
                          {displayPage
                            ?.filter((ele) =>
                              selectbrand
                                ? ele?.productbrand == selectbrand
                                : ele
                            )
                            ?.map((items, id) => {
                              return (
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6" key={id}>
                                  <div className="single-product-wrap mb-3">
                                    <div className="product-image">
                                      <a href="#" className="pro-img">
                                        <img
                                          className="img-fluid img1"
                                          src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                                          alt=""
                                          loading="lazy"
                                          style={{
                                            width: "100%",
                                            height: "157px",
                                          }}
                                        />
                                      </a>
                                      <div className="product-label">
                                        <span className="sale-title">
                                          {items?.customerdiscountpercentage}%
                                        </span>
                                        <div className="product-action">
                                          {/* <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                                      <Tooltip title="WISHLIST">
                                        {" "}
                                        <span
                                          class="add-wishlist"
                                          id="app-title"
                                        >
                                          <i class="fa fa-heart"></i>
                                        </span>
                                      </Tooltip>
                                      <Tooltip title="ADD TO CART">
                                        {" "}
                                        <span class="add-wishlist">
                                          <i class="fa fa-shopping-bag"></i>
                                        </span>
                                      </Tooltip>
                                      <Tooltip title="QUICKVIEW">
                                        {" "}
                                        <span
                                          class="add-wishlist"
                                          onClick={showModal}
                                        >
                                          <i class="fa fa-eye"></i>
                                        </span>
                                      </Tooltip>
                                    </div> */}
                                          <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                                            {wishlist?.filter(
                                              (item) =>
                                                item?.productId?._id ===
                                                items._id
                                            )?.length ? (
                                              <Tooltip
                                                title="REMOVE-WISHLIST"
                                               
                                              >
                                               <span>
                                               <span
                                                  class="add-wishlist"
                                                  id="app-title"
                                                  onClick={() =>
                                                    removewishlist(items)
                                                  }
                                                >
                                                  <i
                                                    class="fa fa-heart"
                                                    style={{ color: "Green" }}
                                                  ></i>
                                                </span>
                                               </span>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="WISHLIST"
                                               
                                              >
                                                <span>
                                                <span
                                                  class="add-wishlist"
                                                  id="app-title"
                                                  onClick={() =>
                                                    addwishlist(items)
                                                  }
                                                >
                                                  <i class="fa fa-heart"></i>
                                                </span>
                                                </span>
                                              </Tooltip>
                                            )}
                                            {Carts?.filter(
                                              (item) =>
                                                item?.productId?._id ===
                                                items._id
                                            )?.length ? (
                                              <Tooltip
                                                title="REMOVE TO CART"
                                                
                                              >
                                              <span>
                                              <span class="add-wishlist" onClick={() =>
                                                  removewCart(items)
                                                }>
                                                  <i
                                                    class="fa fa-shopping-bag"
                                                    style={{ color: "red" }}
                                                  ></i>
                                                </span>
                                              </span>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="ADD TO CART"
                                             
                                              >
                                                <span>
                                                <span class="add-wishlist"    onClick={() => addCart(items)}>
                                                  <i class="fa fa-shopping-bag"></i>
                                                </span>
                                                </span>
                                              </Tooltip>
                                            )}
                                            <Tooltip title="QUICKVIEW">
                                             <span>
                                             <span
                                                class="add-wishlist"
                                                onClick={() => {
                                                  showModal();
                                                  setViewdeata(items);
                                                  setQuntity(1);
                                                  setslePrice(0);
                                                  setselQuntity(0);
                                                  setImage(
                                                    items?.productimage[0]
                                                  );
                                                }}
                                              >
                                                <i class="fa fa-eye"></i>
                                              </span>
                                             </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="product-content mt-4">
                                      <div className="product-title mb-1">
                                        <a href={`/single-pages/${items?._id}`}>
                                          {items?.productname}
                                        </a>
                                      </div>
                                      {/* <div className="product-ratting mb-1">
                                  <span
                                    className="spr-badge"
                                    id="spr_badge_6820894441621"
                                    data-rating="2.0"
                                  >
                                    <span class="spr-starrating spr-badge-starrating">
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </span>
                                </div> */}
                                      <div className="price-box mb-2">
                                        <span
                                          className="new-price"
                                          id="ProductPrice"
                                        >
                                          ₹
                                          {Math.round(
                                            Number(items?.productprice) +
                                              Math.round(
                                                items?.productprice *
                                                  (items?.tax / 100)
                                              ) -
                                              (Number(items?.productprice) +
                                                Math.round(
                                                  items?.productprice *
                                                    (items?.tax / 100)
                                                )) *
                                                (items.customerdiscountpercentage /
                                                  100)
                                          )}
                                        </span>
                                        <span
                                          className="old-price"
                                          id="ComparePrice"
                                        >
                                          ₹
                                          {Number(items?.productprice) +
                                            Math.round(
                                              items?.productprice *
                                                (items?.tax / 100)
                                            )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      )}
                    </Row>
                  </div>
                  {/* Pagination */}

                  <div>
                    <ReactPaginate
                      pageCount={pageCount}
                      onPageChange={changePage}
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      containerClassName=" paginationBttns "
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        {/* Modal */}
        <div className="qui_hero">
          <Modal
            title="Quickview"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="pro-quick">
              <div className="quick-view">
                <div className="quick-view_hero">
                  <a href={`/single-pages/${viewData?._id}`}>
                    <img
                      src={`https://spilso.in/admin/product/${image}`}
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="quick-view">
                <div className="hero_quick">
                  <div className="product-content ">
                    <div className="product-title mb-1">
                      <a href={`/single-pages/${viewData?._id}`}>
                        {viewData?.productname}
                      </a>
                    </div>

                    <div className="price-box mb-1">
                      {selPrice !== 0 ? (
                        <span className="new-price" id="ProductPrice">
                          {selQuntity}
                          {viewData?.productvolumetype}
                          {"-"}₹
                          {Math.round(
                            (Number(selPrice) +
                              Math.round(selPrice * (viewData?.tax / 100)) -
                              (Number(selPrice) +
                                Math.round(selPrice * (viewData?.tax / 100))) *
                                (viewData.customerdiscountpercentage / 100)) *
                              quantity
                          )}
                        </span>
                      ) : (
                        <span className="new-price" id="ProductPrice">
                          ₹
                          {Math.round(
                            (Number(viewData?.productprice) +
                              Math.round(
                                viewData?.productprice * (viewData?.tax / 100)
                              ) -
                              (Number(viewData?.productprice) +
                                Math.round(
                                  viewData?.productprice * (viewData?.tax / 100)
                                )) *
                                (viewData.customerdiscountpercentage / 100)) *
                              quantity
                          )}
                        </span>
                      )}
                      {selPrice !== 0 ? (
                        <span className="old-price" id="ComparePrice">
                          ₹
                          {Number(selPrice * quantity) +
                            Math.round(
                              selPrice * quantity * (viewData?.tax / 100)
                            )}
                        </span>
                      ) : (
                        <span className="old-price" id="ComparePrice">
                          ₹
                          {Number(viewData?.productprice * quantity) +
                            Math.round(
                              viewData?.productprice *
                                quantity *
                                (viewData?.tax / 100)
                            )}
                        </span>
                      )}
                    </div>
                    <div className="more-de mb-1">
                      <p className="simple-0">More Details</p>
                      <p className="lorem-09">
                        {showMore
                          ? viewData?.productdesc
                          : `${viewData?.productdesc?.substring(0, 110)}`}
                        <button
                          className="btn"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? "Show less" : "Show more"}
                        </button>
                      </p>
                    </div>
                    <div className="quick-view-select mb-2">
                      <div className="selector-wrapper">
                        <label>Size :</label>
                        <select
                          class="single-option-selector"
                          data-option="option1"
                          id="product-select-qv-option-0"
                          onChange={(e) => {
                            setslePrice(
                              JSON.parse(e.target.value)?.quntityPrice
                            );
                            setselQuntity(
                              JSON.parse(e.target.value)?.perQuntity
                            );
                          }}
                        >
                          {viewData?.AllPrice?.map((ele) => {
                            return (
                              <option value={JSON.stringify(ele)}>
                                {ele?.perQuntity}
                                {viewData?.productvolumetype}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="quick-view-select mb-2">
                      <div className="selector-wrapper">
                        <label>Discount :</label>
                        <span>{viewData?.customerdiscountpercentage}%</span>
                      </div>
                    </div>

                    <div className="cart-plus-minus">
                      <div className="dec qtybutton" onClick={decQuntity}>
                        -
                      </div>
                      <input
                        value={quantity}
                        type="text"
                        name="quantity"
                        min="1"
                        pattern="[0-9]*"
                      />
                      <div className="inc qtybutton" onClick={incQuntity}>
                        +
                      </div>
                    </div>
                    <br />
                    <div className="product-action-2">
                      <div className="action-wishlist">
                        {wishlist.filter(
                          (item) => item?.productId?._id === viewData._id
                        )?.length ? (
                          <Tooltip
                            title="REMOVE-WISHLIST"
                            onClick={() => removewishlist(viewData)}
                          >
                            {" "}
                            <span
                              id="app-title"
                              style={{
                                opacity: "1",
                                background: "red",
                                color: "white",
                                fontSize: "16px",
                                lineHeight: 0,
                                height: "40px",
                                width: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                marginRight: "15px",
                              }}
                            >
                              <i class="fa fa-heart"></i>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="WISHLIST"
                            onClick={() => addwishlist(viewData)}
                          >
                            {" "}
                            <span
                              class="add-wishlist"
                              id="app-title"
                              style={{ opacity: "1" }}
                            >
                              <i class="fa fa-heart"></i>
                            </span>
                          </Tooltip>
                        )}
                        {Carts?.filter(
                          (item) => item?.productId?._id === viewData._id
                        )?.length ? (
                          <Tooltip
                            title="REMOVE TO CART"
                            onClick={() => removewCart(viewData)}
                          >
                            {" "}
                            <span
                              class="add-wishlist"
                              style={{
                                opacity: "1",
                                background: "red",
                                color: "white",
                                fontSize: "16px",
                                lineHeight: 0,
                                height: "40px",
                                width: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                marginRight: "15px",
                              }}
                            >
                              <i class="fa fa-shopping-bag"></i>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="ADD TO CART"
                            onClick={() => addCart(viewData)}
                          >
                            {" "}
                            <span class="add-wishlist" style={{ opacity: "1" }}>
                              <i class="fa fa-shopping-bag"></i>
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Products;
