import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import moment from "moment";

function Adminaccountstatement() {
  const [data, setdata] = useState([]);

  const [data1, setdata1] = useState([]);
  const [usertype, setusertype] = useState("");
  const [username, setusername] = useState("");
  const [allexpense, setallexpense] = useState([]);
  const [category, setcategory] = useState("");

  const [endDate, setendDate] = useState(new Date());
  const [startDate, setstartDate] = useState(new Date());
  const apiURL = process.env.REACT_APP_API_URL;
  // enableRipple(true);
  useEffect(() => {
    gettransaction();
    if (usertype == "customer") {
      getcustomer();
    }
    if (usertype == "retailer") {
      getretailer();
    }
    if (usertype == "distributor") {
      getdistributor();
    }
  }, [usertype, category]);

  const gettransaction = async () => {
    let res = await axios.get(
      apiURL + "/admin/customerstatement/611e141c89f3e628c00cebb5"
    );
    if ((res.status = 200)) {
      console.log(res.data);
      setdata1(res.data);
    }
  };

  const getcustomer = async () => {
    let res = await axios.get(apiURL + "/customer/getallcustomer");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(
        res.data?.customer.filter((item) => item.profilestatus === true)
      );
    }
  };

  const getdistributor = async () => {
    let res = await axios.get(apiURL + "/distributor/notapproved");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(
        res.data?.distributor.filter((item) => item.profilestatus === true)
      );
    }
  };

  const getretailer = async () => {
    let res = await axios.get(apiURL + "/retailer/notapproved");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(
        res.data?.retailer.filter((item) => item.profilestatus === true)
      );
    }
  };

  //excel
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "_id",
      text: "ID",
    },
    {
      dataField: "total",
      text: "Debit",
    },
    {
      dataField: "",
      text: "Credit",
    },
  ];

  function getDateObject(datevalue) {
    let d = new Date(datevalue);

    let v = d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear();
    return v;
  }

  const search = () => {
    const filteredData = data1?.statement?.filter(
      (data) =>
        new Date(
          moment(moment(data.orderdatetime, "DD/MM/YYYY hh:mm A")).format(
            "YYYY/MM/DD"
          )
        ) >= new Date(startDate?.split("/").reverse().join("/")) &&
        new Date(
          moment(moment(data.orderdatetime, "DD/MM/YYYY hh:mm A")).format(
            "YYYY/MM/DD"
          )
        ) <= new Date(endDate?.split("/").reverse().join("/"))
    );
    setdata(filteredData);
  };

  console.log(data);
  return (
    <div>
      <div className="">
        <div className="row">
          <div className="col-md-2 mt-2">
            <h7>Date From</h7>
            <DatePicker
              style={{ height: "35px", width: "140px", marginRight: "-5px" }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="input-icon"
              onChange={(date) => setstartDate(date.format("DD/MM/YYYY"))}
            />
          </div>
          <div className="col-md-2 mt-2">
            <h7>Date To</h7>
            <DatePicker
              style={{ height: "35px", width: "140px", marginRight: "-5px" }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="input-icon"
              onChange={(date) => setendDate(date.format("DD/MM/YYYY"))}
            />
          </div>

          <div className="col-md-2">
            <label className="mt-2">User Type</label>
            <select
              className="form-control"
              onChange={(e) => setusertype(e.target.value)}
            >
              <option value="">Select User Type</option>
              <option value="distributor">Distributor</option>
              <option value="retailer">Retailer</option>
              <option value="customer">Customer</option>
              <option value="transporter">Transporter</option>
            </select>
          </div>
          <div className="col-md-2">
            <label className="mt-2">User Name</label>
            <select
              className="form-control"
              onChange={(e) => setcategory(e.target.value)}
            >
              <option>Select the user name</option>
              {usertype == "distributor" || usertype == "retailer"
                ? allexpense?.map((data) => (
                    <option value={data._id}>{data.firmname}</option>
                  ))
                : ""}
              {usertype == "customer"
                ? allexpense?.map((data) => (
                    <option value={data._id}>{data.name}</option>
                  ))
                : ""}
            </select>
          </div>
          <div className="col-md-2 mt-2">
            <button
              className="btn btn-primary"
              style={{ marginTop: "20px" }}
              onClick={search}
            >
              Submit
            </button>
          </div>
          <div className="col-md-2 mt-3"></div>
        </div>
      </div>
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        exportCSV={{
          fileName: "Accountstatement.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            <span className="pr-5 mr-auto">
              <ExportCSVButton
                className="btn-success excel-btn"
                {...props.csvProps}
              >
                Download Excels
              </ExportCSVButton>
            </span>

            <div className="table-responsive">
              <BootstrapTable {...props.baseProps} />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}

export default Adminaccountstatement;
