import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

function Adminemployeeorders() {
    const [data, setdata] = useState([]);
    const { SearchBar, ClearSearchButton } = Search;
    const { ExportCSVButton } = CSVExport;
    const apiURL = process.env.REACT_APP_API_URL;
    const imageURL = process.env.REACT_APP_IMAGE_API_URL;

    useEffect(() => {
        getemployee();                       
    }, []);    
    
    const getemployee = () =>{
        try{
            axios.get(`${apiURL}/employee/order`)
                        .then(function(response){
                           if (response.status === 200) { 
                             console.log(response.data);
                            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata)); 
                             setdata(response.data.orders);                                  
                             return 
                       
                     }else{
                       
                       alert("Can't able to fetch ");
                       //setdatacondition(true);
                       return
                     }
                   }).catch(function(error){
                     // setdatacondition(true);
                     console.log(error.message.data);
                     alert("Can't able to fetch ");
                     return
                   });
                 }catch (error) {
                    
                   console.warn(error);
        alert("Can't able  to fetch ");
       // setdatacondition(true);
        return error;
       };
    }

    // const block = async(data) =>{
    //     try{
    //         axios.post(`${apiURL}/blockemployee/`+data._id)
    //                      .then(function(response){
    //                         if (response.status === 200) { 
    //                           console.log(response.data);
    //                           window.location.reload();                
    //                   }
    //                 }).catch(function(error){   
    //                   // setdatacondition(true);
    //                   console.log(error.message.data);
    //                   alert("Not able to complete");
                      
    //                 });
    //               }catch (error) {
                     
    //                 console.warn(error);
    //      alert("Not able to complete");
    //     // setdatacondition(true);
    //      return error;
    //     };
    // }

    // const unblock = async(data) =>{
    //     try{
    //         axios.post(`${apiURL}/unblockemployee/`+data._id)
    //                      .then(function(response){
    //                         if (response.status === 200) { 
    //                           console.log(response.data);
    //                           window.location.reload();                
    //                   }
    //                 }).catch(function(error){   
    //                   // setdatacondition(true);
    //                   console.log(error.message.data);
    //                   alert("Not able to complete");
                      
    //                 });
    //               }catch (error) {
                     
    //                 console.warn(error);
    //      alert("Not able to complete");
    //     // setdatacondition(true);
    //      return error;
    //     };
    // }


    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from } to { to } of { size } Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: data.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };

    function imageFormatter(cell, row){
      return (<img src={`${imageURL}/employees/profile/${cell}`}  height="50px" width="50px"  style={{borderRadius:"100%"}}/>) ;
    }

   

  

  const columns = [{
      dataField: '_id',
      text: 'Order Id',
      sort : true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },  
    {
      dataField: 'paymentmethod',
      text: 'Transaction Id',
      sort : true
    }, {  
      dataField: 'paymentmethod',
      text: 'Payment Method',
      
    },
    {  
      dataField: 'total',
      text: 'Paid Amount',
      
    },
    {  
      dataField: 'orderdatetime',
      text: 'Placed On',
      
    },
    
    {  
      dataField: 'address[0].doorno',
      text: 'Address',
      formatter: (cell, row) => {
        console.log(row);
        return <div>{row?.addresstype == "apartment"? row?.apartmentname: ""}, {row?.doorno}, {row?.addressline}, {row?.area},{row?.city}, {row?.state}, {row?.country}, {row?.pincode}</div>;
      }     
    },      
    {  
      dataField: '',
      text: 'Status',
      formatter: (cell, row) => {
        console.log(row);
        return <div><button className="btn btn-info" >View</button></div>;
      }     
    },
    
  ];

    return (
        <div style={{background:""}}>
        <div className="container pb-5">
            <h1 className="pt-4 pb-3" style={{color:"#28328c"}}>employee</h1>
            <div className="container">
                <div className="card card-body">
                      {/* <div>
                        <h5 className="pb-3">Latest Orders</h5>
                         <div className="container text-center">
                         <Table responsive hover size="md">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Profile Picture</th>
                                <th>Email Id</th>
                                <th>Mobile No</th>
                                <th>Gender</th>   
                                <th>Address</th>
                                <th>Medical Details</th> 
                                <th>Status</th>                               
                                <th>Action</th>                       
                            </tr>
                            </thead>
                            <tbody>
                                {data?.map((datas)=>(
                                  <tr>
                                  <td>{datas.name}</td>
                                  <td><img src={imageURL +"/employees/profile/" + datas.profileimage} height="50px" width="50px" style={{borderRadius:"100%"}}/></td> 
                                  <td>{datas.email}</td>
                                  <td>{datas.phoneNumber}</td>  
                                  <td>{datas.gender}</td>
                                  <td>{datas?.doorno}, {datas?.address[0]?.addressline}, {datas?.address[0]?.area},{datas?.address[0]?.city}, {datas?.address[0]?.state}, {datas?.address[0]?.country}, {datas?.address[0]?.pincode}</td>
                                  <td>{datas.allergies}</td>
                                  <td>{datas?.details[0]?.status}</td>
                                  <td>{!datas?.details[0]?.blockstatus? <button className="btn btn-outline-success" onClick={()=>block(datas)}>Block</button>:<button className="btn btn-outline-danger" onClick={()=>unblock(datas)}>Un-Block</button>}</td>
                                  <td>{datas.specializations}</td>   
                                  <td>{datas.qualification}</td> 
                                  <td>{datas.experience}</td>  
                                  <td>{datas.idprooftype}</td>   
                                  <td><img src={imageURL +"/doctor/idproof/" + datas.idproof} height="50px" width="50px" style={{borderRadius:"100%"}}/></td>                                                   
                                  <td><div className="d-flex"> <button className="btn btn-outline-success" onClick={()=>approve(datas)}>Approve</button></div></td>
                              </tr>  
                                ))}
                               
                            </tbody>
                          </Table>
                        </div>
                    </div> */}

                    <ToolkitProvider
        keyField="id"
        data={ data }
        columns={ columns }
        search
        exportCSV = { {
            fileName: 'employee.csv',            
            blobType: 'text/csv;charset=ansi'
          } }
        >
            {
         props => (
            <div> 
             <span className="pr-5 mr-auto"> 
            <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
            </span>  
            <span className="pl-5 ml-5 mr-5 mt-5">          
            <SearchBar className="form-control " { ...props.searchProps } />
            </span>
            <ClearSearchButton className="btn-outline-info" { ...props.searchProps } />
            
            <hr />
            <div className="table-responsive">
            <BootstrapTable keyField='employee' responsive hover columns={ columns } data={ data } pagination={ paginationFactory(options) }  { ...props.baseProps }/>
            </div>
            </div>
            )
        }
            </ToolkitProvider>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Adminemployeeorders
