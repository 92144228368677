import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Button, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Switch } from "antd";
import moment from "moment";

function Admintransporter() {
  const [selected1, setselected1] = useState("");
  const [selected, setselected] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  //delete model
  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    gettransporters();
  }, []);

  //Edit Model
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };

  const gettransporters = () => {
    try {
      axios.get(`${apiURL}/employee/allemployee`).then(function (response) {
        if (response.status === 200) {
          console.log(response.data);
          //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
          setdata(response.data.employee);
          return;
        }
      });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  const remove = async () => {
    try {
      let res = await axios.delete(`${apiURL}/deleteEmployee/${selected1}`);
      if (res.status == 200) {
        alert("Succssfully deleted");
        gettransporters();
        hideModal();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const approve = async (data) => {
    try {
      axios
        .post(`${apiURL}/blockUnblockemployee/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            alert(response.data.success);
            gettransporters();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const [email, setemail] = useState("");
  const [employeename, setemployeename] = useState("");
  const [permanentno, setpermanentno] = useState("");
  const [dob, setdob] = useState("");
  const [accontno, setaccontno] = useState("");
  const [IDtype, setIDtype] = useState("");
  const [aadhaarno, setaadhaarno] = useState("");
  const [highestQuliDoc, sethighestQuliDoc] = useState("");
  const [empProfile, setempProfile] = useState("");
  const [highestQuli, sethighestQuli] = useState("");
  const [designation, setdesignation] = useState("");
  const [IDDoc, setIDDoc] = useState("");
  const [residentialaddress, setresidentialaddress] = useState("");
  const [alternateno, setalternateno] = useState("");
  const [officeaddress, setofficeaddress] = useState("");
  const [joinDate, setjoinDate] = useState("");
  const formdata = new FormData();
  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }

    return false;
  }
  function pincodecheck(inputtxt) {
    var no = /^\d{12}$/;
    if (inputtxt.match(no)) {
      return true;
    } else {
      return false;
    }
  }
  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  }
  const Onsubmit = async () => {
    if (email) {
      if (!ValidateEmail(email))
        return alert("You have entered an invalid email address!");
    } else if (permanentno) {
      if (!phonenumber(permanentno))
        return alert("You have entered an invalid mobile number!");
    } else if (alternateno) {
      if (!phonenumber(alternateno))
        return alert("You have entered an invalid alternate number!");
    } else if (aadhaarno) {
      if (!pincodecheck(aadhaarno))
        return alert("You have entered an invalid adhar number!");
    } else formdata.append("employeename", employeename);
    formdata.append("officalemail", email);
    // formdata.append("password", password);
    // formdata.append("officalemail", officalemail);

    formdata.append("contactno", permanentno);
    formdata.append("alternateno", alternateno);
    formdata.append("officeaddress", officeaddress);
    formdata.append("residentialaddress", residentialaddress);
    formdata.append("dob", dob);
    formdata.append("accontno", accontno);
    formdata.append("IDtype", IDtype);
    formdata.append("aadhaarno", aadhaarno);
    formdata.append("highestQuliDoc", highestQuliDoc);
    formdata.append("empProfile", empProfile);
    formdata.append("highestQuli", highestQuli);
    formdata.append("designation", designation);
    formdata.append("IDDoc", IDDoc);
    // formdata.append("distributerphoto", distributerphoto);
    // formdata.append("warehousephoto", warehousephoto);
    formdata.append("profilestatus", true);
    formdata.append("approvestatus", true);
    formdata.append("employeeId", selected?._id);
    try {
      const config = {
        url: "/employee/updateEmployeeDetails",
        method: "put",
        baseURL: apiURL,
        data: formdata,
      };
      let res = await axios(config);
      console.log(res.status);
      if (res.status === 200) {
        console.log(res.data);
        alert("Successfully upadated");
        window.location.assign("/admin/notapprovedtransporter");
        return res;
      } else {
        alert("Email or Mobile Already Exist");
        console.log(res.data.error);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/employee/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
        onClick={() =>
          window.location.assign(`${imageURL}/employee/profile/${cell}`)
        }
      />
    );
  }

  const columns = [
    {
      text: "",
    },

    {
      dataField: "_id",
      text: "Enmployee Id",
      sort: true,
    },
    {
      dataField: "empProfile",
      text: "Profile ",
      formatter: imageFormatter,
    },
    {
      dataField: "employeename",
      text: "Full Name",
      sort: true,
    },
    {
      dataField: "officalemail",
      text: "Email",
      sort: true,
    },
    {
      dataField: "contactno",
      text: "Contact No",
    },
    { dataField: "alternateno", text: "Alternate No" },
    { dataField: "aadhaarno", text: "Adhar No" },
    {
      dataField: "",
      text: "Date of Birth",
      formatter: (cell, row) => {
        return <div>{moment(row.dob).format("DD-MMM-YYYY")}</div>;
      },
    },
    {
      dataField: "officeaddress",
      text: "Current Address",
    },
    {
      dataField: "residentialaddress",
      text: "Permanent Address",
    },
    {
      dataField: "highestQuli",
      text: "Heighest qualification ",
    },
    {
      dataField: "highestQuliDoc",
      text: "Education Document ",
      formatter: imageFormatter,
    },
    {
      dataField: "IDtype",
      text: "Id Proof",
    },
    {
      dataField: "IDDoc",
      text: "Id Doc",
      formatter: imageFormatter,
    },

    {
      dataField: "",
      text: "Date Of Joining",
      formatter: (cell, row) => {
        return <div>{moment(row.joinDate).format("DD-MMM-YYYY")}</div>;
      },
    },
    {
      // dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div style={{ display: "flex", gap: "5px" }}>
            <button
              className="btn btn-outline-success"
              onClick={() => {
                setselected(row);
                showModal1();
              }}
            >
              Edit
            </button>
            <Switch
              className="jdsvljds"
              checked={row?.blockstatus}
              onChange={() => approve(row)}
              style={{ margin: "0px 0px" }}
            />
            <AiFillDelete
              className="delete-button"
              onClick={() => {
                showModal();
                setselected1(row?._id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Employee List
        </h1>
        <p className="end">
          <Link to="/admin/employeelistform">
            <Button variant="primary">Add Form</Button>
          </Link>
        </p>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "Notapprovedtransporters.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="transporter"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
      <Modal
        style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
        show={isOpen}
        onHide={hideModal}
        animation={false}
      >
        <Modal.Header closeButton className="mt-4">
          <Modal.Title>Are you sure delete !</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="success" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            style={{ cursor: "pointer" }}
            onClick={() => remove()}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Model */}
      <Modal
        style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
        show={isOpen1}
        onHide={hideModal1}
        animation={false}
      >
        <Modal.Header closeButton className="mt-4">
          <Modal.Title>Edit Profile </Modal.Title>
        </Modal.Header>

        <div className="ms-2 me-2">
          <h5>Update Employee</h5>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Employee Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setemployeename(e.target.value)}
              placeholder={selected.employeename}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Email</Form.Label>
            <Form.Control
              type="email"
              onChange={(e) => setemail(e.target.value)}
              placeholder={selected.officalemail}
            />
          </Form.Group>
          {/* <h5>Bank details</h5> */}
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Mobile Number</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setpermanentno(e.target.value)}
              placeholder={selected.contactno}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
              Alternet Number
            </Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setalternateno(e.target.value)}
              placeholder={selected.alternateno}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Adhar Number</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setaadhaarno(e.target.value)}
              placeholder={selected.aadhaarno}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Date Of Birth</Form.Label>
            <Form.Control
              type="date"
              onChange={(e) => setdob(e.target.value)}
              placeholder={selected.dob}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Designation</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setdesignation(e.target.value)}
              placeholder={selected.designation}
            />
          </Form.Group>
          {/* <h5>Address Change</h5> */}
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Joining Date</Form.Label>
            <Form.Control
              type="date"
              onChange={(e) => setjoinDate(e.target.value)}
              placeholder={selected.joinDate}
            />
          </Form.Group>
          <h5>Address Change</h5>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
              Current Address
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setofficeaddress(e.target.value)}
              placeholder={selected.officeaddress}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
              Permanent Address
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setresidentialaddress(e.target.value)}
              placeholder={selected.residentialaddress}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
              Heigest Qualification
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => sethighestQuli(e.target.value)}
              placeholder={selected.highestQuli}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold" htmlFor="upload1">
              Qualification Doc
            </Form.Label>

            <Form.Control
              type="file"
              id="upload1"
              accept="image/gif, image/jpeg, image/png"
              required=""
              onChange={(e) => sethighestQuliDoc(e.target.files[0])}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Id Type</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setIDtype(e.target.value)}
              placeholder={selected.IDtype}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold" htmlFor="upload2">
              Id Proof Doc
            </Form.Label>
            <Form.Control
              type="file"
              id="upload2"
              accept="image/gif, image/jpeg, image/png"
              required=""
              onChange={(e) => setIDDoc(e.target.files[0])}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold" htmlFor="upload3">
              Employee Image
            </Form.Label>
            <Form.Control
              type="file"
              id="upload3"
              accept="image/gif, image/jpeg, image/png"
              required=""
              onChange={(e) => setempProfile(e.target.files[0])}
            />
          </Form.Group>
        </div>
        <Modal.Footer>
          <Button variant="success" onClick={hideModal1}>
            Close
          </Button>
          <Button variant="danger" onClick={Onsubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Admintransporter;
