import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

function Adminretailerorders() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  useEffect(() => {
    getretailer();
  }, []);

  const getretailer = () => {
    try {
      axios
        .get(`${apiURL}/retailer/order`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setdata(
              response.data.orders.filter((item) => item.products[0].adminId)
            );
            return;
          } else {
            alert("Can't able to fetch ");

            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/retailers/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      // dataField: "_id",
      text: "Order Id",
      sort: true,
    },
    {
      // dataField: "retailers[0].firmname",
      text: "Retailer Id",
      sort: true,
    },
    {
      // dataField: "retailers[0].firmname",
      text: "Retailer Name",
      sort: true,
    },
    {
      // dataField: "paymentmethod",
      text: "Transaction Id",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row.paymentmethod !== "online"
              ? row.paymentmethod.toUpperCase()
              : row.paymentId}
          </div>
        );
      },
    },
    {
      // dataField: "paymentmethod",
      text: "Payment Method",
    },
    {
      // dataField: "",
      text: "Paid Amount",
      formatter: (cell, row) => {
        return (
          <span>
            {parseFloat(row.products[0].retailerofferPrice).toFixed(2) *
              parseInt(row.quantity) +
              (row?.deliverycharge ? parseInt(row.deliverycharge) : 0) -
              parseFloat(row.promodiscount ? row.promodiscount : 0).toFixed(2)}
          </span>
        );
      },
    },
    {
      // dataField: "orderdatetime",
      text: "Placed On",
    },
    {
      // dataField: "duedatetime",
      text: "Due Date",
    },
    {
      // dataField: "status",
      text: "Order status",
    },
    {
      // dataField: "address[0].doorno",
      text: "Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row?.addresstype == "apartment" ? row?.apartmentname : ""},{" "}
            {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
            {row?.state}, {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    {
      // dataField: "",
      text: "Status",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-info">
              <Link
                to={{
                  pathname: "/admin/retailerorderview",
                  state: { data: row },
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                View
              </Link>
            </button>
          </div>
        );
      },
    },

    {
      // dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-info">
              <Link
                to={{ pathname: "/admin/orderview", state: { data: row } }}
                style={{ textDecoration: "none", color: "black" }}
              >
                Accept
              </Link>
            </button>
            <button className="btn btn-danger" style={{margin:"7px 0px"}}>
                Cancel
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c",display:"flex" }}>
          Retailer Orders
          <Link to="/admin/retailerorders">
          <button className="btn btn-primary" style={{ margin: "0px 10px" }}>
              All
            </button>
          </Link>
          <Link to="/admin/rinprocess">
          <button className="btn btn-primary" style={{ margin: "0px 10px" }}>
              Inprocess
            </button>
          </Link>
          <Link to="/admin/routfordelivery">
            <button className="btn btn-warning" style={{ margin: "0px 10px" }}>
              Out For Delivery
            </button>
          </Link>
          <Link to="/admin/rdelivered">
            <button className="btn btn-success" style={{ margin: "0px 10px" }}>
              Delivered
            </button>
          </Link>
          <Link to="/admin/rcanceled">
            <button className="btn btn-danger" style={{ margin: "0px 10px" }}>
              Canceled
            </button>
          </Link>
        </h1>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "retailer.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="retailer"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminretailerorders;
