import React from "react";
import Productslist from "./Productslist";
import Productsmenu from "./Productsmenu";

function Retailerproducts() {
  return (
    <div>
      <div className="container">
        <div className="row">
          {/* <div className="col-md-3">
            <Productsmenu />
          </div> */}
          <div className="col-md-12">
            <Productslist />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Retailerproducts;
