import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';


function Vendoraddproduct() {
    const [image1, setimage1] = useState();
    const [image2, setimage2] = useState();
    const [image3, setimage3] = useState();
    const [image4, setimage4] = useState();
    const [video, setvideo] = useState()    
    const [category, setcategory] = useState("");
    const [subcategory, setsubcategory] = useState("");    
    const [name, setname] = useState("");   
    const [price, setprice] = useState("");   
    const [desc, setdesc] = useState("");
    const [tax, settax] = useState(0)
    const formdata = new FormData();
    const token = sessionStorage.getItem("token");
    const apiURL = process.env.REACT_APP_API_URL;
    const user = JSON.parse(sessionStorage.getItem("user"));
    const [categorydata, setcategorydata] = useState([]);
    const [subcategorydata, setsubcategorydata] = useState([]);
    const [productvolumetype, setproductvolumetype] = useState('');
    const [productvolume, setproductvolume] = useState('');
    const [productsize, setproductsize] = useState('');
    const [productwarranty, setproductwarranty] = useState('');
    const [productwarrantytype, setproductwarrantytype] = useState('');
    const [productmodelno, setproductmodelno] = useState('');
    const [customerofferPrice, setcustomerofferPrice] = useState('');
    const [retailerofferPrice, setretailerofferPrice] = useState('');
    const [distributorofferPrice, setdistributorofferPrice] = useState('');
    const [keyaccountofferPrice, setkeyaccountofferPrice] = useState('');
    const [corporateofferPrice, setcorporateofferPrice] = useState('');
    const [employeeofferPrice, setemployeeofferPrice] = useState('');
    const [customerdiscountpercentage, setcustomerdiscountpercentage] = useState('');
    const [retailerdiscountpercentage, setretailerdiscountpercentage] = useState('');
    const [distributordiscountpercentage, setdistributordiscountpercentage] = useState('');
    const [keyaccountdiscountpercentage, setkeyaccountdiscountpercentage] = useState('');
    const [corporatediscountpercentage, setcorporatediscountpercentage] = useState('');
    const [employeediscountpercentage, setemployeediscountpercentage] = useState('');
    const [productfeatures, setproductfeatures] = useState('');
    const [productbrand, setproductbrand] = useState('');
    const [totalstock, settotalstock] = useState("");
    const [quantitycase, setquantitycase] = useState("");
    const [hsncode, sethsncode] = useState("");
    const [free, setfree] = useState("");
    

    
    




    useEffect(() => {      
      getcategory();
    }, []);

    const getcategory = async () => {
      let res = await axios.get(apiURL + "/getcategory");
      if ((res.status = 200)) {
        console.log(res);
        setcategorydata(res.data?.category);
      }
    };

    useEffect(() => {
      postallsubcategory();
    }, [category]);
  
    const postallsubcategory = async () => {
      let res = await axios.post(apiURL + "/admin/postsubcategory", {
        categoryName: category,
      });
      if ((res.status = 200)) {
        console.log(res);
        setsubcategorydata(res.data?.subcategory);
      }
    };

    
    const postproduct = async () =>{             
        formdata.append("productname", name);
        formdata.append("productcategory", category);
        formdata.append("productsubcategory", subcategory);
        formdata.append("adminId", user.id);        
        formdata.append("productprice", price);
        formdata.append("productdesc", desc);
        formdata.append("tax", tax);
        formdata.append("productimage",image1);
        formdata.append("productimage",image2);
        formdata.append("productimage",image3); 
        formdata.append("productimage",image4);  
        formdata.append("productvideo",video);
        formdata.append("productvolumetype",productvolumetype);
        formdata.append("productvolume",productvolume);
        formdata.append("productsize",productsize);
        formdata.append("productwarranty",productwarranty);
        formdata.append("productwarrantytype",productwarrantytype);
        formdata.append("productmodelno",productmodelno);
        formdata.append("customerofferPrice",customerofferPrice);
        formdata.append("retailerofferPrice",retailerofferPrice);       
        formdata.append("distributorofferPrice",distributorofferPrice);
        formdata.append("keyaccountofferPrice",keyaccountofferPrice);
        formdata.append("corporateofferPrice",corporateofferPrice);
        formdata.append("employeeofferPrice",employeeofferPrice);
        formdata.append("customerdiscountpercentage",customerdiscountpercentage);
        formdata.append("retailerdiscountpercentage",retailerdiscountpercentage);
        formdata.append("distributordiscountpercentage",distributordiscountpercentage);
        formdata.append("keyaccountdiscountpercentage",keyaccountdiscountpercentage);
        formdata.append("corporatediscountpercentage",corporatediscountpercentage);
        formdata.append("employeediscountpercentage",employeediscountpercentage);
        formdata.append("productfeatures",productfeatures);
        formdata.append("productbrand",productbrand);
        formdata.append("totalstock",totalstock);
        formdata.append("quantitycase",quantitycase);
        formdata.append("hsncode",hsncode);
        formdata.append("free",free);
        
        
        try{
            const config ={
                           url:'/vendor/addproduct',
                           method:'post',
                           baseURL: apiURL,
                           headers:{ 'Authorization':"Bearer "+token},
                           data: formdata
                           
            }
            const response= await axios(config)
            .then(
              function(response){
              if (response.status === 200) {   
                alert("Added Successfull");
               window.location.assign("/vendor/product");
                
                return response;
              } else{
                alert(' Unsuccessfull');
                
               }             
          })
          .catch(function(error){
             alert(" Unsuccessfull");
            
           console.log(error.config);                  
            return error;
          });
      
    } catch (error) {
      console.warn(error);
      
    alert(" Unsuccessfull") 
    return error;
    };
    }

    return (
        <div className="container pb-5">
        <Card className="text-center mt-5" style={{  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
        <h5 className="pt-5"> Add Product</h5> 
         <Card.Body>
          <div className="row me-0">
            <div className="col-1"></div>
              <div className="col-2">
          <div>
          {/* {image1 !== undefined? <img src={URL.createObjectURL(image1)} style={{height:"200px" ,width:"200px", borderRadius:"8px"}}/>:  
          <img src={logo} style={{height:"100px" ,width:"200px", borderRadius:"8px"}}/>} */}
          <h2>Mitrakart</h2>
          </div>
          <div className="mt-4">
          <input type="file" style={{border:"none"}} onChange={(e)=>setimage1(e.target.files[0])}/>
          </div>
          </div>
          <div className="col-2">
          <div>
          {/* {image2 !== undefined? <img src={URL.createObjectURL(image2)} style={{height:"200px" ,width:"200px", borderRadius:"8px"}}/>:  
          <img src={logo} style={{height:"100px" ,width:"200px", borderRadius:"8px"}}/>} */}
          <h2>Mitrakart</h2>
          </div>
          <div className="mt-4">
          <input type="file" style={{border:"none"}} onChange={(e)=>setimage2(e.target.files[0])}/>
          </div>
          </div>
          <div className="col-2">
          <div>
          {/* {image3 !== undefined? <img src={URL.createObjectURL(image3)} style={{height:"200px" ,width:"200px", borderRadius:"8px"}}/>:  
          <img src={logo} style={{height:"100px" ,width:"200px", borderRadius:"8px"}}/>} */}
          <h2>Mitrakart</h2>
          </div>
          <div className="mt-4">
          <input type="file" style={{border:"none"}} onChange={(e)=>setimage3(e.target.files[0])}/>
          </div>
          </div>
          <div className="col-2">
          <div>
          {/* {image3 !== undefined? <img src={URL.createObjectURL(image3)} style={{height:"200px" ,width:"200px", borderRadius:"8px"}}/>:  
          <img src={logo} style={{height:"100px" ,width:"200px", borderRadius:"8px"}}/>} */}
          <h2>Mitrakart</h2>
          </div>
          <div className="mt-4">
          <input type="file" style={{border:"none"}} onChange={(e)=>setimage4(e.target.files[0])}/>
          </div>
          </div>
          <div className="col-2">
          <div>
          {/* {image3 !== undefined? <img src={URL.createObjectURL(image3)} style={{height:"200px" ,width:"200px", borderRadius:"8px"}}/>:  
          <img src={logo} style={{height:"100px" ,width:"200px", borderRadius:"8px"}}/>} */}
          <h2>Mitrakart</h2>
          </div>
          <div className="mt-4">
          <input type="file" style={{border:"none"}} onChange={(e)=>setvideo(e.target.files[0])}/>
          </div>
          </div>
          </div>
          <form  noValidate autoComplete="off">
          <div className="row pt-3">
              <div className="col-4">
              {/* <TextField id="standard-secondary" label="Category"  style={{width:"275px"}} onChange={(e)=>setcategory(e.target.value)}/> */}
              <FormControl>
                <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>setcategory(e.target.value)}               
                >{categorydata?.map((allcategory) => (
                  <MenuItem value={allcategory.categoryName}>
                    {allcategory.categoryName}
                  </MenuItem>
                ))}
                {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
            </FormControl>
             </div>
              <div className="col-4">
              {/* <TextField id="standard-secondary" label="Sub-Category"  style={{width:"275px"}} onChange={(e)=>setsubcategory(e.target.value)}/> */}
              <FormControl>
                <InputLabel id="demo-simple-select-label">Select Sub-Category</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}}  
                onChange={(e)=>setsubcategory(e.target.value)}             
                >
                  {subcategorydata?.map((allsubcategory) => (
                  <MenuItem value={allsubcategory.subcategoryName}>
                    {allsubcategory.subcategoryName}
                  </MenuItem>
                ))}
                {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
            </FormControl>
              </div>
              <div className="col-4">
              <TextField id="standard-secondary" label="Product Name"  style={{width:"275px"}} onChange={(e)=>setname(e.target.value)} />
              {/* <TextField id="standard-secondary" label="Product Brand"  style={{width:"275px"}} onChange={(e)=>setbrand(e.target.value)} /> */}
              </div>
          </div>    
          
          <div className="row pt-3">
          <div className="col-4">
          <TextField id="standard-secondary" label="Product price"  style={{width:"275px"}} onChange={(e)=>setprice(e.target.value)} />    
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          </div>
          <div className="col-4">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Product GST</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>settax(e.target.value)}               
                >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
                </Select>
            </FormControl>
              
              </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="HSN Code"   style={{width:"275px"}} onChange={(e)=>sethsncode(e.target.value)} />
          </div>
              </div> 

              <div className="row pt-3">
          <div className="col-4">
          <TextField id="standard-secondary" label="Product Volume"  style={{width:"275px"}} onChange={(e)=>setproductvolume(e.target.value)} />    
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          </div>
          <div className="col-4">
          <TextField id="standard-secondary" label="Product Volume Type"  style={{width:"275px"}} onChange={(e)=>setproductvolumetype(e.target.value)} />    

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label">Product GST</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>settax(e.target.value)}               
                >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
                </Select>
            </FormControl> */}
              
              </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="Product Size"   style={{width:"275px"}} onChange={(e)=>setproductsize(e.target.value)} />
          </div>
              </div>

              <div className="row pt-3">
          <div className="col-4">
          <TextField id="standard-secondary" label="Product Warranty"  style={{width:"275px"}} onChange={(e)=>setproductwarranty(e.target.value)} />    
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          </div>
          <div className="col-4">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Product Warranty Type</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>setproductwarrantytype(e.target.value)}               
                >
                <MenuItem value="Month">Month</MenuItem>
                <MenuItem value="Year">Year</MenuItem>                
                </Select>
            </FormControl>
              
              </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="Product Model No"   style={{width:"275px"}} onChange={(e)=>setproductmodelno(e.target.value)} />
          </div>
              </div>

              {/* <div className="row pt-3">
          <div className="col-4">
          <TextField id="standard-secondary" label="Customer Offer Price"  style={{width:"275px"}} onChange={(e)=>setcustomerofferPrice(e.target.value)} />     */}
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          {/* </div>
          <div className="col-4">
          <TextField id="standard-secondary" label="Retailer Offer Price"  style={{width:"275px"}} onChange={(e)=>setretailerofferPrice(e.target.value)} />     */}

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label">Product GST</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>settax(e.target.value)}               
                >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
                </Select>
            </FormControl> */}
              
              {/* </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="Distributor Offer Price"   style={{width:"275px"}} onChange={(e)=>setdistributorofferPrice(e.target.value)} />
          </div>
              </div>
              <div className="row pt-3">
          <div className="col-4"> */}
          {/* <TextField id="standard-secondary" label="Keyaccount Offer Price"  style={{width:"275px"}} onChange={(e)=>setkeyaccountofferPrice(e.target.value)} />     */}
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          {/* </div>
          <div className="col-4">
          <TextField id="standard-secondary" label="Corporate Offer Price"  style={{width:"275px"}} onChange={(e)=>setcorporateofferPrice(e.target.value)} />     */}

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label">Product GST</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>settax(e.target.value)}               
                >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
                </Select>
            </FormControl> */}
              
              {/* </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="Employee Offer Price"   style={{width:"275px"}} onChange={(e)=>setemployeeofferPrice(e.target.value)} />
          </div>
              </div>
              <div className="row pt-3">
          <div className="col-4">
          <TextField id="standard-secondary" label="Customer Discount Percentage"  style={{width:"275px"}} onChange={(e)=>setcustomerdiscountpercentage(e.target.value)} />     */}
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          {/* </div>
          <div className="col-4">
          <TextField id="standard-secondary" label="Retailer Discount Percentage"  style={{width:"275px"}} onChange={(e)=>setretailerdiscountpercentage(e.target.value)} />     */}

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label">Product GST</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>settax(e.target.value)}               
                >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
                </Select>
            </FormControl> */}
              
              {/* </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="Distributor Discount Percentage"   style={{width:"275px"}} onChange={(e)=>setdistributordiscountpercentage(e.target.value)} />
          </div>
              </div>
              <div className="row pt-3">
          <div className="col-4">
          <TextField id="standard-secondary" label="Keyaccount Discount Percentage"  style={{width:"275px"}} onChange={(e)=>setkeyaccountdiscountpercentage(e.target.value)} />     */}
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          {/* </div>
          <div className="col-4">
          <TextField id="standard-secondary" label="Corporate Discount Percentage"  style={{width:"275px"}} onChange={(e)=>setcorporatediscountpercentage(e.target.value)} />     */}

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label">Product GST</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>settax(e.target.value)}               
                >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
                </Select>
            </FormControl> */}
              
              {/* </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="Employee Discount Percentage"   style={{width:"275px"}} onChange={(e)=>setemployeediscountpercentage(e.target.value)} />
          </div>
              </div> */}
              <div className="row pt-3">
          <div className="col-4">
          <TextField id="standard-secondary" label="Product Features"  style={{width:"275px"}} onChange={(e)=>setproductfeatures(e.target.value)} />    
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          </div>
          <div className="col-4">
          <TextField id="standard-secondary" label="Product Brand"  style={{width:"275px"}} onChange={(e)=>setproductbrand(e.target.value)} />    

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label">Product GST</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>settax(e.target.value)}               
                >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
                </Select>
            </FormControl> */}
              
              </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="Total Stock"   style={{width:"275px"}} onChange={(e)=>settotalstock(e.target.value)} />
          </div>
              </div>

              <div className="row pt-3">
          <div className="col-4">
          <TextField id="standard-secondary" label="Quantity Case"  style={{width:"275px"}} onChange={(e)=>setquantitycase(e.target.value)} />    
          {/* <TextField id="standard-secondary" label="Product Quantity"   style={{width:"275px"}} onChange={(e)=>setquantity(e.target.value)} /> */}
          </div>
          <div className="col-4">
          <TextField id="standard-secondary" label="Product Description"  style={{width:"275px"}}   onChange={(e)=>setdesc(e.target.value)} />    

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label">Product GST</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select" 
                style={{width:"275px"}} 
                onChange={(e)=>settax(e.target.value)}               
                >
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
                </Select>
            </FormControl> */}
              
              </div>
              <div className="col-4">
          <TextField id="standard-secondary" label="Free"   style={{width:"275px"}} onChange={(e)=>setfree(e.target.value)} />
          </div>
              </div>
          </form>
          <button className="btn btn-outline-primary mt-4" onClick={postproduct}>Add Product</button>          
      </Card.Body>
      </Card>
      </div>
    )
}

export default Vendoraddproduct
