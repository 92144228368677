import React, { useEffect, useState } from "react";
import "../../Components/customer/Styles/style12.css";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import { Link } from "react-router-dom";
function DistributorSlider1() {
  // /admin/distributortopdeals

  const [topdeal, setProduct] = useState([]);
  const gettopdeal = async () => {
    try {
      const config = {
        url: "/admin/distributortopdeals",
        method: "get",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setProduct(res.data.data1);
        // getCatory();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  useEffect(() => {
    gettopdeal();
    // newGetProducts();
    // getCatory();
    // if(user){
    //    getCatrDeatils()
    // getwislist();
    // }
  }, []);

  useEffect(() => {
    gettopdeal();
    // newGetProducts();
    // getCatory();
    // if(user){
    //    getCatrDeatils()
    // getwislist();
    // }
  }, []);

  return (
    <>
      <section className="categories" style={{ padding: "0px 40px" }}>
        <div class="container">
          <div class="section-title">
            <h2>
              Today's Our Best Offer
              <Link to="/distributor/distributor-product" style={{ fontSize: "15px" }}>
                <span> See all Deals</span>
              </Link>
            </h2>
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={5}
            dots={false}
          >
            {topdeal
              ?.filter((ele) => ele?.remainingstock > ele?.minimumStock)
              ?.map((items, id) => {
                return (
            <div class="item">
              <div
                class="categories__item set-bg"
                // data-setbg="/img/categories/cat-1.jpg"
                // style={{ backgroundImage: 'url("/img/categories/cat-4.jpg")' }}
              >
              
                <img
                        src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                        alt="hdwh"
                        style={{ width: "100%", height: "270px" }}
                      />
                      {/* href={`/single-pages/${items?._id}`} */}
                      <h5>
                  <Link to={`/distributor/distributor-product/${items?._id}`}> 
                  {items?.productname}
                    </Link>
                </h5>
                <div className="amazon-0" style={{position:" absolute",
    top:" 0px"}}>
                  <p>
                    <span>{items?.distributordiscountpercentage}% off</span>{" "} Deal of the Day
                  </p>
                </div>
              </div>
            </div>
             );
            })}
            {/* <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-2.jpg"
                style={{ backgroundImage: 'url("/img/categories/cat-2.jpg")' }}
              >
                <h5>
                  <a href="#">Dried Fruit</a>
                </h5>
                <div className="amazon-0">
                  <p>
                    <span>17% off</span> Deal of the Day
                  </p>
                </div>
              </div>
            </div>
            <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-3.jpg"
                style={{ backgroundImage: 'url("/img/categories/cat-3.jpg")' }}
              >
                <h5>
                  <a href="#">Vegetables</a>
                </h5>
                <div className="amazon-0">
                  <p>
                    <span>17% off</span> Deal of the Day
                  </p>
                </div>
              </div>
            </div>
            <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-4.jpg"
                style={{ backgroundImage: 'url("/img/categories/cat-4.jpg")' }}
              >
                <h5>
                  <a href="#">drink fruits</a>
                </h5>
                <div className="amazon-0">
                  <p>
                    <span>17% off</span> Deal of the Day
                  </p>
                </div>
              </div>
            </div>
            <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-4.jpg"
                style={{ backgroundImage: 'url("/img/categories/cat-4.jpg")' }}
              >
                <h5>
                  <a href="#">drink fruits</a>
                </h5>
                <div className="amazon-0">
                  <p>
                    <span>17% off</span> Deal of the Day
                  </p>
                </div>
              </div>
            </div>
            <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-2.jpg"
                style={{ backgroundImage: 'url("/img/categories/cat-2.jpg")' }}
              >
                <h5>
                  <a href="#">Dried Fruit</a>
                </h5>
                <div className="amazon-0">
                  <p>
                    <span>17% off</span> Deal of the Day
                  </p>
                </div>
              </div>
            </div> */}
          </OwlCarousel>
          {/* <div class="row">
            <div class="categories__slider owl-carousel">
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-1.jpg"
                >
                  <h5>
                    <a href="#">Fresh Fruit</a>
                  </h5>
                  <div className="amazon-0">
                    <p>
                      <span>17% off</span> Deal of the Day
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-2.jpg"
                >
                  <h5>
                    <a href="#">Dried Fruit</a>
                  </h5>
                  <div className="amazon-0">
                    <p>
                      <span>17% off</span> Deal of the Day
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-3.jpg"
                >
                  <h5>
                    <a href="#">Vegetables</a>
                  </h5>
                  <div className="amazon-0">
                    <p>
                      <span>17% off</span> Deal of the Day
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-4.jpg"
                >
                  <h5>
                    <a href="#">drink fruits</a>
                  </h5>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-5.jpg"
                >
                  <h5>
                    <a href="#">drink fruits</a>
                  </h5>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default DistributorSlider1;
