import React, { useEffect, useState } from "react";
import "../Styles/style12.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { FaPowerOff } from "react-icons/fa";
import { TfiWallet } from "react-icons/tfi";
import { Link } from "react-router-dom";

function Header2() {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState();
  const [acc2, setacc2] = useState();
  const [acc3, setacc3] = useState();
  const [acc4, setacc4] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
  };
  const [navigation, setnavigation] = useState("relative");
  let customer = JSON.parse(sessionStorage.getItem("customer"));
  let [wishlist, setwishlist] = useState([]);

  const getwislist = () => {
    axios
      .get("https://spilso.in/api/getWishlistByCustomerId/" + customer?.id)
      .then(function (response) {
        console.log(response.data);
        setwishlist(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [Carts, setCarts] = useState([]);

  const getCatrDeatils = () => {
    axios
      .get("https://spilso.in/api/getAllCartProductsByCustomer/" + customer?.id)
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [wallet, setwallet] = useState({});
  const getWaller = async () => {
    try {
      const config = {
        url: "/customer/createWallet",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
        data: {
          customerId: customer?.id,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setwallet(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (customer) {
      getwislist();
      getCatrDeatils();
      getWaller();
      getCustomer();
      getAddress();
    }

    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setnavigation("fixed top-0 left-0 z-50")
        : setnavigation("relative");
    }
  };

  const logOut = async () => {
    try {
      const config = {
        url: "/signout/" + customer?.id,
        method: "get",
        baseURL: "https://spilso.in/api/customer",
        headers: { "conttent-type": "application/json" },
        //  data:{phoneNumber:phoneNumber,otp:otp}
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully log out");
        sessionStorage.removeItem("customer");
        window.location.assign("/");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // offcanvas Second
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [pincode, setpincode] = useState("");
  const [House, setHouse] = useState("");
  const [Area, setArea] = useState("");
  const [Landmark, setLandmark] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  // const [name,setname]=useState("");
  const [showAddress, setshowAress] = useState({});
  const [email, setemail] = useState("");
  const getCustomer = async () => {
    try {
      let res = await axios.get(
        "https://spilso.in/api/getprofile/" + customer?.id
      );
      if (res.status === 200) {
        setshowAress(res.data.profilephoto);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [address, setaddress] = useState([]);
  let getAddress = async () => {
    try {
      let res = await axios.get(
        "https://spilso.in/api/customer/address/" + customer?.id
      );
      if (res.status === 200) {
        setaddress(res.data.customeraddress);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addAdress = async () => {
    try {
      let am = address?.filter(
        (ele) =>
          ele?.doorno === showAddress?.doorno && ele?.area === showAddress?.area
      );

      if (am?.length !== 0) {
        alert("Sucessfully apply");
        handleClose1();
        window.location.reload();
      } else {
        const config = {
          url: "/customer/addaddress",
          method: "post",
          baseURL: "https://spilso.in/api",
          headers: { "content-type": "application/json" },
          data: {
            customerId: customer?.id,
            addresstype: "Home",
            name: showAddress?.name,
            number: showAddress?.phoneNumber,
            doorno: showAddress?.doorno,
            area: showAddress?.area,
            addressline: showAddress?.addressline,
            city: showAddress?.city,
            state: showAddress?.state,
            country: showAddress?.country,
            pincode: showAddress?.pincode,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            alert("Successfully added");
            handleClose1();
            window.location.reload();
          }
        });
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const updatecustomer = async () => {
    try {
      const config = {
        url: "/customer/updatecustomer",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: {
          customerId: customer?.id,
          addresstype: "Home",
          doorno: House,
          addressline: Landmark,
          area: Area,
          city: city,
          state: state,
          country: country,
          pincode: pincode,
          name: name,
          phoneNumber: mobile,
          email: email,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Successfully Updated");
          getCustomer();
          handleClose();
          setname("");
          setmobile("");
          setpincode("");
          setHouse("");
          setArea("");
          setLandmark("");
          setcity("");
          setstate("");
          setcountry("");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <!-- Header Section Begin --> */}
      <header className="header">
        <div className="header__top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="header__top__left">
                  <ul>
                    {/* <li>
                      <i className="fa fa-envelope" /> hello@jaaggery.com
                    </li> */}
                    <li>Free Shipping for all Order of $99</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="header__top__right">
                  <div className="header__top__right__social">
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                    <a href="#">
                      <i className="fa fa-instagram" />
                    </a>
                  </div>
                  {customer ? (
                    <div className="header__top__right__auth">
                      <a style={{ color: "white" }}>
                        <i className="fa fa-user" /> {showAddress?.name} {" | "}
                        <span
                          onClick={() => logOut()}
                          style={{ color: "red", cursor: "pointer" }}
                        >
                          <FaPowerOff /> logout
                        </span>
                      </a>
                    </div>
                  ) : (
                    <div className="header__top__right__auth">
                      <a href="/login">
                        <i className="fa fa-user" /> Login
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ height: "90px", backgroundColor: "white" }}
        >
          <div className="row">
            <div className="col-lg-3">
              <div className="header__logo">
                <a href="/">
                  <img src="./Spilsologo.png" alt="" style={{ width: "42%" }} />
                  <span style={{ paddingLeft: "8px" }}>
                    <img
                      src="./Spilsologo2.png"
                      className="spin"
                      alt=""
                      style={{ width: "45px", height: "45px" }}
                    />
                  </span>
                </a>
              </div>
            </div>

            <div className="col-lg-6">
              <nav className="header__menu">
                <ul>
                  <li
                    className={`${acc ? "active" : ""}`}
                    onClick={() => {
                      setacc(true);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                    }}
                  >
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className={`${acc1 ? "active" : ""}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(true);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                    }}
                  >
                    <Link to="/products">Shop Now</Link>
                  </li>
                  <li
                    className={`${acc2 ? "active" : ""}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(true);
                      setacc3(false);
                      setacc4(false);
                    }}
                  >
                    <Link to="/become-a-patner">Become A Partners</Link>
                  </li>
                  {customer ? (
                    <li
                      className={`${acc3 ? "active" : ""}`}
                      onClick={() => {
                        setacc(false);
                        setacc1(false);
                        setacc2(false);
                        setacc3(true);
                        setacc4(false);
                      }}
                    >
                      <a href="#">My Account</a>
                      <ul className="header__menu__dropdown">
                        <li
                          onClick={handleShow1}
                          style={{
                            color: "white",
                            cursor: "pointer",
                            fontWeight: " 400",
                            padding: " 5px 15px",
                          }}
                        >
                          My Profile
                        </li>
                        <li>
                          <a href="/wallet" style={{ color: "white" }}>
                            My Wallet
                          </a>
                        </li>
                        <li>
                          <a href="/my-shopping" style={{ color: "white" }}>
                            My Shopping
                          </a>
                        </li>
                        <li>
                          <a href="/cart" style={{ color: "white" }}>
                            Shopping Cart
                          </a>
                        </li>
                        <li>
                          <a href="/track-orders" style={{ color: "white" }}>
                            Track Order
                          </a>
                        </li>
                        {/* <li><a href="./blog-details.html">Blog Details</a></li> */}
                      </ul>
                    </li>
                  ) : (
                    <></>
                  )}

                  {/* <li><a href="./blog.html">Blog</a></li> */}
                  <li
                    className={`${acc4 ? "active" : ""}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(true);
                    }}
                  >
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-3">
              <div className="header__cart">
                <ul>
                  <li>
                    <a href="/wishlist">
                      <i className="fa fa-heart" />{" "}
                      <span>{wishlist?.length}</span>
                    </a>
                  </li>
                  <li>
                    <a href="/cart">
                      <i className="fa fa-shopping-bag" />{" "}
                      <span>{Carts?.length}</span>
                    </a>
                  </li>
                </ul>
                {customer ? (
                  <div className="header__cart__price">
                    <a href="/wallet">
                      <TfiWallet style={{ fontSize: "18px", color: "black" }} />{" "}
                      <span> ₹{wallet?.totalAmount}.00</span>
                    </a>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="humberger__open">
            <i className="fa fa-bars" />
          </div>
        </div>
      </header>

      {/* <!-- Header Section End --> */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Your Profile</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pro_file">
            <div className="container naukri">
              <div className="">
                <div className="added_0">
                  <h3>Profile</h3>
                </div>
                <div className="profile_))">
                  <div className="profile_9 mb-2">
                    <b>Name:</b>
                    <span>{showAddress?.name}</span>
                  </div>
                  <div className="profile_9 mb-2">
                    <b>MobileNumber:</b>
                    <span>{showAddress?.phoneNumber}</span>
                  </div>
                  <div className="profile_9 mb-2">
                    <b>Email id:</b>
                    <span>{showAddress?.email}</span>
                  </div>
                  <div className="profile_9 mb-2">
                    <b>Delivery Address:</b>
                    <span>
                      {showAddress?.doorno},{showAddress?.area},
                      {showAddress?.addressline} ,{showAddress?.city},{" "}
                      {showAddress?.state}, {showAddress?.pincode},
                      {showAddress?.country}.
                      {/* <b style={{ cursor: "pointer" }} onClick={handleShow}>
                        Edit
                      </b> */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="info" onClick={handleShow}>
            Edit
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "#ed1c23", borderColor: "#ed1c23" }}
            onClick={addAdress}
          >
            Use this address
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Update your profile</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="naukri">
            <div className="added_0">
              <h3>Edit Profile</h3>
            </div>
            <div className="">
              <form>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Full name</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={showAddress?.name}
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>

                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Mobile number</label>
                  <input
                    type="tel"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={showAddress?.phoneNumber}
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Email</label>
                  <input
                    type="email"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={showAddress?.email}
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Flat, House no., Building, Company, Apartment</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={showAddress?.doorno}
                    value={House}
                    onChange={(e) => setHouse(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Area, Street, Sector, Village</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={showAddress?.area}
                    value={Area}
                    onChange={(e) => setArea(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Near Landmark</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={showAddress?.addressline}
                    value={Landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                  />
                </div>
                <div className="joker">
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Country</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder={showAddress?.country}
                      value={country}
                      onChange={(e) => setcountry(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Sate</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder={showAddress?.state}
                      value={state}
                      onChange={(e) => setstate(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>City</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder={showAddress?.city}
                      value={city}
                      onChange={(e) => setcity(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Pin code</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder={showAddress?.pincode}
                      value={pincode}
                      onChange={(e) => setpincode(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updatecustomer}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Header2;
