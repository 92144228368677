import React from "react";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../../../public/img/logo.jpg";

function Header() {
  return (
    <div>
      <Navbar className="header" variant="dark">
        <Navbar.Brand className="top-header">
          {/* <img
            alt=""
            src="/logo.svg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "} */}
          <div className="d-flex justify-content-around">
            <div>
              {/* <h4>IKNAMARTS</h4> */}
              <img src="/logo.png" alt="logo" width="125px" height="50px"></img>
            </div>
          </div>
        </Navbar.Brand>
      </Navbar>
    </div>
  );
}

export default Header;
