import axios from "axios";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

function Testimonials() {
  const [allTestimonial, setAllTestimonial] = useState([]);
  const getTestimonial = async () => {
    try {
      let res = await axios.get("https://spilso.in/api/getAllTestimonial");
      if (res.status == 200) {
        setAllTestimonial(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTestimonial();
  }, []);

  const  responsive={
    0:{
      items:1
    },
    600:{
      items:2
    },
    1000:{
      items:5
    }
  }

  return (
    <>
      <section
        class="latest-product spad"
        style={{ backgroundColor: "rgb(245 245 245)",marginTop:"15px",marginBottom:"40px" }}
      >
        <div class="container">
          <div class="section-title">
            <h2>Testimonials</h2>
          </div>
          <div className="c_04">
            <div className="c_)9">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                items={3}
                nav={false}
                responsive={responsive}
              >
                {allTestimonial?.map((items) => {
                  return (
                    <div class="item">
                      <div className="testi_man">
                        <div className="texti_img">
                          <img
                            src={`https://spilso.in/testimonial/${items?.Image}`}
                            alt="Testimonial_image"
                            className="testimonial_img"
                          />
                        </div>
                        <div
                          className=""
                          style={{ position: "relative", marginTop: "-15px" }}
                        >
                          <div className="testi_content">
                            <span>
                              <img src="/invertedcommas.png " alt="" />
                            </span>
                            <p>
                              {items?.text?.slice(0, 250)}. <br />
                            </p>
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "17px",
                                fontWeight: "700",
                                paddingBottom: "10px",
                              }}
                            >
                              - {items?.Name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi2.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </p>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi3.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </p>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi4.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi5.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi3.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </p>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi5.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </p>
                    </div>
                   </div>
                  </div>
                </div> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
