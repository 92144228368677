import React, { useEffect, useState } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { AiFillDelete } from "react-icons/ai";
import DatePicker from "react-multi-date-picker";
import { Switch } from "antd";
import { Link } from "react-router-dom";

function Singleproduct() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [selected, setselected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stock, setstock] = useState("");
  const [free, setfree] = useState("");
  const [date, setdate] = useState(new Date());
  const [basicprice, setbasicprice] = useState("");
  const [tax, settax] = useState("");
  const [product, setproduct] = useState([]);
  const [excel, setexcel] = useState();
  const [image, setimage] = useState();
  const [data1, setdata1] = useState([]);
  //

  //
  const formdata = new FormData();
  const [selected1, setselected1] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = (data) => {
    setselected(data);
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let res = await axios.get(apiurl + "/admin/product");
    if ((res.status = 200)) {
      console.log(res);
      setproduct(res.data?.products);
    }
  };
  const buyOneGetOne = async (id) => {
    try {
      //changebyOneGetoneproduct
      const config = {
        url: "/admin/changebyOneGetoneproduct",
        method: "put",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: { id: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getproduct();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const blockUnblockproduct = async (id) => {
    try {
      //changebyOneGetoneproduct
      const config = {
        url: "/admin/ProductBlockUnBlock",
        method: "put",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: { id: id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getproduct();
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (excel && data1.length === 0) {
    readFile();
  }

  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setdata1(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }

  function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  const postorders = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/addmultiproduct",
        method: "post",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: {
          adminId: user.id,
          product: data1.map((datas) => ({
            productname: datas.Product_Name,
            productcategory: datas.Category,
            productsubcategory: datas.Subcategory,
            image1: datas.Product_Image_1_Name,
            image2: datas.Product_Image_2_Name,
            image3: datas.Product_Image_3_Name,
            image4: datas.Product_Image_4_Name,
            video: datas.Product_Video_Name,
            productprice: datas.MRP_Price,
            productdesc: datas.Description,
            tax: datas.GST,
            productvolumetype: datas.Product_Volume_Type,
            productvolume: datas.Product_Volume,
            productsize: datas.Product_Size,
            productwarranty: datas.Product_Warranty,
            productwarrantytype: datas.Product_Warranty_Type,
            productmodelno: datas.Product_Model_No,
            customerofferPrice: datas.Customer_Offer_Price,
            retailerofferPrice: datas.Retailer_Offer_Price,
            distributorofferPrice: datas.Distributor_Offer_Price,
            keyaccoutofferPrice: datas.Keyaccout_Offer_Price,
            corporateofferPrice: datas.Corporate_Offer_Price,
            employeeofferPrice: datas.Employee_Offer_Price,
            customerdiscountpercentage: datas.Customer_Discount_Percentage,
            retailerdiscountpercentage: datas.Retailer_Discount_Percentage,
            distributordiscountpercentage:
              datas.Distributor_Discount_Percentage,
            keyaccountdiscountpercentage: datas.Keyaccount_Discount_Percentage,
            corporatediscountpercentage: datas.Corporate_Discount_Percentage,
            empolyeediscountpercentage: datas.Empolyee_Discount_Percentage,
            productfeatures: datas.Product_Features,
            productbrand: datas.Product_Brand,
            totalstock: datas.Total_Stock,
            quantitycase: datas.Quantity_Case,
            hsncode: datas.HSN_Code,
          })),
        },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Products Added Successfully");
        window.location.assign("/admin/products");

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postimage = async (e) => {
    e.preventDefault();
    Array.from(image).forEach((file) => {
      formdata.append("productimage", file);
    });

    try {
      const config = {
        url: "/admin/productimage",
        method: "post",
        baseURL: apiurl,

        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Image Uploaded Successfully");

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [productId, setProductId] = useState("");
  const [freeProductName, setfreeProductName] = useState("");
  const [freePrice, setfreePrice] = useState("");
  const [freeProdesc, setfreeProdesc] = useState("");
  const [freeProImage, setfreeProImage] = useState("");
  const [freeQuntity, setfreeQuntity] = useState("");
  let formDataA = new FormData();
  const AddFreeProduct = async () => {
    try {
      if (!freeProductName) {
        return alert("Please enter product name!");
      }
      if (!freeProdesc) {
        return alert("Please enter product discription!");
      }
      if (!freePrice) {
        return alert("Please enter product price!");
      }
      if (!freeProImage) {
        return alert("Please enter product image!");
      }
      formDataA.append("freeProductName", freeProductName);
      formDataA.append("freeProdesc", freeProdesc);
      formDataA.append("freePrice", freePrice);
      formDataA.append("freeProImage", freeProImage);
      formDataA.append("productId", productId);

      const config = {
        url: "/admin/addFreeProductwith",
        method: "put",
        baseURL: apiurl,
        headers: { "content-type": "multipart/form-data" },
        data: formDataA,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Added");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [vewFree, setviewFree] = useState({});

  const editFreeproductdata = async () => {
    try {
      formDataA.append("freeProductName", freeProductName);
      formDataA.append("freeProdesc", freeProdesc);
      formDataA.append("freePrice", freePrice);
      formDataA.append("freeProImage", freeProImage);
      formDataA.append("productId", vewFree?._id);

      const config = {
        url: "/admin/addFreeProductwith",
        method: "put",
        baseURL: apiurl,
        headers: { "content-type": "multipart/form-data" },
        data: formDataA,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully updated");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deletefreeProduct = async () => {
    try {
      const config = {
        url: "/admin/DeleteFreeProductwith",
        method: "put",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: { productId: vewFree?._id },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully deleted");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const remove = async () => {
    try {
      axios
        .post(`${apiurl}/admin/deleteproduct/` + selected1)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const postcategory = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/addadminstock",
        method: "post",
        baseURL: apiurl,
        // data: formdata,
        headers: { "content-": "application/json" },
        data: {
          productId: selected?._id,
          stock: stock,
          free: free,
          recieptdate: date,
          basicprice: basicprice,
          tax: tax,
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("Stock added");
          window.location.assign("/admin/products");
        }
      });
    } catch (error) {
      console.error(error);
      alert("category Name Not Added");
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: product.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/admin/product/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      text: "",
    },
    {
      dataField: "productimage[0]",
      text: "Product Image1",
      formatter: imageFormatter,
    },
    {
      dataField: "productimage[1]",
      text: "Product Image2",
      formatter: imageFormatter,
    },
    {
      dataField: "productimage[2]",
      text: "Product Image3",
      formatter: imageFormatter,
    },
    {
      dataField: "productimage[3]",
      text: "Product Image4",
      formatter: imageFormatter,
    },

    {
      dataField: "productType",
      text: "Product Type",
    },
    {
      dataField: "_id",
      text: "Product Id",
    },
    {
      dataField: "productcategory",
      text: "Category",
      sort: true,
    },
    // {
    //   dataField: "productsubcategory",
    //   text: "Subcategory",
    //   sort: true,
    // },
    {
      dataField: "productname",
      text: "Product Name With Title",
    },
    {
      dataField: "productprice",
      text: "Product MRP Price",
    },
    {
      dataField: "tax",
      text: "GST",
    },
    {
      dataField: "hsncode",
      text: "HSN Code",
    },

    {
      dataField: "customerdiscountpercentage",
      text: "Customer Discount Percentage(%)",
    },
    {
      dataField: "customerofferPrice",
      text: "Customer Offer Price",
    },

    {
      dataField: "retailerofferPrice",
      text: "Retailer Offer Price",
    },
    {
      dataField: "retailerdiscountpercentage",
      text: "Retailer Discount Percentage(%)",
    },
    {
      dataField: "distributorofferPrice",
      text: "Distributor Offer Price",
    },
    {
      dataField: "distributordiscountpercentage",
      text: "Distributor Discount Percentage(%)",
    },
    {
      dataField: "productdesc",
      text: "Product Description",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.productdesc ? row?.productdesc : ""}
        </p>
      ),
    },

    {
      dataField: "productbrand",
      text: "Product Brand",
    },
    {
      dataField: "totalstock",
      text: "Stock",
    },
    {
      dataField: "remainingstock",
      text: "Remaining Stock",
    },
    {
      dataField: "AllPrice",
      text: "More Valume",
      formatter: (value, row) => (
        <ul style={{ overflowY: "scroll", height: "100px", width: "110px" }}>
          {row?.AllPrice?.map((ele) => {
            return (
              <li>
                {ele?.perQuntity} {row?.productvolumetype} {": "}
                {"₹"} {ele?.quntityPrice}
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      dataField: "productsize",
      text: "Product Size L*B*H",
    },

    {
      dataField: "productmodelno",
      text: "Product Model No",
    },
    {
      dataField: "vendor",
      text: "Vendor Details",
    },
    {
      dataField: "retailerMoq",
      text: "Retailer MOQ",
    },
    {
      dataField: "distributerMoq",
      text: "Distributor MOQ",
    },

    {
      text: "Key Specification/Benifits",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.specification ? row?.specification : ""}
        </p>
      ),
    },
    {
      text: "How to use?/Storage",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.howTostore ? row?.howTostore : ""}
        </p>
      ),
    },
    {
      dataField: "minimumStock",
      text: "Minimum Stock",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "",
      text: "Edit",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <Link to={{ pathname: "/admin/editproject", state: { row } }}>
            <button className="btn btn-outline-success">Edit</button>{" "}
          </Link>
        );
      },
    },
    {
      dataField: "",
      text: "Buy1 Get1",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <Switch
              checked={row.isByOnegetFreeCust}
              onChange={() => buyOneGetOne(row?._id)}
            />
          </div>
        );
      },
    },

    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div className="d-flex">
            {row?.isFree === false ? (
              <button
                className="btn btn-warning"
                onClick={() => {
                  handleShow();
                  setProductId(row?._id);
                }}
                style={{ marginRight: "10px" }}
              >
                Add Free product
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={() => {
                  showModal();
                  setviewFree(row);
                }}
                style={{ marginRight: "10px" }}
              >
                Edit Free product
              </button>
            )}

            {/* <button className="btn btn-success" onClick={() => showModal(row)} >
              Add Stock
            </button> */}
            <Switch
              className="jdsvljds"
              checked={row.isBlock}
              onChange={() => blockUnblockproduct(row?._id)}
              style={{ margin: "0px 10px" }}
            />
            <AiFillDelete
              className="delete-button"
              onClick={() => {
                showModal1();
                setselected1(row?._id);
              }}
            />

            {/* {row?.subscriptation ? (
              <button
                className="btn btn-warning ms-2"
                onClick={() => unsubscribe(row)}
              >
                Unsubscribe
              </button>
            ) : (
              <button
                className="btn btn-success ms-2"
                onClick={() => subscribe(row)}
              >
                Subscribe
              </button>
            )} */}
          </div>
        );
      },
    },
  ];

  var i = 1;
  return (
    <div className="mt-3">
      <div className="d-flex">
        <a href="admin/addproducts" className="btn btn-primary mb-2 me-2">
          Add Product
        </a>
        {/* <a
          style={{ textDecoration: "none" }}
          href={imageURL + "/excelfile/productexcel.xlsx"}
          className="btn btn-outline-info me-2"
        >
          Download Excel
        </a> */}
        <input
          accept=".xlsx,.xls"
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
          // onChange={(e) => setexcel(e.target.files[0])}
        />
        <label className="btn btn-outline-info " htmlFor="icon-button-file">
          {" "}
          Import Excel
        </label>
        {/* <a
          className="btn btn-outline-success ms-2 me-2"
          // onClick={postorders}
        >
          Upload
        </a> */}
        {/* <input
          accept="image/*, video/*"
          style={{ display: "none" }}
          id="icon-button-file1"
          type="file"
          multiple
          // onChange={(e) => setimage(e.target.files)}
        />
        <label className="btn btn-outline-info " htmlFor="icon-button-file1">
          {" "}
          Select Images/Videos
        </label> */}
        {/* <a className="btn btn-outline-success ms-2 me-2" onClick={postimage}>
          Upload Images/Videos
        </a> */}
      </div>
      {/* Add Free Product Model */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Free Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label className="mt-2">Product Title</label>
              <input
                type="text"
                placeholder="Enter Product Title"
                className="form-control"
                value={freeProductName}
                onChange={(e) => setfreeProductName(e.target.value)}
              ></input>
              <label className="mt-2">Small Discription</label>
              <input
                type="text"
                placeholder="Enter Small Discription"
                className="form-control"
                value={freeProdesc}
                onChange={(e) => setfreeProdesc(e.target.value)}
              ></input>
              <label className="mt-2">MRP</label>
              <input
                type="text"
                placeholder="Enter MRP"
                className="form-control"
                value={freePrice}
                onChange={(e) => setfreePrice(e.target.value)}
              ></input>
              <br />
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label htmlFor="upload1">Product Image</Form.Label>
                <br />
                <Form.Control
                  type="file"
                  id="upload1"
                  accept="image/*"
                  name="file"
                  onChange={(e) => setfreeProImage(e.target.files[0])}
                />
              </Form.Group>
            </div>
            <br></br>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={AddFreeProduct}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/*Edit free product model*/}
      <div className="mt-3">
        <Modal show={isOpen} onHide={hideModal}>
          <Modal.Header>
            <Modal.Title>Edit Free Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label className="mt-2">Product Title</label>
                <input
                  type="text"
                  placeholder={vewFree?.freeProductName}
                  className="form-control"
                  value={freeProductName}
                  onChange={(e) => setfreeProductName(e.target.value)}
                ></input>
                <label className="mt-2">Small Discription</label>
                <input
                  type="text"
                  placeholder={vewFree?.freeProdesc}
                  className="form-control"
                  value={freeProdesc}
                  onChange={(e) => setfreeProdesc(e.target.value)}
                ></input>
                <label className="mt-2">MRP</label>
                <input
                  type="text"
                  placeholder={vewFree?.freePrice}
                  className="form-control"
                  value={freePrice}
                  onChange={(e) => setfreePrice(e.target.value)}
                ></input>
                <br />
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label htmlFor="upload1">Product Image</Form.Label>
                  <br />
                  <img
                    src={`${imageURL}/admin/product/${vewFree?.freeProImage}`}
                    height="100px"
                    width="100px"
                    style={{ borderRadius: "100%" }}
                  />

                  <Form.Control
                    type="file"
                    id="upload1"
                    accept="image/*"
                    name="file"
                    onChange={(e) => setfreeProImage(e.target.files[0])}
                  />
                </Form.Group>
              </div>
              <br></br>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={hideModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={deletefreeProduct}>
              Delete
            </Button>
            <Button variant="success" onClick={editFreeproductdata}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <ToolkitProvider
          keyField="id"
          data={product}
          columns={columns}
          search
          exportCSV={{
            fileName: "Products.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pr-5 mr-auto">
                <ExportCSVButton
                  className="btn-outline-success"
                  {...props.csvProps}
                >
                  Export to CSV
                </ExportCSVButton>
              </span>
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={product}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
        <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Are you sure delete !</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Cancel
            </Button>
            <Button
              variant="danger"
              style={{ cursor: "pointer" }}
              onClick={() => remove()}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Singleproduct;
