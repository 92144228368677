import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Components/customer/Styles/style12.css";

function DistributorCart() {
  const [Cartitems, setCartitems] = useState([]);
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));

  const getDistributorsCartItems = async () => {
    try {
      const res = await axios.get(
        `https://spilso.in/api/getAllCartProductsByDistributor/${distributor._id}`
      );
      if (res.status === 200) {
        setCartitems(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const incQuntity = async (item, price, quantity) => {
    try {
      const config = {
        url: "/priceIncAnddec",
        method: "put",
        baseURL: "https://spilso.in/api",
        headers: { "conten-type": "application/json" },
        data: {
          cartId: item?._id,
          quantity: quantity,
          totalPrice: price * quantity,
          // (Number(item?.productId?.productprice)*quantity)+Math.round((item?.productId?.customerofferPrice*quantity)*(item?.productId?.tax/100))-(Math.round((item?.productId?.customerofferPrice*quantity)*(item?.productId?.customerdiscountpercentage/100)))
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getDistributorsCartItems();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const decQuntity = async (item, price, quantity) => {
    try {
      if (quantity !== 0) {
        const config = {
          url: "/priceIncAnddec",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            cartId: item?._id,
            quantity: quantity,
            totalPrice: price * quantity,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          getDistributorsCartItems();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (distributor) {
      getDistributorsCartItems();
    }
  }, []);

  // let subtotal = 0;
  //   Cartitems.forEach((i)=>{
  //     subtotal+=(i?.productId?.distributorofferPrice * i?.quantity);
  //   });
  let subtotal = 0;
  let total = 0;
  let tax = 0;
  if (Cartitems?.length !== 0) {
    for (let i = 0; i < Cartitems.length; i++) {
      subtotal =
        subtotal +
        (Cartitems[i]?.totalPrice -
          Math.round(
            Number(Cartitems[i]?.price * Cartitems[i]?.quantity) *
              (Cartitems[i]?.productId?.tax / 100)
          ));
      total = total + Cartitems[i]?.totalPrice;
      tax =
        tax +
        Math.round(
          Number(Cartitems[i]?.price * Cartitems[i]?.quantity) *
            (Cartitems[i]?.productId?.tax / 100)
        );
    }
  }
  return (
    <>
      <div className="cart">
        <Container>
          <Row>
            <Col lg={9} md={9}>
              <div className="cart_hero">
                <div className="cart-title">
                  <h2>My Cart:</h2>
                  <div class="cart-count">
                    <span className="bigcounter">{Cartitems?.length}</span>
                    <span className="cart-item-title">Item</span>
                  </div>
                </div>
                {Cartitems?.map((items) => {
                  return (
                    <div
                      className="ecommerce_cart"
                      style={{ borderTop: " 1px solid #eeeeee" }}
                    >
                      <div className="item-wrap">
                        <ul className="cart-wrap mt-2">
                          <li className="item-info">
                            <div className="item-img">
                              <Link to="#">
                                <img
                                  src={`https://spilso.in/admin/product/${items?.productId?.productimage[0]}`}
                                  alt="nalla-Image"
                                  className="img-fluid"
                                  style={{ width: "125px", height: "125px" }}
                                />
                              </Link>
                            </div>
                            <div className="item-title">
                              <p className="fresh_hero">
                                {items?.productId?.productname}
                              </p>
                              <p className="item-option">
                                <b>Size:</b> {items?.Size}
                              </p>
                              <p className="item-option">
                                {" "}
                                {items?.quantity} {" x "} ₹
                                {items?.productId.distributorofferPrice}
                                {/* {Math.round(
                                  Number(items?.price) +
                                    Math.round(
                                      items?.price *
                                        (items?.productId?.tax / 100)
                                    ) -
                                    (Number(items?.price) +
                                      Math.round(
                                        items?.price *
                                          (items?.productId?.tax / 100)
                                      )) *
                                      (items?.productId
                                        ?.distributordiscountpercentage /
                                        100)
                                )} */}
                              </p>
                            </div>
                          </li>
                          <li className="item-qty">
                            <div className="product-quantity-action">
                              <div className="product-quantity">
                                <div className="cart-plus-minus">
                                  <div
                                    className="dec qtybutton"
                                    onClick={() =>
                                      decQuntity(
                                        items,
                                        items?.productId.distributorofferPrice,
                                        items?.quantity - 1
                                      )
                                    }
                                  >
                                    -
                                  </div>
                                  <input
                                    type="text"
                                    className="qt"
                                    value={items?.quantity}
                                  />
                                  <div
                                    className="inc qtybutton"
                                    onClick={() =>
                                      incQuntity(
                                        items,
                                        items?.productId.distributorofferPrice,
                                        items?.quantity + 1
                                      )
                                    }
                                  >
                                    +
                                  </div>
                                </div>
                                <span className="dec qtybtn"></span>
                                <span className="inc qtybtn"></span>
                              </div>
                            </div>
                            <div className="item-remove  mt-2">
                              <span className="remove-wrap">
                                <Link to="#">Remove</Link>
                              </span>
                            </div>
                          </li>
                          <li class="item-price">
                            <span class="money amount full-price-34766798487701">
                              ₹{items?.totalPrice}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col lg={3} md={3}>
              <div className="cart-total-wrap">
                <div className="cart-sub-total">
                  <span className="subtotal-title">Subtotal</span>
                  <span className="amount subtotal">₹{subtotal}.00</span>
                </div>
                <div className="cart-sub-total">
                  <span className="subtotal-title">Tax</span>
                  <span className="amount subtotal">₹{tax}.00</span>
                </div>

                <div className="cart-sub-total">
                  <span className="subtotal-title">Total</span>
                  <span className="amount subtotal">₹{total}.00</span>
                </div>
                <Link
                  to="/distributor/distributor-checkout"
                  class="btn btn-style1"
                >
                  Proceed to Checkout
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DistributorCart;
