import React, { useEffect, useState } from "react";
// import Fakedata from "../../../Components/customer/components/FakeData/FakeData"
import Fakedata from "../customer/components/FakeData/FakeData";
import { Button, Modal } from "antd";
import { Tooltip } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
function DistributorFeatured1() {
  const [image, setImage] = useState("");
  const [Currentitem, setCurritem] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [selPrice, setslePrice] = useState(0);
  const [selQuntity, setselQuntity] = useState(0);
  const [products, setproducts] = useState(Fakedata);
  const [Allproducts, setAllproducts] = useState([]);
  const [AllproductsTemp, setAllproductsTemp] = useState([]);
  const [quantity, setQuntity] = useState(1);
  const incQuntity = () => {
    setQuntity(quantity + 1);
  };
  const decQuntity = () => {
    if (quantity > 1) {
      setQuntity(quantity - 1);
    }
  };
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));
  const filterResult = (catitem) => {
    //
    if (!catitem) {
      return setAllproducts(AllproductsTemp);
    }
    const result = AllproductsTemp.filter((currentdata) => {
      return currentdata?.productcategory === catitem;
    });
    setAllproducts(result);
  };
  // console.log(products);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    // console.log(items);
    setIsModalOpen(true);
    console.log("bbbbbbb", Currentitem);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  /* ======================================================================================================== */

  const [categories, setCategories] = useState([]);
  const getCategory = async () => {
    try {
      const config = {
        url: "/getcategory",
        method: "get",
        baseURL: "https://spilso.in/api/",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setCategories(res.data.category);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const productslist = async () => {
    try {
      const res = await axios.get("https://spilso.in/api/getAllproduct");
      if (res.status === 200) {
        setAllproducts(res.data.products);
        setAllproductsTemp(res.data.products);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  /* ======================================================================================================== */
  // cart related api's
  const addToCartDistributor = async (product, e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/addToCartDistributor",
        method: "post",
        baseURL: "https://spilso.in/api",
        header: { "content-type": "application/json" },
        data: {
          distributorId: distributor?._id,
          productId: product?._id,
          quantity: 1,
          totalPrice: product?.distributorofferPrice * 1,
          Size: product?.productsize,
          price: product?.distributorofferPrice,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Product has added to the cart");
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [Carts, setCarts] = useState([]);
  const getCatrDeatils = () => {
    axios
      .get(
        "https://spilso.in/api/getAllCartProductsByDistributor/" +
          distributor?._id
      )
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeProductFromCart = async (item) => {
    if (!distributor) {
      alert("Need to Login");
    } else {
      try {
        const config = {
          url: "/removeProductsFromDistributorsCart",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            productId: item?._id,
            distributorId: distributor?._id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Product removed from wishlist");
          window.location.reload();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };

  /* ======================================================================================================== */
  // wishlist related api's
  const addToWishlistDistributor = async (product, e) => {
    // e.preventDefault();
    try {
      const config = {
        url: "/addWhishList",
        method: "post",
        baseURL: "https://spilso.in/api",
        header: { "content-type": "application/json" },
        data: {
          distributorId: distributor?._id,
          productId: product?._id,
          quantity: 1,
          totalPrice: product?.distributorofferPrice * 1,
          Size: product?.productsize,
          price: product?.distributorofferPrice,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Product has added to the Wishlist");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [wishlist, setwishlist] = useState([]);
  const getwislist = () => {
    axios
      .get(
        "https://spilso.in/api/getWishlistBydistributorId/" + distributor?._id
      )
      .then(function (response) {
        console.log(response.data);
        setwishlist(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeProductFromwishlist = async (item) => {
    if (!distributor) {
      alert("Please login");
    } else {
      axios({
        method: "delete",
        url:
          "https://spilso.in/api/removeWishlistbyDistributorId/" +
          distributor._id +
          "/" +
          item?._id,
      })
        .then(function (response) {
          //handle success
          console.log(response);
          alert("Product removed from wishlist");
          getwislist();
          window.location.reload();
        })
        .catch(function (error) {
          //handle error
          console.log(error.response.data);
        });
    }
  };

  /* ======================================================================================================== */

  useEffect(() => {
    productslist();
    getCategory();
    if (distributor) {
      getCatrDeatils();
      getwislist();
    }
  }, []);

  return (
    <>
      <section class="featured spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title">
                <h2>Our Products</h2>
              </div>
              <div class="featured__controls">
                <ul>
                  <li
                    onClick={() => {
                      filterResult("");
                    }}
                  >
                    All
                  </li>

                  {categories?.map((ele) => {
                    return (
                      <li
                        onClick={() => {
                          filterResult(ele?.categoryName);
                        }}
                      >
                        {ele?.categoryName}
                      </li>
                    );
                  })}
                  {/*
                  <li
                    onClick={() => {
                      filterResult("Vegetables");
                    }}
                  >
                    Vegetables
                  </li>
                  <li
                    onClick={() => {
                      filterResult("Fastfood");
                    }}
                  >
                    Fastfood
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div Layout class="row featured__filter">
            {Allproducts?.filter(
              (ele) => ele?.remainingstock > ele?.minimumStock
            )?.map((items, id) => {
              return (
                <div class="col-lg-2 col-md-2 col-sm-4 mix oranges fresh-meat">
                  <div class="featured__item rty">
                    <div
                      class="featured__item__pic set-bg"
                      // data-setbg="img/featured/feature-1.jpg"
                      // style={{
                      //   backgroundImage: `url(${`https://spilso.in/admin/product/${items?.productimage[0]}`})`,
                      // }}
                    >
                      <img
                        src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                        alt="hdwh"
                        style={{ width: "100%", height: "151px" }}
                      />
                      <div
                        className="product-label"
                        style={{
                          float: "right",
                          position: "absolute",
                          top: "4px",
                          right: "1%",
                        }}
                      >
                        <span className="sale-title">
                          {items?.distributordiscountpercentage}%
                        </span>
                      </div>
                      <ul class="featured__item__pic__hover">
                        {wishlist?.filter(
                          (item) => item?.productId?._id === items._id
                        )?.length ? (
                          <li>
                            <a
                              href="/distributor/distributor-home"
                              onClick={(e) =>
                                removeProductFromwishlist(items, e)
                              }
                            >
                              <i
                                class="fa fa-heart"
                                style={{ color: "Red" }}
                              ></i>
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a
                              href="/distributor/distributor-home"
                              onClick={(e) =>
                                addToWishlistDistributor(items, e)
                              }
                            >
                              <i class="fa fa-heart"></i>
                            </a>
                          </li>
                        )}
                        <li
                          onClick={() => {
                            setImage(items?.productimage[0]);
                            setCurritem(items);
                            setslePrice(0);
                            setselQuntity(0);
                            showModal(items);
                          }}
                        >
                          <a>
                            <i class="fa fa-eye"></i>
                          </a>
                        </li>
                        {Carts?.filter(
                          (item) => item?.productId?._id === items._id
                        )?.length ? (
                          <li onClick={(e) => removeProductFromCart(items, e)}>
                            <a href="/distributor/distributor-home">
                              <i
                                class="fa fa-shopping-cart"
                                style={{ color: "Yellow" }}
                              ></i>
                            </a>
                          </li>
                        ) : (
                          <li onClick={(e) => addToCartDistributor(items, e)}>
                            <a href="/distributor/distributor-home">
                              <i class="fa fa-shopping-cart"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div class="featured__item__text">
                      <h6>
                        <a
                          href={`/distributor/distributor-product/${items?._id}`}
                        >
                          {items?.productname}
                        </a>
                      </h6>
                      <div className="price-box mb-2">
                        <span className="new-price" id="ProductPrice">
                          ₹{items?.distributorofferPrice}
                        </span>
                        <span className="old-price" id="ComparePrice">
                          ₹
                          {Number(items?.productprice) +
                            Math.round(
                              items?.productprice * (items?.tax / 100)
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* Modal */}
      <div className="qui_hero">
        <Modal
          title="Quickview"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="pro-quick">
            <div className="quick-view">
              <div className="quick-view_hero">
                <a href="#">
                  <img
                    src={`https://spilso.in/admin/product/${image}`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="quick-view">
              <div className="hero_quick">
                <div className="product-content ">
                  <div className="product-title mb-1">
                    <Link
                      to={`/distributor/distributor-product/${Currentitem?._id}`}
                    >
                      {Currentitem?.productname}
                    </Link>
                  </div>

                  <div className="price-box mb-1">
                    {selPrice !== 0 ? (
                      <span className="new-price" id="ProductPrice">
                        {selQuntity}
                        {Currentitem?.productvolumetype}
                        {"-"}₹
                        {Math.round(
                          (Number(selPrice) +
                            Math.round(selPrice * (Currentitem?.tax / 100)) -
                            (Number(selPrice) +
                              Math.round(selPrice * (Currentitem?.tax / 100))) *
                              (Currentitem.distributordiscountpercentage /
                                100)) *
                            quantity
                        )}
                      </span>
                    ) : (
                      <span className="new-price" id="ProductPrice">
                        ₹
                        {Math.round(
                          (Number(Currentitem?.productprice) +
                            Math.round(
                              Currentitem?.productprice *
                                (Currentitem?.tax / 100)
                            ) -
                            (Number(Currentitem?.productprice) +
                              Math.round(
                                Currentitem?.productprice *
                                  (Currentitem?.tax / 100)
                              )) *
                              (Currentitem.distributordiscountpercentage /
                                100)) *
                            quantity
                        )}
                      </span>
                    )}
                    {selPrice !== 0 ? (
                      <span className="old-price" id="ComparePrice">
                        ₹
                        {Number(selPrice * quantity) +
                          Math.round(
                            selPrice * quantity * (Currentitem?.tax / 100)
                          )}
                      </span>
                    ) : (
                      <span className="old-price" id="ComparePrice">
                        ₹
                        {Number(Currentitem?.productprice * quantity) +
                          Math.round(
                            Currentitem?.productprice *
                              quantity *
                              (Currentitem?.tax / 100)
                          )}
                      </span>
                    )}
                  </div>
                  <div className="more-de mb-1">
                    <p className="simple-0">More Details</p>
                    <p className="lorem-09">
                      {showMore
                        ? Currentitem?.productdesc
                        : `${Currentitem?.productdesc?.substring(0, 110)}`}
                      <button
                        className="btn"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </button>
                    </p>
                  </div>
                  <div className="quick-view-select mb-2">
                    <div className="selector-wrapper">
                      <label>Size :</label>
                      <select
                        class="single-option-selector"
                        data-option="option1"
                        id="product-select-qv-option-0"
                        onChange={(e) => {
                          setslePrice(JSON.parse(e.target.value)?.quntityPrice);
                          setselQuntity(JSON.parse(e.target.value)?.perQuntity);
                        }}
                      >
                        {Currentitem?.AllPrice?.map((ele) => {
                          return (
                            <option value={JSON.stringify(ele)}>
                              {ele?.perQuntity}
                              {Currentitem?.productvolumetype}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="quick-view-select mb-2">
                    <div className="selector-wrapper">
                      <label>Discount :</label>
                      <span>{Currentitem?.distributordiscountpercentage}%</span>
                    </div>
                  </div>
                  <div className="cart-plus-minus">
                    <div className="dec qtybutton" onClick={decQuntity}>
                      -
                    </div>
                    <input
                      value={quantity}
                      type="text"
                      name="quantity"
                      min="1"
                      pattern="[0-9]*"
                    />
                    <div className="inc qtybutton" onClick={incQuntity}>
                      +
                    </div>
                  </div>
                  <div className="product-action-2">
                    <div className="action-wishlist">
                      <Tooltip title="WISHLIST">
                        {" "}
                        <span
                          class="add-wishlist"
                          id="app-title"
                          style={{ opacity: "1" }}
                        >
                          <i
                            class="fa fa-heart"
                            onClick={(e) =>
                              addToWishlistDistributor(Currentitem, e)
                            }
                          ></i>
                        </span>
                      </Tooltip>
                      <Tooltip title="ADD TO CART">
                        {" "}
                        <span class="add-wishlist" style={{ opacity: "1" }}>
                          <i
                            class="fa fa-shopping-bag"
                            onClick={(e) =>
                              addToCartDistributor(Currentitem, e)
                            }
                          ></i>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default DistributorFeatured1;
