import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";

function Sidebar() {
  const user = JSON.parse(sessionStorage.getItem("user"))
  
  return (
    <div>
      <ProSidebar width="200px">
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem>Dashboard<Link to="dashboard"></Link> </MenuItem>
            


            
            <SubMenu title="Products">            
            <MenuItem>
              Products<Link to="products"></Link>
            </MenuItem>            
            {/* <MenuItem>
            Purchase Orders<Link to="purchaseorders"></Link>
            </MenuItem>      */}

            </SubMenu>
            
            {/* <MenuItem>
              User Data<Link to="userdata"></Link>
            </MenuItem>            
            <MenuItem>
              Category<Link to="category"></Link>
            </MenuItem>
            <MenuItem>
              Subcategory<Link to="subcategory"></Link>
            </MenuItem>
            <MenuItem>
              Pincode<Link to="pincode"></Link>
            </MenuItem>
            <MenuItem>
              Products<Link to="products"></Link>
            </MenuItem> */}
          </Menu>
        </SidebarContent>
        <SidebarFooter >
                {/**
                 *  You can add a footer for the sidebar ex: copyright
                 */}
                
                 <div style={{height:"200px"}}></div> 
            </SidebarFooter>
        
      </ProSidebar>
    </div>
  );
}

export default Sidebar;
