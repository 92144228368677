import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import Cartproduct from "./Cartproduct";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getretailerBasketTotal, gettaxtotal } from "../../../StoreReducer";

import moment from "moment";
import axios from "axios";
import useRazorpay from "react-razorpay";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

function Retailercart(props) {
  const [payid ,setpayid] =useState ("")

  const [code, setcode] = useState("");
  const [show, setshow] = useState(false);
  const [showaddress, setshowaddress] = useState(false);
  const [value, setValue] = useState("");
  const [address, setaddress] = useState([]);
  const [deliveryslot, setdeliveryslot] = useState([]);
  const [pickupslot, setpickupslot] = useState([]);
  const [pincodedata, setpincodedata] = useState([]);
  const [promocode, setpromocode] = useState("");
  const [paymentmode, setpaymentmode] = useState("");
  const [promocodedata, setpromocodedata] = useState();
  const [deliverycharges, setdeliverycharges] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  const Razorpay = useRazorpay();
  const handleshowaddress = () => setshowaddress(true);
  const handleShow = () => {
    if (!value) {
      alert("Please Select Address");
    } else {
      setshowaddress(false);
      setshow(true);
    }
  };
  const handleClose = () => {
    setshow(false);
    setshowaddress(false);
  };
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");

  let newdata = {};

  useEffect(() => {
    getaddress();
  }, []);

  console.log(newdata);
  const getaddress = () => {
    try {
      axios
        .get(`${apiURL}/retailer/address/${user._id}`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setaddress(response.data.retaileraddress);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  console.log(address);

  const search = async () => {
    // e.preventDefault();
    try {
      axios
        .post(`${apiURL}/searchpincode/` + value.split("|")[8])
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setpincodedata(response.data.pincode);
            handleShow();
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Service Unavailable");
        });
    } catch (error) {
      console.warn(error);
      alert("Service Unavailable for this pincode");

      return error;
    }
  };

  const postorders = async (e) => {
    e.preventDefault();
    if (!paymentmode) {
      alert("Please Select Payment Mode");
    } else {
      try {
        const config = {
          url: "/retailer/addorders",
          method: "post",
          baseURL: apiURL,
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: [
            props.basket.map((data) => ({
              retailerId: user._id,
              distributorId: data.product?.distributorId,
              vendorId: data.product?.vendorId,
              adminId: data.product?.adminId,

              productId: data.product._id,
              orderdatetime: moment().format("DD/MM/YYYY hh:mm A"),
              duedatetime: moment()
                .add(parseInt(pincodedata.expecteddeliverydays), "days")
                .format("DD-MM-YYYY"),
              total:
                promocodedata !== undefined && deliverycharges.length !== 0
                  ? parseFloat(
                      parseFloat(getretailerBasketTotal(props.basket)) -
                        parseFloat(
                          (parseFloat(getretailerBasketTotal(props.basket)) /
                            100) *
                            parseInt(promocodedata.discountpercentage)
                        ).toFixed(2) +
                        parseFloat(deliverycharges[0]?.deliverycharge)
                    ).toFixed(2)
                  : promocodedata !== undefined
                  ? parseFloat(
                      parseFloat(getretailerBasketTotal(props.basket)) -
                        parseFloat(
                          (parseFloat(getretailerBasketTotal(props.basket)) /
                            100) *
                            parseInt(promocodedata.discountpercentage)
                        ).toFixed(2)
                    ).toFixed(2)
                  : deliverycharges.length !== 0
                  ? parseFloat(
                      parseFloat(getretailerBasketTotal(props.basket)) +
                        parseFloat(parseInt(deliverycharges[0]?.deliverycharge))
                    ).toFixed(2)
                  : parseFloat(getretailerBasketTotal(props.basket)).toFixed(2),
              deliverycharge:
                deliverycharges.length !== 0
                  ? parseFloat(
                      parseInt(deliverycharges[0]?.deliverycharge)
                    ).toFixed(2) / props.basket.length
                  : 0,
              promodiscount:
                promocodedata !== undefined
                  ? parseFloat(
                      (parseFloat(getretailerBasketTotal(props.basket)) / 100) *
                        parseInt(promocodedata.discountpercentage)
                    ).toFixed(2) / props.basket.length
                  : 0,
              quantity: data.quantity,
              paymentmethod: paymentmode,
              addresstype: value.split("|")[0],
              apartmentname: value.split("|")[1],
              doorno: value.split("|")[2],
              addressline: value.split("|")[3],
              area: value.split("|")[4],
              city: value.split("|")[5],
              state: value.split("|")[6],
              country: value.split("|")[7],
              pincode: value.split("|")[8],
              phoneNumber: user.permanentno,
              paymentId:data.razorpay_payment_id,
              payid:payid,
            })),
          ],
        };
        let res = await axios(config);
        if (res.status === 200 && paymentmode === "online") {
          console.log(res.data);
          setshow(false);
          newdata = res.data.data;

          posttransaction();
        } else if (res.status === 200) {
          console.log(res.data);
          setshow(false);
          props.dispatch({ type: "emptyBasket" });
          alert("Orders Placed Successfully");

          window.location.assign("/retailer");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  console.log(newdata);
  useEffect(() => {
    getdeliveryslot();
    getpickupslot();
  }, []);
  const getdeliveryslot = async () => {
    let res = await axios.get(apiURL + "/getalldeliveryslot");
    if ((res.status = 200)) {
      console.log(res);
      setdeliveryslot(res.data?.deliveryslot);
    }
  };
  const getpickupslot = async () => {
    let res = await axios.get(apiURL + "/getallpickslot");
    if ((res.status = 200)) {
      console.log(res);
      setpickupslot(res.data?.pickslot);
    }
  };

  useEffect(() => {
    getdeliverycharge();
  }, [props.basket]);

  const getdeliverycharge = async () => {
    let res = await axios.get(
      apiURL +
        "/getdeliverycharge/" +
        parseInt(getretailerBasketTotal(props.basket))
    );
    if ((res.status = 200)) {
      console.log(res.data);

      setdeliverycharges(res.data?.deliverycharge);
    }
  };

  console.log(value.split(","));

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const posttransaction = async () => {
    try {
      const config = {
        data: {
          key: "rzp_test_xFpxkjLgSh2gWZ",
          // key: "rzp_live_NSs6yq77ASo8rd",
          amount: `${
            promocodedata !== undefined && deliverycharges.length !== 0
              ? parseFloat(
                  parseFloat(
                    parseFloat(getretailerBasketTotal(props.basket)) -
                      parseFloat(
                        (parseFloat(getretailerBasketTotal(props.basket)) /
                          100) *
                          parseInt(promocodedata.discountpercentage)
                      ).toFixed(2) +
                      parseFloat(deliverycharges[0]?.deliverycharge)
                  ).toFixed(2) * 100
                ).toFixed(2)
              : promocodedata !== undefined
              ? parseFloat(
                  parseFloat(
                    parseFloat(getretailerBasketTotal(props.basket)) -
                      parseFloat(
                        (parseFloat(getretailerBasketTotal(props.basket)) /
                          100) *
                          parseInt(promocodedata.discountpercentage)
                      ).toFixed(2)
                  ).toFixed(2) * 100
                ).toFixed(2)
              : deliverycharges.length !== 0
              ? parseFloat(
                  parseFloat(
                    parseFloat(getretailerBasketTotal(props.basket)) +
                      parseFloat(parseInt(deliverycharges[0]?.deliverycharge))
                  ).toFixed(2) * 100
                ).toFixed(2)
              : parseFloat(getretailerBasketTotal(props.basket) * 100).toFixed(
                  2
                )
          }`,
          currency: "INR",
          name: "Orders",
          description: "IlifeShoppietest",
          image: "/logo.png",
          orderId: newdata._id,
          customerId: user._id,
          customername: user.firmname,
          customeremail: user.email,
          customernumber: user.permanentno,

          handler: async function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id)
            console.log(response);

            if (!response.razorpay_payment_id) {
              deleteorder();
            } else if (response.razorpay_payment_id) {
              sendpaymentid(response);
            }
          },
          prefill: {
            name: user.firmname,
            email: user.email,
            contact: user.permanentno,
          },
          modal: {
            ondismiss: function () {
              console.log("Checkout form closed");
              deleteorder();
            },
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteorder = async () => {
    try {
      axios
        .post(`${apiURL}/deleteorder/` + newdata?._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            alert("Transaction Failed");
            // window.location.reload();
          }
        })
        .catch(function (error) {
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");

      return error;
    }
  };

  const sendpaymentid = async (data) => {
    console.log(data);
    try {
      const config = {
        url: "/transaction/posttransactionorderdetails",
        method: "post",
        baseURL: apiURL,
        data: {
          amount: parseFloat(newdata.total),
          orderId: newdata._id,
          customerId: user._id,
          customername: user.firmname,
          customeremail: user.email,
          paymentdate: moment().format("YYYY-MM-DD hh:mm"),
          customernumber: user.permanentno,
          paymentId: data.razorpay_payment_id,
          type: "retailer",
        },
      };

      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            alert("Transaction Completed");
            props.dispatch({ type: "emptyBasket" });
            window.location.assign("/retailer");
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      // };
    } catch (error) {
      console.log(error);
    }
  };
  const checkpromocode = async () => {
    try {
      axios
        .post(apiURL + "/checkpromocode/" + promocode)
        .then(function (response) {
          if (response.status === 200) {
            setpromocodedata(response.data?.promocode);
          }
        })
        .catch(function (error) {
          setpromocodedata();
          alert("Invalid Code");
        });
    } catch (error) {
      console.warn(error);
      setpromocodedata();
      alert("Invalid Code");
    }
  };

  const checkvoucher = async () => {
    try {
      axios
        .post(apiURL + "/checkvouchercode/" + promocode)
        .then(function (response) {
          if (response.status === 200) {
            setpromocodedata(response.data?.voucher);
          }
        })
        .catch(function (error) {
          setpromocodedata();
          alert("Invalid Code");
        });
    } catch (error) {
      console.warn(error);
      setpromocodedata();
      alert("Invalid Code");
    }
  };
  const checkreferal = async () => {
    try {
      axios
        .post(apiURL + "/checkreferal/" + promocode)
        .then(function (response) {
          if (response.status === 200) {
            setpromocodedata(response.data?.referalcode);
          }
        })
        .catch(function (error) {
          setpromocodedata();
          alert("Invalid Code");
        });
    } catch (error) {
      console.warn(error);
      setpromocodedata();
      alert("Invalid Code");
    }
  };

  return (
    <div>
      <div className="mb-5 container-fluid">
        {/* <Container className="mt-5 pb-5"> */}
        <h3 className="pt-5 ml-5" style={{ color: "#28328c" }}>
          Your Shopping Cart
        </h3>
        <Row className="m-0 p-0">
          {props.basket.length === 0 ? (
            <Col lg={8} className="pt-3 ml-5">
              Cart is empty.{" "}
              <Link to="/retailer/allsubcategorys">Go Shopping</Link>
            </Col>
          ) : (
            <Col lg={8}>
              <Cartproduct />
            </Col>
          )}
          {/* <Col></Col> */}
          <Col lg={3}>
            {props.basket.length === 0 ? (
              <></>
            ) : (
              <>
                <div className="card card-body">
                  <h5>
                    Subtotal ({props.basket.length} items) : Rs.
                    {parseFloat(getretailerBasketTotal(props.basket)).toFixed(
                      2
                    )}
                  </h5>
                  <button
                    style={{ background: "blue", color: "white" }}
                    type="button"
                    onClick={handleshowaddress}
                    className="btn"
                    disabled={props.basket.length === 0}
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </>
            )}
          </Col>
        </Row>
        <Modal show={showaddress} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Shipping Address</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflow: "scroll" }}>
            <div className="container ">
              <div className=" rounded ">
                <div className="p-2 ml-3">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Select Address</FormLabel>

                    <RadioGroup
                      aria-label="address"
                      name="address1"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    >
                      {address?.map((addr) => (
                        <FormControlLabel
                          value={`${addr.addresstype}|${addr.apartmentname}|${addr.doorno}|${addr.addressline}|${addr.area}|${addr.city}|${addr.state}|${addr.country}|${addr.pincode}`}
                          control={<Radio />}
                          label={
                            <>
                              {addr.addresstype === "apartment"
                                ? addr.apartmentname
                                : ""}
                              {addr.doorno},{addr.addressline},{addr.area},
                              {addr.city},{addr.state},{addr.country},
                              {addr.pincode}
                            </>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="mt-2 ">
                  <button
                    className="btn btn-info "
                    onClick={() =>
                      window.location.assign("/retailer/addaddress")
                    }
                  >
                    Add Address
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="form-control" variant="primary" onClick={search}>
              Next
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Payment</Modal.Title>
          </Modal.Header>
          <div className="container pb-5 pt-3">
            <div className="bg-light rounded d-flex flex-column">
              <div className="p-2 ml-3">
                <h4>Order Recap</h4>
              </div>
              <div className="p-2 d-flex">
                <div className="col-8">Total Product Price</div>
                <div className="ml-auto">
                  Rs.
                  {parseFloat(getretailerBasketTotal(props.basket)).toFixed(2)}
                </div>
              </div>
              <div className="p-2 d-flex">
                <div className="col-8">Discount Price</div>
                <div className="ml-auto">
                  -{" "}
                  {promocodedata !== undefined ? (
                    <>
                      Rs.
                      {parseFloat(
                        (parseFloat(getretailerBasketTotal(props.basket)) /
                          100) *
                          parseInt(promocodedata.discountpercentage)
                      ).toFixed(2)}
                    </>
                  ) : (
                    <>Rs.0.00</>
                  )}{" "}
                </div>
              </div>
              {/* <div className="p-2 d-flex">
                <div className="col-8">Tax</div>
                <div className="ml-auto">
                  + Rs.{parseFloat(gettaxtotal(props.basket)).toFixed(2)}
                </div>
              </div> */}
              <div className="p-2 d-flex">
                <div className="col-8">Delivery Charge</div>
                <div className="ml-auto">
                  {" "}
                  {deliverycharges.length !== 0 ? (
                    <>
                      Rs.
                      {parseFloat(
                        parseInt(deliverycharges[0]?.deliverycharge)
                      ).toFixed(2)}
                    </>
                  ) : (
                    <>Rs.0.00</>
                  )}{" "}
                </div>
              </div>
              <div className="border-top px-4 mx-3"> </div>

              <div className="border-top px-4 mx-3"></div>

              <div className="border-top px-4 mx-3"></div>
              <div className="p-2 d-flex pt-3">
                <div className="col-8">
                  <b>Total</b>
                </div>
                <div className="ml-auto">
                  <b class="green">
                    {promocodedata !== undefined &&
                    deliverycharges.length !== 0 ? (
                      <>
                        Rs.
                        {parseFloat(
                          parseFloat(getretailerBasketTotal(props.basket)) -
                            parseFloat(
                              (parseFloat(
                                getretailerBasketTotal(props.basket)
                              ) /
                                100) *
                                parseInt(promocodedata.discountpercentage)
                            ).toFixed(2) +
                            parseFloat(deliverycharges[0]?.deliverycharge)
                        ).toFixed(2)}
                      </>
                    ) : promocodedata !== undefined ? (
                      <>
                        Rs.
                        {parseFloat(
                          parseFloat(getretailerBasketTotal(props.basket)) -
                            (parseFloat(getretailerBasketTotal(props.basket)) /
                              100) *
                              parseInt(promocodedata.discountpercentage)
                        ).toFixed(2)}
                      </>
                    ) : deliverycharges.length !== 0 ? (
                      <>
                        Rs.
                        {parseFloat(
                          parseFloat(getretailerBasketTotal(props.basket)) +
                            parseFloat(
                              parseInt(deliverycharges[0]?.deliverycharge)
                            )
                        ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        Rs.
                        {parseFloat(
                          getretailerBasketTotal(props.basket)
                        ).toFixed(2)}
                      </>
                    )}
                  </b>
                </div>
              </div>
              <div className="ms-3 mt-2 mb-2">
                <h6>
                  Expected Date of Delivery:{" "}
                  <span style={{ color: "red" }}>
                    "
                    {moment()
                      .add(parseInt(pincodedata.expecteddeliverydays), "days")
                      .format("DD-MM-YYYY")}
                    "
                  </span>
                </h6>
              </div>
              <div className="p-2 d-flex">
                <div className="col-8">
                  <b>PaymentMode</b>
                </div>
                <div className="ms-auto">
                  <select
                    className="form-control"
                    placeholder="select Payment Mode"
                    onChange={(e) => setpaymentmode(e.target.value)}
                    style={{ height: "30px", width: "165px", fontSize: "12px" }}
                  >
                    <option value="">Select Payment Mode</option>
                    <option value="COD">Cash On Delivery</option>
                    <option value="online">Online</option>
                  </select>
                </div>
              </div>
              <div className="p-2 d-flex">
                <div className="col-8">
                  <b>Promo Code or Referal Code</b>
                </div>
                <div className="ms-auto">
                  <select
                    className="form-control"
                    placeholder="select Payment Mode"
                    style={{ height: "30px", width: "165px", fontSize: "12px" }}
                    onChange={(e) => setcode(e.target.value)}
                  >
                    <option value={""}>Select Code Type</option>
                    <option value={"promocode"}>Promo Code</option>
                    <option value={"voucher"}>Vouchers</option>
                    <option value={"referalcode"}>Referal Code</option>
                  </select>
                  <input
                    className="form-control mt-2"
                    type="text"
                    placeholder="Enter"
                    onChange={(e) => setpromocode(e.target.value)}
                  ></input>
                  <button
                    className="btn btn-danger mt-1"
                    onClick={() =>
                      code == "promocode"
                        ? checkpromocode()
                        : code == "voucher"
                        ? checkvoucher()
                        : code == "referalcode"
                        ? checkreferal()
                        : alert("Please Select Anyone")
                    }
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              className="form-control"
              variant="primary"
              onClick={postorders}
            >
              Place Order
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Retailercart);
