
import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Container,
  Row,
  InputGroup,
} from "react-bootstrap";
import DatePicker from "react-multi-date-picker";

function Retaileraccount() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const [email, setEmail] = useState(user.email);
  
    const [number, setNumber] = useState("");
    const [name, setName] = useState(user.name);
    const [houseno, sethouseno] = useState(user.doorno);
    // const [housename, sethousename] = useState("");
    const [addressline, setaddressline] = useState(user.addressline);
    // const [addressline2, setaddressline2] = useState("");
    // const [addresstype, setaddresstype] = useState("");
    const [area, setarea] = useState(user.area);
    const [state, setState] = useState(user.state);
    const [City, setCity] = useState(user.city);
    const [country, setcountry] = useState(user.country);
    const [Pincode, setPincode] = useState(user.pincode);
    const [gender, setgender] = useState(user.gender);
    const [apartment, setapartment] = useState(user.addresstype);
    const [apartmentname, setapartmentname] = useState(user.apartmentname);
    const [dob, setdob] = useState(new Date());
    const apiURL = process.env.REACT_APP_API_URL;
    const no = sessionStorage.getItem("phoneno");
  
    console.log(user);
    function ValidateEmail(mail) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          mail
        )
      ) {
        return true;
      }
      alert("You have entered an invalid email address!");
      return false;
    }
  
    function validatename(inputtxt) {
      var phoneno = /^[a-zA-Z ]{2,30}$/;
      // var no = /^\d{10}$/;
      if (inputtxt.match(phoneno)) {
        return true;
      } else {
        alert("You have entered an invalid name!");
        return false;
      }
    }
  
    function validatefirstname(inputtxt) {
      var phoneno = /^[a-zA-Z ]{2,30}$/;
      // var no = /^\d{10}$/;
      if (inputtxt.match(phoneno)) {
        return true;
      } else {
        alert("You have entered an invalid first name!");
        return false;
      }
    }
    function validatelastname(inputtxt) {
      var phoneno = /^[a-zA-Z ]{2,30}$/;
      // var no = /^\d{10}$/;
      if (inputtxt.match(phoneno)) {
        return true;
      } else {
        alert("You have entered an invalid last name!");
        return false;
      }
    }
  
    function phonenumber(inputtxt) {
      var phoneno = /^[6-9]\d{9}$/;
      // var no = /^\d{10}$/;
      if (inputtxt.match(phoneno)) {
        return true;
      } else {
        alert("You have entered an invalid mobile number!");
        return false;
      }
  
      //   var regex = new RegExp("^[0-9\b]+$");
      //   var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      //   console.log(e.target.value.length)
      //   // for 10 digit number only
      //   if (e.target.value.length > 9) {
      //     e.preventDefault();
      //     return e.target.value;
      // }
      //   if (e.charCode < 54 && e.charCode > 47) {
      //       if (e.target.value.length == 0) {
      //           e.preventDefault();
      //           return false;
      //       } else {
      //           return e.target.value;
      //       }
  
      //   }
      //   if (regex.test(str)){
      //       return e.target.value;
      //   }
  
      //   e.preventDefault();
      //   return false;
    }
    console.log(number);
  
    const Onsubmit = async (e) => {
      e.preventDefault();
  
      try {
        if (validatename(name) && ValidateEmail(email)) {
          const config = {
            url: "/customer/updatecustomer",
            method: "post",
            baseURL: apiURL,
            headers: { "content-type": "application/json" },
            data: {
              customerId: user._id,
              name: name,
              email: email,
              phoneNumber: no,
              addresstype: apartment,
              apartmentname: apartmentname,
              doorno: houseno,
              addressline: addressline,
              // addressType:addresstype,
              area: area,
              city: City,
              state: state,
              country: country,
              pincode: Pincode,
              gender: gender,
              dateofbirth: dob,
            },
            //responseType:"application/json"
          };
          let res = await axios(config);
          console.log(res.status);
          if (res.status === 200) {
            console.log(res.data);
            alert("Account Updated");
            sessionStorage.setItem("user", JSON.stringify(res.data.customer));
            window.location.assign("/");
            // sendotp();
            // localStorage.setItem("userdata",JSON.stringify(res.data.user));
            // localStorage.setItem("token",res.data.token);
            // window.location.assign("/signin");
            return res;
          } else {
            alert("Email or Mobile Already Exist");
            console.log(res.data.error);
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    };
  
    return (
      <div className="mt-5" style={{ background: "#F4F4F4" }}>
        <Row className="me-0">
          <Col lg={1} md={1}>
            {" "}
          </Col>
          <Col lg={10} md={10}>
            <Card className="mt-5 mb-5">
              <Card.Body>
                <h3 className="pl-3 pb-4">Profile</h3>
                <Form>
                  <Row>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasic">
                        <Form.Label>
                          Name<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Name"
                          onChange={(e) => setName(e.target.value)}
                          required
                          value={user.firmname}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasic">
                        <Form.Label>
                          Retailer Id<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type=""
                          name="Retailer Id"
                          onChange={(e) => setName(e.target.value)}
                          required
                          value={user._id}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasic">
                        <Form.Label>
                          Email<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          placeholder={user.email}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasic">
                        <Form.Label>
                          Mobile Number<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control type="text" name="Mobileno" value={no} />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasic">
                        <Form.Label>
                          Aadhar Number<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="aadhar"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          value={user.aadharno}
                        />
                      </Form.Group>
                    </Col>

  
                    {/* <Col lg={6} md={6}>
                      <Form.Group controlId="formBasic">
                        <Form.Label>
                          Gender<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="Gender"
                          onChange={(e) => setgender(e.target.value)}
                          required
                        >
                          <option value="">Select the Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Form.Control>
                      </Form.Group>
                    </Col> */}
                    {/* <Col lg={6} md={6}>
                      <Form.Group controlId="formBasic">
                        <Form.Label>
                          Date of Brith<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div>
                          <DatePicker
                            style={{ width: "524px", height: "38px" }}
                            format="DD/MM/YYYY"
                            type="input-icon"
                            maxDate={new Date()}
                            onChange={(date) => setdob(date.format("DD/MM/YYYY"))}
                          />
                        </div>
                      </Form.Group>
                    </Col> */}
                    <Col lg={12} md={12}>
                      <h5 className="pl-2 pb-3">Address</h5>
                    </Col>
                   
                   
                   
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Address<span style={{ color: "red" }}>*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Addressline1"
                          onChange={(e) => setaddressline(e.target.value)}
                          value={user.residentialaddress}
                          required
                        />
                      </Form.Group>
                    </Col>
                   
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          State <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="State"
                          onChange={(e) => setState(e.target.value)}
                          placeholder={user.state}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Country<span style={{ color: "red" }}>*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Country"
                          onChange={(e) => setcountry(e.target.value)}
                          placeholder={user.country}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Pincode<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="pincode"
                          name="Pincode"
                          onChange={(e) => setPincode(e.target.value)}
                          placeholder={user.pincode}
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={12} md={12}>
                      <h5 className="pl-2 pb-3">Accont Details</h5>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Accont number<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="account number"
                          onChange={(e) => setPincode(e.target.value)}
                          placeholder={user.accontno}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Bank Name<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Bank name"
                          onChange={(e) => setPincode(e.target.value)}
                          placeholder={user.bankname}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Bank code<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="bank code"
                          onChange={(e) => setPincode(e.target.value)}
                          placeholder={user.bankname}
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                        Branch<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="Branch"
                          onChange={(e) => setPincode(e.target.value)}
                          placeholder={user.branch}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <br />
                    {/* <Col lg={12} md={12}>
                      <Button
                        className="text-right"
                        variant="success"
                        type="submit"
                        onClick={Onsubmit}
                      >
                        Submit
                      </Button>
                    </Col> */}
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={1} md={1}>
            {" "}
          </Col>
        </Row>
      </div>
    );
  }
  

export default Retaileraccount