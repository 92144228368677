import React, { useRef } from "react";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { useLocation } from "react-router-dom";
import moment from "moment";

function Rinvoice() {
  const contentArea = useRef();
  const location = useLocation();
  const { data } = location.state;
  console.log(data,"data");

  const handleExportWithFunction = (event) => {
    savePDF(contentArea.current, {
      paperSize: "auto",
      fileName: "invoice.pdf",
    });
  };

  return (
    <div className="my-3">
      <div ref={contentArea} className="container ">
        <table
          className="my-3 px-3 table table-bordered"
          cellspacing="0"
          cellpadding="4"
          style={{
            padding: "5px",
            borderSpacing: "15px",
          }}
        >
          <tbody>
            <tr>
              <td rowspan="7" colspan="2">
                <img src="/logo.png" height="40px" alt="" />
                <br></br>
                <b>
                  <i>IlifeShoppie</i>
                </b>
                <p>
                  #562/1 7th Word Kottur Bypass Road,<br></br> Harapanahalli
                  TQ-583131 Ballary Dist
                </p>

                <p>
                  GST IN. <b>29BNFPG3728J2ZR</b>{" "}
                </p>
              </td>
              <td colspan="2">
                <b>GST INVOICE</b>
              </td>
              <td rowspan="7" colspan="2">
                <p>
                  <b>
                    <i>Delivery Address</i>
                  </b>
                </p>
                <label>Name: </label>
                {data.retailer[0].firmname}
                <br />
                <label>Address: </label>
                {data?.addresstype == "apartment"
                  ? data?.apartmentname
                  : ""}, {data?.doorno}, {data?.addressline}, {data?.area},
                {data?.city}, {data?.state}, {data?.country}, {data?.pincode}
                <br />
                <label>Email: </label>
                {data.retailer[0].email} <br />
                <label>Phone: </label>
                {data.retailer[0].permanentno}
                <br />
                <br />
                {data.distributors?.length > 0 ? (
                  <>
                    <div>
                      <b>Seller Details:</b>
                      <b>Sold By</b>
                      <p>
                        <div>Distributor:{data.distributors[0].firmname}</div>
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <b>Seller Details:</b>
                      <p>Sold by IlifeShoppie</p>
                    </div>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Invoice No</td>
              <td>AAA/C/1</td>
            </tr>
            <tr>
              <td>Invoice Date:</td>
              <td>{moment().format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
              <td>Due Date:</td>
              <td>{data.duedatetime}</td>
            </tr>
            <tr>
              <td>Order No:</td>
              <td>{data._id}</td>
            </tr>
            <tr>
              <td>Order Date</td>
              <td>{data.orderdatetime}</td>
            </tr>
            <tr>
              <td>LR No: </td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
        <table
          cellspacing="0"
          cellpadding="4"
          border="1"
          className="my-3 px-3 table table-bordered"
        >
          <thead>
            <tr>
              <th rowspan="1">S No.</th>
              <th rowspan="1">Product</th>
              <th rowspan="1">HSN Code</th>
              <th rowspan="1">MRP</th>
              <th rowspan="1">Offer Price</th>
              <th rowspan="1">Discount(%)</th>
              <th rowspan="1">Code Discount</th>
              <th rowspan="1">Free</th>
              <th rowspan="1">Qty in Units</th>
              <th rowspan="1">SGST</th>
              <th rowspan="1">CGST</th>
              <th rowspan="1">Delivery Charge</th>
              <th rowspan="1">Amount</th>
              <th rowspan="1">Net Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontSize: "12px" }}>
              <td>1</td>
              <td>{data.product[0].productname}</td>
              <td>{data.product[0].hsncode}</td>
              <td>{data.product[0].productprice}</td>
              <td>{data.product[0].retailerofferPrice}</td>
              <td>{data.product[0].retailerdiscountpercentage}</td>
              <td> - {data.promodiscount}</td>
              <td>{data.product[0].free}</td>
              <td>{data.quantity}</td>
              <td>
                {parseFloat(
                  (data.product[0].productprice *
                    data.quantity *
                    data.product[0].tax) /
                    100
                ) / 2}
              </td>
              <td>
                {parseFloat(
                  (data.product[0].productprice *
                    data.quantity *
                    data.product[0].tax) /
                    100
                ) / 2}
              </td>
              <td>{data.deliverycharge}</td>
              <td>
                {parseFloat(data.product[0].retailerofferPrice).toFixed(2) *
                  parseInt(data.quantity) +
                  (data?.deliverycharge ? parseInt(data.deliverycharge) : 0) -
                  parseFloat(data.promodiscount).toFixed(2)}
              </td>
              <td>
                {parseFloat(data.product[0].retailerofferPrice).toFixed(2) *
                  parseInt(data.quantity) +
                  (data?.deliverycharge ? parseInt(data.deliverycharge) : 0) -
                  parseFloat(data.promodiscount).toFixed(2)}
              </td>
            </tr>

            <tr>
              <th colspan="2">Class</th>
              <th colspan="1">Total</th>
              <th colspan="2">Scheme</th>
              <th colspan="2">Discount</th>
              <th colspan="1">SGST</th>
              <th colspan="1">CGST</th>
              <th colspan="1">
                Total GST:{" "}
                {parseFloat(
                  (data.product[0].productprice *
                    data.quantity *
                    data.product[0].tax) /
                    100
                )}
              </th>
              <th colspan="1"></th>
              <th colspan="2">
                Total:{" "}
                {parseFloat(data.product[0].retailerofferPrice).toFixed(2) *
                  parseInt(data.quantity) +
                  (data?.deliverycharge ? parseInt(data.deliverycharge) : 0) -
                  parseFloat(data.promodiscount).toFixed(2)}
              </th>
            </tr>
            <tr>
              <td colspan="9">Payment Mode: {data.paymentmethod}</td>
              <td rowspan="3" colspan="4">
                Delivery:{" "}
                {data?.deliverycharge ? <>{data.deliverycharge}</> : "0"}
                <br />
                Code Discount: -{" "}
                {data?.promodiscount ? <>{data.promodiscount}</> : "0"}
                <br />
                <b>
                  AMOUNT:{" "}
                  {parseFloat(data.product[0].retailerofferPrice).toFixed(2) *
                    parseInt(data.quantity) +
                    (data?.deliverycharge ? parseInt(data.deliverycharge) : 0) -
                    parseFloat(data.promodiscount).toFixed(2)}
                </b>
                <br />
                SGST PAYBLE:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {parseFloat(
                  (data.product[0].productprice *
                    data.quantity *
                    data.product[0].tax) /
                    100
                ) / 2}
                <br />
                CGST PAYBLE:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {parseFloat(
                  (data.product[0].productprice *
                    data.quantity *
                    data.product[0].tax) /
                    100
                ) / 2}
                <br />
              </td>
            </tr>
            <tr>
              <td colspan="9">Message</td>
            </tr>
          </tbody>
        </table>

        <table
          cellspacing="0"
          cellpadding="4"
          border="1"
          className="table table-bordered"
        >
          <tbody>
            <tr>
              <td colspan="2" style={{ fontSize: "14px" }}>
                <b>Terms & Conditions</b>
                <br />
                <br />
                All disputes subject to BANGALORE jurisdiction only
                <br />
                for conditions refer www.IlifeShoppie.com
              </td>
              <td colspan="2">
                <b>For IlifeShoppie </b>
                <br />
                <br />
                <br />
                <br />
                Authorised Signatory
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-right me-5">
        <button
          className="btn btn-outline-success mr-5 mb-5"
          onClick={handleExportWithFunction}
        >
          Save Pdf
        </button>
      </div>
    </div>
  );
}

export default Rinvoice;
