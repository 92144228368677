import React from 'react'

function Thanku() {
  return (
   <>
   <section className="common-use">
    <div className='te-09' style={{
        position:"absolute",
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)"
    }}>
      <div
        className="banner-content text-center pt-3 pb-4"
        style={{ fontSize: "30px" }}
      >
        <h2 style={{fontWeight:"600",color:"#3d459a"}}>Thank You !</h2>
        <h3>We will get back to you soon.</h3>
      </div>
      </div>
   </section>
   </>
  )
}

export default Thanku