import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import AddIcon from "@material-ui/icons/Add";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { Link } from "react-router-dom";

function Topdeals(props) {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [topdeals, settopdeals] = useState([]);
  const [banner, setbanner] = useState([]);

  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log(topdeals);
  useEffect(() => {
    getbanner();
  }, []);
  useEffect(() => {
    gettopdeals();
  }, []);

  const getbanner = async () => {
    let res = await axios.get(apiURL + "/getallbanner/retailer");
    if ((res.status = 200)) {
      console.log(res);
      setbanner(res.data?.banner);
    }
  };
  const gettopdeals = async () => {
    let res = await axios.get(apiURL + "/distributor/allproduct");
    if ((res.status = 200)) {
      console.log(res);
      settopdeals(res.data?.product);
    }
  };
  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  };
  const addtocart = (e) => {
    if (!user) {
      alert("please login");
    } else {
      props.dispatch({
        type: "addBasketItem",
        item: { product: e, quantity: 1 },
      });
    }
  };

  return (
    <div>
      <div className="category-container mb-5">
        {/* <h3 className="py-3">Best Deals on Products</h3>
        <OwlCarousel
          className="owl-theme topdeals-carousel"
          loop
          margin={10}
          nav={true}
          // items={5}
          responsive={catresponsive}
          autoplay={true}
          autoHeightClass="owl-height"
        >
          {topdeals.map((e) => (
            <div
              class="item"
              onClick={() =>
                props.dispatch({
                  type: "Subscribe",
                  item: { product: e, quantity: 1 },
                })
              }
            >
              <Link
                to={{
                  pathname: "/retailer/productdetails",
                  item: { product: topdeals },
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={`${imageURL}/retailer/product/${e.productimage[0]}`}
                  alt=""
                ></img>
                <h6>{e.productname}</h6>
                <p>
                  <b>₹ {e.customerofferPrice} </b>{" "}
                  <span
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }}
                  >
                    M.R.P: ₹ {e.productprice}
                  </span>
                </p>
              </Link>

              <Button
                variant="contained"
                className="cart-btn"
                endIcon={<AddIcon />}
                onClick={() => addtocart(e)}
              >
                Add to cart
              </Button>
            </div>
          ))}
        </OwlCarousel> */}
      </div>
      <div className="custom-offer mb-2">
        <div className="container">
        <OwlCarousel
          className="owl-theme topdeals-carousel"
        
          loop= {true}
         
          margin={10}
          navClass
          responsive={catresponsive }

          autoPlay={true}
          autoplayTimeout={3000}
          autoHeightClass="owl-height"
        >
          {/* <Row xs={1} md={2}> */}
            {banner.map((e) => (
              <Col>
                <Card>
                  <Card.Img
                    variant="top"
                    // style={{ height: "380px" }}
                    src={`${imageURL}/banner/${e.banner}`}
                  ></Card.Img>
                </Card>
              </Col>
            ))}
          {/* </Row> */}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

export default Topdeals;
