import React from "react";
import { Container } from "react-bootstrap";

function Paymentpolicy() {
  return (
    <div className="mt-4 p-5">
      <Container>
        <p>
          <b>
            ALL RETURNS, EXCHANGE AND CANCELLATION OF PRODUCTS ARE SUBJECT TO
            MANUFACTURER&#39;S WARRANTIES AND THE INOVICE TERMS AND CONDITIONS.
          </b>
        </p>
        <p>
          <b>1) Delivery/Shipping Policy: </b>  Delivery Timelines: We will make
          every effort to get your order delivered to you as per the scheduled
          date/time
        </p>
        <p>
          i. The products ordered by You shall be available for delivery as per
          the delivery schedule during the delivery schedule If you fail to take
          delivery on the agreed slot as mentioned in (a) above, your order and
          delivery thereof shall be treated as cancelled and the delivery
          charges will be debited and balance will be credited back to your
          account.
        </p>
        <p>
          ii. At the time of taking delivery of the products, You shall quote
          the PIN No. at the Delivery Point or Delivery Partner
        </p>
        <p>
          iii. The person at the Delivery Point or Delivery Partner authorized
          to deliver the product may decline to deliver the product if he finds
          any inconsistency as regards Order No. / PIN No or any other detail of
          the order
        </p>
        <p>
          iv. Subject to availability of the ordered product at the Delivery
          Point or Delivery Partner on the delivery day, ilife Shoppie
          Enterprises shall deliver the product to You as per schedule, failing
          which ilife Shoppie Enterprises shall inform You with a revised
          schedule for delivery of the Product. You may ask for
          cancellation/modification of Order on account of this.
        </p>
        <p>
          v. It is your responsibility to count and check all the products while
          accepting the Products as per your Order. No dispute will be
          entertained by ilife Shoppie Enterprises once you accept the products
          at the Delivery Point or Delivery Partner.
        </p>
        <p>
          vi. You shall accept the product without demur in the following
          circumstances:
        </p>
        <p>
          a. If the products delivered to you are in accordance with your order,
          or
        </p>
        <p>
          b. Loose Fruits and Vegetables with minor weight variation due to
          moisture loss We request you to check all the Products at the time of
          delivery. Complaint pertaining to short weight will not be
          entertained, if the complaint is not lodged immediately on delivery.
        </p>
        <p>
          <b>2) Shipping Charges:</b>
        </p>
        <p>
          Delivery charge will be leviable for each delivery. However ilife
          Shoppie Enterprises may run certain promotions / offers or prescribe
          minimum order value for each transaction to waive the delivery charges
          or apply discount on delivery charges
        </p>
        <p>
          <b>3) Order Tracking:</b>
        </p>
        <p>
          Once your order has been dispatched from our delivery point, an email
          or an SMS will be sent to you on the email address and mobile number
          provided by you under &quot;Billing Address&quot; and Order placed
          with Us. You can track your order online by the ID Tracking number
          sent to you by us on your Mobile/Email ID.
        </p>
        <p>
          You may also log on to your account on the website and application or
          use the &quot;Track my Order&quot; link on the website and application
          to track your order.
        </p>
        <p>
          <b>4) Order-Splitting:</b>
        </p>
        <p>
          Sometimes we may not be in a position to deliver an order to a
          particular location due to the nature of the product, its availability
          or due to the service limitations of our courier and logistic
          partners. In such cases, you may receive the items (of a single order)
          at different points of time or through different courier partners. The
          status of all items within an order can be tracked online.
        </p>
        <p>
          <b>5) Delivery Locations:</b>
        </p>
        <p>
          We currently deliver orders to selected pin codes/areas across a city
          within India only. In the app itself while ordering will confirm the
          delivery availability and non availability of service of your area.
          You may also check the delivery serviceability of your area/locality
          with our customer care/support team 08105881733.
        </p>
      </Container>
    </div>
  );
}

export default Paymentpolicy;
