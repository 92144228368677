const Fakedata =[
    {
        id:"1",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/1.jpg",
        discount:"59%",
        categories:"Fruits"
    },
    {
        id:"4",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/2.jpg",
        categories:"Vegetables",
        
    },
    {
        id:"2",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/3.jpg",
        discount:"69%",
        categories:"Fastfood",
    },
    {
        id:"5",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/4.jpg",
        categories:"Fruits",
    },
    {
        id:"6",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/5.jpg", discount:"29%", categories:"Vegetables",
    },
    {
        id:"7",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/6.jpg",
        categories:"Fruits"
    },
    {
        id:"8",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/7.jpg",
        discount:"57%",
        categories:"Vegetables",
    },
    {
        id:"9",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/8.jpg", discount:"33%", categories:"Fastfood"
    },
    {
        id:"10",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/9.jpg", categories:"Fruits"
    },
    {
        id:"11",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/10.jpg",
        discount:"12%",
        categories:"Fastfood",
    },
    {
        id:"12",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/11.jpg",
        categories:"Fruits",
    },
    {
        id:"13",
        name:"Fruits banana 100% organic",
        des:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        rating:"3.4",
        price:"550",
        availability:"In stock",
        image1:"/webImages/12.jpg",
        categories:"Fastfood",
    }
]
export default Fakedata 