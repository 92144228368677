import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { MdGraphicEq } from 'react-icons/md';

function Sidebar() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  console.log(user);
  return (
    <div>
      <ProSidebar width="250px">
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem>
              Dashboard<Link to="dashboard"></Link>{" "}
            </MenuItem>
            {user?.subadmin === true ? (
              <SubMenu title="Sub-Admin">
                <MenuItem>
                  Add Sub-Admin<Link to="addsubadmin"></Link>
                </MenuItem>
                <MenuItem>
                  Sub-Admin<Link to="subadmin"></Link>
                </MenuItem>
              </SubMenu>
            ) : (
              ""
            )}
               <SubMenu title="Graphics">
              {user?.customergraphics === true ? (
                <>
                  <MenuItem>
                    Customer Slider<Link to="customerslider"></Link>
                  </MenuItem>
                  <MenuItem>
                    Customer Banner <Link to="customerbanner"></Link>
                  </MenuItem>
                  <MenuItem>
                    Customer Offer
                    <Link to="customerdailyessentials"></Link>
                  </MenuItem>
                </>
              ) : (
                ""
              )}
              {user?.distributorgraphics === true ? (
                <>
                  <MenuItem>
                  Distributor Slider<Link to="distributorslider"></Link>
                  </MenuItem>
                  <MenuItem>
                    Distributor Banner<Link to="distributorbanner"></Link>
                  </MenuItem>
                  <MenuItem>
                    Distributor Offer
                    <Link to="distributordailyessentials"></Link>
                  </MenuItem>
                </>
              ) : (
                ""
              )}
              {user?.retailergraphics === true ? (
                <>
                  {" "}
                  <MenuItem>
                    Retailer Slider<Link to="retailerslider"></Link>
                  </MenuItem>
                  <MenuItem>
                    Retailer Banner<Link to="retailerbanner"></Link>
                  </MenuItem>
                  <MenuItem>
                    Retailer Offer
                    <Link to="Retailerdailyessentials"></Link>
                  </MenuItem>
                </>
              ) : (
                ""
              )}
            </SubMenu>
            <SubMenu title="Channel Partners">
              {user?.customer === true ? (
                <>
                  <MenuItem>
                    Customer <Link to="customer"></Link>
                  </MenuItem>
                  <SubMenu title="Distributor">
                    <MenuItem>
                      Add Distributor <Link to="notapproveddistributor"></Link>
                    </MenuItem>
                    <MenuItem>
                      Distributors <Link to="approveddistributor"></Link>
                    </MenuItem>
                  </SubMenu>
                </>
              ) : (
                ""
              )}
              {user?.retailer === true ? (
                
                  // <MenuItem>
                  //   Retailers <Link to="approvedretailer"></Link>
                  // </MenuItem>
                  <SubMenu title="Retailers">
                      {/* <MenuItem>
                    Not Approved <Link to="notapprovedretailer"></Link>
                  </MenuItem> */}
                  <MenuItem>
                    Not Approved <Link to="notapprovedretailer1"></Link>
                  </MenuItem>
                    <MenuItem>
                    Approved <Link to="approvedretailer"></Link>
                  </MenuItem>
                  {/* <MenuItem>
                    Approved <Link to="approvedtransporter"></Link>
                  </MenuItem> */}
                </SubMenu>
               
              ) : (
                ""
              )}
              {user?.transporter === true ? (
                <SubMenu title="Employee">
                  <MenuItem>
                    Employee List <Link to="notapprovedtransporter"></Link>
                  </MenuItem>
                  <MenuItem>
                    Distributor Employee <Link to="approvedtransporter"></Link>
                  </MenuItem>
                </SubMenu>
              ) : (
                ""
              )}
            </SubMenu>
            {user?.product === true ? (
              <SubMenu title="Products">
                <MenuItem>
                  Category<Link to="category"></Link>
                </MenuItem>
                <MenuItem>
                  Brand<Link to="subcategory"></Link>
                </MenuItem>
                {/* <MenuItem>
                  Add Volume With UOM<Link to="addvolume"></Link>
                </MenuItem> */}
                <MenuItem>
                  Add HSN Code<Link to="addhsncode"></Link>
                </MenuItem>
                <MenuItem>
                  Products<Link to="products"></Link>
                </MenuItem>
                {/* <MenuItem>
                  Distributor Products<Link to="adproductsview"></Link>
                </MenuItem>
                <MenuItem>
                  Retailer Products<Link to="arproductsview"></Link>
                </MenuItem> */}
                <MenuItem>
                  Delivery Charge<Link to="deliverycharge"></Link>
                </MenuItem>
                {/* <MenuItem>
                  Delivery Slot<Link to="deliveryslot"></Link>
                </MenuItem>
                <MenuItem>
                  Pickup Slot<Link to="pickslot"></Link>
                </MenuItem> */}
                <MenuItem>
                  Pincode Block<Link to="pincodeblock"></Link>
                </MenuItem>
                <MenuItem>
                  Pincode<Link to="pincode"></Link>
                </MenuItem>
                <MenuItem>
                  Invoice<Link to="bulkinvoice"></Link>
                </MenuItem>
              </SubMenu>
            ) : ( 
    ""
            )}
                 <MenuItem>
              Stock<Link to="stock"></Link>
            </MenuItem>
            <SubMenu title="Orders">
              {user?.customerorder === true ? (
                <MenuItem>
                  Customer Orders<Link to="customerorders"></Link>
                </MenuItem>
              ) : (
                ""
              )}
              {user?.distributororder === true ? (
                <MenuItem>
                  Distributor Orders<Link to="distributororders"></Link>
                </MenuItem>
              ) : (
                ""
              )}
              {user?.retailerorder === true ? (
                <MenuItem>
                  Retailer Orders<Link to="retailerorders"></Link>
                </MenuItem>
              ) : (
                ""
              )}
              {user?.localshoporder === true ? (
                <MenuItem>
                  Localshop Orders<Link to="localshoporders"></Link>
                </MenuItem>
              ) : (
                ""
              )}
              {user?.distributorretailerorder === true ? (
                <MenuItem>
                  Distributor Retailer Orders
                  <Link to="distributorretailerorders"></Link>
                </MenuItem>
              ) : (
                ""
              )}
              {user?.returnorder === true ? (
                <>
                  <MenuItem>
                    Customer Return Orders<Link to="returnorders"></Link>
                  </MenuItem>
                  <MenuItem>
                    Retailer Return Orders<Link to="rreturnorder"></Link>
                  </MenuItem>
                  <MenuItem>
                    Distributor Return Orders<Link to="dreturnorder"></Link>
                  </MenuItem>
                </>
              ) : (
                ""
              )}
            </SubMenu>
           
            <SubMenu title="Wallet Channel Partners">
              {user?.customer === true ? (
                <>
                  <MenuItem>
                  <Link to="/admin/wallet"> Wallet </Link>
                  </MenuItem>
                  <MenuItem>
                    Customer <Link to="walletcustomer"></Link>
                  </MenuItem>
                  <MenuItem>
                    Distributor <Link to="walletdistributor"></Link>
                  </MenuItem>
                  {/* <SubMenu title="Distributor">
                    <MenuItem>
                      Add Distributor <Link to="notapproveddistributor"></Link>
                    </MenuItem>
                    <MenuItem>
                      Distributors <Link to="approveddistributor"></Link>
                    </MenuItem>
                  </SubMenu> */}
                  <MenuItem>
                    Retailer <Link to="walletretailer"></Link>
                  </MenuItem>
                </>
              ) : (
                ""
              )}
       
            </SubMenu>
            {user?.promocode === true ? (
              <MenuItem>
                Promo Code<Link to="promocode"></Link>
              </MenuItem>
            ) : (
              ""
            )}
            {/* {user?.vouchercode === true ? (
              <MenuItem>
                Voucher Code<Link to="voucher"></Link>
              </MenuItem>
            ) : (
              ""
            )} */}
            {user?.referalcode === true ? (
              <MenuItem>
                Referal Code<Link to="referalcode"></Link>
              </MenuItem>
            ) : (
              ""
            )}
            <SubMenu title="Expense">
              {user?.expense === true ? (
                <>
                  <MenuItem>
                    Expense Type<Link to="expensetype"></Link>
                  </MenuItem>
                  <MenuItem>
                    Expense<Link to="expense"></Link>
                  </MenuItem>
                </>
              ) : (
                ""
              )}
            </SubMenu>
            {/* {user?.transactions === true ? (
              <MenuItem>
                Transaction details<Link to="transaction"></Link>
              </MenuItem>
            ) : (
              ""
            )} */}
            {user?.creditdebitnote === true ? (
              <MenuItem>
                Sales History<Link to="sales-history"></Link>
              </MenuItem>
            ) : (
              ""
            )}
             {user?.creditdebitnote === true ? (
              <MenuItem>
                Cash History<Link to="cash-history"></Link>
              </MenuItem>
            ) : (
              ""
            )}
             {user?.creditdebitnote === true ? (
              <MenuItem>
                Account History<Link to="account-history"></Link>
              </MenuItem>
            ) : (
              ""
            )}


      

            
            <MenuItem>
              Account Statement<Link to="accountstatement"></Link>
            </MenuItem>
            <MenuItem>
              Manage Notification<Link to="adminnotification"></Link>
            </MenuItem>
            <MenuItem>
              Top Retailer<Link to="topretailer"></Link>
            </MenuItem>
            <MenuItem>
              Top Distributor<Link to="topdistributor"></Link>
            </MenuItem>
            <MenuItem>
              Partner Request<Link to="partner-request"></Link>
            </MenuItem>
            <MenuItem>
              Feedback<Link to="feedback"></Link>
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          {/**
           *  You can add a footer for the sidebar ex: copyright
           */}

          <div style={{ height: "200px" }}></div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}

export default Sidebar;
