import React, { useState } from "react";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";

function DistributorOtp() {
  const [otp, setOtp] = useState("");

  return (
    <>
      <div className="hero__0 text-center">
        <div class="section-title">
          <h2>Enter Verification Code</h2>
        </div>
        <div
          className="mt-3 mb-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <OtpInput
            className="o_t_p"
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div className="form-group">
          <Link to="/distributor/distributor-home">
            <Button variant="success">Verify otp</Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default DistributorOtp;
