import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import { Row, Col, Badge } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import Category from "./Category";

function Localshopproducts(props) {
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [product, setproduct] = useState([]);

  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let res = await axios.get(
      apiurl + "/retailer/retailerproduct/" + props.localshop.localshop._id
    );
    if ((res.status = 200)) {
      console.log(res.data);
      setproduct(res.data?.retailerproducts);
      console.log(res.data?.retailerproducts,"pro");

    }
  };
console.log("sfdfgsafd",product);
  return (
    <div>
      <div></div>
      <div className="container mt-4">
        <Row xs={1} md={4} className="g-4">
          {product?.map((data) => (
            <Col>
              <Card>
                <Button
                  component={Link}
                  to="/productdetails"
                  onClick={() =>
                    props.dispatch({
                      type: "Subscribe",
                      item: { product: data, quantity: 1 },
                    })
                  }
                >
                  <Card.Img
                    variant="top"
                    src={imageURL + "/admin/product/" + data.productimage[0]}
                  />
                  {/* {data.type=="admin" ? <Card.Img variant="top" src={imageURL+ "/retailer/product/"  + data.productimage[0]} /> : <Card.Img variant="top" src={imageURL+ "/vendor/product/"  + data.productimage[0]} />} */}
                </Button>
                <Card.Body>
                  <Card.Title>
                    {data.productname.length > 50 ? (
                      <>{`${data.productname.substring(0, 40)}...`}</>
                    ) : (
                      data.productname
                    )}
                  </Card.Title>
                  <Card.Text>
                    <p>
                      <b>₹ {data.customerofferPrice} </b>{" "}
                      <span
                        style={{
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                        }}
                      >
                        M.R.P: ₹ {data.productprice}
                      </span>
                    </p>
                    <div className="mb-2">
                      {" "}
                      <Badge
                        style={{ backgroundColor: "yellow", color: "black" }}
                      >
                        Save
                      </Badge>{" "}
                      <large>{data.customerdiscountpercentage}% Off</large>{" "}
                    </div>
                    {parseInt(data.totalstock) !== 0 &&
                    parseInt(data.remainingstock) >= 2 ? (
                      <Button
                        className="mt-2 mb-4"
                        variant="outlined"
                        color="danger"
                        onClick={() => {
                          props.dispatch({
                            type: "addBasketItem",
                            item: { product: data, quantity: 1 },
                          });
                          alert(
                            `Product is Added to cart ${
                              !user ? "please Login to checkout" : ""
                            }`
                          );
                        }}
                      >
                        Add to cart
                      </Button>
                    ) : parseInt(data.totalstock) !== 0 &&
                      parseInt(data.remainingstock) === 0 ? (
                      <Button
                        className="mt-2 mb-4"
                        variant="outlined"
                        color="danger"
                        onClick={() => {
                          props.dispatch({
                            type: "addBasketItem",
                            item: { product: data, quantity: 1 },
                          });
                          alert(
                            `Product is Added to cart ${
                              !user ? "please Login to checkout" : ""
                            }`
                          );
                        }}
                      >
                        Add to cart
                      </Button>
                    ) : (
                      <div style={{ color: "red" }}>Out of Stock</div>
                    )}
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        props.dispatch({
                          type: "addBasketItem",
                          item: { product: data, quantity: 1 },
                        });
                        alert("Product iS added To cart");
                      }}
                    >
                      Add to Cart
                    </Button> */}
                    {/* {data.subscriptation ? (
                      <Button variant="contained" color="primary">
                        Subscribe
                      </Button>
                    ) : (
                      ""
                    )} */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    localshop: state.localshop,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Localshopproducts);
