import axios from "axios";
import { Col, Button, Form, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-multi-date-picker";

function Feedback() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [pincode, setpincode] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [state, setstate] = useState("");
  const [doorno, setdoorno] = useState("");
  const [dateofbirth, setdateofbirth] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [area, setarea] = useState("");
  const [addresstype, setaddresstype] = useState("");
  const [addressline, setaddressline] = useState("");
  const [apartmentname, setapartmentname] = useState("");

  const [selected, setselected] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  useEffect(() => {
    getcustomer();
  }, []);
  const editprofile = async () => {
    try {
      const config = {
        url: "/customer/updatecustomer",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: {
          customerId: selected._id,
          email: email ? email : selected.email,
          name: name ? name : selected.name,
          state: state ? state : selected.state,
          city: city ? city : selected.city,
          pincode: pincode ? pincode : selected.pincode,
          dateofbirth: dateofbirth ? dateofbirth : selected.dateofbirth,
          doorno: doorno ? doorno : selected.doorno,
          country: country ? country : selected.country,
          area: area ? area : selected.area,
          addressline: addressline ? addressline : selected.addressline,
          apartmentname: apartmentname ? apartmentname : selected.apartmentname,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("details has been edited successfully");
        window.location.reload();
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getcustomer = () => {
    try {
      axios
        .get(`${apiURL}/customer/getallcustomer`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata(response.data.customer);
            return;
          } else {
            alert("Can't able to fetch ");

            return;
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");

      return error;
    }
  };

  const block = async (data) => {
    try {
      axios
        .post(`${apiURL}/blockcustomer/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .post(`${apiURL}/unblockcustomer/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/customers/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      // dataField: "cid",
      text: "Sl No",
      sort: true,
    },
    {
      // dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      // dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      // dataField: "phoneNumber",
      text: "Mobile No",
    },
    {
        // dataField: "phoneNumber",
        text: "Who you are",
      },

    {
      // dataField: "addressline",
      text: "Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row?.addresstype == "apartment" ? row?.apartmentname : ""},{" "}
            {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
            {row?.state}, {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    // {
    // //   dataField: "",
    //   text: "Edit",
    //   formatter: (cell, row) => {
    //     console.log(row);
    //     return (
    //       <div>
    //         <button
    //           className="btn btn-outline-success"
    //           onClick={() => {
    //             setselected(row);
    //             showModal1();
    //           }}
    //         >
    //           Edit
    //         </button>
    //       </div>
    //     );
    //   },
    // },
    {
        // dataField: "",
        text: "Action",
        formatter: (cell, row) => {
          console.log(row);
          return (
            <div>
              <button className="btn btn-danger">
                Delete
              </button>
            </div>
          );
        },
      },
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Feedback
        </h1>
        <div className="container">
          <div className="card card-body">
            <Modal
              style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
              show={isOpen1}
              onHide={hideModal1}
              animation={false}
            >
              <Modal.Header closeButton className="mt-4">
                <Modal.Title>Edit Profile </Modal.Title>
              </Modal.Header>

              <div className="ms-2 me-2">
                <h5>Id Proofs</h5>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">Email</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => setemail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setname(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBasic">
                  <Form.Label>Date of Brith</Form.Label>
                  <div>
                    <DatePicker
                      style={{ width: "470px", height: "38px" }}
                      format="DD/MM/YYYY"
                      type="input-icon"
                      maxDate={new Date()}
                      onChange={(date) =>
                        setdateofbirth(date.format("DD/MM/YYYY"))
                      }
                    />
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasic">
                  <Form.Label>Apartment</Form.Label>
                  <Form.Control
                    as="select"
                    name="apartment"
                    onChange={(e) => setaddresstype(e.target.value)}
                    required
                  >
                    <option value="">Select the type</option>
                    <option value="house">Individual House</option>
                    <option value="apartment">Apartment</option>
                  </Form.Control>
                </Form.Group>
                {addresstype == "apartment" ? (
                  <Col lg={6} md={6}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Apartment Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={(e) => setapartmentname(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  " "
                )}
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Door No</Form.Label>
                  <Form.Control
                    type="text"
                    name="Houseno"
                    onChange={(e) => setdoorno(e.target.value)}
                    placeholder="Enter House Number"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Address </Form.Label>
                  <Form.Control
                    type="text"
                    name="Addressline1"
                    onChange={(e) => setaddressline(e.target.value)}
                    placeholder="Enter Address"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Area</Form.Label>
                  <Form.Control
                    type="text"
                    name="Area"
                    onChange={(e) => setarea(e.target.value)}
                    placeholder="Enter Area"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>City </Form.Label>
                  <Form.Control
                    type="text"
                    name="City"
                    onChange={(e) => setcity(e.target.value)}
                    placeholder="Enter City"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="State"
                    onChange={(e) => setstate(e.target.value)}
                    placeholder="Enter State"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Country </Form.Label>
                  <Form.Control
                    type="text"
                    name="Country"
                    onChange={(e) => setcountry(e.target.value)}
                    placeholder="Enter Country"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="pincode"
                    name="Pincode"
                    onChange={(e) => setpincode(e.target.value)}
                    placeholder="Enter pincode"
                    required
                  />
                </Form.Group>
              </div>
              <Modal.Footer>
                <Button variant="success" onClick={hideModal1}>
                  Close
                </Button>
                <Button variant="danger" onClick={() => editprofile()}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              // exportCSV={{
              //   fileName: "customer.csv",
              //   blobType: "text/csv;charset=ansi",
              // }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="customer"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
