import React, { useState } from "react";
import "./DistributorCheckout.css";
import { IoIosClose } from "react-icons/io";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Input, Radio, Space } from "antd";
import { MdAdd } from "react-icons/md";
import Table from "react-bootstrap/Table";
import useRazorpay from "react-razorpay";
import { useEffect } from "react";
import axios from "axios";

function Checkout() {
  const [value, setValue] = useState();
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const [value1, setValue1] = useState(1);
  const onChange1 = (e) => {
    console.log("radio checked", e.target.value);
    setValue1(e.target.value);
  };
  const [changes, setchanges] = useState(true);
  const [upi, setupi] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [address, setaddress] = useState([]);
  const [showAddress, setshowAress] = useState({});
  const [edit, setedit] = useState({});
  //   const [Showaddress1, setShowaddress1] = useState({});

  const distributor = JSON.parse(sessionStorage.getItem("distributor"));
  /*====================================================================================================*/
  // All distributor addresses added...
  let getAddress = async () => {
    try {
      let res = await axios.get(
        "https://spilso.in/api/distributor/address/" + distributor?._id
      );
      if (res.status === 200) {
        setaddress(res.data?.distributoraddress);
        if (res.data?.distributoraddress?.length > 0) {
          setshowAress(res.data?.distributoraddress[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*====================================================================================================*/
  //   add address of a distributor ...

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [pincode, setpincode] = useState("");
  const [House, setHouse] = useState("");
  const [Area, setArea] = useState("");
  const [Landmark, setLandmark] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");

  const addAdress = async () => {
    try {
      const config = {
        url: "/distributor/addaddress",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: {
          distributorId: distributor?._id,
          name: name,
          number: mobile,
          addresstype: "Home",
          doorno: House,
          addressline: Landmark,
          area: Area,
          city: city,
          state: state,
          country: country,
          pincode: pincode,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Successfully added");
          handleClose1();
          getAddress();
          setname("");
          setmobile("");
          setpincode("");
          setHouse("");
          setArea("");
          setLandmark("");
          setcity("");
          setstate("");
          setcountry("");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  /*====================================================================================================*/
  // get distributor's cart items
  const [Cartitems, setCartitems] = useState([]);
  const [allCartData, setAllCartData] = useState([]);
  const getDistributorsCartItems = async () => {
    try {
      const res = await axios.get(
        `https://spilso.in/api/getAllCartProductsByDistributor/${distributor._id}`
      );
      if (res.status === 200) {
        setCartitems(res.data.success);
        setAllCartData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // remove all the products from the cart after placing the order...
  const deleteCart = async () => {
    try {
      let res = await axios.delete(
        "https://spilso.in/api/DeleteAllDistributorsCartProducts/" +
          distributor?._id
      );
      if (res.status === 200) {
        console.log("rss", res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*====================================================================================================*/
  // get Coupon and applied Coupon
  const [coupon, setCupon] = useState({});
  const [coupondis, setCoupondis] = useState(0);
  const [inputCupon, setInputCupon] = useState("");

  const getCoupon = async () => {
    try {
      const config = {
        url: "/getCouponBycode",
        method: "put",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: {
          couponCode: inputCupon,
          userId: distributor?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setCupon(res.data.success);
        setCoupondis(res.data.success.doscount);
        alert("Successfully applied");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const appliedCoupon = async () => {
    try {
      const config = {
        url: "/applyCoupon",
        method: "put",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: {
          couponCode: inputCupon,
          userId: distributor?._id,
          userName: distributor?.name,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setCoupondis("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*====================================================================================================*/

  useEffect(() => {
    if (!distributor) {
      alert("please login...");
      window.location.assign("/distributor/login");
    }
    getAddress();
    getDistributorsCartItems();
  }, []);

  let subtotal = 0;
  let total = 0;
  let discount = 0;
  let tax = 0;
  if (Cartitems?.length !== 0) {
    for (let i = 0; i < Cartitems.length; i++) {
      subtotal =
        subtotal +
        (Cartitems[i]?.totalPrice -
          Math.round(
            Number(Cartitems[i]?.price * Cartitems[i]?.quantity) *
              (Cartitems[i]?.productId?.tax / 100)
          ));
      total = total + Cartitems[i]?.totalPrice;
      tax =
        tax +
        Math.round(
          Number(Cartitems[i]?.price * Cartitems[i]?.quantity) *
            (Cartitems[i]?.productId?.tax / 100)
        );
      discount =
        discount +
        Math.round(
          Number(Cartitems[i]?.price * Cartitems[i]?.quantity) +
            Math.round(
              Cartitems[i]?.price *
                Cartitems[i]?.quantity *
                (Cartitems[i]?.productId?.tax / 100)
            ) -
            Cartitems[i]?.totalPrice
        );
    }
  }

  /*====================================================================================================*/

  //paymen and -place order
  const Razorpay = useRazorpay();

  const [checkradio, setcheckradio] = useState("");

  const [paymentmethod, setpaymentmethod] = useState("");
  const [payid, setpayid] = useState("");
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const d = new Date();
  const placeorder = async () => {
    if (Object.keys(showAddress).length) {
      if (!paymentmethod) {
        return alert("Please select the Payment method");
      } else
        try {
          const config = {
            url: "/customer/addorders",
            method: "post",
            baseURL: "https://spilso.in/api",
            headers: { "content-type": "application/json" },
            data: {
              distributorId: distributor?._id,
              distributorOrderdatetime: d,
              orderBy: "DISTRIBUTOR",
              addresstype: "Home",
              paymentmethod: paymentmethod,
              name: showAddress?.name,
              number: showAddress?.number,
              doorno: showAddress?.doorno,
              area: showAddress?.area,
              addressline: showAddress?.addressline,
              city: showAddress?.city,
              state: showAddress?.state,
              country: showAddress?.country,
              pincode: showAddress?.pincode,
              payid: payid,
              subTotal: subtotal,
              allTotal:
                total + 30 - Math.round((total + 30) * (coupondis / 100)),
              tax: tax,
              couponDiscount: coupondis,
              couponId: coupon?._id,
              couponPr: Math.round((total + 30) * (coupondis / 100)),
              deliveryCharges: 30,
              Discount: discount,
              allProduct: allCartData,
            },
          };
          await axios(config).then(function (res) {
            if ((res.status = 200)) {
              console.log("success");
              alert("Order Placed Successfully");
              deleteCart();
              if (coupondis) {
                appliedCoupon();
              }
              window.location.assign("/distributor/distributor-home");
            }
          });
        } catch (error) {
          console.log(error);
          alert("Unable to place Order");
        }
    } else {
      alert("Please select the shipping address");
    }
  };

  const [PaymentAmount, setPaymentAmount] = useState(total + 30);
  console.log(total + 30);

  const posttransaction = async () => {
    if (!Object.keys(showAddress).length) {
      return alert("Please select the shipping address");
    } else if (!paymentmethod) {
      return alert("Please select the Payment method");
    } else if (paymentmethod !== "Online Payment") {
      return placeorder();
    } else {
      try {
        const config = {
          data: {
            key: "rzp_test_5vCRZ6rLM2wGN4",
            amount:
              Math.round(
                total + 30 - Math.round((total + 30) * (coupondis / 100))
              ) * 100,
            currency: "INR",
            name: "Jaaggery",
            description: "Order Amount",
            image: "/Logo-1.jpg",
            distributorId: distributor?._id,
            handler: function (response) {
              alert(response.razorpay_payment_id);
              setpayid(response.razorpay_payment_id);
            },
            prefill: {
              name: distributor?.firmname,
              email: distributor?.email,
              contact: distributor?.phoneNumber,
            },
          },
        };
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          alert("Fail to load");
          return;
        }
        const paymentObject = new Razorpay(config.data);
        paymentObject.open();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (payid) {
      placeorder();
    }
  }, [payid]);

  return (
    <>
      <section>
        <div className="check mt-4">
          <div className="check_0">
            <h5 className="text-center">Checkout</h5>
          </div>
          <div className="trading">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  {changes ? (
                    <>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="deliver-1">
                            <h3>1</h3>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="deliver-1">
                            <div className="">
                              <h3>Delivery address</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="deliver-111">
                            <div className="details-2">
                              {Object.keys(showAddress).length ? (
                                <p>
                                  {showAddress?.name} {showAddress?.doorno},
                                  {showAddress?.area},{showAddress?.addressline}{" "}
                                  ,{showAddress?.city}, {showAddress?.state},{" "}
                                  {showAddress?.pincode},{showAddress?.country}
                                  ,Phone number:
                                  {showAddress?.number}{" "}
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <Radio.Group onChange1={onChange1} value1={value}>
                            <Radio value={1}>Deliver Here</Radio>
                            <Radio value={2}>Bill Here</Radio>
                          </Radio.Group>
                        </div>
                        <div className="col-md-2">
                          <div className="deliver-1111">
                            <div
                              className="jkh"
                              onClick={() => {
                                setchanges(!changes);
                              }}
                            >
                              Change
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="change">
                        <div className="row">
                          <div className="col-md-2">
                            <div className="deliver-1">
                              <h3>1</h3>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="deliver-1">
                              <div className="">
                                <h3>Select a delivery address</h3>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="deliver-1">
                              <div
                                className="jkh"
                                onClick={() => {
                                  setchanges(!changes);
                                }}
                              >
                                Close
                                <span>
                                  <IoIosClose />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1"></div>
                        </div>
                        {/* foerijve */}

                        <div className="pn-0">
                          <div className="pi-09">
                            <div className="jh_0">
                              <div className="">
                                <h3>Your addresses</h3>
                              </div>
                              {/* <div className="">Sending items to more than one address?</div> */}
                            </div>
                            <div className="address_80">
                              {address?.map((items) => {
                                return (
                                  <Form>
                                    {["radio"].map((type) => (
                                      <div
                                        key={`inline-${type}`}
                                        className="mb-3 bdm"
                                      >
                                        <Form.Check
                                          block
                                          name="group1"
                                          type={type}
                                          id={`inline-${type}-1`}
                                          onClick={() => {
                                            setshowAress(items);
                                            setchanges(!changes);
                                          }}
                                        />
                                        <p>
                                          {items?.name} {items?.doorno},
                                          {items?.area},{items?.addressline} ,
                                          {items?.city}, {items?.state},{" "}
                                          {items?.pincode},{items?.country}
                                          ,Phone number:
                                          {items?.number}{" "}
                                          <span
                                            className=""
                                            style={{ color: "#007185" }}
                                            onClick={() => {
                                              handleShow(items);
                                              setedit(items);
                                            }}
                                          >
                                            {" "}
                                            Edit address
                                          </span>
                                        </p>
                                      </div>
                                    ))}
                                  </Form>
                                );
                              })}
                            </div>
                          </div>

                          <div
                            className="add_new_ad mb-2"
                            onClick={handleShow1}
                          >
                            <span>
                              <MdAdd className="icon-add" />
                              Add a new address
                            </span>
                          </div>
                          <div
                            className="mb-2"
                            style={{ borderTop: "1px solid #D5D9D9" }}
                          ></div>
                          {/* <div className="add_new_ad">
                            <div>
                              <button>Use this address</button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </>
                  )}
                  {/* Cupon  */}
                  <div className="pay_0">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="deliver-1">
                          <h3>2</h3>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="deliver-1">
                          <div className="">
                            <h3>Check Cupon</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cupon">
                      <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                        <input
                          type="text"
                          className="address-ui-widgets"
                          placeholder="Enter Code"
                          maxlength="50"
                          name="address-ui-widgets-enterAddressFullName"
                          style={{
                            width: "50%",
                            marginRight: "20px",
                            marginLeft: "50px",
                          }}
                        />
                        <Button
                          style={{ marginRight: "20px" }}
                          onClick={getCoupon}
                        >
                          Apply
                        </Button>
                        {coupondis ? (
                          <Button
                            onClick={() => {
                              setCoupondis(0);
                              setInputCupon("");
                              setCupon({});
                            }}
                          >
                            Clear here
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Payment */}
                  <div className="pay_0">
                    <div className="row">
                      <div className="col-md-1">
                        <div className="deliver-1">
                          <h3>3</h3>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="deliver-1">
                          <div className="">
                            <h3>Select a payment method</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pn-0">
                      <div className="pi-09">
                        <div className="jh_0">
                          <div className="">
                            <h5>Another payment method</h5>
                          </div>
                          {/* <div className="">Sending items to more than one address?</div> */}
                        </div>
                        <div className="address_80">
                          <Radio.Group onChange={onChange} value={value}>
                            <Space direction="vertical">
                              <Radio
                                value={1}
                                onClick={() => {
                                  setupi(false);
                                  setpaymentmethod("Online Payment");
                                }}
                              >
                                <div className="chexk_0">
                                  Online Payment
                                  <span>
                                    {" "}
                                    <img
                                      src="/sprite-map._CB443317321_.png"
                                      alt="pay-image"
                                      style={{ width: "100%" }}
                                    />
                                  </span>
                                </div>
                                <div
                                  className="add_new_ad mt-3 mb-2 px-2"
                                  onClick={handleShow2}
                                >
                                  <span>
                                    <MdAdd className="icon-add" />
                                    Enter card details &#x276F;
                                  </span>
                                </div>
                              </Radio>
                              {/* <Radio value={2}   onClick={() => {
                                    setupi(!upi);
                                  }}>Other UPI Apps</Radio>
                              <div className="d-block">
                                  {upi ? (
                                    <>
                                      <div className="d-block">
                                        <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                                          <label>
                                            Please enter your UPI IDe
                                          </label><br/>
                                          <input
                                            type="text"
                                            className="address-ui-widgets"
                                            maxlength="50"
                                            name="address-ui-widgets-enterAddressFullName"
                                            placeholder="Ex: MobileNumber@upi"
                                            style={{
                                              width: "65%",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <Button variant="primary">
                                            verify
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div> */}
                              <Radio
                                value={3}
                                onClick={() => {
                                  setupi(false);
                                  setpaymentmethod("Cash On Delivery");
                                }}
                              >
                                Cash On Delivery/Pay On Delivery
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="">
                    <div className="">
                      <div className="product-summary">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Image</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Discount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Cartitems?.map((items, i) => {
                              return (
                                <tr>
                                  <td>{++i}</td>
                                  <td>
                                    <img
                                      src={`https://spilso.in/admin/product/${items?.productId?.productimage[0]}`}
                                      alt=""
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  </td>
                                  <td>{items?.quantity}</td>
                                  <td>₹{items?.totalPrice}</td>
                                  <td>
                                    ₹
                                    {Math.round(
                                      Number(items?.price * items?.quantity) +
                                        Math.round(
                                          items?.price *
                                            items?.quantity *
                                            (items?.productId?.tax / 100)
                                        ) -
                                        items?.totalPrice
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                  <div className="summer">
                    <div className="pro-summery">
                      <h5>Payment Summary</h5>
                      <div className="">
                        <table
                          data-testid
                          className="a-normal small-line-height"
                        >
                          <tbody>
                            <tr
                              data-testid
                              className="order-summary-unidenfitied-style"
                            >
                              <td className="a-text-left">Sub Total @ MRP :</td>
                              <td className="a-text-right a-align-bottom aok-nowrap">
                                ₹{subtotal}.00
                              </td>
                            </tr>
                            {/* <tr data-testid className="order-summary-unidenfitied-style">
                                                            <td className="a-text-left">
                                                                Product Discount:
                                                            </td>
                                                            <td className="a-text-right a-align-bottom aok-nowrap">
                                                                ₹40
                                                            </td>
                                                        </tr> */}
                            <tr
                              data-testid
                              className="order-summary-unidenfitied-style"
                            >
                              <td className="a-text-left">Delivery Charges:</td>
                              <td className="a-text-right a-align-bottom aok-nowrap">
                                ₹30
                              </td>
                            </tr>
                            <tr
                              data-testid
                              className="order-summary-unidenfitied-style"
                            >
                              <td className="a-text-left">Tax:</td>
                              <td className="a-text-right a-align-bottom aok-nowrap">
                                ₹{tax}.00
                              </td>
                            </tr>

                            {coupondis ? (
                              <tr
                                data-testid
                                className="order-summary-unidenfitied-style"
                              >
                                <td className="a-text-left">
                                  Coupon Discount:
                                </td>
                                <td className="a-text-right a-align-bottom aok-nowrap">
                                  ₹
                                  {Math.round((total + 30) * (coupondis / 100))}
                                  .00
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}

                            <tr className="order-summary-grand-total">
                              <td colSpan={2} className="cell-separator">
                                <hr className="a-spacing-mini a-divider-normal" />
                              </td>
                            </tr>
                            <tr data-testid>
                              <td className="a-color-price a-size-medium a-text-left a-text-bold">
                                Order Total:
                              </td>
                              <td className="a-size-medium a-text-right a-align-bottom aok-nowrap grand-total-price">
                                ₹
                                {total +
                                  30 -
                                  Math.round((total + 30) * (coupondis / 100))}
                                .00
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-success"
                    style={{
                      padding: "8px",
                      marginTop: "10px",
                      marginLeft: "35%",
                      backgroundColor: "#3d459a",
                    }}
                    onClick={posttransaction}
                  >
                    Place order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Edit address */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Update your shipping address</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="naukri">
            <div className="added_0">
              <h3>Edit your address</h3>
            </div>
            <div className="">
              <form>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Full name</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Mobile number</label>
                  <input
                    type="tel"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Pincode</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Flat, House no., Building, Company, Apartment</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Area, Street, Sector, Village</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div className="joker">
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Area, Street, Sector, Village</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      name="address-ui-widgets-enterAddressFullName"
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Landmark</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      name="address-ui-widgets-enterAddressFullName"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Use this address
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add new Address */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Enter a new delivery address</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="naukri">
            <div className="added_0">
              <h3>Add a new address</h3>
            </div>
            <div className="">
              <form>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Full name</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={distributor?.firmname}
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Mobile number</label>
                  <input
                    type="tel"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={distributor?.permanentno}
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
                {/* <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Pincode</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div> */}
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Flat, House no., Building, Company, Apartment</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder="Please enter house no ..."
                    value={House}
                    onChange={(e) => setHouse(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Area, Street, Sector, Village</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder="Please enter area..."
                    value={Area}
                    onChange={(e) => setArea(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Near Landmark</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder="Please enter near landmark"
                    value={Landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                  />
                </div>
                <div className="joker">
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Country</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder="countruy"
                      value={country}
                      onChange={(e) => setcountry(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Sate</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder="sate"
                      value={state}
                      onChange={(e) => setstate(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>City</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder="city"
                      value={city}
                      onChange={(e) => setcity(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Pin code</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder="pincode"
                      value={pincode}
                      onChange={(e) => setpincode(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={addAdress}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Payment */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Enter card details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="naukri">
            <div className="">
              <form>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Card number</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Name on card</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                    placeholder="Shruthi"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Expiry date</label>
                  <input
                    type="date"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose2}>
            Enter card details
          </Button>
        </Modal.Footer>
      </Modal>
      {/*Cupon */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Apply Coupon</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="naukri">
            <div className=""></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Checkout;
