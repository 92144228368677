import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
function NotapprovedRetailer() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen1, setIsOpen1] = useState(false);
  const [selected, setselected] = useState({});
  const [bankname, setbankname] = useState("");
  const [accontno, setaccontno] = useState("");
  const [branch, setbranch] = useState("");
  const [bankcode, setbankcode] = useState("");
  const [panno, setpanno] = useState("");
  const [gstno, setgstno] = useState("");
  const [villagename, setvillagename] = useState("");
  const [addresslandmark, setaddresslandmark] = useState("");
  const [taluka, settaluka] = useState("");
  const [districtname, setdistrictname] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  useEffect(() => {
    getretailers();
  }, []);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const getretailers = () => {
    try {
      axios
        .get(`${apiURL}/retailer/notapproved`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata(response.data.retailer);
            return;
          } else {
            alert("Can't able to fetch ");

            return;
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const block = async (data) => {
    try {
      axios
        .post(`${apiURL}/blockretailer/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .post(`${apiURL}/unblockretailer/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/retailer${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  function imageFormatter1(cell, row) {
    return (
      <img
        src={`${imageURL}/retailer${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }
  function validategst(inputtxt) {
    var phoneno = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid gst number!");
      return false;
    }
  }

  function panverification(inputtxt) {
    var phoneno = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid pan number!");
      return false;
    }
  }

  function pincodecheck(inputtxt) {
    // var phoneno = /^[6-9]\d{9}$/;
    var Pincode = /^[0-9]{6}$/;
    if (inputtxt.match(Pincode)) {
      return true;
    } else {
      alert("You have entered an invalid pincode!");
      return false;
    }
  }

  const editprofile = async () => {
    if (
      validategst(gstno ? gstno : selected.gstno) &&
      panverification(panno ? panno : selected.panno) &&
      pincodecheck(pincode ? pincode : selected.pincode)
    ) {
      try {
        const config = {
          url: "/retailer/updateretailer",
          method: "post",
          baseURL: apiURL,
          headers: { "content-type": "application/json" },
          data: {
            retailerId: selected._id,
            bankname: bankname ? bankname : selected.bankname,
            accontno: accontno ? accontno : selected.accontno,
            branch: branch ? branch : selected.branch,
            bankcode: bankcode ? bankcode : selected.bankcode,
            panno: panno ? panno : selected.panno,
            gstno: gstno ? gstno : selected.gstno,
            villagename: villagename ? villagename : selected.villagename,
            addresslandmark: addresslandmark
              ? addresslandmark
              : selected.addresslandmark,
            taluka: taluka ? taluka : selected.taluka,
            districtname: districtname ? districtname : selected.districtname,
            state: state ? state : selected.state,
            pincode: pincode ? pincode : selected.pincode,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);

          hideModal1();
          alert("Profile is Edited Successfully");
          window.location.reload();

          return res;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const columns = [
    {
      // dataField: "firmname",
      text: "Sl No",
      sort: true,
    },
    {
      // dataField: "rid",
      text: "Retailer Unique Id",
      sort: true,
    },
    {
      // dataField: "rid",
      text: "Retailer Name",
      sort: true,
    },
    {
      // dataField: "rid",
      text: "Retailer Number",
      sort: true,
    },
    {
      // dataField: "rid",
      text: "Email Id",
      sort: true,
    },
    {
      // dataField: "rid",
      text: "Password",
      sort: true,
    },  
    {
      // dataField: "villagename",
      text: "Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {" "}
            {row?.villagename}, {row?.taluka}, {row?.districtname}, {row?.state}
            , {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    {
      // dataField: "email",
      text: "Shop Name",
      sort: true,
    },
    {
      // dataField: "_id",
      text: "Shop Image",
      sort: true,
    },
    {
      // dataField: "permanentno",
      text: "Department Store/Super Market",
    },
    {
      // dataField: "gstno",
      text: "GST No",
    },

    {
      // dataField: "panno",
      text: "PAN No",
    },
    {
      text:"Shop Size in sqft",
    },
    {
      text:"No of 3 wheeler",
    },
    {
      text:"No of 4 wheeler",
    },
    {
      text:"No of retailers servicing",
    },
   




   
    // {
    //   text:"Type of outlet",
    // },
    {
      // dataField: "bankname",
      text: "Bank Details",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            Bank Name: {row.bankname},<br /> Accont No: {row.accontno},<br />{" "}
            IFSC code:{row.bankcode}, <br />
            Branch: {row.branch}{" "}
          </div>
        );
      },
    },
    {
      text:"Retailer Photo",
    },
    {
      text:"Shop Photo",
    },
    {
      text:"Pincodes",
    },
    {
      // dataField: "addresslandmark",
      text: "Distributor",
    },
    {
      // dataField: "",
      text: "Edit",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-outline-success"
              onClick={() => {
                setselected(row);
                showModal1();
              }}
            >
              Edit
            </button>
          </div>
        );
      },
    },
    {
      // dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {!row?.blockstatus ? (
              <button
                className="btn btn-outline-success"
                // onClick={() => block(row)}
              >
                Approved
              </button>
            ) : (
              <button
                className="btn btn-outline-danger"
                // onClick={() => unblock(row)}
              >
                Un-Block
              </button>
            )}
          </div>
        );
      },
    },

    // {
    //   dataField: 'status',
    //   text: 'Status',
    //   sort : true
    // }
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <Row></Row>
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Retailer List
        </h1>
        <div className="end">
          <Link to="/admin/addretailer1">
          <Button variant="primary">Add</Button>
          </Link>
        </div>
      
        <div className="container">
          <div className="card card-body">
            <Modal
              style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
              show={isOpen1}
              onHide={hideModal1}
              animation={false}
            >
              <Modal.Header closeButton className="mt-4">
                <Modal.Title>Edit Profile </Modal.Title>
              </Modal.Header>

              <div className="ms-2 me-2">
                <h5>Id Proofs</h5>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">PAN no</Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setpanno(e.target.value)}
                    // placeholder={selected.panno}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">Gst NO</Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setgstno(e.target.value)}
                    // placeholder={selected.gstno}
                  />
                </Form.Group>
                <h5>Bank details</h5>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">
                    Bank Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setbankname(e.target.value)}
                    // placeholder={selected.bankname}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">
                    Account Number
                  </Form.Label>
                  <Form.Control
                    type="number"
                    // onChange={(e) => setaccontno(e.target.value)}
                    // placeholder={selected.accontno}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">Branch</Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setbranch(e.target.value)}
                    // placeholder={selected.branch}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">
                    IFSC code
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setbankcode(e.target.value)}
                    // placeholder={selected.bankcode}
                  />
                </Form.Group>
                <h5>Address Change</h5>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">
                    Village Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setvillagename(e.target.value)}
                    // placeholder={selected.villagename}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">
                    Address Landmark
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setaddresslandmark(e.target.value)}
                    // placeholder={selected.addresslandmark}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">Taluka</Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => settaluka(e.target.value)}
                    // placeholder={selected.taluka}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">
                    District Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setdistrictname(e.target.value)}
                    // placeholder={selected.districtname}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">State</Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={(e) => setstate(e.target.value)}
                    // placeholder={selected.state}
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                  <Form.Label className="mb-2 mt-2 fw-bold">Pincode</Form.Label>
                  <Form.Control
                    type="number"
                    // onChange={(e) => setpincode(e.target.value)}
                    // placeholder={selected.pincode}
                  />
                </Form.Group>
              </div>
              <Modal.Footer>
                <Button variant="success" onClick={hideModal1}>
                  Close
                </Button>
                <Button variant="danger" onClick={editprofile}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "Approvedretailers.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="retailer"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotapprovedRetailer;
