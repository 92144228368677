import React, { useEffect, useState } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { AiFillDelete } from 'react-icons/ai';
import DatePicker from "react-multi-date-picker";
import { Switch } from 'antd';
import { Link } from "react-router-dom";
import moment from "moment";
const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

function StockDashboard() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [selected, setselected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stock, setstock] = useState("");
  const [free, setfree] = useState("");
  const [date, setdate] = useState(new Date());
  const [basicprice, setbasicprice] = useState("");
  const [tax, settax] = useState("");
  const [product, setproduct] = useState([]);
  const [excel, setexcel] = useState();
  const [image, setimage] = useState();
  const [data1, setdata1] = useState([]);
  //
  const [productname, setproductname] = useState("");
  const [mrp, setmrp] = useState("");
  const [cop, setcop] = useState("");
  const [cdp, setcdp] = useState("");
  const [dop, setdop] = useState("");
  const [ddp, setddp] = useState("");
  const [rop, setrop] = useState("");
  const [rdp, setrdp] = useState("");
  const [desc, setdesc] = useState("");
  const [feature, setfeature] = useState("");
  const [brand, setbrand] = useState("");

  //
  const formdata = new FormData();
  const [selected1, setselected1] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = (data) => {
    setselected(data);
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let res = await axios.get(apiurl + "/admin/product");
    if ((res.status = 200)) {
      console.log(res);
      setproduct(res.data?.products);
    }
  };

  if (excel && data1.length === 0) {
    readFile();
  }

  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setdata1(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }

  function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    
    return JSON.stringify(result); //JSON
  }
 
 
 
 

  const postcategory = async (e) => {
    e.preventDefault();
      if(!date) return alert("Please select date!")
      if(!stock) return alert("Please enter stock!")
    try {
      const config = {
        url: "/admin/addStockofproduct",
        method: "put",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: {
          id: selected?._id,
          totalstock: stock,
          lastStockDate: date,
          
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("Stock added");
          window.location.reload();
        }
      });
    } catch (error) {
      console.error(error);
      alert("category Name Not Added");
    }
  };

  const subscribe = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/subscribeproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unsubscribe = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/unsubscribeproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: product?.filter((ele)=>ele?.remainingstock<ele?.minimumStock).length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/admin/product/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      text:""
    },
    {
      dataField: "productimage[0]",
      text: "Product Image1",
      formatter: imageFormatter,
    },
    {
      dataField: "productimage[1]",
      text: "Product Image2",
      formatter: imageFormatter,
    },
    {
      dataField: "productimage[2]",
      text: "Product Image3",
      formatter: imageFormatter,
    },
    {
      dataField: "productimage[3]",
      text: "Product Image4",
      formatter: imageFormatter,
    },

      {
        dataField: "productType",
      text:"Product Type"
    },
    {
      dataField: "_id",
text:"Product Id"
    },
    {
      dataField: "productcategory",
      text: "Category",
      sort: true,
    },
    // {
    //   dataField: "productsubcategory",
    //   text: "Subcategory",
    //   sort: true,
    // },
    {
      dataField: "productname",
      text: "Product Name With Title",
    },
    
    {
      dataField: "tax",
      text: "GST",
    },
    {
      dataField: "productprice",
      text: "MRP",
    },
  
    {
      dataField: "productbrand",
      text: "Product Brand",
    },
    { 

        text: "Stock Date",
        formatter: (value, row) => (
          <p >
            {row?.lastStockDate ? (moment(row?.lastStockDate).format('L')):(<></>)}
            
          </p>
        ),
      },
      {
        dataField: "remainingstock",
        text:"Current Stock"
      },
    {
      dataField: "totalstock",
      text: "Total Stock",
    },
    {
      dataField: "minimumStock",
      text: "Minimum Stock",
    },
    {
      dataField: "",
      text: "Edit",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
               <Link to={{ pathname: "/admin/editproject", state: { row } }}>
            <button
              className="btn btn-outline-info"
            >
              Edit
            </button></Link>
          </div>
        );
      },
    },
   
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div className="d-flex">
              
            <button className="btn btn-success" onClick={() =>{ showModal(row)
            setselected1(row)
            }} >
              Add Stock
            </button>
          
            {/* {row?.subscriptation ? (
              <button
                className="btn btn-warning ms-2"
                onClick={() => unsubscribe(row)}
              >
                Unsubscribe
              </button>
            ) : (
              <button
                className="btn btn-success ms-2"
                onClick={() => subscribe(row)}
              >
                Subscribe
              </button>
            )} */}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Status",
      formatter: (cell, row) => {
        return (
          <div>
            {row?.remainingstock < row?.minimumStock ? (<p style={{color:"red" ,fontSize:"large",fontWeight:"600"}}>Out Of Stock</p>):(<p style={{color:"green",fontSize:"large",fontWeight:"600"}}>In Stock</p>) }
          </div>
        );
      },
    },
  ];

  var i = 1;
  return (
    <div className="mt-3">
      
      <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Add Free Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label className="mt-2">Product Title</label>
                <input
                  type="text"
                  placeholder="Enter Product Title"
                  className="form-control"
                ></input>
                <label className="mt-2">Small Discription</label>
                <input
                  type="text"
                  placeholder="Enter Small Discription"
                  className="form-control"    
                ></input>
                <label className="mt-2">MRP</label>
                <input
                  type="text"
                  placeholder="Enter MRP"
                  className="form-control"
                ></input>
                <br/>
        <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Product Image</Form.Label>
        <br/>
       <Form.Control type="file" />
      </Form.Group>
              </div>
              <br></br>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      <div className="mt-3">
        <Modal show={isOpen} onHide={hideModal}>
          <Modal.Header>
            <Modal.Title>Add Stock</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label className="mt-2">Date</label>
                <DatePicker
                  style={{ width: "466px", height: "38px" }}
                  format="DD/MM/YYYY"
                  type="input-icon"
                  minDate={new Date()}
                  value={date}
                  onChange={(date) => setdate(date.format("DD/MM/YYYY"))}
                />
                {/* <label className="mt-2">Current Stock</label>
                <input
                  type="text"
                  className="form-control"
                ></input> */}
                <label className="mt-2">New Stock</label>
                <input
                  type="number"
                  value={stock}
                  onChange={(e)=>setstock(e.target.value)}
                  placeholder="Enter Quantity"
                  className="form-control"
                ></input>
              
              </div>
              <br></br>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={hideModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={postcategory}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <ToolkitProvider
          keyField="id"
          data={product?.filter((ele)=>ele?.remainingstock<ele?.minimumStock)}
          columns={columns}
          search
          exportCSV={{
            fileName: "Products.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pr-5 mr-auto">
                <ExportCSVButton
                  className="btn-outline-success"
                  {...props.csvProps}
                >
                  Export to CSV
                </ExportCSVButton>
              </span>
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={product}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
        <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Edit Product </Modal.Title>
          </Modal.Header>

          <div className="ms-2 me-2">
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Product Name
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setproductname(e.target.value)}
                placeholder={selected1.productname}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">MRP</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setmrp(e.target.value)}
                placeholder={selected1.productprice}
              />
            </Form.Group>

            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Customer Discount Percentage
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setcdp(e.target.value)}
                placeholder={selected1.customerdiscountpercentage}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                customer Offer Price
              </Form.Label>
              <Form.Control
                type="number"
                onChange={(e) => setcop(e.target.value)}
                placeholder={selected1.customerofferPrice}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Distributor Offer Price
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setdop(e.target.value)}
                placeholder={selected1.distributorofferPrice}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Distributor Discount Percentage
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setddp(e.target.value)}
                placeholder={selected1.distributordiscountpercentage}
              />
            </Form.Group>

            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Retailer Offer Price
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setrop(e.target.value)}
                placeholder={selected1.retailerofferPrice}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Retailer Discount Percentage
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setrdp(e.target.value)}
                placeholder={selected1.retailerdiscountpercentage}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Product Description
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setdesc(e.target.value)}
                placeholder={selected1.productdesc}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">Brand</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setbrand(e.target.value)}
                placeholder={selected1.productbrand}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">Feature</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setfeature(e.target.value)}
                placeholder={selected1.productfeatures}
              />
            </Form.Group>
          </div>
          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Close
            </Button>
            <Button variant="danger"
            //  onClick={() => editproduct()}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default StockDashboard;
