import React from "react";
import "../Styles/bestseller.css";
import OwlCarousel from 'react-owl-carousel';
function HighQuality() {
  return (
    <>
      <section className="common-use" style={{ backgroundColor: "#eff0e9 " }}>
        <div
          className="banner-content text-center pt-3 pb-4"
          style={{ fontSize: "30px" }}
        >
          <h3>High quality whole Products</h3>
        </div>
        <div className=" container-fluid high_0 ">
            <div className="row">
                <div className="col-md-3 nv-img-left p-0">
                    <div className="left-back"
                    style={{
                        backgroundImage: "url('/right.png')",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        height: "100vh",
                        borderRadius: "0px 180px 180px 0px",
                      }}
                    ></div>
                </div>
                <div className="col-md-6">
                <div className="mt-5 jaggray_0">
            <div className="category-slider">
            <OwlCarousel className='owl-theme' loop margin={10} items={2} nav={false}>
    <div className='item'>
    <div class="categiries-banners">
                  <div
                    className="pumbling"
                    style={{
                      backgroundImage: "url('/Podiumimg.avif')",
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                      paddingBottom: "55px",
                      display: " flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/webImages/cat3.webp"
                      alt="Avatar"
                      class="image-fluid cate"
                    />
                  </div>
                <div className="text-center">
                <div className="">
                    <h5>Fruits banana 100% organic</h5>
                  </div>
                  <div className="price-box mb-2">
                    <span className="new-price" id="ProductPrice">
                      ₹550csdfewerf
                    </span>
                    <span className="old-price" id="ComparePrice">
                      ₹44,00
                    </span>
                  </div>
                  <div className="action-cart-btn hvr-shutter-out-horizontal">
           Add to Cart
                  </div>
                </div>
                </div>
    </div>
 <div className="item">
 <div class="categiries-banners">
                  <div
                    className="pumbling"
                    style={{
                      backgroundImage: "url('/Podiumimg.avif')",
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                      paddingBottom: "55px",
                      display: " flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/webImages/1.jpg"
                      alt="Avatar"
                      class="image-fluid cate"
                    />
                  </div>
                  <div className="text-center">
                <div className="">
                    <h5>Fruits banana 100% organic</h5>
                  </div>
                  <div className="price-box mb-2">
                    <span className="new-price" id="ProductPrice">
                      ₹550
                    </span>
                    <span className="old-price" id="ComparePrice">
                      ₹44,00
                    </span>
                  </div>
                  <div className="action-cart-btn hvr-shutter-out-horizontal">
           Add to Cart
                  </div>
                </div>
                </div>
 </div>
 <div className="item">
 <div class="categiries-banners">
                  <div
                    className="pumbling"
                    style={{
                      backgroundImage: "url('/Podiumimg.avif')",
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                      paddingBottom: "55px",
                      display: " flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/webImages/2.jpg"
                      alt="Avatar"
                      class="image-fluid cate"
                    />
                  </div>
                  <div className="text-center">
                <div className="">
                    <h5>Fruits banana 100% organic</h5>
                  </div>
                  <div className="price-box mb-2">
                    <span className="new-price" id="ProductPrice">
                      ₹550
                    </span>
                    <span className="old-price" id="ComparePrice">
                      ₹44,00
                    </span>
                  </div>
                  <div className="action-cart-btn hvr-shutter-out-horizontal">
           Add to Cart
                  </div>
                </div>
                </div>
 </div>
 <div className="item">
 <div class="categiries-banners">
                  <div
                    className="pumbling"
                    style={{
                      backgroundImage: "url('/Podiumimg.avif')",
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                      paddingBottom: "55px",
                      display: " flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/webImages/2.jpg"
                      alt="Avatar"
                      class="image-fluid cate"
                    />
                  </div>
                  <div className="text-center">
                <div className="">
                    <h5>Fruits banana 100% organic</h5>
                  </div>
                  <div className="price-box mb-2">
                    <span className="new-price" id="ProductPrice">
                      ₹550
                    </span>
                    <span className="old-price" id="ComparePrice">
                      ₹44,00
                    </span>
                  </div>
                  <div className="action-cart-btn hvr-shutter-out-horizontal">
           Add to Cart
                  </div>
                </div>
                </div>
 </div>
 <div className="item">
 <div class="categiries-banners">
                  <div
                    className="pumbling"
                    style={{
                      backgroundImage: "url('/Podiumimg.avif')",
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                      paddingBottom: "55px",
                      display: " flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/webImages/4.jpg"
                      alt="Avatar"
                      class="image-fluid cate"
                    />
                  </div>
                  <div className="text-center">
                <div className="">
                    <h5>Fruits banana 100% organic</h5>
                  </div>
                  <div className="price-box mb-2">
                    <span className="new-price" id="ProductPrice">
                      ₹550
                    </span>
                    <span className="old-price" id="ComparePrice">
                      ₹44,00
                    </span>
                  </div>
                  <div className="action-cart-btn hvr-shutter-out-horizontal">
           Add to Cart
                  </div>
                </div>
                </div>
 </div>
 <div className="item">
 <div class="categiries-banners">
                  <div
                    className="pumbling"
                    style={{
                      backgroundImage: "url('/Podiumimg.avif')",
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                      paddingBottom: "55px",
                      display: " flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/webImages/6.jpg"
                      alt="Avatar"
                      class="image-fluid cate"
                    />
                  </div>
                  <div className="text-center">
                <div className="">
                    <h5>Fruits banana 100% organic</h5>
                  </div>
                  <div className="price-box mb-2">
                    <span className="new-price" id="ProductPrice">
                      ₹550
                    </span>
                    <span className="old-price" id="ComparePrice">
                      ₹44,00
                    </span>
                  </div>
                  <div className="action-cart-btn hvr-shutter-out-horizontal">
           Add to Cart
                  </div>
                </div>
                </div>
 </div>
    
  
</OwlCarousel>
             
            </div>
          </div>
                </div>
                <div className="col-md-3 nv-img-right p-0">
                <div className="right-back"
                    style={{
                        backgroundImage: "url('/right.png')",
                        backgroundSize: "content",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        height: "100vh",
                        borderRadius: "180px 0px 0px 180px",
                        display:"flex",
                        justifyContent:"center",
                        alignContent:"center"
                      }}
                    ></div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
}

export default HighQuality;
