import React, { useEffect, useState } from "react";
import "../Styles/style12.css";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
// import { Link } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
function OursBrand() {
  const [ourbrand, setourbrand] = useState([]);

  const getAllBrand = async () => {
    try {
      let res = await axios.get("https://spilso.in/api/getAllBrand");
      if (res.status == 200) {
        setourbrand(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllBrand();
  }, []);
  useEffect(() => {
    getAllBrand();
  }, []);

  const  responsive={
    0:{
      items:2
    },
    600:{
      items:2
    },
    1000:{
      items:5
    }
  }

  return (
    <>
      <section class="categories" style={{ padding: "15px 40px" }}>
        <div class="container">
          <div class="section-title">
            <h2>Our Brands</h2>
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={5}
            dots={false}
            autoplay={true}
            responsive={responsive}

          >
            {ourbrand?.map((items) => {
              return (
                <div class="item">
                  <div class="brands_0">
                    <Link
                      to={{
                        pathname: "/products",
                        state: { brand: items?.BrandName },
                      }}
                    >
                      <img
                        src={`https://spilso.in/brand/${items?.brandImage}`}
                        alt="brands"
                      />
                    </Link>
                  </div>
                </div>
              );
            })}

            {/* <div class="item">
              <div class="brands_0">
                <img src="/img/featured/Brand3.jpg" alt="brands" />
              </div>
            </div>
            <div class="item">
              <div class="brands_0">
                <img src="/img/featured/Brand4.jpg" alt="brands" />
              </div>
            </div>
            <div class="item">
              <div class="brands_0">
                <img src="/img/featured/Brand5.jpg" alt="brands" />
              </div>
            </div>
            <div class="item">
              <div class="brands_0">
                <img src="/img/featured/Brand1.png" alt="brands" />
              </div>
            </div> */}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
}

export default OursBrand;
