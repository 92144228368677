import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../Components/customer/Styles/style12.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Fakedata from "../NewDistributor/FakeData/FakeData";
import { Tooltip } from "antd";
import { Button, Modal } from "antd";
import ReactPaginate from "react-paginate";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Link } from "react-router-dom";

function DistributorProduct() {
  function valuetext(value) {
    return `${value}°C`;
  }

  const [value, setValue] = React.useState([0, 10000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [products, setproducts] = useState(Fakedata);
  const [Allproducts, setAllproducts] = useState([]);
  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //   Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = Allproducts?.slice(
    visitedPage,
    visitedPage + productPerPage
  );

  const pageCount = Math.ceil(Allproducts?.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const distributor = JSON.parse(sessionStorage.getItem("distributor"));
  const [Currentitem, setCurritem] = useState({});
  const [selPrice, setslePrice] = useState(0);
  const [selQuntity, setselQuntity] = useState(0);
  const [image, setImage] = useState("");
  const [showMore, setShowMore] = useState(false);

  const [quantity, setQuntity] = useState(1);
  const incQuntity = () => {
    setQuntity(quantity + 1);
  };
  const decQuntity = () => {
    if (quantity > 1) {
      setQuntity(quantity - 1);
    }
  };
  /* ================================================================================================== */
  //get all brands and categories...

  const [SelectedCategory, setSelectedCategory] = useState("");
  const [SelectedBrand, setSelectedBrand] = useState("");
  const [ProductCategories, setProductCategories] = useState([]);
  const [ComboProduct, setComboProduct] = useState(false);
  const [FreeProduct, setFreeProduct] = useState(false);
  const [ByonegetoneProduct, setByonegetoneProduct] = useState(false);
  const [DiscountUpto10, setDiscountUpto10] = useState(0);
  const [DiscountUpto20, setDiscountUpto20] = useState(0);
  const [DiscountUpto30, setDiscountUpto30] = useState(0);
  const [DiscountUpto40, setDiscountUpto40] = useState(0);
  const [DiscountUpto50, setDiscountUpto50] = useState(0);
  const [DiscountAbove50, setDiscountAbove50] = useState(0);

  const getAllProductCategories = async () => {
    try {
      const res = await axios.get("https://spilso.in/api/getcategory");
      if (res.status === 200) {
        setProductCategories(res.data.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Ourbrand, setOurbrand] = useState([]);
  const getAllBrands = async () => {
    try {
      const res = await axios.get("https://spilso.in/api/getAllBrand");
      if (res.status === 200) {
        setOurbrand(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* ================================================================================================== */

  const productslist = async () => {
    try {
      const res = await axios.get("https://spilso.in/api/getAllproduct");
      if (res.status === 200) {
        setAllproducts(res.data.products);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  /* ======================================================================================================== */
  // cart related api's
  const addToCartDistributor = async (product, e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/addToCartDistributor",
        method: "post",
        baseURL: "https://spilso.in/api",
        header: { "content-type": "application/json" },
        data: {
          distributorId: distributor?._id,
          productId: product?._id,
          quantity: 1,
          totalPrice: product?.distributorofferPrice * 1,
          Size: product?.productsize,
          price: product?.distributorofferPrice,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Product has added to the cart");
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [Carts, setCarts] = useState([]);
  const getCatrDeatils = () => {
    axios
      .get(
        "https://spilso.in/api/getAllCartProductsByDistributor/" +
          distributor?._id
      )
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeProductFromCart = async (item) => {
    if (!distributor) {
      alert("Need to Login");
    } else {
      try {
        const config = {
          url: "/removeProductsFromDistributorsCart",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            productId: item?._id,
            distributorId: distributor?._id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Product removed from wishlist");
          window.location.reload();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };

  /* ======================================================================================================== */
  // wishlist related api's
  const addToWishlistDistributor = async (product, e) => {
    try {
      const config = {
        url: "/addWhishList",
        method: "post",
        baseURL: "https://spilso.in/api",
        header: { "content-type": "application/json" },
        data: {
          distributorId: distributor?._id,
          productId: product?._id,
          quantity: 1,
          totalPrice: product?.distributorofferPrice * 1,
          Size: product?.productsize,
          price: product?.distributorofferPrice,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Product has added to the Wishlist");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [wishlist, setwishlist] = useState([]);
  const getwislist = () => {
    axios
      .get(
        "https://spilso.in/api/getWishlistBydistributorId/" + distributor?._id
      )
      .then(function (response) {
        console.log(response.data);
        setwishlist(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeProductFromwishlist = async (item) => {
    if (!distributor) {
      alert("Need to Login");
    } else {
      axios({
        method: "delete",
        url:
          "https://spilso.in/api/removeWishlistbyDistributorId/" +
          distributor._id +
          "/" +
          item?._id,
      })
        .then(function (response) {
          //handle success
          console.log(response);
          alert("Product removed from wishlist");
          getwislist();
          window.location.reload();
        })
        .catch(function (error) {
          //handle error
          console.log(error.response.data);
        });
    }
  };

  /* ======================================================================================================== */

  const [FilterAtoZ, setFilterAtoZ] = useState();
  const [SearchName, setSearchName] = useState("");

  /* ======================================================================================================== */

  useEffect(() => {
    getAllProductCategories();
    getAllBrands();
    productslist();
    if (distributor) {
      getCatrDeatils();
      getwislist();
    }
  }, []);
  return (
    <>
      <div className="filter">
        <Container>
          <div className="filter_he">
            <Row>
              <Col lg={3} md={3}>
                <div className="hero_product_filter">
                  <div className="pages-filter pb-2">
                    <h5 className="fill_h">Filter</h5>
                    <div className="filter_pro">
                      Reset
                      <span className="px-2">
                        <i
                          class="fa fa-refresh"
                          aria-hidden="true"
                          onClick={() => window.location.reload(true)}
                        ></i>
                      </span>
                    </div>
                  </div>
                  {/* Category Filter */}
                  <div className="pages-filter pt-2 ">
                    <h5 className="fill_h">Category</h5>
                  </div>
                  <div className="product-count pt-2 pb-3">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="">All Categories</option>
                      {ProductCategories?.map((items) => {
                        return (
                          <>
                            <option value={items.categoryName}>
                              {items.categoryName}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </div>
                  {/* Price Filter */}
                  <div className="pages-filter pt-2 ">
                    <h5 className="fill_h">Price</h5>
                  </div>
                  <div className="product-count pt-2 pb-3">
                    {/* The highest price is 66,00 */}
                    <Box sx={{ width: 270 }}>
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        min={0}
                        max={10000}
                      />
                    </Box>
                  </div>
                  {/* Brand Filter */}
                  <div className="pages-filter pt-2 ">
                    <h5 className="fill_h">Brands</h5>
                  </div>
                  <div className="product-count pt-2 pb-3">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setSelectedBrand(e.target.value)}
                    >
                      <option value="">All Brands</option>
                      {Ourbrand?.map((items) => {
                        return (
                          <>
                            <option value={items.BrandName}>
                              {items.BrandName}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </div>
                  {/* Offers */}
                  <div className="pages-filter pt-2 pb-2">
                    <h5 className="fill_h">Offers</h5>
                  </div>

                  <div className="product-count">
                    <ul className="multiple-filters scrollbar">
                      {ComboProduct ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setComboProduct(false)}
                            />
                            <label>Combo</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setComboProduct(true)}
                            />
                            <label>Combo</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                      {FreeProduct ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="Free"
                              onChange={() => setFreeProduct(false)}
                            />
                            <label>Free</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="Free"
                              onChange={() => setFreeProduct(true)}
                            />
                            <label>Free</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                      {ByonegetoneProduct ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="Buy 1 Get 1"
                              onChange={() => setByonegetoneProduct(false)}
                            />
                            <label>Buy 1 Get 1</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="Buy 1 Get 1"
                              onChange={() => setByonegetoneProduct(true)}
                            />
                            <label>Buy 1 Get 1</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                      <li className="size-hero">
                        <div className="filter-name">
                          <input
                            type="checkbox"
                            className="check_size"
                            value="1kg"
                          />
                          <label>Deals of the day</label>
                        </div>
                        {/* <div className="count-check">(0)</div> */}
                      </li>
                    </ul>
                  </div>
                  <div className="pages-filter pt-2 pb-2">
                    <h5 className="fill_h">Discount</h5>
                    <div className="filter_pro">
                      {/* <span className="px-2">(0)</span> Select */}
                    </div>
                  </div>

                  <div className="product-count">
                    <ul className="multiple-filters scrollbar">
                      {DiscountUpto10 ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto10(0)}
                            />
                            <label>Up to 10%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto10(10)}
                            />
                            <label>Up to 10%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                      {DiscountUpto20 ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto20(0)}
                            />
                            <label>Up to 20%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto20(20)}
                            />
                            <label>Up to 20%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                      {DiscountUpto30 ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto30(0)}
                            />
                            <label>Up to 30%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto30(30)}
                            />
                            <label>Up to 30%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                      {DiscountUpto40 ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto40(0)}
                            />
                            <label>Up to 40%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto40(40)}
                            />
                            <label>Up to 40%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                      {DiscountUpto50 ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto50(0)}
                            />
                            <label>Up to 50%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountUpto50(50)}
                            />
                            <label>Up to 50%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                      {DiscountAbove50 ? (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountAbove50(0)}
                            />
                            <label>Above 50%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      ) : (
                        <li className="size-hero">
                          <div className="filter-name">
                            <input
                              type="checkbox"
                              className="check_size"
                              value="1kg"
                              onChange={() => setDiscountAbove50(50)}
                            />
                            <label>Above 50%</label>
                          </div>
                          {/* <div className="count-check">(0)</div> */}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9}>
                <div className="banana-filter">
                  <div className="filter_search">
                    <div className="search-filter mb-2">
                      <label for="SortBy">Sort by:</label>
                      <select
                        className="select-sort"
                        data-option="option1"
                        id="product-select-qv-option-0"
                        onChange={(e) => setFilterAtoZ(e.target.value)}
                      >
                        <option value="">Select option</option>
                        <option value="AtoZ">Alphabetically, A-Z</option>
                        <option value="ZtoA">Alphabetically, Z-A</option>
                        <option value="lowtohigh">Price, low to high</option>
                        <option value="hightolow">Price, high to low</option>
                      </select>
                    </div>
                    <div className="sidebar-search-input">
                      <div
                        className="search-bar site-header__search"
                        role="search"
                        style={{ position: "relative" }}
                      >
                        <div className="form-search">
                          <input
                            type="search"
                            name="q"
                            value={SearchName}
                            placeholder="Search our store"
                            id="search"
                            required=""
                            className="input-text"
                            autocomplete="off"
                            onChange={(e) => setSearchName(e.target.value)}
                          />
                          <button className="search-btn" type="submit">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="products mt-3">
                    <Row>
                      {displayPage
                        ?.filter((i) => {
                          if (SelectedCategory && SelectedBrand) {
                            if (
                              i?.productcategory?.toUpperCase() ==
                                SelectedCategory?.toUpperCase() &&
                              i?.productbrand?.toUpperCase() ==
                                SelectedBrand?.toUpperCase()
                            ) {
                              return true;
                            } else if (
                              i?.productcategory?.toUpperCase() ==
                                SelectedCategory?.toUpperCase() &&
                              i?.productbrand?.toUpperCase() !=
                                SelectedBrand?.toUpperCase()
                            ) {
                              return false;
                            } else if (
                              i?.productcategory?.toUpperCase() !=
                                SelectedCategory?.toUpperCase() &&
                              i?.productbrand?.toUpperCase() ==
                                SelectedBrand?.toUpperCase()
                            ) {
                              return false;
                            }
                            return false;
                          } else if (SelectedCategory && !SelectedBrand) {
                            if (
                              i?.productcategory?.toUpperCase() ==
                              SelectedCategory?.toUpperCase()
                            ) {
                              return true;
                            }
                            return false;
                          } else if (!SelectedCategory && SelectedBrand) {
                            if (
                              i?.productbrand?.toUpperCase() ==
                              SelectedBrand?.toUpperCase()
                            ) {
                              return true;
                            }
                            return false;
                          } else {
                            return true;
                          }
                        })

                        ?.filter((j) => {
                          if (
                            Math.round(
                              Number(j?.productprice) +
                                Math.round(j?.productprice * (j?.tax / 100)) -
                                (Number(j?.productprice) +
                                  Math.round(
                                    j?.productprice * (j?.tax / 100)
                                  )) *
                                  (j.distributordiscountpercentage / 100)
                            ) >= value[0] &&
                            Math.round(
                              Number(j?.productprice) +
                                Math.round(j?.productprice * (j?.tax / 100)) -
                                (Number(j?.productprice) +
                                  Math.round(
                                    j?.productprice * (j?.tax / 100)
                                  )) *
                                  (j.distributordiscountpercentage / 100)
                            ) <= value[1]
                          ) {
                            return true;
                          }
                          return false;
                        })

                        ?.filter((k) => {
                          if (ComboProduct) {
                            if (ComboProduct != k?.isCombo) {
                              return false;
                            }
                          }
                          if (FreeProduct) {
                            if (FreeProduct != k?.isFree) {
                              return false;
                            }
                          }
                          if (ByonegetoneProduct) {
                            if (ByonegetoneProduct != k?.isByOnegetFreeCust) {
                              return false;
                            }
                          }
                          return true;
                        })

                        ?.filter((l) => {
                          if (DiscountAbove50) {
                            if (
                              l?.distributordiscountpercentage > DiscountAbove50
                            ) {
                              return true;
                            }
                            return false;
                          }
                          if (DiscountUpto50) {
                            if (
                              l?.distributordiscountpercentage <= DiscountUpto50
                            ) {
                              return true;
                            }
                            return false;
                          }
                          if (DiscountUpto40) {
                            if (
                              l?.distributordiscountpercentage <= DiscountUpto40
                            ) {
                              return true;
                            }
                            return false;
                          }
                          if (DiscountUpto30) {
                            if (
                              l?.distributordiscountpercentage <= DiscountUpto30
                            ) {
                              return true;
                            }
                            return false;
                          }
                          if (DiscountUpto20) {
                            if (
                              l?.distributordiscountpercentage <= DiscountUpto20
                            ) {
                              return true;
                            }
                            return false;
                          }
                          if (DiscountUpto10) {
                            if (
                              l?.distributordiscountpercentage <= DiscountUpto10
                            ) {
                              return true;
                            }
                            return false;
                          }

                          return true;
                        })

                        ?.sort((a, b) => {
                          if (FilterAtoZ == "AtoZ") {
                            const nameA = a.productname.toUpperCase(); // ignore upper and lowercase
                            const nameB = b.productname.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }

                            return 0;
                          } else if (FilterAtoZ == "ZtoA") {
                            const nameA = a.productname.toUpperCase(); // ignore upper and lowercase
                            const nameB = b.productname.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                              return -1;
                            }
                            if (nameA < nameB) {
                              return 1;
                            }

                            return 0;
                          } else if (FilterAtoZ == "hightolow") {
                            if (
                              Number(a.distributorofferPrice) <
                              Number(b.distributorofferPrice)
                            ) {
                              return 1;
                            } else if (
                              Number(a.distributorofferPrice) >
                              Number(b.distributorofferPrice)
                            ) {
                              return -1;
                            } else {
                              return 0;
                            }
                          } else if (FilterAtoZ == "lowtohigh") {
                            if (
                              Number(a.distributorofferPrice) >
                              Number(b.distributorofferPrice)
                            ) {
                              return 1;
                            } else if (
                              Number(a.distributorofferPrice) <
                              Number(b.distributorofferPrice)
                            ) {
                              return -1;
                            } else {
                              return 0;
                            }
                          }
                        })

                        // search filter
                        ?.filter((m) => {
                          if (SearchName) {
                            if (
                              m?.productname
                                ?.toUpperCase()
                                .includes(SearchName?.toUpperCase())
                            ) {
                              return true;
                            }
                            return false;
                          }
                          return true;
                        })

                        ?.map((items, id) => {
                          return (
                            <Col lg={3} md={3} sm={3} key={id}>
                              <div className="single-product-wrap mb-3">
                                <div className="product-image">
                                  <a href="#" className="pro-img">
                                    <img
                                      className="img-fluid img1"
                                      src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                                      alt=""
                                      loading="lazy"
                                      style={{ width: "100%", height: "157px" }}
                                    />
                                  </a>
                                  <div className="product-label">
                                    <span className="sale-title">
                                      {items?.distributordiscountpercentage}%
                                    </span>
                                    <div className="product-action">
                                      <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                                        {wishlist?.filter(
                                          (item) =>
                                            item?.productId?._id === items._id
                                        )?.length ? (
                                          <Tooltip title="WISHLIST">
                                            {" "}
                                            <span
                                              class="add-wishlist"
                                              id="app-title"
                                              onClick={(e) =>
                                                removeProductFromwishlist(
                                                  items,
                                                  e
                                                )
                                              }
                                            >
                                              <i
                                                class="fa fa-heart"
                                                style={{ color: "Red" }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title="WISHLIST">
                                            {" "}
                                            <span
                                              class="add-wishlist"
                                              id="app-title"
                                              onClick={(e) =>
                                                addToWishlistDistributor(
                                                  items,
                                                  e
                                                )
                                              }
                                            >
                                              <i class="fa fa-heart"></i>
                                            </span>
                                          </Tooltip>
                                        )}

                                        {Carts?.filter(
                                          (item) =>
                                            item?.productId?._id === items._id
                                        )?.length ? (
                                          <Tooltip title="ADD TO CART">
                                            {" "}
                                            <span
                                              class="add-wishlist"
                                              onClick={(e) =>
                                                removeProductFromCart(items, e)
                                              }
                                            >
                                              <i
                                                class="fa fa-shopping-bag"
                                                style={{ color: "Yellow" }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title="ADD TO CART">
                                            {" "}
                                            <span
                                              class="add-wishlist"
                                              onClick={(e) =>
                                                addToCartDistributor(items, e)
                                              }
                                            >
                                              <i class="fa fa-shopping-bag"></i>
                                            </span>
                                          </Tooltip>
                                        )}
                                        <Tooltip title="QUICKVIEW">
                                          {" "}
                                          <span
                                            class="add-wishlist"
                                            // onClick={showModal}
                                            onClick={() => {
                                              setImage(items?.productimage[0]);
                                              setCurritem(items);
                                              setslePrice(0);
                                              showModal(items);
                                            }}
                                          >
                                            <i class="fa fa-eye"></i>
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="product-content mt-4">
                                  <div className="product-title mb-1">
                                    <Link to={`/distributor/distributor-product/${items?._id}`}>
                                      {items?.productname}
                                    </Link>
                                  </div>
                                  {/* <div className="product-ratting mb-1">
  <span
    className="spr-badge"
    id="spr_badge_6820894441621"
    data-rating="2.0"
  >
    <span class="spr-starrating spr-badge-starrating">
      <i
        class="fa fa-star"
        aria-hidden="true"
      ></i>
      <i
        class="fa fa-star"
        aria-hidden="true"
      ></i>
      <i
        class="fa fa-star"
        aria-hidden="true"
      ></i>
      <i
        class="fa fa-star"
        aria-hidden="true"
      ></i>
      <i
        class="fa fa-star"
        aria-hidden="true"
      ></i>
    </span>
  </span>
</div> */}
                                  <div className="price-box mb-2">
                                    <span
                                      className="new-price"
                                      id="ProductPrice"
                                    >
                                      ₹
                                      {Math.round(
                                        Number(items?.productprice) +
                                          Math.round(
                                            items?.productprice *
                                              (items?.tax / 100)
                                          ) -
                                          (Number(items?.productprice) +
                                            Math.round(
                                              items?.productprice *
                                                (items?.tax / 100)
                                            )) *
                                            (items.distributordiscountpercentage /
                                              100)
                                      )}
                                    </span>
                                    <span
                                      className="old-price"
                                      id="ComparePrice"
                                    >
                                      ₹
                                      {Number(items?.productprice) +
                                        Math.round(
                                          items?.productprice *
                                            (items?.tax / 100)
                                        )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>

                  {/* Pagination */}

                  <div>
                    <ReactPaginate
                      pageCount={pageCount}
                      onPageChange={changePage}
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      containerClassName=" paginationBttns "
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        {/* Modal */}
        {/* <div className="qui_hero">
          <Modal
            title="Quickview"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="pro-quick">
              <div className="quick-view">
                <div className="quick-view_hero">
                  <a href="#">
                    <img src="/webImages/1.jpg" className="img-fluid" alt="" />
                  </a>
                </div>
              </div>
              <div className="quick-view">
                <div className="hero_quick">
                  <div className="product-content ">
                    <div className="product-title mb-1">
                      <a href="#">Fruits banana 100% organic</a>
                    </div>

                    <div className="price-box mb-1">
                      <span className="new-price" id="ProductPrice">
                        ₹550
                      </span>
                      <span className="old-price" id="ComparePrice">
                        ₹44,00
                      </span>
                    </div>
                    <div className="more-de mb-1">
                      <p className="simple-0">More Details</p>
                      <p className="lorem-09">
                        Lorem Ipsum is simply dummy text of the printing and
                        typ.......
                      </p>
                    </div>
                    <div className="quick-view-select mb-2">
                      <div className="selector-wrapper">
                        <label>Size :</label>
                        <select
                          class="single-option-selector"
                          data-option="option1"
                          id="product-select-qv-option-0"
                        >
                          <option value="1kg">1kg</option>
                          <option value="2kg">2kg</option>
                          <option value="5kg">5kg</option>
                        </select>
                      </div>
                    </div>
                    <div className="quick-view-select mb-2">
                      <div className="selector-wrapper">
                        <label>Material :</label>
                        <select
                          class="single-option-selector"
                          data-option="option1"
                          id="product-select-qv-option-0"
                        >
                          <option value="1kg">India</option>
                        </select>
                      </div>
                    </div>
                    <div className="product-quantity mb-3">
                      <div className="cart-plus-minus">
                        <div className="dec qtybutton">-</div>
                        <input
                          value="1"
                          type="text"
                          name="quantity"
                          min="1"
                          pattern="[0-9]*"
                        />
                        <div className="inc qtybutton">+</div>
                      </div>
                    </div>
                    <div className="product-action-2">
                      <div className="action-wishlist">
                        <Tooltip title="WISHLIST">
                          {" "}
                          <span
                            class="add-wishlist"
                            id="app-title"
                            style={{ opacity: "1" }}
                          >
                            <i class="fa fa-heart"></i>
                          </span>
                        </Tooltip>
                        <Tooltip title="ADD TO CART">
                          {" "}
                          <span class="add-wishlist" style={{ opacity: "1" }}>
                            <i class="fa fa-shopping-bag"></i>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div> */}



<div className="qui_hero">
        <Modal
          title="Quickview"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="pro-quick">
            <div className="quick-view">
              <div className="quick-view_hero">
                <a href="#">
                  <img
                    src={`https://spilso.in/admin/product/${image}`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="quick-view">
              <div className="hero_quick">
                <div className="product-content ">
                  <div className="product-title mb-1">
                    <a href="#">{Currentitem?.productname}</a>
                  </div>

                  <div className="price-box mb-1">
                    {selPrice !== 0 ? (
                      <span className="new-price" id="ProductPrice">
                        {selQuntity}
                        {Currentitem?.productvolumetype}
                        {"-"}₹
                        {Math.round(
                          (Number(selPrice) +
                            Math.round(selPrice * (Currentitem?.tax / 100)) -
                            (Number(selPrice) +
                              Math.round(selPrice * (Currentitem?.tax / 100))) *
                              (Currentitem.distributordiscountpercentage /
                                100)) *
                            quantity
                        )}
                      </span>
                    ) : (
                      <span className="new-price" id="ProductPrice">
                        ₹
                        {Math.round(
                          (Number(Currentitem?.productprice) +
                            Math.round(
                              Currentitem?.productprice *
                                (Currentitem?.tax / 100)
                            ) -
                            (Number(Currentitem?.productprice) +
                              Math.round(
                                Currentitem?.productprice *
                                  (Currentitem?.tax / 100)
                              )) *
                              (Currentitem.distributordiscountpercentage /
                                100)) *
                            quantity
                        )}
                      </span>
                    )}
                    {selPrice !== 0 ? (
                      <span className="old-price" id="ComparePrice">
                        ₹
                        {Number(selPrice * quantity) +
                          Math.round(
                            selPrice * quantity * (Currentitem?.tax / 100)
                          )}
                      </span>
                    ) : (
                      <span className="old-price" id="ComparePrice">
                        ₹
                        {Number(Currentitem?.productprice * quantity) +
                          Math.round(
                            Currentitem?.productprice *
                              quantity *
                              (Currentitem?.tax / 100)
                          )}
                      </span>
                    )}
                  </div>
                  <div className="more-de mb-1">
                    <p className="simple-0">More Details</p>
                    <p className="lorem-09">
                      {showMore
                        ? Currentitem?.productdesc
                        : `${Currentitem?.productdesc?.substring(0, 110)}`}
                      <button
                        className="btn"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </button>
                    </p>
                  </div>
                  <div className="quick-view-select mb-2">
                    <div className="selector-wrapper">
                      <label>Size :</label>
                      <select
                        class="single-option-selector"
                        data-option="option1"
                        id="product-select-qv-option-0"
                        onChange={(e) => {
                          setslePrice(JSON.parse(e.target.value)?.quntityPrice);
                          setselQuntity(JSON.parse(e.target.value)?.perQuntity);
                        }}
                      >
                        {Currentitem?.AllPrice?.map((ele) => {
                          return (
                            <option value={JSON.stringify(ele)}>
                              {ele?.perQuntity}
                              {Currentitem?.productvolumetype}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="quick-view-select mb-2">
                    <div className="selector-wrapper">
                      <label>Discount :</label>
                      <span>{Currentitem?.distributordiscountpercentage}%</span>
                    </div>
                  </div>
                  <div className="cart-plus-minus">
                    <div className="dec qtybutton" onClick={decQuntity}>
                      -
                    </div>
                    <input
                      value={quantity}
                      type="text"
                      name="quantity"
                      min="1"
                      pattern="[0-9]*"
                    />
                    <div className="inc qtybutton" onClick={incQuntity}>
                      +
                    </div>
                  </div>
                  <div className="product-action-2">
                    <div className="action-wishlist">
                      <Tooltip title="WISHLIST">
                        {" "}
                        <span
                          class="add-wishlist"
                          id="app-title"
                          style={{ opacity: "1" }}
                        >
                          <i
                            class="fa fa-heart"
                            onClick={(e) =>
                              addToWishlistDistributor(Currentitem, e)
                            }
                          ></i>
                        </span>
                      </Tooltip>
                      <Tooltip title="ADD TO CART">
                        {" "}
                        <span class="add-wishlist" style={{ opacity: "1" }}>
                          <i
                            class="fa fa-shopping-bag"
                            onClick={(e) =>
                              addToCartDistributor(Currentitem, e)
                            }
                          ></i>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>




      </div>
    </>
  );
}

export default DistributorProduct;
