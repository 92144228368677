import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";

function Ltr() {
  const [newProduct, setnewProduct] = useState([]);
  const newGetProducts = async () => {
    try {
      const config = {
        url: "/letestProduct",
        method: "get",
        baseURL: "https://spilso.in/api/admin",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setnewProduct(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  useEffect(() => {
    newGetProducts();
  }, []);
  useEffect(() => {
    newGetProducts();
  }, []);

  return (
    <>
      <section
        class="latest-product spad"
        style={{ background: "#d3d3d33b",paddingTop:"20px",marginTop:"11px",marginBottom:"40px" }}
      >
        <div class="container">
          <div class="section-title">
            <h2>Suggested Deals</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div class="latest-product__text">
                <h4>Combo Products</h4>
                <div class="latest-product__slider owl-carousel-0">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    items={1}
                    nav
                    dots={false}
                    autoplay={true}
                  >
                    {newProduct
                      ?.filter((ele) => ele?.remainingstock > ele?.minimumStock)
                      ?.filter((ele) => ele?.isCombo === true)
                      ?.map((items) => {
                        return (
                          <div class="item">
                            <div class="latest-prdouct__slider__item">
                              <a
                                href={`/single-pages/${items?._id}`}
                                class="latest-product__item"
                              >
                                <div class="latest-product__item__pic">
                                  <img
                                    src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                                    alt=""
                                  />
                                </div>
                                <div class="latest-product__item__text">
                                  <h6>
                                    <a href={`/single-pages/${items?._id}`}>
                                      {items?.productname}
                                    </a>
                                  </h6>
                                  <span>
                                    ₹
                                    {Math.round(
                                      Number(items?.productprice) +
                                        Math.round(
                                          items?.productprice *
                                            (items?.tax / 100)
                                        ) -
                                        (Number(items?.productprice) +
                                          Math.round(
                                            items?.productprice *
                                              (items?.tax / 100)
                                          )) *
                                          (items.customerdiscountpercentage /
                                            100)
                                    )}
                                  </span>
                                </div>
                                <img
                                  src="/combo.jpg"
                                  alt="g"
                                  style={{ width: "50px", height: "42px" }}
                                />
                              </a>
                              {/* <a href="#" class="latest-product__item">
                                <div class="latest-product__item__pic">
                                  <img src="/img/latest-product/lp-2.jpg" alt="" />
                                </div>
                                <div class="latest-product__item__text">
                                  <h6>Crab Pool Security</h6>
                                  <span>₹30.00</span>
                                </div>
                                <img src="/combo.jpg" alt="g" style={{width:"50px",height:"42px"}}/>
                              </a>
                              <a href="#" class="latest-product__item">
                                <div class="latest-product__item__pic">
                                  <img src="/img/latest-product/lp-3.jpg" alt="" />
                                </div>
                                <div class="latest-product__item__text">
                                  <h6>Crab Pool Security</h6>
                                  <span>₹30.00</span>
                                </div>
                                <img src="/combo.jpg" alt="g" style={{width:"50px",height:"42px"}}/>
                              </a> */}
                            </div>
                          </div>
                        );
                      })}

                    {/* <div class="item">
                      <div class="latest-prdouct__slider__item">
                        <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-1.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/combo.jpg" alt="g" style={{width:"50px",height:"42px"}}/>
                        </a>
                        <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-2.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/combo.jpg" alt="g" style={{width:"50px",height:"42px"}}/>
                        </a>
                        <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-3.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/combo.jpg" alt="g" style={{width:"50px",height:"42px"}}/>
                        </a>
                      </div>
                    </div> */}
                  </OwlCarousel>
                  <div className="arrow-co">
                    <a href="">
                      {" "}
                      <BsArrowRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div class="latest-product__text">
                <h4>Free Products</h4>
                <div class="latest-product__slider owl-carousel-0">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    items={1}
                    nav
                    dots={false}
                    autoplay={true}
                  >
                    {/* <div class="item">
                      <div class="latest-prdouct__slider__item">
                        <a href="#" class="latest-product__item">
                          <s>Free</s>
                          <div class="latest-product__item__pic">
                        
                            <img src="/img/latest-product/lp-1.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/5.png" alt="g" style={{width:"50px",height:"42px"}}/>
                        </a>
                        <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-2.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/5.png" alt="g" style={{width:"50px",height:"42px"}}/>
                        </a>
                        <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-3.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/5.png" alt="g" style={{width:"50px",height:"42px"}}/>
                        </a>
                      </div>
                    </div> */}
                    {newProduct
                      ?.filter((ele) => ele?.remainingstock > ele?.minimumStock)
                      ?.filter((ele) => ele?.isFree === true)
                      ?.map((items) => {
                        return (
                          <div class="item">
                            <div class="latest-prdouct__slider__item">
                              {/* <s>Free</s> */}
                              <a
                                href={`/single-pages/${items?._id}`}
                                class="latest-product__item"
                              >
                                <div class="latest-product__item__pic">
                                  <img
                                    src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                                    alt=""
                                  />
                                </div>
                                <div class="latest-product__item__text">
                                  <h6>
                                    <a href={`/single-pages/${items?._id}`}>
                                      {items?.productname}
                                    </a>
                                  </h6>
                                  <span>
                                    ₹
                                    {Math.round(
                                      Number(items?.productprice) +
                                        Math.round(
                                          items?.productprice *
                                            (items?.tax / 100)
                                        ) -
                                        (Number(items?.productprice) +
                                          Math.round(
                                            items?.productprice *
                                              (items?.tax / 100)
                                          )) *
                                          (items.customerdiscountpercentage /
                                            100)
                                    )}
                                  </span>
                                </div>
                                <img
                                  src="/5.png"
                                  alt="g"
                                  style={{ width: "50px", height: "42px" }}
                                />
                              </a>

                              {/* <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-2.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/5.png" alt="g" style={{width:"50px",height:"42px"}}/>
                        </a>
                        <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-3.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/5.png" alt="g" style={{width:"50px",height:"42px"}}/>
                        </a> */}
                            </div>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                  <div className="arrow-co">
                    <a href="">
                      {" "}
                      <BsArrowRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div class="latest-product__text">
                <h4>Buy 1 Get 1 </h4>
                <div class="latest-product__slider owl-carousel-0">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    items={1}
                    nav
                    dots={false}
                    autoplay={true}
                  >
                    <div class="item">
                      <div class="latest-prdouct__slider__item">
                        {newProduct
                          ?.filter(
                            (ele) => ele?.remainingstock > ele?.minimumStock
                          )
                          ?.filter((ele) => ele?.isByOnegetFreeCust === true)
                          ?.map((items) => {
                            return (
                              <a
                                href={`/single-pages/${items?._id}`}
                                class="latest-product__item"
                              >
                                <div class="latest-product__item__pic">
                                  <img
                                    src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                                    alt=""
                                  />
                                </div>
                                <div class="latest-product__item__text">
                                  <h6>
                                    {" "}
                                    <a href={`/single-pages/${items?._id}`}>
                                      {items?.productname}
                                    </a>
                                  </h6>
                                  <span>
                                    {" "}
                                    ₹
                                    {Math.round(
                                      Number(items?.productprice) +
                                        Math.round(
                                          items?.productprice *
                                            (items?.tax / 100)
                                        ) -
                                        (Number(items?.productprice) +
                                          Math.round(
                                            items?.productprice *
                                              (items?.tax / 100)
                                          )) *
                                          (items.customerdiscountpercentage /
                                            100)
                                    )}
                                  </span>
                                </div>
                                <img
                                  src="/free1.png"
                                  alt=""
                                  style={{ width: "50px", height: "42px" }}
                                />
                              </a>
                            );
                          })}

                        {/* <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-2.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/free1.png"alt="" style={{width:"50px",height:"42px"}}/>
                        </a>
                        <a href="#" class="latest-product__item">
                          <div class="latest-product__item__pic">
                            <img src="/img/latest-product/lp-3.jpg" alt="" />
                          </div>
                          <div class="latest-product__item__text">
                            <h6>Crab Pool Security</h6>
                            <span>₹30.00</span>
                          </div>
                          <img src="/free1.png"alt="" style={{width:"50px",height:"42px"}}/>
                        </a> */}
                      </div>
                    </div>
                  </OwlCarousel>
                  <div className="arrow-co">
                    <a href="">
                      {" "}
                      <BsArrowRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ltr;
