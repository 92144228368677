import { Button } from "@material-ui/core";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function Retailerfavourite(props) {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [product, setproduct] = useState();
  const [like, setLike] = useState([]);

  useEffect(() => {
    getsubcategoryproduct();
  }, []);
  useEffect(() => {
    getlike();
  }, []);

  const getsubcategoryproduct = async () => {
    let res = await axios.get(apiurl + "/getuserfav/" + user._id);
    if ((res.status = 200)) {
      console.log(res.data);
      setproduct(res.data?.favourite);
    }
  };
  let Onlike = async (product) => {
    try {
      let config1 = {
        url: "/postlike",
        method: "post",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: {
          productid: product.product[0]._id,
          userid: user._id,
        },
      };
      let res = await axios(config1);
      if (res.status === 200) {
        alert("Added to Wishlist");
        getlike();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getlike = async (product) => {
    let res = await axios.get(apiurl + "/getlike/" + `${user._id}`);
    if (res.status === 200) {
      console.log(res.data);
      setLike(res.data.favourite);
    }
  };

  const deletelike = async (product) => {
    let res = await axios.post(
      apiurl +
        "/getdeletelike/" +
        `${user._id}` +
        "/" +
        `${product.product[0]._id}`
    );
    if (res.status === 200) {
      console.log(res.data);
      alert("Removed from Wishlist");
      window.location.reload();
    }
  };
  return (
    <div
      className=""
      style={{ background: "f4f4f4", marginTop: "1rem", marginBottom: "4rem" }}
    >
      <div className="px-3 pb-3  title d-flex align-items-center">
        <h5 className="m-0 pt-4">Wishlist</h5>
      </div>
      <div className="px-3 container">
        <Row className="">
          {product?.length > 0 ? (
            <>
              {product?.map((data) => (
                <Col lg={4} md={3}>
                  <Card style={{ width: "18rem" }}>
                    {data.product[0].type === "admin" ? (
                      <img
                        alt=""
                        src={
                          imageURL +
                          "/admin/product/" +
                          data.product[0].productimage[0]
                        }
                        // className="img-fluid item-img w-100"
                      ></img>
                    ) : (
                      <img
                        alt=""
                        src={
                          imageURL +
                          "/vendor/product/" +
                          data.product[0].productimage[0]
                        }
                        // className="img-fluid item-img w-100"
                      ></img>
                    )}
                    <Card.Body>
                      <div className="d-flex">
                        <h5 className="mb-1 text-center">
                          {data.product[0].productname}
                        </h5>
                        <div className="mx-auto">
                          {like.length > 0 ? (
                            like.find(
                              (item) => item.productid === data.product[0]._id
                            )?._id ? (
                              <i
                                class="fa fa-heart"
                                style={{
                                  fontSize: "24px",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                                onClick={() => deletelike(data)}
                              ></i>
                            ) : (
                              <i
                                class="	fa fa-heart-o"
                                style={{
                                  fontSize: "24px",
                                  color: "#0d6efd",
                                  fontWeight: "bold",
                                }}
                                onClick={() => Onlike(data)}
                              ></i>
                            )
                          ) : (
                            <i
                              class="	fa fa-heart-o"
                              style={{
                                fontSize: "24px",
                                color: "#0d6efd",
                                fontWeight: "bold",
                              }}
                              onClick={() => Onlike(data)}
                            ></i>
                          )}
                        </div>
                      </div>
                      <div style={{ fontSize: "large" }}>
                        MRP ₹{" "}
                        <span>
                          <s>{data.product[0].productprice}</s>{" "}
                        </span>
                      </div>
                      <div
                        className="text-gray mb-1 "
                        style={{ fontSize: "x-large", color: "#dc3545" }}
                      >
                        ₹ {data.product[0].retailerofferPrice}
                      </div>

                      <div className="list-card-badge  mt-2">
                        {" "}
                        <span
                          className="badge badge-warning"
                          style={{
                            color: "#fb1e1e",
                            backgroundColor: "yellow",
                          }}
                        >
                          SAVE
                        </span>{" "}
                        <large>
                          {data.product[0].retailerdiscountpercentage}% Off
                        </large>{" "}
                      </div>
                      <Button
                        variant="contained"
                        className="mt-2 fw-bold"
                        style={{
                          backgroundColor: "#4d90fe",
                          color: "white",
                        }}
                        onClick={() => {
                          props.dispatch({
                            type: "addBasketItem",
                            item: {
                              product: data.product[0],
                              quantity: 1,
                            },
                          });
                          alert("Product is Added to cart");
                        }}
                      >
                        Add to Cart
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          ) : (
            <h5 className="m-auto mt-2">No Product Found</h5>
          )}
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    Subscribe: state.Subscribe,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Retailerfavourite);
