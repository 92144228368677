import React, { useEffect, useState } from "react";
import { Card, Carousel, Container, Row, Col } from "react-bootstrap";
import "../Styles/style12.css";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import Slider1 from "./Slider1";
import Featured1 from "./Featured1";
import Ltr from "./Ltr";
import DiscountPages from "./DiscountPages";
import OursBrand from "./OursBrand";
import NewArrivals from "./NewArrivals";
import BeatSellers from "./BestSeller";
import Testimonials from "./Testimonials";
import axios from "axios";
import { Link } from "react-router-dom";

function Home1() {
  const [sdec, setsdec] = useState(true);
  const [banner, setbanner] = useState([]);
  const [graph, setgraph] = useState([]);
  const logOut = async () => {
    try {
      const config = {
        url: "/getAllgraph",
        method: "get",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setgraph(res.data.success);
        getAllBanner();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllBanner = async () => {
    try {
      const config = {
        url: "/getallbanner/CustomerHome",
        method: "get",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setbanner(res.data.banner);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [category, setcategory] = useState([]);
  const getcategory = () => {
    axios
      .get("https://spilso.in/api/getcategory")
      .then(function (response) {
        // handle success
        // console.log(response.data);
        setcategory(response.data.category);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    logOut();
    getAllBanner();
    getcategory();
  }, []);

  const [search, setsearch] = useState("");
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="hero__categories">
                <div
                  className="hero__categories__all"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <i className="fa fa-bars" />
                    <span>All departments</span>
                  </div>
                  <div
                    className=""
                    onClick={() => {
                      setsdec(!sdec);
                    }}
                  >
                    {sdec ? (
                      <>
                        <RiArrowDownSLine
                          style={{ fontSize: "20px", color: "white" }}
                        />
                      </>
                    ) : (
                      <>
                        <RiArrowUpSLine
                          style={{ fontSize: "20px", color: "white" }}
                        />
                      </>
                    )}
                  </div>
                </div>
                {sdec ? (
                  <>
                    <ul>
                      {category?.map((item) => (
                        <li>
                          <Link
                            to={{
                              pathname: "/products",
                              state: { category: item?.categoryName },
                            }}
                          >
                           &#x27A4; {item?.categoryName}
                          </Link>
                        </li>
                      ))}
                      {/* <li>
                        <a href="#">Fresh Meat</a>
                      </li>
                      <li>
                        <a href="#">Vegetables</a>
                      </li>
                      <li>
                        <a href="#">Fruit &amp; Nut Gifts</a>
                      </li>
                      <li>
                        <a href="#">Fresh Berries</a>
                      </li>
                      <li>
                        <a href="#">Ocean Foods</a>
                      </li>
                      <li>
                        <a href="#">Butter &amp; Eggs</a>
                      </li>
                      <li>
                        <a href="#">Fastfood</a>
                      </li>
                      <li>
                        <a href="#">Fresh Onion</a>
                      </li>
                      <li>
                        <a href="#">Papayaya &amp; Crisps</a>
                      </li>
                      <li>
                        <a href="#">Oatmeal</a>
                      </li>
                      <li>
                        <a href="#">Fresh Bananas</a>
                      </li> */}
                    </ul>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="hero__search">
                <div className="hero__search__form">
                  <form action="#">
                    <div className="hero__search__categories">
                      All Categories
                      <span className="arrow_carrot-down" />
                    </div>
                    <input
                      type="text"
                      placeholder="What do yo u need?"
                      value={search}
                      onChange={(e) => setsearch(e.target.value)}
                    />
                    <Link
                      to={{ pathname: "/products", state: { search: search } }}
                    >
                      <button type="submit" className="site-btn">
                        SEARCH
                      </button>
                    </Link>
                  </form>
                </div>
                <div className="hero__search__phone">
                  <div className="hero__search__phone__icon">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="hero__search__phone__text">
                    <h5>+91 9845091018</h5>
                    <span>support 24/7 time</span>
                  </div>
                </div>
              </div>
              <div className="hero__item set-bg" style={{ paddingLeft: "0px" }}>
                <section>
                  <div className="hom-banner">
                    <Carousel>
                      {graph
                        ?.filter((ele) => ele?.type === "HomeCustomer")
                        ?.map((item) => {
                          return (
                            <Carousel.Item>
                              <a href={item?.link}>
                                {" "}
                                <img
                                  className="d-block w-100"
                                  style={{ height: "411px" }}
                                  src={`https://spilso.in/graph/${item?.image}`}
                                  alt="First slide"
                                />{" "}
                              </a>
                            </Carousel.Item>
                          );
                        })}
                      
                    </Carousel>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Slider1 />
      <Featured1 />
      <section className="common-use">
        <div className="banner-card">
          <Container>
            <div className="banner_he">
              <Row>
                {banner?.map((item) => {
                  return (
                    <Col lg={6} md={6} sm={6}>
                      <div class="banner-hover">
                        <a href="#">
                          <img
                            class="img-fluid"
                            src={`https://spilso.in/banner/${item?.banner}`}
                            loading="lazy"
                            style={{ width: "100%", height: "260px" }}
                          />
                        </a>
                        <div class="banner-content">
                          <h3>{item?.text}</h3>

                          <a href={item?.link} class="btn btn-style1">
                            Shop now
                          </a>
                        </div>
                      </div>
                    </Col>
                  );
                })}
                
              </Row>
            </div>
          </Container>
        </div>
      </section>
      <NewArrivals />
      <BeatSellers />
      <Ltr />
      <DiscountPages />
      <OursBrand />
      <Testimonials />
    </>
  );
}

export default Home1;
