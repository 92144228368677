import React from "react";
import { Container } from "react-bootstrap";

function Refundpolicy() {
  return (
    <div className="mt-4 p-5">
      <Container>
        <h5>RETURN, EXCHANGE &amp; REFUND</h5>
        <p>
          <b>1) Return &amp; Refund Policy:</b>
        </p>
        <p>
          We endeavour to provide the best quality products every single time
          you order with us.
        </p>
        <p>
          We have a &quot;no questions asked return and refund policy&quot;
          which entitles all our customers to return the product at the time of
          delivery if they are not satisfied with the quality, freshness or
          physical condition of the product. At the time of delivery, we will
          take the returned product back with us and if already paid the
          corresponding value would be refunded to you through the same mode of
          payment used at the time of purchase viz., credit card, debit card,
          net banking. Alternatively, at your option, the said amount can be
          credited to your store credit account which can be used for your
          subsequent purchases.
        </p>
        <p>
          After the delivery, the amount shall be refunded to you through the
          same mode of payment or via credit to your store credit account which
          can be used for subsequent purchases.
        </p>
        <p>
          Products once sold and delivered to you after checking and accepted by
          the delivery time will not be taken back or exchanged at any cause
        </p>
        <p>We do not make any cash refunds.</p>
        <p>
          In case of prepaid orders, if any part of it remains short delivered
          due to some reason, corresponding value would be refunded to you
          through the same mode of payment used at the time of purchase viz.,
          credit card, debit card, net banking .
        </p>
        <p>
          The amount will be refunded to you between 3-15 working days depending
          upon the mode of payment chosen by you. Sometimes banks or financial
          intermediaries take a longer time to process the refund request.
          However, if the refund does not happen by the date advised, you may
          contact us and we will gladly help you. Alternatively at your option,
          the said amount can be credited to your store credit account which can
          be used for your subsequent purchases.
        </p>
        <p>
          <b>2) Exchange Policy:</b>
        </p>
        <p>
          All products listed on our Website and application can be exchanged or
          returned subject to the following conditions:
        </p>
        <p>
          Product Condition: A product cannot be exchanged or returned unless it
          is a defective, broken, faulty, leaking or not of satisfactory
          quality. In case of return/exchange you must return the product to us
          in the same original condition as is supplied by us at the time of
          delivery itself not after accepting the delivery. Products, under the
          following instances, cannot be accepted by us as/under Return:
        </p>
        <p>i. damages due to mishandling of product;</p>
        <p>ii. products with tampered or missing labels;</p>
        <p>
          iii. Products without all original packaging and accessories,
          including the box, manufacturer&#39;s packaging if any, original
          invoice and all other items originally included with the product(s)
          delivered.
        </p>
        <p>
          iv. Product Accessories / Freebies / Bundles &amp; Combos: All the
          product accessories available with the product and / or all the
          freebies associated with the product and / or other products
          associated as &quot;bundles&quot; or &quot;combos&quot; with the
          product should also be returned so as to enable us to initiate the
          exchange or return process.
        </p>
        <p>
          <b>3) Cancellation Policy:</b>
        </p>
        <p>
          You can cancel your order fully or partially anytime up to the cut-off
          time of the slot for which you have placed an order by calling our
          customer service. In such a case we shall refund any payments already
          made by you for the order as per the prevailing Refund Policy.
        </p>
        <p>
          Please contact us at 08105881733 or email us at: Info@ilifeshoppie.com
          for cancellation or modification of your Order.
        </p>
        <p>
          On receipt of the cancellation notice we shall cancel the order and
          refund the entire amount if the order has not been processed by us.
          Cancellation of Order can&#39;t be done/accepted once the products
          under your order is released for dispatch/ shipped out from our
          delivery location.
        </p>
      </Container>
    </div>
  );
}

export default Refundpolicy;
