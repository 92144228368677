import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-multi-date-picker";
import { Link } from "react-router-dom";

function Admincustomerorders() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [data3, setData3] = useState({});
  
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  useEffect(() => {
    getcustomer();
  }, []);

  const showModal = () => {
    setIsOpen(true);
  };
  const hideModal = () => {
    setIsOpen(false);
  };

  const getcustomer = () => {
    try {
      axios
        .get(`${apiURL}/customer/order`)
        .then(function (response) {
          if (response.status === 200) {
        
            setdata(response.data.orders?.filter((ele)=>ele.customerId ));
       
          }
        })
       
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);

    }
  };
  
  const [dueDate,setDuedate]=useState("")
  const updateDuedate=async ()=>{
    if(!dueDate) return alert("Please select due date")
    try {
      const config = {
        url: "/admin/updateDuedate",
        method: "put",
        baseURL: apiURL,
        // data: formdata,
        headers: { "content-type": "application/json" },
        data: {orderId:data3?._id,duedatetime:dueDate}}
        let res=await axios(config);
        if(res.status===200){
          alert("Successfully accepted")
          getcustomer()
          hideModal()
        }
    } catch (error) {
      console.log(error);
    }
  }
  const makeCancel=async ()=>{
    try {
      let res=await axios.put(`${apiURL}/cancel/${data3?._id}`);
      if(res.status==200){
        alert("Successfully cancelled order")
        getcustomer()
        hideModal1()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/customers/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      text: "",
      sort: true,
    },
    {
      dataField: "_id",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "customerId.name",
      text: "Customer Name",
      sort: true,
    },
    {
      dataField: "paymentmethod",
      text: "Method of Payment",
    },
    {
      dataField: "payid",
      text: "Payment Id",
    
    },
    {
       dataField: "allTotal",
      text: "Total Amount",
     
    },
    {
      dataField: "",
      text: "Placed On",
      formatter:(cell,row)=>{
        return (<h6>{moment(row.createdAt).startOf('hour').fromNow()}</h6>)
      }
    },
    {
      // dataField: "address[0].doorno",
      text: "Delivery Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
           
            {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
            {row?.state}, {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    {
      dataField: "duedatetime",
      text: "Due Date",
    },
    {
      dataField: "status",
      text: "Orders Status",
    
    },
    {
      // dataField: "",
      text: "Orders",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-warning">
              <Link
                to={{ pathname: "/admin/orderview", state: { data: row } }}
                style={{ textDecoration: "none", color: "black" }}
              >
                View
              </Link>
            </button>
          </div>
        );
      },
    },

    {
      // dataField: "",
      text: "Action",
      formatter: (cell, row) => {
    
        return (
          <div style={{display:"flex",gap:"5px"}}>
            {row?.duedatetime ? ( <></>):(<>{row?.status==="cancelled" ? (<></>):(<button className="btn btn-info"  onClick={()=>{showModal()
            setData3(row)}}>  
                Accept
            </button>)}</>)}
            {row?.status === "delivered" ? (<></>):(<>{row?.status==="cancelled" ? (<></>):(<button className="btn btn-danger"  onClick={()=>{showModal1()
            setData3(row)}}>
                Cancel
            </button>)}</> )}
           
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c", display: "flex" }}>
          Customer Orders
          <Link to="/admin/customerorders">
            <button className="btn btn-primary" style={{ margin: "0px 10px" }}>
              All
            </button>
          </Link>
          <Link to="/admin/inprocess">
            <button className="btn btn-info" style={{ margin: "0px 10px" }}>
              Inprocess
            </button>
          </Link>
          <Link to="/admin/outfordelivery">
            <button className="btn btn-warning" style={{ margin: "0px 10px" }}>
              Out For Delivery
            </button>
          </Link>
          <Link to="/admin/delivered">
            <button className="btn btn-success" style={{ margin: "0px 10px" }}>
              Delivered
            </button>
          </Link>
          <Link to="/admin/canceled">
            <button className="btn btn-danger" style={{ margin: "0px 10px" }}>
              Canceled
            </button>
          </Link>
        </h1>
        <Modal show={isOpen} onHide={hideModal}>
          <Modal.Header>
            <Modal.Title>Add Due date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
            <h3>Due Date</h3>
            <div className="col-mb-6">
               <DatePicker
                  style={{ width: "466px", height: "38px" }}
                  format="DD/MM/YYYY"
                  type="input-icon"
                  minDate={new Date()}
                  placeholder="Due Date"
                  value={dueDate}
                onChange={(dueDate) => setDuedate(dueDate.format("DD/MM/YYYY"))}
               
              />
            </div>
             
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={hideModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={updateDuedate}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
         {/* deleteMode */}
       <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Are you sure cancel order!</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Back
            </Button>
            <Button
              variant="danger"
              style={{ cursor: "pointer" }}
              onClick={() => makeCancel()}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "customer.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />
                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="customer"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admincustomerorders;
