import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function Localshop(props) {
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const pincode = sessionStorage.getItem("Pincode");
  const [localshop, setlocalshop] = useState([]);

  useEffect(() => {
    getlocalshops();
  }, []);

  const getlocalshops = async () => {
    let res = await axios.get(apiurl + "/getlocalshop/" + pincode);
    if ((res.status = 200)) {
      console.log(res.data);
      setlocalshop(res.data?.localshop);
    }
  };

  return (
    <div className="container mt-5">
      <Row xs={1} md={4} className="g-4">
        {localshop?.map((data) => (
          <Col>
            <Link
              to="/localshopproduct"
              onClick={() => {
                props.dispatch({ type: "emptyBasket" });
                props.dispatch({
                  type: "localshop",
                  item: { localshop: data },
                });
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card>
                {data.shopimg ? (
                  <>
                    {" "}
                    <Card.Img
                      variant="top"
                      src={`${imageURL}/retailer/${data.shopimg}`}
                      style={{ height: "232px" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <Card.Img
                      variant="top"
                      src={
                        "https://cdn.vectorstock.com/i/1000x1000/08/28/shop-store-flat-icon-vector-14270828.webp"
                      }
                      style={{ height: "232px" }}
                    />
                  </>
                )}
                <Button
                  component={Link}
                  to="/localshopproduct"
                  onClick={() => {
                    props.dispatch({ type: "emptyBasket" });
                    props.dispatch({
                      type: "localshop",
                      item: { localshop: data },
                    });
                  }}
                >
                  {/* {data.type=="admin" ? <Card.Img variant="top" src={imageURL+ "/admi/product/"  + data.productimage[0]} /> : <Card.Img variant="top" src={imageURL+ "/vendor/product/"  + data.productimage[0]} />} */}

                  <Card.Body>
                    <Card.Title>{data.firmname}</Card.Title>
                    <Card.Text>
                      <b>{data.pincode}</b>
                      <b>{data.addresslandmark}</b>
                    </Card.Text>
                  </Card.Body>
                </Button>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    localshop: state.localshop,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Localshop);
