import axios from "axios";
import React, { useEffect, useState } from "react";

import { Card, Col, Row } from "react-bootstrap";
import { Country, State, City } from "country-state-city";


import { Radio, Select, Space } from 'antd';
const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}
const handleChange = (value) => {
  console.log(`Selected: ${value}`);
};

function AdminAddRetailer() {
  const [size, setSize] = useState('middle');
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const apiURL = process.env.REACT_APP_API_URL;
  const [countrycode, setcountrycode] = useState("");
  const [statecode, setstatecode] = useState("");
  const [city, setcity] = useState("");

  const countriesList = Country.getAllCountries();
  const statesList = State.getStatesOfCountry(countrycode);
  // const citiesList = data.getAllCitiesFromState('Maharashtra');
  const cities = City.getCitiesOfState(countrycode, statecode);
  const no = sessionStorage.getItem("phoneno");
  const [email, setemail] = useState("");
  const [firmname, setfirmname] = useState("");
  const [password, setpassword] = useState("");
  const [partnership, setpartnership] = useState("");
  const [partnersname, setpartnersname] = useState("");
  const [gstno, setgstno] = useState("");

  const [panno, setpanno] = useState("");
  const [villagename, setvillagename] = useState("");
  const [taluka, settaluka] = useState("");
  const [villagecode, setvillagecode] = useState("");
  const [pincode, setpincode] = useState("");
  const [addresslandmark, setaddresslandmark] = useState("");
  const [residentialaddress, setresidentialaddress] = useState("");
  const [alternateno, setalternateno] = useState("");
  const [whatsappno, setwhatsappno] = useState("");
  const [outlettype, setoutlettype] = useState("");
  const [bankname, setbankname] = useState("");
  const [accontno, setaccontno] = useState("");
  const [branch, setbranch] = useState("");
  const [bankcode, setbankcode] = useState("");
  const [noofvillagescovering, setnoofvillagescovering] = useState("");
  const [noofretailersservicing, setnoofretailersservicing] = useState("");
  const [whsize, setwhsize] = useState("");
  const [no0f3wheelersmodel, setno0f3wheelersmodel] = useState("");
  const [no0f4wheelersmodel, setno0f4wheelersmodel] = useState("");
  const [permanentno, setpermanentno] = useState("");
  const [distributerphoto, setdistributerphoto] = useState();
  const [warehousephoto, setwarehousephoto] = useState();
  const [accountHoldName, setaccountHoldName] = useState([]);
  const formdata = new FormData();


  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }
  function pincodecheck(inputtxt) {
    var no = /^\d{6}$/;
    if (inputtxt.match(no)) {
      return true;
    } else {
      alert("You have entered an invalid Pincode!");
      return false;
    }
  }
  function validategst(inputtxt) {
    var phoneno = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/;

    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid gst number!");
      return false;
    }
  }

  function panverification(inputtxt) {
    var phoneno = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid pan number!");
      return false;
    }
  }
  const check = () => {
    if (
      !email |
      !password |
      
      !bankcode |
      !bankname |
      !password |
      !accontno |
      !branch |
      !partnership |
      !gstno |
      !panno |
      !villagename |
      !taluka |
      !villagecode |
      !city |
      !statecode |
      !pincode |
      !addresslandmark |
      !distributerphoto |
      !permanentno
    ) {
      alert("Please Fill All Field");
    } else if (
      validategst(gstno) &&
      panverification(panno) &&
      ValidateEmail(email) &&
      phonenumber(permanentno) &&
      pincodecheck(pincode)
    ) {
      postnumber();
    } else {
      alert("Please Check the form");
    }
  };
  const postnumber = async () => {
    try {
      const config = {
        url: "/retailer/phoneno",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: { permanentno: permanentno },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
     
        Onsubmit();
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const Onsubmit = async (e) => {
    formdata.append("shopname", firmname);
    formdata.append("accountHoldName",accountHoldName)
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("partnership", partnership);
    formdata.append("partnersname", partnersname);
    formdata.append("gstno", gstno);
    formdata.append("panno", panno);
    formdata.append("villagename", villagename);
    formdata.append("taluka", taluka);
    formdata.append("villagecode", villagecode);
    formdata.append("districtname", city);
    formdata.append("state", statecode);
    formdata.append("country", countrycode);
    formdata.append("pincode", pincode);
    formdata.append("addresslandmark", addresslandmark);
    formdata.append("permanentno", permanentno);
    formdata.append("alternateno", alternateno);
    formdata.append("whatsappno", whatsappno);
    formdata.append("bankname", bankname);
    formdata.append("accontno", accontno);
    formdata.append("branch", branch);
    formdata.append("bankcode", bankcode);
    formdata.append("noofvillagescovering", noofvillagescovering);
    formdata.append("noofretailersservicing", noofretailersservicing);
    formdata.append("shopSize", whsize);
    formdata.append("no0f3wheelersmodel", no0f3wheelersmodel);
    formdata.append("no0f4wheelersmodel", no0f4wheelersmodel);
    formdata.append("profilephoto", distributerphoto);
    formdata.append("shopimg", warehousephoto);
    formdata.append("AddedBy","Admin");
    formdata.append("approvestatus",true);
    formdata.append("profilestatus",true);
    try {
      const config = {
        url: "/retailer/signup",
        method: "post",
        baseURL: apiURL,
        data: formdata,
      };
      let res = await axios(config);
      console.log(res.status);
      if (res.status === 200) {
        console.log(res.data);
        alert("Account Created ");
        window.location.assign("/admin/approvedretailer");
        return res;
      } else {
        alert("Email or Mobile Already Exist");
        console.log(res.data.error);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  return (
    <div className="" style={{ background: "#F4F4F4" }}>
      <Row className="me-0">
        <Col lg={1} md={1}>
          {" "}
        </Col>
        <Col lg={10} md={10}>
          <Card className="mt-5 mb-5">
            <Card.Body>
              <h5 className="pl-3 pb-4"> Retailer Sign Up</h5>

              <div className="row me-0">
                {/* <form action="http://www.mitrakart.com/distributor/pview/signup" method="post" enctype="multipart/form-data"> */}
                {/* <div className="col-md-4 form-group">
                  <label>Firm Name</label>
                  <input
                    type="text"
                    name="firm_name"
                    className="form-control"
                    placeholder="Firm Name"
                    // onChange={(e) => setfirmname(e.target.value)}
                    required
                  />
                </div> */}
                <div className="col-md-4 form-group">
                  <label>Retailer Name</label>
                  <input
                    type="text"
                    name="prop_partener"
                    className="form-control"
                    placeholder="Owner Name"
                    onChange={(e) => setpartnersname(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Retailer Number</label>
                  <input
                    type="text"
                    name="partner_name"
                    className="form-control"
                    placeholder="Owner Number"
                    onChange={(e) => setpermanentno(e.target.value)}
                    required
                  />
                </div>
      
                {/* <div className="col-md-4 form-group">
                  <label>Contact Person Name</label>
                  <input
                    type="text"
                    name="prop_partener"
                    className="form-control"
                    placeholder="Contact Person Name"
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Contact Person Number</label>
                  <input
                    type="text"
                    name="partner_name"
                    className="form-control"
                    placeholder="Contact Person Number"
                    required
                  />
                </div> */}
                <div className="col-md-4 form-group">
                  <label>Email Id</label>
                  <input
                    type="text"
                    name="partner_name"
                    className="form-control"
                    placeholder="Email Id"
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Password</label>
                  <input
                    type="text"
                    name="partner_name"
                    className="form-control"
                    placeholder="Password"
                    onChange={(e) => setpassword(e.target.value)}
                    required
                  />
                </div>
           <br/>
           <br/>
                <h5 className="pl-3 pb-4"> Add Address</h5>
                
                 <div className="col-md-4 form-group">
                  <label>Street Address With Building No.</label>
                  <input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    placeholder="Street Address With Building No."
                    onChange={(e) => setvillagecode(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Near Landmark</label>
                  <input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    placeholder="Near Landmark"
                    onChange={(e) => setaddresslandmark(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Village/Area</label>
                  <input
                    type="text"
                    name="village_name"
                    className="form-control"
                    placeholder="Village name"
                    onChange={(e) => setvillagename(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label>Country</label>
                  <select
                    className="form-control"
                    name="student_country"
                    id="countryId"
                    onChange={(e) => setcountrycode(e.target.value)}
                    required=""
                  >
                    <option value="">Select Country</option>
                    {countriesList.map((country) => (
                      <option value={country.isoCode}>{country.name}</option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4 form-group">
                  <label>State</label>
                  <select
                    name="state"
                    className="form-control"
                    id="stateId"
                    onChange={(e) => setstatecode(e.target.value)}
                    required
                  >
                    <option value="">Select State</option>
                    {statesList.map((state) => (
                      <option value={state.isoCode}>{state.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 form-group">
                  <label>District</label>
                  <select
                    name="district_name"
                    className="form-control"
                    id="cityDiv"
                    onChange={(e) => setcity(e.target.value)}
                    required
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option value={city.name}>{city.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 form-group">
                  <label>Taluka</label>
                  <input
                    type="tel"
                    name="Pincode"
                    className="form-control"
                    placeholder="Taluka"
                    onChange={(e) => settaluka(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label>Pincode</label>
                  <input
                    type="tel"
                    name="Pincode"
                    className="form-control"
                    placeholder="Pincode"
                    onChange={(e) => setpincode(e.target.value)}
                    required
                  />
                </div>
            
       <br/>
       <br/>
       <h5 className="pl-3 pb-4"> Shop Details</h5>
            
                <div className="col-md-4 form-group">
                  <label>Shop Name</label>
                  <input
                    type="text"
                    name="alt_contactNo"
                    className="form-control"
                    placeholder="Firm Name"
                    onChange={(e)=>setfirmname(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Select Type</label>
                  <br/>
                  <select aria-label="Default select example" style={{width:"100%", padding:"7px",
                   border:"1px solid gray",borderRadius:"5px"}} onChange={(e)=>setpartnership(e.target.value)}>
      <option>Open this select menu</option>
      <option value="Department Store">Department Store</option>
      <option value="Super Market">Super Market</option>
    </select>
                </div>
                 <div className="col-md-4 form-group">
                  <label>GST No.</label>
                  <input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    placeholder="GST No."
                    onChange={(e) => setgstno(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Pan No</label>
                  <input
                    type="text"
                    name="pan_no"
                    className="form-control"
                    placeholder="Pan No"
                    onChange={(e) => setpanno(e.target.value)}
                    required
                  />
                </div> 
 
                <div className="col-md-4 form-group">
                  <label>Shop Size in sqft</label>
                  <input
                    type="email"
                    name="mail_id"
                    className="form-control"
                    placeholder="Enter Shop Size in sqft"
                    onChange={(e)=>setwhsize(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>No of 3 wheeler</label>
                  <input
                    type="text"
                    name="pwd"
                    className="form-control"
                    placeholder="No of 3 wheeler"
                    onChange={(e)=>setno0f3wheelersmodel(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>No of 4 wheeler</label>
                  <input
                    type="text"
                    name="Village_covering"
                    className="form-control"
                    placeholder="No of 4 wheeler"
                    onChange={(e)=>setno0f4wheelersmodel(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>No of retailers servicing</label>
                  <input
                    type="text"
                    name="retailer_serviceNo"
                    className="form-control"
                    placeholder="No of retailers servicing"
                    onChange={(e) => setnoofretailersservicing(e.target.value)}
                    required
                  />
                </div>
                <br/>
     <br/>
     <br/>
       <h5 className="pl-3 pb-4"> Bank Details</h5>
                <div className="col-md-4 form-group">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    name="Bank_name"
                    className="form-control"
                    placeholder="Bank Name"
                    onChange={(e) => setbankname(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Account Holder Name</label>
                  <input
                    type="text"
                    name="Bank_name"
                    className="form-control"
                    placeholder="Enter Account Holder Name"
                    onChange={(e) => setaccountHoldName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Account Number</label>
                  <input
                    type="text"
                    name="Account_no"
                    className="form-control"
                    placeholder="Account Number"
                    onChange={(e) => setaccontno(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>IFSC Code</label>
                  <input
                    type="text"
                    name="ifsc_code"
                    className="form-control"
                    placeholder="IFSC Code"
                    onChange={(e) => setbankcode(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Branch </label>
                  <input
                    type="text"
                    name="branch_name"
                    className="form-control"
                    placeholder="Branch "
                    onChange={(e) => setbranch(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label for="">Retailer Photo</label>

                  <input
                    name="distri_photo"
                    className="form-input"
                    type="file"
                    onChange={(e) => setdistributerphoto(e.target.files[0])}
                    accept="image/gif, image/jpeg, image/png"
                    required=""
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label for="">Shop Photo</label>

                  <input
                    name="warehouse_photo"
                    className="form-input"
                    type="file"
                    onChange={(e) => setwarehousephoto(e.target.files[0])}
                    accept="image/gif, image/jpeg, image/png"
                    required=""
                  />
                </div>

                <div className="col-md-4 form-group">
         <label for="">Select Pincode</label>
         <Select
          mode="tags"
          size={size}
          placeholder="Please select"
          defaultValue={['585105', '585101']}
          onChange={handleChange}
          style={{
            width: '100%',
          }}
          options={options}
        />    </div>
           <div className="col-md-4 form-group">
                  <label>Select Distributer</label>
                  <br/>
                  <select aria-label="Default select example" style={{width:"100%", padding:"7px",
                   border:"1px solid gray",borderRadius:"5px"}}>
      <option>Open this select menu</option>
      <option value="1">1</option>
      <option value="2">2</option>
    </select>
                </div>
                <div className="col-md-4 form-group">
                  <label for="">&nbsp;</label>
                  <button
                    className="form-control btn btn-primary"
                    onClick={check}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={1} md={1}>
        </Col>
      </Row>
    </div>
  );
}

export default AdminAddRetailer;
