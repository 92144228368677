import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import * as ReactBootstrap from "react-bootstrap";
import axios from "axios";
import Category from "./Category";
import Essentials from "./Essentials";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import Topdeals from "./Topdeals";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

function Retailerhome(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const apiURL = process.env.REACT_APP_API_URL;
  const [lat, setlat] = useState("");
  const [lon, setlon] = useState("");
  const [slider, setslider] = useState([]);
  const life = sessionStorage.getItem("Ilifeshoppie");
  const local = sessionStorage.getItem("Localshop");
  const [pincodedata, setpincodedata] = useState([]);
  const [pincode, setpincode] = useState("");
  const pincodenew = sessionStorage.getItem("Pincode");
  const [localshop, setlocalshop] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const shop = sessionStorage.getItem("shop");
  const [colorchange, setcolorchange] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setlat(position.coords.latitude);
      setlon(position.coords.longitude);
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
    if (user) {
      if (!pincodenew) {
        showModal();
      }
    }
  }, [user]);
  useEffect(() => {
    // if (local) {
    getlocalshops();
    // }
  }, []);

  useEffect(() => {
    // getcountry();
    getpincode();
  }, []);
  const getpincode = async () => {
    let res = await axios.get(apiURL + "/getallpincode");
    if ((res.status = 200)) {
      console.log(res);
      setpincodedata(res.data?.pincode);
    }
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const showModal1 = () => {
    setIsOpen1(true);
    setIsOpen(false);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const hideModal2 = () => {
    setIsOpen1(false);
    window.location.reload();
  };

  useEffect(() => {
    getslider();
    getlocalshops();
  }, []);

  const getlocalshops = async () => {
    let res = await axios.get(
      apiURL + "/getdistributorlocalshop/" + pincodenew
    );
    if ((res.status = 200)) {
      console.log(res.data);
      setlocalshop(res.data?.localshop);
    }
  };

  const ids = pincodedata?.map((o) => o.districtname);
  const filtered = pincodedata?.filter(
    ({ districtname }, index) => !ids.includes(districtname, index + 1)
  );
  const getslider = async () => {
    let res = await axios.get(apiURL + "/getallslider/retailer");
    if ((res.status = 200)) {
      console.log(res);
      setslider(res.data?.slider);
    }
  };
  const catresponsive = {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };

  const search = async (e) => {
    e.preventDefault();
    try {
      axios
        .post(`${apiURL}/searchpincode/` + pincode)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            alert("Service Available");
            sessionStorage.setItem("Pincode", pincode);
            //  alert("Service Available");
            hideModal();
            showModal1();
            //window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Service Unavailable");
        });
    } catch (error) {
      console.warn(error);
      alert("Service Unavailable");
      // setdatacondition(true);
      return error;
    }
  };

  return (
    <div>
      <Carousel
        showArrows={true}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
       
      >
        {slider?.map((slider) => (
          <div>
            <img src={`${imageURL}/slider/${slider.slider}`} alt="" />
          </div>
        ))}
      </Carousel>

      {shop === "ilife" ? (
        <Category />
      ) : (
        <>
          {" "}
          <div className="container my-5">
            <div className="row me-0 mt-5">
              <div className="col-md-10">
                <h3 className="ms-3">Our Distributor's </h3>
              </div>
              <div className="col-md-2">
                {/* <Link to="/localshop"> View all </Link> */}
              </div>
            </div>
            <div className=" mt-3">
              <OwlCarousel
                className="owl-theme category-carousel"
          loop= {true}
              
                margin={10}
            navClass
                // items={5}
                responsive={catresponsive}
                autoplay={true}
                autoplayTimeout={3000}
              >
                {localshop.length === 0 ? "No localshops" : ""}

                {localshop.map((item, index) =>
                  index < 3 ? (
                    <Col>
                      <Card className="home-local" style={{ height: "385px" }}>
                        <Card.Img
                          variant="top"
                          src={`${imageURL}/distributor/${item.distributerphoto}`}
                        />
                        <Button
                          className="button"
                          component={Link}
                          to="/retailer/retailerlocalshopproduct"
                          onClick={() => {
                            props.dispatch({ type: "emptyBasket" });
                            props.dispatch({
                              type: "localshop",
                              item: { localshop: item },
                            });
                          }}
                        >
                          {/* {data.type=="admin" ? <Card.Img variant="top" src={imageURL+ "/admi/product/"  + data.productimage[0]} /> : <Card.Img variant="top" src={imageURL+ "/vendor/product/"  + data.productimage[0]} />} */}

                          <Card.Body>
                            <Card.Title style={{ color: "black" }}>
                              {item?.firmname}
                            </Card.Title>
                            <Card.Text>
                              <div style={{ color: "black" }}>
                                <b>
                                  {item?.addresslandmark}
                                  {""}
                                </b>
                              </div>

                              <div style={{ color: "black" }}>
                                <b>{item?.pincode}</b>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Button>
                      </Card>
                    </Col>
                  ) : (
                    ""
                  )
                )}
              </OwlCarousel>
            </div>
          </div>
        </>
      )}
      <Topdeals></Topdeals>

      <Essentials></Essentials>
      <Modal
        size="sm"
        show={isOpen}
        className="my-modal"
        // onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        style={{
          marginTop: "280px",
          right: "200px",
          backgroundColor: "none !important",
        }}
      >
        <Modal.Body style={{ alignSelf: "center" }}>
          <div style={{ display: "flex" }}>
            <input
              style={{ width: "255px" }}
              className="form-control"
              placeholder="Enter the pincode"
              onChange={(e) => setpincode(e.target.value)}
            />

            <button
              className="btn btn-success"
              onClick={search}
              style={{ background: "var(--bs-pink)", marginLeft: "5px" }}
            >
              Next
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isOpen1}
        // onHide={hideModal1}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">Buy from?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div
                onClick={() => {
                  sessionStorage.setItem("shop", "ilife");
                  setcolorchange("ilife");
                }}
                style={{
                  border: `1px solid ${
                    colorchange === "ilife" ? "red" : "white"
                  }`,
                  borderRadius: "10px",
                }}
              >
                <img
                  src="logo.png"
                  style={{ width: "150px", height: "100px" }}
                ></img>
                <p
                  className="header-signin btn btn-primary"
                  style={{
                    marginTop: "15px",
                    color: "blue",
                    backgroundColor: "white",
                    border: "1px solid white",
                  }}
                >
                  Ilifeshoppie{" "}
                </p>
              </div>
            </div>

            <div className="col-6">
              <div
                onClick={() => {
                  sessionStorage.setItem("shop", "retailer");
                  setcolorchange("retailer");
                }}
                style={{
                  border: `1px solid ${
                    colorchange === "retailer" ? "red" : "white"
                  }`,
                  borderRadius: "10px",
                }}
              >
                <img
                  src="distributorlogo.png"
                  style={{ width: "150px", height: "100px" }}
                ></img>
                <p
                  className="header-signin btn btn-primary"
                  style={{
                    marginTop: "15px",
                    color: "blue",
                    marginLeft: "10px",
                    backgroundColor: "white",
                    border: "1px solid white",
                  }}
                >
                  Distributor's
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            onClick={hideModal2}
            style={{
              background:
                "linear-gradient(45deg, var(--bs-pink), var(--bs-indigo))",
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    localshop: state.localshop,
    basket: state.basket,
  };
};
export default connect(mapStateToProps)(Retailerhome);
