import React, { useEffect, useState } from "react";
import { Card, Carousel, Container, Row, Col, Modal, Button } from "react-bootstrap";
import "../../Components/customer/Styles/style12.css"
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import DistributorSlider1 from '../NewDistributor/DistributorSlider1'
import DistributorFeatured1 from '../NewDistributor/DistributorFeatured1'
import DistributorNewArrivals from '../NewDistributor/DistributorNewArrivals'
import DistributorBestSellers from '../NewDistributor/DistributorBestSellers'
import DistributorOursBrand from '../NewDistributor/DistributorOursBrand'
import DistributorTestimonials from '../NewDistributor/DistributorTestimonials'
import { Link } from "react-router-dom";
import axios from "axios";


function DistributorHome() {
  const [sdec, setsdec] = useState(true);
  const [sdec1, setsdec1] = useState(true);
  const [addEmployer, setaddEmployer] = useState(true);
  const [sdec2, setsdec2] = useState(true);
  const [addEmployer1, setaddEmployer1] = useState(true);

  const [banner, setbanner] = useState([]);
  const [graph, setgraph] = useState([]);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logout = ()=>{
    sessionStorage.removeItem('distributor');
    window.location.assign("/distributor/login");
  }

  // 
  const getAllgraph = async () => {
    try {
      const config = {
        url: "/getAllgraph",
        method: "get",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setgraph(res.data.success);
        getAllBanner();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBanner = async () => {
    try {
      const config = {
        url: "/getallbanner/distributerHome",
        method: "get",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setbanner(res.data.banner);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(()=>{
    getAllgraph();
    getAllBanner();
  }, []);
  useEffect(()=>{
    getAllgraph();
    getAllBanner();
  }, []);
  return (
    <>
      <section className="hero">
        <div className="container"> 
          <div className="row">
          <div className="col-lg-3">
              <div className="hero__categories">
                <div
                  className="hero__categories__all"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#ed1c23",
                  }}
                >
                  <div>
                    <i className="fa fa-bars" />
                    <span>All departments</span>
                  </div>
                  <div
                    className=""
                    onClick={() => {
                      setsdec(!sdec);
                    }}
                  >
                    {sdec ? (
                      <>
                        <RiArrowDownSLine
                          style={{ fontSize: "20px", color: "white" }}
                        />
                      </>
                    ) : (
                      <>
                        <RiArrowUpSLine
                          style={{ fontSize: "20px", color: "white" }}
                        />
                      </>
                    )}
                  </div>
                </div>
                {sdec ? (
                  <>
                    <ul
                      style={{
                        border: "1px solid #ed1c23",
                        backgroundColor: "#ed1c23",
                      }}
                    >
                      <li>
                        <Link
                          to="/distributor/distributor-add-employed"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Add Employee{" "}
                        </Link>
                      </li>
                      <li>
                        <a
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setaddEmployer(!addEmployer);
                            setsdec1(!sdec1);
                          }}
                        >
                          {" "}
                          Add Retailer
                          {sdec1 ? (
                            <>
                              <RiArrowDownSLine
                                style={{ fontSize: "20px", color: "white" }}
                              />
                            </>
                          ) : (
                            <>
                              <RiArrowUpSLine
                                style={{ fontSize: "20px", color: "white" }}
                              />
                            </>
                          )}
                        </a>

                        {!addEmployer ? (
                          <>
                            <ol>
                              <li className="p-0">
                                {" "}
                                <a 
                                  href="/distributor/distributor-not-approved"
                                  style={{
                                    color: "white",
                                    fontSize: "17px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Not Approved
                                </a>
                              </li>
                              <li  className="p-0">
                                {" "}
                                <a
                                  href="/distributor/distributor-approved"
                                  style={{
                                    color: "white",
                                    fontSize: "17px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Approved
                                </a>
                              </li>
                            </ol>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-orders-list"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Retailer Orders List
                        </a>
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-sales-history"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Sales History
                        </a>
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-accounts-history"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Accounts History
                        </a>
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-cash-history"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Cash History
                        </a>
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-stock"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                         Stock
                        </a>
                      </li>
                      <li>
                        <a
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setaddEmployer1(!addEmployer1);
                            setsdec2(!sdec2);
                          }}
                        >
                          {" "}
                        Expense
                          {sdec2 ? (
                            <>
                              <RiArrowDownSLine
                                style={{ fontSize: "20px", color: "white" }}
                              />
                            </>
                          ) : (
                            <>
                              <RiArrowUpSLine
                                style={{ fontSize: "20px", color: "white" }}
                              />
                            </>
                          )}
                        </a>

                        {!addEmployer1 ? (
                          <>
                            <ol>
                              <li className="p-0">
                                {" "}
                                <a 
                                  href="/distributor/distributor-expensetype"
                                  style={{
                                    color: "white",
                                    fontSize: "17px",
                                    fontWeight: "700",
                                  }}
                                >
                                 Expense Type
                                </a>
                              </li>
                              <li  className="p-0">
                                {" "}
                                <a
                                  href="/distributor/distributor-expense"
                                  style={{
                                    color: "white",
                                    fontSize: "17px",
                                    fontWeight: "700",
                                  }}
                                >
                                   Expense 
                                </a>
                              </li>
                            </ol>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>
                      <li>
                        <a
                          href="#"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Shipping & Delivery Policy
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Cancellation & Refund Policy
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          Guide
                        </a>
                      </li>
                      <li>
                        <span
                          style={{
                            color: "white",
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                          onClick={handleShow}
                        >
                          Logout
                        </span>
                      </li>
                    </ul>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="hero__search">
                <div className="hero__search__form">
                  <form action="#">
                    <div className="hero__search__categories">
                      All Categories
                      <span className="arrow_carrot-down" />
                    </div>
                    <input type="text" placeholder="What do yo u need?" />
                    <button type="submit" className="site-btn">
                      SEARCH
                    </button>
                  </form>
                </div>
                <div className="hero__search__phone">
                  <div className="hero__search__phone__icon">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="hero__search__phone__text">
                    <h5>+91 9845091018</h5>
                    <span>support 24/7 time</span>
                  </div>
                </div>
              </div>
              <div className="hero__item set-bg" style={{ paddingLeft: "0px",display:"block" }}>
                <section>
                  <div className="hom-banner">
                    <Carousel>
                    {graph
                        ?.filter((ele) => ele?.type === "distributorHome")
                        ?.map((item) => {
                          return (
                      <Carousel.Item>
                        <Link to = {item?.link}>
                        {" "}
                        
                                <img
                                  className="d-block w-100"
                                  style={{ height: "411px" }}
                                  src={`https://spilso.in/graph/${item?.image}`}
                                  alt="First slide"
                                />{" "}
                        </Link>
                        
                      </Carousel.Item>
                       );
                      })}
                      {/* <Carousel.Item>
                        <img
                          className="d-block w-100"
                          style={{ height: "411px" }}
                          src="/webImages/slider2.webp"
                          alt="Second slide"
                        />

                        <Carousel.Caption className="text_he second">
                          <p>Organic indian masala</p>
                          <h1>
                            Prod of indian <br /> 100% packaging
                          </h1>
                          <a href="#" class="btn btn-style1">
                            Shop now
                          </a>
                        </Carousel.Caption>
                      </Carousel.Item> */}
                    </Carousel>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
         <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" style={{backgroundColor: "red", color: "white"}} onClick={logout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
      </section>
       <DistributorSlider1/>
      <DistributorFeatured1/> 
      <section className="common-use">
        <div className="banner-card">
          <Container>
            <div className="banner_he">
              <Row>
              {banner?.map((item) => {
                  return (
                <Col lg={6} md={6} sm={6}>
                  <div class="banner-hover">
                  <a href={item?.link}>
                          <img
                            class="img-fluid"
                            src={`https://spilso.in/banner/${item?.banner}`}
                            loading="lazy"
                            style={{ width: "100%", height: "260px" }}
                          />
                        </a>
                    <div class="banner-content">
                      <h3>{item?.text}</h3>

                      <a href={item?.link} class="btn btn-style1">
                        Shop now
                      </a>
                    </div>
                  </div>
                </Col>
                );
              })}
                {/* <Col lg={6} md={6} sm={6}>
                  <div class="banner-hover">
                    <a href="#">
                      <img
                        class="img-fluid"
                        src="/webImages/banner-3.webp"
                        loading="lazy"
                        style={{ width: "100%", height: "260px" }}
                      />
                    </a>
                    <div class="banner-content">
                      <h3>Fresh fruit,vegetable on our product</h3>

                      <a href="#" class="btn btn-style1">
                        Shop now
                      </a>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </div>
          </Container>
        </div>
      </section>
       <DistributorNewArrivals/>
       <DistributorBestSellers/>
       <DistributorOursBrand/>
       <DistributorTestimonials/> 
    </>
  );
}

export default DistributorHome;
