import axios from "axios";
import React, { useEffect, useState } from "react";

import { Card, Col, Row } from "react-bootstrap";
import { Country, State, City } from "country-state-city";

import { Radio, Select, Space } from "antd";

const distributor = JSON.parse(sessionStorage?.getItem("distributor"));
const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}
const handleChange = (value) => {
  console.log(`Selected: ${value}`);
};

function DistributorEmployeeListForm() {
  // const [size, setSize] = useState('middle');
  // const handleSizeChange = (e) => {
  //   setSize(e.target.value);
  // };

  // const apiURL = process.env.REACT_APP_API_URL;
  // const [countrycode, setcountrycode] = useState("");
  // const [statecode, setstatecode] = useState("");
  // const [city, setcity] = useState("");

  // const countriesList = Country.getAllCountries();
  // const statesList = State.getStatesOfCountry(countrycode);
  // // const citiesList = data.getAllCitiesFromState('Maharashtra');
  // const cities = City.getCitiesOfState(countrycode, statecode);
  // const no = sessionStorage.getItem("phoneno");
  // const [email, setemail] = useState("");
  // const [firmname, setfirmname] = useState("");
  // const [password, setpassword] = useState("");
  // const [partnership, setpartnership] = useState("");
  // const [partnersname, setpartnersname] = useState("");
  // const [gstno, setgstno] = useState("");

  // const [panno, setpanno] = useState("");
  // const [villagename, setvillagename] = useState("");
  // const [taluka, settaluka] = useState("");
  // const [villagecode, setvillagecode] = useState("");
  // const [pincode, setpincode] = useState("");
  // const [addresslandmark, setaddresslandmark] = useState("");
  // const [residentialaddress, setresidentialaddress] = useState("");
  // const [alternateno, setalternateno] = useState("");
  // const [whatsappno, setwhatsappno] = useState("");
  // const [outlettype, setoutlettype] = useState("");
  // const [bankname, setbankname] = useState("");
  // const [accontno, setaccontno] = useState("");
  // const [branch, setbranch] = useState("");
  // const [bankcode, setbankcode] = useState("");
  // const [noofvillagescovering, setnoofvillagescovering] = useState("");
  // const [noofretailersservicing, setnoofretailersservicing] = useState("");
  // const [whsize, setwhsize] = useState("");
  // const [no0f3wheelersmodel, setno0f3wheelersmodel] = useState("");
  // const [no0f4wheelersmodel, setno0f4wheelersmodel] = useState("");
  // const [permanentno, setpermanentno] = useState("");
  // const [distributerphoto, setdistributerphoto] = useState();
  // const [warehousephoto, setwarehousephoto] = useState();
  // const [data, setdata] = useState([]);
  // const formdata = new FormData();

  // function ValidateEmail(mail) {
  //   if (
  //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
  //       mail
  //     )
  //   ) {
  //     return true;
  //   }
  //   alert("You have entered an invalid email address!");
  //   return false;
  // }

  // function phonenumber(inputtxt) {
  //   var phoneno = /^[6-9]\d{9}$/;
  //   // var no = /^\d{10}$/;
  //   if (inputtxt.match(phoneno)) {
  //     return true;
  //   } else {
  //     alert("You have entered an invalid mobile number!");
  //     return false;
  //   }
  // }
  // function pincodecheck(inputtxt) {
  //   var no = /^\d{6}$/;
  //   if (inputtxt.match(no)) {
  //     return true;
  //   } else {
  //     alert("You have entered an invalid Pincode!");
  //     return false;
  //   }
  // }
  // function validategst(inputtxt) {
  //   var phoneno = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/;

  //   if (inputtxt.match(phoneno)) {
  //     return true;
  //   } else {
  //     alert("You have entered an invalid gst number!");
  //     return false;
  //   }
  // }

  // function panverification(inputtxt) {
  //   var phoneno = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  //   if (inputtxt.match(phoneno)) {
  //     return true;
  //   } else {
  //     alert("You have entered an invalid pan number!");
  //     return false;
  //   }
  // }
  // const check = () => {
  //   if (
  //     !email |
  //     !password |
  //     !whatsappno |
  //     !bankcode |
  //     !bankname |
  //     !password |
  //     !accontno |
  //     !branch |
  //     !partnership |
  //     !gstno |
  //     !panno |
  //     !villagename |
  //     !taluka |
  //     !villagecode |
  //     !city |
  //     !statecode |
  //     !pincode |
  //     !addresslandmark |
  //     !distributerphoto |
  //     !permanentno
  //   ) {
  //     alert("Please Fill All Field");
  //   } else if (
  //     validategst(gstno) &&
  //     panverification(panno) &&
  //     ValidateEmail(email) &&
  //     phonenumber(permanentno) &&
  //     pincodecheck(pincode)
  //   ) {
  //     postnumber();
  //   } else {
  //     alert("Please Check the form");
  //   }
  // };
  // const postnumber = async () => {
  //   try {
  //     const config = {
  //       url: "/distributor/phoneno",
  //       method: "post",
  //       baseURL: apiURL,
  //       headers: { "content-type": "application/json" },
  //       data: { permanentno: permanentno },
  //     };
  //     let res = await axios(config);
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       Onsubmit();
  //     }
  //   } catch (error) {
  //     console.log(error.response);
  //     if (error.response) {
  //       alert(error.response.data.error);
  //     }
  //   }
  // };
  // const Onsubmit = async (e) => {
  //   formdata.append("firmname", firmname);
  //   formdata.append("email", email);
  //   formdata.append("password", password);
  //   formdata.append("partnership", partnership);
  //   formdata.append("partnersname", partnersname);
  //   formdata.append("gstno", gstno);
  //   formdata.append("panno", panno);
  //   formdata.append("villagename", villagename);
  //   formdata.append("taluka", taluka);
  //   formdata.append("villagecode", villagecode);
  //   formdata.append("districtname", city);
  //   formdata.append("state", statecode);
  //   formdata.append("country", countrycode);
  //   formdata.append("pincode", pincode);
  //   formdata.append("addresslandmark", addresslandmark);
  //   formdata.append("permanentno", permanentno);
  //   formdata.append("alternateno", alternateno);
  //   formdata.append("whatsappno", whatsappno);
  //   formdata.append("bankname", bankname);
  //   formdata.append("accontno", accontno);
  //   formdata.append("branch", branch);
  //   formdata.append("bankcode", bankcode);
  //   formdata.append("noofvillagescovering", noofvillagescovering);
  //   formdata.append("noofretailersservicing", noofretailersservicing);
  //   formdata.append("whsize", whsize);
  //   formdata.append("no0f3wheelersmodel", no0f3wheelersmodel);
  //   formdata.append("no0f4wheelersmodel", no0f4wheelersmodel);
  //   formdata.append("distributerphoto", distributerphoto);
  //   formdata.append("warehousephoto", warehousephoto);

  //   try {
  //     const config = {
  //       url: "/distributor/signup",
  //       method: "post",
  //       baseURL: apiURL,
  //       data: formdata,
  //     };
  //     let res = await axios(config);
  //     console.log(res.status);
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       alert("Account Created ");
  //       window.location.assign("/admin/approveddistributor");
  //       return res;
  //     } else {
  //       alert("Email or Mobile Already Exist");
  //       console.log(res.data.error);
  //     }
  //   } catch (error) {
  //     console.log(error.response);
  //     if (error.response) {
  //       alert(error.response.data.error);
  //     }
  //   }
  // };

  // useEffect(()=>{
  //   if(!distributor){
  //     alert("Please login first...");
  //     window.location.assign("/distributor/login");
  //   }
  // }, [])

  // return (
  //   <div className="" style={{ background: "#F4F4F4" }}>
  //     <Row className="me-0">
  //       <Col lg={1} md={1}>
  //         {" "}
  //       </Col>
  //       <Col lg={10} md={10}>
  //         <Card className="mt-5 mb-5">
  //           <Card.Body>
  //             <h5 className="pl-3 pb-4"> Employee List Form</h5>

  //             <div className="row me-0">

  //               <div className="col-md-4 form-group">
  //                 <label>Employee Name</label>
  //                 <input
  //                   type="text"
  //                   name="prop_partener"
  //                   className="form-control"
  //                   placeholder="Employee Name"
  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Email</label>
  //                 <input
  //                   type="text"
  //                   name="partner_name"
  //                   className="form-control"
  //                   placeholder="Email"
  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Password</label>
  //                 <input
  //                   type="text"
  //                   name="partner_name"
  //                   className="form-control"
  //                   placeholder="Password"
  //                   required
  //                 />
  //               </div>

  //               <div className="col-md-4 form-group">
  //                 <label>Number</label>
  //                 <input
  //                   type="text"
  //                   name="prop_partener"
  //                   className="form-control"
  //                   placeholder="Number"
  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Alternet Number</label>
  //                 <input
  //                   type="text"
  //                   name="prop_partener"
  //                   className="form-control"
  //                   placeholder="Alternet Number"
  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Date Of Birth</label>
  //                 <input
  //                   type="text"
  //                   name="partner_name"
  //                   className="form-control"
  //                   placeholder="Date Of Birth"

  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Designation</label>
  //                 <input
  //                   type="text"
  //                   name="partner_name"
  //                   className="form-control"
  //                   placeholder="designation"

  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Joining Date</label>
  //                 <input
  //                   type="text"
  //                   name="partner_name"
  //                   className="form-control"
  //                   placeholder="Joining Date"

  //                   required
  //                 />
  //               </div>
  //          <br/>
  //          <br/>
  //               <h5 className="pl-3 pb-4"> Add Address</h5>

  //                <div className="col-md-4 form-group">
  //                 <label>Current Address</label>
  //                 <input
  //                   type="text"
  //                   name="gst_no"
  //                   className="form-control"
  //                   placeholder="Current Address"
  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Permanent Address</label>
  //                 <input
  //                   type="text"
  //                   name="gst_no"
  //                   className="form-control"
  //                   placeholder="Permanent Address"
  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Heigest Qualification </label>
  //                 <input
  //                   type="text"
  //                   name="gst_no"
  //                   className="form-control"
  //                   placeholder="Enter Qualification"
  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label for="">Heights Qualification</label>

  //                 <input
  //                   name="distri_photo"
  //                   className="form-input"
  //                   type="file"
  //                   accept="image/gif, image/jpeg, image/png"
  //                   required=""
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label>Id Type</label>
  //                 <input
  //                   type="text"
  //                   name="gst_no"
  //                   className="form-control"
  //                   placeholder="Id Type"
  //                   required
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label for="">Id Proof</label>
  //                 <input
  //                   name="distri_photo"
  //                   className="form-input"
  //                   type="file"
  //                   accept="image/gif, image/jpeg, image/png"
  //                   required=""
  //                 />
  //               </div>
  //               <div className="col-md-4 form-group">
  //                 <label for="">Employee Image</label>
  //                 <input
  //                   name="distri_photo"
  //                   className="form-input"
  //                   type="file"
  //                   accept="image/gif, image/jpeg, image/png"
  //                   required=""
  //                 />
  //               </div>

  //               <div className="col-md-4 form-group">
  //                 <label for="">&nbsp;</label>
  //                 <button
  //                   className="form-control btn btn-primary"
  //                 >
  //                   Submit
  //                 </button>
  //               </div>
  //             </div>
  //           </Card.Body>
  //         </Card>
  //       </Col>
  //       <Col lg={1} md={1}>
  //       </Col>
  //     </Row>
  //   </div>
  // );

  const [size, setSize] = useState("middle");
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const apiURL = process.env.REACT_APP_API_URL;
  const [countrycode, setcountrycode] = useState("");
  const [statecode, setstatecode] = useState("");
  const [city, setcity] = useState("");

  const countriesList = Country.getAllCountries();
  const statesList = State.getStatesOfCountry(countrycode);
  // const citiesList = data.getAllCitiesFromState('Maharashtra');
  const cities = City.getCitiesOfState(countrycode, statecode);
  const no = sessionStorage.getItem("phoneno");
  const [email, setemail] = useState("");
  const [employeename, setemployeename] = useState("");
  const [password, setpassword] = useState("");
  const [officalemail, setofficalemail] = useState("");
  const [partnersname, setpartnersname] = useState("");
  const [gstno, setgstno] = useState("");

  const [joinDate, setjoinDate] = useState("");
  const [villagename, setvillagename] = useState("");
  const [taluka, settaluka] = useState("");
  const [villagecode, setvillagecode] = useState("");
  const [pincode, setpincode] = useState("");
  const [addresslandmark, setaddresslandmark] = useState("");
  const [residentialaddress, setresidentialaddress] = useState("");
  const [alternateno, setalternateno] = useState("");
  const [officeaddress, setofficeaddress] = useState("");
  const [outlettype, setoutlettype] = useState("");
  const [dob, setdob] = useState("");
  const [accontno, setaccontno] = useState("");
  const [IDtype, setIDtype] = useState("");
  const [aadhaarno, setaadhaarno] = useState("");
  const [highestQuliDoc, sethighestQuliDoc] = useState("");
  const [empProfile, setempProfile] = useState("");
  const [highestQuli, sethighestQuli] = useState("");
  const [designation, setdesignation] = useState("");
  const [IDDoc, setIDDoc] = useState("");
  const [permanentno, setpermanentno] = useState("");
  const [distributerphoto, setdistributerphoto] = useState();
  const [warehousephoto, setwarehousephoto] = useState();
  const [data, setdata] = useState([]);
  const formdata = new FormData();

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      return alert("You have entered an invalid mobile number!");
    }
  }
  function phonenumber1(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  }
  function pincodecheck(inputtxt) {
    var no = /^\d{12}$/;
    if (inputtxt.match(no)) {
      return true;
    } else {
      return alert("You have entered an invalid adhar number!");
      return false;
    }
  }
  function validategst(inputtxt) {
    var phoneno = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/;

    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid gst number!");
      return false;
    }
  }

  function panverification(inputtxt) {
    var phoneno = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid pan number!");
      return false;
    }
  }
  const check = () => {
    if (
      !email |
      !employeename |
      !officeaddress |
      !aadhaarno |
      !dob |
      !password |
      !IDtype |
      !IDDoc |
      !empProfile |
      !designation |
      !residentialaddress |
      !joinDate |
      !highestQuli |
      !permanentno
    ) {
      return alert("Please Fill All Field");
    } else if (
      ValidateEmail(email) &&
      phonenumber(permanentno) &&
      pincodecheck(aadhaarno)
    ) {
      if (alternateno) {
        if (!phonenumber1(alternateno))
          return alert("You have entered an invalid alternate mobile number!");
      }
      postnumber();
    } else {
      alert("Please Check the form");
    }
  };
  const postnumber = async () => {
    try {
      const config = {
        url: "/employee/phoneno",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: { contactno: permanentno },
      };
      let res = await axios(config);
      if (res.status === 200) {
        Onsubmit();
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const Onsubmit = async (e) => {
    formdata.set("employeename", employeename);
    formdata.set("officalemail", email);
    formdata.set("password", password);
    // formdata.set("officalemail", officalemail);
    formdata.set("partnersname", partnersname);
    formdata.set("gstno", gstno);
    formdata.set("joinDate", joinDate);
    formdata.set("villagename", villagename);
    formdata.set("taluka", taluka);
    formdata.set("villagecode", villagecode);
    formdata.set("districtname", city);
    formdata.set("state", statecode);
    formdata.set("country", countrycode);
    formdata.set("pincode", pincode);
    formdata.set("addresslandmark", addresslandmark);
    formdata.set("contactno", permanentno);
    formdata.set("alternateno", alternateno);
    formdata.set("officeaddress", officeaddress);
    formdata.set("residentialaddress", residentialaddress);
    formdata.set("dob", dob);
    formdata.set("accontno", accontno);
    formdata.set("IDtype", IDtype);
    formdata.set("aadhaarno", aadhaarno);
    formdata.set("highestQuliDoc", highestQuliDoc);
    formdata.set("empProfile", empProfile);
    formdata.set("highestQuli", highestQuli);
    formdata.set("designation", designation);
    formdata.set("IDDoc", IDDoc);
    // formdata.set("distributerphoto", distributerphoto);
    // formdata.set("warehousephoto", warehousephoto);
    formdata.set("profilestatus", true);
    formdata.set("approvestatus", false);
    formdata.set("distributorId", distributor?._id);
    formdata.set("addBy", "DISTRIBUTOR");

    try {
      const config = {
        url: "/employee/signup",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: formdata,
      };
      let res = await axios(config);
      console.log(res.status);
      if (res.status === 200) {
        console.log(res.data);
        alert("Account Created ");
        // window.location.assign("/admin/notapprovedtransporter");
        // window.location.assign("/distributor/distributor-not-approved");
        window.location.assign("/distributor/distributor-add-employed");
        return res;
      } else {
        alert("Email or Mobile Already Exist");
        console.log(res.data.error);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  return (
    <div className="" style={{ background: "#F4F4F4" }}>
      <Row className="me-0">
        <Col lg={1} md={1}>
          {" "}
        </Col>
        <Col lg={10} md={10}>
          <Card className="mt-5 mb-5">
            <Card.Body>
              <h5 className="pl-3 pb-4"> Employee List Form</h5>

              <div className="row me-0">
                {/* <div className="col-md-4 form-group">
                  <label>Employee Id</label>
                  <input
                    type="text"
                    name="firm_name"
                    className="form-control"
                    placeholder="Employee Id"
                    required
                  />
                </div> */}
                <div className="col-md-4 form-group">
                  <label>Employee Name</label>
                  <input
                    type="text"
                    name="prop_partener"
                    className="form-control"
                    placeholder="Employee Name"
                    onChange={(e) => setemployeename(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    name="partner_name"
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Password</label>
                  <input
                    type="text"
                    name="partner_name"
                    className="form-control"
                    placeholder="Password"
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    name="prop_partener"
                    className="form-control"
                    placeholder="Number"
                    required
                    onChange={(e) => setpermanentno(e.target.value)}
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Adhar Number</label>
                  <input
                    type="text"
                    name="prop_partener"
                    className="form-control"
                    placeholder="Adhar number"
                    required
                    onChange={(e) => setaadhaarno(e.target.value)}
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Alternet Number</label>
                  <input
                    type="text"
                    name="prop_partener"
                    className="form-control"
                    placeholder="Alternet Number"
                    onChange={(e) => setalternateno(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Date Of Birth</label>
                  <input
                    type="date"
                    name="partner_name"
                    className="form-control"
                    placeholder="Date Of Birth"
                    onChange={(e) => setdob(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Designation</label>
                  <input
                    type="text"
                    name="partner_name"
                    className="form-control"
                    placeholder="designation"
                    onChange={(e) => setdesignation(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Joining Date</label>
                  <input
                    type="date"
                    name="partner_name"
                    className="form-control"
                    placeholder="Joining Date"
                    onChange={(e) => setjoinDate(e.target.value)}
                    required
                  />
                </div>
                <br />
                <br />
                <h5 className="pl-3 pb-4"> Add Address</h5>

                <div className="col-md-4 form-group">
                  <label>Current Address</label>
                  <input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    placeholder="Current Address"
                    onChange={(e) => setofficeaddress(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Permanent Address</label>
                  <input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    placeholder="Permanent Address"
                    required
                    onChange={(e) => setresidentialaddress(e.target.value)}
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Heigest Qualification </label>
                  <input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    placeholder="Enter Qualification"
                    required
                    onChange={(e) => sethighestQuli(e.target.value)}
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="upload1">Qualification Doc</label>

                  <input
                    name="distri_photo"
                    className="form-input"
                    type="file"
                    id="upload1"
                    accept="image/gif, image/jpeg, image/png"
                    required=""
                    onChange={(e) => sethighestQuliDoc(e.target.files[0])}
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Id Type</label>
                  <input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    placeholder="Id Type"
                    onChange={(e) => setIDtype(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="upload2">Id Proof Doc</label>
                  <input
                    name="distri_photo"
                    className="form-input"
                    type="file"
                    id="upload2"
                    accept="image/gif, image/jpeg, image/png"
                    required=""
                    onChange={(e) => setIDDoc(e.target.files[0])}
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="upload3">Employee Image</label>
                  <input
                    name="distri_photo"
                    className="form-input"
                    type="file"
                    id="upload3"
                    accept="image/gif, image/jpeg, image/png"
                    required=""
                    onChange={(e) => setempProfile(e.target.files[0])}
                  />
                </div>

                {/* <div className="col-md-4 form-group">
                  <label>Select Distributer</label>
                  <br/>
                  <select aria-label="Default select example" style={{width:"100%", padding:"7px",
                   border:"1px solid gray",borderRadius:"5px"}}>
      <option>Open this select menu</option>
      <option value="1">1</option>
      <option value="2">2</option>
    </select>
                </div> */}
                <div className="col-md-4 form-group">
                  <label for="">&nbsp;</label>
                  <button
                    className="form-control btn btn-primary"
                    onClick={check}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={1} md={1}></Col>
      </Row>
    </div>
  );
}

export default DistributorEmployeeListForm;
