import React, { useEffect, useState } from "react";
import "../../Components/customer/Styles/style12.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TfiWallet } from "react-icons/tfi";
import axios from "axios";
import { Link } from "react-router-dom";

function DistributorHeader() {

  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState();
  const [acc2, setacc2] = useState();
  const [acc3, setacc3] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [address, setaddress] = useState([]);
  const [showAddress, setshowAress] = useState({});

  const distributor = JSON.parse(sessionStorage.getItem("distributor"));

  /*====================================================================================================*/

  // All distributor addresses added...
  let getAddress = async () => {
    try {
      let res = await axios.get(
        "https://spilso.in/api/distributor/address/" + distributor?._id
      );
      if (res.status === 200) {
        setaddress(res.data?.distributoraddress);
        if (res.data?.distributoraddress?.length > 0) {
          setshowAress(res.data?.distributoraddress[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  /*====================================================================================================*/
  //   add address of a distributor ...

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [pincode, setpincode] = useState("");
  const [House, setHouse] = useState("");
  const [Area, setArea] = useState("");
  const [Landmark, setLandmark] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");

  const addAdress = async () => {
    try {
      const config = {
        url: "/distributor/addaddress",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: {
          distributorId: distributor?._id,
          name: name,
          number: mobile,
          addresstype: "Home",
          doorno: House,
          addressline: Landmark,
          area: Area,
          city: city,
          state: state,
          country: country,
          pincode: pincode,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Successfully added");
          handleClose2();
          // getAddress();
          setname("");
          setmobile("");
          setpincode("");
          setHouse("");
          setArea("");
          setLandmark("");
          setcity("");
          setstate("");
          setcountry("");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  /* ============================================================================================== */

  // wishlist related api's
  const [wishlist, setwishlist] = useState([]);
  const getwislist = () => {
    axios
      .get(
        "https://spilso.in/api/getWishlistBydistributorId/" + distributor?._id
      )
      .then(function (response) {
        console.log(response.data);
        setwishlist(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  /* ============================================================================================== */

  const [Carts, setCarts] = useState([]);
  const getCatrDeatils = () => {
    axios
      .get(
        "https://spilso.in/api/getAllCartProductsByDistributor/" +
          distributor?._id
      )
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  /* ============================================================================================== */

  const [wallet, setwallet] = useState({});
  const createWallet = async () => {
    try {
      const config = {
        url: "/distributor/createWallet",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
        data: {
          distributorId: distributor?._id,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setwallet(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("fsdfasfdasfdafd", wallet);

    /* ============================================================================================== */
  useEffect(() => {
    if (distributor) {
      createWallet();
      getAddress();
      getCatrDeatils();
      getwislist();
    }
  }, []);

  return (
    <>
      {/* <!-- Header Section Begin --> */}
      <header className="header">
        <div className="header__top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="header__top__left">
                  <ul>
                    {/* <li>
                      <i className="fa fa-envelope" /> hello@jaaggery.com
                    </li> */}
                    <li>Free Shipping for all Order of $99</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="header__top__right">
                  <div className="header__top__right__social">
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                    <a href="#">
                      <i className="fa fa-instagram" />
                    </a>
                  </div>
                  {/* <div className="header__top__right__language">
              <img src="img/language.png" alt />
              <div>English</div>
              <span className="arrow_carrot-down" />
              <ul>
                <li><a href="#">Spanis</a></li>
                <li><a href="#">English</a></li>
              </ul>
            </div> */}
                  <div className="header__top__right__auth">
                    <a href="/distributor/login">
                      <i className="fa fa-user" />{" "}
                      {distributor ? distributor?.firmname : "Login"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ height: "90px", backgroundColor: "white" }}
        >
          <div className="row">
            <div className="col-lg-3">
              <div className="header__logo">
               
              <a href="/distributor/distributor-home">
                  <img src="./Spilsologo.png" alt="" style={{ width: "42%" }} />
                  <span style={{ paddingLeft: "8px" }}>
                    <img
                      src="./Spilsologo2.png"
                      className="spin"
                      alt=""
                      style={{ width: "45px", height: "45px" }}
                    />
                  </span>
                </a>
              
              </div>
            </div>
            <div className="col-lg-6">
              <nav className="header__menu">
                <ul>
                  <li  className={`${acc ? "active" : ""}`}
                    onClick={() => {
                      setacc(true);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                     
                    }}>
                    <Link to="/distributor/distributor-home">Home</Link>
                  </li>
                  <li className={`${acc1 ? "active" : ""}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(true);
                      setacc2(false);
                      setacc3(false);
                     
                    }}>
                    <Link to="/distributor/distributor-product">
                      Shop By Category
                    </Link>
                  </li>
                  <li className={`${acc2 ? "active" : ""}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(true);
                      setacc3(false);
                     
                    }}>
                    <a>My Account</a>
                    <ul className="header__menu__dropdown">
                      <li
                        onClick={handleShow1}
                        style={{
                          color: "white",
                          cursor: "pointer",
                          fontWeight: " 400",
                          padding: " 5px 15px",
                        }}
                      >
                        My Profile
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-my-wallet"
                          style={{ color: "white" }}
                        >
                          My Wallet
                        </a>
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-myshopping"
                          style={{ color: "white" }}
                        >
                          My Shopping
                        </a>
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-cart"
                          style={{ color: "white" }}
                        >
                          Shopping Cart
                        </a>
                      </li>
                      <li>
                        <a
                          href="/distributor/distributor-track-orders"
                          style={{ color: "white" }}
                        >
                          Track Order
                        </a>
                      </li>
                      {/* <li><a href="./blog-details.html">Blog Details</a></li> */}
                    </ul>
                  </li>
                  {/* <li><a href="./blog.html">Blog</a></li> */}
                  <li className={`${acc3 ? "active" : ""}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(true);
                     
                    }}>
                    <Link to="/distributor/distributor-contact">Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-3">
              <div className="header__cart">
                <ul>
                  <li>
                    <a href="/distributor/distributor-whislist">
                      <i className="fa fa-heart" />
                      <span>{wishlist?.length}</span>
                    </a>
                  </li>
                  <li>
                    <a href="/distributor/distributor-cart">
                      <i className="fa fa-shopping-bag" />
                      <span>{Carts?.length}</span>
                    </a>
                  </li>
                </ul>
                  {distributor?(
                <div className="header__cart__price">
                  <Link to='/distributor/distributor-my-wallet'>
                  <TfiWallet style={{ fontSize: "24px", fontWeight: "600" }} />:{" "}
                  <span>₹{wallet?.totalAmount}.00</span>
                  </Link>
                  </div>
                  )
                  :

                 ( <></>)}
              </div>
            </div>
          </div>
          <div className="humberger__open">
            <i className="fa fa-bars" />
          </div>
        </div>
      </header>

      {/* <!-- Header Section End --> */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Your Profile</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pro_file">
            <div className="container naukri">
              <div className="">
                <div className="added_0">
                  <h3>Profile</h3>
                </div>
                <div className="profile_))">
                  <div className="profile_9 mb-2">
                    <b>Name:</b>
                    <span>{distributor?.partnersname}</span>
                  </div>
                  <div className="profile_9 mb-2">
                    <b>MobileNumber:</b>
                    <span>{distributor?.permanentno}</span>
                  </div>
                  <div className="profile_9 mb-2">
                    <b>Email id:</b>
                    <span>{distributor?.email}</span>
                  </div>
                  <div className="profile_9 mb-2">
                    <b>Delivery Address:</b>
                    <span>
                      {address?.length < 1?
                      <>
                      {" "}{distributor?.villagecode},
                                  {distributor?.addresslandmark},{distributor?.villagename}{" "}
                                  ,{distributor?.taluka}, {distributor?.districtname},{" "}
                                  ,{distributor?.pincode},{distributor?.state}{" "}
                                  ,{distributor?.country},
                      </>
                      :
                      <>
                      {address[0]?.doorno},
                                  {address[0]?.area},{address[0]?.addressline}{" "}
                                  ,{address[0]?.city}, {address[0]?.state},{" "}
                                  {address[0]?.pincode},{address[0]?.country}{" "}
                      </>
                    
                      }
                      <b style={{ cursor: "pointer" }} onClick={handleShow2}>
                        Edit
                      </b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose1}>
            Use this address
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Update your address</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="naukri">
            <div className="added_0">
              <h3>Edit your address</h3>
            </div>
            <div className="">
              <form>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Full name</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Mobile number</label>
                  <input
                    type="tel"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Email Id</label>
                  <input
                    type="email"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Address</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    name="address-ui-widgets-enterAddressFullName"
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Use this address
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add new Address */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Enter a new delivery address</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="naukri">
            <div className="added_0">
              <h3>Add a new address</h3>
            </div>
            <div className="">
              <form>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Full name</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={distributor?.partnersname}
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Mobile number</label>
                  <input
                    type="tel"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder={distributor?.permanentno}
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
                
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Flat, House no., Building, Company, Apartment</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder="Please enter house no ..."
                    value={House}
                    onChange={(e) => setHouse(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Area, Street, Sector, Village</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder="Please enter area..."
                    value={Area}
                    onChange={(e) => setArea(e.target.value)}
                  />
                </div>
                <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                  <label>Near Landmark</label>
                  <input
                    type="text"
                    className="address-ui-widgets"
                    maxlength="50"
                    placeholder="Please enter near landmark"
                    value={Landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                  />
                </div>
                <div className="joker">
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Country</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder="countruy"
                      value={country}
                      onChange={(e) => setcountry(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Sate</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder="sate"
                      value={state}
                      onChange={(e) => setstate(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>City</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder="city"
                      value={city}
                      onChange={(e) => setcity(e.target.value)}
                    />
                  </div>
                  <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                    <label>Pin code</label>
                    <input
                      type="text"
                      className="address-ui-widgets"
                      maxlength="50"
                      placeholder="pincode"
                      value={pincode}
                      onChange={(e) => setpincode(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={addAdress}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DistributorHeader;
