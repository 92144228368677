import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col, Container, Table } from "react-bootstrap";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { data } from "jquery";
import axios from "axios";

function Retailerfooter() {
const [data, setdata] = useState([]);
const apiURL = process.env.REACT_APP_API_URL;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));

  let i=1;
  const classes = useStyles();


  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    gettopretailer();
  }, []);

  const gettopretailer = () => {
    try {
      axios
        .get(`${apiURL}/retailer/topallretailers`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata(response.data.topretailer);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition( true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  return (
    <div className="mt-5">
      <div className="footer">
        <Container>
          <Row xs={1} md={4} className="g-4">
            <Col sm={4}>
              <div>
                <h6>About Us</h6>
                <p>
                Spilso consumer products pvt ltd is an E-Commeerce platform operating in across
                  India. We offer a wide range of food products for all your
                  everyday needs in select cities.
                </p>

                {/*  <ul>
                  <li>Beverages</li>
                  <li>Personal Care</li>
                  <li>Baby Care</li>
                  <li>Fruits and Vegetables</li>
                </ul> */}
              </div>
            </Col>
            <Col sm={4}>
              {" "}
              <div>
                <h6>Customer Services</h6>
                <ul>
                  <li>About Us</li>
                  <li>FAQ</li>
                  <li>Need Help</li>
                </ul>
              </div>
            </Col>
            <Col sm={4}>
              <div>
                <h6>Contact Us</h6>
                <ul>
                  <li>Call Us : +91 9845091018, +91 8041666123</li>
                  {/* <li>Email : Info@ilifeshoppie.com</li> */}
                  <li>
                    Address : Spilso consumer products pvt ltd
                    Ganganagar CBI road bangalore 560024.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="bottom-footer">
          <div className="d-lg-flex justify-content-lg-between">
            <div className="p-2">© 2021 All rights reserved. Spilso</div>
            <div className="p-2">Designed & Developed By Parnets Group</div>
          </div>
        </div>
        <div className="content">
        
 
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{color:"white",padding:"9px"}}
      >
        Top Retailers for the month
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'top',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'bottom',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography >
          
         
          <Table>
   <thead>
    <tr>
      <th></th>
      <th>Name</th>
      <th>Pincode</th>
      <th>Address</th>
    </tr>
  </thead>
  <tbody>

    {data?.map((item)=>(

    <tr>
      <td>{i++}</td>
      <td>{item.name}</td>
      <td>{item.pincode}</td>
      <td>{item.address}</td>
    </tr>
    ))}
 
  </tbody>
</Table>
          
          </Typography>
      </Popover>
    </div>
    <div>
        </div>
       
      </div>
    </div>
  );
}

export default Retailerfooter;
