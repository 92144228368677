import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import { Provider } from "react-redux";
import { createStore } from "redux";
import StoreReducer from "./StoreReducer"
import reportWebVitals from './reportWebVitals';
import 'antd/dist/reset.css';

const store = createStore(StoreReducer);

ReactDOM.render(
  <Provider store = { store }>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
