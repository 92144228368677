import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import moment from "moment";

function DistributorSalesHistory() {
  const [data, setdata] = useState([]);

  const [data1, setdata1] = useState([]);
  const [usertype, setusertype] = useState("");
  const [username, setusername] = useState("");
  const [allexpense, setallexpense] = useState([]);
  const [category, setcategory] = useState("");

  let [endDate, setendDate] = useState();
  let [startDate, setstartDate] = useState();
  const apiURL = process.env.REACT_APP_API_URL;
  // enableRipple(true);
  // useEffect(() => {
  //   gettransaction();
  //   if (usertype == "customer") {
  //     getcustomer();
  //   }
  //   if (usertype == "retailer") {
  //     getretailer();
  //   }
  //   if (usertype == "distributor") {
  //     getdistributor();
  //   }
  // }, [usertype, category]);

  // const gettransaction = async () => {
  //   let res = await axios.get(
  //     apiURL + "/admin/customerstatement/611e141c89f3e628c00cebb5"
  //   );
  //   if ((res.status = 200)) {
  //     console.log(res.data);
  //     setdata1(res.data);
  //   }
  // };

  // const getcustomer = async () => {
  //   let res = await axios.get(apiURL + "/customer/getallcustomer");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setallexpense(
  //       res.data?.customer.filter((item) => item.profilestatus === true)
  //     );
  //   }
  // };

  // const getdistributor = async () => {
  //   let res = await axios.get(apiURL + "/distributor/notapproved");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setallexpense(
  //       res.data?.distributor.filter((item) => item.profilestatus === true)
  //     );
  //   }
  // };

  // const getretailer = async () => {
  //   let res = await axios.get(apiURL + "/retailer/notapproved");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setallexpense(
  //       res.data?.retailer.filter((item) => item.profilestatus === true)
  //     );
  //   }
  // };

  const [nochandata, setnodata] = useState([]);
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));
  useEffect(() => {
    if (!distributor) {
      alert("Please login");
      window.location.assign("/distributor/login");
    }
  }, []);
  useEffect(() => {
    getretailerOrders();
  }, []);
  useEffect(() => {
    getretailerOrders();
  }, []);
  
  const getretailerOrders = async () => {
    try {
      const response = await axios.get(`${apiURL}/retailer/allorders`);
      if (response.status === 200) {
        setdata(
          response.data.allretailerOrders.filter(
            (item) => item.retailerId.distributorId === distributor?._id
          )
        );
        setnodata(
          response.data.allretailerOrders.filter(
            (item) => item.retailerId.distributorId === distributor?._id
          )
        );
      }
    } catch (error) {
      console.warn(error);
      alert(error.response.data.error);
      // setdatacondition(true);
      // return error;
    }
  };

  //excel
  const { ExportCSVButton } = CSVExport;
  const columns = [
    // {
    //   dataField: "retailerId._id",
    //   text: "Retailer Id",
    // },
    // {
    //   text: "User Type",
    // },
    {
      dataField: "retailerId._id",
      text: "Retailer Id",
    },
    {
      dataField: "retailerId.partnersname",
      text: "Retailer Name",
    },
    {
      dataField: "_id",
      text: "Order Id",
    },
    {
      // dataField: "",
      text: "Order date",
      formatter: (cell, row) => {
        return <>{moment(row?.retailerOrderdatetime).format("DD-MM-YYYY")}</>;
      },
    },
    {
      dataField: "status",
      text: "Order status",
    },
    {
      dataField: "allTotal",
      text: "Order Amount",
    },
    {
      dataField: "paymentmethod",
      text: "Method of Payment",
    },
  ];

  function getDateObject(datevalue) {
    let d = new Date(datevalue);

    let v = d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear();
    return v;
  }

  const search = () => {
    // const filteredData = nochandata?.filter(
    //   (data) =>
    //     new Date(
    //       moment(moment(data.retailerOrderdatetime, "DD/MM/YYYY hh:mm A")).format(
    //         "YYYY/MM/DD"
    //       )
    //     ) >= new Date(startDate?.split("/").reverse().join("/")) &&
    //     new Date(
    //       moment(moment(data.retailerOrderdatetime, "DD/MM/YYYY hh:mm A")).format(
    //         "YYYY/MM/DD"
    //       )
    //     ) <= new Date(endDate?.split("/").reverse().join("/"))
    // );
    // setdata(filteredData);

    startDate = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    endDate = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    // alert(startDate);
    // alert(endDate);
    // alert(typeof startDate)
    // alert( typeof endDate)

    const filteredData = nochandata?.filter((item) => {
      let compareD = moment(item.retailerOrderdatetime).format("YYYY-MM-DD");
      // alert(compareD); alert(typeof compareD);
      let d1 = moment(startDate.toString()).diff(compareD.toString(), "days");
      let d2 = moment(endDate.toString()).diff(compareD.toString(), "days");

      if (d1 > 0 || d2 < 0) {
        return false;
      }
      return true;
      // alert(d1);
      // alert(d2);
    });
    setdata(filteredData);
  };

  // console.log(data);
  return (
    <div className="container">
      <div>
        <div className="row">
          <div className="col-md-2 mt-2">
            <h7>Date From</h7>
            <DatePicker
              style={{ height: "35px", width: "140px", marginRight: "-5px" }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="input-icon"
              onChange={(date) => setstartDate(date.format("DD/MM/YYYY"))}
            />
          </div>
          <div className="col-md-2 mt-2">
            <h7>Date To</h7>
            <DatePicker
              style={{ height: "35px", width: "150px", marginRight: "-5px" }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="input-icon"
              onChange={(date) => setendDate(date.format("DD/MM/YYYY"))}
            />
          </div>

          {/* <div className="col-md-2">
            <label className="mt-2">User Type</label>
            <select
              className="form-control"
              onChange={(e) => setusertype(e.target.value)}
            >
              <option value="">Select User Type</option>
              <option value="distributor">Distributor</option>
              <option value="retailer">Retailer</option>
              <option value="customer">Customer</option>
            </select>
          </div>
          <div className="col-md-2">
            <label className="mt-2">User Id</label>
            <select
              className="form-control"
              onChange={(e) => setusertype(e.target.value)}
            >
              <option value="">Select User Id</option>
              <option value="distributor">123</option>
              <option value="retailer">456</option>
              <option value="customer">789</option>
            </select>
          </div>
          <div className="col-md-2">
            <label className="mt-2">User Name</label>
            <select
              className="form-control"
              onChange={(e) => setcategory(e.target.value)}
            >
              <option>Select the user name</option>
              {usertype == "distributor" || usertype == "retailer"
                ? allexpense?.map((data) => (
                    <option value={data._id}>{data.firmname}</option>
                  ))
                : ""}
              {usertype == "customer"
                ? allexpense?.map((data) => (
                    <option value={data._id}>{data.name}</option>
                  ))
                : ""}
            </select>
          </div> */}

          <div className="col-md-2 mt-2">
            <button
              className="btn btn-primary"
              style={{ marginTop: "20px" }}
              onClick={search}
            >
              Submit
            </button>
          </div>
          <div className="col-md-2 mt-3"></div>
        </div>
      </div>
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        exportCSV={{
          fileName: "Accountstatement.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            <span className="pr-5 mr-auto mb-2">
              <ExportCSVButton
                className="btn-success excel-btn"
                {...props.csvProps}
              >
                Download Excels
              </ExportCSVButton>
            </span>

            <div className="table-responsive">
              <BootstrapTable {...props.baseProps} />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}

export default DistributorSalesHistory;
