import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-multi-date-picker";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

function DistributorPurchase() {
  // getcategory();
  const [clickno, setclickno] = useState("false");
  const [clickyes, setclickyes] = useState("true");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    alert("Combo product added sucessfully");
    setShow(false);
  };
  const handleClose1 = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [image1, setimage1] = useState();
  const [image2, setimage2] = useState();
  const [image3, setimage3] = useState();
  const [image4, setimage4] = useState();
  const [video, setvideo] = useState();
  const [category, setcategory] = useState("");
  const [subcategory, setsubcategory] = useState("");
  const [name, setname] = useState("");
  const [Brand, setBrand] = useState("");
  let [price, setprice] = useState(0);
  let [GST, setGST] = useState(0);
  let [MRP, setMRP] = useState(0);
  const [totalstock, settotalstock] = useState();
  const [minstock, setminstock] = useState();
  const [Ordernum, setOrdernum] = useState();
  const [invoicenum, setinvoicenum] = useState();
  // const [Ordernum, setOrdernum] = useState()
  const [desc, setdesc] = useState("");
  const [tax, settax] = useState(0);
  const formdata = new FormData();
  // const token = sessionStorage.getItem("token");
  const apiURL = process.env.REACT_APP_API_URL;
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));
  const [categorydata, setcategorydata] = useState([]);
  const [subcategorydata, setsubcategorydata] = useState([]);

  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());

  const handleCalendarClose = () => console.log("Calendar closed");
  const handleCalendarOpen = () => console.log("Calendar opened");
  const handleCalendarClose1 = () => console.log("Calendar closed");
  const handleCalendarOpen1 = () => console.log("Calendar opened");

  // const [productvolumetype, setproductvolumetype] = useState("");
  // const [productvolume, setproductvolume] = useState("");
  // const [productsize, setproductsize] = useState("");
  // const [productwarranty, setproductwarranty] = useState("");
  // const [productwarrantytype, setproductwarrantytype] = useState("");
  // const [productmodelno, setproductmodelno] = useState("");
  // const [customerofferPrice, setcustomerofferPrice] = useState("");
  // const [retailerofferPrice, setretailerofferPrice] = useState("");
  // const [distributorofferPrice, setdistributorofferPrice] = useState("");
  // const [keyaccountofferPrice, setkeyaccountofferPrice] = useState("");
  // const [corporateofferPrice, setcorporateofferPrice] = useState("");
  // const [employeeofferPrice, setemployeeofferPrice] = useState("");
  // const [customerdiscountpercentage, setcustomerdiscountpercentage] =
  //   useState("");
  // const [retailerdiscountpercentage, setretailerdiscountpercentage] =
  //   useState("");
  // const [distributordiscountpercentage, setdistributordiscountpercentage] =
  //   useState("");
  // const [keyaccountdiscountpercentage, setkeyaccountdiscountpercentage] =
  //   useState("");
  // const [corporatediscountpercentage, setcorporatediscountpercentage] =
  //   useState("");
  // const [employeediscountpercentage, setemployeediscountpercentage] =
  //   useState("");
  // const [productfeatures, setproductfeatures] = useState("");
  // const [productbrand, setproductbrand] = useState("");
  // const [totalstock, settotalstock] = useState("");
  // const [quantitycase, setquantitycase] = useState("");
  // const [hsncode, sethsncode] = useState("");
  // const [free, setfree] = useState("");



  // const [OrderListByDistributor, setOrderListByDistributor] = useState([]);
  // const [ProductId, setProductId] = useState("");
  // const getAllOrdersByDistributor = async()=>{
  //   try{
  //     const orderByDisributor = await axios.get(apiURL + `/distributor/orders/${distributor?._id}`);
  //     if(orderByDisributor.status === 200 && orderByDisributor.data.deliveredorders?.length > 0){
  //       setOrderListByDistributor(orderByDisributor.data.deliveredorders);
  //     }
  //   }catch(error){
  //     console.log(error);
  //   }
  // }


  const top100Films = categorydata;
  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getcategory");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.category);
    }
  };

  useEffect(() => {
    getcategory();
  }, []);
  useEffect(() => {
    getcategory();
  }, []);

  // useEffect(() => {
  //   postallsubcategory();
  // }, [category]);
  // useEffect(() => {
  //   getAllOrdersByDistributor();
  // }, []);


  // const postallsubcategory = async () => {
  //   let res = await axios.post(apiURL + "/admin/postsubcategory", {
  //     categoryName: category,
  //   });
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setsubcategorydata(res.data?.subcategory);
  //   }
  // };

  useEffect(()=>{
    // GST = Number(gst);
    
    price = Number((Math.round(price*100)/100).toFixed(2));
    // alert(typeof price);

    let additionalValue = price*(GST/100); 
    // gets the value to 2 decimal places...
    let addGSTVal = Number((Math.round(additionalValue*100)/100).toFixed(2));
    let mrp = price + addGSTVal;
    setMRP(mrp);
  });

  // function callMRP(gst){
    
  // }
  const purchaseEntry = async () => {
    try {
      const config = {
        url: `/addPurchaseDetails/${distributor._id}`,
        method: "post",
        baseURL: apiURL,
        header: { "content-type": "application/json" },
        data: {
          productCategory:category,
          productName:name,
          productPrice:price,
          GST:GST,
          mrp:MRP,
          productBrand:Brand,

          totalStock:totalstock,
          minStock:minstock,

          purchaseOrderId:Ordernum,
          purchaseDate:date,
          invoiceNum:invoicenum,
          invoiceDate:date1,
        }
      };
      await axios(config).then((res)=>{
        if(res.status === 200){
          alert(res.data.success);
          window.location.assign("/distributor/distributor-stock");
        }
      })
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // const postproduct = async () => {
  //   if (!image1) {
  //     alert("Please select images");
  //   }
  //   if (!category || !subcategory || !name || !hsncode || !customerofferPrice || !retailerofferPrice || !distributorofferPrice || !customerdiscountpercentage || !retailerdiscountpercentage || !price ||!distributordiscountpercentage || !productfeatures || !productbrand || !totalstock || !desc ) {
  //     alert("Please fill mandetory fields");
  //   }
  //   formdata.append("productname", name);
  //   formdata.append("productcategory", category);
  //   formdata.append("productsubcategory", subcategory);
  //   formdata.append("adminId", user.id);
  //   formdata.append("productprice", price);
  //   formdata.append("productdesc", desc);
  //   formdata.append("tax", tax);
  //   formdata.append("productimage", image1);
  //   formdata.append("productimage", image2);
  //   formdata.append("productimage", image3);
  //   formdata.append("productimage", image4);
  //   formdata.append("productvideo", video);
  //   formdata.append("productvolumetype", productvolumetype);
  //   formdata.append("productvolume", productvolume);
  //   formdata.append("productsize", productsize);
  //   formdata.append("productwarranty", productwarranty);
  //   formdata.append("productwarrantytype", productwarrantytype);
  //   formdata.append("productmodelno", productmodelno);
  //   formdata.append("customerofferPrice", customerofferPrice);
  //   formdata.append("retailerofferPrice", retailerofferPrice);
  //   formdata.append("distributorofferPrice", distributorofferPrice);
  //   formdata.append("keyaccountofferPrice", keyaccountofferPrice);
  //   formdata.append("corporateofferPrice", corporateofferPrice);
  //   formdata.append("employeeofferPrice", employeeofferPrice);
  //   formdata.append("customerdiscountpercentage", customerdiscountpercentage);
  //   formdata.append("retailerdiscountpercentage", retailerdiscountpercentage);
  //   formdata.append(
  //     "distributordiscountpercentage",
  //     distributordiscountpercentage
  //   );
  //   formdata.append(
  //     "keyaccountdiscountpercentage",
  //     keyaccountdiscountpercentage
  //   );
  //   formdata.append("corporatediscountpercentage", corporatediscountpercentage);
  //   formdata.append("employeediscountpercentage", employeediscountpercentage);
  //   formdata.append("productfeatures", productfeatures);
  //   formdata.append("productbrand", productbrand);
  //   formdata.append("totalstock", totalstock);
  //   formdata.append("quantitycase", quantitycase);
  //   formdata.append("hsncode", hsncode);
  //   formdata.append("free", free);

  //   try {
  //     const config = {
  //       url: "/admin/addproduct",
  //       method: "post",
  //       baseURL: apiURL,
  //       headers: { Authorization: "Bearer " + token },
  //       data: formdata,
  //     };
  //     const response = await axios(config)
  //       .then(function (response) {
  //         if (response.status === 200) {
  //           alert("Added Successfull");
  //           window.location.assign("/admin/products");

  //           return response;
  //         } else {
  //           alert(" Unsuccessfull");
  //         }
  //       })
  //       .catch(function (error) {
  //         alert(" Unsuccessfull");

  //         console.log(error.config);
  //         return error;
  //       });
  //   } catch (error) {
  //     console.warn(error);

  //     alert(" Unsuccessfull");
  //     return error;
  //   }
  // };
  // console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb",top100Films)
  // const defaultProps = {
  //   options: top100Films,
  //   getOptionLabel: (option) => option,

  // };
  // const flatProps = {
  //   options: top100Films.map((option) => option.title),
  // };
  // const [value, setValue] = React.useState(null);

  return (
    <>
      <div className="container pb-5">
        <Card
          className="text-center mt-5"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <h5 className="pt-5"> Purchase Entry</h5>
          <Card.Body>
            <div className="row pt-3">
              {/* <div className="col-4 " style={{ paddingLeft: "72px" }}>
                <div>ProductId*</div>
                <select
                  id="standard-secondary"
                  style={{ width: "275px", borderRadius: "10px" }}
                  onChange={(e) => setProductId(e.target.value)}
                >
                  <option>Choose ProductId</option>
                  {OrderListByDistributor?.map((item) => {
                    item?.allProduct?.map((item1)=>{
                      return (
                        <option value={item1?.productId}>
                          {item1?.productId}
                        </option>
                      );
                    })
                    
                  })}
                </select>
              </div> */}
              <div className="col-4 " style={{ paddingLeft: "72px" }}>
                {/* <Stack spacing={1} sx={{ width: 280 }}>
                  <Autocomplete
                    {...top100Films}
                    id="disable-close-on-select"
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        variant="standard"
                      />
                    )}
                  />
                </Stack> */}
                {/* <TextField
                id="standard-secondary"
                label="Category*"
                style={{ width: "275px" }}
                onChange={(e) => setname(e.target.value)}
              /> */}
              <div>Category*</div>
                <select
                  id="standard-secondary"
                  style={{ width: "275px", borderRadius: "10px" }}
                  onChange={(e) => setcategory(e.target.value)}
                >
                  <option>Choose Category</option>
                  {categorydata?.map((item) => {
                    return (
                      <option value={item?.categoryName}>
                        {item?.categoryName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product Name With Title*"
                  style={{ width: "275px" }}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product Price*"
                  style={{ width: "275px" }}
                  onChange={(e) => setprice(e.target.value)}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="GST*"
                  style={{ width: "275px" }}
                  onChange={(e) => {
                    setGST(e.target.value);
                    // callMRP(e.target.value);
                    // let additionalValue = (price*(GST/100)); 
                    // // gets the value to 2 decimal places...
                    // let addGSTVal = (Math.round(additionalValue*100)/100).toFixed(2);
                    // let mrp = price + addGSTVal;
                    // MRP = mrp;
                    
                  }}
                />
              </div>

              <div className="col-4">
                {MRP > 0?
                <TextField
                id="standard-secondary"
                label="MRP*"
                value={MRP}
                style={{ width: "275px" }}
                
              />
              // <div>{MRP}</div>
                :
                <TextField
                id="standard-secondary"
                label="MRP*"
                value={MRP}
                style={{ width: "275px" }}
                
              />
                }
                
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product Brand"
                  style={{ width: "275px" }}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </div>
              {/* <div className="col-4">
               
                <div>Stock Date</div>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  id="standard-secondary"
                  style={{
                    width: "275px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeftL: "none",
                  }}
                  onCalendarClose={handleCalendarClose}
                  onCalendarOpen={handleCalendarOpen}
                />
              </div> */}
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Total Stock"
                  style={{ width: "275px" }}
                  onChange={(e) => settotalstock(e.target.value)}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Minimum Stock"
                  style={{ width: "275px" }}
                  onChange={(e) => setminstock(e.target.value)}
                />
              </div>
              {/* <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Current Stock"
                  style={{ width: "275px" }}
                  // onChange={(e) => setproductmodelno(e.target.value)}
                />
              </div> */}
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Purchase Order No"
                  style={{ width: "275px" }}
                  onChange={(e) => setOrdernum(e.target.value)}
                />
              </div>
              <div className="col-4">
                <div>Purchase Date</div>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  id="standard-secondary"
                  style={{
                    width: "275px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeftL: "none",
                  }}
                  onCalendarClose={handleCalendarClose}
                  onCalendarOpen={handleCalendarOpen}
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Invoice No"
                  style={{ width: "275px" }}
                  onChange={(e) => setinvoicenum(e.target.value)}
                />
              </div>
              <div className="col-4">
                {/* <TextField
                  id="standard-secondary"
                  label="Invoice Date"
                  style={{ width: "275px" }}
                  // onChange={(e) => setproductmodelno(e.target.value)}
                /> */}
                <div>Invoice Date</div>
                <DatePicker
                  selected={date1}
                  onChange={(date1) => setDate1(date1)}
                  id="standard-secondary"
                  style={{
                    width: "275px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeftL: "none",
                  }}
                  onCalendarClose={handleCalendarClose1}
                  onCalendarOpen={handleCalendarOpen1}
                />
              </div>
              <div className="col-4 mt-3">
                <Button onClick={purchaseEntry}>Submit</Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <Modal show={show} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>ADD COMBO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ backgroundColor: "white" }}
            >
              <Form.Label>
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#2e3da0ad",
                  }}
                >
                  Combo product Name
                </h6>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product Name"
                autoFocus
                // value={comboproduct}
                // onChange={(e) => {
                //   setcomboproduct(e.target.value);
                // }}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    paddingTop: "10px",
                    color: "#2e3da0ad",
                  }}
                >
                  Combo product description
                </h6>
              </Form.Label>
              {/* <Form.Control
            as="textarea"
            value={combodescription}
            onChange={(e) => {
              setcombodescription(e.target.value);
            }}
            rows={3}
          /> */}
              <CKEditor
                editor={ClassicEditor}
                // data={combodescription}
                // onChange={handleChange1}
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    paddingTop: "10px",
                    color: "#2e3da0ad",
                  }}
                >
                  Combo product Price
                </h6>
              </Form.Label>
              <Form.Control
                type="text"
                // value={comboprice}
                // onChange={(e) => {
                //   setcomboprice(e.target.value);
                // }}
                rows={3}
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    paddingTop: "10px",
                    color: "#2e3da0ad",
                  }}
                >
                  Combo product Free Price
                </h6>
              </Form.Label>
              <Form.Control
                type="text"
                // value={combofreeprice}
                // onChange={(e) => {
                //   setcombofreeprice(e.target.value);
                // }}
                rows={3}
              />
            </Form.Group>
            <div style={{ display: "flex" }}>
              <h6
                style={{
                  marginTop: "40px",
                  marginRight: "30px",
                  marginLeft: "30px",
                  fontSize: "15px",
                  fontWeight: "500",
                  width: "100%",
                  color: "#2e3da0ad",
                }}
              >
                SELECT COMBO IMAGE
              </h6>
              {/* {comboImage && (
            <div>
              <img
                src={URL.createObjectURL(comboImage)}
                alt="Thumb"
                width={100}
                height={100}
              ></img>
            </div>
          )} */}
              <label htmlFor="upload12">
                <span>
                  <img
                    src="/img/upload.png"
                    alt=""
                    style={{
                      width: "25%",
                      paddingTop: "10%",
                      marginLeft: "30%",
                    }}
                  />
                </span>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="file"
                  id="upload12"
                  accept="image/*"
                  // onChange={(e) => setcomboImage(e.target.files[0])}
                />
              </label>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#2e3da0ad" }}
            onClick={handleClose}
          >
            ADD COMBO
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
//  [
//   { title: "The Shawshank Redemption", year: 1994 },
//   { title: "The Godfather", year: 1972 },
//   { title: "The Godfather: Part II", year: 1974 },
//   { title: "The Dark Knight", year: 2008 },
//   { title: "12 Angry Men", year: 1957 },
//   { title: "Schindler's List", year: 1993 },
//   { title: "Pulp Fiction", year: 1994 },
//   {
//     title: "The Lord of the Rings: The Return of the King",
//     year: 2003,
//   },
//   { title: "The Good, the Bad and the Ugly", year: 1966 },
//   { title: "Fight Club", year: 1999 },
//   {
//     title: "The Lord of the Rings: The Fellowship of the Ring",
//     year: 2001,
//   },
//   {
//     title: "Star Wars: Episode V - The Empire Strikes Back",
//     year: 1980,
//   },
//   { title: "Forrest Gump", year: 1994 },
//   { title: "Inception", year: 2010 },
//   {
//     title: "The Lord of the Rings: The Two Towers",
//     year: 2002,
//   },
//   { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
//   { title: "Goodfellas", year: 1990 },
//   { title: "The Matrix", year: 1999 },
//   { title: "Seven Samurai", year: 1954 },
//   {
//     title: "Star Wars: Episode IV - A New Hope",
//     year: 1977,
//   },
//   { title: "City of God", year: 2002 },
//   { title: "Se7en", year: 1995 },
//   { title: "The Silence of the Lambs", year: 1991 },
//   { title: "It's a Wonderful Life", year: 1946 },
//   { title: "Life Is Beautiful", year: 1997 },
//   { title: "The Usual Suspects", year: 1995 },
//   { title: "Léon: The Professional", year: 1994 },
//   { title: "Spirited Away", year: 2001 },
//   { title: "Saving Private Ryan", year: 1998 },
//   { title: "Once Upon a Time in the West", year: 1968 },
//   { title: "American History X", year: 1998 },
//   { title: "Interstellar", year: 2014 },
//   { title: "Casablanca", year: 1942 },
//   { title: "City Lights", year: 1931 },
//   { title: "Psycho", year: 1960 },
//   { title: "The Green Mile", year: 1999 },
//   { title: "The Intouchables", year: 2011 },
//   { title: "Modern Times", year: 1936 },
//   { title: "Raiders of the Lost Ark", year: 1981 },
//   { title: "Rear Window", year: 1954 },
//   { title: "The Pianist", year: 2002 },
//   { title: "The Departed", year: 2006 },
//   { title: "Terminator 2: Judgment Day", year: 1991 },
//   { title: "Back to the Future", year: 1985 },
//   { title: "Whiplash", year: 2014 },
//   { title: "Gladiator", year: 2000 },
//   { title: "Memento", year: 2000 },
//   { title: "The Prestige", year: 2006 },
//   { title: "The Lion King", year: 1994 },
//   { title: "Apocalypse Now", year: 1979 },
//   { title: "Alien", year: 1979 },
//   { title: "Sunset Boulevard", year: 1950 },
//   {
//     title:
//       "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
//     year: 1964,
//   },
//   { title: "The Great Dictator", year: 1940 },
//   { title: "Cinema Paradiso", year: 1988 },
//   { title: "The Lives of Others", year: 2006 },
//   { title: "Grave of the Fireflies", year: 1988 },
//   { title: "Paths of Glory", year: 1957 },
//   { title: "Django Unchained", year: 2012 },
//   { title: "The Shining", year: 1980 },
//   { title: "WALL·E", year: 2008 },
//   { title: "American Beauty", year: 1999 },
//   { title: "The Dark Knight Rises", year: 2012 },
//   { title: "Princess Mononoke", year: 1997 },
//   { title: "Aliens", year: 1986 },
//   { title: "Oldboy", year: 2003 },
//   { title: "Once Upon a Time in America", year: 1984 },
//   { title: "Witness for the Prosecution", year: 1957 },
//   { title: "Das Boot", year: 1981 },
//   { title: "Citizen Kane", year: 1941 },
//   { title: "North by Northwest", year: 1959 },
//   { title: "Vertigo", year: 1958 },
//   {
//     title: "Star Wars: Episode VI - Return of the Jedi",
//     year: 1983,
//   },
//   { title: "Reservoir Dogs", year: 1992 },
//   { title: "Braveheart", year: 1995 },
//   { title: "M", year: 1931 },
//   { title: "Requiem for a Dream", year: 2000 },
//   { title: "Amélie", year: 2001 },
//   { title: "A Clockwork Orange", year: 1971 },
//   { title: "Like Stars on Earth", year: 2007 },
//   { title: "Taxi Driver", year: 1976 },
//   { title: "Lawrence of Arabia", year: 1962 },
//   { title: "Double Indemnity", year: 1944 },
//   {
//     title: "Eternal Sunshine of the Spotless Mind",
//     year: 2004,
//   },
//   { title: "Amadeus", year: 1984 },
//   { title: "To Kill a Mockingbird", year: 1962 },
//   { title: "Toy Story 3", year: 2010 },
//   { title: "Logan", year: 2017 },
//   { title: "Full Metal Jacket", year: 1987 },
//   { title: "Dangal", year: 2016 },
//   { title: "The Sting", year: 1973 },
//   { title: "2001: A Space Odyssey", year: 1968 },
//   { title: "Singin' in the Rain", year: 1952 },
//   { title: "Toy Story", year: 1995 },
//   { title: "Bicycle Thieves", year: 1948 },
//   { title: "The Kid", year: 1921 },
//   { title: "Inglourious Basterds", year: 2009 },
//   { title: "Snatch", year: 2000 },
//   { title: "3 Idiots", year: 2009 },
//   { title: "Monty Python and the Holy Grail", year: 1975 },
// ];

export default DistributorPurchase;
