import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

function Customerorders() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [reason, setreason] = useState("");
  const [issue, setissue] = useState("");
  const [comments, setcomments] = useState("");
  const [show, setShow] = useState(false);
  const [selected, setselected] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setselected(data);
  };

  const returnorders = async (data) => {
    try {
      const config = {
        url: "/admin/addreturnorders",
        method: "post",
        baseURL: apiURL,
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer ",
        },
        data: {
          orderId: data._id,
          customerId: data.customers[0]._id,
          productId: data.products[0]._id,
          paymentmethod: data.paymentmethod,
          doorno: data.doorno,
          addressline: data.addressline,
          area: data.area,
          city: data.city,
          reason: reason,
          issue: issue,
          comments: comments,
          state: data.state,
          country: data.country,
          pincode: data.pincode,
          total: data.total,
          type: data.type,
          quantity: data.quantity,
          orderdatetime: data.orderdatetime,
          status: "requested",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        window.location.assign("/orders");
        window.location.reload("/");

        return res;
      } else {
        console.log(res.data);

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getcustomer();
  }, []);

  const getcustomer = () => {
    try {
      axios
        .get(`${apiURL}/customer/orders/${user._id}`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.orders);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ],
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/customers/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const cancel = async (data) => {
    try {
      const config = {
        url: "/cancel/" + data._id,
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: { data: data },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);

        alert("Order Cancelled");
        window.location.reload();

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      dataField: "_id",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "paymentId",
      text: "Transaction Id",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row.paymentmethod === "online"
              ? row.paymentId
              : row.paymentmethod.toUpperCase()}
          </div>
        );
      },
    },
    {
      dataField: "paymentmethod",
      text: "Payment Method",
    },
    {
      dataField: "",
      text: "Paid Amount",
      formatter: (cell, row) => {
        return (
          <span>
            {parseFloat(row.products[0].customerofferPrice) *
              parseInt(row.quantity)
               +
              (row.deliverycharge ? parseInt(row.deliverycharge) : 0) 
              -
              (row.promodiscount ? parseFloat(row.promodiscount) : 0).toFixed(2)}
          </span>
        );
      },
    },
    {
      dataField: "orderdatetime",
      text: "Placed On",
    },

    {
      dataField: "address[0].doorno",
      text: "Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row?.addresstype == "apartment" ? row?.apartmentname : ""},{" "}
            {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
            {row?.state}, {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
    },

    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(
          moment() <= moment(row.deliverydate).add(7, "days"),
          moment().format("DD/MM/YYYY").split("/")[0] <=
            moment(row.deliverydate)
              .add(7, "days")
              .format("DD/MM/YYYY")
              .split("/")[0] ||
            moment().format("DD/MM/YYYY").split("/")[1] <=
              moment(row.deliverydate)
                .add(7, "days")
                .format("DD/MM/YYYY")
                .split("/")[1],
          moment().format("DD/MM/YYYY").split("/")[0],
          moment(row.deliverydate).add(7, "days").format("DD/MM/YYYY")
        );
        return (
          <>
            {row.status === "inprocess" ? (
              <div>
                <Button className="btn btn-danger" onClick={() => cancel(row)}>
                  Cancel
                </Button>
              </div>
            ) : (
              <div>
                {row.status === "cancelled" ? (
                  <div>
                    <Button className="btn btn-info">Cancelled</Button>
                  </div>
                ) : (
                  <div>
                    {row.status === "delivered" &&
                    moment() <= moment(row.deliverydate).add(7, "days") ? (
                      <div>
                        <Button
                          className="btn btn-info"
                          onClick={() => handleShow(row)}
                        >
                          Return
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {row.status === "requested for return" ? (
                          <>
                            <div>
                              {" "}
                              <Button className="btn btn-info">
                                Requested for Return
                              </Button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div>
              {row.status === "outfordelivery" ? (
                <div>
                  <Link
                    to={{ pathname: "/ordermap", state: { data: row } }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button className="btn btn-info">Track Order</Button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        );
      },
    },
    {
      dataField: "address[0].doorno",
      text: "Invoice",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row.status === "cancelled" ? "" : ( <Link
              to={{ pathname: "/cinvoice", state: { data: row } }}
              style={{ textDecoration: "none" }}
            >
              <Button className="btn btn-info">Invoice</Button>
            </Link>)}
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          My orders
        </h1>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "customer.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="customer"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Reason For return </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              <div className="form-group">
                <label>Reason for return</label>
                <select
                  className="form-control"
                  name="status"
                  on
                  onChange={(e) => setreason(e.target.value)}
                >
                  <option value="">Select Reason</option>
                  <option value="Wrong shippment">Wrong shippment</option>
                  <option value="Product damage">Product damage</option>
                </select>
              </div>{" "}
              <div className="form-group">
                <label>Issue with item</label>
                <select
                  className="form-control"
                  name="status"
                  on
                  onChange={(e) => setissue(e.target.value)}
                >
                  <option value="">Select Issue</option>
                  <option value="Different product">Different product</option>
                </select>
                <label>Comments</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setcomments(e.target.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => returnorders(selected)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Customerorders;
