import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { connect } from "react-redux";
import ReactImageMagnify from "react-image-magnify";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import axios from "axios";
import moment from "moment";
import { Checkout } from "capacitor-razorpay";
function Subscription(props) {
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [enddate, setenddate] = useState(new Date());
  const [startdate, setstartdate] = useState(new Date());
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState(false);
  const [paymentmode, setpaymentmode] = useState("");
  const [image, setimage] = useState(
    props.subscribe.product.type == "admin"
      ? imageURL + "/admin/product/" + props.subscribe.product.productimage[0]
      : props.subscribe.product.type == "vendor"
      ? imageURL + "/vendor/product/" + props.subscribe.product.productimage[0]
      : imageURL +
        "/retailer/product/" +
        props.subscribe.product.productimage[0]
  );
  let paymentid = "";
  const [showaddress, setshowaddress] = useState(false);
  const [address, setaddress] = useState([]);
  const [addresses, setaddresses] = useState("");
  const handleshowaddress = () => {
    if (enddate === moment().format("DD/MM/YYYY")) {
      alert("Select Range of Subscription Date fields");
      setshowaddress(false);
    } else {
      setshowaddress(true);
    }
  };
  const handleClose = () => {
    setshowaddress(false);
  };
  const apiURL = process.env.REACT_APP_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  let token = sessionStorage.getItem("token");

  useEffect(() => {
    setValue1(false);
  }, [value1]);
  const checkpayment = () => {
    if (!paymentmode) {
      alert("please select payment method");
    } else if (paymentmode === "online") {
      posttransaction();
    } else {
      subscribe();
    }
  };
  useEffect(() => {
    getaddress();
  }, []);
  const getaddress = () => {
    try {
      axios
        .get(`${apiURL}/customer/address/${user._id}`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setaddress(response.data.customeraddress);
            return;
          } else {
            alert("Can't able to fetch ");

            return;
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");

      return error;
    }
  };
  console.log(
    moment(enddate, "DD/MM/YYYY").diff(moment(startdate, "DD/MM/YYYY"), "days")
  );
  const posttransaction = async () => {
    try {
      const config = {
        data: {
          key: "rzp_test_xFpxkjLgSh2gWZ",
          // key: "rzp_live_NSs6yq77ASo8rd",
          // keysceret : e9esNHQwJ4pa41dVRkHLj761
          amount: (
            parseFloat(props.subscribe.product.customerofferPrice) *
            parseInt(props.subscribe.quantity) *
            100 *
            moment(enddate, "DD/MM/YYYY").diff(
              moment(startdate, "DD/MM/YYYY"),
              "days"
            )
          ).toFixed(2),
          currency: "INR",
          name: "Orders",
          description: "Test Transaction",
          // image: "logo.jpg",
          // orderId: newdata._id,
          customerId: user.id,
          customername: user.name,
          customeremail: user.email,
          customernumber: user.phoneNumber,

          prefill: {
            name: user.name,
            email: user.email,
            contact: user.phoneNumber,
          },
        },
      };

      let data = await Checkout.open(config.data);
      if (data.response.razorpay_payment_id) {
        // sendpaymentid(data.response);
        paymentid = data.response.razorpay_payment_id;
        subscribe();
      }

      console.log(data.response);
    } catch (error) {
      console.log(error);
      console.log(JSON.parse(error));
      alert("Incomplete Transaction");
    }
  };

  const subscribe = async (e) => {
    try {
      const config = {
        url: "/customer/addsubscriptions",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: {
          customerId: user._id,
          // customeraddressId: address[0]._id,
          vendorId: props.subscribe.product?.vendorId,
          adminId: props.subscribe.product?.adminId,
          retailerId: props.subscribe.product?.retailerId,
          productId: props.subscribe.product._id,
          startdate: startdate,
          enddate: enddate,
          subscriptiontype: "Daily",
          orderdatetime: moment().format("DD/MM/YYYY hh:mm A"),
          subscriptiontotal:
            (
              parseFloat(props.subscribe.product.customerofferPrice) *
              parseInt(props.subscribe.quantity) *
              100
            ).toFixed(2) *
            moment(enddate, "DD/MM/YYYY").diff(
              moment(startdate, "DD/MM/YYYY"),
              "days"
            ),
          total:
            parseFloat(props.subscribe.product.customerofferPrice) *
            parseInt(props.subscribe.quantity),
          quantity: props.subscribe.quantity,
          paymentmethod: paymentmode,
          addresstype: value.split("|")[0],
          apartmentname: value.split("|")[1],
          doorno: value.split("|")[2],
          addressline: value.split("|")[3],
          area: value.split("|")[4],
          city: value.split("|")[5],
          state: value.split("|")[6],
          country: value.split("|")[7],
          pincode: value.split("|")[8],
          phonenumber: user.phoneNumber,
          paymentid: paymentid,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Subscription Successfull");
        window.location.assign("/subscriptionorders");
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const imageProps = {
    smallImage: {
      alt: "img",
      isFluidWidth: true,
      src: image,
    },
    largeImage: {
      src: image,
      width: 1200,
      height: 1200,
    },
    enlargedImageContainerStyle: { background: "#fff", zIndex: 9 },
  };

  return (
    <div className="pt-3 container-fluid">
      <h3 className="pt-5 pl-5">Subscription</h3>
      {props.subscribe === null ? (
        "please go back"
      ) : (
        <Row>
          <Col className="pt-5" lg={5}>
            <ReactImageMagnify {...imageProps} />
            <div className="d-flex mb-4 ml-3 mt-2">
              {props.subscribe.product.type == "admin"
                ? props.subscribe.product.productimage.map((i) => (
                    <div className="border ml-1 mr-1">
                      <span>
                        <img
                          src={imageURL + "/admin/product/" + i}
                          onClick={() =>
                            setimage(imageURL + "/admin/product/" + i)
                          }
                          height="100px"
                          width="100px"
                        />
                      </span>
                    </div>
                  ))
                : props.subscribe.product.type == "vendor"
                ? props.subscribe.product.productimage.map((i) => (
                    <div className="border ml-1 mr-1">
                      <span>
                        <img
                          src={imageURL + "/vendor/product/" + i}
                          onClick={() =>
                            setimage(imageURL + "/vendor/product/" + i)
                          }
                          height="100px"
                          width="100px"
                        />
                      </span>
                    </div>
                  ))
                : props.subscribe.product.productimage.map((i) => (
                    <div className="border ml-1 mr-1">
                      <span>
                        <img
                          src={imageURL + "/retailer/product/" + i}
                          onClick={() =>
                            setimage(imageURL + "/retailer/product/" + i)
                          }
                          height="100px"
                          width="100px"
                        />
                      </span>
                    </div>
                  ))}
            </div>
          </Col>
          <Col className="pt-3" lg={7}>
            <div
              className="pt-5 pb-3"
              style={{ fontWeight: "700", fontSize: "20px" }}
            >
              {props.subscribe.product.productName}
            </div>
            <div className="pb-3 flex-column">
              Subscription type
              <select className="mt-2 form-control w-50">
                <option>Select Subscription Type</option>
                <option>Daily</option>
              </select>
            </div>
            <div className="pb-3 flex-column">
              <div className="pb-2">Start Date</div>
              <DatePicker
                style={{ height: "38px" }}
                format="DD/MM/YYYY"
                type="input-icon"
                minDate={new Date()}
                onChange={(date) => setstartdate(date.format("DD/MM/YYYY"))}
              />
            </div>
            <div className="pb-3 flex-column">
              <div className="pb-2">End Date</div>
              <DatePicker
                style={{ height: "38px" }}
                format="DD/MM/YYYY"
                type="input-icon"
                minDate={new Date()}
                onChange={(date) => setenddate(date.format("DD/MM/YYYY"))}
              />
            </div>
            <div className="pb-3 flex-column">
              Quantity
              <div>
                <Button
                  className="mr-2 text-center"
                  style={{ width: "30px" }}
                  onClick={() => {
                    props.dispatch({ type: "add", item: props.subscribe });
                    setValue1(true);
                  }}
                >
                  +
                </Button>
                <span>{props.subscribe.quantity}</span>
                <Button
                  className="ml-2"
                  style={{ width: "30px" }}
                  onClick={() => {
                    props.dispatch({ type: "sub", item: props.subscribe });
                    setValue1(true);
                  }}
                >
                  -
                </Button>
              </div>
            </div>
            <div className="pb-3 flex-column">
              Amount
              <div className="pl-2 pt-2">
                {(
                  props.subscribe.product.customerofferPrice *
                  props.subscribe.quantity
                ).toFixed(2)}
              </div>
            </div>
            <div className="pb-3 flex-column">
              Total
              <div className="pl-2 pt-2">
                {(
                  parseFloat(props.subscribe.product.customerofferPrice) *
                  parseInt(props.subscribe.quantity) *
                  moment(enddate, "DD/MM/YYYY").diff(
                    moment(startdate, "DD/MM/YYYY"),
                    "days"
                  )
                ).toFixed(2)}
              </div>
            </div>
            <div className="pb-3 flex-column">
              Payment Method
              <select
                className="form-control"
                placeholder="select Payment Mode"
                style={{ height: "30px", fontSize: "12px" }}
                onChange={(e) => setpaymentmode(e.target.value)}
              >
                <option value="">Select Payment Mode</option>
                <option value="cod">Cash On Delivery</option>
                <option value="online">Online</option>
              </select>
            </div>
            <Button
              className="mt-3 mb-3"
              variant="outlined"
              color="primary"
              onClick={handleshowaddress}
            >
              Subscribe Product
            </Button>
          </Col>
        </Row>
      )}

      <Modal show={showaddress} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Shipping Address</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowWrap: "anywhere" }}>
          <div className="container ">
            <div className=" rounded ">
              <div className="p-2 ml-3">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Address</FormLabel>

                  <RadioGroup
                    aria-label="address"
                    name="address1"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  >
                    <FormControlLabel
                      value={`${user.addresstype}|${user.apartmentname}|${user.doorno}|${user.addressline}|${user.area}|${user.city}|${user.state}|${user.country}|${user.pincode}`}
                      control={<Radio />}
                      label={
                        <>
                          {user.addresstype === "apartment"
                            ? user.apartmentname
                            : ""}
                          {user.doorno},{user.addressline},{user.area},
                          {user.city},{user.state},{user.country},{user.pincode}
                        </>
                      }
                    />
                    {address?.map((addr) => (
                      <FormControlLabel
                        value={`${addr.addresstype}|${addr.apartmentname}|${addr.doorno}|${addr.addressline}|${addr.area}|${addr.city}|${addr.state}|${addr.country}|${addr.pincode}`}
                        control={<Radio />}
                        label={
                          <>
                            {addr.addresstype === "apartment"
                              ? addr.apartmentname
                              : ""}
                            {addr.doorno},{addr.addressline},{addr.area},
                            {addr.city},{addr.state},{addr.country},
                            {addr.pincode}
                          </>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="mt-2 ">
                <button
                  className="btn btn-info "
                  onClick={() => window.location.assign("/addaddress")}
                >
                  Add Address
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outlined" color="primary" onClick={checkpayment}>
            Subscribe
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    subscribe: state.Subscribe,
  };
};
export default connect(mapStateToProps)(Subscription);
