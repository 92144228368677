import React, { useEffect, useState } from "react";
// import Addsingleproduct from "./Addsingleproduct";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-multi-date-picker";

function Retaileraddedproduct() {
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [product, setproduct] = useState([]);
  const [excel, setexcel] = useState();
  const [image, setimage] = useState();
  const [data1, setdata1] = useState([]);
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [stock, setstock] = useState("");
  const [free, setfree] = useState("");
  const [date, setdate] = useState(new Date());
  const [selected, setselected] = useState({});
  const [basicprice, setbasicprice] = useState(selected.productprice);
  const [tax, settax] = useState("");
  const [productname, setproductname] = useState("");
  const [mrp, setmrp] = useState("");
  //
  const [rop, setrop] = useState("");
  const [rdp, setrdp] = useState("");
  const [desc, setdesc] = useState("");
  const [feature, setfeature] = useState("");
  const [brand, setbrand] = useState("");
  const [selected1, setselected1] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  
  const [isOpen, setIsOpen] = useState(false);
  const showModal = (data) => {
    setselected(data);
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  useEffect(() => {
    getproduct();
  }, []);

  if (!user) {
    alert("Please Login");
    window.location.assign("/retailer/signin");
  }
  const postcategory = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/addadminstock",
        method: "post",
        baseURL: apiurl,
        // data: formdata,
        headers: { "content-": "application/json" },
        data: {
          productId: selected?._id,
          stock: stock,
          free: free,
          recieptdate: date,
          basicprice: basicprice,
          tax: tax,
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("Stock added successfully");
          window.location.reload();
        }
      });
    } catch (error) {
      console.error(error);
      alert("STOCK Not Added");
    }
  };
  const getproduct = async () => {
    let res = await axios.get(apiurl + "/retailer/retailerproduct/" + user._id);
    if ((res.status = 200)) {
      console.log(res);
      setproduct(res.data?.retailerproducts);
    } else {
      alert("Something Went Wrong");
    }
  };

  if (excel && data1.length === 0) {
    readFile();
  }
  const editproduct = async () => {
    try {
      const config = {
        url: "/admin/editproduct",
        method: "post",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: {
          productId: selected1._id,
          productname: productname ? productname : selected1.productname,
          productprice: mrp ? mrp : selected1.productprice,
          customerofferPrice: rop ? rop : selected1.customerofferPrice,
          customerdiscountpercentage: rdp
            ? rdp
            : selected1.customerdiscountpercentage,
          productbrand: brand ? brand : selected1.productbrand,
          productdesc: desc ? desc : selected1.productdesc,
          productfeatures: feature ? feature : selected1.productfeatures,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("details has been edited successfully");
        window.location.reload();
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(product);

  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setdata1(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }

  function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  const postorders = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/retailer/addmultiproduct",
        method: "post",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: {
          retailerId: user._id,
          product: data1.map((datas) => ({
            productname: datas.Product_Name,
            productcategory: datas.Category,
            productsubcategory: datas.Subcategory,
            image1: datas.Product_Image_1_Name,
            image2: datas.Product_Image_2_Name,
            image3: datas.Product_Image_3_Name,
            image4: datas.Product_Image_4_Name,
            video: datas.Product_Video_Name,
            productprice: datas.MRP_Price,
            productdesc: datas.Description,
            tax: datas.GST,
            productvolumetype: datas.Product_Volume_Type,
            productvolume: datas.Product_Volume,
            productsize: datas.Product_Size,
            productwarranty: datas.Product_Warranty,
            productwarrantytype: datas.Product_Warranty_Type,
            productmodelno: datas.Product_Model_No,
            customerofferPrice: datas.Offer_Price,
            customerdiscountpercentage: datas.Discount_Percentage,
            productfeatures: datas.Product_Features,
            productbrand: datas.Product_Brand,
            totalstock: datas.Total_Stock,
            quantitycase: datas.Quantity_Case,
            hsncode: datas.HSN_Code,
            free: datas.Free,
          })),
        },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Products Added Successfully");
        window.location.assign("/retailer/myproducts");

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postimage = async (e) => {
    e.preventDefault();
    Array.from(image).forEach((file) => {
      formdata.append("productimage", file);
    });
    // image.map((d)=>{

    // })

    try {
      const config = {
        url: "/admin/productimage",
        method: "post",
        baseURL: apiurl,
        // headers:{ 'Authorization':"Bearer "+token},
        data: formdata,

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Image Uploaded Successfully");

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const remove = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/deleteproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const subscribe = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/subscribeproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unsubscribe = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/unsubscribeproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: product.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/subcategory/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "productcategory",
      text: "Category",
      sort: true,
    },
    {
      dataField: "productsubcategory",
      text: "Subcategory",
      sort: true,
    },
    {
      dataField: "productname",
      text: "Product Name",
    },
    {
      dataField: "productprice",
      text: "MRP",
    },
    {
      dataField: "customerofferPrice",
      text: "CUstomer Offer Price",
    },
    {
      dataField: "customerdiscountpercentage",
      text: "Customer Discount Percentage",
    },
    {
      dataField: "productdesc",
      text: "Product Description",
    },
    {
      dataField: "productfeatures",
      text: "Product Features",
    },
    {
      dataField: "productbrand",
      text: "Product Brand",
    },
    {
      dataField: "totalstock",
      text: "Total Stock",
    },
    {
      dataField: "remainingstock",
      text: "Remaining Stock",
    },
    {
      dataField: "",
      text: "Edit",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-outline-success"
              onClick={() => {
                setselected1(row);
                showModal1();
              }}
            >
              Edit
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Add Stock",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-success" onClick={() => showModal(row)}>
              Add Stock
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div className="d-flex">
            <button className="btn btn-danger" onClick={() => remove(row)}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  var i = 1;
  return (
    <div className="container mt-3">
      <div className="d-flex">
        <a href="/retailer/addproducts" className="btn btn-primary mb-2 me-2">
          Add Product
        </a>
        <button className="btn btn-outline-info me-2">
          <a
            style={{ textDecoration: "none" }}
            href={imageURL + "/excelfile/retailerproduct.xlsx"}
          >
            Download Excel
          </a>
        </button>
        <input
          accept=".xlsx,.xls"
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
          onChange={(e) => setexcel(e.target.files[0])}
        />
        <label className="btn btn-outline-info mt-2" htmlFor="icon-button-file">
          {" "}
          Import Excel
        </label>
        <button
          className="btn btn-outline-success ms-2 me-2"
          onClick={postorders}
        >
          Upload
        </button>
        <input
          accept="image/*, video/*"
          style={{ display: "none" }}
          id="icon-button-file1"
          type="file"
          multiple
          onChange={(e) => setimage(e.target.files)}
        />
        <label
          className="btn btn-outline-info mt-2"
          htmlFor="icon-button-file1"
        >
          {" "}
          Select Images/Videos
        </label>
        <button
          className="btn btn-outline-success ms-2 me-2"
          onClick={postimage}
        >
          Upload Images/Videos
        </button>
      </div>
      <div className="mt-3">
        <ToolkitProvider
          keyField="id"
          data={product}
          columns={columns}
          search
          exportCSV={{
            fileName: "Products.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pr-5 mr-auto">
                <ExportCSVButton
                  className="btn-outline-success"
                  {...props.csvProps}
                >
                  Export to CSV
                </ExportCSVButton>
              </span>
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={product}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
      <Modal
        style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
        show={isOpen1}
        onHide={hideModal1}
      >
        <Modal.Header closeButton className="mt-4">
          <Modal.Title>Edit Product </Modal.Title>
        </Modal.Header>

        <div className="ms-2 me-2">
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Product Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setproductname(e.target.value)}
              placeholder={selected1.productname}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">MRP</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setmrp(e.target.value)}
              placeholder={selected1.productprice}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
              Customer Offer Price
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setrop(e.target.value)}
              placeholder={selected1.customerofferPrice}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
              Customer Discount Percentage
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setrdp(e.target.value)}
              placeholder={selected1.customerdiscountpercentage}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
              Product Description
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setdesc(e.target.value)}
              placeholder={selected1.productdesc}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Brand</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setbrand(e.target.value)}
              placeholder={selected1.productbrand}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Feature</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setfeature(e.target.value)}
              placeholder={selected1.productfeatures}
            />
          </Form.Group>
        </div>
        <Modal.Footer>
          <Button variant="success" onClick={hideModal1}>
            Close
          </Button>
          <Button variant="danger" onClick={() => editproduct()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Add Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label className="mt-2">Date</label>
              <DatePicker
                style={{ width: "466px", height: "38px" }}
                format="DD/MM/YYYY"
                type="input-icon"
                minDate={new Date()}
                onChange={(date) => setdate(date.format("DD/MM/YYYY"))}
              />

              <label className="mt-2">Price</label>
              <input
                type="text"
                // placeholder="Enter Amount"
                className="form-control"
                placeholder={selected.productprice}
                onChange={(e) => setbasicprice(e.target.value)}
              ></input>
              <label className="mt-2">Stock</label>
              <input
                type="text"
                placeholder="Enter Quantity"
                className="form-control"
                onChange={(e) => setstock(e.target.value)}
              ></input>
              <label className="mt-2">Free</label>
              <input
                type="text"
                placeholder="Enter Free"
                className="form-control"
                onChange={(e) => setfree(e.target.value)}
              ></input>
              <label className="mt-2">Tax</label>
              <input
                type="text"
                placeholder="Enter Free"
                className="form-control"
                onChange={(e) => settax(e.target.value)}
              ></input>
            </div>
            <br></br>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postcategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Retaileraddedproduct;
