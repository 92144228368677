import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DatePicker from 'react-multi-date-picker';


function Adminpackage() {
    const [data, setdata] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [packagetitle, setpackagetitle] = useState("");
    const [packagedescription, setpackagedescription] = useState("");
    const [packageprice, setpackageprice] = useState("");
    const [validityfrom, setvalidityfrom] = useState(new Date);
    const [validityto, setvalidityto] = useState(new Date);
    const { SearchBar, ClearSearchButton } = Search;
    const { ExportCSVButton } = CSVExport;
    const apiURL = process.env.REACT_APP_API_URL;
    const imageURL = process.env.REACT_APP_IMAGE_API_URL;
    

    const showModal = () => {
      setIsOpen(true);
    };
  
    const hideModal = () => {
      setIsOpen(false);
    };

    useEffect(() => {
        getdisease();                       
    }, []);    
    
    const getdisease = () =>{
        try{
            axios.get(`${apiURL}/getallpackage`)
                        .then(function(response){
                           if (response.status === 200) { 
                             console.log(response.data);
                            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata)); 
                             setdata(response.data.package);                                  
                             return 
                       
                     }else{
                       
                       alert("Can't able to fetch ");
                       //setdatacondition(true);
                       return
                     }
                   }).catch(function(error){
                     // setdatacondition(true);
                     console.log(error.message.data);
                     alert("Can't able to fetch ");
                     return
                   });
                 }catch (error) {
                    
                   console.warn(error);
        alert("Can't able  to fetch ");
       // setdatacondition(true);
        return error;
       };
    }

    const postdisease = async (e) =>{
      e.preventDefault();
      if(!packagetitle | !packagedescription | !packageprice | !validityto | !validityfrom ){
          alert("Please fill all fields")
      }else{
      try{ 
          const config ={ 
              url:"/admin/addpackage",
              method:'post',
              baseURL:apiURL,
              headers:{ 'content-type': 'application/json'},
              data:{ 
                  packagetitle: packagetitle, 
                  packagedescription: packagedescription, 
                  packageprice: packageprice, 
                  validityfrom: validityfrom, 
                  validityto: validityto
                  }
              //responseType:"application/json"
            }
          let res = await axios(config);            
          if(res.status === 200){
              console.log(res.data); 
              window.location.assign("/admin/package");                                        
              return res;                
          }
      }catch(error){
        console.log(error);
      }
  }               
  }

    const remove = async(data) =>{
      try{
          axios.post(`${apiURL}/admin/deletepackage/`+data._id)
                       .then(function(response){
                          if (response.status === 200) { 
                            console.log(response.data);
                            window.location.reload();                
                    }
                  }).catch(function(error){   
                    // setdatacondition(true);
                    console.log(error.message.data);
                    alert("Not able to complete");
                    
                  });
                }catch (error) {
                   
                  console.warn(error);
       alert("Not able to complete");
      // setdatacondition(true);
       return error;
      };
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: data.length
    }] // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row){
    return (<img src={`${imageURL}/patients/profile/${cell}`}  height="50px" width="50px"  style={{borderRadius:"100%"}}/>) ;
  }

 



const columns = [{
    dataField: 'packagetitle',
    text: 'Package Title',
    sort : true,    
  },   
  {
    dataField: 'packagedescription',
    text: 'Package Description',
    sort : true
  }, {  
    dataField: 'packageprice',
    text: 'Package Price',
    
  }, 
  {  
    dataField: 'validityfrom',
    text: 'Validity From',
    
  }, 
  {  
    dataField: 'validityto',
    text: 'Validity To',
    
  }, 
  // {  
  //   dataField: '',
  //   text: 'Access Modules',
  //   formatter: (cell, row) => {
  //     console.log(row);
  //     return <div><Button onClick={()=>{setshow(true); setselected(row)}}>View</Button></div>;
  //   }     
  // },      
  {  
    dataField: '',
    text: 'Action',
    formatter: (cell, row) => {
      console.log(row);
      return <div><button className="btn btn-danger" onClick={()=>remove(row)}>Delete</button></div>;
    }     
  },
  
];
    return (
        <div style={{background:""}}>

<Button variant="success" onClick={showModal} className="mb-2">
        Add Package Plan
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Package Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              {/* <FormGroup>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={countryName}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {data?.map((name) => (
                      <MenuItem key={name._id} value={name?.countryName}>
                        <Checkbox
                          checked={countryName.indexOf(name?.countryName) > -1}
                        />
                        <ListItemText primary={name?.countryName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup> */}
              {/* <br></br> */}
              <div className="form-group">
              <label>Package Title</label>                
                  <input className="form-control" type="text" placeholder="Enter the Package Title" onChange={(e)=>setpackagetitle(e.target.value)}/>
                  <label>Package Description</label>                
                  <input className="form-control" type="text" placeholder="Enter the Package Description" onChange={(e)=>setpackagedescription(e.target.value)}/> 
                  <label>Package Price</label>                
                  <input className="form-control" type="text" placeholder="Enter the Package Price" onChange={(e)=>setpackageprice(e.target.value)}/> 
                  <label>Validity From</label>  
                  <DatePicker
                      style={{width:"325px",height:"38px"}}
                      minDate={new Date()}
                      format="DD/MM/YYYY"                                         
                      type="input-icon"
                      onChange={date=>setvalidityfrom(date.format("DD/MM/YYYY"))}                     
                      />  

                      <label>Validity To</label>  
                  <DatePicker
                      style={{width:"325px",height:"38px"}}
                      minDate={new Date()}
                      format="DD/MM/YYYY"                                         
                      type="input-icon"
                      onChange={date=>setvalidityto(date.format("DD/MM/YYYY"))}                     
                      /> 
                     
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postdisease}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
        <div className="container pb-5">
            <h1 className="pt-4 pb-3" style={{color:"#28328c"}}> Package Plan</h1>
            <div className="container">
                <div className="card card-body">
                      {/* <div>
                        <h5 className="pb-3">Latest Orders</h5>
                         <div className="container text-center">
                         <Table responsive hover size="md">
                            <thead>
                            <tr>                                
                                <th>Package Title</th>
                                <th>Package Description</th>
                                <th>Package Price</th> 
                                <th>Validity From</th> 
                                <th>Validity To</th>
                                <th>Validity Status</th>                                
                                <th>Action</th>                       
                            </tr>
                            </thead>
                            <tbody>
                                {data?.map((datas)=>(
                                  <tr>                                  
                                  <td>{datas.packagetitle}</td>  
                                  <td>{datas.packagedescription}</td>
                                  <td>{datas.packageprice}</td>
                                  <td>{datas.validityfrom}</td>
                                  <td>{datas.validityto}</td> 
                                  <td><button className="btn btn-danger" onClick={()=>remove(datas)}>Delete</button></td>
                                  <td>{datas.diseases}</td>                                                                                                                       
                                  <td><div className="d-flex"> <button className="btn btn-outline-success" onClick={()=>approve(datas)}>Approve</button></div></td>
                              </tr>  
                                ))}
                                
                            </tbody>
                          </Table>
                        </div>
                    </div> */}
                    <ToolkitProvider
        keyField="id"
        data={ data }
        columns={ columns }
        search
        exportCSV = { {
            fileName: 'subadmin.csv',            
            blobType: 'text/csv;charset=ansi'
          } }
        >
            {
         props => (
            <div> 
             <span className="pr-5 mr-auto"> 
            {/* <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton>  */}
            </span>  
            <span className="pl-5 ml-5 mr-5 mt-5">          
            <SearchBar className="form-control " { ...props.searchProps } />
            </span>
            <ClearSearchButton className="btn-outline-info" { ...props.searchProps } />
            
            <hr />
            <div className="table-responsive">
            <BootstrapTable keyField='subadmin' responsive hover columns={ columns } data={ data } pagination={ paginationFactory(options) }  { ...props.baseProps }/>
            </div>
            </div>
            )
        }
            </ToolkitProvider>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Adminpackage
