import React, { useEffect, useState } from "react";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import "../../Components/customer/Styles/style12.css"

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {RxAvatar} from 'react-icons/rx'
import axios from "axios";
import useRazorpay from "react-razorpay";
import moment from "moment";

function DistributorMyWallet() {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [wallet, setwallet] = useState({});
  let distributor = JSON.parse(sessionStorage.getItem("distributor"));

// ===================================================================================================================

  const getWallet = async () => {
    try {
      let res = await axios.get(
        "https://spilso.in/api/distributor/getWalletByDistributorId/" + distributor?._id
      );
      if (res.status == 200) {
        setwallet(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getWallet();
  }, []);

// ===================================================================================================================

  const Razorpay = useRazorpay();
  const [payid, setpayid] = useState("");
  // console.log("wallet it",wallet);
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

// ===================================================================================================================

  const [Amount, setAmount] = useState(0);

  const posttransaction = async () => {
    if (!Amount) {
      return alert("Please enter amount");
    } else {
      try {
        const config = {
          data: {
            key: "rzp_test_5vCRZ6rLM2wGN4",
            amount: Math.round(Amount) * 100,
            currency: "INR",
            name: "Jaaggery",
            description: "Order Amount",
            image: "/Logo-1.jpg",
            distributorId: distributor?._id,
            handler: function (response) {
              alert(response.razorpay_payment_id);
              setpayid(response.razorpay_payment_id);
            },
            prefill: {
              name: distributor?.partnersname,
              email: distributor?.email,
              contact: distributor?.permanentno,
            },
          },
        };
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          alert("Fail to load");
          return;
        }
        const paymentObject = new Razorpay(config.data);
        paymentObject.open();
      } catch (error) {
        console.log(error);
      }
    }
  };

// ===================================================================================================================

  const addTransaction = async () => {
    try {
      const config = {
        url: "/distributor/addTransaction",
        method: "put",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: {
          distributorId: distributor?._id,
          heading: "Added to Wallet",
          payId: payid,
          amount: Amount,
          status: "CR",
          paymentStatus: "Success",
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (payid) {
      addTransaction();
    }
  }, [payid]);

  return (
    <>
      <section class="latest-product spad">
        <div class="container">
          <div class="section-title">
            <h2>My Wallet</h2>
          </div>
          <div className="my-wal_0">
            <div className="c_w_0">
              <div className="row">
                <div className="col-md-6">
                  <div className="ba_l_img">
                    <img
                      src="/img/featured/wallet.avif"
                      alt="wallet"
                      style={{ width: "100%", height: "401px" }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ba_l_0">
                    <div className="mo_0">
                      <div
                        className="mb-1"
                        onClick={handleShow1}
                        style={{
                          cursor: "pointer",
                          fontWeight: "700",
                          fontSize: "20px",
                          textAlign:"end"
                        }}
                      >
                        <span>
                          <MdOutlineAccountBalanceWallet />
                        </span>
                        Add Money
                      </div>
                      <h2>
                        ₹{wallet.totalAmount}.00 <br />
                        <span style={{fontSize:"20px"}}>Wallet Balance</span>
                      </h2>
                    
                    </div>
                    <div
                      className=""
                      style={{ padding: "23px", fontWeight: "600" }}
                    >
                      <div className="tran">Transaction History</div>
                      <ul>
                      {wallet?.transaction?.map((ele) => {
                          return (
                        <div className="row mb-3">
                            <div className="col-md-1">
                            <div><MdOutlineAccountBalanceWallet style={{fontSize:"40px"}}/> </div>
                            </div>
                            <div className="col-md-11">
                            <div className="P_J_w" >
                          <span >
                          {ele?.heading}
                          </span>{" "}
                          <br />
                          <div className="walls">
                            <div style={{ fontSize: "13px" }}>{moment(ele?.date).format("LLL")}<br />
                                      PayId :-{" "}
                                      <span style={{ fontSize: "13px" }}>
                                        {ele?.payId}
                                      </span>
                            </div>
                            {ele?.status === "CR" ? (
                                      <div style={{ color: "green" }}>
                                        + ₹{ele?.amount}.00
                                      </div>
                                    ) : (
                                      <div style={{ color: "red" }}>
                                        - ₹{ele?.amount}.00
                                      </div>
                                    )}
                          </div>
                          </div>
                            </div>
                        </div>
                        );
                        })}
                        {/* <div className="row mb-3">
                            <div className="col-md-1">
                            <div> <RxAvatar style={{fontSize:"40px"}}/></div>
                            </div>
                            <div className="col-md-11">
                            <div className="P_J_w">
                          <span >
                          Order Id
                          </span>{" "}
                          <br />
                          <div className="walls">
                            <div>4:00pm 21March2023</div>
                            <div style={{ color: "red" }}>- ₹300:00</div>
                          </div>
                          </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-1">
                            <div> <RxAvatar style={{fontSize:"40px"}}/></div>
                            </div>
                            <div className="col-md-11">
                            <div className="P_J_w">
                          <span >
                          Jaaggery Rewards
                          </span>{" "}
                          <br />
                          <div className="walls">
                            <div>4:00pm 21March2023</div>
                            <div style={{ color: "green" }}>+ ₹300:00</div>
                          </div>
                          </div>
                            </div>
                        </div>
                       
                        <div className="row mb-3">
                            <div className="col-md-1">
                            <div> <RxAvatar style={{fontSize:"40px"}}/></div>
                            </div>
                            <div className="col-md-11">
                            <div className="P_J_w">
                          <span >
                          Jaaggery Refunds
                          </span>{" "}
                          <br />
                          <div className="walls">
                            <div>4:00pm 21March2023</div>
                            <div style={{ color: "green" }}>+ ₹300:00</div>
                          </div>
                          </div>
                            </div>
                        </div> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Add Money</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pro_file">
            <form>
              <div class="mb-2 a-section a-spacing-base adddress-ui-widgets-form-field-container">
                <label>Enter Amount</label>
                <input
                  type="text"
                  className="address-ui-widgets"
                  maxlength="50"
                  name="address-ui-widgets-enterAddressFullName"
                  value={Amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={posttransaction}>
            Add
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DistributorMyWallet;
