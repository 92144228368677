import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-multi-date-picker";
import { SentimentVerySatisfiedTwoTone } from "@material-ui/icons";

function Adminbulkinvoice() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [product, setproduct] = useState([]);
  const [category, setcategory] = useState("");
  const [allexpense, setallexpense] = useState([]);
  const [creditdebitnote, setcreditdebitnote] = useState("");
  const [usertype, setusertype] = useState("");
  const [username, setusername] = useState("");
  const [date, setdate] = useState(new Date());
  const [amount, setamount] = useState("");
  const [expenseremark, setexpenseremark] = useState("");
  const [categoryimage, setcategoryimage] = useState();
  const [categorydata, setcategorydata] = useState([]);
  const [creditdebitnote1, setcreditdebitnote1] = useState("");
  const [usertype1, setusertype1] = useState("");
  const [amount1, setamount1] = useState("");
  const [expenseremark1, setexpenseremark1] = useState("");
  const [creditdebitnote2, setcreditdebitnote2] = useState("");
  const [usertype2, setusertype2] = useState("");
  const [amount2, setamount2] = useState("");
  const [expenseremark2, setexpenseremark2] = useState("");
  const [creditdebitnote3, setcreditdebitnote3] = useState("");
  const [usertype3, setusertype3] = useState("");
  const [amount3, setamount3] = useState("");
  const [expenseremark3, setexpenseremark3] = useState("");
  const [creditdebitnote4, setcreditdebitnote4] = useState("");
  const [usertype4, setusertype4] = useState("");
  const [amount4, setamount4] = useState("");
  const [expenseremark4, setexpenseremark4] = useState("");
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [i, seti] = useState(1);
  const [free, setfree] = useState("");

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // getcountry();
    getcategory();

    if (usertype == "customer") {
      getcustomer();
    }
    if (usertype == "retailer") {
      getretailer();
    }
    if (usertype == "distributor") {
      getdistributor();
    }
    if (usertype == "corporate") {
      getcorporate();
    }
    if (usertype == "keyaccount") {
      getkeyaccount();
    }
    if (usertype == "employee") {
      getemployee();
    }
    if (usertype == "vendor") {
      getvendor();
    }
    getproduct();
  }, [usertype]);

  const getproduct = async () => {
    let res = await axios.get(apiURL + "/admin/product");
    if ((res.status = 200)) {
      console.log(res);
      setproduct(res.data?.products);
    }
  };

  const postcategory = async (e) => {
    e.preventDefault();
    // formdata.append("adminId", user.id);
    // formdata.append("vendorId", category);
    // formdata.append("productId", creditdebitnote);
    // formdata.append("quantity", expenseremark);
    // formdata.append("date", date);
    // formdata.append("price", amount);
    // formdata.append("free", usertype)
    try {
      const config = {
        url: "/admin/addbulkinvoice",
        method: "post",
        baseURL: apiURL,
        // data: formdata,
        headers: { "content-": "application/json" },
        data: {
          adminId: user.id,
          usertype: usertype,
          username: category,
          date: date,
          product: [
            {
              productId: creditdebitnote,
              quantity: expenseremark,
              price: amount,
              free: free,
            },
            i == 2
              ? {
                  productId: creditdebitnote1,
                  quantity: expenseremark1,
                  price: amount1,
                  free: usertype1,
                }
              : "",
          ],
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("bulkinvoice added");
          window.location.assign("/admin/bulkinvoice");
        }
      });
    } catch (error) {
      console.error(error);
      alert("category Name Not Added");
    }
  };

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getallbulkinvoice");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.bulkinvoice);
    }
  };

  const getcustomer = async () => {
    let res = await axios.get(apiURL + "/customer/getallcustomer");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.customer);
    }
  };

  const getdistributor = async () => {
    let res = await axios.get(apiURL + "/distributor/getalldistributor");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.distributor);
    }
  };

  const getretailer = async () => {
    let res = await axios.get(apiURL + "/retailer/getallretailer");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.retailer);
    }
  };

  const getcorporate = async () => {
    let res = await axios.get(apiURL + "/corporate/getallcorporate");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.corporate);
    }
  };

  const getemployee = async () => {
    let res = await axios.get(apiURL + "/employee/getallemployee");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.employee);
    }
  };

  const getkeyaccount = async () => {
    let res = await axios.get(apiURL + "/keyaccount/getallkeyaccount");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.keyaccount);
    }
  };

  const getvendor = async () => {
    let res = await axios.get(apiURL + "/vendor/getallvendor");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.vendor);
    }
  };

  // const handleChange = (event) => {
  //   setCountryName(event.target.value);
  // };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const remove = async (data) => {
    try {
      axios
        .post(`${apiURL}/admin/deletebulkinvoice/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: categorydata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/creditdebitnote/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "_id",
      text: "Sl No",
      // formatter: imageFormatter
    },
    {
      dataField: "_id",
      text: "Order Id",
      // formatter: imageFormatter
    },
    {
      dataField: "username",
      text: "User Name",
      // formatter: imageFormatter
    },
    {
      dataField: "usertype",
      text: "User Type",
      // formatter: imageFormatter
    },
    {
      dataField: "date",
      text: "Date",
      // formatter: imageFormatter
    },
    // {
    //   dataField: 'expanseremark',
    //   text: 'Expanse Remark',
    //   // formatter: imageFormatter
    // },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-info">View</button>
          </div>
        );
      },
    },
  ];

  console.log(i);

  return (
    <div className="mt-3">
      <Button variant="success" onClick={showModal} className="mb-2">
        Create Bulk Invoice
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Bulk Invoice </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <FormGroup>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Select Country
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={countryName}
                      onChange={handleChange}
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {data?.map((name) => (
                        <MenuItem key={name._id} value={name?.countryName}>
                          <Checkbox
                            checked={countryName.indexOf(name?.countryName) > -1}
                          />
                          <ListItemText primary={name?.countryName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup> */}
            {/* <br></br> */}
            <div className="form-group">
              {/* <label> creditdebitnote Image</label>
                  <input
                    type="file"
                    placeholder="Enter Category Name"
                    className="form-control"
                    onChange={(e) => setcategoryimage(e.target.files[0])}
                  ></input>               */}
              <label className="mt-2">User Type</label>
              <select
                className="form-control"
                onChange={(e) => setusertype(e.target.value)}
              >
                <option>Select the user type</option>
                <option value="">Select User Type</option>
                <option value="distributor">Distributor</option>
                <option value="retailer">Retailer</option>
                <option value="customer">Customer</option>

                <option value="transporter">Transporter</option>
              </select>
              <label className="mt-2">User Name</label>
              <select
                className="form-control"
                onChange={(e) => setcategory(e.target.value)}
              >
                <option>Select the user name</option>
                {usertype == "distributor" ||
                usertype == "retailer" ||
                usertype == "vendor"
                  ? allexpense?.map((data) => (
                      <option value={data._id}>{data.firmname}</option>
                    ))
                  : ""}
                {usertype == "customer"
                  ? allexpense?.map((data) => (
                      <option value={data._id}>{data.name}</option>
                    ))
                  : ""}
                {usertype == "corporate" || usertype == "keyaccount"
                  ? allexpense?.map((data) => (
                      <option value={data._id}>{data.ownername}</option>
                    ))
                  : ""}
                {usertype == "employee"
                  ? allexpense?.map((data) => (
                      <option value={data._id}>{data.employeename}</option>
                    ))
                  : ""}
              </select>

              <label className="mt-2">Date</label>
              <DatePicker
                style={{ width: "466px", height: "38px" }}
                format="DD/MM/YYYY"
                type="input-icon"
                minDate={new Date()}
                onChange={(date) => setdate(date.format("DD/MM/YYYY"))}
              />
              {/* <input
                    type="date"
                    placeholder="Enter Date"
                    className="form-control"
                    onChange={(e) => setdate(e.target.value)}
                  ></input> */}
              <label>Product Name</label>
              <select
                class="form-control"
                name="creditdebittype"
                required=""
                onChange={(e) => setcreditdebitnote(e.target.value)}
              >
                <option value="">Select Product Name</option>
                {product.map((data) => (
                  <option value={data._id}>{data.productname}</option>
                ))}
              </select>
              <label className="mt-2">Price</label>
              <input
                type="text"
                placeholder="Enter Amount"
                className="form-control"
                onChange={(e) => setamount(e.target.value)}
              ></input>
              <label className="mt-2">Quantity</label>
              <input
                type="text"
                placeholder="Enter Quantity"
                className="form-control"
                onChange={(e) => setexpenseremark(e.target.value)}
              ></input>
              <label className="mt-2">Free</label>
              <input
                type="text"
                placeholder="Enter Free"
                className="form-control"
                onChange={(e) => setfree(e.target.value)}
              ></input>
              {i === 2 ? (
                <>
                  {" "}
                  <label>Product Name</label>
                  <select
                    class="form-control"
                    name="creditdebittype"
                    required=""
                    onChange={(e) => setcreditdebitnote1(e.target.value)}
                  >
                    <option value="">Select Product Name</option>
                    {product.map((data) => (
                      <option value={data._id}>{data.productname}</option>
                    ))}
                  </select>
                  <label className="mt-2">Price</label>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    className="form-control"
                    onChange={(e) => setamount1(e.target.value)}
                  ></input>
                  <label className="mt-2">Quantity</label>
                  <input
                    type="text"
                    placeholder="Enter Quantity"
                    className="form-control"
                    onChange={(e) => setexpenseremark1(e.target.value)}
                  ></input>
                  <label className="mt-2">Free</label>
                  <input
                    type="text"
                    placeholder="Enter Free"
                    className="form-control"
                    onChange={(e) => setusertype1(e.target.value)}
                  ></input>{" "}
                </>
              ) : (
                ""
              )}
              {i === 3 ? (
                <>
                  {" "}
                  <label>Product Name</label>
                  <select
                    class="form-control"
                    name="creditdebittype"
                    required=""
                    onChange={(e) => setcreditdebitnote2(e.target.value)}
                  >
                    <option value="">Select Product Name</option>
                    {product.map((data) => (
                      <option value={data._id}>{data.productname}</option>
                    ))}
                  </select>
                  <label className="mt-2">Price</label>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    className="form-control"
                    onChange={(e) => setamount2(e.target.value)}
                  ></input>
                  <label className="mt-2">Quantity</label>
                  <input
                    type="text"
                    placeholder="Enter Quantity"
                    className="form-control"
                    onChange={(e) => setexpenseremark2(e.target.value)}
                  ></input>
                  <label className="mt-2">Free</label>
                  <input
                    type="text"
                    placeholder="Enter Free"
                    className="form-control"
                    onChange={(e) => setusertype2(e.target.value)}
                  ></input>{" "}
                </>
              ) : (
                ""
              )}
              {i === 4 ? (
                <>
                  {" "}
                  <label>Product Name</label>
                  <select
                    class="form-control"
                    name="creditdebittype"
                    required=""
                    onChange={(e) => setcreditdebitnote3(e.target.value)}
                  >
                    <option value="">Select Product Name</option>
                    {product.map((data) => (
                      <option value={data._id}>{data.productname}</option>
                    ))}
                  </select>
                  <label className="mt-2">Price</label>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    className="form-control"
                    onChange={(e) => setamount3(e.target.value)}
                  ></input>
                  <label className="mt-2">Quantity</label>
                  <input
                    type="text"
                    placeholder="Enter Quantity"
                    className="form-control"
                    onChange={(e) => setexpenseremark3(e.target.value)}
                  ></input>
                  <label className="mt-2">Free</label>
                  <input
                    type="text"
                    placeholder="Enter Free"
                    className="form-control"
                    onChange={(e) => setusertype3(e.target.value)}
                  ></input>{" "}
                </>
              ) : (
                ""
              )}
              <button
                className="btn btn-success mt-2"
                onClick={() => seti(i + 1)}
              >
                Add Row
              </button>
              <button
                className="btn btn-danger mt-2 ms-2"
                onClick={() => seti(i - 1)}
              >
                Delete Row
              </button>
            </div>
            <br></br>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postcategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        {/* <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Sl. No</th>
                <th> Country Name</th>
                <th> Category Name</th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {categorydata?.map((category) => (
                <tr>
                  <td>{i++}</td>
                  <td>
                    {category.countryName?.map((category) => (
                      <span>{category},</span>
                    ))}
                  </td>
                  <td>{category.categoryName}</td>
                  <td>
                    <button
                      className="btn btn-success mr-5"
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => postdeleteamount(amount)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
        <ToolkitProvider
          keyField="id"
          data={categorydata}
          columns={columns}
          search
          exportCSV={{
            fileName: "patient.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              {/* <span className="pr-5 mr-auto"> 
              <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
              </span>   */}
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={categorydata}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Adminbulkinvoice;
