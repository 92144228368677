import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

function Retailerbanner() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [category, setcategory] = useState("");
  const [categoryimage, setcategoryimage] = useState();
  const [categorydata, setcategorydata] = useState([]);
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // getcountry();
    getcategory();
  }, []);

  // const getcountry = async () => {
  //   let res = await axios.get(apiurl + "/admin/getcountry");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setData(res.data.country);
  //   }
  // };

  const postcategory = async (e) => {
    e.preventDefault();
    if(!categoryimage){
      return alert("Please select image")
    }
    formdata.append("type", "retailerHome");
    formdata.append("image", categoryimage);
    try {
      const config = {
        url: "/admin/addbanerImage",
        method: "post",
        baseURL: apiURL,
        data: formdata,
        // headers: { "content-type": "application/json" },
        // data: { categoryName: category },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("Successfull added banner");
          getcategory()
          hideModal()
        }
      });
    } catch (error) {
      console.error(error);
      alert("category Name Not Added");
    }
  };

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getbanerImage/retailerHome");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.success);
    }
  };

  // const handleChange = (event) => {
  //   setCountryName(event.target.value);
  // };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  var i = 1;
  const remove = async (data) => {
    try {
      axios
        .delete(`${apiURL}/admin/deletebanerImage/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: categorydata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/bannerImage/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      text: "",
    },
    {
      dataField: "image",
      text: "Banner Image",
      formatter: imageFormatter,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-danger" onClick={() => remove(row)}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-3">
      <Button variant="success" onClick={showModal} className="mb-2">
        Add Banner
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
             
              <div className="form-group">
              <label htmlFor="upload1">Image</label>
                <input
                  type="file"
                  placeholder="Banner Image"
                  className="form-control"
                  id="upload1"
                  name="file"
                  accept="image/*"
                  onChange={(e)=>setcategoryimage(e.target.files[0])}
                ></input>
                
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger"   onClick={postcategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        {/* <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Sl. No</th>
              <th> Country Name</th>
              <th> Category Name</th>
              <th> Action</th>
            </tr>
          </thead>
          <tbody>
            {categorydata?.map((category) => (
              <tr>
                <td>{i++}</td>
                <td>
                  {category.countryName?.map((category) => (
                    <span>{category},</span>
                  ))}
                </td>
                <td>{category.categoryName}</td>
                <td>
                  <button
                    className="btn btn-success mr-5"
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => postdeleteamount(amount)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        <ToolkitProvider
          keyField="id"
          data={categorydata}
          columns={columns}
          search
          exportCSV={{
            fileName: "patient.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              {/* <span className="pr-5 mr-auto"> 
            <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
            </span>   */}
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={categorydata}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Retailerbanner;
