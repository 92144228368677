import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup } from "@material-ui/core";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DatePicker from "react-multi-date-picker";


function Adminexpense() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);  
  const [category, setcategory] = useState("");
  const [allexpense, setallexpense] = useState([]);
  const [expensedate, setexpensedate] = useState(new Date());
  const [expenseamount, setexpenseamount] = useState('');
  const [expenseremark, setexpenseremark] = useState('');
  const [categoryimage, setcategoryimage] = useState();
  const [categorydata, setcategorydata] = useState([]);
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [data3, setData3] = useState({});
  
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };

  useEffect(() => {
    // getcountry();
    getcategory();
    getcategory1();
  }, []);

  // const getcountry = async () => {
  //   let res = await axios.get(apiurl + "/admin/getcountry");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setData(res.data.country);
  //   }
  // };

  const postcategory = async (e) => {
    e.preventDefault();
    // formdata.append("expensedate", expensedate);
    // formdata.append("expensetype", category);
    // formdata.append("expenseamount", expenseamount);
    // formdata.append("expenseremark", expenseremark);
    try {
      const config = {
        url: "/admin/addexpense",
        method: "post",
        baseURL: apiURL,
        // data: formdata,
        headers: { "content-": "application/json" },
        data: {
           expensedate: expensedate,
           expensetype: category,
           expenseamount: expenseamount, 
           expenseremark: expenseremark 
         },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("expense added");
          getcategory();
          hideModal();
        }
      });
    } catch (error) {
      console.error(error);
      alert(error.response.data.error);
    }
  };

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getallexpense");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.expense);
    }
  };

  const getcategory1 = async () => {
    let res = await axios.get(apiURL + "/getallexpensetype");
    if ((res.status = 200)) {
      setallexpense(res.data?.expensetype);
    }
  };

  // const handleChange = (event) => {
  //   setCountryName(event.target.value);
  // };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  var i = 1;
  const remove = async() =>{
    try{
        axios.post(`${apiURL}/admin/deleteexpense/`+data3._id)
                     .then(function(response){
                        if (response.status === 200) { 
                          alert("Successfully deleted")
                          hideModal1()
                            getcategory()  
                  }
                }).catch(function(error){   
                  // setdatacondition(true);
                  console.log(error.message.data);
                  alert("Not able to complete");
                  
                });
              }catch (error) {
                 
                console.warn(error);
     alert("Not able to complete");
    // setdatacondition(true);
     return error;
    };
}

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: categorydata.length
    }] // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row){
    return (<img src={`${imageURL}/expense/${cell}`}  height="50px" width="50px"  style={{borderRadius:"100%"}}/>) ;
  }

 



const columns = [ 
  {  
    dataField: 'expensetype',
    text: 'Expense Type',
    // formatter: imageFormatter
  },  
  {  
    dataField: 'expensedate',
    text: 'Expense Date',
    // formatter: imageFormatter
  },
  {  
    dataField: 'expenseamount',
    text: 'Expense Amount',
    // formatter: imageFormatter
  },
  {  
    dataField: 'expenseremark',
    text: 'Expense Remark',
    // formatter: imageFormatter
  },   
  {  
    dataField: '',
    text: 'Action',
    formatter: (cell, row) => {
      console.log(row);
      return <div><button className="btn btn-danger"  onClick={()=>{
        showModal1()
        setData3(row)
      }}>Delete</button></div>;
    }     
  },
  
];

  return (
    <div className="mt-3">
      <Button variant="success" onClick={showModal} className="mb-2">
        Add Expense 
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Expense </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
           
              <div className="form-group">
              {/* <label> expense Image</label>
                <input
                  type="file"
                  placeholder="Enter Category Name"
                  className="form-control"
                  onChange={(e) => setcategoryimage(e.target.files[0])}
                ></input>               */}
                <label className="mt-2">Expense Type</label>
                <select                  
                  className="form-control"
                  onChange={(e) => setcategory(e.target.value)}
                >
                  <option>Select the expense type</option>
                  {allexpense?.map((data)=>(
                    <option value={data.expensetype}>{data.expensetype}</option>
                  ))}
                </select>
                <label className="mt-2">Expense Date</label><br/>
                <DatePicker
                      // style={{width:"325px",height:"38px"}}                      
                      format="DD/MM/YYYY"                                         
                      type="input-icon"
                    
                      minDate={new Date()}
                      onChange={date=>setexpensedate(date.format("DD/MM/YYYY"))}                     
                      /> <br/>
                {/* <input
                  type="date"
                  placeholder="Enter Expense Type"
                  className="form-control"
                  onChange={(e) => setexpensedate(e.target.value)}
                ></input> */}
                <label className="mt-2">Expense Amount</label>
                <input
                  type="text"
                  placeholder="Enter Expense Amount"
                  className="form-control"
                  onChange={(e) => setexpenseamount(e.target.value)}
                ></input>
                <label className="mt-2">Expense Remark</label>
                <input
                  type="text"
                  placeholder="Enter Expense Remark"
                  className="form-control"
                  onChange={(e) => setexpenseremark(e.target.value)}
                ></input>
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postcategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        {/* deleteMode */}
        <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Are you sure delete !</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Cancel
            </Button>
            <Button
              variant="danger"
              style={{ cursor: "pointer" }}
              onClick={() => remove()}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <ToolkitProvider
        keyField="id"
        data={ categorydata }
        columns={ columns }
        search
        exportCSV = { {
            fileName: 'patient.csv',            
            blobType: 'text/csv;charset=ansi'
          } }
        >
            {
         props => (
            <div className="mt-5"> 
             {/* <span className="pr-5 mr-auto"> 
            <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
            </span>   */}
            <span className="pl-5 ms-5 me-5 mt-5">          
            <SearchBar className="form-control " { ...props.searchProps } />
            </span>
            <ClearSearchButton className="btn-outline-info" { ...props.searchProps } />
            
            <hr />
            <div className="table-responsive">
            <BootstrapTable keyField='patient' responsive hover columns={ columns } data={ categorydata } pagination={ paginationFactory(options) }  { ...props.baseProps }/>
            </div>
            </div>
            )
        }
            </ToolkitProvider>
      </div>
    </div>
  );
}

export default Adminexpense;
