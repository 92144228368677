import axios from "axios";
import React, { useEffect, useState } from "react";

import { Button, Modal, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-multi-date-picker";

function Adminreferalcode() {
  const [data, setdata] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [promocode, setpromocode] = useState("");

  const [description, setdescription] = useState("");
  const [startingdate, setstartingdate] = useState(new Date());
  const [discountpercentage, setdiscountpercentage] = useState(0);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    getdisease();
  }, []);

  const getdisease = () => {
    try {
      axios
        .get(`${apiURL}/getallreferalcode`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.referalcode);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const postdisease = async (e) => {
    e.preventDefault();

    if (!promocode | !description | !discountpercentage) {
      alert("Please fill all fields");
    } else {
      try {
        const config = {
          url: "/admin/addreferalcode",
          method: "post",
          baseURL: apiURL,
          headers: { "content-type": "application/json" },
          data: {
            referalcode: promocode,
            description: description,
            // startingdate: startingdate,
            discountpercentage: discountpercentage,
          },
          //responseType:"application/json"
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          window.location.assign("/admin/referalcode");
          return res;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const remove = async (data) => {
    try {
      axios
        .post(`${apiURL}/admin/deletereferalcode/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/patients/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      text: "Sl No",
    },
    {
      // dataField: "referalcode",
      text: "Referal Code",
      sort: true,
    },
    // {
    //   dataField: "validity",
    //   text: "Validity",
    //   sort: true,
    // },
    {
      // dataField: "description",
      text: "Description",
    },
    // {
    //   dataField: "startingdate",
    //   text: "Starting Date",
    // },
    {
      // dataField: "discountpercentage",
      text: "Discount Percentage",
    },
    // {
    //   dataField: '',
    //   text: 'Access Modules',
    //   formatter: (cell, row) => {
    //     console.log(row);
    //     return <div><Button onClick={()=>{setshow(true); setselected(row)}}>View</Button></div>;
    //   }
    // },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-danger" onClick={() => remove(row)}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ background: "" }}>
      <Button variant="success" onClick={showModal} className="mb-2">
        Add Referal Code
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Referal Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              <div className="form-group">
                <label>Referal Code</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter the Referal Code"
                  onChange={(e) => setpromocode(e.target.value)}
                />

                <label>Description</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter the Description"
                  onChange={(e) => setdescription(e.target.value)}
                />
                {/* <label>Starting Date</label>
                <DatePicker
                  style={{ width: "325px", height: "38px" }}
                  format="DD/MM/YYYY"
                  type="input-icon"
                  minDate={new Date()}
                  onChange={(date) =>
                    setstartingdate(date.format("DD/MM/YYYY"))
                  }
                /> */}
                <label>Discount Percentage</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Enter the Discount Percentage"
                  onChange={(e) => setdiscountpercentage(e.target.value)}
                />
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postdisease}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Referal Code
        </h1>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "subadmin.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    {/* <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton>  */}
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="subadmin"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminreferalcode;
