import axios from "axios";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function Addsubadmin() {
  const apiURL = process.env.REACT_APP_API_URL;
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [password, setpassword] = useState("");
  const [subadmin, setsubadmin] = useState(false);
  const [customergraphics, setcustomergraphics] = useState(false);
  const [retailergraphics, setretailergraphics] = useState(false);
  const [distributorgraphics, setdistributorgraphics] = useState(false);
  const [customer, setcustomer] = useState(false);
  const [stock, setstock] = useState(false);
  const [distributor, setdistributor] = useState(false);
  const [retailer, setretailer] = useState(false);
  const [keyacccount, setkeyacccount] = useState(false);
  const [corporate, setcorporate] = useState(false);
  const [employee, setemployee] = useState(false);
  const [transporter, settransporter] = useState(false);
  const [wallet, setwallet] = useState(false);
   const [destributretailorder, setdestributretailorder] = useState(false);
    const [de, setde] = useState(false);
  const [me, setme] = useState(false);
  const [customerorder, setcustomerorder] = useState(false);
  const [distributororder, setdistributororder] = useState(false);
  const [retailerorder, setretailerorder] = useState(false);
  const [keyacccountorder, setkeyacccountorder] = useState(false);
  const [corporateorder, setcorporateorder] = useState(false);
  const [employeeorder, setemployeeorder] = useState(false);
  const [localshoporder, setlocalshoporder] = useState(false);
  const [distributorretailerorder, setdistributorretailerorder] =
    useState(false);
  const [returnorder, setreturnorder] = useState(false);
  const [expense, setexpense] = useState(false);
  const [creditdebitnote, setcreditdebitnote] = useState(false);
  const [promocode, setpromocode] = useState(false);
  const [referalcode, setreferalcode] = useState(false);
  const [package1, setpackage1] = useState(false);
    const [topreatailer, settopreatailer] = useState(false);
     const [topdistributor, settopdistributor] = useState(false);
  const [product, setproduct] = useState(false);
  const [retailerproduct, setretailerproduct] = useState(false);
  const [vendorproduct, setvendorproduct] = useState(false);
  const [distributorproduct, setdistributorproduct] = useState(false);
  const [purchaseorders, setpurchaseorders] = useState(false);
  const [pincodeblock, setpincodeblock] = useState(false);
  const [transactions, settransactions] = useState(false);
  const [vouchercode, setvouchercode] = useState(false);

  const postsubadmin = async (e) => {
    e.preventDefault();

    if (!name | !email | !password | !phonenumber) {
      alert("Please Fill all fields");
    } else {
      try {
        const config = {
          url: "/admin/addsubadmin",
          method: "post",
          baseURL: apiURL,
          headers: { "content-type": "application/json" },
          data: {
            name: name,
            email: email,
            password: password,
            phoneNumber: phonenumber,
            subadmin: subadmin,
            customergraphics: customergraphics,
            retailergraphics: retailergraphics,
            distributorgraphics: distributorgraphics,
            transporter: transporter,
            customer: customer,
            distributor: distributor,
            retailer: retailer,
            keyacccount: keyacccount,
            corporate: corporate,
            employee: employee,
            de: de,
            me: me,
            customerorder: customerorder,
            distributororder: distributororder,
            retailerorder: retailerorder,
            keyacccountorder: keyacccountorder,
            corporateorder: corporateorder,
            employeeorder: employeeorder,
            localshoporder: localshoporder,
            distributorretailerorder: distributorretailerorder,
            returnorder: returnorder,
            expense: expense,
            creditdebitnote: creditdebitnote,
            promocode: promocode,
            referalcode: referalcode,
            packagenew: package1,
            product: product,
            retailerproduct: retailerproduct,
            vendorproduct: vendorproduct,
            distributorproduct: distributorproduct,
            purchaseorders: purchaseorders,
            pincodeblock: pincodeblock,
            transactions: transactions,
            vouchercode: vouchercode,
          },
          //responseType:"application/json"
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          window.location.assign("/admin/subadmin");
          return res;
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  return (
    <div className="container pt-5 pb-3">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5"> Add Sub-Admin</h5>
        <Card.Body>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <label>Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter the Name"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
              <label>Email</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter the Email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
              <label>Mobile Number</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter the Mobile Number"
                value={phonenumber}
                onChange={(e) => setphonenumber(e.target.value)}
              />
              <label>Password</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter the Password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              />
              <label>Access Modules</label>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={subadmin}
                      onChange={() => setsubadmin(!subadmin)}
                    />
                  }
                  label="Sub-Admin"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={customergraphics}
                    onChange={() => setcustomergraphics(!customergraphics)}
                    />
                  }
                  label="Customer Graphics"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={retailergraphics}
                    onChange={() => setretailergraphics(!retailergraphics)}
                    />
                  }
                  label="Retailer Graphics"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={distributorgraphics}
                    onChange={() => setdistributorgraphics(!distributorgraphics)}
                    />
                  }
                  label="Distributor Graphics"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={stock}
                    onChange={() => setstock(!stock)}
                    />
                  }
                  label="Stocks"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer}
                      onChange={() => setcustomer(!customer)}
                    />
                  }
                  label="Customer"
                /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={distributor}
                    onChange={() => setdistributor(!distributor)}
                    />
                  }
                  label="Distributor"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={retailer}
                    onChange={() => setretailer(!retailer)}
                    />
                  }
                  label="Retailer"
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={customerorder}
                    onChange={() => setcustomerorder(!customerorder)}
                    />
                  }
                  label="Customer Orders"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={distributororder}
                    onChange={() => setdistributororder(!distributororder)}
                    />
                  }
                  label="Distributor Orders"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={retailerorder}
                    onChange={() => setretailerorder(!retailerorder)}
                    />
                  }
                  label="Retailer Orders"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                    checked={wallet}
                    onChange={() => setwallet(!wallet)}
                    />
                  }
                  label="Wallet"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={destributretailorder}
                    onChange={() => setdestributretailorder(!destributretailorder)}
                    />
                  }
                  label="Distributor Retailer Order"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={returnorder}
                    onChange={() => setreturnorder(!returnorder)}
                    />
                  }
                  label="Return Order"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={transactions}
                    onChange={() => settransactions(!transactions)}
                    />
                  }
                  label="Transaction"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={vouchercode}
                      onChange={() => setvouchercode(!vouchercode)}
                    />
                  }
                  label="Voucher code"
                /> */}
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={expense}
                    onChange={() => setexpense(!expense)}
                    />
                  }
                  label="Expense"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={creditdebitnote}
                    onChange={() => setcreditdebitnote(!creditdebitnote)}
                    />
                  }
                  label="Payment/Credit/Debit Note"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={promocode}
                    onChange={() => setpromocode(!promocode)}
                    />
                  }
                  label="Promo Code"
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                    checked={referalcode}
                    onChange={() => setreferalcode(!referalcode)}
                    />
                  }
                  label="Referal Code"
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                    checked={topreatailer}
                    onChange={() => settopreatailer(!topreatailer)}
                    />
                  }
                  label="Top Retailer"
                />
                   <FormControlLabel
                  control={
                    <Checkbox 
                    checked={topdistributor}
                    onChange={() => settopdistributor(!topdistributor)}
                     />
                  }
                  label="Top Distributor"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={product}
                    onChange={() => setproduct(!product)}
                    />
                  }
                  label="Products"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={purchaseorders}
                      onChange={() => setpurchaseorders(!purchaseorders)}
                    />
                  }
                  label="Purchase Orders"
                /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={pincodeblock}
                    onChange={() => setpincodeblock(!pincodeblock)}
                    />
                  }
                  label="Pincode Block"
                />
              </FormGroup>
            </div>
          </div>

          <button className="btn btn-primary mt-3" onClick={(e)=>postsubadmin(e)}>
            Add Subadmin
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addsubadmin;
