import React from "react"
import Routes from "./Components";
import "./App.css"
import 'react-multi-carousel/lib/styles.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
