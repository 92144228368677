import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function DistibutorLogin() {
  const [disLo, setdisLo] = useState("");
  const [employer, setemployer] = useState("");

  // distributor-login
  const [distributoremail, setdistributoremail] = useState("");
  const [distributorpwd, setdistributorpwd] = useState("");

  const distributorLogin = async () => {
    try {
      const config = {
        url: "/distributor/signin",
        method: "post",
        baseURL: "https://spilso.in/api",
        header: { "content-type": "application/json" },
        data: {
          email: distributoremail,
          password: distributorpwd,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("login successful");
          sessionStorage.setItem("distributor", JSON.stringify(res.data.user));
          window.location.assign("/distributor/distributor-home");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <div
        className="hero"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="row"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        >
          <div className="col-md-6 p-0">
            <div className="">
              <img
                src="/img/featured/d-login.avif"
                alt=""
                style={{
                  width: "100%",
                  height: "300px",
                  borderTopLeftRadius: "10px",
                }}
              />
            </div>
          </div>
          <div
            className="col-md-6 p-0"
            style={{ backgroundColor: "#ed1c23", borderTopRightRadius: "10px" }}
          >
            <div className="">
              <ul style={{ display: "flex", justifyContent: "space-around" }}>
                <li
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    setdisLo(true);
                    setemployer(false);
                  }}
                >
                  Distributor Login
                </li>
                <li
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    setdisLo(false);
                    setemployer(true);
                  }}
                >
                  Employer Login
                </li>
              </ul>
            </div>
            {disLo ? (
              <>
                <div className="partner-wrapper">
                  <div
                    className="banner-content pt-3 pb-4"
                    style={{ fontSize: "30px" }}
                  >
                    <h3> Distributor Login </h3>
                  </div>
                  <div className="">
                    <form>
                      <div class="form-group mb-3">
                        <input
                          type="email"
                          name="email"
                          value={distributoremail}
                          placeholder="example@gmail.com*"
                          className="form-control"
                          onChange={(e) => setdistributoremail(e.target.value)}
                          required
                        />
                      </div>
                      <div class="form-group mb-3">
                        <input
                          type="password"
                          name="password"
                          value={distributorpwd}
                          placeholder="password"
                          className="form-control"
                          onChange={(e) => setdistributorpwd(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group">
                        {/* <Link to="#"> */}
                        <Button variant="success" onClick={distributorLogin}>
                          Login
                        </Button>
                        {/* </Link> */}
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="partner-wrapper">
                  <div
                    className="banner-content pt-3 pb-4"
                    style={{ fontSize: "30px" }}
                  >
                    <h3> Employer Login </h3>
                  </div>
                  <div className="">
                    <form>
                      <div class="form-group mb-3">
                        <input
                          type="email"
                          name="email"
                          placeholder="example@gmail.com*"
                          className="form-control"
                          required
                        />
                      </div>
                      <div class="form-group mb-3">
                        <input
                          type="password"
                          name="password"
                          placeholder=""
                          className="form-control"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <Link to="#">
                          <Button variant="success">Login</Button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DistibutorLogin;
