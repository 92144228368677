import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Modal, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
function Adminsubadmin() {
  const [data, setdata] = useState([]);
  const [show, setshow] = useState(false);
  const [selected, setselected] = useState({});
  const [selected1, setselected1] = useState({});
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  const [isOpen1, setIsOpen1] = useState(false);
  const [subadmin, setsubadmin] = useState(false);
  const [customergraphics, setcustomergraphics] = useState(false);
  const [retailergraphics, setretailergraphics] = useState(false);
  const [distributorgraphics, setdistributorgraphics] = useState(false);
  const [customer, setcustomer] = useState(false);
  const [distributor, setdistributor] = useState(false);
  const [retailer, setretailer] = useState(false);
  const [keyacccount, setkeyacccount] = useState(false);
  const [corporate, setcorporate] = useState(false);
  const [employee, setemployee] = useState(false);
  const [transporter, settransporter] = useState(false);
  const [de, setde] = useState(false);
  const [me, setme] = useState(false);
  const [customerorder, setcustomerorder] = useState(false);
  const [distributororder, setdistributororder] = useState(false);
  const [retailerorder, setretailerorder] = useState(false);
  const [keyacccountorder, setkeyacccountorder] = useState(false);
  const [corporateorder, setcorporateorder] = useState(false);
  const [employeeorder, setemployeeorder] = useState(false);
  const [localshoporder, setlocalshoporder] = useState(false);
  const [distributorretailerorder, setdistributorretailerorder] =
    useState(false);
  const [returnorder, setreturnorder] = useState(false);
  const [expense, setexpense] = useState(false);
  const [creditdebitnote, setcreditdebitnote] = useState(false);
  const [promocode, setpromocode] = useState(false);
  const [referalcode, setreferalcode] = useState(false);
  const [package1, setpackage1] = useState(false);
  const [product, setproduct] = useState(false);
  const [retailerproduct, setretailerproduct] = useState(false);
  const [vendorproduct, setvendorproduct] = useState(false);
  const [distributorproduct, setdistributorproduct] = useState(false);
  const [purchaseorders, setpurchaseorders] = useState(false);
  const [pincodeblock, setpincodeblock] = useState(false);
  const [transactions, settransactions] = useState(false);
  const [vouchercode, setvouchercode] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  useEffect(() => {
    getdisease();
  }, []);

  const getdisease =async () => {
    try {
     let response= await axios.get(`${apiURL}/getallsubadmin`)
       
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.subadmin);
  
          } 
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  const editprofile = async (e) => {
    try {
      const config = {
        url: "/admin/editadmin",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: {
          adminId: selected1._id,
          name: name ? name : selected1.name,
          email: email ? email : selected1.email,
          phoneNumber: phonenumber ? phonenumber : selected1.phoneNumber,
          subadmin: subadmin,
          customergraphics: customergraphics,
          retailergraphics: retailergraphics,
          distributorgraphics: distributorgraphics,
          transporter: transporter,
          customer: customer,
          distributor: distributor,
          retailer: retailer,
          keyacccount: keyacccount,
          corporate: corporate,
          employee: employee,
          de: de,
          me: me,
          customerorder: customerorder,
          distributororder: distributororder,
          retailerorder: retailerorder,
          keyacccountorder: keyacccountorder,
          corporateorder: corporateorder,
          employeeorder: employeeorder,
          localshoporder: localshoporder,
          distributorretailerorder: distributorretailerorder,
          returnorder: returnorder,
          expense: expense,
          creditdebitnote: creditdebitnote,
          promocode: promocode,
          referalcode: referalcode,
          packagenew: package1,
          product: product,
          retailerproduct: retailerproduct,
          vendorproduct: vendorproduct,
          distributorproduct: distributorproduct,
          purchaseorders: purchaseorders,
          pincodeblock: pincodeblock,
          transactions: transactions,
          vouchercode: vouchercode,
        },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("subadmin edited ");
        window.location.reload();
        return res;
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const remove = async (data) => {
    try {
      axios
        .post(`${apiURL}/deletesubadmin/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/patients/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phoneNumber",
      text: "Mobile No",
    },
    {
      dataField: "",
      text: "Access Modules",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <Button
              onClick={() => {
                setshow(true);
                setselected(row);
              }}
            >
              View
            </Button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Edit",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-outline-success"
              onClick={() => {
                setselected1(row);
                setsubadmin(row.subadmin);
                setcustomergraphics(row.customergraphics);
                setretailergraphics(row.retailergraphics);
                setdistributorgraphics(row.distributorgraphics);
                setcustomer(row.customer);
                setdistributor(row.distributor);
                setretailer(row.retailer);

                settransporter(row.transporter);

                setcustomerorder(row.customerorder);
                setdistributororder(row.distributororder);
                setretailerorder(row.retailerorder);

                setlocalshoporder(row.localshoporder);
                setdistributorretailerorder(row.distributorretailerorder);

                setreturnorder(row.returnorder);
                setexpense(row.expense);
                setcreditdebitnote(row.creditdebitnote);
                setpromocode(row.promocode);
                setreferalcode(row.referalcode);
                setpackage1(row.package1);
                setproduct(row.product);
                setretailerproduct(row.retailerproduct);
                setvendorproduct(row.vendorproduct);
                setdistributorproduct(row.distributorproduct);
                setpurchaseorders(row.purchaseorders);
                setpincodeblock(row.pincodeblock);
                settransactions(row.transactions);
                setvouchercode(row.vouchercode);
                showModal1();
              }}
            >
              Edit
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Block/Unblock",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {!row?.blockstatus ? (
              <button
                className="btn btn-outline-success"
                 onClick={() => block(row)}
              >
                Block
              </button>
            ) : (
              <button
                className="btn btn-outline-danger"
                onClick={() => unblock(row)}
              >
                Un-Block
              </button>
            )}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Delete",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-danger">
              Delete
            </button>
          </div>
        );
      },
    },
  ];
  const block = async (data) => {
    try {
      axios
        .post(`${apiURL}/blocksubadmin/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .post(`${apiURL}/unblocksubadmin/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Sub-Admin 
        </h1>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "subadmin.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="subadmin"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>

        <Modal
          show={show}
          onHide={() => setshow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Selected Access Module
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div>{selected.summary ? selected.summary : "No Summary found"}</div>                     */}
            <div className="row ml-5">
              <div className="col-md-6">Sub-Admin</div>
              {selected.subadmin ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Customer Graphics</div>
              {selected.customergraphics ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Retailer Graphics</div>
              {selected.retailergraphics ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Distributor Graphics</div>
              {selected.distributorgraphics ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Customer</div>
              {selected.customer ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Distributor</div>
              {selected.distributor ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Retailer</div>
              {selected.retailer ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Keyacccount</div>
              {selected.keyacccount ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Corporate</div>
              {selected.corporate ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Employee</div>
              {selected.employee ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Transporter</div>
              {selected.transporter ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">DE</div>
              {selected.de ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">ME</div>
              {selected.me ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Customer Order</div>
              {selected.customerorder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Distributor Order</div>
              {selected.distributororder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Retailer Order</div>
              {selected.retailerorder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Keyacccount Order</div>
              {selected.keyacccountorder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Corporate Order</div>
              {selected.corporateorder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Employee Order</div>
              {selected.employeeorder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Localshop Order</div>
              {selected.localshoporder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Distributor Retailer Order</div>
              {selected.distributorretailerorder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Return Order</div>
              {selected.returnorder ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Expense</div>
              {selected.expense ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Credit/Debit Note</div>
              {selected.creditdebitnote ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Promo Code</div>
              {selected.promocode ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Referal Code</div>
              {selected.referalcode ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Member Package</div>
              {selected.packagenew ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Products</div>
              {selected.product ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Purchase Orders</div>
              {selected.purchaseorders ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Pincode Block</div>
              {selected.pincodeblock ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Transaction</div>
              {selected.transactions ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Voucher Code</div>
              {selected.vouchercode ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Edit Subadmin </Modal.Title>
          </Modal.Header>
          <Container>
            <div style={{ width: "max-content" }}>
              <div>
                <label>Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={selected1.name}
                  onChange={(e) => setname(e.target.value)}
                />
                <label>Email</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={selected1.email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <label>Mobile Number</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={selected1.phoneNumber}
                  onChange={(e) => setphonenumber(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={subadmin}
                        onChange={() => setsubadmin(!subadmin)}
                      />
                    }
                    label="Sub-Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customergraphics}
                        onChange={() => setcustomergraphics(!customergraphics)}
                      />
                    }
                    label="Customer Graphics"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retailergraphics}
                        onChange={() => setretailergraphics(!retailergraphics)}
                      />
                    }
                    label="Retailer Graphics"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={distributorgraphics}
                        onChange={() =>
                          setdistributorgraphics(!distributorgraphics)
                        }
                      />
                    }
                    label="Distributor Graphics"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={transporter}
                        onChange={() => settransporter(!transporter)}
                      />
                    }
                    label="Transporter"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customer}
                        onChange={() => setcustomer(!customer)}
                      />
                    }
                    label="Customer"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={distributor}
                        onChange={() => setdistributor(!distributor)}
                      />
                    }
                    label="Distributor"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retailer}
                        onChange={() => setretailer(!retailer)}
                      />
                    }
                    label="Retailer"
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customerorder}
                        onChange={() => setcustomerorder(!customerorder)}
                      />
                    }
                    label="Customer Orders"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={distributororder}
                        onChange={() => setdistributororder(!distributororder)}
                      />
                    }
                    label="Distributor Orders"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retailerorder}
                        onChange={() => setretailerorder(!retailerorder)}
                      />
                    }
                    label="Retailer Orders"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={localshoporder}
                        onChange={() => setlocalshoporder(!localshoporder)}
                      />
                    }
                    label="Localshop Orders"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={distributorretailerorder}
                        onChange={() =>
                          setdistributorretailerorder(!distributorretailerorder)
                        }
                      />
                    }
                    label="Distributor Retailer Order"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={returnorder}
                        onChange={() => setreturnorder(!returnorder)}
                      />
                    }
                    label="Return Order"
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={expense}
                        onChange={() => setexpense(!expense)}
                      />
                    }
                    label="Expense"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={creditdebitnote}
                        onChange={() => setcreditdebitnote(!creditdebitnote)}
                      />
                    }
                    label="Payment/Credit/Debit Note"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={promocode}
                        onChange={() => setpromocode(!promocode)}
                      />
                    }
                    label="Promo Code"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={referalcode}
                        onChange={() => setreferalcode(!referalcode)}
                      />
                    }
                    label="Referal Code"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={product}
                        onChange={() => setproduct(!product)}
                      />
                    }
                    label="Products"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={purchaseorders}
                        onChange={() => setpurchaseorders(!purchaseorders)}
                      />
                    }
                    label="Purchase Orders"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pincodeblock}
                        onChange={() => setpincodeblock(!pincodeblock)}
                      />
                    }
                    label="Pincode Block"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={transactions}
                        onChange={() => settransactions(!transactions)}
                      />
                    }
                    label="Transaction"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vouchercode}
                        onChange={() => setvouchercode(!vouchercode)}
                      />
                    }
                    label="Voucher code"
                  />
                </FormGroup>
              </div>
            </div>
          </Container>
          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Close
            </Button>
            <Button variant="danger" onClick={() => editprofile()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Adminsubadmin;
