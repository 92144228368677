import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormControl, FormGroup, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Button, Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";

function Adminretailerorders() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [pincode, setpincode] = useState("");
  const [address, setaddress] = useState("");
  const [name, setname] = useState("");
  const [data1, setdata1] = useState([]);
  const [retailerId,setretailerId]=useState("");

  const topretailers = async (e) => {
    e.preventDefault();

    try {
      if(!retailerId) return alert("Please select retailer Id!");
      if(!name) return alert("Please enter name!")
      if(!pincode) return alert("Please enter pincode!");
      if(!(/^\d{6}$/).test(pincode)) return alert("Invalid pincode!")
      if(!address) return alert("Please enter address!")
      const config = {
        url: "/retailer/topretailers",
        method: "post",
        baseURL: apiURL,
        // data: formdata,
        headers: { "content-type": "application/json" },
         data: {
          retailerId:retailerId,
          name: name,
          pincode: pincode,
          address: address,
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert(" Added");
          gettopretailer();
          hideModal()
        }
      });
    } catch (error) {
      console.error(error);
      alert(" Not Added");
    }
  };

  const getAllretailer=async()=>{
    try {
      let res=await axios.get(`${apiURL}/retailer/allretailer`);
      if(res.status===200){
        setdata(res.data.retailer)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    gettopretailer();
    getAllretailer()
  }, []);

  const gettopretailer = () => {
    try {
      axios
        .get(`${apiURL}/retailer/topallretailers`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata1(response.data.topretailer);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition( true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [data3, setData3] = useState({});
  
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const remove = async() =>{
    try{
        axios.post(`${apiURL}/retailer/deletetopretailer/`+data3._id)
                     .then(function(response){
                        if (response.status === 200) { 
                         alert("Successfully deleted") 
                         hideModal1();
                         gettopretailer()  
                  }
                }).catch(function(error){   
                  // setdatacondition(true);
                  console.log(error.message.data);
                  alert("Not able to complete");
                  
                });
              }catch (error) {
                 
                console.warn(error);
     alert("Not able to complete");
    // setdatacondition(true);
     return error;
    };
}


  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data1.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/retailers/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      text:"Retailer Id",
      formatter:(cell,row)=>{
        return (<p>{row?.retailerId?.rid}</p>)
      }
    },
    {
      dataField: "name",
      text: "Retailer Name",
    },

    {
      dataField: "pincode",
      text: "Retailer pincode",
    },
    {
      dataField: "address",
      text: "Address",
      
    },
    {
      dataField: "",
      text: "Status",
      formatter: (cell, row) => {
     
        return <div><button className="btn btn-danger" onClick={()=>{   showModal1()
          setData3(row)}}>Delete</button></div>;
      }     
    },
  ];
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const showModal = () => {
    setIsOpen(true);
  };
  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <div style={{ background: "" }}>
      <div className="mt-3">
        <Button variant="success" onClick={showModal} className="mb-2">
          Add Top retailers
        </Button>
        <Modal show={isOpen} onHide={hideModal}>
          <Modal.Header>
            <Modal.Title>Top Retailers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
            <select style={{marginBottom:"7px"}} onChange={(e)=>setretailerId(e.target.value)}>
                <option>Select Retailer Id</option>
                {data?.map((ele)=>{
                  return (<option value={ele?._id}>{ele?.rid}</option>)
                })}
              </select>
              <br/>
              <input
                type="text"
                placeholder="Enter the Retailer name"
                onChange={(e) => setname(e.target.value)}
               
              ></input>
              <input
                type="text"
                placeholder="Enter the Retailer pincode"
                onChange={(e) => setpincode(e.target.value)}
                style={{marginTop:"10px"}}
              ></input>
              <input
                type="text"
                placeholder="Enter the  Retailer address"
                onChange={(e) => setaddress(e.target.value)}
                style={{marginTop:"10px"}}
              ></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={hideModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={topretailers}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
          {/* deleteMode */}
       <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Are you sure delete !</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Cancel
            </Button>
            <Button
              variant="danger"
              style={{ cursor: "pointer" }}
              onClick={() => remove()}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Top retailer
        </h1>

        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data1}
              columns={columns}
              search
              exportCSV={{
                fileName: "retailer.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="retailer"
                      responsive
                      hover
                      columns={columns}
                      data={data1}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminretailerorders;
