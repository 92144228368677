import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AiFillDelete } from "react-icons/ai";
import { useLocation } from "react-router-dom";

function AdminEditProduct() {
  const [clickno, setclickno] = useState("false");
  const [clickyes, setclickyes] = useState("true");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    alert("Combo product added sucessfully");
    setShow(false);
  };
  const handleClose1 = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [image1, setimage1] = useState();
  const [image2, setimage2] = useState();
  const [image3, setimage3] = useState();
  const [image4, setimage4] = useState();
  const [minimumStock, setminimumStock] = useState();
  const [category, setcategory] = useState("");
  const [subcategory, setsubcategory] = useState("");
  const [name, setname] = useState("");
  const [price, setprice] = useState("");
  const [desc, setdesc] = useState("");
  const [tax, settax] = useState("");
  const formdata = new FormData();
  const token = sessionStorage.getItem("token");
  const apiURL = process.env.REACT_APP_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [categorydata, setcategorydata] = useState([]);
  const [subcategorydata, setsubcategorydata] = useState([]);
  const [productvolumetype, setproductvolumetype] = useState("");
  const [productvolume, setproductvolume] = useState("");
  const [productsize, setproductsize] = useState("");
  const [productwarranty, setproductwarranty] = useState("");
  const [productwarrantytype, setproductwarrantytype] = useState("");
  const [productmodelno, setproductmodelno] = useState("");
  const [retailerMoq, setretailerMoq] = useState("");
  const [distributerMoq, setdistributerMoq] = useState("");
  const [productType, setproductType] = useState("");
  const [vendor, setvendor] = useState("");
  const [corporateofferPrice, setcorporateofferPrice] = useState("");
  const [employeeofferPrice, setemployeeofferPrice] = useState("");
  const [customerdiscountpercentage, setcustomerdiscountpercentage] =
    useState("");
  const [retailerdiscountpercentage, setretailerdiscountpercentage] =
    useState("");
  const [distributordiscountpercentage, setdistributordiscountpercentage] =
    useState("");
  const [howTostore, sethowTostore] = useState("");
  const [specification, setspecification] = useState("");
  const [employeediscountpercentage, setemployeediscountpercentage] =
    useState("");
  const [productfeatures, setproductfeatures] = useState("");
  const [productbrand, setproductbrand] = useState("");
  const [totalstock, settotalstock] = useState("");
  const [quantitycase, setquantitycase] = useState("");
  const [hsncode, sethsncode] = useState("");
  const [free, setfree] = useState("");
  const [iscombo, setiscombo] = useState(false);
  useEffect(() => {
    getcategory();
  }, []);

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getcategory");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.category);
    }
  };

  useEffect(() => {
    postallsubcategory();
  }, [category]);

  const postallsubcategory = async () => {
    let res = await axios.post(apiURL + "/admin/postsubcategory", {
      categoryName: category,
    });
    if ((res.status = 200)) {
      console.log(res);
      setsubcategorydata(res.data?.subcategory);
    }
  };


  const [Abc, setabc] = useState({});
  const getproductById=async()=>{
    try {
      let res = await axios.get(`${apiURL}/admin/getSingleProductById/${selected1?._id}`)
      if(res.status===200){
        setabc(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getproductById()
  },[])
  const [perQuntity, setperQuntity] = useState("");
  const [quntityPrice, setquntityPrice] = useState("");
  const addPrice = async () => {
    try {
      if (!perQuntity) {
        return alert("Please enter value");
      }
      if (!quntityPrice) {
        return alert("Please enter price");
      }
        const config = {
          url: "/admin/addProductPrice",
          method: "put",
          baseURL: apiURL,
          headers: { "content-type": "application/json" },
          data: {id:selected1?._id, perQuntity: perQuntity, quntityPrice: quntityPrice}}
          let res=await axios(config)
          if(res.status===200){
           alert("Added");
           setperQuntity("");
           setquntityPrice("");
           getproductById()
          }
     
    } catch (error) {
      console.log(error);
    }
  };




  const removePrice =async (i) => {
  try {
    const config = {
      url: "/admin/removeProductPrice",
      method: "put",
      baseURL: apiURL,
      headers: { "content-type": "application/json" },
      data: {id:selected1?._id, removeId:i}
    }
      let res=await axios(config)
      if(res.status===200){
        alert("Successfully deleted");
        getproductById()
      }
  } catch (error) {
    console.log(error);
  }
    // 2nd parameter means remove one item only
  };


  const postproduct = async () => {

    formdata.append("productname", name);

    formdata.append("retailerMoq", retailerMoq);
    formdata.append("distributerMoq", distributerMoq);
    formdata.append("productType", productType);
    formdata.append("vendor", vendor);
    formdata.append("productcategory", category);
    formdata.append("productsubcategory", subcategory);
    formdata.append("adminId", user.id);
    formdata.append("productprice", price);
    formdata.append("productdesc", desc);
    formdata.append("tax", tax);
  
    formdata.append("productimage", image1  );
    formdata.append("productimage1", image2 );
    formdata.append("productimage2", image3 );
    formdata.append("productimage3", image4);

    formdata.append("productimageA", Abc?.productimage[0] );
    formdata.append("productimageB",Abc?.productimage[1]);
    formdata.append("productimageC", Abc?.productimage[2]);
    formdata.append("productimageD", Abc?.productimage[3]);
    formdata.append("minimumStock", minimumStock);
    formdata.append("productvolumetype", productvolumetype);
    formdata.append("productvolume", productvolume);
    formdata.append("productsize", productsize);
    formdata.append("productwarranty", productwarranty);
    formdata.append("productwarrantytype", productwarrantytype);
    formdata.append("productmodelno", productmodelno);
    formdata.append(
      "customerofferPrice",
      Math.round(
        Number(price ? price :Abc.productprice) +
          Math.round(Number(price ? price :Abc.productprice) * (Number(tax ? tax : Abc.tax) / 100)) -
          (Number(price ? price :Abc.productprice) + Math.round(Number(price ? price :Abc.productprice) * (Number(tax ? tax : Abc.tax) / 100))) *
            (Number(customerdiscountpercentage ? customerdiscountpercentage : Abc?.customerdiscountpercentage) / 100)
      )
    );
    formdata.append("retailerofferPrice",   Math.round(
      Number(price ? price :Abc.productprice) +
        Math.round(Number(price ? price :Abc.productprice) * (Number(tax ? tax : Abc.tax) / 100)) -
        (Number(price ? price :Abc.productprice) + Math.round(Number(price ? price :Abc.productprice) * (Number(tax ? tax : Abc.tax) / 100))) *
          (Number(retailerdiscountpercentage ? retailerdiscountpercentage : Abc?.retailerdiscountpercentage) / 100)
    ));
    formdata.append("distributorofferPrice",  Math.round(
      Number(price ? price :Abc.productprice) +
        Math.round(Number(price ? price :Abc.productprice) * (Number(tax ? tax : Abc.tax) / 100)) -
        (Number(price ? price :Abc.productprice) + Math.round(Number(price ? price :Abc.productprice) * (Number(tax ? tax : Abc.tax) / 100))) *
          (Number(distributordiscountpercentage ? distributordiscountpercentage : Abc?.distributordiscountpercentage) / 100)
    ));

    formdata.append("employeeofferPrice",  Math.round(
      Number(price ? price :Abc.productprice) +
        Math.round(Number(price ? price :Abc.productprice) * (Number(tax ? tax : Abc.tax) / 100)) -
        (Number(price ? price :Abc.productprice) + Math.round(Number(price ? price :Abc.productprice) * (Number(tax ? tax : Abc.tax) / 100))) *
          (Number(customerdiscountpercentage ? customerdiscountpercentage : Abc?.customerdiscountpercentage) / 100)
    ));

    formdata.append("customerdiscountpercentage", customerdiscountpercentage);
    formdata.append("retailerdiscountpercentage", retailerdiscountpercentage);
    formdata.append(
      "distributordiscountpercentage",
      distributordiscountpercentage
    );
    formdata.append("howTostore", howTostore);
    formdata.append("specification", specification);
    formdata.append("employeediscountpercentage", employeediscountpercentage);
    formdata.append("productfeatures", productfeatures);
    formdata.append("productbrand", productbrand);
    formdata.append("totalstock", totalstock);
    formdata.append("remainingstock", totalstock);
    formdata.append("quantitycase", quantitycase);
    formdata.append("hsncode", hsncode);
    formdata.append("free", free);
      formdata.append("productId",Abc?._id)

    try {
      const config = {
        url: "/admin/editproduct",
        method: "post",
        baseURL: apiURL,
        data: formdata,
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        alert("Successfully updated")
        window.location.assign("/admin/products");
      } else {
        alert(" Unsuccessfull");
      }
    } catch (error) {
      console.warn(error);

      alert(" Unsuccessfull");
      return error;
    }
  };

  const location=useLocation()
  const selected1= location?.state?.row

  console.log("check it state",selected1);
  // const editproduct = async () => {
  //   try {
  //     const config = {
  //       url: "/admin/editproduct",
  //       method: "post",
  //       baseURL: apiURL,
  //       headers: { "content-type": "application/json" },
  //       // data: {
  //       //   productId: selected1._id,
  //       //   productname: productname ? productname : selected1.productname,
  //       //   productprice: mrp ? mrp : selected1.productprice,
  //       //   customerofferPrice: cop ? cop : selected1.customerofferPrice,
  //       //   customerdiscountpercentage: cdp
  //       //     ? cdp
  //       //     : selected1.customerdiscountpercentage,
  //       //   retailerofferPrice: rop ? rop : selected1.retailerofferPrice,
  //       //   retailerdiscountpercentage: rdp
  //       //     ? rdp
  //       //     : selected1.retailerdiscountpercentage,
  //       //   distributorofferPrice: dop ? dop : selected1.distributorofferPrice,
  //       //   distributordiscountpercentage: ddp
  //       //     ? ddp
  //       //     : selected1.distributordiscountpercentage,
  //       //   productbrand: brand ? brand : selected1.productbrand,
  //       //   productdesc: desc ? desc : selected1.productdesc,
  //       //   productfeatures: feature ? feature : selected1.productfeatures,
  //       // },
  //     };
  //     let res = await axios(config);
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       alert("details has been edited successfully");
  //       window.location.reload();
  //       return res;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
// useEffect(()=>{
//    if(selected1?.productprice){
//     setprice(selected1?.productprice)
//    }
//    if(selected1?.tax){
//     settax(selected1?.tax)
//    }

// },[])
  const [allBrand, setAllBrand] = useState([]);
  const getAllBrand = async () => {
    try {
      let res = await axios.get(`${apiURL}/getAllBrand`);
      if (res.status === 200) {
        setAllBrand(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBrand();
  }, []);

  console.log("check it", allBrand);
  return (
    <>
      <div className="container pb-5">
        <Card
          className="text-center mt-5"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <h5 className="pt-5"> Add Product</h5>
          <Card.Body>
            <div className="row me-0">
              <div className="col-1"></div>
              <div className="col-2">
                <div>
                  <h5 htmlFor="upload1">Image 1</h5>
                </div>
                <div className="mt-4">
                  <input
                    type="file"
                    id="upload1"
                    name="file"
                    accept="image/*"
                    style={{ border: "none" }}
                    onChange={(e) => setimage1(e.target.files[0])}
                    required
                  />
                </div>
              </div>
              <div className="col-2">
                <div>
                  <h5 htmlFor="upload2">Image 2</h5>
                </div>
                <div className="mt-4">
                  <input
                    type="file"
                    id="upload2"
                    name="file"
                    accept="image/*"
                    style={{ border: "none" }}
                    onChange={(e) => setimage2(e.target.files[0])}
                    required
                  />
                </div>
              </div>
              <div className="col-2">
                <div>
                  <h5 htmlFor="upload3">Image 3</h5>
                </div>
                <div className="mt-4">
                  <input
                    type="file"
                    id="upload3"
                    name="file"
                    accept="image/*"
                    style={{ border: "none" }}
                    onChange={(e) => setimage3(e.target.files[0])}
                    required
                  />
                </div>
              </div>
              <div className="col-2">
                <div>
                  <h5 htmlFor="upload4">Image4</h5>
                </div>
                <div className="mt-4">
                  <input
                    type="file"
                    id="upload4"
                    name="file"
                    accept="image/*"
                    style={{ border: "none" }}
                    onChange={(e) => setimage4(e.target.files[0])}
                    required
                  />
                </div>
              </div>
              {/* <div className="col-2">
              <div>
                <h5>Video</h5>
              </div>
              <div className="mt-4">
                <input
                  type="file"
                  style={{ border: "none" }}
                  onChange={(e) => setvideo(e.target.files[0])}
                />
              </div>
            </div> */}
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <FormControl>
                  
                   {selected1?.productType ? (<InputLabel id="demo-simple-select-label">
                    {selected1?.productType}
                  </InputLabel>):(<InputLabel id="demo-simple-select-label">
                    Select Product Type
                  </InputLabel>)}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: "275px" }}
                    onChange={(e) => setproductType(e.target.value)}
                  >
                   
                    <MenuItem value="Product">Product</MenuItem>
                    <MenuItem value="Combo Product">Combo Product</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl>
               
                   {selected1?.productcategory ? (   <InputLabel id="demo-simple-select-label">
                    {selected1?.productcategory }
                  </InputLabel>):(   <InputLabel id="demo-simple-select-label">
                    Select Category*
                  </InputLabel>)}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: "275px" }}
                    onChange={(e) => setcategory(e.target.value)}
                  >

                    {categorydata?.map((allcategory) => (
                      <MenuItem value={allcategory.categoryName}>
                        {allcategory.categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product Name With Title*"
                  style={{ width: "275px" }}
                  onChange={(e) => setname(e.target.value)}
                  placeholder={selected1?.productname ? (selected1?.productname):("Product Name With Title*")}
                />
              </div>
              {/* <div className="col-4">
            <TextField
                id="standard-secondary"
                label="Product Id"
                style={{ width: "275px" }}
              />
            </div> */}
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product price Without GST"
                  style={{ width: "275px" }}
                  onChange={(e) => setprice(e.target.value)}
                  placeholder={selected1?.productprice ? (selected1?.productprice):("Product price Without GST")}
                />
              </div>
              <div className="col-4">
                <FormControl>
                  {selected1?.tax ? (   <InputLabel id="demo-simple-select-label">
                    {selected1?.tax}%
                  </InputLabel>):(   <InputLabel id="demo-simple-select-label">
                    Product GST
                  </InputLabel>)}
               
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: "275px" }}
                    onChange={(e) => settax(e.target.value)}
                  >
                    <MenuItem value={5}>5%</MenuItem>
                    <MenuItem value={12}>12%</MenuItem>
                    <MenuItem value={18}>18%</MenuItem>
                    <MenuItem value={28}>28%</MenuItem>
                    <MenuItem value={0}>none</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="HSN Code *"
                  placeholder={selected1?.hsncode}
                  style={{ width: "275px" }}
                  onChange={(e) => sethsncode(e.target.value)}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <FormControl>
                  {/* <InputLabel id="demo-simple-select-label">
                  Product Volume With UOM
                </InputLabel> */}
                  <TextField
                    id="standard-secondary"
                    label="Product Value Type"
                    style={{ width: "275px" }}
                    placeholder={selected1?.productvolumetype ? (selected1?.productvolumetype):("Product Value Type")}
                    onChange={(e) => setproductvolumetype(e.target.value)}
                  />
                  {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{ width: "275px" }}
                  onChange={(e) => settax(e.target.value)}
                >
                  <MenuItem value={5}>1Kg</MenuItem>
                  <MenuItem value={12}>1pc</MenuItem>
                  <MenuItem value={18}></MenuItem>
                  <MenuItem value={28}></MenuItem>
                  <MenuItem value={0}>none</MenuItem>
                </Select> */}
                </FormControl>
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product Value"
                  style={{ width: "275px" }}
                  placeholder={selected1?.productvolume}
                  onChange={(e) => setproductvolume(e.target.value)}
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product Size L*B*H"
                  placeholder={selected1?.productsize}
                  style={{ width: "275px" }}
                  onChange={(e) => setproductsize(e.target.value)}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Product Brand
                  </InputLabel>
                  <Select
                    labelId={selected1?.productbrand ? (selected1?.productbrand):("demo-simple-select-label")}
                    id="demo-simple-select"
                    style={{ width: "275px" }}
                    onChange={(e) => setproductbrand(e.target.value)}
                  >
                    {allBrand?.map((ele) => {
                      return (
                        <MenuItem value={ele?.BrandName}>
                          {ele?.BrandName}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value="none">none</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* <div className="col-4">
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Product Warranty Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{ width: "275px" }}
                  onChange={(e) => setproductwarrantytype(e.target.value)}
                >
                  <MenuItem value="Month">Month</MenuItem>
                  <MenuItem value="Year">Year</MenuItem>
                </Select>
              </FormControl>
            </div> */}
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product Model No"
                  placeholder={selected1?.productmodelno}
                  style={{ width: "275px" }}
                  onChange={(e) => setproductmodelno(e.target.value)}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Vendor Details *"
                  placeholder={selected1?.vendor}
                  style={{ width: "275px" }}
                  onChange={(e) => setvendor(e.target.value)}
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Retailer MOQ *"
                  placeholder={selected1?.retailerMoq}
                  style={{ width: "275px" }}
                  onChange={(e) => setretailerMoq(e.target.value)}
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Distributor MOQ *"
                  placeholder={selected1?.distributerMoq}
                  style={{ width: "275px" }}
                  onChange={(e) => setdistributerMoq(e.target.value)}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Customer Discount Percentage *"
                  placeholder={selected1?.customerdiscountpercentage}
                  style={{ width: "275px" }}
                  onChange={(e) =>
                    setcustomerdiscountpercentage(e.target.value)
                  }
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Retailer Discount Percentage *"
                  style={{ width: "275px" }}
                  placeholder={selected1?.retailerdiscountpercentage}
                  onChange={(e) =>
                    setretailerdiscountpercentage(e.target.value)
                  }
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Distributor Discount Percentage *"
                  placeholder={selected1?.distributordiscountpercentage}
                  style={{ width: "275px" }}
                  onChange={(e) =>
                    setdistributordiscountpercentage(e.target.value)
                  }
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Product Small Discription *"
                  placeholder={selected1?.productdesc}
                  style={{ width: "275px" }}
                  onChange={(e) => setdesc(e.target.value)}
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Key Specification/Benifits *"
                  placeholder={selected1?.specification}
                  style={{ width: "275px" }}
                  onChange={(e) => setspecification(e.target.value)}
                />
              </div>
              <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="How to use?/Storage *"
                  style={{ width: "275px" }}
                   placeholder={selected1?.howTostore}
                  value={howTostore}
                  onChange={(e) => sethowTostore(e.target.value)}
                />
              </div>

              {/* <div className="col-4">
                <TextField
                  id="standard-secondary"
                  label="Total Stock *"
                       placeholder={selected1?.totalstock}
                  style={{ width: "275px" }}
                  onChange={(e) => settotalstock(e.target.value)}
                />
              </div> */}
            </div>
            <div className="col-4">
              <TextField
                id="standard-secondary"
                label="Minimum Stock"
                style={{ width: "275px" }}
                value={minimumStock}
                onChange={(e) => setminimumStock(e.target.value)}
              />
            </div>

            <section className="row">
              <section className="col-md-4"></section>
              <section className="col-md-8">
                <h3>Add More Volume</h3>
                <div className="row pt-3">
                  <div className="col-4">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Product Volume
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{ width: "275px" }}
                        onChange={(e) => setperQuntity(e.target.value)}
                      >
                        <MenuItem value={1}>1{productvolumetype ? (productvolumetype):(selected1?.productvolumetype)}</MenuItem>
                        <MenuItem value={2}>2{productvolumetype ? (productvolumetype):(selected1?.productvolumetype)}</MenuItem>
                        <MenuItem value={5}>5{productvolumetype ? (productvolumetype):(selected1?.productvolumetype)}</MenuItem>
                        <MenuItem value={10}>10{productvolumetype ? (productvolumetype):(selected1?.productvolumetype)}</MenuItem>
                        <MenuItem value={15}>15{productvolumetype ? (productvolumetype):(selected1?.productvolumetype)}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <TextField
                      id="standard-secondary"
                      label="Product Price WithOut Gst *"
                      style={{ width: "275px" }}
                      value={quntityPrice}
                      onChange={(e) => setquntityPrice(e.target.value)}
                    />
                  </div>
                  <div className="col-4">
                    <Button
                      onClick={addPrice}
                      style={{ backgroundColor: "#2e3da0ad" }}
                    >
                      ADD
                    </Button>
                  </div>
                </div>
                {Abc?.AllPrice?.map((item, i) => {
                  return (
                    <div className="row pt-3">
                      <div className="col-3">
                        <h6>
                          {item?.perQuntity}
                          {productvolumetype ? (productvolumetype):(selected1?.productvolumetype)}
                        </h6>
                      </div>
                      <div className="col-4">
                        <h6> ₹ {item?.quntityPrice}</h6>
                      </div>
                      <div className="col-4">
                        <AiFillDelete
                          className="delete-button"
                          onClick={() => removePrice(item?._id)}
                        />
                      </div>
                    </div>
                  );
                })}
              </section>
            </section>

            <div className="row pt-3"></div>
            {/* <div className="mt-3 mb-3">
            *<span style={{ color: "red" }}>Required feilds</span>
          </div> */}
            <button
              className="btn btn-outline-primary mt-4"
              onClick={postproduct}
            >
              Save All Changes
            </button>
          </Card.Body>
        </Card>
      </div>

      <Modal show={show} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>ADD COMBO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ backgroundColor: "white" }}
            >
              <Form.Label>
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#2e3da0ad",
                  }}
                >
                  Combo product Name
                </h6>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product Name"
                autoFocus
                // value={comboproduct}
                // onChange={(e) => {
                //   setcomboproduct(e.target.value);
                // }}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    paddingTop: "10px",
                    color: "#2e3da0ad",
                  }}
                >
                  Combo product description
                </h6>
              </Form.Label>
              {/* <Form.Control
            as="textarea"
            value={combodescription}
            onChange={(e) => {
              setcombodescription(e.target.value);
            }}
            rows={3}
          /> */}
              <CKEditor
                editor={ClassicEditor}
                // data={combodescription}
                // onChange={handleChange1}
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    paddingTop: "10px",
                    color: "#2e3da0ad",
                  }}
                >
                  Combo product Price
                </h6>
              </Form.Label>
              <Form.Control
                type="text"
                // value={comboprice}
                // onChange={(e) => {
                //   setcomboprice(e.target.value);
                // }}
                rows={3}
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    paddingTop: "10px",
                    color: "#2e3da0ad",
                  }}
                >
                  Combo product Free Price
                </h6>
              </Form.Label>
              <Form.Control
                type="text"
                // value={combofreeprice}
                // onChange={(e) => {
                //   setcombofreeprice(e.target.value);
                // }}
                rows={3}
              />
            </Form.Group>
            <div style={{ display: "flex" }}>
              <h6
                style={{
                  marginTop: "40px",
                  marginRight: "30px",
                  marginLeft: "30px",
                  fontSize: "15px",
                  fontWeight: "500",
                  width: "100%",
                  color: "#2e3da0ad",
                }}
              >
                SELECT COMBO IMAGE
              </h6>
              {/* {comboImage && (
            <div>
              <img
                src={URL.createObjectURL(comboImage)}
                alt="Thumb"
                width={100}
                height={100}
              ></img>
            </div>
          )} */}
              <label htmlFor="upload12">
                <span>
                  <img
                    src="/img/upload.png"
                    alt=""
                    style={{
                      width: "25%",
                      paddingTop: "10%",
                      marginLeft: "30%",
                    }}
                  />
                </span>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="file"
                  id="upload12"
                  accept="image/*"
                  // onChange={(e) => setcomboImage(e.target.files[0])}
                />
              </label>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#2e3da0ad" }}
            onClick={handleClose}
          >
            ADD COMBO
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminEditProduct;
