import React, { useEffect, useState } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit, FiEye } from "react-icons/fi";
import DatePicker from "react-multi-date-picker";
import { Switch } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

function DistributorStock() {
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [selected, setselected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stock, setstock] = useState("");
  const [free, setfree] = useState("");
  const [date, setdate] = useState(new Date());
  const [basicprice, setbasicprice] = useState("");
  const [tax, settax] = useState("");
  const [PurchaseEntry, setPurchaseEntry] = useState([]);
  const [excel, setexcel] = useState();
  const [image, setimage] = useState();
  const [data1, setdata1] = useState([]);
  //

  const handleCalendarClose = () => console.log("Calendar closed");
  const handleCalendarOpen = () => console.log("Calendar opened");
  const handleCalendarClose1 = () => console.log("Calendar closed");
  const handleCalendarOpen1 = () => console.log("Calendar opened");

  const [productname, setproductname] = useState("");
  const [ProducePrice, setProducePrice] = useState();
  const [GST, setGST] = useState();
  const [mrp, setmrp] = useState(0);
  const [Brand, setBrand] = useState("");
  const [totalstock, settotalstock] = useState();
  const [minStock, setminStock] = useState();
  const [PurchaseOrderId, setPurchaseOrderId] = useState("");
  const [Purchasedate, setPurchasedate] = useState(new Date());
  const [Invoicenum, setInvoicenum] = useState("");
  const [Invoicedate, setInvoicedate] = useState(new Date());
  const [categorydata, setcategorydata] = useState([]);
  const [StockDate, setStockDate] = useState(new Date());

  // const [desc, setdesc] = useState("");
  // const [feature, setfeature] = useState("");
  // const [brand, setbrand] = useState("");
  const [Category, setCategory] = useState("");
  let j = 1;
  //
  const formdata = new FormData();
  const [selected1, setselected1] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  // const [ID, setId] = useState("");
  const [Entry, setEntry] = useState({});
  const [NewStock, setNewStock] = useState();
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = (data) => {
    setselected(data);
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const [showD, setShowD] = useState(false);

  const handleCloseD = () => setShowD(false);
  const handleShowD = () => {
    setShowD(true);
  }

  useEffect(() => {
    if(!distributor){
      alert("Please login first");
      window.location.assign("/distributor/login");
    }
    getpurchaseEntry();
    getcategory();
  }, []);

  useEffect(() => {
    if(!distributor){
      alert("Please login first");
      window.location.assign("/distributor/login");
    }
    getpurchaseEntry();
    getcategory();
  }, []);

  useEffect(() => {
    if (ProducePrice || GST) {
      let price_product;
      let product_gst;
      if (ProducePrice && GST) {
        price_product = ProducePrice;
        product_gst = GST;
      } else {
        if (ProducePrice) {
          // update.productPrice = productPrice;
          price_product = ProducePrice;
          product_gst = Entry?.GST;
        }
        if (GST) {
          // update.GST = GST;
          product_gst = GST;
          price_product = Entry?.productPrice;
        }
      }

      let price = Number((Math.round(price_product * 100) / 100).toFixed(2));
      // alert(typeof price);

      let additionalValue = price * (product_gst / 100);
      // gets the value to 2 decimal places...
      let addGSTVal = Number(
        (Math.round(additionalValue * 100) / 100).toFixed(2)
      );
      let mrp = price + addGSTVal;
      setmrp(mrp);
    }
  });

  

  const getpurchaseEntry = async () => {
    let res = await axios.get(
      apiurl + `/getPurchaseDetails/${distributor?._id}`
    );
    if ((res.status = 200)) {
      console.log(res);
      setPurchaseEntry(res.data?.purchaseDetails);
    }
  };

  const getcategory = async () => {
    let res = await axios.get(apiurl + "/getcategory");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.category);
    }
  };
  // const getpurchaseEntry = async () => {
  //   let res = await axios.get(apiurl + `/getPurchaseDetails/${distributor?._id}`);
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setPurchaseEntry(res.data?.purchaseDetails);
  //   }
  // };

  if (excel && data1.length === 0) {
    readFile();
  }

  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setdata1(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }

  function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }
  const editproduct = async () => {
    try {
      const config = {
        url: `/editPurchaseDetails/${Entry?._id}`,
        method: "put",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: {
          productName: productname,
          productPrice: ProducePrice,
          productCategory: Category,
          GST: GST,
          mrp: mrp,
          productBrand: Brand,
          totalStock: totalstock,
          minStock: minStock,
          purchaseOrderId: PurchaseOrderId,
          purchaseDate: Purchasedate,
          invoiceNum: Invoicenum,
          invoiceDate: Invoicedate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("details has been edited successfully");
        // window.location.reload();
        hideModal1();
        getpurchaseEntry();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const deleteProductDetails = async (e)=>{
e.preventDefault();
    try{
      const config = {
        url: `/deletePurchaseDetails/${Entry?._id}`,
        method: "delete",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
      }
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert('Deleted successfully');
        handleCloseD();
        getpurchaseEntry();
      }

      // axios.delete(apiurl+`/deletePurchaseDetails/${Entry?._id}`).then((res)=>{
      //   if(res.status === 200){
      //     alert('Deleted successfully');
      //   }
      // }).catch((error)=>{
      //   console.log(error);
      //   alert(error.response.data.error);
      // })
    }catch(error){
      console.log(error);
      alert(error.response.data.error);
    }
  }

  const postorders = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/addmultiproduct",
        method: "post",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: {
          adminId: user.id,
          product: data1.map((datas) => ({
            productname: datas.Product_Name,
            productcategory: datas.Category,
            productsubcategory: datas.Subcategory,
            image1: datas.Product_Image_1_Name,
            image2: datas.Product_Image_2_Name,
            image3: datas.Product_Image_3_Name,
            image4: datas.Product_Image_4_Name,
            video: datas.Product_Video_Name,
            productprice: datas.MRP_Price,
            productdesc: datas.Description,
            tax: datas.GST,
            productvolumetype: datas.Product_Volume_Type,
            productvolume: datas.Product_Volume,
            productsize: datas.Product_Size,
            productwarranty: datas.Product_Warranty,
            productwarrantytype: datas.Product_Warranty_Type,
            productmodelno: datas.Product_Model_No,
            customerofferPrice: datas.Customer_Offer_Price,
            retailerofferPrice: datas.Retailer_Offer_Price,
            distributorofferPrice: datas.Distributor_Offer_Price,
            keyaccoutofferPrice: datas.Keyaccout_Offer_Price,
            corporateofferPrice: datas.Corporate_Offer_Price,
            employeeofferPrice: datas.Employee_Offer_Price,
            customerdiscountpercentage: datas.Customer_Discount_Percentage,
            retailerdiscountpercentage: datas.Retailer_Discount_Percentage,
            distributordiscountpercentage:
              datas.Distributor_Discount_Percentage,
            keyaccountdiscountpercentage: datas.Keyaccount_Discount_Percentage,
            corporatediscountpercentage: datas.Corporate_Discount_Percentage,
            empolyeediscountpercentage: datas.Empolyee_Discount_Percentage,
            productfeatures: datas.Product_Features,
            productbrand: datas.Product_Brand,
            totalstock: datas.Total_Stock,
            quantitycase: datas.Quantity_Case,
            hsncode: datas.HSN_Code,
          })),
        },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Products Added Successfully");
        window.location.assign("/admin/products");

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postimage = async (e) => {
    e.preventDefault();
    Array.from(image).forEach((file) => {
      formdata.append("productimage", file);
    });

    try {
      const config = {
        url: "/admin/productimage",
        method: "post",
        baseURL: apiurl,

        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Image Uploaded Successfully");

        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const remove = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/deleteproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const updateStock = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: `/addStock/${Entry?._id}`,
        method: "put",
        baseURL: apiurl,
        // data: formdata,
        headers: { "content-type": "application/json" },
        data: {
          newStock: NewStock,
          updateStockDate: StockDate,
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert(response.data.success);
          hideModal();
          getpurchaseEntry();
        }
      });
    } catch (error) {
      console.error(error);
      alert(error.response.data.error);
    }
  };

  const subscribe = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/subscribeproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unsubscribe = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/unsubscribeproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: PurchaseEntry?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/subcategory/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  // let jfjf=1;
  const columns = [
    {
      dataField: `_id`,
      text: "id",
      sort: false,
    },

    {
      dataField: "productCategory",
      text: "Category",
      sort: true,
    },
    // {
    //   dataField: "productsubcategory",
    //   text: "Subcategory",
    //   sort: true,
    // },
    {
      dataField: "productName",
      text: "Product Name With Title",
    },
    {
      dataField: "productPrice",
      text: "Product Price",
    },
    {
      dataField: "GST",
      text: "GST",
    },
    {
      dataField: "mrp",
      text: "MRP",
    },

    {
      dataField: "productBrand",
      text: "Product Brand",
    },
    // {
    //   dataField: "productBrand",
    //     text: "Stock Date",
    //   },
    {
      dataField: "totalStock",
      text: "Total Stock",
    },
    {
      dataField: "minStock",
      text: "Minimum Stock",
    },
    // {
    //   dataField: "purchaseOrderId",
    //   text: "Purchase Order ID",
    // },
    {
      // dataField: "purchaseDate",
      text: "Purchase Date",
      formatter: (cell, row) => {
        return <div>{moment(row?.purchaseDate).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      dataField: "invoiceNum",
      text: "Invoice Number",
    },
    {
      // dataField: "invoiceDate",
      text: "Invoice Date",
      formatter: (cell, row) => {
        return <div>{moment(row?.invoiceDate).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      dataField: "",
      text: "Edit",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {/* <button
              className="btn btn-outline-success"
            >
            View
            </button> */}
            {/* <FiEye/> /  */}
            <FiEdit
              onClick={() => {
                setIsOpen1(true);
                setEntry(row);
              }}
            />
          </div>
        );
      },
    },

    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div className="d-flex">
            <button
              className="btn btn-success"
              onClick={() => {
                showModal(row);
                setEntry(row);
              }}
            >
              Add Stock
            </button>

            <AiFillDelete
              className="delete-button"
              onClick={() => {
                setEntry(row);
                handleShowD();
              }}
            />
          </div>
        );
      },
    },
    {
      text: "Remarks",
    },
  ];

  var i = 1;
  return (
    <div className="container mt-3">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Free Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label className="mt-2">Product Title</label>
              <input
                type="text"
                placeholder="Enter Product Title"
                className="form-control"
              ></input>
              <label className="mt-2">Small Discription</label>
              <input
                type="text"
                placeholder="Enter Small Discription"
                className="form-control"
              ></input>
              <label className="mt-2">MRP</label>
              <input
                type="text"
                placeholder="Enter MRP"
                className="form-control"
              ></input>
              <br />
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Product Image</Form.Label>
                <br />
                <Form.Control type="file" />
              </Form.Group>
            </div>
            <br></br>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mt-3">
        <Modal show={isOpen} onHide={hideModal}>
          <Modal.Header>
            <Modal.Title>Add Stock</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label className="mt-2">Date</label>
                <DatePicker
                  style={{ width: "466px", height: "38px" }}
                  format="DD-MM-YYYY"
                  type="input-icon"
                  minDate={new Date()}
                  onChange={(date) => setStockDate(date)}
                />

                <label className="mt-2">Current Stock</label>
                <div> {Entry.totalStock}</div>
                {/* <input type="text" className="form-control" ></input> */}
                <label className="mt-2">New Stock</label>
                <input
                  type="text"
                  value={NewStock}
                  placeholder="Enter Quantity"
                  className="form-control"
                  onChange={(e) => setNewStock(e.target.value)}
                ></input>
                {/* <label className="mt-2">Free</label>
                <input
                  type="text"
                  placeholder="Enter Free"
                  className="form-control"
                  onChange={(e) => setfree(e.target.value)}
                ></input>
                <label className="mt-2">Tax</label>
                <input
                  type="text"
                  placeholder="Enter Free"
                  className="form-control"
                  onChange={(e) => settax(e.target.value)}
                ></input> */}
              </div>
              <br></br>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={hideModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={updateStock}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <ToolkitProvider
          keyField="id"
          data={PurchaseEntry}
          columns={columns}
          search
          exportCSV={{
            fileName: "Products.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5 ">
              <span className="pr-5 mr-auto">
                <ExportCSVButton
                  className="btn-outline-success"
                  {...props.csvProps}
                >
                  Export to CSV
                </ExportCSVButton>
              </span>
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />
              {/* <PurchaseSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              /> */}
              <span className="pl-5 ms-5 me-5 mt-5">
                <Button {...props.searchProps}>
                  <Link
                    to="/distributor/distributor-purchase-entry"
                    style={{ color: "white" }}
                  >
                    Purchase Entry
                  </Link>
                </Button>
              </span>

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={PurchaseEntry}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
        <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Edit Product </Modal.Title>
          </Modal.Header>

          <div className="ms-2 me-2">
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Product Name
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setproductname(e.target.value)}
                placeholder={Entry.productName}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                product price
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setProducePrice(e.target.value)}
                placeholder={Entry.productPrice}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">GST</Form.Label>
              <Form.Control
                type="number"
                onChange={(e) => setGST(e.target.value)}
                placeholder={Entry.GST}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">MRP</Form.Label>
              {/* <Form.Control
                type="text"
                onChange={(e) => setmrp(e.target.value)}
                placeholder={Entry.mrp}
              /> */}
              {mrp ? <div> {mrp}</div> : <div> {Entry.mrp}</div>}
            </Form.Group>

            {/* <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Customer Discount Percentage
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setcdp(e.target.value)}
                placeholder={selected1.customerdiscountpercentage}
              />
            </Form.Group> */}
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Product Category
              </Form.Label>
              {/* <Form.Control
                type="text"
                onChange={(e) => setCategory(e.target.value)}
                placeholder={Entry.productCategory}
              /> */}
              {"   "}
              <select
                id="standard-secondary"
                style={{ width: "275px", borderRadius: "10px" }}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option>Choose Category</option>
                {categorydata?.map((item) => {
                  return (
                    <option value={item?.categoryName}>
                      {item?.categoryName}
                    </option>
                  );
                })}
              </select>
            </Form.Group>
            {/* <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                customer Offer Price
              </Form.Label>
              <Form.Control
                type="number"
                onChange={(e) => setcop(e.target.value)}
                placeholder={Entry.customerofferPrice}
              />
            </Form.Group> */}

            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Product Brand
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setBrand(e.target.value)}
                placeholder={Entry.productBrand}
              />
            </Form.Group>
            {/* <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Distributor Discount Percentage
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setddp(e.target.value)}
                placeholder={Entry.distributordiscountpercentage}
              />
            </Form.Group> */}

            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">Total stock</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => settotalstock(e.target.value)}
                placeholder={Entry.totalStock}
              />
            </Form.Group>

            {/* <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Retailer Offer Price
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setrop(e.target.value)}
                placeholder={Entry.retailerofferPrice}
              />
            </Form.Group> */}
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Minimum stock
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setminStock(e.target.value)}
                placeholder={Entry.minStock}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Purchase orderId
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setPurchaseOrderId(e.target.value)}
                placeholder={Entry.purchaseOrderId}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                Purchase Date
              </Form.Label>
              {/* <Form.Control
                type="text"
                onChange={(e) => setPurchasedate(e.target.value)}
                placeholder={moment(Entry.purchaseDate).format("DD-MM-YYYY")}
              /> */}
              {"   "}
              <DatePicker
                selected={Purchasedate}
                onChange={(Purchasedate) => setPurchasedate(Purchasedate)}
                id="standard-secondary"
                style={{
                  width: "275px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeftL: "none",
                }}
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={handleCalendarOpen}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                invoice number
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setInvoicenum(e.target.value)}
                placeholder={Entry.invoiceNum}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label className="mb-2 mt-2 fw-bold">
                invoice Date
              </Form.Label>
              {/* <Form.Control
                type="text"
                onChange={(e) => setInvoicedate(e.target.value)}
                placeholder={moment(Entry.invoiceDate).format("DD-MM-YYYY")}
              /> */}
              {"   "}
              <DatePicker
                selected={Invoicedate}
                onChange={(Invoicedate) => setInvoicedate(Invoicedate)}
                id="standard-secondary"
                style={{
                  width: "275px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeftL: "none",
                }}
                onCalendarClose={handleCalendarClose1}
                onCalendarOpen={handleCalendarOpen1}
              />
            </Form.Group>
          </div>
          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                editproduct();
                getcategory();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showD} onHide={handleCloseD} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you wanted to delete it</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseD}>
            Close
          </Button>
          <Button variant="primary" onClick={(e)=>deleteProductDetails(e)} style={{backgroundColor: "Red", color: "white"}}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </div>
  );
}

export default DistributorStock;
