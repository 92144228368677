import axios from 'axios';
import React, { useState } from 'react';
import { Button,Form,Card,Col, Container, Row, InputGroup } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { Country, State, City }  from 'country-state-city';

function Vendorsignup() {   
    const apiURL = process.env.REACT_APP_API_URL;    
    const [countrycode,setcountrycode] = useState("");
    const [statecode, setstatecode] = useState("");
    const [city, setcity] = useState("");
    const countriesList = Country.getAllCountries();
    const statesList = State.getStatesOfCountry(countrycode);
    // const citiesList = data.getAllCitiesFromState('Maharashtra');
    const cities = City.getCitiesOfState(countrycode, statecode);
    const no = sessionStorage.getItem("phoneno");
    const [email, setemail] = useState('');
    const [ownername, setownername] = useState('');
    const [firmname, setfirmname] = useState('');
    const [password, setpassword] = useState('');    
    const [gstno, setgstno] = useState('');
    const [aadharno, setaadharno] = useState('');   
    const [villagename, setvillagename] = useState('');
    const [taluka, settaluka] = useState('');
    const [villagecode, setvillagecode] = useState('');
    const [pincode, setpincode] = useState('');
    const [address, setaddress] = useState('');   
    const [whatsappno, setwhatsappno] = useState('');    
    const [bankname, setbankname] = useState('');
    const [accontno, setaccontno] = useState('');
    const [branch, setbranch] = useState('');
    const [bankcode, setbankcode] = useState('');   
    const [dateofestablishment, setdateofestablishment] = useState('');  
    const [aadharphoto, setaadharphoto] = useState();    
    const formdata = new FormData();


    function ValidateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
          {
            return (true)
          }
            alert("You have entered an invalid email address!")
            return (false)
        }

        function validatename(inputtxt)
            {
              var phoneno = /^[a-zA-Z ]{2,30}$/;
              // var no = /^\d{10}$/;
              if(inputtxt.match(phoneno))
                    {
                  return true;
                    }
                  else
                    {
                    alert("You have entered an invalid name!");
                    return false;
                    }
                  } 

          function validatefirstname(inputtxt)
                  {
                    var phoneno = /^[a-zA-Z ]{2,30}$/;
                    // var no = /^\d{10}$/;
                    if(inputtxt.match(phoneno))
                          {
                        return true;
                          }
                        else
                          {
                          alert("You have entered an invalid first name!");
                          return false;
                          }
                        }
                        function validatelastname(inputtxt)
                        {
                          var phoneno = /^[a-zA-Z ]{2,30}$/;
                          // var no = /^\d{10}$/;
                          if(inputtxt.match(phoneno))
                                {
                              return true;
                                }
                              else
                                {
                                alert("You have entered an invalid last name!");
                                return false;
                                }
                              }                   
                

        function phonenumber(inputtxt)
            {
              var phoneno = /^[6-9]\d{9}$/;
              // var no = /^\d{10}$/;
              if(inputtxt.match(phoneno))
                    {
                  return true;
                    }
                  else
                    {
                    alert("You have entered an invalid mobile number!");
                    return false;
                    }
              
          //   var regex = new RegExp("^[0-9\b]+$");
          //   var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
          //   console.log(e.target.value.length)
          //   // for 10 digit number only
          //   if (e.target.value.length > 9) {
          //     e.preventDefault();
          //     return e.target.value;
          // }
          //   if (e.charCode < 54 && e.charCode > 47) {              
          //       if (e.target.value.length == 0) {
          //           e.preventDefault();
          //           return false;
          //       } else {                                    
          //           return e.target.value;
          //       }

          //   }
          //   if (regex.test(str)){               
          //       return e.target.value;
          //   }
            
          //   e.preventDefault();            
          //   return false;
       
            }
    const Onsubmit = async(e) =>{
      formdata.append("ownername", ownername);
      formdata.append("firmname", firmname);
      formdata.append("email", email);
      formdata.append("password", password);      
      formdata.append("gstno", gstno);    
      formdata.append("aadharno", aadharno);      
      formdata.append("villagename", villagename);
      formdata.append("taluka", taluka);
      formdata.append("villagecode", villagecode);
      formdata.append("districtname", city);
      formdata.append("state", statecode);
      formdata.append("country", countrycode);
      formdata.append("pincode", pincode);
      formdata.append("address", address);
      formdata.append("contactno", no);      
      formdata.append("whatsappno", whatsappno);
      formdata.append("bankname", bankname);
      formdata.append("accontno", accontno);
      formdata.append("branch", branch);
      formdata.append("bankcode", bankcode);   
      formdata.append("dateofestablishment", dateofestablishment);  
      formdata.append("aadharphoto", aadharphoto);
      


        e.preventDefault();       
        if( !email | !password ){
            alert("Please Fill All Field")
        }else{          
       try{              
        
         
        const config ={ 
            url:"/vendor/signup",
            method:'post',
            baseURL:apiURL,
            data: formdata,
            // headers:{ 'content-type': 'application/json'},
            // data:{name: name, 
            //     email:email, 
            //     password:password, 
            //     cPassword: confirmPassword, 
            //     phoneNumber:no ,                 
            //     doorno:houseno,
            //     addressline:addressline,
            //  //    addressType:addresstype,
            //     area:area,
            //     city:City,
            //     state:state,
            //     country:country,
            //     pincode:Pincode,
            //     gender: gender,
            //     dateofbirth: dob
            // }
            //responseType:"application/json"
          }
        let res = await axios(config); 
        console.log(res.status);            
        if(res.status === 200){
            console.log(res.data);
            alert("Account Created");
            sessionStorage.setItem("user",JSON.stringify(res.data.vendor));
            window.location.assign("/vendor/dashboard");
            // sendotp();
            // localStorage.setItem("userdata",JSON.stringify(res.data.user));
            // localStorage.setItem("token",res.data.token);
            // window.location.assign("/signin");               
            return res;                
        }else{
          alert("Email or Mobile Already Exist");
          console.log(res.data.error);
        }
            
    }catch(error){     
      console.log(error.response);
      if(error.response){
      alert(error.response.data.error);
    }
    }
    }
    }

    return (
        <div className="" style={{background:"#F4F4F4"}}>
             
            {/* <Row className="me-0" ><Col lg={1} md={1}> </Col>
            <Col lg={10} md={10}> 
            <Card  className="mt-5 mb-5" >
            <Card.Body>
            <h5 className="pl-3 pb-4">Sign Up</h5>
            <Form validated>
            
            <Row>           
            <Col lg={6} md={6}>
            <Form.Group controlId="formBasic">
                <Form.Label>Name<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="Name" onChange={(e)=>setName(e.target.value)} required/>     
            </Form.Group>
            </Col>   
            <Col lg={6} md={6}> 
            <Form.Group controlId="formBasic">
                <Form.Label>Email<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="Email"  onChange={(e)=>setEmail(e.target.value)} required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}> 
            <Form.Group controlId="formBasic">
                <Form.Label>Mobile Number<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="Mobileno" value={no} />     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}> 
            <Form.Group controlId="formBasic">
                <Form.Label>Password<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="password" onChange={(e)=>setPassword(e.target.value)} required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}> 
            <Form.Group controlId="formBasic">
                <Form.Label>Confirm Password<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="confirmpassword" onChange={(e)=>setConfirmPassword(e.target.value)} required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}> 
            <Form.Group controlId="formBasic">
                <Form.Label>Gender<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control as="select" name="Gender"onChange={(e) => setgender(e.target.value)} required>
                <option value="">Select the Gender</option>    
                <option value="Male">Male</option>
                <option value="Female">Female</option>
            </Form.Control>        
            </Form.Group>
            </Col>
            <Col lg={6} md={6}> 
            <Form.Group controlId="formBasic">
                <Form.Label>Date of Brith<span style={{color:"red"}}>*</span></Form.Label>
                <div>
                <DatePicker
                      style={{ width:"524px", height:"38px"}}                     
                      format="DD/MM/YYYY"                                         
                      type="input-icon"
                      maxDate={new Date()}                                             
                      onChange={date =>setdob(date.format("DD/MM/YYYY"))}
                      />  
                 </div>        
            </Form.Group>
            </Col>           
            <Col lg={12} md={12}>
            <h5 className="pl-2 pb-3">Address</h5>
            </Col>
            <Col lg={6} md={6}> 
            <Form.Group controlId="formBasic">
                <Form.Label>Apartment<span style = {{color:"red"}}>*</span></Form.Label>
                <Form.Control as="select" name="apartment"onChange={(e) => setapartment(e.target.value)} required>
                <option value="">Select the type</option>    
                <option value="house">Individual House</option>
                <option value="apartment">Apartment</option>
            </Form.Control>        
            </Form.Group>
            </Col>
            {apartment == "apartment" ? 
            <Col lg={6} md={6}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Apartment Name<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="name"onChange={(e) => setapartmentname(e.target.value)} placeholder="Enter House Number"  required/>     
            </Form.Group>
            </Col> : " "}
            <Col lg={6} md={6}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Door No<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="Houseno"onChange={(e) => sethouseno(e.target.value)} placeholder="Enter House Number"  required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Address<span style={{color:"red"}}>*</span> </Form.Label>
                <Form.Control type="text" name="Addressline1"onChange={(e) => setaddressline(e.target.value)} placeholder="Enter Address"  required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}>            
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Area<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="Area"onChange={(e) => setarea(e.target.value)} placeholder="Enter Area"  required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>City<span style={{color:"red"}}>*</span> </Form.Label>
                <Form.Control type="text" name="City"onChange={(e) => setCity(e.target.value)} placeholder="Enter City" required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>State <span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="text" name="State"onChange={(e) => setState(e.target.value)} placeholder="Enter State"  required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Country<span style={{color:"red"}}>*</span> </Form.Label>
                <Form.Control type="text" name="Country"onChange={(e) => setcountry(e.target.value)} placeholder="Enter Country" required/>     
            </Form.Group>
            </Col>
            <Col lg={6} md={6}>            
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Pincode<span style={{color:"red"}}>*</span></Form.Label>
                <Form.Control type="pincode" name="Pincode"onChange={(e) => setPincode(e.target.value)} placeholder="Enter pincode" required/>     
            </Form.Group>
            </Col>                
            <br/>
            <Col lg={12} md={12}> 
            <Button className="text-right"   variant="success" type="submit" onClick={Onsubmit}>
                Submit
            </Button>
            </Col>
            </Row>


            </Form>
            </Card.Body>
            </Card>
            </Col>
            <Col lg={1} md={1}> </Col>
            </Row> */}

            <Row className="me-0" ><Col lg={1} md={1}> </Col>
            <Col lg={10} md={10}> 
            <Card  className="mt-5 mb-5" >
            <Card.Body>
            <h5 className="pl-3 pb-4">Sign Up</h5>
            <Form validated>

            <div className="row me-0">
      {/* <form action="http://www.mitrakart.com/distributor/pview/signup" method="post" enctype="multipart/form-data"> */}
      <div className="col-md-4 form-group">
          <label>Owner Name</label>
          <input type="text" name="owner_name" className="form-control" placeholder="Owner Name" onChange={(e)=>setownername(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>Firm Name</label>
          <input type="text" name="firm_name" className="form-control" placeholder="Firm Name" onChange={(e)=>setfirmname(e.target.value)} required />
        </div>        
        <div className="col-md-4 form-group">
          <label>GST No.</label>
          <input type="text" name="gst_no" className="form-control" placeholder="GST No." onChange={(e)=>setgstno(e.target.value)} required /> 
        </div>
        <div className="col-md-4 form-group">
          <label>Aadhar No.</label>
          <input type="text" name="aadhar_no" className="form-control" placeholder="Aadhar No." onChange={(e)=>setaadharno(e.target.value)} required />
        </div>        
        <div className="col-md-4 form-group">
          <label>Village name</label>
          <input type="text" name="village_name" className="form-control" placeholder="Village name" onChange={(e)=>setvillagename(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>Taluka</label>
          <input type="text" name="taluka" className="form-control" placeholder="Taluka" onChange={(e)=>settaluka(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>Village code</label>
          <input type="text" name="village_code" className="form-control" placeholder="Village code" onChange={(e)=>setvillagecode(e.target.value)} required />
        </div>
        {/* <!-- <div className="col-md-4 form-group">
          <label>State</label>
          <select name="state" className="form-control" required>
            <option value="Karnataka">Karnataka</option>
            <option value="Maharastra">Maharastra</option>
            <option value="Tamil">Tamil</option>
          </select>
        </div>
        <div className="col-md-4 form-group">
          <label>District Name</label>
          <select name="district_name" className="form-control" required>
            <option value="Bangalore">Bangalore</option>
            <option value="Mysore">Mysore</option>
          </select>
        </div> --> */}
        <div className="col-md-4 form-group">
          <label>Country</label>
          <select className="form-control" name="student_country" id="countryId" onChange={(e)=>setcountrycode(e.target.value)} required="">
              <option value="">Select Country</option>
              {countriesList.map((country)=>(
                      <option value={country.isoCode}>{country.name}</option>
                    ))}
                           
                          </select>
        </div>
        
        <div className="col-md-4 form-group">
          <label>State</label>
          <select name="state" className="form-control" id="stateId" onChange={(e)=>setstatecode(e.target.value)} required>
            <option value="">Select State</option> 
            {statesList.map((state)=>(
                      <option value={state.isoCode}>{state.name}</option>
                    ))} 
          </select>
        </div>
        <div className="col-md-4 form-group">
          <label>District Name</label>
          <select name="district_name" className="form-control"  id="cityDiv" onChange={(e)=>setcity(e.target.value)} required>
            <option value="">Select District</option>
            {cities.map((city)=>(
                      <option value={city.name}>{city.name}</option>
                    ))}
          </select>
        </div>

        <div className="col-md-4 form-group">
          <label>Pincode</label>
          <select type="text" name="pincode" className="form-control" placeholder="Pincode" onChange={(e)=>setpincode(e.target.value)} required>
                           <option selected="" disabled="">Select Post code/ zip</option>
                                                                                                                                            <option>560001</option>
                                                                                                                                            <option>560002</option>
                                                                                                                                            <option>560003</option>
                                                                                                                                            <option>560004</option>
                                                                                                                                            <option>560005</option>
                                                                                                                                            <option>560006</option>
                                                                                                                                            <option>560007</option>
                                                                                                                                            <option>560008</option>
                                                                                                                                            <option>560009</option>
                                                                                                                                            <option>560010</option>
                                                                                                                                            <option>560011</option>
                                                                                                                                            <option>560012</option>
                                                                                                                                            <option>560013</option>
                                                                                                                                            <option>560015</option>
                                                                                                                                            <option>560016</option>
                                                                                                                                            <option>560017</option>
                                                                                                                                            <option>560018</option>
                                                                                                                                            <option>560020</option>
                                                                                                                                            <option>560021</option>
                                                                                                                                            <option>560022</option>
                                                                                                                                            <option>560023</option>
                                                                                                                                            <option>560024</option>
                                                                                                                                            <option>560025</option>
                                                                                                                                            <option>560026</option>
                                                                                                                                            <option>560027</option>
                                                                                                                                            <option>560029</option>
                                                                                                                                            <option>560030</option>
                                                                                                                                            <option>560032</option>
                                                                                                                                            <option>560033</option>
                                                                                                                                            <option>560034</option>
                                                                                                                                            <option>560035</option>
                                                                                                                                            <option>560036</option>
                                                                                                                                            <option>560037</option>
                                                                                                                                            <option>560038</option>
                                                                                                                                            <option>560040</option>
                                                                                                                                            <option>560041</option>
                                                                                                                                            <option>560042</option>
                                                                                                                                            <option>560043</option>
                                                                                                                                            <option>560045</option>
                                                                                                                                            <option>560046</option>
                                                                                                                                            <option>560047</option>
                                                                                                                                            <option>560048</option>
                                                                                                                                            <option>560049</option>
                                                                                                                                            <option>560050</option>
                                                                                                                                            <option>560051</option>
                                                                                                                                            <option>560053</option>
                                                                                                                                            <option>560054</option>
                                                                                                                                            <option>560055</option>
                                                                                                                                            <option>560056</option>
                                                                                                                                            <option>560057</option>
                                                                                                                                            <option>560058</option>
                                                                                                                                            <option>560059</option>
                                                                                                                                            <option>560060</option>
                                                                                                                                            <option>560061</option>
                                                                                                                                            <option>560062</option>
                                                                                                                                            <option>560063</option>
                                                                                                                                            <option>560064</option>
                                                                                                                                            <option>560065</option>
                                                                                                                                            <option>560066</option>
                                                                                                                                            <option>560067</option>
                                                                                                                                            <option>560068</option>
                                                                                                                                            <option>560070</option>
                                                                                                                                            <option>560071</option>
                                                                                                                                            <option>560072</option>
                                                                                                                                            <option>560073</option>
                                                                                                                                            <option>560074</option>
                                                                                                                                            <option>560075</option>
                                                                                                                                            <option>560076</option>
                                                                                                                                            <option>560077</option>
                                                                                                                                            <option>560078</option>
                                                                                                                                            <option>560079</option>
                                                                                                                                            <option>560080</option>
                                                                                                                                            <option>560081</option>
                                                                                                                                            <option>560082</option>
                                                                                                                                            <option>560083</option>
                                                                                                                                            <option>560084</option>
                                                                                                                                            <option>560085</option>
                                                                                                                                            <option>560086</option>
                                                                                                                                            <option>560087</option>
                                                                                                                                            <option>560088</option>
                                                                                                                                            <option>560089</option>
                                                                                                                                            <option>560090</option>
                                                                                                                                            <option>560091</option>
                                                                                                                                            <option>560092</option>
                                                                                                                                            <option>560093</option>
                                                                                                                                            <option>560094</option>
                                                                                                                                            <option>560095</option>
                                                                                                                                            <option>560096</option>
                                                                                                                                            <option>560097</option>
                                                                                                                                            <option>560098</option>
                                                                                                                                            <option>560099</option>
                                                                                                                                            <option>560100</option>
                                                                                                                                            <option>560102</option>
                                                                                                                                            <option>560103</option>
                                                                                                                                            <option>560104</option>
                                                                                                                                            <option>560105</option>
                                                                                                                                            <option>560107</option>
                                                                                                                                            <option>560108</option>
                                                                                                                                            <option>560109</option>
                                                                                                                                            <option>560110</option>
                                                                                                                                            <option>560111</option>
                                                                                                                                            <option>560300</option>
                                                                                                                                            <option>561101</option>
                                                                                                                                            <option>561201</option>
                                                                                                                                            <option>561202</option>
                                                                                                                                            <option>561203</option>
                                                                                                                                            <option>561204</option>
                                                                                                                                            <option>561205</option>
                                                                                                                                            <option>561206</option>
                                                                                                                                            <option>561207</option>
                                                                                                                                            <option>561208</option>
                                                                                                                                            <option>561209</option>
                                                                                                                                            <option>561210</option>
                                                                                                                                            <option>561211</option>
                                                                                                                                            <option>561212</option>
                                                                                                                                            <option>561213</option>
                                                                                                                                            <option>561228</option>
                                                                                                                                            <option>562101</option>
                                                                                                                                            <option>562102</option>
                                                                                                                                            <option>562103</option>
                                                                                                                                            <option>562104</option>
                                                                                                                                            <option>562105</option>
                                                                                                                                            <option>562106</option>
                                                                                                                                            <option>562107</option>
                                                                                                                                            <option>562108</option>
                                                                                                                                            <option>562109</option>
                                                                                                                                            <option>562110</option>
                                                                                                                                            <option>562111</option>
                                                                                             </select>
          {/* <!-- <input type="text" name="pincode" className="form-control" placeholder="Pincode" required> --> */}
        </div>
        <div className="col-md-4 form-group">
          <label>Address </label>
          <input type="text" name="address" className="form-control" placeholder="Address " onChange={(e)=>setaddress(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>Contact No</label>
          <input type="text" name="per_contactNo" className="form-control" placeholder="Contact No" value={no} required />
        </div>        
        <div className="col-md-4 form-group">
          <label>WhatsApp No</label>
          <input type="text" name="whatsapp_no" className="form-control" placeholder="WhatsApp No" onChange={(e)=>setwhatsappno(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>Mail ID</label>
          <input type="email" name="mail_id" className="form-control" placeholder="Mail ID" onChange={(e)=>setemail(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>Password</label>
          <input type="text" name="pwd" className="form-control" placeholder="Password" onChange={(e)=>setpassword(e.target.value)} required />
        </div>        
        <div className="col-md-4 form-group">
          <label>Bank Name</label>
          <input type="text" name="Bank_name" className="form-control" placeholder="Bank Name" onChange={(e)=>setbankname(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>Account Number</label>
          <input type="text" name="Account_no" className="form-control" placeholder="Account Number" onChange={(e)=>setaccontno(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>IFSC Code</label>
          <input type="text" name="ifsc_code" className="form-control" placeholder="IFSC Code" onChange={(e)=>setbankcode(e.target.value)} required />
        </div>
        <div className="col-md-4 form-group">
          <label>Branch </label>
          <input type="text" name="branch_name" className="form-control" placeholder="Branch " onChange={(e)=>setbranch(e.target.value)} required />
        </div>      
        <div className="col-md-4 form-group">
          <label>Date of Establishment </label>
          <input type="text" name="branch_name" className="form-control" placeholder="Date of Establishment" onChange={(e)=> setdateofestablishment(e.target.value)} required />
        </div> 
        <div className="col-md-4 form-group">
          <label for="">Aadhar Photo</label>
          {/* <img id="userImg2" src="http://www.mitrakart.com/distributor/images/upload_photo.jpg" /> */}
           <input  name="aadhar_photo" className="form-input" type="file" onChange={(e)=>setaadharphoto(e.target.files[0])} accept="image/gif, image/jpeg, image/png" required="" />
        </div> 
        <div className="col-md-4 form-group">
          <label for="">&nbsp;</label> 
          <button className="form-control btn btn-primary" onClick={Onsubmit}>Submit</button>
        </div>
      {/* </form> */}
    </div>

    </Form>
            </Card.Body>
            </Card>
            </Col>
            <Col lg={1} md={1}> </Col>
            </Row>
            
        </div>
    )
    
}

export default Vendorsignup
