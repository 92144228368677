import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import axios from "axios";
import { FormGroup } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

function Subcategory() {
  const [isOpen, setIsOpen] = useState(false);
  const apiurl = process.env.REACT_APP_API_URL;
  const [categorydata, setcategorydata] = useState([]);
  const [subcategory, setsubcategory] = useState([]);
  const [categoryName, setcategoryName] = useState("");
  const [subcategoryName, setsubcategoryName] = useState("");
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const formdata = new FormData();
  const [subcategoryimage, setsubcategoryimage] = useState();
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen1, setIsOpen1] = useState(false);
  const [data, setData] = useState({});
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const postsubcategory = async (e) => {
    e.preventDefault();
    if(!categoryName){
      return alert("Please enter brand name!")
    }
    if(!subcategoryimage){
      return alert("Please selce brand logo!")
    }
    formdata.append("BrandName", categoryName);
 
    formdata.append("brandImage", subcategoryimage);
    try {
      const config = {
        url: "/admin/addBrand",
        method: "post",
        baseURL: apiurl,
        // headers: { "content-type": "application/json" },
        // data: { categoryName: categoryName, subcategoryName: subcategoryName },
        data: formdata,
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("Successfully added");
          getsubcategory();
          hideModal()
        }
      });
    } catch (error) {
      console.error(error);
      alert("Not Added");
    }
  };

  const getsubcategory = async () => {
    let res = await axios.get(apiurl + "/getAllBrand");
    if ((res.status = 200)) {
      console.log(res);
      setsubcategory(res.data?.success);
    }
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  //   const [state, setState] = React.useState({
  //     age: "",
  //     name: "hai",
  //   });

  const handleChange = (event) => {
    setcategoryName(event.target.value);
  };

  var i = 1;

  useEffect(() => {
  
    getsubcategory();
  }, []);



  const remove = async () => {
    try {
      axios
        .delete(`${apiurl}/admin/deleteBrand/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            alert("Successfully delted")
            hideModal1()
            getsubcategory();

          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: subcategory.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/brand/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      text:""
    },
    {
      dataField: "BrandName",
      text: "Brand Name",
      sort: true,
   
    },
    {
      dataField: "brandImage",
      text: "Brand Logo",
      formatter:imageFormatter,
     
    },

    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-danger"onClick={()=>{
              showModal1()
              setData(row)
            }}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-3">
      <Button variant="success" onClick={showModal} className="mb-2">
        Add Brand
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Add Brand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <form> */}
          
            <br></br>
            <div className="form-group">
              <label> Brand Name</label>
              <input
                type="text"
                placeholder="Enter Brand Name"
                className="form-control"
                value={categoryName}
                onChange={(e)=>setcategoryName(e.target.value)}
              ></input>
              <label htmlFor="upload1"> Brand Logo</label>
              <input
                type="file"
                placeholder="Enter Country Name"
                className="form-control"
                id="upload1"
                name="file"
                accept="image/*"
                onChange={(e)=>setsubcategoryimage(e.target.files[0])}
              ></input>
            </div>{" "}
            <br></br>
            {/* </form> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postsubcategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
       {/* deleteMode */}
       <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Are you sure delete !</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Cancel
            </Button>
            <Button
              variant="danger"
              style={{ cursor: "pointer" }}
              onClick={() => remove()}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      <div>
        <ToolkitProvider
          keyField="id"
          data={subcategory}
          columns={columns}
          search
          exportCSV={{
            fileName: "patient.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={subcategory}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Subcategory;
