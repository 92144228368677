import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, LoadScript, TrafficLayer } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import { DirectionsService, DirectionsRenderer } from "@react-google-maps/api";
import axios from "axios";
import { useLocation } from "react-router-dom";
import userEvent from "@testing-library/user-event";

const containerStyle = {
  width: "100%",
  height: window.innerHeight,
};

function Customerordermap() {
  const [response, setresponse] = useState("");
  const [d, setd] = useState({});
  const location = useLocation();
  const { data } = location.state;
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const interval = setInterval(() => {
      gettrack();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const gettrack = async () => {
    let res = await axios.get(apiURL + "/gettrackupdate/" + data._id);
    if ((res.status = 200)) {
      console.log(res);
      setd(res.data?.tracking);
    }
  };

  function directionsCallback(res) {
    console.log(res);

    if (res !== null && !response) {
      if (res.status === "OK") {
        setresponse(res);
      } else {
        console.log("res: ", res);
      }
    }
  }

  return (
    <LoadScript googleMapsApiKey="AIzaSyCom4BCfTVsw3o22AYg69Y7EovmLfV_Alw">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: parseFloat(d.lat),
          lng: parseFloat(d.long),
        }}
        zoom={10}
      >
        <DirectionsService
          // required
          options={{
            origin: `${
              data?.addresstype == "apartment" ? data?.apartmentname : ""
            }, ${data?.doorno}, ${data?.addressline}, ${data?.area}, ${
              data?.city
            }, ${data?.state}, ${data?.country}, ${data?.pincode}`,
            destination: { lat: parseFloat(d.lat), lng: parseFloat(d.long) },
            travelMode: "DRIVING",
          }}
          // required
          callback={(res) => directionsCallback(res)}
        />

        {response ? (
          <DirectionsRenderer
            // required
            options={{
              directions: response,
              // markerOptions: {
              //   icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
              // },
              polylineOptions: {
                strokeColor: "red",
                strokeOpacity: 0.5,
                strokeWeight: 4,
              },
              preserveViewport: true,
            }}
          />
        ) : (
          <></>
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default Customerordermap;
