import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function Adminorderview() {
  const location = useLocation();
  const { data } = location.state;
  const [transporter, settransporter] = useState([]);
  const [transporterid, settransporterid] = useState("");
  const [orderdetail, setorderdetail] = useState({});
  const apiURL = process.env.REACT_APP_API_URL;
  console.log(data);

  useEffect(() => {
    getapprovedtransporter();
    getorderdetail();
  }, []);
  const getapprovedtransporter = () => {
    try {
      axios
        .get(`${apiURL}/transporter/alltransporter`)
        .then(function (response) {
          if ((response.status = 200)) {
            console.log(response.data);
            settransporter(response.data.transporter);
            return;
          } else {
            console.log("can't able to fetch");
            alert("can't able to fetch");
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.log(error);
      alert("can't able to fetch");
      return error;
    }
  };
  const reassignorder = async () => {
    try {
      axios
        .post(`${apiURL}/transporterreassign/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");

      return error;
    }
  };
  const getorderdetail = () => {
    try {
      axios
        .get(`${apiURL}/transporterorder/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            // if (response.data?.order !== null) {
            setorderdetail(response.data?.order);
            // }
          } else {
            console.log("can't able to fetch");
            alert("can't able to fetch");
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
        });
    } catch (error) {
      console.log(error);

      return error;
    }
  };
  console.log(transporterid);
  const assignorder = async () => {
    if (!transporterid) {
      alert("please select transporter");
    } else {
      try {
        const config = {
          url: "/assignorder",
          baseURL: apiURL,
          method: "post",
          headers: { "content-type": "application/json" },
          data: {
            transporterId: transporterid,
            orderId: data._id,
            customerId: data.customers[0]._id,
            productId: data.products[0]._id,
            paymentmethod: data.paymentmethod,
            doorno: data.doorno,
            addressline: data.addressline,
            area: data.area,
            city: data.city,
            state: data.state,
            country: data.country,
            pincode: data.pincode,
            total: data.total,
            quantity: data.quantity,
            phoneNumber: data.phonenumber,
            orderdatetime: data.orderdatetime,
            type: "customer",
            transporterno: transporter.find(
              (item) => item._id === transporterid
            ).contactno,
            status: "assigned",
          },
        };

        let res = await axios(config);
        if ((res.status = 200)) {
          console.log(res.data);
          alert("order assigned to the delivery boy");
          window.location.assign("/admin/customerorders");
          return res;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  console.log(
    (Object.keys(orderdetail).length > 0 &&
      data.status === "returntowarehouse") ||
      (Object.keys(orderdetail).length > 0 &&
        orderdetail.transporterstatus === "rejected"),
    Object.keys(orderdetail).length > 0 && data.status === "returntowarehouse",
    Object.keys(orderdetail).length > 0 &&
      orderdetail.transporterstatus === "rejected"
  );
  return (
    <div className="row mt-5">
      <div className="col-md-4">
        <div>Payment Id:
          {/* {data.paymentmethod} */}
        </div>
        <div>Date of Order :
           {/* {data.orderdatetime} */}
        </div>
        <div>Order Status : 
          {/* {data.status} */}
          </div>
      </div>
      <div className="col-md-5"></div>
      <div className="col-md-3">
        <div>Self Pickup / Delivery Address</div>
        {/* <div>{data.customers[0].name}</div> */}

        {/* <div>
          {data?.addresstype == "apartment" ? data?.apartmentname : ""},{" "}
          {data?.doorno}, {data?.addressline}, {data?.area},{data?.city},{" "}
          {data?.state}, {data?.country}, {data?.pincode}
        </div> */}

        <div>Mobile: 
          {/* {data.customers[0].phoneNumber} */}
        </div>

        <div>Email:
           {/* {data.customers[0].email} */}
        </div>
      </div>
      <div className="col=md-12 mt-4">
        <div className="table-responsive">
          <table
            className="table table-bordered"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Products</th>
                <th>Per / Unit </th>
                <th>Quantity</th>
                <th>Sub total</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>1</td>
                {/* <td>{data.products[0].productname}</td> */}
                {/* <td className="test">{data.products[0].customerofferPrice}</td> */}
                {/* <td className="quant">{data.quantity}</td> */}
                {/* <td>
                  {" "}
                  {parseFloat(data.products[0].customerofferPrice).toFixed(2) *
                    parseInt(data.quantity)}
                </td> */}
              </tr>
            </tbody>
          </table>
          <table className="table mt-5">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Order Id</th>
                <th scope="col">Amount</th>
                <th scope="col">GST</th>
                <th scope="col">Delivery Charge</th>
                <th scope="col">Code Discount</th>
                <th scope="col">MOP</th>
                <th scope="col">Paid Amount</th>
                <th scope="col">Due Amount</th>
                <th scope="col">Order Total</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                 {/* <td>{data._id}</td>
                <td>
                  {parseFloat(data.products[0].customerofferPrice).toFixed(2) *
                    parseInt(data.quantity) +
                    (data?.deliverycharge ? parseInt(data.deliverycharge) : 0) -
                    parseFloat(data.promodiscount ?  data.promodiscount : 0).toFixed(2)}
                </td>
                 <td>{data.products[0].tax}</td>
                <td>{data.deliverycharge}</td>
                <td>-{data.promodiscount}</td>
                <td>{data.paymentmethod}</td> */}
                {/* <td>
                  {parseFloat(data.products[0].customerofferPrice).toFixed(2) *
                    parseInt(data.quantity) +
                    (data?.deliverycharge ? parseInt(data.deliverycharge) : 0) -
                    parseFloat(data.promodiscount ? data.promodiscount : 0).toFixed(2)}
                </td> */}
                {/* <td>0</td>
                <td>
                  {parseFloat(data.products[0].customerofferPrice).toFixed(2) *
                    parseInt(data.quantity) +
                    (data?.deliverycharge ? parseInt(data.deliverycharge) : 0) -
                    parseFloat(data.promodiscount ? data.promodiscount : 0).toFixed(2)}
                </td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          {/* <div className="col-md-3">
            <div className="form-group">
              <label>Transporter</label>
              {Object.keys(orderdetail).length === 0 ? (
                <select
                  className="form-control"
                  name="transporter"
                  onChange={(e) => settransporterid(e.target.value)}
                >
                  <option selected="" disabled="">
                    Select Delivery Boy
                  </option>
                  {transporter.map((transporter) => (
                    <option value={transporter._id}>
                      {transporter.transportername}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  className="form-contol"
                  value={
                    transporter.find(
                      (item) => item._id === orderdetail.transporterId
                    ).transportername
                  }
                />
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Transporter Status</label>
              {Object.keys(orderdetail).length === 0 ? (
                <select className="form-control" name="transporter_status" on>
                  <option value="0">Process</option>
                  <option value="1">In Transit</option>
                  <option value="2">Delivered</option>
                  <option value="3">Undelivered</option>
                  <option value="4">Return to WareHouse</option>
                  <option value="11">Transport Time Updated</option>
                </select>
              ) : (
                <input
                  className="form-contol"
                  // value={orderdetail.transporterstatus}
                />
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>User Status</label>
              {Object.keys(orderdetail).length === 0 ? (
                <select className="form-control" name="status" on>
                  <option value="0">Process</option>
                  <option value="1">In Transit</option>
                  <option value="2">Delivered</option>
                  <option value="3">Undelivered</option>
                </select>
              ) : (
                <input className="form-contol" 
                // value={orderdetail.status} 
                />
              )}
            </div>
          </div> */}
          <div className="col-md-3">
            <div className="form-group">
              {orderdetail.status === "undelivered" ? (
                <>
                  <label>Transporter Remarks</label>
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    value={orderdetail.remark}
                    disabled
                    readOnly
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {orderdetail.status === "rejected" ||
              orderdetail.transporterstatus === "rejected" ? (
                <>
                  {" "}
                  <label>Transporter Remarks</label>
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    value={orderdetail.remark}
                    disabled
                    readOnly
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="form-group pull-right">
              {Object.keys(orderdetail).length === 0 &&
              data.status === "inprocess" ? (
                <button className="btn btn-success" 
                // onClick={assignorder}
                >
                  Assign
                </button>
              ) : Object.keys(orderdetail).length > 0 &&
                orderdetail.transporterstatus === "rejected" ? (
                ""
              ) : (
                <Button variant="outline-info">Assigned</Button>
              )}
              {(Object.keys(orderdetail).length > 0 &&
                data.status === "returntowarehouse") ||
              (Object.keys(orderdetail).length > 0 &&
                orderdetail.transporterstatus === "rejected") ? (
                <button
                  className="btn btn-success ms-2 "
                  // onClick={reassignorder}
                >
                  Reassign
                </button>
              ) : (
                ""
              )}
              <button className="btn btn-primary ms-2">
                <Link
                  to={{
                    pathname: "/admin/customerinvoice",
                    state: { data: data },
                  }}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Print Invoice
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminorderview;
