import { CardContent, CardMedia, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function Notification() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [item, setitem] = useState([]);
  useEffect(() => {
    getnotification();
  }, []);
  const getnotification = async () => {
    let res = await axios.get(apiURL + "/admin/getcustomernotification");
    if ((res.status = 200)) {
      console.log(res);
      setitem(res.data?.notification);
    }
  };
  return (
    <div style={{ marginTop: "2rem", marginBottom: "5rem" }}>
      <h5 className="ms-3 mt-1 mb-2">Notification</h5>
      <div>
        <Container>
          <div className="row ml-2 mr-0">
            {item.length > 0 ? (
              <>
                {item.map((data) => (
                  <div className="col-12 mt-3">
                    <Card sx={{ maxWidth: 300 }}>
                      {data.image ? (
                        <CardMedia
                          component="img"
                          image={`${imageURL}/notification/${data.image}`}
                          alt="green iguana"
                        />
                      ) : (
                        ""
                      )}

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {data.content}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </>
            ) : (
              <h5 className="mt-2 text-center">No Notification found</h5>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Notification;
