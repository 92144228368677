import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "../Styles/slider.css";
import Fakedata from "./FakeData/FakeData";
import { Tooltip } from "antd";
import { Button, Modal } from "antd";

function Slider() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const [products, setproducts] = useState(Fakedata);
  const filterResult = (catitem) => {
    const result = Fakedata.filter((currentdata) => {
      return currentdata.categories === catitem;
    });
    setproducts(result);
  };
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState("");
  const [acc2, setacc2] = useState("");

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Shop By Categories */}
      <section className="common-use">
        <div className="banner-slider">
          <>
            <div
              className="banner-content text-center pt-3 pb-4"
              style={{ fontSize: "30px" }}
            >
              <h3>Shop by category</h3>
            </div>
            <div className="category-slider">
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                <div class="categiries-banner">
                  <img
                    src="/webImages/cat3.webp"
                    alt="Avatar"
                    class="image-fluid cate"
                  />
                  <div class="overlay">
                    <div class="text">Dairy & chesse</div>
                  </div>
                </div>
                <div class="categiries-banner">
                  <img
                    src="/webImages/cat3.webp"
                    alt="Avatar"
                    class="image-fluid cate"
                  />
                  <div class="overlay">
                    <div class="text">Dairy & chesse</div>
                  </div>
                </div>
                <div class="categiries-banner">
                  <img
                    src="/webImages/cat4.avif"
                    alt="Avatar"
                    class="image-fluid cate"
                  />
                  <div class="overlay">
                    <div class="text">Dairy & chesse</div>
                  </div>
                </div>
                <div class="categiries-banner">
                  <img
                    src="/webImages/cat8.jpg"
                    alt="Avatar"
                    class="image-fluid cate"
                  />
                  <div class="overlay">
                    <div class="text">Dairy & chesse</div>
                  </div>
                </div>
                <div class="categiries-banner">
                  <img
                    src="/webImages/cat3.webp"
                    alt="Avatar"
                    class="image-fluid cate"
                  />
                  <div class="overlay">
                    <div class="text">Dairy & chesse</div>
                  </div>
                </div>
                <div class="categiries-banner">
                  <img
                    src="/webImages/cat3.webp"
                    alt="Avatar"
                    class="image-fluid cate"
                  />
                  <div class="overlay">
                    <div class="text">Dairy & chesse</div>
                  </div>
                </div>
              </Carousel>
            </div>
          </>
        </div>
      </section>
      {/* Dummy Image */}
      <section className="common-use">
        <div
          className="dummy"
          style={{
            backgroundImage: `linear-gradient(45deg, rgb(0 163 84 / 26%), rgb(4 132 72 / 27%)), url(/webImages/dealbanner-min.webp)`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            height: "400px",
          }}
        ></div>
      </section>

      {/*Our Products  */}
      <section className="common-use">
        <div className="product">
          <Container>
            <div
              className="banner-content text-center pt-3 pb-4"
              style={{ fontSize: "30px" }}
            >
              <h3>Our products</h3>
            </div>
            <div className="product_hero mb-4">
              <ul>
                <li
                  className={acc ? "active-tab" : ""}
                  onClick={() => {
                    filterResult("SPECIAL PRODUCT");
                    setacc(true);
                    setacc1(false);
                    setacc2(false);
                  }}
                >
                  SPECIAL PRODUCT
                </li>
                <li
                  className={acc1 ? "active-tab" : ""}
                  onClick={() => {
                    filterResult("NEW PRODUCT");
                    setacc(false);
                    setacc1(true);
                    setacc2(false);
                  }}
                >
                  NEW PRODUCT
                </li>
                <li
                  className={acc2 ? "active-tab" : ""}
                  onClick={() => {
                    filterResult("BESTSELLER");
                    setacc(false);
                    setacc1(false);
                    setacc2(true);
                  }}
                >
                  BESTSELLER
                </li>
              </ul>
            </div>
            <div className="products">
              <Row>
                {products.map((items, id) => {
                  return (
                    <Col lg={3} md={3} sm={3} key={id}>
                      <div className="single-product-wrap mb-3">
                        <div className="product-image">
                          <a href="/productdetails" className="pro-img">
                            <img
                              className="img-fluid img1"
                              src={items?.image1}
                              alt=""
                              loading="lazy"
                              style={{ width: "100%", height: "280px" }}
                            />
                          </a>
                          <div className="product-label">
                            <span className="sale-title">
                              {items?.discount}
                            </span>
                            <div className="product-action">
                              <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                                <Tooltip title="WISHLIST">
                                  {" "}
                                  <span class="add-wishlist" id="app-title">
                                    <i class="fa fa-heart"></i>
                                  </span>
                                </Tooltip>
                                <Tooltip title="ADD TO CART">
                                  {" "}
                                  <span class="add-wishlist">
                                    <i class="fa fa-shopping-bag"></i>
                                  </span>
                                </Tooltip>
                                <Tooltip title="QUICKVIEW">
                                  {" "}
                                  <span
                                    class="add-wishlist"
                                    onClick={showModal}
                                  >
                                    <i class="fa fa-eye"></i>
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="product-content mt-4">
                          <div className="product-title mb-1">
                            <a href="/productdetails">{items?.name}</a>
                          </div>
                          <div className="product-ratting mb-1">
                            <span
                              className="spr-badge"
                              id="spr_badge_6820894441621"
                              data-rating="2.0"
                            >
                              <span class="spr-starrating spr-badge-starrating">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </span>
                            </span>
                          </div>
                          <div className="price-box mb-2">
                            <span className="new-price" id="ProductPrice">
                              ₹{items?.price}
                            </span>
                            <span className="old-price" id="ComparePrice">
                              ₹44,00
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Container>
        </div>
      </section>
      {/* Modal */}
      <div className="qui_hero">
      <Modal
        title="Quickview"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="pro-quick">
          <div className="quick-view">
            <div className="quick-view_hero">
              <a href="#">
                <img src="/webImages/1.jpg" className="img-fluid" alt="" />
              </a>
            </div>
          </div>
          <div className="quick-view">
            <div className="hero_quick">
              <div className="product-content ">
                <div className="product-title mb-1">
                  <a href="#">Fruits banana 100% organic</a>
                </div>

                <div className="price-box mb-1">
                  <span className="new-price" id="ProductPrice">
                    ₹550
                  </span>
                  <span className="old-price" id="ComparePrice">
                    ₹44,00
                  </span>
                </div>
                <div className="more-de mb-1">
                  <p className="simple-0">More Details</p>
                  <p className="lorem-09">
                    Lorem Ipsum is simply dummy text of the printing and
                    typ.......
                  </p>
                </div>
                <div className="quick-view-select mb-2">
                  <div className="selector-wrapper">
                    <label>Size :</label>
                    <select
                      class="single-option-selector"
                      data-option="option1"
                      id="product-select-qv-option-0"
                    >
                      <option value="1kg">1kg</option>
                      <option value="2kg">2kg</option>
                      <option value="5kg">5kg</option>
                    </select>
                  </div>
                </div>
                <div className="quick-view-select mb-2">
                  <div className="selector-wrapper">
                    <label>Material :</label>
                    <select
                      class="single-option-selector"
                      data-option="option1"
                      id="product-select-qv-option-0"
                    >
                      <option value="1kg">India</option>
                    </select>
                  </div>
                </div>
                <div className="product-quantity mb-3">
                  <div className="cart-plus-minus">
                    <div className="dec qtybutton">-</div>
                    <input
                      value="1"
                      type="text"
                      name="quantity"
                      min="1"
                      pattern="[0-9]*"
                    />
                    <div className="inc qtybutton">+</div>
                  </div>
                </div>
                <div className="product-action-2">
                  <div className="action-wishlist">
                    <Tooltip title="WISHLIST">
                      {" "}
                      <span class="add-wishlist" id="app-title" style={{opacity:"1"}}>
                        <i class="fa fa-heart"></i>
                      </span>
                    </Tooltip>
                    <Tooltip title="ADD TO CART">
                      {" "}
                      <span class="add-wishlist" style={{opacity:"1"}}>
                        <i class="fa fa-shopping-bag"></i>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      </div>
    </>
  );
}

export default Slider;
