import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import * as ReactBootstrap from "react-bootstrap";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

function Category() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [categorydata, setcategorydata] = useState([]);
  useEffect(() => {
    // getcountry();
    getcategory();
  }, []);
  const catresponsive = {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  };

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getcategory");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.category);
    }
  };
  return (
    <div>
      <div className="container mt-5">
        <h3>Categories</h3>
        <OwlCarousel
          className="owl-theme category-carousel"
          loop
          margin={10}
          navClass
          // items={5}
          responsive={catresponsive}
          autoplay={true}
        >
          {categorydata.map((data) => (
            <div class="item">
              <Link
                to={{
                  pathname: "/retailer/allsubcategorys",
                  state: { data: data },
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={`${imageURL}/category/${data.categoryimage}`}
                  alt=""
                ></img>
                <h6>{data.categoryName}</h6>
              </Link>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
}

export default Category;
