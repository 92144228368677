import React, { useEffect, useState } from "react";
import "../../Components/customer/Styles/style12.css";
import OwlCarousel from "react-owl-carousel";
import Fakedata from "../customer/components/FakeData/FakeData";
import axios from "axios";
import { Link } from "react-router-dom";

function DistributorNewArrivals() {
  const [products, setproducts] = useState([]);
  const newGetProducts = async () => {
    try {
      const config = {
        url: "/letestProduct",
        method: "get",
        baseURL: "https://spilso.in/api/admin",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setproducts(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  useEffect(() => {
    newGetProducts();
  }, []);
  useEffect(() => {
    newGetProducts();
  }, []);
  return (
    <>
      <section className="categories" style={{ padding: "0px 40px" }}>
        <div class="container">
          <div class="section-title">
            <h2>
              New Arrivals
              <Link to="/distributor/distributor-product" style={{ fontSize: "15px" }}>
                <span>Views all</span>
              </Link>
            </h2>
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={5}
            dots={false}
          >
            {products?.map((items) => {
              return (
                <div class="item" key={items?.toString()}>
                  <div Layout class="row featured__filter">
                    <div class=" mix oranges fresh-meat ghjk">
                      <div class="featured__item m-0">
                        <div class="bg-0_0">
                          <Link to={`/distributor/distributor-product/${items?._id}`}>
                            <img
                              src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                              alt=""
                              style={{ width: "100%", height: "200px" }}
                            />
                          </Link>
                        </div>
                        <div
                          className="product-label"
                          style={{ float: "right" }}
                        >
                          <span className="sale-title">New</span>
                        </div>
                        {/* <ul class="featured__item__pic__hover">
                                    <li><a href="#"><i class="fa fa-heart"></i></a></li>
                                    <li ><a href="#"><i class="fa fa-eye"></i></a></li>
                                    <li><a href="#"><i class="fa fa-shopping-cart"></i></a></li>
                                </ul> */}
                      </div>
                      <div class="featured__item__text">
                        <h6>
                          {" "}
                          <Link to={`/distributor/distributor-product/${items?._id}`}>
                            {items?.productname}
                          </Link>
                        </h6>
                        <div className="price-box mb-2">
                          <span className="new-price" id="ProductPrice">
                            ₹
                            {Math.round(
                              Number(items?.productprice) +
                                Math.round(
                                  items?.productprice * (items?.tax / 100)
                                ) -
                                (Number(items?.productprice) +
                                  Math.round(
                                    items?.productprice * (items?.tax / 100)
                                  )) *
                                  (items?.distributordiscountpercentage / 100)
                            )}
                          </span>
                          <span className="old-price" id="ComparePrice">
                            ₹
                            {Number(items?.productprice) +
                              Math.round(
                                items?.productprice * (items?.tax / 100)
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
}

export default DistributorNewArrivals;
