import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

function Adminnotification() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [title, settitle] = useState("");
  const [subject, setsubject] = useState("");
  const [type, settype] = useState("");
  const [content, setcontent] = useState("");
  const [image, setimage] = useState("");
  const [video, setvideo] = useState("");
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const postnotification = async () => {
    formdata.append("title", title);
    formdata.append("type", type);
    formdata.append("content", content);
    formdata.append("subject", subject);
    formdata.append("image", image);
    if (!type && !content) {
      alert("please fill all mandatory feilds");
    } else {
      try {
        const config = {
          url: "/admin/addnotification",
          method: "post",
          baseURL: apiURL,
          data: formdata,
        };
        await axios(config).then(function (response) {
          if (response.status === 200) {
            console.log("success");
            alert("Notification Added");
            window.location.reload();
          }
        });
      } catch (error) {
        console.error(error);
        alert("category Name Not Added");
      }
    }
  };
  const remove = async (data) => {
    try {
      axios
        .post(`${apiURL}/admin/deletenotification/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");

      return error;
    }
  };
  useEffect(() => {
    getnotification();
  }, []);

  const getnotification = async () => {
    let res = await axios.get(apiURL + "/admin/getnotification");
    if ((res.status = 200)) {
      console.log(res);
      setdata(res.data?.notification);
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/notification/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }
  const columns = [
    {
      // dataField: "type",
      text: "USERTYPE",
      sort: true,
    },
    {
      // dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      // dataField: "subject",
      text: "Subject",
      sort: true,
    },
    {
      // dataField: "content",
      text: "Content",
      sort: true,
    },
    // {
    //   dataField: "image",
    //   text: "Image",
    //   formatter: imageFormatter,
    // },
    {
      // dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-danger" onClick={() => remove(row)}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-3">
      <div className="d-flex">
        <Button variant="success" onClick={showModal} className=" me-2">
          Add Notification
        </Button>
      </div>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label for="type">
            Select User Type:<span style={{ color: "red" }}>*</span>
          </label>

          <select
            className="ms-5"
            name="type"
            id="type"
            onChange={(e) => settype(e.target.value)}
          >
            {" "}
            <option value="">Select User Type</option>
            <option value="customer">Customer</option>
            <option value="distributor">Distributor</option>
            <option value="retailer">Retailer</option>
            <option value="deliveryhero">Delivery Hero</option>
          </select>
          <div>
            <label className="mt-2">Title</label>
            <input
              type="text"
              placeholder="Title"
              className="form-control"
              onChange={(e) => settitle(e.target.value)}
            ></input>
            <label className="mt-2"> Subject</label>
            <input
              type="text"
              placeholder="Subject"
              className="form-control"
              onChange={(e) => setsubject(e.target.value)}
            ></input>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Content<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setcontent(e.target.value)}
              />
            </Form.Group>
            {/* <label>Image/Video</label>
            <input
              type="file"
              placeholder="Enter Country Name"
              className="form-control"
              onChange={(e) => setimage(e.target.files[0])}
            ></input> */}
          </div>
          <div className="ms-3 mt-3">
            <span style={{ color: "red" }}>*</span>Required Fiels
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postnotification}>
            Save{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
          exportCSV={{
            fileName: "patient.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={data}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Adminnotification;
