import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Styles/cart.css";
function Cart() {
  let customer = JSON.parse(sessionStorage.getItem("customer"));
  const [Carts, setCarts] = useState([]);

  const getCatrDeatils = () => {
    axios
      .get("https://spilso.in/api/getAllCartProductsByCustomer/" + customer?.id)
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const removewCart = async (item) => {
    if (!customer) {
      alert("Need to Login");
    } else {
      try {
        const config = {
          url: "/removeInCartOfCustomer",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            productId: item?.productId?._id,
            customerId: customer?.id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Product removed from wishlist");
          window.location.reload();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };

  const incQuntity = async (item, price, quantity) => {
    try {
      const config = {
        url: "/priceIncAnddec",
        method: "put",
        baseURL: "https://spilso.in/api",
        headers: { "conten-type": "application/json" },
        data: {
          cartId: item?._id,
          quantity: quantity,
          totalPrice: price * quantity,
          // (Number(item?.productId?.productprice)*quantity)+Math.round((item?.productId?.customerofferPrice*quantity)*(item?.productId?.tax/100))-(Math.round((item?.productId?.customerofferPrice*quantity)*(item?.productId?.customerdiscountpercentage/100)))
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getCatrDeatils();
      }
    } catch (error) {}
  };

  const decQuntity = async (item, price, quantity) => {
    try {
      if (quantity !== 0) {
        const config = {
          url: "/priceIncAnddec",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            cartId: item?._id,
            quantity: quantity,
            totalPrice: price * quantity,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          getCatrDeatils();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (customer) {
      getCatrDeatils();
    }
  }, []);

  let subtotal = 0;
  let total = 0;
  let tax = 0;
  if (Carts?.length !== 0) {
    for (let i = 0; i < Carts.length; i++) {
      subtotal =
        subtotal +
        (Carts[i]?.totalPrice -
          Math.round(
            Number(Carts[i]?.price * Carts[i]?.quantity) *
              (Carts[i]?.productId?.tax / 100)
          ));
      total = total + Carts[i]?.totalPrice;
      tax =
        tax +
        Math.round(
          Number(Carts[i]?.price * Carts[i]?.quantity) *
            (Carts[i]?.productId?.tax / 100)
        );
    }
  }

  return (
    <>
      <div className="cart">
        <Container>
          <Row>
            <Col lg={9} md={9}>
              <div className="cart_hero">
                <div className="cart-title">
                  <h2>My Cart:</h2>
                  <div class="cart-count">
                    <span className="bigcounter">{Carts?.length} </span>
                    <span className="cart-item-title">Items</span>
                  </div>
                </div>
                {Carts?.map((items) => {
                  return (
                    <div
                      className="ecommerce_cart"
                      style={{ borderTop: " 1px solid #eeeeee" }}
                    >
                      <div className="item-wrap">
                        <ul className="cart-wrap mt-2">
                          <li className="item-info">
                            <div className="item-img">
                              <Link
                                to={`/single-pages/${items?.productId?._id}`}
                              >
                                <img
                                  src={`https://spilso.in/admin/product/${items?.productId?.productimage[0]}`}
                                  alt="nalla-Image"
                                  className="img-fluid"
                                  style={{ width: "125px", height: "125px" }}
                                />
                              </Link>
                            </div>
                            <div className="item-title">
                              <p className="fresh_hero">
                                {items?.productId?.productname}
                              </p>
                              <p className="item-option">
                                <b>Size:</b> {items?.Size}
                              </p>
                              <p className="item-option">
                                {items?.quantity} {" x "} ₹
                                {Math.round(
                                  Number(items?.price) +
                                    Math.round(
                                      items?.price *
                                        (items?.productId?.tax / 100)
                                    ) -
                                    (Number(items?.price) +
                                      Math.round(
                                        items?.price *
                                          (items?.productId?.tax / 100)
                                      )) *
                                      (items?.productId
                                        ?.customerdiscountpercentage /
                                        100)
                                )}
                              </p>
                            </div>
                          </li>
                          <li className="item-qty">
                            <div className="product-quantity-action">
                              <div className="product-quantity">
                                <div className="cart-plus-minus">
                                  <div
                                    className="dec qtybutton"
                                    onClick={() =>
                                      decQuntity(
                                        items,
                                        Math.round(
                                          Number(items?.price) +
                                            Math.round(
                                              items?.price *
                                                (items?.productId?.tax / 100)
                                            ) -
                                            (Number(items?.price) +
                                              Math.round(
                                                items?.price *
                                                  (items?.productId?.tax / 100)
                                              )) *
                                              (items?.productId
                                                ?.customerdiscountpercentage /
                                                100)
                                        ),
                                        items?.quantity - 1
                                      )
                                    }
                                  >
                                    -
                                  </div>
                                  <input
                                    type="text"
                                    value={items?.quantity}
                                    className="qt"
                                  />
                                  <div
                                    className="inc qtybutton"
                                    onClick={() =>
                                      incQuntity(
                                        items,
                                        Math.round(
                                          Number(items?.price) +
                                            Math.round(
                                              items?.price *
                                                (items?.productId?.tax / 100)
                                            ) -
                                            (Number(items?.price) +
                                              Math.round(
                                                items?.price *
                                                  (items?.productId?.tax / 100)
                                              )) *
                                              (items?.productId
                                                ?.customerdiscountpercentage /
                                                100)
                                        ),
                                        items?.quantity + 1
                                      )
                                    }
                                  >
                                    +
                                  </div>
                                </div>
                                <span className="dec qtybtn"></span>
                                <span className="inc qtybtn"></span>
                              </div>
                            </div>
                            <div className="item-remove  mt-2">
                              <span className="remove-wrap">
                                <Link to="#" onClick={() => removewCart(items)}>
                                  Remove
                                </Link>
                              </span>
                            </div>
                          </li>
                          <li class="item-price">
                            <span class="money amount full-price-34766798487701">
                              {" "}
                              ₹{items?.totalPrice}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col lg={3} md={3}>
              <div className="cart-total-wrap">
                <div className="cart-sub-total">
                  <span className="subtotal-title">Subtotal</span>
                  <span className="amount subtotal">₹{subtotal}.00</span>
                </div>
                <div className="cart-sub-total">
                  <span className="subtotal-title">Tax</span>
                  <span className="amount subtotal">₹{tax}.00</span>
                </div>
                {/* <div className="cart-sub_hero mt-2">
                          <div className="culculate-shipping">Shipping Charge</div>
                      </div>
                      <div className="country mb-2">
                          <label>Country</label>
                          <input type="text" value="India" className="country_hero"/>

                      </div> */}
                {/* <div className="country mb-2">
                          <label>State</label>
                          <input type="text" value="" className="country_hero"/>
                          
                      </div> */}
                {/* <div className="country mb-2">
                          <label>Coupon code</label>
                          <input type="text" value="" placeholder="Coupon code" className="country_hero"/>
                          
                      </div> */}
                <div className="cart-sub-total">
                  <span className="subtotal-title">Total</span>
                  <span className="amount subtotal">₹{total}.00</span>
                </div>
                <a href="/checkout" class="btn btn-style1">
                  Proceed to Checkout
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Cart;
