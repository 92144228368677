import React from "react";
import { Link, useLocation } from "react-router-dom";

function Retailerorderview() {
  const location = useLocation();
  const { data } = location.state;
  console.log(data);
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4">
          <div>Payment Id:{data.paymentmethod}</div>
          <div>Date of Order : {data.orderdatetime}</div>
          <div>Order Status : {data.status}</div>
        </div>
        <div className="col-md-5"></div>
        <div className="col-md-3">
          <div>Self Pickup / Delivery Address</div>
          <div>{data.customers[0]?.name}</div>

          <div>
            {data?.addresstype == "apartment" ? data?.apartmentname : ""},{" "}
            {data?.doorno}, {data?.addressline}, {data?.area},{data?.city},{" "}
            {data?.state}, {data?.country}, {data?.pincode}
          </div>

          <div>Mobile: {data.customers[0]?.phoneNumber}</div>

          <div>Email: {data.customers[0]?.email}</div>
        </div>
        <div className="col=md-12 mt-4">
          <div className="table-responsive">
            <table
              className="table table-bordered"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Product</th>
                  <th>Per / Unit </th>
                  <th>Quantity</th>
                  <th>Sub total</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>{data.product[0].productname}</td>
                  <td className="test">{data.product[0].customerofferPrice}</td>
                  <td className="quant">{data.quantity}</td>
                  <td>{data.product[0].customerofferPrice}</td>
                </tr>
              </tbody>
            </table>
            <table className="table mt-5">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Order Id</th>
                  <th scope="col">Amount</th>
                  <th scope="col">GST</th>
                  <th scope="col">Delivery Charge</th>
                  <th scope="col">MOP</th>
                  <th scope="col">Paid Amount</th>
                  <th scope="col">Due Amount</th>
                  <th scope="col">Order Total</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>{data.product[0].customerofferPrice}</td>
                  <td>{data.product[0].tax}</td>
                  <td>0</td>
                  <td>cod</td>
                  <td>{data.product[0].customerofferPrice}</td>
                  <td>0</td>
                  <td>{data.product[0].customerofferPrice}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3">
              {/* <form action="http://mitrakart.com/crm/Orders/customertransporter/1" method="post" enctype="multipart/form-data" className="form" > */}
              {/* <div className="form-group">
                <label>Transporter</label>
                <select className="form-control" name="transporter">
                  <option selected="" disabled="">
                    Select Transporter
                  </option> */}
                  {/* <!--<option selected="Courier">Courier</option>-->
                                  <!-- <option value="0">Processing</option> 
                                  <option value="1">Delivered</option>
                                  <option value="2">Out for Delivery</option> -->*/}
                  {/* <option value="1" >jaibajrang</option>
                                                                  <option value="2"  >test</option>
                                                                  <option value="3"  ></option> */}
                {/* </select>
              </div> */}
              {/* </form> */}
            </div>
            {/* <div className="col-md-3">
              <div className="form-group">
                <label>Transporter Status</label>
                <select className="form-control" name="transporter_status" on>
                  <option value="0">Process</option>
                  <option value="1">In Transit</option>
                  <option value="2">Delivered</option>
                  <option value="3">Undelivered</option>
                  <option value="4">Return to WareHouse</option>
                  <option value="11">Transport Time Updated</option>
                </select>
              </div>
            </div> */}
            {/* <div className="col-md-3">
              <div className="form-group">
                <label>User Status</label>
                <select className="form-control" name="status" on>
                  <option value="0">Process</option>
                  <option value="1">In Transit</option>
                  <option value="2">Delivered</option>
                  <option value="3">Undelivered</option>
                </select>
              </div>
            </div> */}
            <div className="col-md-3 mt-3">
              <div className="form-group pull-right">
                {/* <button className="btn btn-success">Assign</button> */}
                <button className="btn btn-primary ms-2">
                  <Link
                    to={{
                      pathname: "/retailer/retailercustomerinvoice",
                      state: { data: data },
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Print Invoice
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Retailerorderview;
