import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

function DistributorOrderslist() {
  const [data, setdata] = useState([]);
  const [nochandata, setnodata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  let DeliveryStatus = "";
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));

  const getretailerOrders = async () => {
    try {
      const response = await axios.get(`${apiURL}/retailer/allorders`);
      if (response.status === 200) {
        setdata(
          response.data.allretailerOrders.filter(
            (item) => item.retailerId.distributorId === distributor?._id
          )
        );
        setnodata(
          response.data.allretailerOrders.filter(
            (item) => item.retailerId.distributorId === distributor?._id
          )
        );
      }
    } catch (error) {
      console.warn(error);
      alert(error.response.data.error);
      // setdatacondition(true);
      // return error;
    }
  };

  useEffect(() => {
    if (!distributor) {
      window.location.assign("/distributor/login");
      alert("Please login");
    } else {
      getretailerOrders();
    }
  }, []);
  const filterDeliveryStatus = (ab) => {
    if (!ab) {
      setdata(nochandata);
    } else {
      setdata(nochandata.filter((item) => item.status == ab));
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/retailers/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "_id",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "retailerId._id",
      text: "Retailer Id",
      sort: true,
    },
    {
      dataField: "retailerId.partnersname",
      text: "Retailer Name",
      sort: true,
    },
    {
      // dataField: "allProduct.productId._id",
      text: "Product Id",
      sort: true,
      formatter: (cell, row) => {
        let arr = [];
        row.allProduct.forEach((item) => {
          arr.push(item?.productId._id);
        });
        return <>{arr.join(", ")} </>;
      },
    },
    {
      // dataField: "allProduct.productId.productname",
      text: "product Name",
      sort: true,
      formatter: (cell, row) => {
        let arr = [];
        row.allProduct.forEach((item) => {
          arr.push(item?.productId.productname);
        });
        return <>{arr.join(", ")} </>;
      },
    },
    {
      dataField: "payid",
      text: "Transaction Id",
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return (
      //     <div>
      //       {row.paymentmethod !== "online"
      //         ? row.paymentmethod.toUpperCase()
      //         : row.paymentId}
      //     </div>
      //   );
      // },
    },
    {
      dataField: "paymentmethod",
      text: "Payment Method",
    },
    {
      dataField: "allTotal",
      text: "Paid Amount",
      // formatter: (cell, row) => {
      //   return (
      //     <span>
      //       {parseFloat(row.products[0].retailerofferPrice).toFixed(2) *
      //         parseInt(row.quantity) +
      //         (row?.deliverycharge ? parseInt(row.deliverycharge) : 0) -
      //         parseFloat(row.promodiscount ? row.promodiscount : 0).toFixed(2)}
      //     </span>
      //   );
      // },
    },
    {
      // dataField: "retailerOrderdatetime",
      text: "Placed On",
      formatter: (cell, row) => {
        return <>{moment(row?.retailerOrderdatetime).format("DD-MM-YYYY")}</>;
      },
    },
    // {
    //   // dataField: "duedatetime",
    //   text: "Due Date",
    // },
    {
      dataField: "status",
      text: "Order status",
    },
    {
      // dataField: "address[0].doorno",
      text: "Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row?.addresstype == "apartment" ? row?.apartmentname : ""}{" "}
            {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
            {row?.state}, {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    // {
    // dataField: "",
    // text: "Status",
    // formatter: (cell, row) => {
    //   console.log(row);
    //   return (
    //     <div>
    //       <button className="btn btn-info">
    //         <Link
    //           to={{
    //             pathname: "/admin/retailerorderview",
    //             state: { data: row },
    //           }}
    //           style={{ textDecoration: "none", color: "black" }}
    //         >
    //           View
    //         </Link>
    //       </button>
    //     </div>
    //   );
    // },
    // },

    // {
    // dataField: "",
    // text: "Action",
    // formatter: (cell, row) => {
    //   console.log(row);
    //   return (
    //     <div>
    //       <button className="btn btn-info">
    //         <Link
    //           to={{ pathname: "/admin/orderview", state: { data: row } }}
    //           style={{ textDecoration: "none", color: "black" }}
    //         >
    //           Accept
    //         </Link>
    //       </button>
    //       <button className="btn btn-danger" style={{ margin: "7px 0px" }}>
    //         Cancel
    //       </button>
    //     </div>
    //   );
    // },
    // },
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c", display: "flex" }}>
          Retailer Orders
          {/* <Link to="/admin/retailerorders"> */}
          <button
            className="btn btn-primary"
            style={{ margin: "0px 10px" }}
            onClick={() => filterDeliveryStatus("")}
          >
            All
          </button>
          {/* </Link> */}
          {/* <Link to="/admin/rinprocess"> */}
          <button
            className="btn btn-primary"
            style={{ margin: "0px 10px" }}
            onClick={() => {
              filterDeliveryStatus("inprocess");
            }}
          >
            Inprocess
          </button>
          {/* </Link> */}
          {/* <Link to="/admin/routfordelivery"> */}
          <button
            className="btn btn-warning"
            style={{ margin: "0px 10px" }}
            onClick={() => {
              filterDeliveryStatus("outfordelivery");
            }}
          >
            Out For Delivery
          </button>
          {/* </Link> */}
          {/* <Link to="/admin/rdelivered"> */}
          <button
            className="btn btn-success"
            style={{ margin: "0px 10px" }}
            onClick={() => {
              filterDeliveryStatus("delivered");
            }}
          >
            Delivered
          </button>
          {/* </Link> */}
          {/* <Link to="/admin/rcanceled"> */}
          <button
            className="btn btn-danger"
            style={{ margin: "0px 10px" }}
            onClick={() => {
              filterDeliveryStatus("cancelled");
            }}
          >
            Canceled
          </button>
          {/* </Link> */}
        </h1>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="_id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "retailer.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="retailer"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DistributorOrderslist;
