import React, { useEffect, useState } from "react";
import "../../Components/customer/Styles/style12.css"

import { Button } from "react-bootstrap";
import { Tooltip } from "antd";
import {RiArrowDropDownLine} from 'react-icons/ri'
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

function DistributorTrackOrders() {
  const [acce1, setacce1] = useState(true);
  const [acce2, setacce2] = useState();

const text=<span>AWD No : 4535 <br/>
expected date</span>

let distributor=JSON.parse(sessionStorage.getItem("distributor"));


const [allOrder,setOrder]=useState([])

const getAllOrder=async ()=>{
  try {
    let res=await axios.get("https://spilso.in/api/distributor/orders/"+distributor?._id)
    if(res.status===200){
      setOrder(res.data.success)
    }
  } catch (error) {
    console.log(error);
  }
}

useEffect(()=>{
  if(!distributor){
    window.location.assign("/distributor/login");
  }
  getAllOrder()
},[])

  return (
    <>
      <section class="latest-product spad track">
        <div class="container">
          <div class="section-title" >
            <h2>Track Orders</h2>
          </div>
          <div className="track">
            <div className="track_list">
              <ul>
                <li
                  className={`orderlist_00 ${acce1 ? "active-tab-0":"null"} `}
                  onClick={() => {
                    setacce1(true);
                    setacce2(false);
                  }}
                >
                  Placed Orders
                </li>
                <li
                 className={`orderlist_00 ${acce2 ? "active-tab-0":"null"} `}
                  onClick={() => {
                    setacce1(false);
                    setacce2(true);
                  }}
                >
                  Out for Delivery
                </li>
              </ul>
            </div>
          </div>
          {acce1 ? (
            <>
            {allOrder
                ?.filter((ele) => ele?.status === "inprocess")
                ?.map((items) => {
                  return (
              <div className="track_0">
                <div className="track_12">
                  <div className="track_order_list">
                    <ul>
                      <li>
                        ORDER PLACED <br />
                        <span>{moment(items?.createdAt).format("lll")}</span>
                      </li>
                      <li>
                        TOTAL <br />
                        <span> ₹{items?.allTotal}.00</span>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <Tooltip
                          placement="bottom"
                          title={`${items?.name} 
                          ${items?.doorno} 
                          ${items?.area} 
                          ${items?.addressline} 
                          ${items?.city}, ${items?.state}, ${items?.pincode}
                           ${items?.country} 
                           Phone: ${items?.number} `}
                        >
                          SHIP TO
                          <br />
                          <span>Name <RiArrowDropDownLine style={{fontSize:"20px"}}/></span>
                        </Tooltip>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        ORDER ID<br />
                        <span>{items?._id}</span>
                      </li>
                    </ul>
                  </div>
                  <b className="p-2">Inprocess</b>
                  <div className="track_0_8">
                    
                    <div className="image_00">
                      <img
                        src={`https://spilso.in/admin/product/${items?.allProduct[0]?.productId?.productimage[0]}`}
                        alt=""
                        style={{ height: "100px", width: "120px" }}
                      />
                    </div>
                    <div className="con_passage">
                      <div className="a_0_9">
                      {items?.allProduct[0]?.productId?.productcategory}
                              /{items?.allProduct[0]?.productId?.productname}/
                              {items?.allProduct[0]?.quantity}×
                              {items?.allProduct[0]?.Size}/
                              {items?.allProduct[0]?.totalPrice}/payment:
                              {items?.paymentmethod}/{items?.payid}
                      </div>
                      <div className="view_track">
                        <div className="mb-2">
                          <Button>{" "}
                                  <Link
                                    to="/views-orders"
                                    style={{
                                      color: "white",
                                      textDecoration: "none",
                                    }}
                                  >
                                    View Orders
                                  </Link></Button>
                        </div>
                        <div className="mb-2">
                          <Button>Cancel Orders</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              );
            })}
            </>
          ) : (
            <>
            {allOrder
                ?.filter((ele) => ele?.status === "outfordelivery")
                ?.map((items) => {
                  return (
              <div className="track_0">
                <div className="track_12">
                  <div className="track_order_list">
                    <ul>
                      <li>
                        ORDER PLACED <br />
                        <span>{moment(items?.createdAt).format("lll")}</span>
                      </li>
                      <li>
                        TOTAL <br />
                        <span> ₹{items?.allTotal}.00</span>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <Tooltip
                          placement="bottom"
                          title={`${items?.name} 
                          ${items?.doorno} 
                          ${items?.area} 
                          ${items?.addressline} 
                          ${items?.city}, ${items?.state}, ${items?.pincode}
                           ${items?.country} 
                           Phone: ${items?.number} `}
                        >
                          SHIP TO
                          <br />
                          <span>Name <RiArrowDropDownLine style={{fontSize:"20px"}}/></span>
                        </Tooltip>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        ORDER ID<br />
                        <span>{items?._id}</span>
                      </li>
                    </ul>
                  </div>
                  <b className="p-2">Out for  Delivery</b>
                  <div className="track_0_8">
                    <div className="image_00">
                      <img
                        src={`https://spilso.in/admin/product/${items?.allProduct[0]?.productId?.productimage[0]}`}
                        alt=""
                        style={{ height: "100px", width: "120px" }}
                      />
                    </div>
                    <div className="con_passage">
                      <div className="a_0_9">
                      {items?.allProduct[0]?.productId?.productcategory}
                              /{items?.allProduct[0]?.productId?.productname}/
                              {items?.allProduct[0]?.quantity}×
                              {items?.allProduct[0]?.Size}/
                              {items?.allProduct[0]?.totalPrice}/payment:
                              {items?.paymentmethod}/{items?.payid}
                      </div>
                      <div className="view_track">
                        <div className="mb-2">
                        <Tooltip
                          placement="bottom"
                          title={text}>
                          <Button>Track</Button>
                          </Tooltip>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              );
            })}
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default DistributorTrackOrders;
