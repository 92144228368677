import React from "react";

function Customerinvoice() {
  return (
    <div className="my-3">
      <div className="container ">
        <table
          className="my-3 px-3 table table-bordered"
          cellspacing="0"
          cellpadding="4"
          style={{
            padding: "5px",
            borderSpacing: "15px",
          }}
        >
          <tbody>
            <tr>
              <td rowspan="7" colspan="2">
                <img src="logo.png" height="40px" alt="" />
                <br></br>
                <b>
                  <i>IlifeShoppie</i>
                </b>
                <p>
                #562/1 7th Word Kottur Bypass Road,<br></br> Harapanahalli TQ-583131 Ballary Dist
                </p>

                <p>
                  GST IN. {" "}
                </p>
              </td>
              <td colspan="2">
                <b>GST INVOICE</b>
              </td>
              <td rowspan="7" colspan="2">
                <p>
                  <b>
                    <i>Delivery Address</i>
                  </b>
                </p>
                <label>Name: </label>'.$name.'
                <br />
                <label>Address: </label>'.$address.'
                <br />
                <label>Email: </label>'.$email.' <br />
                <label>Phone: </label>'.$phone.'
                <br />
                <br />
                GSTIN:{" "}
              </td>
            </tr>
            <tr>
              <td>Invoice No</td>
              <td>'.$invoice.'</td>
            </tr>
            <tr>
              <td>Invoice Date:</td>
              <td>'.$date.'</td>
            </tr>
            <tr>
              <td>Due Date:</td>
              <td>'.$date.'</td>
            </tr>
            <tr>
              <td>Order No:</td>
              <td>'.$invoice.'</td>
            </tr>
            <tr>
              <td>Order Date</td>
              <td>'.$date.'</td>
            </tr>
            <tr>
              <td>LR No: </td>
              <td>'.$transport.' </td>
            </tr>
          </tbody>
        </table>
        <table
          cellspacing="0"
          cellpadding="4"
          border="1"
          className="my-3 px-3 table table-bordered"
        >
          <thead>
            <tr>
              <th rowspan="1">S No.</th>
              <th rowspan="1">SKU Code</th>
              <th rowspan="1">Product</th>
              <th rowspan="1">HSN Code</th>
              <th rowspan="1">Base Price</th>
              <th rowspan="1">Discount(%)</th>
              <th rowspan="1">Free</th>
              <th rowspan="1">Qty in Units</th>
              <th rowspan="1">SGST</th>
              <th rowspan="1">CGST</th>
              <th rowspan="1">Amount</th>
              <th rowspan="1">Net Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontSize: "12px" }}>
              <td>'.$count.'</td>
              <td>'.$keysku.'</td>
              <td>'.$keypro_name.'</td>
              <td>'.$keyhsn.'</td>
              <td>''</td>
              <td>'.$keydiscount.'</td>
              <td>'.$keyfree.'</td>
              <td>'.$keypro_qnty.'</td>
              <td>''</td>
              <td>''</td>
              <td>''</td>
              <td>'.'</td>
            </tr>

            <tr>
              <th colspan="2">Class</th>
              <th colspan="1">Total</th>
              <th colspan="2">Scheme</th>
              <th colspan="2">Discount</th>
              <th colspan="1">SGST</th>
              <th colspan="1">CGST</th>
              <th colspan="1">Total GST: '.$totalgst.'</th>
              <th colspan="1"></th>
              <th colspan="2">Total: '.$grand_total.'</th>
            </tr>
            <tr>
              <td colspan="9">Payment Mode: '.$payment_mode.'</td>
              <td rowspan="3" colspan="4">
                Delivery:'.$delivery_charge.'
                <br />
                AMOUNT: '.$sum.'
                <br />
                SGST PAYBLE:'.($totalgst/2).'
                <br />
                CGST PAYBLE:'.($totalgst/2).'
                <br />
              </td>
            </tr>
            <tr>
              <td colspan="9">In Words: hcjch</td>
            </tr>
            <tr>
              <td colspan="9">Message</td>
            </tr>
          </tbody>
        </table>

        <table
          cellspacing="0"
          cellpadding="4"
          border="1"
          className="table table-bordered"
        >
          <tbody>
            <tr>
              <td colspan="2" style={{ fontSize: "14px" }}>
                <b>Terms & Conditions</b>
                <br />
                <br />
                All disputes subject to BANGALORE jurisdiction only
                <br />
                for conditions refer www.mitrakart.com
              </td>
              <td colspan="2">
                <b>For Azeurr Aggregate Acess Serrvices Private Limited</b>
                <br />
                <br />
                <br />
                <br />
                Authorised Signatory
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Customerinvoice;
