import React, { useEffect, useState } from "react";
import Fakedata from "../../../Components/customer/components/FakeData/FakeData";
import { Button, Modal } from "antd";
import { Tooltip } from "antd";
import axios from "axios";
import { jsx } from "@emotion/react";
function Featured1() {
  const [products, setproducts] = useState([]);
  const [neproducts, setnewproducts] = useState([]);
  const newGetProducts = async () => {
    try {
      const config = {
        url: "/product",
        method: "get",
        baseURL: "https://spilso.in/api/admin",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setproducts(res.data.products);
        setnewproducts(res.data.products);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const filterResult = (catitem) => {
    if (!catitem) {
      return setproducts(neproducts);
    }
    const result = neproducts?.filter((currentdata) => {
      return currentdata?.productcategory === catitem;
    });
    setproducts(result);
  };
  // console.log(products)

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [categories, setCotegory] = useState([]);
  const getCatory = async () => {
    try {
      const config = {
        url: "/getcategory",
        method: "get",
        baseURL: "https://spilso.in/api/",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setCotegory(res.data.category);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const user = JSON.parse(sessionStorage.getItem("customer"));
  const [showMore, setShowMore] = useState(false);
  const [viewData, setViewdeata] = useState({});
  const [selPrice, setslePrice] = useState(0);
  const [selQuntity, setselQuntity] = useState(0);
  console.log("seleted price==>", selQuntity, selPrice);
  const [image, setImage] = useState("");
  const [quantity, setQuntity] = useState(1);
  const incQuntity = () => {
    setQuntity(quantity + 1);
  };
  const decQuntity = () => {
    if (quantity > 1) {
      setQuntity(quantity - 1);
    }
  };
  const removewishlist = async (item) => {
    if (!user) {
      alert("Need to Login");
    } else {
      axios({
        method: "delete",
        url:
          "https://spilso.in/api/removeWishlistbcustomeryId/" +
          user.id +
          "/" +
          item?._id,
      })
        .then(function (response) {
          //handle success
          console.log(response);
          alert("Product removed from wishlist");
          getwislist();
          window.location.reload();
        })
        .catch(function (error) {
          //handle error
          console.log(error.response.data);
        });
    }
  };
  const addwishlist = async (item) => {
    if (user) {
      try {
        const config = {
          url: "/addWhishList",
          method: "post",
          baseURL: "https://spilso.in/api",
          data: {
            productId: item._id,
            customerId: user.id,
            quantity: quantity,
            price: selPrice ? selPrice : Number(item?.productprice),
            Size: selQuntity
              ? selQuntity + item?.productvolumetype
              : "1" + item?.productvolumetype,
            totalPrice: selPrice
              ? Math.round(
                  (Number(selPrice) +
                    Math.round(selPrice * (item?.tax / 100)) -
                    (Number(selPrice) +
                      Math.round(selPrice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                )
              : Math.round(
                  (Number(item?.productprice) +
                    Math.round(item?.productprice * (item?.tax / 100)) -
                    (Number(item?.productprice) +
                      Math.round(item?.productprice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                ),
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            console.log("success");
            alert("Added to wishlist");
            window.location.reload();
          }
        });
      } catch (error) {
        console.log(error);
        alert("product not added");
      }
    } else {
      alert("Need to Login");
    }
  };
  const addCart = async (item) => {
    if (user) {
      try {
        const config = {
          url: "/addToCartCustomer",
          method: "post",
          baseURL: "https://spilso.in/api",
          data: {
            productId: item._id,
            customerId: user.id,
            quantity: quantity,
            price: selPrice ? selPrice : Number(item?.productprice),
            Size: selQuntity
              ? selQuntity + item?.productvolumetype
              : "1" + item?.productvolumetype,
            totalPrice: selPrice
              ? Math.round(
                  (Number(selPrice) +
                    Math.round(selPrice * (item?.tax / 100)) -
                    (Number(selPrice) +
                      Math.round(selPrice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                )
              : Math.round(
                  (Number(item?.productprice) +
                    Math.round(item?.productprice * (item?.tax / 100)) -
                    (Number(item?.productprice) +
                      Math.round(item?.productprice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                ),
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            console.log("success");
            alert("Added to Cart");
            window.location.reload();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    } else {
      alert("Need to Login");
    }
  };

  const [wishlist, setwishlist] = useState([]);
  const getwislist = () => {
    axios
      .get("https://spilso.in/api/getWishlistByCustomerId/" + user?.id)
      .then(function (response) {
        console.log(response.data);
        setwishlist(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [Carts, setCarts] = useState([]);

  const getCatrDeatils = () => {
    axios
      .get("https://spilso.in/api/getAllCartProductsByCustomer/" + user?.id)
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const removewCart = async (item) => {
    if (!user) {
      alert("Need to Login");
    } else {
      try {
        const config = {
          url: "/removeInCartOfCustomer",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            productId: item?._id,
            customerId: user?.id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Product removed from wishlist");
          window.location.reload();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };
  useEffect(() => {
    getCatory();
    newGetProducts();
    if (user) {
      getCatrDeatils();
      getwislist();
    }
  }, []);
  return (
    <>
      <section class="featured spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title">
                <h2>Our Products</h2>
              </div>
              <div class="featured__controls">
                <ul>
                  <li
                    onClick={() => {
                      filterResult("");
                    }}
                  >
                    All
                  </li>
                  {categories?.map((ele) => {
                    return (
                      <li
                        onClick={() => {
                          filterResult(ele?.categoryName);
                        }}
                      >
                        {ele?.categoryName}
                      </li>
                    );
                  })}

                
                </ul>
              </div>
            </div>
          </div>

          <div Layout class="row featured__filter">
            {products
              ?.filter((ele) => ele?.remainingstock > ele?.minimumStock)
              ?.map((items, id) => {
                return (
                  <div class="col-lg-2 col-md-4 col-6 mix oranges fresh-meat">
                    <div class="featured__item rty">
                      <div
                        class="featured__item__pic set-bg"
                        // data-setbg="img/featured/feature-1.jpg"
                        // style={{
                        //   backgroundImage: `url(https://spilso.in/admin/product/${items?.productimage[0]})`,
                        // }}
                      >
                        <img
                          src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                          alt="hdwh"
                          style={{ width: "100%", height: "151px" }}
                        />
                        <div
                          className="product-label"
                          style={{
                            float: "right",
                            position: "absolute",
                            top: "4px",
                            right: "1%",
                          }}
                        >
                          <span className="sale-title">
                            {items?.customerdiscountpercentage}%
                          </span>
                        </div>
                        <ul class="featured__item__pic__hover">
                          {wishlist?.filter(
                            (item) => item?.productId?._id === items._id
                          )?.length ? (
                            <li>
                              <a href="#" onClick={() => removewishlist(items)}>
                                <i
                                  class="fa fa-heart"
                                  style={{ color: "Green" }}
                                ></i>
                              </a>
                            </li>
                          ) : (
                            <li>
                              <a href="#" onClick={() => addwishlist(items)}>
                                <i class="fa fa-heart"></i>
                              </a>
                            </li>
                          )}

                          <li
                            onClick={() => {
                              showModal();
                              setViewdeata(items);
                              setQuntity(1);
                              setslePrice(0);
                              setselQuntity(0);
                              setImage(items?.productimage[0]);
                            }}
                          >
                            <a href="#">
                              <i class="fa fa-eye"></i>
                            </a>
                          </li>
                          {Carts?.filter(
                            (item) => item?.productId?._id === items._id
                          )?.length ? (
                            <li>
                              <a href="#" onClick={() => removewCart(items)}>
                                <i
                                  class="fa fa-shopping-cart"
                                  style={{ color: "red" }}
                                ></i>
                              </a>
                            </li>
                          ) : (
                            <li>
                              <a href="#" onClick={() => addCart(items)}>
                                <i class="fa fa-shopping-cart"></i>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div class="featured__item__text">
                        <h6>
                          {" "}
                          <a href={`/single-pages/${items?._id}`}>
                            {items?.productname}
                          </a>
                        </h6>
                        <div className="price-box mb-2">
                          <span className="new-price" id="ProductPrice">
                            ₹
                            {Math.round(
                              Number(items?.productprice) +
                                Math.round(
                                  items?.productprice * (items?.tax / 100)
                                ) -
                                (Number(items?.productprice) +
                                  Math.round(
                                    items?.productprice * (items?.tax / 100)
                                  )) *
                                  (items.customerdiscountpercentage / 100)
                            )}
                          </span>
                          <span className="old-price" id="ComparePrice">
                            ₹
                            {Number(items?.productprice) +
                              Math.round(
                                items?.productprice * (items?.tax / 100)
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      {/* Modal */}
      <div className="qui_hero">
        <Modal
          title="Quickview"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="pro-quick">
            <div className="quick-view">
              <div className="quick-view_hero">
                <a href={`/single-pages/${viewData?._id}`}>
                  <img
                    src={`https://spilso.in/admin/product/${image}`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="quick-view">
              <div className="hero_quick">
                <div className="product-content ">
                  <div className="product-title mb-1">
                    <a href={`/single-pages/${viewData?._id}`}>
                      {viewData?.productname}
                    </a>
                  </div>

                  <div className="price-box mb-1">
                    {selPrice !== 0 ? (
                      <span className="new-price" id="ProductPrice">
                        {selQuntity}
                        {viewData?.productvolumetype}
                        {"-"}₹
                        {Math.round(
                          (Number(selPrice) +
                            Math.round(selPrice * (viewData?.tax / 100)) -
                            (Number(selPrice) +
                              Math.round(selPrice * (viewData?.tax / 100))) *
                              (viewData.customerdiscountpercentage / 100)) *
                            quantity
                        )}
                      </span>
                    ) : (
                      <span className="new-price" id="ProductPrice">
                        ₹
                        {Math.round(
                          (Number(viewData?.productprice) +
                            Math.round(
                              viewData?.productprice * (viewData?.tax / 100)
                            ) -
                            (Number(viewData?.productprice) +
                              Math.round(
                                viewData?.productprice * (viewData?.tax / 100)
                              )) *
                              (viewData.customerdiscountpercentage / 100)) *
                            quantity
                        )}
                      </span>
                    )}
                    {selPrice !== 0 ? (
                      <span className="old-price" id="ComparePrice">
                        ₹
                        {Number(selPrice * quantity) +
                          Math.round(
                            selPrice * quantity * (viewData?.tax / 100)
                          )}
                      </span>
                    ) : (
                      <span className="old-price" id="ComparePrice">
                        ₹
                        {Number(viewData?.productprice * quantity) +
                          Math.round(
                            viewData?.productprice *
                              quantity *
                              (viewData?.tax / 100)
                          )}
                      </span>
                    )}
                  </div>
                  <div className="more-de mb-1">
                    <p className="simple-0">More Details</p>
                    <p className="lorem-09">
                      {showMore
                        ? viewData?.productdesc
                        : `${viewData?.productdesc?.substring(0, 110)}`}
                      <button
                        className="btn"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </button>
                    </p>
                  </div>
                  <div className="quick-view-select mb-2">
                    <div className="selector-wrapper">
                      <label>Size :</label>
                      <select
                        class="single-option-selector"
                        data-option="option1"
                        id="product-select-qv-option-0"
                        onChange={(e) => {
                          setslePrice(JSON.parse(e.target.value)?.quntityPrice);
                          setselQuntity(JSON.parse(e.target.value)?.perQuntity);
                        }}
                      >
                        {viewData?.AllPrice?.map((ele) => {
                          return (
                            <option value={JSON.stringify(ele)}>
                              {ele?.perQuntity}
                              {viewData?.productvolumetype}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="quick-view-select mb-2">
                    <div className="selector-wrapper">
                      <label>Discount :</label>
                      <span>{viewData?.customerdiscountpercentage}%</span>
                    </div>
                  </div>

                  <div className="cart-plus-minus">
                    <div className="dec qtybutton" onClick={decQuntity}>
                      -
                    </div>
                    <input
                      value={quantity}
                      type="text"
                      name="quantity"
                      min="1"
                      pattern="[0-9]*"
                    />
                    <div className="inc qtybutton" onClick={incQuntity}>
                      +
                    </div>
                  </div>
                  <br />
                  <div className="product-action-2">
                    <div className="action-wishlist">
                      {wishlist.filter(
                        (item) => item?.productId?._id === viewData._id
                      )?.length ? (
                        <Tooltip
                          title="REMOVE-WISHLIST"
                          onClick={() => removewishlist(viewData)}
                        >
                          {" "}
                          <span
                            id="app-title"
                            style={{
                              opacity: "1",
                              background: "red",
                              color: "white",
                              fontSize: "16px",
                              lineHeight: 0,
                              height: "40px",
                              width: "40px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "50%",
                              marginRight: "15px",
                            }}
                          >
                            <i class="fa fa-heart"></i>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="WISHLIST"
                          onClick={() => addwishlist(viewData)}
                        >
                          {" "}
                          <span
                            class="add-wishlist"
                            id="app-title"
                            style={{ opacity: "1" }}
                          >
                            <i class="fa fa-heart"></i>
                          </span>
                        </Tooltip>
                      )}
                      {Carts?.filter(
                        (item) => item?.productId?._id === viewData._id
                      )?.length ? (
                        <Tooltip
                          title="REMOVE TO CART"
                          onClick={() => removewCart(viewData)}
                        >
                          {" "}
                          <span
                            class="add-wishlist"
                            style={{
                              opacity: "1",
                              background: "red",
                              color: "white",
                              fontSize: "16px",
                              lineHeight: 0,
                              height: "40px",
                              width: "40px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "50%",
                              marginRight: "15px",
                            }}
                          >
                            <i class="fa fa-shopping-bag"></i>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="ADD TO CART"
                          onClick={() => addCart(viewData)}
                        >
                          {" "}
                          <span class="add-wishlist" style={{ opacity: "1" }}>
                            <i class="fa fa-shopping-bag"></i>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Featured1;
