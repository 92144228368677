import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Card, Col, Container, Row } from "react-bootstrap";

function Addaddress() {
  const [houseno, sethouseno] = useState("");
  const [addressline, setaddressline] = useState("");
  const [area, setarea] = useState("");
  const [state, setState] = useState("");
  const [City, setCity] = useState("");
  const [country, setcountry] = useState("");
  const [Pincode, setPincode] = useState("");
  const [apartment, setapartment] = useState("");
  const [apartmentname, setapartmentname] = useState("");
  const apiURL = process.env.REACT_APP_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");
  function pincodecheck(inputtxt) {
    var Pincode = /^[0-9]{6}$/;
    if (inputtxt.match(Pincode)) {
      return true;
    } else {
      alert("You have entered an invalid pincode!");
      return false;
    }
  }
  const search = async (e) => {
    e.preventDefault();
    if (
      !houseno |
      !addressline |
      !area |
      !City |
      !state |
      !country |
      !Pincode
    ) {
      alert("Please Fill All The Details");
    } else {
      try {
        if (pincodecheck(Pincode)) {
          axios
            .post(`${apiURL}/searchpincode/` + Pincode)
            .then(function (response) {
              if (response.status === 200) {
                console.log(response.data);
                postaddress();
              }
            })
            .catch(function (error) {
              console.log(error.message.data);
              alert("Service Unavailable");
            });
        }
      } catch (error) {
        console.warn(error);
        alert("Service Unavailable for this pincode");

        return error;
      }
    }
  };
  const postaddress = async () => {
    try {
      const config = {
        url: "/customer/addaddress",
        method: "post",
        baseURL: apiURL,
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          //    houseName:housename,
          customerId: user._id,
          addresstype: apartment,
          apartmentname: apartmentname,
          doorno: houseno,
          addressline: addressline,
          // addressType:addresstype,
          area: area,
          city: City,
          state: state,
          country: country,
          pincode: Pincode,
        },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Address added ");
        window.location.assign("/cart");
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ background: "#F4F4F4" }}>
      <Container>
        <Row>
          <Col lg={1} md={1}>
            {" "}
          </Col>
          <Col lg={10} md={10}>
            <Card className="mt-5 mb-5">
              <Card.Body>
                <h5 className="text-center">Shipping Address</h5>
                <Form validated>
                  <Row>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasic">
                        <Form.Label style={{ fontSize: "17px" }}>
                          Apartment<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="apartment"
                          onChange={(e) => setapartment(e.target.value)}
                          required
                        >
                          <option value="">Select the House</option>
                          <option value="house">Individual House</option>
                          <option value="apartment">Apartment</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {apartment == "apartment" ? (
                      <Col lg={6} md={6}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label style={{ fontSize: "17px" }}>
                            Apartment Name
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            onChange={(e) => setapartmentname(e.target.value)}
                            placeholder="Enter House Number"
                            required
                          />
                        </Form.Group>
                      </Col>
                    ) : (
                      " "
                    )}
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "17px" }}>
                          Door No<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Houseno"
                          onChange={(e) => sethouseno(e.target.value)}
                          placeholder="Enter House Number"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "17px" }}>
                          Address<span style={{ color: "red" }}>*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Addressline1"
                          onChange={(e) => setaddressline(e.target.value)}
                          placeholder="Enter Address"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "17px" }}>
                          Area<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Area"
                          onChange={(e) => setarea(e.target.value)}
                          placeholder="Enter Area"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "17px" }}>
                          City<span style={{ color: "red" }}>*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="City"
                          onChange={(e) => setCity(e.target.value)}
                          placeholder="Enter City"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "17px" }}>
                          State <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="State"
                          onChange={(e) => setState(e.target.value)}
                          placeholder="Enter State"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "17px" }}>
                          Country<span style={{ color: "red" }}>*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="Country"
                          onChange={(e) => setcountry(e.target.value)}
                          placeholder="Enter Country"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "17px" }}>
                          Pincode<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="Pincode"
                          onChange={(e) => setPincode(e.target.value)}
                          placeholder="Enter pincode"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <br />
                    <div style={{ margin: "auto" }}>
                      <Button
                        className="larger"
                        variant="warning"
                        type="submit"
                        onClick={search}
                      >
                        Submit
                      </Button>
                    </div>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={1} md={1}>
            {" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Addaddress;
