import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";

function Essentials() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [essential, setessential] = useState([]);
  const essresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  };

  useEffect(() => {
    getessential();
  }, []);
  const getessential = async () => {
    let res = await axios.get(apiURL + "/getalldailyessentials/customer");
    if ((res.status = 200)) {
      console.log(res);
      setessential(res.data?.dailyessentials);
    }
  };
  return (
    <div>
      <div className="container mt-5">
        <h3 className="text-start">Offers Zone</h3>
        <OwlCarousel
          className="owl-theme essential-carousel"
          loop={true}
          margin={10}
          navClass
          responsive={essresponsive}
        >
          {essential.map((e) => (
            <div class="item">
              <img
                src={`${imageURL}/dailyessentials/${e.dailyessentials}`}
                alt=""
              ></img>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
}

export default Essentials;
