import React, { useEffect, useState } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

function Adminretailerproduct() {
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  const [product, setproduct] = useState([]);

  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let res = await axios.get(apiurl + "/admin/retailerproduct");
    if ((res.status = 200)) {
      console.log(res);
      setproduct(res.data?.products);
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: product.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/subcategory/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "productcategory",
      text: "Category",
      sort: true,
    },
    {
      dataField: "productsubcategory",
      text: "Subcategory",
      sort: true,
    },
    {
      dataField: "productname",
      text: "Product Name",
    },
    {
      dataField: "productprice",
      text: "MRP",
    },
    {
      dataField: "customerofferPrice",
      text: "Customer Offer Price",
    },
    {
      dataField: "customerdiscountpercentage",
      text: "Customer Discount Percentage(%)",
    },

    {
      dataField: "productdesc",
      text: "Product Description",
    },
    {
      dataField: "productfeatures",
      text: "Product Features",
    },
    {
      dataField: "productbrand",
      text: "Product Brand",
    },
    {
      dataField: "totalstock",
      text: "Stock",
    },
    {
      dataField: "remainingstock",
      text: "Remaining Stock",
    },
  ];

  return (
    <div className="mt-3">
      <div className="mt-3">
        <h5 className="mt-2 ms-2">Retailer Products</h5>
        <ToolkitProvider
          keyField="id"
          data={product}
          columns={columns}
          search
          exportCSV={{
            fileName: "Products.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pr-5 mr-auto">
                <ExportCSVButton
                  className="btn-outline-success"
                  {...props.csvProps}
                >
                  Export to CSV
                </ExportCSVButton>
              </span>
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={product}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Adminretailerproduct;
