import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

function Retailercustomerorder() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [pending, setpending] = useState([]);
  const [confirm, setconfirm] = useState([]);
  const [delivered, setdelivered] = useState([]);
  const [cancelled, setcancelled] = useState([]);
  const [t1, sett1] = useState(true);
  const [t2, sett2] = useState(false);
  const [t3, sett3] = useState(false);
  const [t4, sett4] = useState(false);

  useEffect(() => {
    getcustomer();
  }, []);

  const getcustomer = () => {
    try {
      axios
        .get(`${apiURL}/retailer/customerorders/${user._id}`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setpending(response.data.inprocessorders);
            setconfirm(response.data.outfordeliveryorders);
            setdelivered(response.data.deliveredorders);
            setcancelled(response.data.cancelledorders);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const approve = (data) => {
    try {
      axios
        .post(`${apiURL}/retailer/confirm/` + data)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to approve");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to approve");
      // setdatacondition(true);
      return error;
    }
  };

  const assign = (data) => {
    try {
      axios
        .post(`${apiURL}/retailer/assignorder/` + data)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            alert("assigned to ilifeshoppie");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message);
          alert("Not able to assign");
        });
    } catch (error) {
      console.warn(error.message);
      alert("Not able to assign");
      // setdatacondition(true);
      return error;
    }
  };

  const reject = (data) => {
    try {
      axios
        .post(`${apiURL}/retailer/cancel/` + data)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to reject");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to reject");
      // setdatacondition(true);
      return error;
    }
  };

  const complete = (data) => {
    try {
      axios
        .post(`${apiURL}/retailer/delivered/` + data)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: t1
          ? pending.length
          : t2
          ? confirm.length
          : t3
          ? delivered.length
          : t4
          ? cancelled.length
          : data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/customers/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "_id",
      text: "Order Id",
      sort: true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },
    {
      dataField: "",
      text: "Payment Id",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row.paymentmethod !== "online"
              ? row.paymentmethod.toUpperCase()
              : row.paymentId}
          </div>
        );
      },
    },
    {
      dataField: "paymentmethod",
      text: "Payment Method",
    },
    {
      dataField: "total",
      text: "Paid Amount",
    },
    {
      dataField: "orderdatetime",
      text: "Placed On",
    },

    {
      dataField: "address[0].doorno",
      text: "Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row?.addresstype == "apartment" ? row?.apartmentname : ""},{" "}
            {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
            {row?.state}, {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div><button className="btn btn-info" ><Link to={{pathname:"/admin/orderview", state:{ data: row }}} style={{textDecoration:"none",color:"black"}}>View</Link></button></div>;
      // }
    },
  ];

  const inprocesscolumns = [
    {
      dataField: "_id",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "customers[0].name",
      text: "Customer Name",
    },
    {
      dataField: "",
      text: "Payment Id",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>{row?.paymentmethod !== "online" ? "COD" : row?.paymentId}</div>
        );
      },
    },
    {
      dataField: "paymentmethod",
      text: "Payment Method",
    },
    {
      dataField: "product[0].customerofferPrice",
      text: "Paid Amount",
    },
    {
      dataField: "orderdatetime",
      text: "Placed On",
    },
    {
      dataField: "status",
      text: "Order status",
    },
    {
      dataField: "duedatetime",
      text: "Due Date",
    },
    {
      dataField: "address[0].doorno",
      text: "Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row?.addresstype == "apartment" ? row?.apartmentname : ""},{" "}
            {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
            {row?.state}, {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div className="d-flex">
            {row?.ordertype !== "retailerassign" ? (
              <div className="d-flex">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={() => approve(row._id)}
                >
                  Out For Delivery
                </button>{" "}
                <button
                  className="btn btn-danger"
                  onClick={() => assign(row._id)}
                >
                  Assign to IlifeShoppie
                </button>
              </div>
            ) : (
              "Assigned to IlifeShoppie for delivery"
            )}{" "}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Status",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-info">
              <Link
                to={{ pathname: "/retailer/orderview", state: { data: row } }}
                style={{ textDecoration: "none", color: "black" }}
              >
                View
              </Link>
            </button>
          </div>
        );
      },
    },
  ];

  const outfordeliverycolumns = [
    {
      dataField: "_id",
      text: "Order Id",
      sort: true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },
    {
      dataField: "",
      text: "Payment Id",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row.paymentmethod !== "online"
              ? row.paymentmethod.toUpperCase()
              : row.paymentId}
          </div>
        );
      },
    },
    {
      dataField: "paymentmethod",
      text: "Payment Method",
    },
    {
      dataField: "total",
      text: "Paid Amount",
    },
    {
      dataField: "orderdatetime",
      text: "Placed On",
    },

    {
      dataField: "address[0].doorno",
      text: "Address",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row?.addresstype == "apartment" ? row?.apartmentname : ""},{" "}
            {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
            {row?.state}, {row?.country}, {row?.pincode}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row?.ordertype !== "retailerassign" ? (
              <button
                className="btn btn-success"
                onClick={() => complete(row._id)}
              >
                Delivered
              </button>
            ) : (
              "Assigned to IlifeShoppie for delivery"
            )}
          </div>
        );
      },
    },
  ];

  const upcoming = () => {
    sett1(true);
    sett2(false);
    sett3(false);
    sett4(false);
  };

  const ongoing = () => {
    sett1(false);
    sett2(true);
    sett3(false);
    sett4(false);
  };

  const past = () => {
    sett1(false);
    sett2(false);
    sett3(true);
    sett4(false);
  };
  const cancel = () => {
    sett1(false);
    sett2(false);
    sett3(false);
    sett4(true);
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className="container pb-5">
        <h5 className="pt-4 pb-3">Customers orders</h5>
        <div className="pl-4 pb-4 d-flex">
          <button
            className="btn btn-outline-info me-2"
            style={{ fontSize: "13px" }}
            onClick={upcoming}
          >
            Inprocess Orders
          </button>
          <button
            className="btn btn-outline-info me-2"
            style={{ fontSize: "13px" }}
            onClick={ongoing}
          >
            Out for Delivery Orders
          </button>

          <button
            className="btn btn-outline-info me-2"
            style={{ fontSize: "13px" }}
            onClick={past}
          >
            Delivered Orders
          </button>
          <button
            className="btn btn-outline-info"
            style={{ fontSize: "13px" }}
            onClick={cancel}
          >
            Cancelled Orders
          </button>
        </div>
        <div className="container">
          <div className="card card-body">
            {/* <div>
                        <h5 className="pb-3">Latest Orders</h5>
                         <div className="container text-center">
                         <Table responsive hover size="md">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Profile Picture</th>
                                <th>Email Id</th>
                                <th>Mobile No</th>
                                <th>Gender</th>   
                                <th>Address</th>
                                <th>Medical Details</th> 
                                <th>Status</th>                               
                                <th>Action</th>                       
                            </tr>
                            </thead>
                            <tbody>
                                {data?.map((datas)=>(
                                  <tr>
                                  <td>{datas.name}</td>
                                  <td><img src={imageURL +"/customers/profile/" + datas.profileimage} height="50px" width="50px" style={{borderRadius:"100%"}}/></td> 
                                  <td>{datas.email}</td>
                                  <td>{datas.phoneNumber}</td>  
                                  <td>{datas.gender}</td>
                                  <td>{datas?.doorno}, {datas?.address[0]?.addressline}, {datas?.address[0]?.area},{datas?.address[0]?.city}, {datas?.address[0]?.state}, {datas?.address[0]?.country}, {datas?.address[0]?.pincode}</td>
                                  <td>{datas.allergies}</td>
                                  <td>{datas?.details[0]?.status}</td>
                                  <td>{!datas?.details[0]?.blockstatus? <button className="btn btn-outline-success" onClick={()=>block(datas)}>Block</button>:<button className="btn btn-outline-danger" onClick={()=>unblock(datas)}>Un-Block</button>}</td>
                                  <td>{datas.specializations}</td>   
                                  <td>{datas.qualification}</td> 
                                  <td>{datas.experience}</td>  
                                  <td>{datas.idprooftype}</td>   
                                  <td><img src={imageURL +"/doctor/idproof/" + datas.idproof} height="50px" width="50px" style={{borderRadius:"100%"}}/></td>                                                   
                                  <td><div className="d-flex"> <button className="btn btn-outline-success" onClick={()=>approve(datas)}>Approve</button></div></td>
                              </tr>  
                                ))}
                               
                            </tbody>
                          </Table>
                        </div>
                    </div> */}

            <ToolkitProvider
              keyField="id"
              data={
                t1
                  ? pending
                  : t2
                  ? confirm
                  : t3
                  ? delivered
                  : t4
                  ? cancelled
                  : data
              }
              columns={
                t1 ? inprocesscolumns : t2 ? outfordeliverycolumns : columns
              }
              search
              exportCSV={{
                fileName: "customer.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  {/* <span className="pr-5 mr-auto"> 
            <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
            </span>   */}
                  <div>
                    <span className=" ">
                      <SearchBar
                        className="form-control "
                        {...props.searchProps}
                      />
                    </span>
                    <ClearSearchButton
                      className="btn-outline-info"
                      {...props.searchProps}
                    />
                  </div>
                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="customer"
                      responsive
                      hover
                      columns={columns}
                      data={
                        t1
                          ? pending
                          : t2
                          ? confirm
                          : t3
                          ? delivered
                          : t4
                          ? cancelled
                          : data
                      }
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Retailercustomerorder;
