// import { render } from "@testing-library/react";
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
// import logo from "../../../public/img/logo.png";

function Login() {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const apiurl = process.env.REACT_APP_API_URL;

  const onsignin = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/signin",
        method: "post",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: { email: email, password: password },
        // responseType:"application/json"
      };
      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.user.blockstatus) {
              alert("your account is blocked");
            } else {
              console.log("success");
              alert("login successfull");
              sessionStorage.setItem(
                "user",
                JSON.stringify(response.data.user)
              );
              window.location.assign("/admin/dashboard");
            }
          } else {
            alert("Email or Password Wrong");
          }
        })
        .catch(function (error) {
          console.error(error);
          alert("email or password wrong");
        });
    } catch (error) {
      console.error(error);
      alert("Email or Password Wrong");
    }
  };

  return (
    <div className="admin-bg" style={{ padding: "6em 0em" }}>
      <div className="login-form">
        <div className=" justify-content-lg-around">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src="./Spilsologo.png" alt="" style={{ width: "42%" }} />
            <span style={{ paddingLeft: "8px", marginTop: "23px" }}>
              <img
                src="./Spilsologo2.png"
                className="spin"
                alt=""
                style={{ width: "45px", height: "45px" }}
              />
            </span>
          </div>
          <div style={{ padding: "0px 20px" }}>
            <h2 className="text-center text-white mb-3">Admin Login</h2>
            <form>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Enter An Email"
                  className="form-control"
                  onChange={(e) => setemail(e.target.value)}
                ></input>
              </div>{" "}
          
              <div className="form-group">
                <input
                  type="password"
                  placeholder="Enter password"
                  className="form-control"
                  onChange={(e) => setpassword(e.target.value)}
                ></input>
              </div>{" "}
              <br></br>
            </form>
            <Button
              variant="success"
              onClick={onsignin}
              style={{
                border: " 2px solid #ed1c23",
                backgroundColor: "#ed1c23",
                width:"100%"
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
