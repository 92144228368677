import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-multi-date-picker";

function Adminpromocode() {
  const [data, setdata] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [categorydata, setcategorydata] = useState([]);

  const [promocode, setpromocode] = useState("");
  const [description, setdescription] = useState("");
  const [productId, setproductId] = useState("");
  const [allProdcut,setAllproduct]=useState([])
  const [discountpercentage, setdiscountpercentage] = useState(0);
  // const [description,setdescription]
  const [category, setcategory] = useState("");
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  // console.log("productId",productId);
  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getcategory");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.category);
    }
  };

  const getAllProduct= async ()=>{
    try {
      let res=await axios.get(`${apiURL}/admin/product`);
      if(res.status==200){
        setAllproduct(res.data.products)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllProduct()
    getcategory();
    getdisease();
  }, []);

  const getdisease = () => {
    try {
      axios
        .get(`${apiURL}/getallpromocode`)
        .then(function (response) {
          if (response.status === 200) {
            setdata(response.data.promocode);
          } 
        })
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");

      return error;
    }
  };

  console.log("check",data)

  const postdisease = async (e) => {
    e.preventDefault();

    if (!category | !productId | !promocode | !description | !discountpercentage) {
      alert("Please fill all fields");
    } else {
      try {
        const config = {
          url: "/admin/addpromocode",
          method: "post",
          baseURL: apiURL,
          headers: { "content-type": "application/json" },
          data: {
            promocode: promocode,
            productId:productId,
            description: description,
            // startingdate: startingdate,
            discountpercentage: discountpercentage,
            category: category,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Successfully added")
          getdisease();
         hideModal()
          
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [data3, setData3] = useState({});
  
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };

  const remove = async () => {
    try {
      axios
        .post(`${apiURL}/admin/deletepromocode/` + data3._id)
        .then(function (response) {
          if (response.status === 200) {
         
            alert("Successfully deleted")
            hideModal1()
            getdisease()
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");

      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      text:""
    },
    {
      dataField:"category",
      text:"Category"
    },
    {
      dataField:"productId.productname",
      text:"Products"
    },
    {
      dataField: "promocode",
      text: "Promo Code",
      sort: true,
    },

    {
      dataField: "description",
      text: "Description",
    },

    {
      dataField: "discountpercentage",
      text: "Discount Percentage",
    },

    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-danger" onClick={()=>{
              showModal1()
              setData3(row)
            }}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ background: "" }}>
      <Button variant="success" onClick={showModal} className="mb-2">
        Add Promocode
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Promocode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              <div className="form-group">
              <label>Select Category</label>
              <br/>
              <select aria-label="Default select example" 
              style={{padding:"5px 4px",width:"100%",borderRadius:"5px"}} onChange={(e)=>setcategory(e.target.value)}>
      <option>Open this select menu</option>
      {categorydata?.map((item)=>{
        return (
           <option value={item?.categoryName}>{item?.categoryName}</option>
        )
      })}
    </select>
<br/>

             <label>Select Product</label>
              <br/>
              <select aria-label="Default select example" 
              style={{padding:"5px 4px",width:"100%",borderRadius:"5px"}} onChange={(e)=>setproductId(e.target.value)}>
      <option>Open this select menu</option>
      {allProdcut?.filter((ele)=>ele?.productcategory===category)?.map((item)=>{
        return (<option value={item?._id}>{item?.productname}</option> )
      })}
      
      
    </select>
                <label>Promo Code</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter the Promo Code"
                  onChange={(e) => setpromocode(e.target.value)}
                />

                <label>Description</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter the Description"
                  value={description}
                onChange={(e)=>setdescription(e.target.value)}
                />
                {/* <label>Starting Date</label>
                <DatePicker
                  style={{ width: "325px", height: "38px" }}
                  format="DD/MM/YYYY"
                  type="input-icon"
                  minDate={new Date()}
                  onChange={(date) =>
                    setstartingdate(date.format("DD/MM/YYYY"))
                  }
                /> */}
                <label>Discount Percentage</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Enter the Discount Percentage"
                value={discountpercentage}
                onChange={(e)=>setdiscountpercentage(e.target.value)}
                />
              
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postdisease}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
         {/* deleteMode */}
         <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Are you sure delete !</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Cancel
            </Button>
            <Button
              variant="danger"
              style={{ cursor: "pointer" }}
              onClick={() => remove()}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Promo Code
        </h1>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "subadmin.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto"></span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="subadmin"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminpromocode;
