import React, { useState } from 'react'
import "../Styles/register.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import {Link} from 'react-router-dom'
import axios from 'axios';
import {FiPhoneCall} from 'react-icons/fi';
import Buttonn from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import {isEmpty, isValidMobile} from "./validation"
function isValidEmail(emailid){
  emailid=emailid?.trim();
  if(emailid?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)){
      return true;
  }
  return false;
}

function isValidMobile(mobile){
  mobile=mobile?.trim();
  if(mobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) ){  //&& ! (mobile.match(/0{5,}/))
      return true;
  }
  return false;
}    

function isEmpty(str){
  if(typeof (str) == "undefined" || typeof (str) == null) {return false}
  if(typeof (str) == "string" && (str).trim().length == 0) {return false}
  if(typeof (str) == 'number' && (str).toString().trim().length == 0){return false}
  return true
}

function Login() {
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [phoneNumber,setphoneNumber]=useState("");
    const [password,setPassword]=useState("");
    const [otp,setOtp]=useState("");
    const [loginWit,setLoginwith]=useState(false);

    const nowlogin=async (e)=>{
      e.preventDefault()
      if(!isEmpty(phoneNumber)) return alert("Please enter mobile number!")
      if(!isValidMobile(phoneNumber)) return alert("Invalid mobile number!")
      if(!isEmpty(password)) return alert("Please enter password!")
      try{
        const config = {
          url: "/signin",
          method: "post",
          baseURL: "https://spilso.in/api/customer",
          headers: { "conttent-type": "application/json" },
         data:{phoneNumber:phoneNumber,password:password}
        };
    
        let res=await axios(config);
        if(res.status===200){
          alert("Successfully Login")
          sessionStorage.setItem("customer",JSON.stringify(res.data.user));
          window.location.assign("/")
        }
      } catch (error) {
        alert(error.response.data.error)
        console.log(error);
      }
    }
  // sendotp
  const sendotp=async (e)=>{
    e.preventDefault()
    if(!isEmpty(phoneNumber)) return alert("Please enter mobile number!")
    if(!isValidMobile(phoneNumber)) return alert("Invalid mobile number!")
  
    try{
      const config = {
        url: "/sendotp",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
       data:{phoneNumber:phoneNumber}
      };
  
      let res=await axios(config);
      if(res.status===200){
        setShow(true)
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  const reSendOtp=async ()=>{
  
    if(!isEmpty(phoneNumber)) return alert("Please enter mobile number!")
    if(!isValidMobile(phoneNumber)) return alert("Invalid mobile number!")
  
    try{
      const config = {
        url: "/sendotp",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
       data:{phoneNumber:phoneNumber}
      };
  
      let res=await axios(config);
      if(res.status===200){
        alert("Successfully resend otp")
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  const verifyOtp=async ()=>{
  
    if(!isEmpty(otp)) return alert("Please enter mobile number!")
    try{
      const config = {
        url: "/verifycustomerotp",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "conttent-type": "application/json" },
       data:{phoneNumber:phoneNumber,otp:otp}
      };
  
      let res=await axios(config);
      if(res.status===200){
        alert("Successfully verify otp")
        sessionStorage.setItem("customer",JSON.stringify(res.data.customer));
        window.location.assign("/")
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  return (
    <>
    <section className="common-use" style={{
      // backgroundImage:"url('/webImages/bg-login.webp')",
      // height:"auto",
      // backgroundSize:"cover",
      // backgroundPosition:"center",
      // minHeight:"500px"
    }}>
      <div className="user reg-0" >
        <div className="user-re">
          <div class="title-text">
            <h2 style={{fontWeight:"600",color:"#333333"}}>Login</h2>

            <p>Please login below account details</p>
          </div>
          <div className="form-re">
            {loginWit ? (  <form>
              <div className="detail_hero mb-3">
                <label>
                  <i  aria-hidden="true"><FiPhoneCall/></i>
                </label>

                <input
                  type="number"
                  name="number"
                  placeholder="+91 88990***10"
                  className="regis-pro"
      
                  value={phoneNumber}
                  onChange={(e)=>setphoneNumber(e.target.value)}
                  required
                
                />
              </div>            
              <div class="form-action-button">
                    <button type="submit" onClick={(e)=> sendotp(e)}>Send Otp</button>
                  </div>
                  <br/>
                  <div style={{color:"blue",cursor:"pointer",display:"flex", float:"left"}} onClick={()=>setLoginwith(false)}> Login with password ?</div>
            </form>):(
               <form>
              <div className="detail_hero mb-3">
                <label>
                  <i  aria-hidden="true"><FiPhoneCall/></i>
                </label>

                <input
                  type="number"
                  name="number"
                  placeholder="+91 88990***10"
                  className="regis-pro"
      
                  value={phoneNumber}
                  onChange={(e)=>setphoneNumber(e.target.value)}
                  required
                
                />
              </div>
             
              <div className="detail_hero mb-3">
                <label>
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </label>
                <Space direction="vertical">
                <Input.Password placeholder="Password"   className="regis-pro" value={password}  onChange={(e)=>setPassword(e.target.value)}/>
              </Space>
              </div>
              
              <div class="form-action-button">
                    <button type="submit" onClick={(e)=>nowlogin(e)}>Login</button>
                  </div>
                  <br/>
                  <div style={{color:"blue",cursor:"pointer",display:"flex", float:"left"}} onClick={()=>setLoginwith(true)}> Login with otp ?</div>
            </form>)}
           
          </div>
          <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Otp verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-6'>
          <div className="detail_hero mb-6">
                <input
                  type="number"
                  name="number"
                  placeholder="Enter otp"
                  className="regis-pro"
      
                  value={otp}
                  onChange={(e)=>setOtp(e.target.value)}
                  required
                
                />
              </div>
          </div>
          <div className='col-6'>
          <Buttonn variant="success" onClick={reSendOtp}>
          Resend
        </Buttonn>
          </div>
        </div>
    </Modal.Body>
      <Modal.Footer>
        <Buttonn variant="secondary" onClick={handleClose}>
          Back
        </Buttonn>
        <Buttonn variant="primary" onClick={verifyOtp}>
          Verify
        </Buttonn>
      </Modal.Footer>
    </Modal>
        </div>
        <div className="form-re">
              <div className="already">
                  <div className="">Don't have an account?</div>
                  <div class="form-action-button-0">
                  <Link to="/register"><button type="submit">Register</button></Link>  
                  </div>
              </div>
          </div>
      </div>
    </section>
  </>
    // <>
    //   <section className="common-use" style={{
    //     // backgroundImage:"url('/webImages/bg-login.webp')",
    //     // height:"auto",
    //     // backgroundSize:"cover",
    //     // backgroundPosition:"center",
    //     // minHeight:"500px"
    //   }}>
    //     <div className="user reg-0" >
    //       <div className="user-re">
    //         <div class="title-text">
    //           <h2 style={{fontWeight:"600",color:"#333333"}}>Login</h2>

    //           <p>Please login below account details</p>
    //         </div>
    //         <div className="form-re">
    //           <form>
    //             <div className="detail_hero mb-3">
    //               <label>
    //                 <i class="fa fa-envelope-o" aria-hidden="true"></i>
    //               </label>

    //               <input
    //                 type="email"
    //                 name="email"
    //                 placeholder="example@gmail.com"
    //                 className="regis-pro"
    //                 required
    //               />
    //             </div>
               
    //             <div className="detail_hero mb-3">
    //               <label>
    //                 <i class="fa fa-lock" aria-hidden="true"></i>
    //               </label>
    //               <Space direction="vertical">
    //               <Input.Password placeholder="Password"   className="regis-pro" />
    //             </Space>
    //             </div>
                
    //             <div class="form-action-button">
    //                   <button type="submit">Login</button>
    //                 </div>
    //           </form>
    //         </div>
           
    //       </div>
    //       <div className="form-re">
    //             <div className="already">
    //                 <div className="">Don't have an account?</div>
    //                 <div class="form-action-button-0">
    //                 <Link to="/register"><button type="submit">Register</button></Link>  
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //   </section>
    // </>
  )
}

export default Login