import { Button, Card, CardContent, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function Retaileroffer(props) {
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const pincode = sessionStorage.getItem("Pincode");
  const [localshop, setlocalshop] = useState([]);

  useEffect(() => {
    getlocalshops();
  }, []);

  const getlocalshops = async () => {
    let res = await axios.get(apiurl + "/getlocalshop/" + pincode);
    if ((res.status = 200)) {
      console.log(res.data);
      setlocalshop(res.data?.localshop);
    }
  };

  return (
    <div
      className="container  "
      style={{ marginTop: "2rem", marginBottom: "4rem" }}
    >
      <h5 className="ms-3 mt-1">Retailer's List</h5>
      <Row xs={1} md={4} className="pt-2">
        {localshop?.length > 0 ? (
          <>
            {localshop?.map((data, index) => (
              <Col>
                <Card className="mt-2 p-2" style={{width:"400px"}}>
                  <h5 className="mt-1 mb-1 m-auto" style={{ color: "#7b3ae5" }}>
                    Retailer {index + 1}
                  </h5>
                  <Button
                    component={Link}
                    to="/retailerofferproduct"
                    onClick={() => {
                      props.dispatch({ type: "emptyBasket" });
                      props.dispatch({
                        type: "localshop",
                        item: { localshop: data },
                      });
                    }}
                  >
                    <CardContent>
                      <Typography gutterBottom component="div" className="" style={{fontSize:"13px"}}>
                        <b>
                          Firm Name:
                          <span className="ms-2" style={{ color: "#7b3ae5" }}>
                            {data?.firmname}
                          </span>
                        </b>
                        <br />
                        <b>
                          Pincode:
                          <span className="ms-2" style={{ color: "#7b3ae5" }}>
                            {data?.pincode}
                          </span>
                        </b>
                        <br />
                        <b>
                          Landmark:
                          <span className="ms-2" style={{ color: "#7b3ae5",display:"flex",flexWrap:"wrap" }}>
                            {data?.addresslandmark}
                          </span>
                        </b>
                      </Typography>
                    </CardContent>
                  </Button>
                </Card>
              </Col>
            ))}
          </>
        ) : (
          <h5 style={{ marginTop: "5rem", textAlignLast: "center" }}>
            {" "}
            No Retailers Found!!
          </h5>
        )}
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    localshop: state.localshop,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Retaileroffer);
