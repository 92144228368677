import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

function Vendorlayout({ childern }) {
  return (
    <div>
      <Header />
      <div className="row me-0">
        <div className="col-2">
          <Sidebar />
        </div>
        <div className="col-10 ">{childern}</div>
      </div>
      <Footer />
    </div>
  );
}

export default Vendorlayout;
