import axios from "axios";
import { Col, Button, Form, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-multi-date-picker";

function PartnerRequest() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [pincode, setpincode] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [state, setstate] = useState("");
  const [doorno, setdoorno] = useState("");
  const [dateofbirth, setdateofbirth] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [area, setarea] = useState("");
  const [addresstype, setaddresstype] = useState("");
  const [addressline, setaddressline] = useState("");
  const [apartmentname, setapartmentname] = useState("");

  const [selected, setselected] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  useEffect(() => {
    getcustomer();
  }, []);
  const editprofile = async () => {
    try {
      const config = {
        url: "/customer/updatecustomer",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: {
          customerId: selected._id,
          email: email ? email : selected.email,
          name: name ? name : selected.name,
          state: state ? state : selected.state,
          city: city ? city : selected.city,
          pincode: pincode ? pincode : selected.pincode,
          dateofbirth: dateofbirth ? dateofbirth : selected.dateofbirth,
          doorno: doorno ? doorno : selected.doorno,
          country: country ? country : selected.country,
          area: area ? area : selected.area,
          addressline: addressline ? addressline : selected.addressline,
          apartmentname: apartmentname ? apartmentname : selected.apartmentname,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("details has been edited successfully");
        window.location.reload();
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getcustomer = () => {
    try {
 
      axios
        .get(`${apiURL}/admin/getAllRequest`)
        .then(function (response) {
          if (response.status === 200) {
            setdata(response.data.success);
            return;
          }
        })
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");

      return error;
    }
  };



  const BlockUnblock = async (data,status) => {
    try {
      const config = {
        url: "/admin/updateRequestStatus",
        method: "put",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: {id:data._id,status:status}}
     let res=await axios(config);
     if(res.status===200){
      alert(`Successfully ${status}`)
      getcustomer()
     }
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const remove=async ()=>{
    try {
      let res=await axios.delete(`${apiURL}/admin/deleteRequest/${selected?._id}`);
      if(res.status===200){
        alert("Successfully deleted");
        getcustomer()
        hideModal1()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/customers/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      // dataField: "cid",
      text: "",
      sort: true,
    },
    {
      dataField: "Name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "number",
      text: "Mobile No",
    },
    {
        dataField: "WhoYouAre",
        text: "Who you are",
      },

    {
      dataField: "comments",
      text: "Comment",
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return (
      //     <div>
      //       {row?.addresstype == "apartment" ? row?.apartmentname : ""},{" "}
      //       {row?.doorno}, {row?.addressline}, {row?.area},{row?.city},{" "}
      //       {row?.state}, {row?.country}, {row?.pincode}
      //     </div>
      //   );
      // },
    },
    {
      dataField: "",
      text: "Status",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row.status==="Pending" ? (<p style={{color:"blue"}}>Pending</p>):(<></>)}
            {row.status==="Accepted" ? (<p style={{color:"green"}}>Accepted</p>):(<></>)}
            {row.status==="Rejected" ? (<p style={{color:"red"}}>Rejected</p>):(<></>)}
           <p></p>
          </div>
        );
      },
    },
    {
        dataField: "",
        text: "Action",
        formatter: (cell, row) => {
          return (
            <div style={{display:"flex",gap:"5px"}}>
              {row.status==="Pending" ? (<div style={{display:"flex",gap:"5px"}}> 
                <button
              className="btn btn-outline-success"
              onClick={() => {
                BlockUnblock(row,"Accepted")
              }}
            >
              Accept
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                BlockUnblock(row,"Rejected")
              }}
            >
              Reject
            </button>
              </div>):(<></>)}
              
              <button className="btn btn-danger"    onClick={() => {
                setselected(row);
                showModal1();
              }}>
                Delete
              </button>
            </div>
          );
        },
      },
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Partner Request
        </h1>
        <div className="container">
          <div className="card card-body">
            <Modal
              style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
              show={isOpen1}
              onHide={hideModal1}
              animation={false}
            >
              <Modal.Header closeButton className="mt-4">
                <Modal.Title>Are you sure delete! </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="success" onClick={hideModal1}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={() => remove()}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              // exportCSV={{
              //   fileName: "customer.csv",
              //   blobType: "text/csv;charset=ansi",
              // }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="customer"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerRequest;
