import axios from 'axios';
import React, { useState } from 'react'
import { Button,Form,Card,Col, Container, Row, InputGroup } from "react-bootstrap";

function Vendorverifyotp() {
    const [otp, setotp] = useState('');
    const no = sessionStorage.getItem("phoneno");
    const apiURL = process.env.REACT_APP_API_URL;

    const handleverify = async(e) =>{
      e.preventDefault();

      try{ 
          const config ={ 
              url:"/verifyvendorotp",
              method:'post',
              baseURL:apiURL,
              headers:{ 'content-type': 'application/json'},
              data:{ otp: otp, phoneNumber: no}
              //responseType:"application/json"
            }
          let res = await axios(config);            
          if(res.status === 200){
              console.log(res.data);                
              // localStorage.setItem("signupdata",JSON.stringify(res.data.user));
              // localStorage.setItem("token",res.data.token);
              // window.location.assign("/signin"); 
              if(res.data.vendor.userRole === 3 ){
                if(res.data.vendor.profilestatus){
                  window.location.assign("/vendor/dashborad")
                  sessionStorage.setItem("user", JSON.stringify(res.data.vendor))
                }else{
                  window.location.assign("/vendor/signup")
                }                 

              }              
              return res;                
          }
      }catch(error){
        console.log(error);
      }
  }

  // const sendotp = async() =>{
  //     // e.preventDefault();

  //     try{ 
  //         const config ={ 
  //             url:"/sendverification",
  //             method:'post',
  //             baseURL:apiURL,
  //             headers:{ 'content-type': 'application/json'},
  //             data:{ email:signupdata.email, mobileNumber: signupdata.number}
  //             //responseType:"application/json"
  //           }
  //         let res = await axios(config);            
  //         if(res.status === 200){
  //             console.log(res.data);                
  //             // localStorage.setItem("signupdata",JSON.stringify(res.data.user));
  //             // localStorage.setItem("token",res.data.token);
  //             // window.location.assign("/verify-otp");               
  //             return res;                
  //         }
  //     }catch(error){
  //       console.log(error);
  //     }
  // }

    return (
        <div style={{ background:"#f6f8fc"}} >
              <div className="container pt-5" >
                 <div className="row" style={{padding:" 50px 30px",}}>
                 
     <div className="col-md-6 ">
                <img src="https://www.jiomart.com/msassets/images/login-banner.jpg" style={{ width:"100%", height:"500px"}}></img>
                {/* <p className="t-c pt-3"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p> */}
                </div>
                <div className="col-md-6">
                  <form validated>
   <div className="row ">
   <div className="col-md-12 t-c ">
     <h1 className="" style={{padding:"20px 20px 50px"}}>Verify </h1>
     </div>
     <div className="col-md-12">     
     <Form.Group controlId="formBasic">
                <Form.Label>Otp<span style={{color:"red"}}>*</span></Form.Label>
                <InputGroup>
                {/* <InputGroup.Prepend>
                <InputGroup.Text>+91</InputGroup.Text>
                </InputGroup.Prepend> */}
                <Form.Control type="text" name="otp" onChange={(e) => setotp(e.target.value)} placeholder="Enter Otp" />
                 
                </InputGroup>    
            </Form.Group>            
       
       {/* <div>
        <ReCAPTCHA 
        sitekey="6LdiARoaAAAAAPpRZ8n7muGi7JnbvR_JTd-eaMbx" onChange={onCaptchaVerify} />
        </div> */}
     </div>
     {/* <div className="form-group pb-2">
     <input type="checkbox" className="ml-4 mr-2 mt-2"  onChange={()=>setterm(!term)} required/>
      <label for="vehicle1"> Terms & Conditions</label>        
         
       </div> */}
     <div className="col-md-12 pt-2" style={{padding:"20px 20px 10px", float:"right"}}>
                <div className="form-gropu pt-2">
                  <button className="btn btn-primary form-control" type="submit" style={{ padding:"5px", background:"#ed2025"}} onClick={handleverify}>Verify</button>
                </div>

                {/* <p className="t-c pt-2" style={{textDecoration:" underline "}} > Don't have an account? <Link to="/signup">Sign Up</Link>  </p> */}
                {/* <p className="t-c"> Or Continue with</p> */}
     </div>
     </div>                 
                  </form>
                </div>
               

              </div>    
             </div>
   
            
        </div>
    )
}

export default Vendorverifyotp
