import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";

function Retailerproductdetails(props) {
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [product, setproduct] = useState([]);
  const apiurl = process.env.REACT_APP_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [video, setvideo] = useState();

  const [value, setvalue] = useState(false);
  const [image, setimage] = useState(
    props.subscribe.product.type == "admin"
      ? imageURL + "/admin/product/" + props.subscribe.product.productimage[0]
      : props.subscribe.product.type == "vendor"
      ? imageURL + "/vendor/product/" + props.subscribe.product.productimage[0]
      : imageURL +
        "/admin/product/" +
        props.subscribe.product.productimage[0]
  );

  // const  images = ["https://image.shutterstock.com/image-photo/red-apple-on-white-background-260nw-158989157.jpg","https://static3.depositphotos.com/1000955/121/i/600/depositphotos_1219519-stock-photo-red-apple.jpg","https://st.depositphotos.com/1003272/1632/i/600/depositphotos_16322913-stock-photo-red-apple.jpg"]

  useEffect(() => {
    setvalue(false);

    // setimage(props.subscribe.imagepath[0]);
  }, [value]);

  const imageProps = {
    smallImage: {
      alt: "img",
      isFluidWidth: false,
      width: 330,
      height: 400,
      src: image,
    },
    largeImage: {
      src: image,
      width: 1200,
      height: 1200,
    },
    enlargedImageContainerStyle: { background: "#fff", zIndex: 9 },
  };

  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let res = await axios.get(apiurl + "/admin/product");
    if ((res.status = 200)) {
      console.log(res);
      setproduct(
        res.data?.products.filter(
          (item) =>
            item.productsubcategory ===
            props.subscribe.product.productsubcategory
        )
      );
    }
  };
  return (
    <div style={{ background: "#F4F4F4" }}>
      <div className="container-fluid">
        <h3 className="pt-5 pl-5" style={{ color: "#28328c" }}>
          Product Details
        </h3>
        <Row>
          <Col className="pt-3 pl-5" lg={5}>
            {/* <ReactImageMagnify {...imageProps} /> */}
            {!video ? (
              <ReactImageMagnify
                {...imageProps}
                // className="img-fluid mx-auto shadow-sm rounded"
              />
            ) : (
              <video
                src={video}
                // className="img-fluid mx-auto shadow-sm rounded"
                height="450px"
                width="450px"
                controls
                controlsList="nodownload"
              />
            )}
            <div className="d-flex mb-4 ml-3 mt-2">
              {props.subscribe.product.type == "admin"
                ? props.subscribe.product.productimage.map((i) => (
                    <div className="border ml-1 mr-1">
                      <span>
                        <img
                          src={imageURL + "/admin/product/" + i}
                          onClick={() =>
                            setimage(imageURL + "/admin/product/" + i)
                          }
                          height="100px"
                          width="100px"
                        />
                      </span>
                    </div>
                  ))
                : props.subscribe.product.productimage.map((i) => (
                    <div className="border ml-1 mr-1">
                      <span>
                        <img
                          src={imageURL + "/admin/product/" + i}
                          onClick={() =>
                            setimage(imageURL + "/admin/product/" + i)
                          }
                          height="100px"
                          width="100px"
                        />
                      </span>
                    </div>
                    //   ))
                    // : props.subscribe.product.productimage.map((i) => (
                    //     <div className="border ml-1 mr-1">
                    //       <span>
                    //         <img
                    //           src={imageURL + "/admin/product/" + i}
                    //           onClick={() =>
                    //             setimage(imageURL + "/admin/product/" + i)
                    //           }
                    //           height="100px"
                    //           width="100px"
                    //         />
                    //       </span>
                    //     </div>
                  ))}
            </div>
            <div className="d-flex mb-4 ml-3 mt-2">
              {props.subscribe.product.type == "admin" &&
              props.subscribe.product?.productvideo ? (
                <div className="border ml-1 mr-1">
                  <span>
                    <video
                      src={
                        imageURL +
                        "/admin/product/" +
                        props.subscribe.product?.productvideo
                      }
                      onClick={() =>
                        setvideo(
                          imageURL +
                            "/admin/product/" +
                            props.subscribe.product?.productvideo
                        )
                      }
                      height="100px"
                      width="100px"
                      // controls
                      controlsList="nodownload"
                    />
                  </span>
                </div>
              ) : props.subscribe.product.type == "vendor" &&
                props.subscribe.product?.productvideo ? (
                <div className="border ml-1 mr-1">
                  <span>
                    <video
                      src={
                        imageURL +
                        "/vendor/product/" +
                        props.subscribe.product?.productvideo
                      }
                      onClick={() =>
                        setvideo(
                          imageURL +
                            "/vendor/product/" +
                            props.subscribe.product?.productvideo
                        )
                      }
                      height="100px"
                      width="100px"
                      // controls
                      controlsList="nodownload"
                    />
                  </span>
                </div>
              ) : props.subscribe.product?.productvideo ? (
                <div className="border ml-1 mr-1">
                  <span>
                    <video
                      src={
                        imageURL +
                        "/admin/product/" +
                        props.subscribe.product?.productvideo
                      }
                      onClick={() =>
                        setvideo(
                          imageURL +
                            "/admin/product/" +
                            props.subscribe.product?.productvideo
                        )
                      }
                      height="100px"
                      width="100px"
                      // controls
                      controlsList="nodownload"
                    />
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* <img src={props.subscribe.imagepath[0]} alt="" height="400px" width="500px" /> */}
          </Col>
          <Col className="pt-3" lg={7}>
            <div
              className="pt-5 pb-3"
              style={{ fontWeight: "700", fontSize: "20px", color: "#28328c" }}
            >
              {props.subscribe.product.productname}{" "}
              {props.subscribe.product.productUnites}
            </div>
            <div className="pb-3 ">
              <strong>Product Description</strong> :{" "}
              {props.subscribe.product.productdesc}
              {/* <div className="pl-2 pt-2"></div> */}
            </div>
            <div className="pb-3">
                <strong>Product Features</strong> :{" "}
                {props.subscribe.product.productfeatures}{" "}
              </div>
              <div className="pb-3">
                <strong>Product Brand</strong> :{" "}
                {props.subscribe.product.productbrand}{" "}
              </div>
              {!props.subscribe.product.productwarranty ? null :  <div className="pb-3">
                <strong>Product Warranty</strong> :{" "}
                {props.subscribe.product.productwarranty + props.subscribe.product.productwarrantytype }{" "}
              </div>}
            <div className="pb-3">
              <strong>Category</strong> :{" "}
              {props.subscribe.product.productcategory}{" "}
            </div>
            <div className="pb-3">
              <strong>Sub-Category</strong> :{" "}
              {props.subscribe.product.productsubcategory}{" "}
            </div>
            <div className="pb-3">
              <strong>Price</strong> : RS.{" "}
              {props.subscribe.product.retailerofferPrice *
                props.subscribe.quantity}{" "}
            </div>

            <div className="pb-3 flex-column">
              <strong>Quantity</strong>
              <div>
                <Button
                  className="mr-2 text-center"
                  style={{ width: "30px" }}
                  onClick={() => {
                    props.dispatch({
                      type: "addquantity",
                      item: props.subscribe,
                    });
                    setvalue(true);
                  }}
                >
                  +
                </Button>
                <span>{props.subscribe.quantity}</span>
                <Button
                  className="ml-2"
                  style={{ width: "30px" }}
                  onClick={() => {
                    props.dispatch({
                      type: "subquantity",
                      item: props.subscribe,
                    });
                    setvalue(true);
                  }}
                >
                  -
                </Button>
              </div>
            </div>

            {parseInt(props.subscribe.product.totalstock) !== 0 &&
            parseInt(props.subscribe.product.remainingstock) >= 2 &&
            parseInt(props.subscribe.product.remainingstock) >=
              props.subscribe.quantity ? (
              <Button
                className="mt-2 mb-4"
                variant="outlined"
                color="primary"
                onClick={() => {
                  props.dispatch({
                    type: "addBasketItem",
                    item: props.subscribe,
                  });
                  alert(
                    `Product is Added to cart ${
                      !user ? "please Login to checkout" : ""
                    }`
                  );
                }}
              >
                Add to cart
              </Button>
            ) : parseInt(props.subscribe.product.totalstock) !== 0 &&
              parseInt(props.subscribe.product.remainingstock) === 0 &&
              parseInt(props.subscribe.product.totalstock) >=
                props.subscribe.quantity ? (
              <Button
                className="mt-2 mb-4"
                variant="outlined"
                color="primary"
                onClick={() => {
                  props.dispatch({
                    type: "addBasketItem",
                    item: props.subscribe,
                  });
                  alert(
                    `Product is Added to cart ${
                      !user ? "please Login to checkout" : ""
                    }`
                  );
                }}
              >
                Add to cart
              </Button>
            ) : (
              <div style={{ color: "red" }}>Out of Stock</div>
            )}

            {/* <Button
              className="mt-2 mb-4"
              variant="outlined"
              color="primary"
              onClick={() =>
                props.dispatch({ type: "addBasketItem", item: props.subscribe })
              }
            >
              Add to cart
            </Button> */}
          </Col>
        </Row>

        {/* <Row xs={1} md={4} className="g-4">
          <Col md={12}>
            <h4>Similar Products</h4>
          </Col>
          {product.map((e) => (
            <Col className="allcategory">
              <Card>
                <Link
                  to={{
                    pathname: "/retailer/productdetails",
                    item: { product: product },
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Card.Img
                    variant="top"
                    src={`${imageURL}/admin/product/${e.productimage[0]}`}
                  />
                </Link>
                <Card.Body>
                  <Card.Title>{e.productname}</Card.Title>
                  <Card.Text>
                    <p>
                      <b>₹ {e.customerofferPrice} </b>{" "}
                      <span
                        style={{
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                        }}
                      >
                        M.R.P: ₹ {e.productprice}
                      </span>
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        props.dispatch({
                          type: "addquantity",
                          item: props.subscribe,
                        });
                        setvalue(true);
                      }}
                    >
                      Add to Cart
                    </Button>{" "}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    subscribe: state.Subscribe,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Retailerproductdetails);
