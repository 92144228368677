import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col, Badge } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { Button, Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
function Productslist(props) {
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { data } = location.state;
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [product, setproduct] = useState();
  const [product1, setproduct1] = useState();

  const [like, setLike] = useState([]);
  console.log(data);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    getsubcategoryproduct();
  }, [data]);
  useEffect(() => {
    getlike();
  }, []);

  const getsubcategoryproduct = async () => {
    let res = await axios.get(
      apiurl + "/customer/product/" + data.subcategoryName
    );
    if ((res.status = 200)) {
      console.log(res.data);
      setproduct(res.data?.product);
      setproduct1(res.data?.product);
    }
  };
  const filter = (s1, s2) => {
    setproduct1(
      product?.filter(
        (p) => p?.retailerofferPrice >= s1 && p?.retailerofferPrice <= s2
      )
    );
  };
  const filter2 = (s1, s2) => {
    setproduct1(
      product?.filter(
        (p) =>
          p?.retailerdiscountpercentage >= s1 &&
          p?.retailerdiscountpercentage <= s2
      )
    );
  };
  let Onlike = async (product1) => {
    if (!user) {
      alert("Please Login");
    } else {
      try {
        let config1 = {
          url: "/postlike",
          method: "post",
          baseURL: apiurl,
          headers: { "content-type": "application/json" },
          data: {
            productid: product1._id,
            userid: user._id,
          },
        };
        let res = await axios(config1);
        if (res.status === 200) {
          alert("Added to Wishlist");
          getlike();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getlike = async (product) => {
    let res = await axios.get(apiurl + "/getlike/" + `${user?._id}`);
    if (res.status === 200) {
      console.log(res.data);
      setLike(res.data.favourite);
    }
  };

  const deletelike = async (product1) => {
    let res = await axios.post(
      apiurl + "/getdeletelike/" + `${user?._id}` + "/" + `${product1._id}`
    );
    if (res.status === 200) {
      console.log(res.data);
      alert("Removed from Wishlist");
      getlike();
    }
  };
  return (
    <div className="container allcategory">
      <div className="d-flex mt-2" style={{ justifyContent: "space-between" }}>
        <h5>Products</h5>
        <div className="me-3 ">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Filter
            <ExpandMoreIcon style={{ color: "#874ed1" }} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div className="text-center mt-1 mb-1" style={{ color: "red" }}>
              Filter by Price
            </div>
            <div className="d-flex">
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(0, 1000000000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  All price
                </Button>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(0, 1000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  Under ₹ 1000
                </Button>
              </MenuItem>
            </div>
            <div className="d-flex">
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(1000, 5000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  ₹ 1,000 - ₹5,000
                </Button>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(5000, 10000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  ₹ 5,000 -₹ 10,000
                </Button>
              </MenuItem>
            </div>
            <div className="d-flex">
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(10000, 20000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  ₹ 10,000 -₹ 20,000
                </Button>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(20000, 50000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  ₹ 20,000 - ₹ 50,000
                </Button>
              </MenuItem>
            </div>
            <div className="d-flex">
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(50000, 100000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  ₹ 50,000 - ₹ 1,00,000
                </Button>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(100000, 150000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  ₹ 1,00,000 - ₹ 1,50,000
                </Button>
              </MenuItem>
            </div>
            <div className="d-flex">
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(150000, 200000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  ₹ 1,50,000 -₹ 2,00,000
                </Button>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  filter(200000, 10000000);
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#e6eaed", border: "none" }}
                >
                  Above ₹ 2,00,000
                </Button>
              </MenuItem>
            </div>
            <div className="text-center mt-1 mb-1" style={{ color: "red" }}>
              Filter by Discounts
            </div>
            <MenuItem
              onClick={() => {
                handleClose();
                filter2(0, 10);
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#e6eaed", border: "none" }}
              >
                Discount below 10%
              </Button>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                filter2(10, 50);
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#e6eaed", border: "none" }}
              >
                Discount 10%-50%
              </Button>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                filter2(50, 100);
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#e6eaed", border: "none" }}
              >
                {" "}
                Above 50%
              </Button>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Row xs={1} md={4} className="g-4 mt-2">
        {product1?.length > 0 ? (
          <>
            {product1?.map((data) => (
              <Col>
                <Card>
                  <Button
                    component={Link}
                    to="/retailer/productdetails"
                    onClick={() =>
                      props.dispatch({
                        type: "Subscribe",
                        item: { product: data, quantity: 1 },
                      })
                    }
                  >
                    {data.type == "admin" ? (
                      <Card.Img
                        variant="top"
                        src={
                          imageURL + "/admin/product/" + data.productimage[0]
                        }
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        src={
                          imageURL + "/vendor/product/" + data.productimage[0]
                        }
                      />
                    )}
                  </Button>
                  <Card.Body>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      {" "}
                      <h5>
                        {data.productname.length > 50 ? (
                          <>{`${data.productname.substring(0, 40)}...`}</>
                        ) : (
                          data.productname
                        )}
                      </h5>
                      <div className="mx-auto">
                        {like.length > 0 ? (
                          like.find((item) => item.productid === data._id)
                            ?._id ? (
                            <i
                              class="fa fa-heart"
                              style={{
                                fontSize: "24px",
                                color: "red",
                                fontWeight: "bold",
                              }}
                              onClick={() => deletelike(data)}
                            ></i>
                          ) : (
                            <i
                              class="	fa fa-heart-o"
                              style={{
                                fontSize: "24px",
                                color: "#0d6efd",
                                fontWeight: "bold",
                              }}
                              onClick={() => Onlike(data)}
                            ></i>
                          )
                        ) : (
                          <i
                            class="	fa fa-heart-o"
                            style={{
                              fontSize: "24px",
                              color: "#0d6efd",
                              fontWeight: "bold",
                            }}
                            onClick={() => Onlike(data)}
                          ></i>
                        )}
                      </div>
                    </div>

                    <Card.Text>
                      <p>
                        <b>₹ {data.retailerofferPrice} </b>{" "}
                        <span
                          style={{
                            textDecorationLine: "line-through",
                            textDecorationStyle: "solid",
                          }}
                        >
                          M.R.P: ₹ {data.productprice}
                        </span>
                      </p>

                      <div className="mb-2">
                        {" "}
                        <Badge
                          style={{ backgroundColor: "yellow", color: "black" }}
                        >
                          Save
                        </Badge>{" "}
                        <large>{data.retailerdiscountpercentage}% Off</large>{" "}
                      </div>

                      {parseInt(data.totalstock) !== 0 &&
                      parseInt(data.remainingstock) >= 2 ? (
                        <>
                          {" "}
                          <Button
                            variant="contained"
                            style={{
                              background:
                                "linear-gradient(45deg, var(--bs-pink), var(--bs-purple))",
                              color: "white",
                            }}
                            onClick={() => {
                              props.dispatch({
                                type: "addBasketItem",
                                item: { product: data, quantity: 1 },
                              });
                              alert(
                                `Product is Added to cart ${
                                  !user ? "please Login to checkout" : ""
                                }`
                              );
                            }}
                          >
                            Add to Cart
                          </Button>
                        </>
                      ) : parseInt(data.totalstock) !== 0 &&
                        parseInt(data.remainingstock) === 0 ? (
                        <>
                          {" "}
                          <Button
                            variant="contained"
                            style={{
                              background:
                                "linear-gradient(45deg, var(--bs-pink), var(--bs-purple))",
                              color: "white",
                            }}
                            onClick={() => {
                              props.dispatch({
                                type: "addBasketItem",
                                item: { product: data, quantity: 1 },
                              });
                              alert(
                                `Product is Added to cart ${
                                  !user ? "please Login to checkout" : ""
                                }`
                              );
                            }}
                          >
                            Add to Cart
                          </Button>
                        </>
                      ) : (
                        <div style={{ color: "red" }}>Out of Stock</div>
                      )}
                      {/* <Button
                        variant="contained"
                        style={{
                          background:
                            "linear-gradient(45deg, var(--bs-pink), var(--bs-purple))",
                          color: "white",
                        }}
                        onClick={() => {
                          props.dispatch({
                            type: "addBasketItem",
                            item: { product: data, quantity: 1 },
                          });
                          alert("Product is Added To cart");
                        }}
                      >
                        Add to Cart
                      </Button> */}
                      {/* {data.subscriptation ? (
                        <Button
                          className="ms-2"
                          variant="contained"
                          color="#ed2025"
                          style={{ backgroundColor: "#ed2025" }}
                          component={Link}
                          to="/subscription"
                          onClick={() =>
                            props.dispatch({
                              type: "Subscribe",
                              item: { product: data, quantity: 1 },
                            })
                          }
                        >
                          Subscribe
                        </Button>
                      ) : (
                        ""
                      )} */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </>
        ) : (
          <h5 className="m-auto mt-4">No products found!!</h5>
        )}
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    Subscribe: state.Subscribe,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Productslist);
