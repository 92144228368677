import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import BootstrapTable, { ROW_SELECT_DISABLED } from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function DistributorCashHistory() {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  

  const [data, setdata] = useState([]);

  const [data1, setdata1] = useState([]);
  const [usertype, setusertype] = useState("");
  const [username, setusername] = useState("");
  const [allexpense, setallexpense] = useState([]);
  const [category, setcategory] = useState("");

  let [endDate, setendDate] = useState();
  let [startDate, setstartDate] = useState();
  const apiURL = process.env.REACT_APP_API_URL;
  // enableRipple(true);
  // useEffect(() => {
  //   gettransaction();
  //   if (usertype == "customer") {
  //     getcustomer();
  //   }
  //   if (usertype == "retailer") {
  //     getretailer();
  //   }
  //   if (usertype == "distributor") {
  //     getdistributor();
  //   }
  // }, [usertype, category]);

  // const gettransaction = async () => {
  //   let res = await axios.get(
  //     apiURL + "/admin/customerstatement/611e141c89f3e628c00cebb5"
  //   );
  //   if ((res.status = 200)) {
  //     console.log(res.data);
  //     setdata1(res.data);
  //   }
  // };

  // const getcustomer = async () => {
  //   let res = await axios.get(apiURL + "/customer/getallcustomer");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setallexpense(
  //       res.data?.customer.filter((item) => item.profilestatus === true)
  //     );
  //   }
  // };

  // const getdistributor = async () => {
  //   let res = await axios.get(apiURL + "/distributor/notapproved");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setallexpense(
  //       res.data?.distributor.filter((item) => item.profilestatus === true)
  //     );
  //   }
  // };

  // const getretailer = async () => {
  //   let res = await axios.get(apiURL + "/retailer/notapproved");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setallexpense(
  //       res.data?.retailer.filter((item) => item.profilestatus === true)
  //     );
  //   }
  // };



  const [nochandata, setnodata] = useState([]);
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));
  useEffect(() => {
    if (!distributor) {
      alert("Please login");
      window.location.assign("/distributor/login");
    }
  }, []);
  useEffect(() => {
    getRetailerAndDistributorOrders();
  }, []);
  useEffect(() => {
    getRetailerAndDistributorOrders();
  }, []);

  const getRetailerAndDistributorOrders = async () => {
    try {
      const response = await axios.get(`${apiURL}/distributor/getDistributorAndRetailerOrders`);
      // console.log("tttt", response);
      if (response.status === 200) {
        setdata(
          response.data.getOrders.filter(
            (item) =>{
              if(item.orderBy === "DISTRIBUTOR"){
                if(item.distributorId._id === distributor?._id && item.paymentmethod === "Cash On Delivery"){
                  return true;
                }
                return false;
              }
              else if(item.orderBy === "RETAILER"){
                if(item.retailerId.distributorId === distributor?._id && item.paymentmethod === "Cash On Delivery"){
                  return true;
                }
                return false;
              }
              else {
                return false;
              }
            }
            //  item.retailerId.distributorId === distributor?._id || item.distributorId._id === distributor?._id
          )
        );
        setnodata(
          response.data.getOrders.filter(
            (item) =>{
              if(item.orderBy === "DISTRIBUTOR"){
                if(item.distributorId._id === distributor?._id && item.paymentmethod === "Cash On Delivery"){
                  return true;
                }
                return false;
              }
              else if(item.orderBy === "RETAILER"){ // && item.paymentmethod === "Cash On Delivery"
                if(item.retailerId.distributorId === distributor?._id && item.paymentmethod === "Cash On Delivery"){
                  return true;
                }
                return false;
              }
              else {
                return false;
              }
            }
            //  item.retailerId.distributorId === distributor?._id || item.distributorId._id === distributor?._id
          )
        );
      }
    } catch (error) {
      console.warn(error);
      alert(error.response.data.error);
      // setdatacondition(true);
      // return error;
    }
  };



  //excel
  const { ExportCSVButton } = CSVExport;
  const columns = [
    // {
    //   dataField: "",
    //   text: "S.NO",
    // },
    {
      dataField: "orderBy",
      text: "User Type",
    },
    {
      // dataField: "",
      text: "User Id",
      formatter: (cell, row)=>{
        return(
        <>
        {row.orderBy === "DISTRIBUTOR"?
          row.distributorId?._id
        :
          row.retailerId?._id}
          </>
        )
      }
    },
    {
        // dataField: "",
        text: "User Name",
        formatter: (cell, row)=>{
          return (
          <>
            {row.orderBy === "DISTRIBUTOR"?
            row.distributorId?.partnersname
          
          :
            
            row.retailerId?.partnersname}
            </>
          )
        }
      },
      {
        // dataField: "",
        text: "Order date",
        formatter: (cell, row)=>{
          return (
            <>
              {row.orderBy === "DISTRIBUTOR"?
                row?.distributorOrderdatetime
              :
                row?.retailerOrderdatetime
              }
            </>
          )
        }
      },
      {
        dataField: "allTotal",
        text: "Order Amount",
      },
      {
        dataField: "status",
        text: "Order status",
      },
      
      {
        dataField: "",
        text: "Invoice Id",
      },
      {
        dataField: "",
        text: "Invoice Number",
      },
      {
        dataField: "",
        text: "Invoice Date",
      },
      {
        dataField: "allTotal",
        text: "Invoice Amount",
      },
      {
        dataField: "",
        text: "Payment Paid",
      },
      {
        dataField: "",
        text: "Payment Date",
      },
      {
        dataField: "",
        text: "Payment Pending",
      },
      // {
      //   dataField: "",
      //   text: "Remarks",
      // },
      // {
      //   dataField: "",
      //   text: "Action",
      //   formatter: (cell, row) => {
      //     console.log(row);
      //     return (
      //       <div>
      //         {!row?.blockstatus ? (<>
      //           <button
      //             className="btn btn-outline-success"
      //             // onClick={() => block(row)}
      //           >
      //             Approved
      //           </button><br/>
      //            <button
      //            className="btn btn-outline-success"
      //            onClick={handleShow}
      //          >
      //            Add
      //          </button>
      //          </>
      //         ) : (
      //           <button
      //             className="btn btn-outline-danger"
      //             // onClick={() => unblock(row)}
      //           >
      //             Un-Block
      //           </button>
      //         )}
      //       </div>
      //     );
      //   },
      // },
  ];

  function getDateObject(datevalue) {
    let d = new Date(datevalue);

    let v = d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear();
    return v;
  }

  const search = () => {
    // const filteredData = data1?.statement?.filter(
    //   (data) =>
    //     new Date(
    //       moment(moment(data.orderdatetime, "DD/MM/YYYY hh:mm A")).format(
    //         "YYYY/MM/DD"
    //       )
    //     ) >= new Date(startDate?.split("/").reverse().join("/")) &&
    //     new Date(
    //       moment(moment(data.orderdatetime, "DD/MM/YYYY hh:mm A")).format(
    //         "YYYY/MM/DD"
    //       )
    //     ) <= new Date(endDate?.split("/").reverse().join("/"))
    // );

    startDate = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    endDate = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    // alert(startDate);
    // alert(endDate);
    // alert(typeof startDate)
    // alert( typeof endDate)

    const filteredData = nochandata?.filter((item) => {
      let compareD;
      if(item.retailerOrderdatetime){
        compareD = moment(item.retailerOrderdatetime).format("YYYY-MM-DD");
      }else{
        compareD = moment(item.distributorOrderdatetime).format("YYYY-MM-DD");
      }
      // alert(compareD); alert(typeof compareD);
      let d1 = moment(startDate.toString()).diff(compareD.toString(), "days");
      let d2 = moment(endDate.toString()).diff(compareD.toString(), "days");

      if (d1 > 0 || d2 < 0) {
        return false;
      }
      return true;
      // alert(d1);
      // alert(d2);
    });
    setdata(filteredData);
  };

  console.log(data);
  return (
    <div className="container">
      <div>
        <div className="row">
          <div className="col-md-2 mt-2">
            <h7>Date From</h7>
            <DatePicker
              style={{ height: "35px", width: "140px", marginRight: "-5px" }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="input-icon"
              onChange={(date) => setstartDate(date.format("DD/MM/YYYY"))}
            />
          </div>
          <div className="col-md-2 mt-2">
            <h7>Date To</h7>
            <DatePicker
              style={{ height: "35px", width: "150px", marginRight: "-5px" }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="input-icon"
              onChange={(date) => setendDate(date.format("DD/MM/YYYY"))}
            />
          </div>

          {/* <div className="col-md-2">
            <label className="mt-2">User Type</label>
            <select
              className="form-control"
              onChange={(e) => setusertype(e.target.value)}
            >
              <option value="">Select User Type</option>
              <option value="distributor">Distributor</option>
              <option value="retailer">Retailer</option>
              <option value="customer">Customer</option>
            </select>
          </div>
         
          <div className="col-md-2">
            <label className="mt-2">User Id</label>
            <select
              className="form-control"
              onChange={(e) => setusertype(e.target.value)}
            >
              <option value="">Select User Id</option>
              <option value="distributor">123</option>
              <option value="retailer">456</option>
              <option value="customer">789</option>
            </select>
          </div>
          <div className="col-md-2">
            <label className="mt-2">User Name</label>
            <select
              className="form-control"
              onChange={(e) => setcategory(e.target.value)}
            >
              <option>Select the user name</option>
              {usertype == "distributor" || usertype == "retailer"
                ? allexpense?.map((data) => (
                    <option value={data._id}>{data.firmname}</option>
                  ))
                : ""}
              {usertype == "customer"
                ? allexpense?.map((data) => (
                    <option value={data._id}>{data.name}</option>
                  ))
                : ""}
            </select>
          </div> */}

          <div className="col-md-2 mt-2">
            <button
              className="btn btn-primary"
              style={{ marginTop: "20px" }}
              onClick={search}
            >
              Submit
            </button>
          </div>
          <div className="col-md-2 mt-3">
        
          </div>
        </div>
      </div>
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        exportCSV={{
          fileName: "Accountstatement.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            <span className="pr-5 mr-auto mb-2">
              <ExportCSVButton
                className="btn-success excel-btn"
                {...props.csvProps}
              >
                Download Excels
              </ExportCSVButton>
            </span>

            <div className="table-responsive">
              <BootstrapTable {...props.baseProps} />
            </div>
          </div>
        )}
      </ToolkitProvider>
      
    

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
<div className="">
<Form>
      <Form.Group className="mb-3" controlId="formAddAmount">
        <Form.Label>Add Amount</Form.Label>
        <Form.Control type="text" placeholder="Add Amount" />
        
      </Form.Group>

      <Form.Group className="mb-3" controlId="formAddDate">
        <Form.Label>Add Date</Form.Label>
        <Form.Control type="date" placeholder="Date" />
      </Form.Group>

   
    </Form>
</div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DistributorCashHistory;
