import React, { useState, useEffect } from "react";
import { Container, Form, Modal, Button } from "react-bootstrap";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
// import logo from "../../../public/img/logo.jpg";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import ShoppingCartSharpIcon from "@material-ui/icons/ShoppingCartSharp";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import List from "@material-ui/core/List";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import { Badge, ListItemText } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "Black",
    padding: theme.spacing(0, 1),
    backgroundColor: "#aab2e3",

    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const apiurl = process.env.REACT_APP_API_URL;
  const [subcategory, setsubcategory] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const pincode = sessionStorage.getItem("Pincode");
  const apiURL = process.env.REACT_APP_API_URL;
  const [profilephoto, setprofilephoto] = useState();
  const [profile, setprofile] = useState();
  const [item, setitem] = useState([]);
  const [emptyprofile, setemptyprofile] = useState(
    "https://www.dgvaishnavcollege.edu.in/dgvaishnav-c/uploads/2021/01/dummy-profile-pic.jpg"
  );
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const ilifenew = () => {
    sessionStorage.setItem("shop", "ilife");
    window.location.assign("/");
  };

  const retailerenew = () => {
    sessionStorage.setItem("shop", "retailer");
    window.location.assign("/");
  };
  useEffect(() => {
    getnotification();
    getallcategory();
  }, []);
  const getnotification = async () => {
    let res = await axios.get(apiURL + "/admin/getcustomernotification");
    if ((res.status = 200)) {
      console.log(res);
      setitem(res.data?.notification);
    }
  };
  const getallcategory = async () => {
    let res = await axios.get(apiurl + "/getallcategory");
    if ((res.status = 200)) {
      console.log(res);
      setsubcategory(res.data?.category);
    }
  };

  const userdel = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("phoneno");
    sessionStorage.removeItem("Pincode");
    sessionStorage.removeItem("shop");
    alert("Log Out successfull");
    window.location.assign("/");
    window.reload("/");
  };
  useEffect(() => {
    getprofilephoto();
  }, [profile]);

  const getprofilephoto = () => {
    try {
      axios
        .get(`${apiURL}/getprofile/` + user._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            if (response.data.profilephoto?.profilephoto) {
              console.log(true);
              setprofilephoto(response.data.profilephoto?.profilephoto);
            }
          }
        })
        .catch(function (error) {
          return;
        });
    } catch (error) {
      console.warn(error);

      return error;
    }
  };
  return (
    <div>
      <div className="header-top ">
        <div className=" container">
          <div className="d-flex">
            <div className="">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Link to="/">
                <img src="/logo.png" alt="logo" width="200px" height="60px" />
              </Link>
            </div>

            <div className="ms-auto">
              {user !== null ? (
                <div className="ms-auto me-5">
                  <Link to="/cart">
                    <div style={{ marginTop: "20px" }}>
                      <Badge
                        badgeContent={
                          props.basket.length > 0 ? props.basket.length : "0"
                        }
                        color="secondary"
                      >
                        <ShoppingCartSharpIcon
                          style={{ color: "#6610f2", fontSize: "30px" }}
                          onClick={() => hideModal()}
                        />
                      </Badge>
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="d-flex">
                  <button
                    style={{
                      background:
                        "linear-gradient(45deg, var(--bs-pink), var(--bs-purple))",
                    }}
                    onClick={() => window.location.assign("/signin")}
                    className="head mt-2 me-4"
                  >
                    Login / Signup
                  </button>
                  <div className="me-5">
                    <Link to="/cart">
                      <div style={{ marginTop: "20px" }}>
                      {user  ? (  <Badge
                          badgeContent={
                            props.basket.length > 0 ? props.basket.length : "0"
                          }
                          color="secondary"
                        >
                          <ShoppingCartSharpIcon
                            style={{ color: "#6610f2", fontSize: "30px" }}
                            onClick={() => hideModal()}
                          />
                        </Badge>): ""}
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {user !== null ? (
            <div className="d-flex">
              <div>
                <Link to="/profile">
                  {" "}
                  <IconButton aria-controls="simple-menu" aria-haspopup="true">
                    <Avatar
                      src={
                        profilephoto === undefined
                          ? emptyprofile
                          : profilephoto !== undefined
                          ? "https://ilifeshoppie.info/customers/profile/" +
                            profilephoto
                          : ""
                      }
                      style={{ height: "30px", width: "30px" }}
                      alt={user.name}
                    />
                  </IconButton>
                </Link>
              </div>

              <div
                className="mt-3"
                style={{ color: "#0d36fd", fontWeight: "700" }}
              >
                {user.name}
              </div>
              <div className="mt-3 ms-auto">
                <CloseIcon onClick={handleDrawerClose} />
              </div>
            </div>
          ) : (
            <>
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div>Please Login </div>{" "}
                <div className="">
                  <CloseIcon onClick={handleDrawerClose} />
                </div>
              </div>
            </>
          )}
        </div>
        <Divider />
        <List>
          <ListItem button key="Home">
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              {" "}
              <ListItemText onClick={handleDrawerClose} primary="Home" />
            </Link>
          </ListItem>

          <ListItem button key="AllCategory">
            <Link
              to="/allcategorys"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText
                onClick={handleDrawerClose}
                primary="Shop by Category"
              />
            </Link>
          </ListItem>
          <ListItem button key="AllRetailers">
            <Link
              to="/localshop"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText
                onClick={handleDrawerClose}
                primary="Shop by Retailers"
              />
            </Link>
          </ListItem>
          <ListItem button key="Retailer Offer Zone">
            <Link
              to="/retaileroffer"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText
                onClick={handleDrawerClose}
                primary="Retailer Offer Zone"
              />
            </Link>
          </ListItem>
          {user ? (
            <>
              <ListItem button key="Wishlist">
                <Link
                  to="/favourites"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemText
                    onClick={handleDrawerClose}
                    primary="Wishlist"
                  />
                </Link>
              </ListItem>
            </>
          ) : (
            ""
          )}

          {user ? (
            <>
              <ListItem button key="My Orders">
                <Link
                  to="/orders"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemText
                    onClick={handleDrawerClose}
                    primary="My Orders"
                  />
                </Link>
              </ListItem>
            </>
          ) : (
            ""
          )}
          {user ? (
            <>
              <ListItem button key="My Subscription">
                <Link
                  to="/subscriptionorders"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemText
                    onClick={handleDrawerClose}
                    primary="My Subscription"
                  />
                </Link>
              </ListItem>
            </>
          ) : (
            ""
          )}
          {user ? (
            <>
              <ListItem button key="My Account">
                <Link
                  to="/profile"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemText
                    primary="My Account"
                    onClick={handleDrawerClose}
                  />
                </Link>
              </ListItem>
            </>
          ) : (
            ""
          )}
          {user ? (
            <>
              <ListItem key="Change Language">
                <div>
                  {" "}
                  <div id="google_translate_element"></div>
                </div>
              </ListItem>
            </>
          ) : (
            ""
          )}
          <ListItem button key="Terms">
            <Link
              to="/terms"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText
                onClick={handleDrawerClose}
                primary="Terms & Conditions"
              />
            </Link>
          </ListItem>

          <ListItem button key="Policy">
            <Link
              to="/policy"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText
                onClick={handleDrawerClose}
                primary="Privacy & Policy"
              />
            </Link>
          </ListItem>
          <ListItem button key="Policy">
            <Link
              to="/deliverypolicy"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText
                onClick={handleDrawerClose}
                primary="Shipping & Delivery Policy"
              />
            </Link>
          </ListItem>
          <ListItem button key="Policy">
            <Link
              to="/refundpolicy"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText
                onClick={handleDrawerClose}
                primary="Cancellation & Refund Policy"
              />
            </Link>
          </ListItem>

          <ListItem button key="Guide">
            <ListItemText onClick={handleDrawerClose} primary="Guide" />
          </ListItem>

          <ListItem button key="Logout">
            <ListItemText onClick={userdel} primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Singin / Signup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Enter your Phone Number:</Form.Label>
              <Form.Control type="text" placeholder="Enter your Phone Number" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
          <Button variant="primary">
            {/* <Link to="/signup" /> */}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="custom-nav"
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Container>
            <Nav className="">
              {user ? (
                <>
                  <Nav.Link style={{ float: "right" }}>
                    Deliver to {pincode}
                  </Nav.Link>
                  <Form
                    className="d-flex"
                    style={{ width: "-webkit-fill-available" }}
                  >
                    <Form.Control
                      type="search"
                      placeholder="Search for products"
                      className="me-2"
                      aria-label="Search"
                      onClick={() => window.location.assign("/search")}
                    />
                  </Form>
                  <div className="d-flex ms-auto me-4">
                    {" "}
                    <NavDropdown
                      title="Select the Shop"
                      id="basic-nav-dropdown"
                      className="me-3 mt-2"
                    >
                      <NavDropdown.Item onClick={ilifenew}>
                        Shop By Ilifeshoppie
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={retailerenew}>
                        Shop By Retailers
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Link to="/notification" style={{ textDecoration: "none" }}>
                      <div className="ms-auto mt-2">
                        {" "}
                        <Badge badgeContent={item.length} color="secondary">
                          <NotificationsActiveIcon
                            style={{ color: "#fff" }}
                            onClick={() => hideModal()}
                          />
                        </Badge>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                ""
              )}
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Header);
