import React, { useEffect, useState } from "react";
// import Addsingleproduct from "./Addsingleproduct";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

function Vendorproduct() {
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [product, setproduct] = useState([]);
  const [excel, setexcel] = useState();
  const [image, setimage] = useState();
  const [data1, setdata1] = useState([]);
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let res = await axios.get(apiurl + "/vendor/product/" + user._id);
    if ((res.status = 200)) {
      console.log(res);
      setproduct(res.data?.products);
    }
  };

  if (excel && data1.length === 0) {
    readFile();
  }

  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setdata1(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }

  function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  const postorders = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/addmultiproduct",
        method: "post",
        baseURL: apiurl,
        headers: { "content-type": "application/json" },
        data: {
          adminId: user.id,
          product: data1.map((datas) => ({
            productname: datas.Product_Name,
            productcategory: datas.Category,
            productsubcategory: datas.Subcategory,
            image1: datas.Product_Image_1_Name,
            image2: datas.Product_Image_2_Name,
            image3: datas.Product_Image_3_Name,
            image4: datas.Product_Image_4_Name,
            video: datas.Product_Video_Name,
            productprice: datas.Price,
            productdesc: datas.Description,
            tax: datas.GST,
            productvolumetype: datas.Product_Volume_Type,
            productvolume: datas.Product_Volume,
            productsize: datas.Product_Size,
            productwarranty: datas.Product_Warranty,
            productwarrantytype: datas.Product_Warranty_Type,
            productmodelno: datas.Product_Model_No,
            customerofferPrice: datas.Customer_Offer_Price,
            retailerofferPrice: datas.Retailer_Offer_Price,
            distributorofferPrice: datas.Distributor_Offer_Price,
            keyaccoutofferPrice: datas.Keyaccout_Offer_Price,
            corporateofferPrice: datas.Corporate_Offer_Price,
            employeeofferPrice: datas.Employee_Offer_Price,
            customerdiscountpercentage: datas.Customer_Discount_Percentage,
            retailerdiscountpercentage: datas.Retailer_Discount_Percentage,
            distributordiscountpercentage:
              datas.Distributor_Discount_Percentage,
            keyaccountdiscountpercentage: datas.Keyaccount_Discount_Percentage,
            corporatediscountpercentage: datas.Corporate_Discount_Percentage,
            empolyeediscountpercentage: datas.Empolyee_Discount_Percentage,
            productfeatures: datas.Product_Features,
            productbrand: datas.Product_Brand,
            totalstock: datas.Total_Stock,
            quantitycase: datas.Quantity_Case,
            hsncode: datas.HSN_Code,
            free: datas.Free,
          })),
        },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Products Added Successfully");
        window.location.assign("/admin/products");
        // localStorage.setItem("signupdata",JSON.stringify(res.data.user));
        // localStorage.setItem("token",res.data.token);
        // window.location.assign("/verify-otp");
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postimage = async (e) => {
    e.preventDefault();
    Array.from(image).forEach((file) => {
      formdata.append("productimage", file);
    });
    // image.map((d)=>{

    // })

    try {
      const config = {
        url: "/admin/productimage",
        method: "post",
        baseURL: apiurl,
        // headers:{ 'Authorization':"Bearer "+token},
        data: formdata,

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Image Uploaded Successfully");
        // window.location.assign("/pharmacy/product");
        // localStorage.setItem("signupdata",JSON.stringify(res.data.user));
        // localStorage.setItem("token",res.data.token);
        // window.location.assign("/verify-otp");
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const remove = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/deleteproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const subscribe = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/subscribeproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unsubscribe = async (data) => {
    try {
      axios
        .post(`${apiurl}/admin/unsubscribeproduct/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: product.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/subcategory/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "productcategory",
      text: "Category",
      sort: true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },
    {
      dataField: "productsubcategory",
      text: "Subcategory",
      sort: true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },
    {
      dataField: "productname",
      text: "Product Name",
      // formatter: imageFormatter
    },
    {
      dataField: "productprice",
      text: "Actual Price",
      // formatter: imageFormatter
    },
    {
      dataField: "productdesc",
      text: "Product Description",
      // formatter: imageFormatter
    },
    {
      dataField: "productfeatures",
      text: "Product Features",
      // formatter: imageFormatter
    },
    {
      dataField: "productbrand",
      text: "Product Brand",
      // formatter: imageFormatter
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div className="d-flex">
            <button className="btn btn-danger" onClick={() => remove(row)}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  var i = 1;
  return (
    <div className="mt-3">
      <div className="d-flex">
        <a href="addproducts" className="btn btn-primary mb-2 me-2">
          Add Product
        </a>
        <button className="btn btn-outline-info me-2">
          <a
            style={{ textDecoration: "none" }}
            href={imageURL + "/excelfile/productexcel.xlsx"}
          >
            Download Excel
          </a>
        </button>
        <input
          accept=".xlsx,.xls"
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
          onChange={(e) => setexcel(e.target.files[0])}
        />
        <label className="btn btn-outline-info mt-2" htmlFor="icon-button-file">
          {" "}
          Import Excel
        </label>
        <button
          className="btn btn-outline-success ms-2 me-2"
          onClick={postorders}
        >
          Upload
        </button>
        <input
          accept="image/*, video/*"
          style={{ display: "none" }}
          id="icon-button-file1"
          type="file"
          multiple
          onChange={(e) => setimage(e.target.files)}
        />
        <label
          className="btn btn-outline-info mt-2"
          htmlFor="icon-button-file1"
        >
          {" "}
          Select Images
        </label>
        <button
          className="btn btn-outline-success ms-2 me-2"
          onClick={postimage}
        >
          Upload Images
        </button>
      </div>
      <div className="mt-3">
        {/* <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Sl. No</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th> Product Image</th>
              <th> Product Name</th>
              <th>Actual Price</th>
              <th>Offer Price</th>
              <th>Product Description</th>
              <th>Product Features</th>
              <th>Product Brand</th>
              <th> Action</th>
            </tr>
          </thead>
          <tbody>
            {product?.map((product) => (
              <tr>
                <td>{i++}</td>
                <td>{product.productcategory}</td>
                <td>{product.productsubcategory}</td>
                <td>{product.productImg}</td>
                <td>{product.productname}</td>
                <td>{product.productprice}</td>
                <td>{product.offerPrice}</td>
                <td>{product.productdesc}</td>
                <td>{product.productfeatures}</td>
                <td>{product.productbrand}</td>
                <td>
                  <button className="btn btn-danger" onClick={()=>remove(product)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        <ToolkitProvider
          keyField="id"
          data={product}
          columns={columns}
          search
          exportCSV={{
            fileName: "Products.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pr-5 mr-auto">
                <ExportCSVButton
                  className="btn-outline-success"
                  {...props.csvProps}
                >
                  Export to CSV
                </ExportCSVButton>
              </span>
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={product}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Vendorproduct;
