import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";

function Userdata() {
  const [data, setdata] = useState([]);
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getamount();
  }, []);

  const getamount = async () => {
    let res = await axios.get(apiurl + "/user/getuser");
    if ((res.status = 200)) {
      console.log(res.data);
      setdata(res.data.user);
    }
  };

  var i = 1;

  return (
    <div className="py-5">
      <h2>User Data</h2>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>FirstName</th>
            <th>LastName</th>
            <th>Email</th>
            <th>Password</th>
            <th>Confirm Password</th>
            <th>OTP</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((user) => (
            <tr>
              <td>{i++}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.password}</td>
              <td>{user.confirmPassword}</td>
              <td>{user.otp}</td>
              <td>
                <button className="btn btn-danger">Block</button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Userdata;
