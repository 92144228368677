import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Styles/footer.css";

function Footer() {
  return (
    <>
      <section className="common-use" style={{ paddingBottom: "0px" }}>
        <div className="footer">
          <Container>
            <div className="japggery_0 mb-3">
              <Row>
                <Col lg={3} md={3}>
                  <div className="single-service mb-2">
                    <li className="service-content">
                      <span>Free delivery</span>
                      <a>
                        <i
                          class="fa fa-truck"
                          aria-hidden="true"
                          style={{ color: "white" }}
                        ></i>
                      </a>
                    </li>
                  </div>
                </Col>
                <Col lg={3} md={3}>
                  <div className="single-service mb-2">
                    <li className="service-content">
                      <span>Cash on delivery</span>
                      <a>
                        <i
                          class="fa fa-money"
                          aria-hidden="true"
                          style={{ color: "white" }}
                        ></i>
                      </a>
                    </li>
                  </div>
                </Col>
                <Col lg={3} md={3}>
                  <div className="single-service mb-2">
                    <li className="service-content">
                      <span>30 days returns</span>
                      <a>
                        <i
                          class="fa fa-refresh"
                          aria-hidden="true"
                          style={{ color: "white" }}
                        ></i>
                      </a>
                    </li>
                  </div>
                </Col>
                <Col lg={3} md={3}>
                  <div className="single-service mb-2">
                    <li className="service-content">
                      <span>Online support</span>
                      <a>
                        <i
                          class="fa fa-headphones"
                          aria-hidden="true"
                          style={{ color: "white" }}
                        ></i>
                      </a>
                    </li>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="bottom_0 mb-3 mt-3"></div>
          </Container>

          <Container>
            <Row>
              <Col lg={4} md={4}>
                <div className="logo">
                  <div>
                    <img
                      src="/newS.png"
                      className="img-fluid"
                      alt="Vegist"
                      style={{ width: "200px", height: "130px" }}
                      loading="lazy"
                    />
                    <p>
                    Spilso consumer products pvt ltd
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg={4} md={4}>
                <div className="address">
                  <div className="address-icon">
                    <span>
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </span>
                    <div>
                      <h6>Address</h6>
                      <p>
                      No 3 1st floor 10 Cross opp to CBI office
                        <br />
                        Ganganagar CBI road bangalore 560024.
                        <div>
                          <a href="/Privacypolicy">Privacy Policy</a>
                        </div>
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="contact" style={{ paddingTop: "0px" }}>
                  <div className="address-icon">
                    <span>
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    <div>
                      <h6>Get in touch</h6>
                      <div className="jgp">+91 9845091018</div>
                      <div className="jgp">+91 8041666123</div>
                    </div>
                  </div>
                  <div className="play_0 mt-2">
                    <img src="/download1.png" alt="app" />
                    <img src="/download2.png" alt="app" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="diff">
            <>
              <div className="social">
                <div className="social_0">
                  <p
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontFamily: " monospace",
                    }}
                  >
                    Copyright 2023-24 Japggery all rights reserved
                  </p>
                </div>
                <div className="social_0">
                  <p
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontFamily: " monospace",
                    }}
                  >
                    Design & Developed By{" "}
                    <a
                      href="https://parnetsgroup.com/"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <span>Par</span>
                      <span>Nets</span>
                    </a>
                  </p>
                </div>
                <div className="sociale_icons">
                  <ul>
                    <li className="facebook-0">
                      <a href="#">
                        <i class="fa fa-whatsapp"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
