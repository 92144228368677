import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Container,
  Row,
  InputGroup,
} from "react-bootstrap";

function Customerverifyotp() {
  const [otp, setotp] = useState("");
  const no = sessionStorage.getItem("phoneno");
  const apiURL = process.env.REACT_APP_API_URL;

  const handleverify = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/verifycustomerotp",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: { otp: otp, phoneNumber: no },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);

        if (res.data.customer.userRole === 0) {
          if (res.data.customer.profilestatus) {
            window.location.assign("/");
            alert("Welcome Back");
            sessionStorage.setItem("user", JSON.stringify(res.data.customer));
          } else {
            alert("Not Yet Registered please Register");
            window.location.assign("/signup");
          }
        }
        return res;
      }
    } catch (error) {
      alert("invalid otp");
      console.log(error);
    }
  };

  return (
    <div style={{ background: "#f6f8fc" }}>
      <div className="container pt-5">
        <div className="row" style={{ padding: " 50px 30px" }}>
          <div className="col-md-6 ">
            <img
              src="https://feelmyworth.com/wp-content/uploads/2021/06/outlet-shopping.jpg"
              style={{ width: "100%", height: "500px" }}
            ></img>
            {/* <p className="t-c pt-3"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p> */}
          </div>
          <div className="col-md-6">
            <form validated>
              <div className="row ">
                <div className="col-md-12 t-c ">
                  <h1 className="" style={{ padding: "20px 20px 50px" }}>
                    Sign In{" "}
                  </h1>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="formBasic">
                    <Form.Label>
                      Otp<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <InputGroup>
                      {/* <InputGroup.Prepend>
                <InputGroup.Text>+91</InputGroup.Text>
                </InputGroup.Prepend> */}
                      <Form.Control
                        type="text"
                        name="otp"
                        onChange={(e) => setotp(e.target.value)}
                        placeholder="Enter Otp"
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                <div
                  className="col-md-12 pt-2"
                  style={{ padding: "20px 20px 10px", float: "right" }}
                >
                  <div className="form-gropu pt-2">
                    <button
                      className="btn btn-primary form-control"
                      type="submit"
                      style={{
                        padding: "5px",
                        background:
                          "linear-gradient(45deg, var(--bs-pink), var(--bs-purple))",
                      }}
                      onClick={handleverify}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customerverifyotp;
