import { React, Fragment } from "react";

function Footer() {
  return (
    <div>
      <Fragment>
        <footer
          style={{ background: "#303031", color: "#87898A" }}
          className="z-10 py-6 px-4 md:px-12 text-center"
        >
          Develop & Design ParNets © Copyright
          {/* {moment().format("YYYY")} */}
        </footer>
      </Fragment>
    </div>
  );
}

export default Footer;
