import { IconButton } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

function Retailerprofile() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const apiURL = process.env.REACT_APP_API_URL;
  const [profilephoto, setprofilephoto] = useState();
  const [profile, setprofile] = useState();
  const [emptyprofile, setemptyprofile] = useState(
    "https://www.dgvaishnavcollege.edu.in/dgvaishnav-c/uploads/2021/01/dummy-profile-pic.jpg"
  );
  const formdata = new FormData();
  const [isOpen1, setIsOpen1] = useState(false);

  const [bankname, setbankname] = useState(user.bankname);
  const [firmname, setfirmname] = useState(user.firmname);
  const [email, setemail] = useState(user.email);
  const [accontno, setaccontno] = useState(user.accontno);
  const [branch, setbranch] = useState(user.branch);
  const [bankcode, setbankcode] = useState(user.bankcode);
  const [panno, setpanno] = useState(user.panno);
  const [gstno, setgstno] = useState(user.gstno);
  const [villagename, setvillagename] = useState(user.villagename);
  const [addresslandmark, setaddresslandmark] = useState(user.addresslandmark);
  const [taluka, settaluka] = useState(user.taluka);
  const [districtname, setdistrictname] = useState(user.districtname);
  const [state, setstate] = useState(user.state);
  const [pincode, setpincode] = useState(user.pincode);
  useEffect(() => {
    getprofilephoto();
  }, []);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  useEffect(() => {
    if (profile) {
      postprofilephoto();
    }
  }, [profile]);
  const getprofilephoto = () => {
    try {
      axios
        .get(`${apiURL}/getretailerprofile/` + user._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            if (response.data.shopimg?.shopimg) {
              console.log(true);
              setprofilephoto(response.data.shopimg?.shopimg);
            }
          }
        })
        .catch(function (error) {
          return;
        });
    } catch (error) {
      console.warn(error);

      return error;
    }
  };
  const signout = () => {
    try {
      axios
        .get(`${apiURL}/retailer/signout/${user._id}`)
        .then(function (res) {
          if (res.status === 200) {
            alert("signout Successfull");
            localStorage.removeItem("phoneno");
            localStorage.removeItem("user");
            window.location.assign("/");
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("signout not Successfull");
        });
    } catch (error) {
      console.warn(error);
      alert("signout not Successfull");
    }
  };
  const postprofilephoto = async () => {
    try {
      formdata.append("retailerId", user._id);
      formdata.append("shopimg", profile);
      const config = {
        url: "/postretailerprofile",
        method: "post",
        baseURL: apiURL,
        header: { "Content-Type": "multipart/formdata" },
        data: formdata,
      };

      let res = await axios(config);

      if (res.status === 200) {
        console.log(res.data);
        sessionStorage.setItem("user", JSON.stringify(res.data?.user));

        setprofile();
        getprofilephoto();
        window.location.reload();
      }
    } catch (error) {
      console.warn(error);

      return error;
    }
  };
  function validategst(inputtxt) {
    var phoneno = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid gst number!");
      return false;
    }
  }

  function panverification(inputtxt) {
    var phoneno = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid pan number!");
      return false;
    }
  }

  function pincodecheck(inputtxt) {
    // var phoneno = /^[6-9]\d{9}$/;
    var Pincode = /^[0-9]{6}$/;
    if (inputtxt.match(Pincode)) {
      return true;
    } else {
      alert("You have entered an invalid pincode!");
      return false;
    }
  }

  const editprofile = async () => {
    if (validategst(gstno) && panverification(panno) && pincodecheck(pincode)) {
      try {
        const config = {
          url: "/retailer/updateretailer",
          method: "post",
          baseURL: apiURL,
          headers: { "content-type": "application/json" },
          data: {
            retailerId: user._id,
            firmname: firmname,
            email: email,
            bankname: bankname,
            accontno: accontno,
            branch: branch,
            bankcode: bankcode,
            panno: panno,
            gstno: gstno,
            villagename: villagename,
            addresslandmark: addresslandmark,
            taluka: taluka,
            districtname: districtname,
            state: state,
            pincode: pincode,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          sessionStorage.setItem("user", JSON.stringify(res.data?.user));
          hideModal1();
          alert("Profile is Edited Successfully");
          window.location.reload();

          return res;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div>
      <div style={{ marginTop: "2rem" }}>
        <Container>
          <h3 className="mt-1 mb-3">
            Profile{" "}
            <span className="ms-2">
              *
              <i
                class="fa fa-pencil"
                style={{ color: "red" }}
                onClick={() => showModal1()}
              ></i>
            </span>
          </h3>
          <Row>
            <Col md={3}>
              <div style={{ marginLeft: "30px" }}>
                <img
                  src={
                    profile === undefined && profilephoto === undefined
                      ? emptyprofile
                      : profilephoto !== undefined
                      ? "https://ilifeshoppie.info/retailer/" +
                        profilephoto
                      : URL.createObjectURL(profile)
                  }
                  // alt="avatar"
                  className=" "
                  width="200px"
                  height="200px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ marginLeft: "50px" }}>
                <div className="button">
                  <input
                    accept="image/*, video/*"
                    style={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => setprofile(e.target.files[0])}
                  />
                  <label className="mt-1" htmlFor="icon-button-file">
                    <IconButton
                      aria-label="upload picture"
                      component="span"
                      style={{ fontSize: "2rem" }}
                    >
                      <PhotoCamera />{" "}
                      <span style={{ fontSize: "20px" }}>
                        * Choose from file
                      </span>
                    </IconButton>
                  </label>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="">
                <div>
                  <h5 className="mt-2 mb-2" style={{ color: "#bc3364" }}>
                    Personal details
                  </h5>
                  <div>
                    <b>Name :</b>
                    <span>{user.firmname}</span>
                  </div>
                  <div>
                    <b>Partnership :</b>
                    <span>{user.partnership}</span>
                  </div>
                  <div>
                    <b>Partners Name :</b>
                    <span>{user.partnersname}</span>
                  </div>
                  <div>
                    <b>Contact Number :</b>
                    <span>{user.permanentno}</span>
                  </div>
                  <div>
                    <b>Email :</b>
                    <span>{user.email}</span>
                  </div>
                </div>
                <div className="mt-2">
                  <h5 className="mb-2" style={{ color: "#bc3364" }}>
                    Bank Details
                  </h5>
                  <div>
                    <b>Bank Name :</b>
                    <span>{user.bankname}</span>
                  </div>
                  <div>
                    <b>Account Number :</b>
                    <span>{user.accontno}</span>
                  </div>
                  <div>
                    <b>Branch :</b>
                    <span>{user.branch}</span>
                  </div>
                  <div>
                    <b>Ifsc Code :</b>
                    <span>{user.bankcode}</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <div className="mt-2">
                  <h5 className="mb-2" style={{ color: "#bc3364" }}>
                    Id details
                  </h5>
                  <div>
                    <b>Pan no :</b>
                    <span>{user.panno}</span>
                  </div>
                  <div>
                    <b>GSt number :</b>
                    <span>{user.gstno}</span>
                  </div>
                </div>
                <div className="mt-2">
                  <h5 className="mb-2" style={{ color: "#bc3364" }}>
                    Contact Details
                  </h5>
                  <div>
                    <b>Address:</b>
                    <span>
                      ,{user.villagename},{user.addresslandmark},{user.taluka},
                      {user.districtname},{user.state}
                    </span>
                  </div>
                  <div>
                    <b>Pincode :</b>
                    <span>{user.pincode}</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
            show={isOpen1}
            onHide={hideModal1}
          >
            <Modal.Header closeButton className="mt-4">
              <Modal.Title>Edit Profile </Modal.Title>
            </Modal.Header>

            <div className="ms-2 me-2">
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">Firm Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setfirmname(e.target.value)}
                  placeholder={user.firmname}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">Email</Form.Label>
                <Form.Control
                  type="email"
                  onChange={(e) => setemail(e.target.value)}
                  placeholder={user.email}
                />
              </Form.Group>
              <h5>Id Proofs</h5>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">PAN no</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setpanno(e.target.value)}
                  placeholder={user.panno}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">Gst NO</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setgstno(e.target.value)}
                  placeholder={user.gstno}
                />
              </Form.Group>
              <h5>Bank details</h5>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setbankname(e.target.value)}
                  placeholder={user.bankname}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">
                  Account Number
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) => setaccontno(e.target.value)}
                  placeholder={user.accontno}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">Branch</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setbranch(e.target.value)}
                  placeholder={user.branch}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">IFSC code</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setbankcode(e.target.value)}
                  placeholder={user.bankcode}
                />
              </Form.Group>
              <h5>Address Change</h5>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">
                  Village Name
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setvillagename(e.target.value)}
                  placeholder={user.villagename}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">
                  Address Landmark
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setaddresslandmark(e.target.value)}
                  placeholder={user.addresslandmark}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">Taluka</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => settaluka(e.target.value)}
                  placeholder={user.taluka}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">
                  District Name
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setdistrictname(e.target.value)}
                  placeholder={user.districtname}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">State</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setstate(e.target.value)}
                  placeholder={user.state}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">Pincode</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) => setpincode(e.target.value)}
                  placeholder={user.pincode}
                />
              </Form.Group>
            </div>
            <Modal.Footer>
              <Button variant="success" onClick={hideModal1}>
                Close
              </Button>
              <Button variant="danger" onClick={editprofile}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </div>
  );
}

export default Retailerprofile;
