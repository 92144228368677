import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import "../Styles/singal.css";
import { Tooltip } from "antd";
import OwlCarousel from "react-owl-carousel";
import Fakedata from "./FakeData/FakeData";
import { useParams } from "react-router-dom";
import axios from "axios";

function Productdetails() {
  const [previewImg, setPreviewImg] = useState("");
  const [showMore, setShowMore] = useState(false);
  const { id } = useParams();
  const [onProduct, setonproduct] = useState({});
  const [AllPrice, setAllPrice] = useState([]);
  const [products, setProduct] = useState([]);

  const getAllProduct = async () => {
    try {
      const config = {
        url: "/product/",
        method: "get",
        baseURL: "https://spilso.in/api/admin",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setProduct(res.data.products);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const getProductbyId = async () => {
    try {
      let res = await axios.get(
        "https://spilso.in/api/admin/getSingleProductById/" + id
      );
      if (res.status === 200) {
        setonproduct(res.data.success);
        setViewdeata(res.data.success);
        setAllPrice(res.data.success?.AllPrice);
        setPreviewImg(
          `https://spilso.in/admin/product/${res.data.success?.productimage[0]}`
        );
        getAllProduct();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [acc1, setacc1] = useState("true");
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);

  const [tab, settab] = useState(true);
  const [tab1, settab1] = useState();
  const [tab2, settab2] = useState();
  const [review, setreview] = useState("");
  // const [products, setproducts] = useState(Fakedata);

  const  responsive={
    0:{
      items:2
    },
    600:{
      items:2
    },
    1000:{
      items:5
    }
  }

  const user = JSON.parse(sessionStorage.getItem("customer"));
  // const [showMore, setShowMore] = useState(false);
  const [viewData, setViewdeata] = useState({});
  const [selPrice, setslePrice] = useState(0);
  const [selQuntity, setselQuntity] = useState(0);
  console.log("seleted price==>", selQuntity, selPrice, AllPrice);
  const [image, setImage] = useState("");
  const [quantity, setQuntity] = useState(1);
  const incQuntity = () => {
    setQuntity(quantity + 1);
  };
  const decQuntity = () => {
    if (quantity > 1) {
      setQuntity(quantity - 1);
    }
  };
  const removewishlist = async (item) => {
    if (!user) {
      alert("Need to Login");
    } else {
      axios({
        method: "delete",
        url:
          "https://spilso.in/api/removeWishlistbcustomeryId/" +
          user.id +
          "/" +
          item?._id,
      })
        .then(function (response) {
          //handle success
          console.log(response);
          alert("Product removed from wishlist");
          getwislist();
          window.location.reload();
        })
        .catch(function (error) {
          //handle error
          console.log(error.response.data);
        });
    }
  };
  const addwishlist = async (item) => {
    if (user) {
      try {
        const config = {
          url: "/addWhishList",
          method: "post",
          baseURL: "https://spilso.in/api",
          data: {
            productId: item._id,
            customerId: user.id,
            quantity: quantity,
            price: selPrice ? selPrice : Number(item?.productprice),
            Size: selQuntity
              ? selQuntity + item?.productvolumetype
              : "1" + item?.productvolumetype,
            totalPrice: selPrice
              ? Math.round(
                  (Number(selPrice) +
                    Math.round(selPrice * (item?.tax / 100)) -
                    (Number(selPrice) +
                      Math.round(selPrice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                )
              : Math.round(
                  (Number(item?.productprice) +
                    Math.round(item?.productprice * (item?.tax / 100)) -
                    (Number(item?.productprice) +
                      Math.round(item?.productprice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                ),
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            console.log("success");
            alert("Added to wishlist");
            window.location.reload();
          }
        });
      } catch (error) {
        console.log(error);
        alert("product not added");
      }
    } else {
      alert("Need to Login");
    }
  };
  const addCart = async (item) => {
    if (user) {
      try {
        const config = {
          url: "/addToCartCustomer",
          method: "post",
          baseURL: "https://spilso.in/api",
          data: {
            productId: item._id,
            customerId: user.id,
            quantity: quantity,
            price: selPrice ? selPrice : Number(item?.productprice),
            Size: selQuntity
              ? selQuntity + item?.productvolumetype
              : "1" + item?.productvolumetype,
            totalPrice: selPrice
              ? Math.round(
                  (Number(selPrice) +
                    Math.round(selPrice * (item?.tax / 100)) -
                    (Number(selPrice) +
                      Math.round(selPrice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                )
              : Math.round(
                  (Number(item?.productprice) +
                    Math.round(item?.productprice * (item?.tax / 100)) -
                    (Number(item?.productprice) +
                      Math.round(item?.productprice * (item?.tax / 100))) *
                      (item.customerdiscountpercentage / 100)) *
                    quantity
                ),
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            console.log("success");
            alert("Added to Cart");
            window.location.reload();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    } else {
      alert("Need to Login");
    }
  };

  const [wishlist, setwishlist] = useState([]);
  const getwislist = () => {
    axios
      .get("https://spilso.in/api/getWishlistByCustomerId/" + user?.id)
      .then(function (response) {
        console.log(response.data);
        setwishlist(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [Carts, setCarts] = useState([]);

  const getCatrDeatils = () => {
    axios
      .get("https://spilso.in/api/getAllCartProductsByCustomer/" + user?.id)
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const removewCart = async (item) => {
    if (!user) {
      alert("Need to Login");
    } else {
      try {
        const config = {
          url: "/removeInCartOfCustomer",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            productId: item?._id,
            customerId: user?.id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Product removed from wishlist");
          window.location.reload();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };

  useEffect(() => {
    getProductbyId();
    getAllProduct();
    if (user) {
      getCatrDeatils();
      getwislist();
    }
  }, []);
  return (
    <>
      <section style={{ marginTop: "20px" }}>
        <Container>
          <Row>
            <Col lg="12" md="12">
              <div className="singal_hero">
                <Row>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-3">
                        <div
                          className="product__images mt-2 "
                          style={{ gap: "5px" }}
                        >
                          {onProduct?.productimage?.map((item) => {
                            return (
                              <div
                                className={`img__item mb-3 ${
                                  acc1 ? "tab-0-active" : ""
                                }`}
                                onClick={() => {
                                  setPreviewImg(
                                    `https://spilso.in/admin/product/${item}`
                                  );
                                  setacc1(true);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                }}
                              >
                                <img
                                  src={`https://spilso.in/admin/product/${item}`}
                                  alt=""
                                  className=" img-fluid wt-50"
                                />
                              </div>
                            );
                          })}
                          {/* <div
                            className={`img__item mb-2 ${
                              acc1 ? "tab-0-active" : ""
                            }`}
                            onClick={() => {
                              setPreviewImg("/webImages/apple1.jpg");
                              setacc1(true);
                              setacc2(false);
                              setacc3(false);
                              setacc4(false);
                            }}
                          >
                            <img
                              src="/webImages/apple1.jpg"
                              alt=""
                              className=" img-fluid wt-50"
                            />
                          </div>
                          <div
                            className={`img__item mb-2 ${
                              acc2 ? "tab-0-active" : ""
                            }`}
                            onClick={() => {
                              setPreviewImg("/webImages/apple2.avif");
                              setacc1(false);
                              setacc2(true);
                              setacc3(false);
                              setacc4(false);
                            }}
                          >
                            <img
                              src="/webImages/apple2.avif"
                              alt=""
                              className="img-fluid wt-50"
                            />
                          </div>

                          <div
                            className={`img__item mb-2 ${
                              acc3 ? "tab-0-active" : ""
                            }`}
                            onClick={() => {
                              setPreviewImg("/webImages/apple3.avif");
                              setacc1(false);
                              setacc2(false);
                              setacc3(true);
                              setacc4(false);
                            }}
                          >
                            <img
                              src="/webImages/apple3.avif"
                              alt=""
                              className="img-fluid wt-50"
                            />
                          </div>
                          <div
                            className={`img__item mb-3 ${
                              acc4 ? "tab-0-active" : ""
                            }`}
                            onClick={() => {
                              setPreviewImg("/webImages/apple4.avif");
                              setacc1(false);
                              setacc2(false);
                              setacc3(false);
                              setacc4(true);
                            }}
                          >
                            <img
                              src="/webImages/apple4.avif"
                              alt=""
                              className="img-fluid wt-50"
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="singal_hero_0">
                          <div className="product__main-img">
                            <img
                              src={previewImg}
                              alt=""
                              className=" w-100"
                              style={{ height: "370px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="product-details"
                      style={{ paddingTop: "0px", display: "flex" }}
                    >
                      <div className="product_details_info">
                        <div class="product-title">
                          <h2>
                            <b>{onProduct?.productname}</b>
                          </h2>
                        </div>
                        {/* <div className="product-ratting mb-2">
                          <span
                            className="spr-badge"
                            id="spr_badge_6820894441621"
                            data-rating="2.0"
                          >
                            <span class="spr-starrating spr-badge-starrating">
                              <i class="fa fa-star" aria-hidden="true"></i>
                              <i class="fa fa-star" aria-hidden="true"></i>
                              <i class="fa fa-star" aria-hidden="true"></i>
                              <i class="fa fa-star" aria-hidden="true"></i>
                              <i class="fa fa-star" aria-hidden="true"></i>
                            </span>
                          </span>
                        </div> */}
                        <div class="pro-price-label mb-2">
                          <div class="price-box">
                            {selPrice !== 0 ? (
                              <span className="new-price" id="ProductPrice">
                                {selQuntity}
                                {viewData?.productvolumetype}
                                {"-"}₹
                                {Math.round(
                                  (Number(selPrice) +
                                    Math.round(
                                      selPrice * (viewData?.tax / 100)
                                    ) -
                                    (Number(selPrice) +
                                      Math.round(
                                        selPrice * (viewData?.tax / 100)
                                      )) *
                                      (viewData.customerdiscountpercentage /
                                        100)) *
                                    quantity
                                )}
                              </span>
                            ) : (
                              <span className="new-price" id="ProductPrice">
                                ₹
                                {Math.round(
                                  (Number(viewData?.productprice) +
                                    Math.round(
                                      viewData?.productprice *
                                        (viewData?.tax / 100)
                                    ) -
                                    (Number(viewData?.productprice) +
                                      Math.round(
                                        viewData?.productprice *
                                          (viewData?.tax / 100)
                                      )) *
                                      (viewData.customerdiscountpercentage /
                                        100)) *
                                    quantity
                                )}
                              </span>
                            )}
                            {selPrice !== 0 ? (
                              <span className="old-price" id="ComparePrice">
                                ₹
                                {Number(selPrice * quantity) +
                                  Math.round(
                                    selPrice * quantity * (viewData?.tax / 100)
                                  )}
                              </span>
                            ) : (
                              <span className="old-price" id="ComparePrice">
                                ₹
                                {Number(viewData?.productprice * quantity) +
                                  Math.round(
                                    viewData?.productprice *
                                      quantity *
                                      (viewData?.tax / 100)
                                  )}
                              </span>
                            )}
                          </div>

                          <div class="product-label">
                            <span class="sale-title" id="ProductDiscount">
                              {onProduct?.customerdiscountpercentage}%
                            </span>
                          </div>
                        </div>
                        <div class="product-inventory d-flex mb-2">
                          <h6>Availability:</h6>

                          <span class="in-stock text-success">
                            {onProduct?.remainingstock ? (
                              <span>
                                In Stock<i class="ti-check"></i>
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                Out of Stock<i class="ti-check"></i>
                              </span>
                            )}
                          </span>
                        </div>
                        <div class="product-description mb-2">
                          {showMore
                            ? onProduct?.productdesc
                            : `${onProduct?.productdesc?.substring(0, 200)}`}
                          <button
                            className="btn"
                            onClick={() => setShowMore(!showMore)}
                          >
                            {showMore ? "Show less" : "Show more"}
                          </button>
                        </div>
                        <div className="quick-view-select mb-3">
                          <div className="selector-wrapper">
                            <label>Size : </label>
                            <select
                              class="single-option-selector"
                              data-option="option1"
                              id="product-select-qv-option-0"
                              onChange={(e) => {
                                setslePrice(
                                  JSON.parse(e.target.value)?.quntityPrice
                                );
                                setselQuntity(
                                  JSON.parse(e.target.value)?.perQuntity
                                );
                              }}
                            >
                              {viewData?.AllPrice?.map((ele) => {
                                return (
                                  <option value={JSON.stringify(ele)}>
                                    {ele?.perQuntity}
                                    {viewData?.productvolumetype}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="product-quantity mb-3 d-flex">
                          <b>Quntity :</b>{" "}
                          <div className="cart-plus-minus">
                            <div className="dec qtybutton" onClick={decQuntity}>
                              -
                            </div>
                            <input
                              value={quantity}
                              type="text"
                              name="quantity"
                              min="1"
                              pattern="[0-9]*"
                            />
                            <div className="inc qtybutton" onClick={incQuntity}>
                              +
                            </div>
                          </div>
                        </div>
                        <div className="pro-detail-button-0 mb-2">
                          {wishlist.filter(
                            (item) => item?.productId?._id === onProduct._id
                          )?.length ? (
                            <Tooltip
                              title="REMOVE-WISHLIST"
                              onClick={() => removewishlist(onProduct)}
                            >
                              {" "}
                              <span
                                id="app-title"
                                style={{
                                  opacity: "1",
                                  background: "red",
                                  color: "white",
                                  fontSize: "16px",
                                  lineHeight: 0,
                                  height: "40px",
                                  width: "40px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "50%",
                                  marginRight: "15px",
                                }}
                              >
                                <i class="fa fa-heart"></i>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="WISHLIST"
                              onClick={() => addwishlist(onProduct)}
                            >
                              {" "}
                              <span
                                class="add-wishlist"
                                id="app-title"
                                style={{ opacity: "1" }}
                              >
                                <i class="fa fa-heart"></i>
                              </span>
                            </Tooltip>
                          )}

                          {Carts?.filter(
                            (item) => item?.productId?._id === onProduct._id
                          )?.length ? (
                            <Tooltip
                              title="REMOVE TO CART"
                              onClick={() => removewCart(onProduct)}
                            >
                              {" "}
                              <span
                                class="add-wishlist"
                                style={{
                                  opacity: "1",
                                  background: "red",
                                  color: "white",
                                  fontSize: "16px",
                                  lineHeight: 0,
                                  height: "40px",
                                  width: "40px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "50%",
                                  marginRight: "15px",
                                }}
                              >
                                <i class="fa fa-shopping-bag"></i>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="ADD TO CART"
                              onClick={() => addCart(onProduct)}
                            >
                              {" "}
                              <span
                                class="add-wishlist"
                                style={{ opacity: "1" }}
                              >
                                <i class="fa fa-shopping-bag"></i>
                              </span>
                            </Tooltip>
                          )}
                          {/* <div className="wish-09">
                            <span>BUY NOW</span>
                          </div> */}
                        </div>
                        {/* <div class="product-sku">
                          <h6>SKU:</h6>
                          <span class="variant-sku" id="variantsku">
                            123456
                          </span>
                        </div> */}
                      </div>
                      {onProduct?.isCombo === true ? (
                        <div className="product_details_info">
                          <div class="product-title">
                            <img
                              src="/combo.jpg"
                              alt="g"
                              style={{ width: "80px", height: "83px" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {onProduct?.isFree === true ? (
                        <div className="product_details_info">
                          <div>
                            <img
                              src={`https://spilso.in/admin/product/${onProduct?.freeProImage}`}
                              alt=""
                              className=" img-fluid wt-50"
                            />
                          </div>
                          <div class="product-title">
                            <h2>
                              <b>{onProduct?.freeProductName}</b>
                            </h2>
                            <div class="pro-price-label mb-2">
                              <div class="price-box">
                                <span class="old-price" id="ComparePrice">
                                  ₹{onProduct?.freePrice}
                                </span>
                                <span>
                                  <img
                                    src="/5.png"
                                    alt="g"
                                    style={{ width: "50px", height: "42px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div class="product-description mb-2">
                              {onProduct?.freeProdesc}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {onProduct?.isByOnegetFreeCust === true ? (
                        <div className="product_details_info">
                          <div>
                            <img
                              src="/free1.png"
                              alt=""
                              className=" img-fluid wt-50"
                            />
                          </div>
                          {/* <div class="product-title">
                          <h2>
                            <b>Fresh green orange</b>
                          </h2>
                          <div class="pro-price-label mb-2">
                          <div class="price-box">
                            
                            <span class="old-price" id="ComparePrice">
                              ₹15,00
                            </span>
                            <span><img src="/5.png" alt="g" style={{width:"50px",height:"42px"}}/></span>
                          </div> 
                        </div>
                        <div class="product-description mb-2">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </div>
                        </div>    */}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>

          <div className="uni-details">
            <div className="uni-details-hero">
              <ul className="presentation">
                <li
                  className={`des-0__0 ${tab ? "pre-active" : ""}`}
                  onClick={() => {
                    settab(true);
                    settab1(false);
                    settab2(false);
                  }}
                >
                  {" "}
                  Description
                </li>
                <li
                  className={`des-0__0 ${tab1 ? "pre-active" : ""}`}
                  onClick={() => {
                    settab(false);
                    settab1(true);
                    settab2(false);
                  }}
                >
                  {" "}
                  Additional information
                </li>
                {/* <li
                  className={`des-0__0 ${tab2 ? "pre-active" : ""}`}
                  onClick={() => {
                    settab(false);
                    settab1(false);
                    settab2(true);
                  }}
                >
                  {" "}
                  Reviews
                </li> */}
              </ul>

              <div className="">
                {tab ? (
                  <>
                    <div className="M-0_0  mb-2">
                      <h5 className="des-0__0">Key Specification/Benefits</h5>
                      <ul>
                        <li>{onProduct?.specification}</li>
                      </ul>
                    </div>
                    <div className="M-0_0  mb-2">
                      <h5 className="des-0__0">How to Use</h5>
                      <ul>
                        <li>{onProduct?.howTostore}</li>
                      </ul>
                    </div>

                    {/* <div className="M-0_0  mb-2">
                      <h5 className="des-0__0">Storage</h5>
                      <ul>
                        <li>
                        Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry
                        </li>
                      </ul>
                    </div> */}
                  </>
                ) : (
                  <>
                    {tab1 ? (
                      <>
                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Vendor:</td>
                              <td>{onProduct?.vendor}</td>
                            </tr>
                            <tr>
                              <td>Size:</td>
                              <td>
                                {AllPrice?.map((ele) => {
                                  return (
                                    <span>
                                      {ele?.perQuntity}
                                      {onProduct?.productvolumetype}
                                      {", "}
                                    </span>
                                  );
                                })}
                              </td>
                            </tr>
                            <tr>
                              <td>Product Id</td>
                              <td>{onProduct?._id}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <>
                        {/* {tab2 ? (
                          <>
                            <div className="review-0">
                              <div className="spr-review">
                                <h2 class="spr-header-title">
                                  Customer Reviews
                                </h2>
                                <s
                                  className="write_re"
                                  onClick={() => setreview(!review)}
                                >
                                  Write review
                                </s>
                                <div className="jk_0 mt-3">
                                  {review ? (
                                    <>
                                      <div className="nalla_00">
                                        <div className="">Write a review</div>
                                        <div className="review-filed">
                                          <form>
                                            <div className="review__o mb-2">
                                              <label>Name</label>
                                              <br />
                                              <input
                                                type="text"
                                                placeholder="Name"
                                                className="spr-form-input-text"
                                                required
                                              />
                                            </div>
                                            <div className="review__o mb-2">
                                              <label>Email</label>
                                              <br />
                                              <input
                                                type="email"
                                                placeholder="example@gmail.com"
                                                className="spr-form-input-text"
                                                required
                                              />
                                            </div>
                                            <div className="review__o mb-2">
                                              <label>Title</label>
                                              <br />
                                              <input
                                                type="text"
                                                placeholder="Give your review a title"
                                                className="spr-form-input-text"
                                                required
                                              />
                                            </div>
                                         
                                            <div className="review__o mb-2">
                                              <button
                                                type="submit"
                                                className="write_re__090"
                                                style={{ float: "right" }}
                                              >
                                                Submit Review
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )} */}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <div className="related mt-3">
            <div className="re-pro-0-9 text-center">
              <h4>Related Product</h4>
            </div>
            <div className="diff_he">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav={false}
                items={5}
                responsive={responsive}
                autoplay={true}
              >
                {products
                  ?.filter(
                    (ele) =>
                      ele.productcategory === onProduct?.productcategory &&
                      ele?._id !== onProduct?._id
                  )
                  .map((items, id) => {
                    return (
                      <div className="item">
                        <div className="single-product-wrap mb-3">
                          <div className="product-image">
                            <a
                              href={`/single-pages/${items?._id}`}
                              className="pro-img"
                            >
                              <img
                                className="img-fluid img1"
                                src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                                alt=""
                                loading="lazy"
                                style={{ width: "100%", height: "280px" }}
                              />
                            </a>
                            <div className="product-label">
                              <span className="sale-title">
                                {items?.customerdiscountpercentage}%
                              </span>
                              {/* <div className="product-action">
                              <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                                <Tooltip title="WISHLIST">
                                  {" "}
                                  <span class="add-wishlist" id="app-title">
                                    <i class="fa fa-heart"></i>
                                  </span>
                                </Tooltip>
                                <Tooltip title="ADD TO CART">
                                  {" "}
                                  <span class="add-wishlist">
                                    <i class="fa fa-shopping-bag"></i>
                                  </span>
                                </Tooltip>
                                <Tooltip title="QUICKVIEW">
                                  {" "}
                                  <span
                                    class="add-wishlist"
                                    onClick={showModal}
                                  >
                                    <i class="fa fa-eye"></i>
                                  </span>
                                </Tooltip>
                              </div>
                            </div> */}
                            </div>
                          </div>

                          <div className="product-content mt-4">
                            <div className="product-title mb-1">
                              <a href={`/single-pages/${items?._id}`}>
                                {items?.productname}
                              </a>
                            </div>
                            {/* <div className="product-ratting mb-1">
                            <span
                              className="spr-badge"
                              id="spr_badge_6820894441621"
                              data-rating="2.0"
                            >
                              <span class="spr-starrating spr-badge-starrating">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </span>
                            </span>
                          </div> */}
                            <div className="price-box mb-2">
                              <span className="new-price" id="ProductPrice">
                                ₹
                                {Math.round(
                                  Number(items?.productprice) +
                                    Math.round(
                                      items?.productprice * (items?.tax / 100)
                                    ) -
                                    (Number(items?.productprice) +
                                      Math.round(
                                        items?.productprice * (items?.tax / 100)
                                      )) *
                                      (items.customerdiscountpercentage / 100)
                                )}
                              </span>
                              <span className="old-price" id="ComparePrice">
                                ₹
                                {Number(items?.productprice) +
                                  Math.round(
                                    items?.productprice * (items?.tax / 100)
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </OwlCarousel>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Productdetails;
