import React from "react";
import "../Styles/patner.css";

function Pattner() {
  return (
    <section className="common-use">
      <div
        className="banner-content text-center pt-3 pb-4"
        style={{ fontSize: "30px" }}
      >
        <h3>We are open to collaborations.Become our partners</h3>
      </div>
      <div className="pattner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="sec-bg" style={{ background: "#d3c7d9" }}>
                <img
                  className="lazyload-0"
                  alt=""
                  src="//cdn.shopify.com/s/files/1/0261/7960/0481/files/Suppliers.png?v=1674032067"
                />
                <h3>Suppliers</h3>
                <p>We are always on the lookout for high quality suppliers</p>
                <a href="/become-a-partne-1">Contact Us</a>
              </div>
            </div>

            <div className="col-md-3">
              <div className="sec-bg" style={{ background: "#dbdebc" }}>
                <img
                  className="lazyload-0"
                  alt
                  src="//cdn.shopify.com/s/files/1/0261/7960/0481/files/Careers.png?v=1674032257"
                />
                <h3>Careers</h3>
                <p>Work with us and help us take Japggrey to new heights</p>
                <a href="/become-a-partne-1">Join Us</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sec-bg" style={{ background: "#dbdebc" }}>
                <img
                  className="lazyload-0"
                  alt
                  src="//cdn.shopify.com/s/files/1/0261/7960/0481/files/Careers.png?v=1674032257"
                />
                <h3>Retailers</h3>
                <p>We collaborate regularly with many retailer</p>
                <a href="/become-a-partne-1">Join Us</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sec-bg" style={{ background: "#dbdebc" }}>
                <img
                  className="lazyload-0"
                  alt
                  src="//cdn.shopify.com/s/files/1/0261/7960/0481/files/Careers.png?v=1674032257"
                />
                <h3>Distribution</h3>
                <p>Are you a distributor? Let us get connected</p>
                <a href="/become-a-partne-1">Join Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pattner;
