import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function Retailerofferproduct(props) {
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [product, setproduct] = useState();

  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let res = await axios.get(
      apiurl + "/retailer/retailerproduct/" + props.localshop.localshop?._id,
      {
        type: "retailer",
      }
    );
    if ((res.status = 200)) {
      console.log(res);
      setproduct(
        res.data?.retailerproducts.filter(
          (item) => item.customerdiscountpercentage >= 20
        )
      );
    }
  };
  console.log(product,"product");
  return (
    <div className="" style={{ background: "f4f4f4", marginTop: "2rem" }}>
      <div className="px-3 pb-3 mt-2 title d-flex align-items-center">
        <h5 className="m-0 pt-3">Retailer offer Products</h5>
      </div>
      <div className="most_popular px-3">
        <div className="row">
          {product?.length > 0 ? (
            <>
              {product?.map((data) => (
                <div className="col-3 pr-2 mt-2">
                  <div className="list-card bg-white  rounded overflow-hidden position-relative shadow-sm">
                    <div className="list-card-image">
                      <Button
                        component={Link}
                        to="/productdetails"
                        onClick={() =>
                          props.dispatch({
                            type: "Subscribe",
                            item: { product: data, quantity: 1 },
                          })
                        }
                      >
                        {data.type == "admin" ? (
                          <img
                            style={{ height: "200px" }}
                            src={
                              imageURL +
                              "/admin/product/" +
                              data.productimage[0]
                            }
                            className=" item-img w-100"
                          ></img>
                        ) : data.type == "vendor" ? (
                          <img
                            style={{ height: "200px" }}
                            src={
                              imageURL +
                              "/vendor/product/" +
                              data.productimage[0]
                            }
                            className=" item-img w-100"
                          ></img>
                        ) : (
                          <img
                            style={{ height: "200px" }}
                            src={
                              imageURL +
                              "/admin/product/" +
                              data.productimage[0]
                            }
                            className=" item-img w-100"
                          ></img>
                        )}{" "}
                      </Button>
                    </div>
                    <div className="p-3 position-relative">
                      <div className="list-card-body">
                        <h6 className="mb-1 text-center">
                          {data.productname.length > 50 ? (
                            <>{`${data.productname.substring(0, 40)}...`}</>
                          ) : (
                            data.productname
                          )}
                        </h6>
                        <div
                          className="text-center"
                          style={{ fontSize: "large" }}
                        >
                          MRP ₹{" "}
                          <span>
                            <s>{data.productprice}</s>{" "}
                          </span>
                        </div>
                        <div
                          className="text-gray mb-1  text-center"
                          style={{ fontSize: "x-large", color: "#dc3545" }}
                        >
                          ₹ {data.customerofferPrice}
                        </div>
                        <div className="list-card-badge text-center mt-2">
                          {" "}
                          <span
                            className="badge badge-warning"
                            style={{ color: "black" }}
                          >
                            SAVE
                          </span>{" "}
                          <large>{data.customerdiscountpercentage}% Off</large>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <h5 className="mt-2 text-center"> No Product is Found</h5>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    localshop: state.localshop,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Retailerofferproduct);
