import React, { useState } from "react";
import "../../Components/customer/Styles/style12.css";
import OwlCarousel from "react-owl-carousel";
import Fakedata from "./FakeData/FakeData";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
function BestSellers() {
  // const [products, setproducts] = useState(Fakedata);
  const [products, setproducts] = useState([]);
  useEffect(() => {
    const bestProduct = async () => {
      try {
        let res = await axios.get("https://spilso.in/api/admin/bestProduce");
        if (res.status === 200) {
          setproducts(res.data.data1);
        }
      } catch (error) {
        alert(error.response.data.error);
        console.log(error);
      }
    };
    bestProduct();
    bestProduct();
  }, []);
  // useEffect(() => {
  //   bestProduct();
  // }, []);
  return (
    <>
      <section className="categories" style={{ padding: "0px 40px" }}>
        <div class="container">
          <div class="section-title">
            <h2>
              Best Sellers
              <Link to="/distributor/distributor-product" style={{ fontSize: "15px" }}>
                <span> Views all</span>
              </Link>
            </h2>
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={5}
            dots={false}
          >
            {products?.map((items) => {
              return (
                <div class="item" key={items?.id}>
                  <div Layout class="row featured__filter">
                    <div class=" mix oranges fresh-meat ghjk-best">
                      <div class="featured__item m-0">
                        <div class="bg-0_0">
                          <img
                            src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                            alt=""
                            style={{ width: "100%", height: "200px" }}
                          />
                        </div>
                        <div
                          className="product-label"
                          style={{ float: "right" }}
                        >
                          <span className="sale-title">
                            {items?.distributordiscountpercentage}%
                          </span>
                        </div>
                        {/* <ul class="featured__item__pic__hover">
                                    <li><a href="#"><i class="fa fa-heart"></i></a></li>
                                    <li ><a href="#"><i class="fa fa-eye"></i></a></li>
                                    <li><a href="#"><i class="fa fa-shopping-cart"></i></a></li>
                                </ul> */}
                      </div>
                      <div class="featured__item__text">
                        <h6>
                          <Link to={`/distributor/distributor-product/${items?._id}`}>{items?.productname}</Link>
                        </h6>
                        <div className="price-box mb-2">
                          <span className="new-price" id="ProductPrice">
                            ₹
                            {Math.round(
                              Number(items?.productprice) +
                                Math.round(
                                  items?.productprice * (items?.tax / 100)
                                ) -
                                (Number(items?.productprice) +
                                  Math.round(
                                    items?.productprice * (items?.tax / 100)
                                  )) *
                                  (items.distributordiscountpercentage / 100)
                            )}
                          </span>
                          <span className="old-price" id="ComparePrice">
                            ₹
                            {Number(items?.productprice) +
                              Math.round(
                                items?.productprice * (items?.tax / 100)
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
}

export default BestSellers;
