import axios from "axios";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton } from "@material-ui/core";
import { Nav } from "react-bootstrap";

function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const apiURL = process.env.REACT_APP_API_URL;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const signout = () => {
    try {
      axios
        .get(`${apiURL}/admin/signout/` + userdata.id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("profile");
            alert("Signout Successfully");

            window.location.href = "/admin";
            return;
          } else {
            alert("Signout Unsuccessfully");

            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Signout Unsuccessfully");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Signout Unsuccessfully");
      // setdatacondition(true);
      return error;
    }
  };
  return (
    <div>
      <Navbar className="header">
        <Navbar.Brand className="top-header">
          <div className="d-flex justify-content-around">
            <div>
            <img src="./Spilsologo.png" alt="" style={{ height:"60px" }} />
                  <span style={{ paddingLeft: "8px" }}>
                    <img
                      src="./Spilsologo2.png"
                      className="spin"
                      alt=""
                      style={{ width: "45px", height: "45px" }}
                    />
                  </span>
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto"></Nav>
          {userdata !== null ? (
            <span>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <Avatar
                  src={null}
                  style={{ height: "30px", width: "30px" }}
                  alt={" "}
                />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={signout}>Logout</MenuItem>
              </Menu>
            </span>
          ) : (
            <span></span>
          )}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
