import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Search(props) {
  const apiURL = process.env.REACT_APP_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [product, setproduct] = useState([]);
  const [filteredproduct, setfilteredproduct] = useState([]);
  const [like, setLike] = useState([]);
  useEffect(() => {
    getsearch();
  }, []);
  useEffect(() => {
    getlike();
  }, []);
  let Onlike = async (product) => {
    try {
      let config1 = {
        url: "/postlike",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: {
          productid: product._id,
          userid: user._id,
        },
      };
      let res = await axios(config1);
      if (res.status === 200) {
        alert("Added to Favourite");
        getlike();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getlike = async (product) => {
    let res = await axios.get(apiURL + "/getlike/" + `${user._id}`);
    if (res.status === 200) {
      console.log(res.data);
      setLike(res.data.favourite);
    }
  };

  const deletelike = async (product) => {
    let res = await axios.post(
      apiURL + "/getdeletelike/" + `${user._id}` + "/" + `${product._id}`
    );
    if (res.status === 200) {
      console.log(res.data);
      alert("Removed from Favourite");
      getlike();
    }
  };
  const getsearch = async () => {
    let res = await axios.get(apiURL + "/searchproduct");
    if ((res.status = 200)) {
      console.log(res);
      setproduct(res.data?.product);
     

    }
  };

  const filter = (e) => {
    var serchstring = e.target.value;
    let products = [...product];
    let newproduct = products.filter(
      (item) =>
        item.productname?.toLowerCase().includes(serchstring?.toLowerCase()) ||
        item.productbrand?.toLowerCase().includes(serchstring?.toLowerCase()) 
        ||
        item.productcategory
          ?.toLowerCase()
          .includes(serchstring?.toLowerCase()) ||
        item.productsubcategory
          ?.toLowerCase()
          .includes(serchstring?.toLowerCase())
    );
    console.log(products, e.target.value, newproduct);
    setfilteredproduct(newproduct);
  };

  return (
    <div className="mb-5 pb-3" style={{ background: "f4f4f4" }}>
      <div className="px-3 pb-3  title d-flex align-items-center">
        <h5 className="m-0 pt-4">Search Products</h5>
      </div>
      <div className="most_popular px-3">
        <div>
          <input
            className="form-control"
            placeholder="Search Product Name.."
            onChange={filter}
          ></input>
        </div>
        <Row xs={1} md={4} className="g-4 mt-2 d-flex">
          {filteredproduct.length === 0 ? (
            <div style={{ margin: "auto" }}>No Product Found!!!</div>
          ) : (
            ""
          )}{" "}
          {filteredproduct?.map((data) => (
            <Col>
              <div className="list-card bg-white rounded overflow-hidden  shadow-sm">
                <div className="list-card-image">
                  <Button
                    component={Link}
                    to="/productdetails"
                    onClick={() =>
                      props.dispatch({
                        type: "Subscribe",
                        item: { product: data, quantity: 1 },
                      })
                    }
                  >
                    {data.type == "admin" ? (
                      <img
                        style={{ height: "250px" }}
                        src={
                          imageURL + "/admin/product/" + data.productimage[0]
                        }
                        className="item-img w-100"
                      ></img>
                    ) : (
                      <img
                        style={{ height: "250px" }}
                        src={
                          imageURL + "/vendor/product/" + data.productimage[0]
                        }
                        className="item-img w-100"
                      ></img>
                    )}{" "}
                  </Button>
                </div>
                <div className="p-3 position-relative">
                  <div className="list-card-body d-flex">
                    <h6 className="mb-1">
                      <a href="restaurant.html" className="text-black">
                        {data.productname}
                      </a>
                    </h6>
                    <div className="mx-auto">
                      {like.length > 0 ? (
                        like.find((item) => item.productid === data._id)
                          ?._id ? (
                          <i
                            class="fa fa-heart"
                            style={{
                              fontSize: "24px",
                              color: "red",
                              fontWeight: "bold",
                            }}
                            onClick={() => deletelike(data)}
                          ></i>
                        ) : (
                          <i
                            class="	fa fa-heart-o"
                            style={{
                              fontSize: "24px",
                              color: "#0d6efd",
                              fontWeight: "bold",
                            }}
                            onClick={() => Onlike(data)}
                          ></i>
                        )
                      ) : (
                        <i
                          class="	fa fa-heart-o"
                          style={{
                            fontSize: "24px",
                            color: "#0d6efd",
                            fontWeight: "bold",
                          }}
                          onClick={() => Onlike(data)}
                        ></i>
                      )}
                    </div>

                    <p className="text-gray mb-1 rating"></p>
                  </div>
                  <div
                    className="text-gray mb-1  text-center"
                    style={{ fontSize: "x-large", color: "#dc3545" }}
                  >
                    ₹ {data.customerofferPrice}
                  </div>
                  <div className="text-center" style={{ fontSize: "large" }}>
                    MRP ₹{" "}
                    <span>
                      <s>{data.productprice}</s>{" "}
                    </span>
                  </div>
                  <div className="list-card-badge">
                    {" "}
                    <span
                      className="badge badge-success"
                      style={{ color: "red", backgroundColor: "yellow" }}
                    >
                      SAVE
                    </span>{" "}
                    <small>{data.customerdiscountpercentage}% Off</small>{" "}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    Subscribe: state.Subscribe,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Search);
