import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";

function Cartproduct(props) {
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  return (
    <Container className="pt-2">
      <Row>
        <Col lg={12}>
          <div className="container pt-3">
            {props.basket?.map((product) => (
              <Row>
                <Col className="" lg={4}>
                  {product.product?.type === "admin" ? (
                    <img
                      src={
                        imageURL +
                        "/admin/product/" +
                        product.product?.productimage[0]
                      }
                      alt=""
                      height="175px"
                      width="200px"
                    />
                  ) : (
                    <img
                      src={
                        imageURL +
                        "/admin/product/" +
                        product.product?.productimage[0]
                      }
                      alt=""
                      height="175px"
                      width="200px"
                    />
                  )}
                </Col>
                <Col className="" lg={8}>
                  <div
                    style={{
                      color: "#28328c",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    {product.product?.productname}{" "}
                    {product.product?.productUnites}
                  </div>
                  <div>{product.product?.productdesc}</div>
                  <div>
                    <strong>Price:</strong> RS.{" "}
                    {parseFloat(product.product?.retailerofferPrice * product?.quantity).toFixed(2)}
                  </div>
                  <div>
                    <Button
                      className="mr-2 text-center"
                      style={{ width: "30px" }}
                      onClick={() =>
                        props.dispatch({ type: "addquantity", item: product })
                      }
                    >
                      +
                    </Button>
                    <span>{product.quantity}</span>
                    <Button
                      className="ml-2"
                      style={{ width: "30px" }}
                      onClick={() =>
                        props.dispatch({ type: "subquantity", item: product })
                      }
                    >
                      -
                    </Button>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-4">
                      <Button
                        color="secondary"
                        onClick={() =>
                          props.dispatch({
                            type: "deleteBasketItem",
                            item: product,
                          })
                        }
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                </Col>

                {/* <s>{product.product.originalPrice}</s>  */}
              </Row>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Cartproduct);
