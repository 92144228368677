import React, { useEffect, useState } from "react";
import "../Styles/style12.css";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import { Link } from "react-router-dom";
function Slider1() {
  // const history=useNavigate()
  const [showMore, setShowMore] = useState(false);
  const [topdeal, setProduct] = useState([]);
  const [productDeal, setproductDeal] = useState(false);
  const [newProduct, setnewProduct] = useState([]);

  const [image, setimage] = useState("");

  const  responsive={
    0:{
      items:1
    },
    600:{
      items:2
    },
    1000:{
      items:5
    }
  }


  const gettopdeal = async () => {
    try {
      const config = {
        url: "/getTopDealsCustomer",
        method: "get",
        baseURL: "https://spilso.in/api/admin",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setProduct(res.data.data1);
        getCatory();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const newGetProducts = async () => {
    try {
      const config = {
        url: "/letestProduct",
        method: "get",
        baseURL: "https://spilso.in/api/admin",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setnewProduct(res.data.data1);
        gettopdeal();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [categories, setCotegory] = useState([]);
  const getCatory = async () => {
    try {
      const config = {
        url: "/getcategory",
        method: "get",
        baseURL: "https://spilso.in/api/",
        headers: { "conttent-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setCotegory(res.data.category);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  useEffect(() => {
    gettopdeal();
    newGetProducts();
    getCatory();
    // if(user){
    //    getCatrDeatils()
    // getwislist();
    // }
  }, []);

  useEffect(() => {
    gettopdeal();
    newGetProducts();
    getCatory();
    // if(user){
    //    getCatrDeatils()
    // getwislist();
    // }
  }, []);

  return (
    <>
      <section className="ban_o container">
        <img
          src="/img/featured/grocery2.jpg"
          alt="offer"
          style={{ width: "100%", height: "280px" }}
        />
      </section>
      <section className="categories" style={{ padding: "40px 35px" }}>
        <div class="container">
          <div class="section-title">
            <h2>
              Today's Our Best Offer{" "}
              <Link
                to={{ pathname: "/products", state: { best: "BestOffer" } }}
                style={{ fontSize: "15px" ,float:"right"}}
              >
                <span> See all Deals</span>
              </Link>
            </h2>
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={5}
            dots={false}
            responsive={responsive}
          >
            {topdeal
              ?.filter((ele) => ele?.remainingstock > ele?.minimumStock)
              ?.map((items, id) => {
                return (
                  // <Col lg={3} md={3} sm={3} key={id}>
                  //   <div className="single-product-wrap mb-3">
                  //     <div className="product-image">
                  //       <a href={`/single-pages/${items?._id}`} className="pro-img">
                  //         <img
                  //           className="img-fluid img1"
                  //           src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                  //           alt=""
                  //           loading="lazy"
                  //           style={{ width: "100%", height: "280px" }}
                  //         />
                  //       </a>
                  //       <div className="product-label">
                  //         <span className="sale-title">
                  //           {items?.customerdiscountpercentage}%
                  //         </span>
                  //         <div className="product-action">
                  //           <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                  //           {wishlist?.filter(
                  //                   (item) =>
                  //                     item?.productId?._id === items._id
                  //                 )?.length ? (<Tooltip title="REMOVE-WISHLIST" onClick={() => removewishlist(items)}>
                  //                 {" "}
                  //                 <span class="add-wishlist" id="app-title">
                  //                   <i class="fa fa-heart" style={{ color: "Green" }}></i>
                  //                 </span>
                  //               </Tooltip>):(<Tooltip title="WISHLIST"  onClick={() => addwishlist(items)}>
                  //               {" "}
                  //               <span class="add-wishlist" id="app-title">
                  //                 <i class="fa fa-heart"    ></i>
                  //               </span>
                  //             </Tooltip>)}
                  //             {Carts?.filter(
                  //                   (item) =>
                  //                     item?.productId?._id === items._id
                  //                 )?.length ? (<Tooltip title="REMOVE TO CART"  onClick={()=>removewCart(items)}>
                  //                 {" "}
                  //                 <span class="add-wishlist">
                  //                   <i class="fa fa-shopping-bag"  style={{ color: "red" }}></i>
                  //                 </span>
                  //               </Tooltip>):(<Tooltip title="ADD TO CART" onClick={()=>addCart(items)}>
                  //               {" "}
                  //               <span class="add-wishlist">
                  //                 <i class="fa fa-shopping-bag"></i>
                  //               </span>
                  //             </Tooltip>)}

                  //             <Tooltip title="QUICKVIEW">
                  //               {" "}
                  //               <span
                  //                 class="add-wishlist"
                  //                  onClick={()=>{showModal()
                  //                   setViewdeata(items)
                  //                   setimage(items?.productimage[0])}}
                  //               >

                  //                 <i class="fa fa-eye"></i>
                  //               </span>
                  //             </Tooltip>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>

                  //     <div className="product-content mt-4">
                  //       <div className="product-title mb-1">
                  //         <a href={`/single-pages/${items?._id}`}>{items?.productname}</a>
                  //       </div>
                  //       {/* <div className="product-ratting mb-1">
                  //         <span
                  //           className="spr-badge"
                  //           id="spr_badge_6820894441621"
                  //           data-rating="2.0"
                  //         >
                  //           <span class="spr-starrating spr-badge-starrating">
                  //             <i class="fa fa-star" aria-hidden="true"></i>
                  //             <i class="fa fa-star" aria-hidden="true"></i>
                  //             <i class="fa fa-star" aria-hidden="true"></i>
                  //             <i class="fa fa-star" aria-hidden="true"></i>
                  //             <i class="fa fa-star" aria-hidden="true"></i>
                  //           </span>
                  //         </span>
                  //       </div> */}
                  //       <div className="price-box mb-2">
                  //         <span className="new-price" id="ProductPrice">
                  //         ₹{Math.round(Number(items?.productprice)+Math.round(items?.productprice*(items?.tax/100))-((Number(items?.productprice)+Math.round(items?.productprice*(items?.tax/100)))*(items.customerdiscountpercentage/100)))}

                  //         </span>
                  //         <span className="old-price" id="ComparePrice">
                  //           ₹{Number(items?.productprice)+Math.round(items?.productprice*(items?.tax/100))}
                  //         </span>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </Col>
                  <div class="item" key={items?.id}>
                    <div
                      class="categories__item set-bg"
                      // data-setbg={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                      // style={{
                      //   backgroundImage: `url(https://spilso.in/admin/product/${items?.productimage[0]})`,
                      //   backgroundSize: "cover",
                      //   backgroundPosition: "center",
                      // }}
                    >
                      <img
                        src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                        alt="hdwh"
                        style={{ width: "100%", height: "270px" }}
                      />
                      <h5>
                        <a href={`/single-pages/${items?._id}`}>
                          {items?.productname}
                        </a>
                      </h5>
                      <div
                        className="amazon-0"
                        style={{ position: "absolute", top: "0px" }}
                      >
                        <p>
                          <span>{items?.customerdiscountpercentage}% off</span>{" "}
                          Deal of the Day
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-2.jpg"
                style={{backgroundImage: 'url("/img/categories/cat-2.jpg")'}}>
              
                <h5>
                  <a href="#">Dried Fruit</a>
                </h5>
                <div className="amazon-0">
                  <p><span>17% off</span> Deal of the Day</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-3.jpg"
                style={{backgroundImage: 'url("/img/categories/cat-3.jpg")'}}>
                <h5>
                  <a href="#">Vegetables</a>
                </h5>
                <div className="amazon-0">
                  <p><span>17% off</span> Deal of the Day</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-4.jpg"
                style={{backgroundImage: 'url("/img/categories/cat-4.jpg")'}}>
                <h5>
                  <a href="#">drink fruits</a>
                </h5>
                <div className="amazon-0">
                  <p><span>17% off</span> Deal of the Day</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-4.jpg"
                style={{backgroundImage: 'url("/img/categories/cat-4.jpg")'}}>
                <h5>
                  <a href="#">drink fruits</a>
                </h5>
                <div className="amazon-0">
                  <p><span>17% off</span> Deal of the Day</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div
                class="categories__item set-bg"
                data-setbg="/img/categories/cat-2.jpg"
                style={{backgroundImage: 'url("/img/categories/cat-2.jpg")'}}>
                <h5>
                  <a href="#">Dried Fruit</a>
                </h5>
                <div className="amazon-0">
                  <p><span>17% off</span> Deal of the Day</p>
                </div>
              </div>
            </div> */}
          </OwlCarousel>
          {/* <div class="row">
            <div class="categories__slider owl-carousel">
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-1.jpg"
                >
                  <h5>
                    <a href="#">Fresh Fruit</a>
                  </h5>
                  <div className="amazon-0">
                  <p><span>17% off</span> Deal of the Day</p>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-2.jpg"
                >
                  <h5>
                    <a href="#">Dried Fruit</a>
                  </h5>
                  <div className="amazon-0">
                  <p><span>17% off</span> Deal of the Day</p>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-3.jpg"
                >
                  <h5>
                    <a href="#">Vegetables</a>
                  </h5>
                  <div className="amazon-0">
                  <p><span>17% off</span> Deal of the Day</p>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-4.jpg"
                >
                  <h5>
                    <a href="#">drink fruits</a>
                  </h5>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="categories__item set-bg"
                  data-setbg="/img/categories/cat-5.jpg"
                >
                  <h5>
                    <a href="#">drink fruits</a>
                  </h5>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default Slider1;
