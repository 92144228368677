import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';


function Adminkeyaccount() {
    const [data, setdata] = useState([]);
    const { SearchBar, ClearSearchButton } = Search;
    const { ExportCSVButton } = CSVExport;
    const apiURL = process.env.REACT_APP_API_URL;
    const imageURL = process.env.REACT_APP_IMAGE_API_URL;

    useEffect(() => {
        getkeyaccounts();                       
    }, []);    
    
    const getkeyaccounts = () =>{
        try{
            axios.get(`${apiURL}/keyaccount/notapproved`)
                        .then(function(response){
                           if (response.status === 200) { 
                             console.log(response.data);
                            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata)); 
                             setdata(response.data.keyaccount);                                  
                             return 
                       
                     }else{
                       
                       alert("Can't able to fetch ");
                       //setdatacondition(true);
                       return
                     }
                   }).catch(function(error){
                     // setdatacondition(true);
                     console.log(error.message.data);
                     alert("Can't able to fetch ");
                     return
                   });
                 }catch (error) {
                    
                   console.warn(error);
        alert("Can't able  to fetch ");
       // setdatacondition(true);
        return error;
       };
    }

    const approve = async(data) =>{
        try{
            axios.post(`${apiURL}/keyaccount/approvekeyaccount/`+data._id)
                         .then(function(response){
                            if (response.status === 200) { 
                              console.log(response.data);
                              window.location.reload();                
                      }
                    }).catch(function(error){   
                      // setdatacondition(true);
                      console.log(error.message.data);
                      alert("Not able to complete");
                      
                    });
                  }catch (error) {
                     
                    console.warn(error);
         alert("Not able to complete");
        // setdatacondition(true);
         return error;
        };
    }

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from } to { to } of { size } Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: data.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };

    function imageFormatter(cell, row){
      return (<img src={`${imageURL}/keyaccount/${cell}`}  height="50px" width="50px"  style={{borderRadius:"100%"}}/>) ;
    }

    function imageFormatter1(cell, row){
      return (<img src={`${imageURL}/keyaccount/${cell}`}  height="50px" width="50px"  style={{borderRadius:"100%"}}/>) ;
    }

  

  const columns = [{
      dataField: 'ownername',
      text: 'ownername Name',
      sort : true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },
    {
      dataField: 'firmname',
      text: 'Firm Name',
      sort : true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },    
    {
      dataField: 'email',
      text: 'Email',
      sort : true
    }, {  
      dataField: 'contactno',
      text: 'Contact No',
      
    },    
    {  
      dataField: 'gstno',
      text: 'GST No',
      
    },
    {  
      dataField: 'aadharno',
      text: 'Aadhar No',
      
    },
    {  
      dataField: 'panno',
      text: 'PAN No',
      
    },   
    {  
      dataField: 'empstrength',
      text: 'No of Employee',
      
    },
    {  
      dataField: 'companyimage',
      text: 'Company Image',
      formatter: imageFormatter
    },     
    {  
      dataField: 'villagename',
      text: 'Address',
      formatter: (cell, row) => {
        console.log(row);
        return <div> {row?.villagename}, {row?.taluka}, {row?.area},{row?.districtname}, {row?.state}, {row?.country}, {row?.pincode}</div>;
      }     
    },          
    {  
      dataField: '',
      text: 'Action',
      formatter: (cell, row) => {
        console.log(row);
        return <div><button className="btn btn-outline-success" onClick={()=>approve(row)}>Approve</button></div>;
      }     
    },
    // {
    //   dataField: 'status',
    //   text: 'Status',
    //   sort : true
    // }
  ];

    return (
        <div style={{background:""}}>
        <div className="container pb-5">
            <h1 className="pt-4 pb-3" style={{color:"#28328c"}}>keyaccount</h1>
            <div className="container">
                <div className="card card-body">
                      {/* <div>
                        <h5 className="pb-3">Latest Orders</h5>
                         <div className="container text-center">
                         <Table responsive hover size="md">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Profile Picture</th>
                                <th>Email Id</th>
                                <th>Mobile No</th>
                                <th>Gender</th>   
                                <th>Specialization</th>
                                <th>Qualification</th>   
                                <th>Experience</th>
                                <th>ID Proof Type</th>
                                <th>ID Proof</th>
                                <th>Action</th>                       
                            </tr>
                            </thead>
                            <tbody>
                                {data?.map((datas)=>(
                                  <tr>
                                  <td>Dr.{datas.firstname}  {datas.lastname}</td>
                                  <td><img src={imageURL +"/keyaccount/profile/" + datas.profileimage} height="50px" width="50px" style={{borderRadius:"100%"}}/></td> 
                                  <td>{datas.email}</td>
                                  <td>{datas.phoneNumber}</td>  
                                  <td>{datas.gender}</td>
                                  <td>{datas.specializations}</td>   
                                  <td>{datas.qualification}</td> 
                                  <td>{datas.experience}</td>  
                                  <td>{datas.idprooftype}</td>   
                                  <td><img src={imageURL +"/keyaccount/idproof/" + datas.idproof} height="50px" width="50px" style={{borderRadius:"100%"}}/></td>                                                   
                                  <td><div className="d-flex"> <button className="btn btn-outline-success" onClick={()=>approve(datas)}>Approve</button></div></td>
                              </tr>  
                                ))}                               
                            </tbody>
                          </Table>
                        </div>
                    </div> */}

                    <ToolkitProvider
        keyField="id"
        data={ data }
        columns={ columns }
        search
        exportCSV = { {
            fileName: 'Notapprovedkeyaccounts.csv',            
            blobType: 'text/csv;charset=ansi'
          } }
        >
            {
         props => (
            <div> 
             <span className="pr-5 mr-auto"> 
            <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
            </span>  
            <span className="pl-5 ml-5 mr-5 mt-5">          
            <SearchBar className="form-control " { ...props.searchProps } />
            </span>
            <ClearSearchButton className="btn-outline-info" { ...props.searchProps } />
            
            <hr />
            <div className="table-responsive">
            <BootstrapTable keyField='keyaccount' responsive hover columns={ columns } data={ data } pagination={ paginationFactory(options) }  { ...props.baseProps }/>
            </div>
            </div>
            )
        }
            </ToolkitProvider>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Adminkeyaccount
