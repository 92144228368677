import React from 'react'
import { BsTelephoneFill } from 'react-icons/bs';
import {MdLocationOn} from 'react-icons/md';
import {BiTime} from 'react-icons/bi';
import {MdEmail} from 'react-icons/md';
import "../../Components/customer/Styles/style12.css"
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <>
     <section style={{ marginTop: "20px" }}>
        <div className='contact_us'>
      <div>
  <section className="breadcrumb-section set-bg" data-setbg="/img/featured/contact_us.jpg"
  style={{
    backgroundImage:"url('/img/featured/contact_us.jpg')"
  }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="breadcrumb__text">
            <h2>Contact Us</h2>
            <div className="breadcrumb__option">
              <Link to="/distributor/distributor-home">Home</Link>
              <span>Contact Us</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="contact spad">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
          <div className="contact__widget">
            <span><BsTelephoneFill  className="icon_phone" /></span> 
            <h4>Phone</h4>
            <p>+01-3-8888-6868</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
          <div className="contact__widget">
            <span><MdLocationOn className="icon_pin_alt" /></span> 
            <h4>Address</h4>
            <p>60-49 Road 11378 New York</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
          <div className="contact__widget">
            <span><BiTime className="icon_clock_alt"/></span> 
            <h4>Open time</h4>
            <p>10:00 am to 23:00 pm</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
          <div className="contact__widget">
            <span><MdEmail className="icon_mail_alt"/></span>
            <h4>Email</h4>
            <p>hello@colorlib.com</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

   
        </div>
     </section>
    </>
  )
}

export default Contact