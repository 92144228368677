import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import "../../Components/customer/Styles/singal.css";
import { Tooltip } from "antd";
import OwlCarousel from "react-owl-carousel";
import Fakedata from "./FakeData/FakeData";
import axios from "axios";
import { useParams } from "react-router-dom";

function DistributorProductdetails() {
  const [previewImg, setPreviewImg] = useState("");
  // useEffect(() => {
  //   setPreviewImg("/webImages/apple1.jpg");
  // }, []);

  const [acc1, setacc1] = useState("true");
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);

  const [tab, settab] = useState(true);
  const [tab1, settab1] = useState();
  const [tab2, settab2] = useState();
  const [review, setreview] = useState("");
  const [products, setproducts] = useState([]);

  const [selPrice, setslePrice] = useState(0);
  const [selQuntity, setselQuntity] = useState(0);
  const [image, setImage] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [AllPrice, setAllPrice] = useState([]);
  const [quantity, setQuntity] = useState(1);
  const incQuntity = () => {
    setQuntity(quantity + 1);
  };
  const decQuntity = () => {
    if (quantity > 1) {
      setQuntity(quantity - 1);
    }
  };
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));
  /* ====================================================================================================== */
  const { id } = useParams();
  const [MainProduct, setMainProduct] = useState({});

  const productslist = async () => {
    try {
      const res = await axios.get("https://spilso.in/api/getAllproduct");
      if (res.status === 200) {
        setproducts(res.data.products);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getProductById = async () => {
    try {
      const res = await axios.get(
        `https://spilso.in/api/admin/getSingleProductById/${id}`
      );
      if (res.status === 200) {
        setPreviewImg(
          `https://spilso.in/admin/product/${res.data.success?.productimage[0]}`
        );
        setMainProduct(res.data.success);
        setAllPrice(res.data.success?.AllPrice);
        productslist();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */
  // cart related api's
  const addToCartDistributor = async (product, e) => {
    e.preventDefault();
    try {
      if (!distributor) {
        alert("Please login.");
      } else {
        const config = {
          url: "/addToCartDistributor",
          method: "post",
          baseURL: "https://spilso.in/api",
          header: { "content-type": "application/json" },
          data: {
            distributorId: distributor?._id,
            productId: product?._id,
            quantity: 1,
            totalPrice: product?.distributorofferPrice * 1,
            Size: product?.productsize,
            price: product?.distributorofferPrice,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert("Product has added to the cart");
            window.location.reload();
          }
        });
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [Carts, setCarts] = useState([]);
  const getCatrDeatils = () => {
    axios
      .get(
        "https://spilso.in/api/getAllCartProductsByDistributor/" +
          distributor?._id
      )
      .then(function (response) {
        console.log(response.data);
        setCarts(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeProductFromCart = async (item) => {
    if (!distributor) {
      alert("Please login.");
    } else {
      try {
        const config = {
          url: "/removeProductsFromDistributorsCart",
          method: "put",
          baseURL: "https://spilso.in/api",
          headers: { "conten-type": "application/json" },
          data: {
            productId: item?._id,
            distributorId: distributor?._id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Product removed from wishlist");
          window.location.reload();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };

  /* ======================================================================================================== */
  // wishlist related api's
  const addToWishlistDistributor = async (product, e) => {
    try {
      if (!distributor) {
        alert("Please login...");
        // window.location.assign("")
      } else {
        const config = {
          url: "/addWhishList",
          method: "post",
          baseURL: "https://spilso.in/api",
          header: { "content-type": "application/json" },
          data: {
            distributorId: distributor?._id,
            productId: product?._id,
            quantity: 1,
            totalPrice: product?.distributorofferPrice * 1,
            Size: product?.productsize,
            price: product?.distributorofferPrice,
          },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert("Product has added to the Wishlist");
          }
        });
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [wishlist, setwishlist] = useState([]);
  const getwislist = () => {
    axios
      .get(
        "https://spilso.in/api/getWishlistBydistributorId/" + distributor?._id
      )
      .then(function (response) {
        console.log(response.data);
        setwishlist(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeProductFromwishlist = async (item) => {
    if (!distributor) {
      alert("Please login...");
      // window.location.assign("")
    } else {
      axios({
        method: "delete",
        url:
          "https://spilso.in/api/removeWishlistbyDistributorId/" +
          distributor._id +
          "/" +
          item?._id,
      })
        .then(function (response) {
          //handle success
          console.log(response);
          alert("Product removed from wishlist");
          getwislist();
          window.location.reload();
        })
        .catch(function (error) {
          //handle error
          console.log(error.response.data);
        });
    }
  };

  /* ====================================================================================================== */
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    getProductById();
    getwislist();
    getCatrDeatils();
    productslist();
  }, []);

  return (
    <>
      <section style={{ marginTop: "20px" }}>
        <Container>
          <Row>
            <Col lg="12" md="12">
              <div className="singal_hero">
                <Row>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-3">
                        <div
                          className="product__images mt-2 "
                          style={{ gap: "5px" }}
                        >
                          {MainProduct?.productimage?.map((item) => {
                            return (
                              <div
                                className={`img__item mb-2 ${
                                  acc1 ? "tab-0-active" : ""
                                }`}
                                onClick={() => {
                                  setPreviewImg(
                                    `https://spilso.in/admin/product/${item}`
                                  );
                                  setacc1(true);
                                  setacc2(false);
                                  setacc3(false);
                                  setacc4(false);
                                }}
                              >
                                <img
                                  src={`https://spilso.in/admin/product/${item}`}
                                  alt=""
                                  className=" img-fluid wt-50"
                                />
                              </div>
                            );
                          })}
                          {/* <div
                            className={`img__item mb-2 ${
                              acc2 ? "tab-0-active" : ""
                            }`}
                            onClick={() => {
                              setPreviewImg("/webImages/apple2.avif");
                              setacc1(false);
                              setacc2(true);
                              setacc3(false);
                              setacc4(false);
                            }}
                          >
                            <img
                              src="/webImages/apple2.avif"
                              alt=""
                              className="img-fluid wt-50"
                            />
                          </div>

                          <div
                            className={`img__item mb-2 ${
                              acc3 ? "tab-0-active" : ""
                            }`}
                            onClick={() => {
                              setPreviewImg("/webImages/apple3.avif");
                              setacc1(false);
                              setacc2(false);
                              setacc3(true);
                              setacc4(false);
                            }}
                          >
                            <img
                              src="/webImages/apple3.avif"
                              alt=""
                              className="img-fluid wt-50"
                            />
                          </div>
                          <div
                            className={`img__item mb-3 ${
                              acc4 ? "tab-0-active" : ""
                            }`}
                            onClick={() => {
                              setPreviewImg("/webImages/apple4.avif");
                              setacc1(false);
                              setacc2(false);
                              setacc3(false);
                              setacc4(true);
                            }}
                          >
                            <img
                              src="/webImages/apple4.avif"
                              alt=""
                              className="img-fluid wt-50"
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="singal_hero_0">
                          <div className="product__main-img">
                            <img
                              src={previewImg}
                              alt=""
                              className=" w-100"
                              style={{ height: "370px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="product-details"
                      style={{ paddingTop: "0px", display: "flex" }}
                    >
                      <div className="product_details_info">
                        <div class="product-title">
                          <h2>
                            <b>{MainProduct?.productname}</b>
                          </h2>
                        </div>
                        {/* <div className="product-ratting mb-2">
                          <span
                            className="spr-badge"
                            id="spr_badge_6820894441621"
                            data-rating="2.0"
                          >
                            <span class="spr-starrating spr-badge-starrating">
                              <i class="fa fa-star" aria-hidden="true"></i>
                              <i class="fa fa-star" aria-hidden="true"></i>
                              <i class="fa fa-star" aria-hidden="true"></i>
                              <i class="fa fa-star" aria-hidden="true"></i>
                              <i class="fa fa-star" aria-hidden="true"></i>
                            </span>
                          </span>
                        </div> */}
                        <div class="pro-price-label mb-2">
                          <div class="price-box">
                            {selPrice !== 0 ? (
                              <span class="new-price" id="ProductPrice">
                                {selQuntity}
                                {MainProduct?.productvolumetype}
                                {"-"}₹
                                {Math.round(
                                  (Number(selPrice) +
                                    Math.round(
                                      selPrice * (MainProduct?.tax / 100)
                                    ) -
                                    (Number(selPrice) +
                                      Math.round(
                                        selPrice * (MainProduct?.tax / 100)
                                      )) *
                                      (MainProduct?.distributordiscountpercentage /
                                        100)) *
                                    quantity
                                )}
                              </span>
                            ) : (
                              <span class="new-price" id="ProductPrice">
                                ₹
                                {Math.round(
                                  (Number(MainProduct?.productprice) +
                                    Math.round(
                                      MainProduct?.productprice *
                                        (MainProduct?.tax / 100)
                                    ) -
                                    (Number(MainProduct?.productprice) +
                                      Math.round(
                                        MainProduct?.productprice *
                                          (MainProduct?.tax / 100)
                                      )) *
                                      (MainProduct.distributordiscountpercentage /
                                        100)) *
                                    quantity
                                )}
                              </span>
                            )}
                            {selPrice !== 0 ? (
                              <span class="old-price" id="ComparePrice">
                                ₹
                                {Number(selPrice * quantity) +
                                  Math.round(
                                    selPrice *
                                      quantity *
                                      (MainProduct?.tax / 100)
                                  )}
                              </span>
                            ) : (
                              <span class="old-price" id="ComparePrice">
                                ₹
                                {Number(MainProduct?.productprice * quantity) +
                                  Math.round(
                                    MainProduct?.productprice *
                                      quantity *
                                      (MainProduct?.tax / 100)
                                  )}
                              </span>
                            )}
                          </div>

                          <div class="product-label">
                            <span class="sale-title" id="ProductDiscount">
                              {MainProduct?.distributordiscountpercentage}%
                            </span>
                          </div>
                        </div>
                        <div class="product-inventory d-flex mb-2">
                          <h6>Availability:</h6>

                          <span class="in-stock text-success">
                            {MainProduct?.remainingstock ? (
                              <span>
                                In Stock<i class="ti-check"></i>
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                Out of Stock<i class="ti-check"></i>
                              </span>
                            )}
                          </span>
                        </div>
                        <div class="product-description mb-2">
                          {showMore
                            ? MainProduct?.productdesc
                            : `${MainProduct?.productdesc?.substring(0, 200)}`}
                          <button
                            className="btn"
                            onClick={() => setShowMore(!showMore)}
                          >
                            {showMore ? "Show less" : "Show more"}
                          </button>
                        </div>
                        <div className="quick-view-select mb-3">
                          <div className="selector-wrapper">
                            <label>Size : </label>
                            <select
                              class="single-option-selector"
                              data-option="option1"
                              id="product-select-qv-option-0"
                              onChange={(e) => {
                                setslePrice(
                                  JSON.parse(e.target.value)?.quntityPrice
                                );
                                setselQuntity(
                                  JSON.parse(e.target.value)?.perQuntity
                                );
                              }}
                            >
                              {MainProduct?.AllPrice?.map((ele) => {
                                return (
                                  <option value={JSON.stringify(ele)}>
                                    {ele?.perQuntity}
                                    {MainProduct?.productvolumetype}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="product-quantity mb-3 d-flex">
                          <b>Quantity :</b>{" "}
                          <div className="cart-plus-minus">
                            <div className="dec qtybutton" onClick={decQuntity}>
                              -
                            </div>
                            <input
                              value={quantity}
                              type="text"
                              name="quantity"
                              min="1"
                              pattern="[0-9]*"
                            />
                            <div className="inc qtybutton" onClick={incQuntity}>
                              +
                            </div>
                          </div>
                        </div>
                        <div className="pro-detail-button-0 mb-2">
                          {/* <div className="wish-09"> */}

                          {/* <Tooltip title="WISHLIST">
                              {" "}
                              <span class="add-wishlist-0" id="app-title">
                                <i class="fa fa-heart"></i>
                              </span>
                            </Tooltip> */}
                          {wishlist.filter(
                            (item) => item?.productId?._id === MainProduct._id
                          )?.length ? (
                            <Tooltip
                              title="REMOVE-WISHLIST"
                              onClick={(e) =>
                                removeProductFromwishlist(MainProduct, e)
                              }
                            >
                              {" "}
                              <span
                                id="app-title"
                                style={{
                                  opacity: "1",
                                  background: "red",
                                  color: "white",
                                  fontSize: "16px",
                                  lineHeight: 0,
                                  height: "40px",
                                  width: "40px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "50%",
                                  marginRight: "15px",
                                }}
                              >
                                <i class="fa fa-heart"></i>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="WISHLIST"
                              onClick={(e) =>
                                addToWishlistDistributor(MainProduct, e)
                              }
                            >
                              {" "}
                              <span
                                class="add-wishlist"
                                id="app-title"
                                style={{ opacity: "1" }}
                              >
                                <i class="fa fa-heart"></i>
                              </span>
                            </Tooltip>
                          )}
                          {/* </div> */}
                          {/* <div className="wish-09"> */}
                          {Carts?.filter(
                            (item) => item?.productId?._id === MainProduct?._id
                          )?.length ? (
                            <Tooltip
                              title="REMOVE TO CART"
                              onClick={(e) =>
                                removeProductFromCart(MainProduct, e)
                              }
                            >
                              {" "}
                              <span
                                class="add-wishlist"
                                style={{
                                  opacity: "1",
                                  background: "yellow",
                                  color: "white",
                                  fontSize: "16px",
                                  lineHeight: 0,
                                  height: "40px",
                                  width: "40px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "50%",
                                  marginRight: "15px",
                                }}
                              >
                                <i class="fa fa-shopping-bag"></i>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="ADD TO CART"
                              onClick={(e) =>
                                addToCartDistributor(MainProduct, e)
                              }
                            >
                              {" "}
                              <span
                                class="add-wishlist"
                                style={{ opacity: "1" }}
                              >
                                <i class="fa fa-shopping-bag"></i>
                              </span>
                            </Tooltip>
                          )}
                          {/* </div> */}
                          {/* <div className="wish-09">
                            <span>BUY NOW</span>
                          </div> */}
                        </div>
                        {/* <div class="product-sku">
                          <h6>SKU:</h6>
                          <span class="variant-sku" id="variantsku">
                            123456
                          </span>
                        </div> */}
                      </div>
                      {MainProduct?.isCombo === true ? (
                        <div className="product_details_info">
                          <div class="product-title">
                            <img
                              src="/combo.jpg"
                              alt="g"
                              style={{ width: "80px", height: "83px" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {MainProduct?.isFree === true ? (
                        <div className="product_details_info">
                          <div>
                            <img
                              src={`https://spilso.in/admin/product/${MainProduct?.freeProImage}`}
                              alt=""
                              className=" img-fluid wt-50"
                            />
                          </div>
                          <div class="product-title">
                            <h2>
                              <b>{MainProduct?.freeProductName}</b>
                            </h2>
                            <div class="pro-price-label mb-2">
                              <div class="price-box">
                                <span class="old-price" id="ComparePrice">
                                  ₹{MainProduct?.freePrice}
                                </span>
                                <span>
                                  <img
                                    src="/5.png"
                                    alt="g"
                                    style={{ width: "50px", height: "42px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div class="product-description mb-2">
                              {MainProduct?.freeProdesc}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {MainProduct?.isByOnegetFreeCust === true ? (
                        <div className="product_details_info">
                          <div>
                            <img
                              src="/free1.png"
                              alt=""
                              className=" img-fluid wt-50"
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>

          <div className="uni-details">
            <div className="uni-details-hero">
              <ul className="presentation">
                <li
                  className={`des-0__0 ${tab ? "pre-active" : ""}`}
                  onClick={() => {
                    settab(true);
                    settab1(false);
                    settab2(false);
                  }}
                >
                  {" "}
                  Description
                </li>
                <li
                  className={`des-0__0 ${tab1 ? "pre-active" : ""}`}
                  onClick={() => {
                    settab(false);
                    settab1(true);
                    settab2(false);
                  }}
                >
                  {" "}
                  Additional information
                </li>
                {/* <li
                  className={`des-0__0 ${tab2 ? "pre-active" : ""}`}
                  onClick={() => {
                    settab(false);
                    settab1(false);
                    settab2(true);
                  }}
                >
                  {" "}
                  Reviews
                </li> */}
              </ul>

              <div className="">
                {tab ? (
                  <>
                    {/* <div className="M-0_0 mb-2">
                      <h5 className="des-0__0">More Detail</h5>
                      <ul>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry
                        </li>
                      </ul>
                    </div> */}
                    <div className="M-0_0  mb-2">
                      <h5 className="des-0__0">Key Specification</h5>
                      <ul>
                        <li>
                        {MainProduct?.specification}
                        </li>
                      </ul>
                    </div>
                    <div className="M-0_0  mb-2">
                      <h5 className="des-0__0">How to Use</h5>
                      <ul>
                        <li>
                        {MainProduct?.howTostore}
                        </li>
                      </ul>
                    </div>
                    {/* <div className="M-0_0  mb-2">
                      <h5 className="des-0__0">Benefits</h5>
                      <ul>
                        <li>
                          There are a variety of recipes that can be made with
                          this Dal like Dal Tadka, Dal Palak or you can use this
                          dal as a mix or use it to make Lentil Soup or use it
                          along with Veggies to make a delicious curry.
                        </li>
                      </ul>
                    </div>
                    <div className="M-0_0  mb-2">
                      <h5 className="des-0__0">Storage</h5>
                      <ul>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry
                        </li>
                      </ul>
                    </div> */}
                  </>
                ) : (
                  <>
                    {tab1 ? (
                      <>
                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Vendor:</td>
                              <td>{MainProduct?.vendor}</td>
                            </tr>
                            <tr>
                              <td>Size:</td>
                              <td>
                              {AllPrice?.map((ele) => {
                                  return (
                                    <span>
                                      {ele?.perQuntity}
                                      {MainProduct?.productvolumetype}
                                      {", "}
                                    </span>
                                  );
                                })}
                              </td>
                            </tr>
                            <tr>
                              <td>Product Id</td>
                              <td>{MainProduct?._id}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <>
                        {/* {tab2 ? (
                          <>
                            <div className="review-0">
                              <div className="spr-review">
                                <h2 class="spr-header-title">
                                  Customer Reviews
                                </h2>
                                <s
                                  className="write_re"
                                  onClick={() => setreview(!review)}
                                >
                                  Write review
                                </s>
                                <div className="jk_0 mt-3">
                                  {review ? (
                                    <>
                                      <div className="nalla_00">
                                        <div className="">Write a review</div>
                                        <div className="review-filed">
                                          <form>
                                            <div className="review__o mb-2">
                                              <label>Name</label>
                                              <br />
                                              <input
                                                type="text"
                                                placeholder="Name"
                                                className="spr-form-input-text"
                                                required
                                              />
                                            </div>
                                            <div className="review__o mb-2">
                                              <label>Email</label>
                                              <br />
                                              <input
                                                type="email"
                                                placeholder="example@gmail.com"
                                                className="spr-form-input-text"
                                                required
                                              />
                                            </div>
                                            <div className="review__o mb-2">
                                              <label>Title</label>
                                              <br />
                                              <input
                                                type="text"
                                                placeholder="Give your review a title"
                                                className="spr-form-input-text"
                                                required
                                              />
                                            </div> */}
                                            {/* <div className="review__o mb-2">
                                              <label>Body of Review </label>
                                              <br />
                                              <textarea
                                                placeholder="Give your review a title"
                                                className="spr-form-input-text"
                                                style={{ height: "151px" }}
                                                required
                                              />
                                            </div> */}
                                            {/* <div className="review__o mb-2">
                                              <button
                                                type="submit"
                                                className="write_re__090"
                                                style={{ float: "right" }}
                                              >
                                                Submit Review
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </> 
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}*/}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <div className="related mt-3">
            <div className="re-pro-0-9 text-center">
              <h4>Related Product</h4>
            </div>
            <div className="diff_he">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav={false}
                items={4}
                responsive={responsive}
              >
                {products
                ?.filter(
                  (ele) =>
                    ele.productcategory === MainProduct?.productcategory &&
                    ele?._id !== MainProduct?._id
                )
                .map((items, id) => {
                  return (
                    <div className="item">
                      <div className="single-product-wrap mb-3">
                        <div className="product-image">
                          <a href={`/distributor/distributor-product/${items?._id}`} className="pro-img">
                            <img
                              className="img-fluid img1"
                              src={`https://spilso.in/admin/product/${items?.productimage[0]}`}
                              alt=""
                              loading="lazy"
                              style={{ width: "100%", height: "280px" }}
                            />
                          </a>
                          <div className="product-label">
                            <span className="sale-title">
                            {items?.distributordiscountpercentage}%
                            </span>
                            {/* <div className="product-action">
                              <div className="action-wishlist tile-actions--btn wishlist-btn wishlist">
                                <Tooltip title="WISHLIST">
                                  {" "}
                                  <span class="add-wishlist" id="app-title">
                                    <i class="fa fa-heart"></i>
                                  </span>
                                </Tooltip>
                                <Tooltip title="ADD TO CART">
                                  {" "}
                                  <span class="add-wishlist">
                                    <i class="fa fa-shopping-bag"></i>
                                  </span>
                                </Tooltip>
                                <Tooltip title="QUICKVIEW">
                                  {" "}
                                  <span
                                    class="add-wishlist"
                                    onClick={showModal}
                                  >
                                    <i class="fa fa-eye"></i>
                                  </span>
                                </Tooltip>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="product-content mt-4">
                          <div className="product-title mb-1">
                            <a href={`/distributor/distributor-product/${items?._id}`}>{items?.productname}</a>
                          </div>
                          {/* <div className="product-ratting mb-1">
                            <span
                              className="spr-badge"
                              id="spr_badge_6820894441621"
                              data-rating="2.0"
                            >
                              <span class="spr-starrating spr-badge-starrating">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </span>
                            </span>
                          </div> */}
                          <div className="price-box mb-2">
                            <span className="new-price" id="ProductPrice">
                            ₹
                                {Math.round(
                                  Number(items?.productprice) +
                                    Math.round(
                                      items?.productprice * (items?.tax / 100)
                                    ) -
                                    (Number(items?.productprice) +
                                      Math.round(
                                        items?.productprice * (items?.tax / 100)
                                      )) *
                                      (items.distributordiscountpercentage / 100)
                                )}
                            </span>
                            <span className="old-price" id="ComparePrice">
                            ₹
                                {Number(items?.productprice) +
                                  Math.round(
                                    items?.productprice * (items?.tax / 100)
                                  )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default DistributorProductdetails;
