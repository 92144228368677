import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-multi-date-picker";

function Creditdebitnote() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [category, setcategory] = useState("");
  const [allexpense, setallexpense] = useState([]);
  const [creditdebitnote, setcreditdebitnote] = useState("");
  const [usertype, setusertype] = useState("");
  const [username, setusername] = useState("");
  const [date, setdate] = useState(new Date());
  const [amount, setamount] = useState("");
  const [expenseremark, setexpenseremark] = useState("");
  const [categoryimage, setcategoryimage] = useState();
  const [categorydata, setcategorydata] = useState([]);
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // getcountry();
    getcategory();
    if (usertype == "customer") {
      getcustomer();
    }
    if (usertype == "retailer") {
      getretailer();
    }
    if (usertype == "distributor") {
      getdistributor();
    }
    if (usertype == "corporate") {
      getcorporate();
    }
    if (usertype == "keyaccount") {
      getkeyaccount();
    }
    if (usertype == "employee") {
      getemployee();
    }
    if (usertype == "vendor") {
      getvendor();
    }
    if (usertype == "transporter") {
      gettransporter();
    }
  }, [usertype]);

  // const getcountry = async () => {
  //   let res = await axios.get(apiurl + "/admin/getcountry");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setData(res.data.country);
  //   }
  // };

  const postcategory = async (e) => {
    e.preventDefault();
    formdata.append("usertype", usertype);
    formdata.append("username", category);
    formdata.append("date", date);
    formdata.append("creditdebitnote", creditdebitnote);
    formdata.append("amount", amount);
    formdata.append("expanseremark", expenseremark);
    try {
      const config = {
        url: "/admin/addcreditdebitnote",
        method: "post",
        baseURL: apiURL,
        data: formdata,
        // headers: { "content-": "application/json" },
        // data: { categoryName: category },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("creditdebitnote added");
          window.location.assign("/admin/creditdebitnote");
        }
      });
    } catch (error) {
      console.error(error);
      alert("category Name Not Added");
    }
  };

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getallcreditdebitnote");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.creditdebitnote);
    }
  };

  const getcustomer = async () => {
    let res = await axios.get(apiURL + "/customer/getallcustomer");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.customer);
    }
  };

  const getdistributor = async () => {
    let res = await axios.get(apiURL + "/distributor/getalldistributor");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.distributor);
    }
  };

  const getretailer = async () => {
    let res = await axios.get(apiURL + "/retailer/getallretailer");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.retailer);
    }
  };

  const getcorporate = async () => {
    let res = await axios.get(apiURL + "/corporate/getallcorporate");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.corporate);
    }
  };

  const getemployee = async () => {
    let res = await axios.get(apiURL + "/employee/getallemployee");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.employee);
    }
  };

  const getkeyaccount = async () => {
    let res = await axios.get(apiURL + "/keyaccount/getallkeyaccount");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.keyaccount);
    }
  };

  const getvendor = async () => {
    let res = await axios.get(apiURL + "/vendor/getallvendor");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.vendor);
    }
  };

  const gettransporter = async () => {
    let res = await axios.get(apiURL + "/transporter/getalltransporter");
    if ((res.status = 200)) {
      console.log(res);
      setallexpense(res.data?.transporter);
    }
  };

  // const handleChange = (event) => {
  //   setCountryName(event.target.value);
  // };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  var i = 1;
  const remove = async (data) => {
    try {
      axios
        .post(`${apiURL}/admin/deletecreditdebitnote/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: categorydata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/creditdebitnote/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "usertype",
      text: "User Type",
      // formatter: imageFormatter
    },
    {
      dataField: "username",
      text: "User Name",
      // formatter: imageFormatter
    },
    {
      dataField: "creditdebitnote",
      text: "Payment/Credit/Debit",
      // formatter: imageFormatter
    },
    {
      dataField: "amount",
      text: "Amount",
      // formatter: imageFormatter
    },
    {
      dataField: "date",
      text: "Date",
      // formatter: imageFormatter
    },
    {
      dataField: "expanseremark",
      text: "Expanse Remark",
      // formatter: imageFormatter
    },
    // {
    //   dataField: '',
    //   text: 'Action',
    //   formatter: (cell, row) => {
    //     console.log(row);
    //     return <div><button className="btn btn-danger" onClick={()=>remove(row)}>Delete</button></div>;
    //   }
    // },
  ];

  return (
    <div className="mt-3">
      <Button variant="success" onClick={showModal} className="mb-2">
        Add Payment/Credit/Debit
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Payment/Credit/Debit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              {/* <FormGroup>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Select Country
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={countryName}
                      onChange={handleChange}
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {data?.map((name) => (
                        <MenuItem key={name._id} value={name?.countryName}>
                          <Checkbox
                            checked={countryName.indexOf(name?.countryName) > -1}
                          />
                          <ListItemText primary={name?.countryName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup> */}
              {/* <br></br> */}
              <div className="form-group">
                {/* <label> creditdebitnote Image</label>
                  <input
                    type="file"
                    placeholder="Enter Category Name"
                    className="form-control"
                    onChange={(e) => setcategoryimage(e.target.files[0])}
                  ></input>               */}
                <label className="mt-2">User Type</label>
                <select
                  className="form-control"
                  onChange={(e) => setusertype(e.target.value)}
                >
                  <option>Select the user type</option>
                  <option value="">Select User Type</option>
                  <option value="distributor">Distributor</option>
                  <option value="retailer">Retailer</option>
                  <option value="customer">Customer</option>
                  <option value="keyaccount">Key Account</option>
                  <option value="employee">Employee</option>
                  <option value="corporate">Corporate</option>
                  <option value="vendor">Vendor</option>
                  <option value="transporter">Transporter</option>
                </select>
                <label className="mt-2">User Name</label>
                <select
                  className="form-control"
                  onChange={(e) => setcategory(e.target.value)}
                >
                  <option>Select the user name</option>
                  {usertype == "distributor" ||
                  usertype == "retailer" ||
                  usertype == "vendor"
                    ? allexpense?.map((data) => (
                        <option value={data._id}>{data.firmname}</option>
                      ))
                    : ""}
                  {usertype == "customer"
                    ? allexpense?.map((data) => (
                        <option value={data._id}>{data.name}</option>
                      ))
                    : ""}
                  {usertype == "corporate" || usertype == "keyaccount"
                    ? allexpense?.map((data) => (
                        <option value={data._id}>{data.ownername}</option>
                      ))
                    : ""}
                  {usertype == "employee"
                    ? allexpense?.map((data) => (
                        <option value={data._id}>{data.employeename}</option>
                      ))
                    : ""}
                  {usertype == "transporter"
                    ? allexpense?.map((data) => (
                        <option value={data._id}>{data.transportername}</option>
                      ))
                    : ""}
                </select>
                <label>Payment /Credit / Debit Note</label>
                <select
                  class="form-control"
                  name="creditdebittype"
                  required=""
                  onChange={(e) => setcreditdebitnote(e.target.value)}
                >
                  <option value="">Select Payment / Credit /Debit Note</option>
                  <option value="Credit">Credit</option>
                  <option value="Debit">Debit</option>
                  <option value="Payment">Payment</option>
                </select>
                <label className="mt-2">Date</label>
                <DatePicker
                  // style={{width:"325px",height:"38px"}}
                  format="DD/MM/YYYY"
                  type="input-icon"
                  minDate={new Date()}
                  onChange={(date) => setdate(date.format("DD/MM/YYYY"))}
                />
                {/* <input
                    type="date"
                    placeholder="Enter Date"
                    className="form-control"
                    onChange={(e) => setdate(e.target.value)}
                  ></input> */}
                <label className="mt-2">Amount</label>
                <input
                  type="text"
                  placeholder="Enter Amount"
                  className="form-control"
                  onChange={(e) => setamount(e.target.value)}
                ></input>
                <label className="mt-2">Expense</label>
                <input
                  type="text"
                  placeholder="Enter Expense"
                  className="form-control"
                ></input>
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postcategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        {/* <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Sl. No</th>
                <th> Country Name</th>
                <th> Category Name</th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {categorydata?.map((category) => (
                <tr>
                  <td>{i++}</td>
                  <td>
                    {category.countryName?.map((category) => (
                      <span>{category},</span>
                    ))}
                  </td>
                  <td>{category.categoryName}</td>
                  <td>
                    <button
                      className="btn btn-success mr-5"
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => postdeleteamount(amount)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
        <ToolkitProvider
          keyField="id"
          data={categorydata}
          columns={columns}
          search
          exportCSV={{
            fileName: "patient.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              {/* <span className="pr-5 mr-auto"> 
              <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
              </span>   */}
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={categorydata}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Creditdebitnote;
