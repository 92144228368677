import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function DistributorWishlist() {
  const [wishlistitems, setwishlistitems] = useState([]);
  const distributor = JSON.parse(sessionStorage.getItem("distributor"));

  const getDistributorswishlistItems = async () => {
    try {
      const res = await axios.get(
        `https://spilso.in/api/getWishlistBydistributorId/${distributor._id}`
      );
      if (res.status === 200) {
        setwishlistitems(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDistributorswishlistItems();
  }, []);

  // let subtotal = 0;
  //   wishlistitems.forEach((i)=>{
  //     subtotal+=(i?.productId?.distributorofferPrice * i?.quantity);
  //   });

  return (
    <>
      <div className="cart">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="cart_hero">
                <div className="cart-title">
                  <h2>My Wishlist:</h2>
                  <div class="cart-count">
                    <span className="bigcounter">{wishlistitems?.length}</span>
                    <span className="cart-item-title">Item</span>
                  </div>
                </div>
                {wishlistitems.map((items) => {
                  return (
                    <div
                      className="ecommerce_cart"
                      style={{ borderTop: " 1px solid #eeeeee" }}
                    >
                      <div className="item-wrap">
                        <ul className="cart-wrap mt-2">
                          <li className="item-info">
                            <div className="item-img">
                              <Link to="#">
                                <img
                                  src={`https://spilso.in/admin/product/${items?.productId?.productimage[0]}`}
                                  alt="nalla-Image"
                                  className="img-fluid"
                                  style={{ width: "125px", height: "125px" }}
                                />
                              </Link>
                            </div>
                            <div className="item-title">
                              <p className="fresh_hero">
                                {items?.productId?.productname}
                              </p>
                              <p className="item-option">
                                <b>Size:</b> {items?.Size}
                              </p>
                              <p className="item-option">
                                {items?.quantity} {" x "} ₹
                                {Math.round(
                                  Number(items?.price) +
                                    Math.round(
                                      items?.price *
                                        (items?.productId?.tax / 100)
                                    ) -
                                    (Number(items?.price) +
                                      Math.round(
                                        items?.price *
                                          (items?.productId?.tax / 100)
                                      )) *
                                      (items?.productId
                                        ?.customerdiscountpercentage /
                                        100)
                                )}
                              </p>
                            </div>
                          </li>
                          <li className="item-qty">
                            <div className="product-quantity-action">
                              <div
                                className="product-quantity"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "#00a354",
                                }}
                              >
                                Add to cart
                              </div>
                            </div>
                            <div className="item-remove  mt-2">
                              <span className="remove-wrap">
                                <Link
                                  to="#"
                                  style={{
                                    fontSize: "16px",
                                    color: "#00a354",
                                  }}
                                >
                                  Buy now
                                </Link>
                              </span>
                            </div>
                          </li>
                          <li class="item-price">
                            <span class="money amount full-price-34766798487701">
                              ₹{items?.productId?.distributorofferPrice}
                            </span>{" "}
                            <br />
                            <span class="money amount full-price-34766798487701">
                              <i class="fa fa-times" aria-hidden="true"></i>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
                {/*  */}
                {/* <div
                  className="ecommerce_cart"
                  style={{ borderTop: " 1px solid #eeeeee" }}
                >
                  <div className="item-wrap">
                    <ul className="cart-wrap  mt-2">
                      <li className="item-info">
                        <div className="item-img">
                          <Link to="#">
                            <img
                              src="/webImages/1.jpg"
                              alt="nalla-Image"
                              className="img-fluid"
                              style={{ width: "125px", height: "125px" }}
                            />
                          </Link>
                        </div>
                        <div className="item-title">
                          <p className="fresh_hero">Fresh green orange</p>
                          <p className="item-option">
                            <b>Size:</b>5kg
                          </p>
                          <p className="item-option">₹800</p>
                        </div>
                      </li>
                      <li className="item-qty">
                        <div className="product-quantity-action">
                          <div
                            className="product-quantity"
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#00a354",
                            }}
                          >
                            Add to cart
                          </div>
                        </div>
                        <div className="item-remove  mt-2">
                          <span className="remove-wrap">
                            <Link
                              to="#"
                              style={{
                                fontSize: "16px",
                                color: "#00a354",
                              }}
                            >
                              Buy now
                            </Link>
                          </span>
                        </div>
                      </li>
                      <li class="item-price">
                        <span class="money amount full-price-34766798487701">
                          ₹8,00
                        </span>{" "}
                        <br />
                        <span class="money amount full-price-34766798487701">
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DistributorWishlist;
