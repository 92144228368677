import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Styles/patner.css";


function PattnerForm() {
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [WhoYouAre, setWhoYouAre] = useState("");
  const [comment, setcomment] = useState("");
  // const [Landmark, setLandmark] = useState("");
  // const [city, setcity] = useState("");
  // const [state, setstate] = useState("");
  // const [country, setcountry] = useState("");
  function isValidEmail(emailid){
            emailid=emailid?.trim();
            if(emailid?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)){
                return true;
            }
            return false;
        }

function isValidMobile(mobile){
            mobile=mobile?.trim();
            if(mobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) ){  //&& ! (mobile.match(/0{5,}/))
                return true;
            }
            return false;
        }    

function isEmpty(str){
            if(typeof (str) == "undefined" || typeof (str) == null) {return false}
            if(typeof (str) == "string" && (str).trim().length == 0) {return false}
            if(typeof (str) == 'number' && (str).toString().trim().length == 0){return false}
            return true
        }
 
  const addRequiest = async () => {
    try {
      if(!isEmpty(name)) return alert("Please enter full name")
      if(!isEmpty(email)) return alert("Please enter email id")
      if(!isValidEmail(email)) return alert("Invalid email id")
      if(!isEmpty(mobile)) return alert("Please enter mobile number")
      if(!isValidMobile(mobile)) return alert("Invalid mobile number")
      if(!isEmpty(WhoYouAre)) return alert("Please select role")
      if(!isEmpty(comment)) return alert("Please write same comment")
      const config = {
        url: "/addRequest",
        method: "post",
        baseURL: "https://spilso.in/api",
        headers: { "content-type": "application/json" },
        data: {
          WhoYouAre: WhoYouAre,
          email: email,
          Name: name,
          number: mobile,
          comments:comment
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Successfully accepted team will be contact as soon...");
          setname("");
          setmobile("");
          setemail("");
          setWhoYouAre("");
          setcomment("");
          window.location.assign("/thank-u")
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  return (
    <>
      <section
        className="common-use "
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="row" style={{border:"1px solid rgb(179 170 170 / 95%)",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}>
          <div className="col-md-6 p-0">
            <div className="">
              <img src="/img/featured/join_us.webp" alt="" style={{width:"100%",height:"500px",borderTopLeftRadius:"10px"}}/>
            </div>
          </div>
          <div className="col-md-6 p-0">
          <div className="partner-wrapper">
          <div
            className="banner-content pt-3 pb-4"
            style={{ fontSize: "30px" }}
          >
            <h3>Please Fill The Form Below</h3>
            <div style={{ fontSize: "18px" }}>
              We’ll get back to you shortly.
            </div>
          </div>
          <div className="">
            <form>
              <div class="form-group mb-3">
                <input
                  type="text"
                  name="entry.1386372166"
                  placeholder="Please enter full name"
                  className="form-control"
                  value={name}
                  onChange={(e)=>setname(e.target.value)}
                  required
                />
              </div>
              <div class="form-group mb-3">
                <input
                  type="email"
                  name="entry.1386372166"
                  placeholder="japggery@gmail.com"
                    value={email}
                  onChange={(e)=>setemail(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div class="form-group mb-3">
                <input
                  type="text"
                  name="entry.1386372166"
                  placeholder="998860*010"
                  className="form-control"
                       value={mobile}
                  onChange={(e)=>setmobile(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <span>Who You are?*</span>
                <select className="form-control" onChange={(e)=>setWhoYouAre(e.target.value)}>
                  <option value>Please select</option>
                  <option value="Influencer/ Food Bloggers">
                    Influencer/ Food Bloggers
                  </option>
                  <option value="Marketing Place">Marketing Place</option>
                  <option value="Suppliers">Suppliers</option>
                  <option value="Careers">Careers</option>
                  <option value="Retailers">Retailers</option>
                  <option value="Distribution">Distribution</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group  mb-3">
                <input
                  id={669756635}
                  type="text"
                  placeholder="Tell us how you want to Collab?*"
                  className="form-control"
                  value={comment}
                  onChange={(e)=>setcomment(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
             
               <Button variant="success" onClick={addRequiest}>Submit</Button>
              
              </div>
            </form>
          </div>
        </div>
          </div>
        </div>
     
      </section>
    </>
  );
}

export default PattnerForm;
