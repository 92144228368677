import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Country, State, City } from "country-state-city";
import {
  Button,
  Form,
  Card,
  Col,
  Container,
  Row,
  InputGroup,
} from "react-bootstrap";

function Adminretailer() {
  const apiURL = process.env.REACT_APP_API_URL;
  const no = sessionStorage.getItem("phoneno");
  const [countrycode, setcountrycode] = useState("");
  const [statecode, setstatecode] = useState("");
  const [city, setcity] = useState("");
  const countriesList = Country.getAllCountries();
  const statesList = State.getStatesOfCountry(countrycode);
  const cities = City.getCitiesOfState(countrycode, statecode);
  const [email, setemail] = useState("");
  const [firmname, setfirmname] = useState("");
  const [password, setpassword] = useState("");
  const [partnership, setpartnership] = useState("");
  const [partnersname, setpartnersname] = useState("");
  const [gstno, setgstno] = useState("");
  const [aadharno, setaadharno] = useState("");
  const [panno, setpanno] = useState("");
  const [villagename, setvillagename] = useState("");
  const [taluka, settaluka] = useState("");
  const [villagecode, setvillagecode] = useState("");
  const [pincode, setpincode] = useState("");
  const [addresslandmark, setaddresslandmark] = useState("");
  const [residentialaddress, setresidentialaddress] = useState("");
  const [alternateno, setalternateno] = useState("");
  const [whatsappno, setwhatsappno] = useState("");
  const [outlettype, setoutlettype] = useState("");
  const [bankname, setbankname] = useState("");
  const [accontno, setaccontno] = useState("");
  const [branch, setbranch] = useState("");
  const [bankcode, setbankcode] = useState("");
  const [permanentno, setpermanentno] = useState("");
  const [data, setdata] = useState([]);
  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function validatefirstname(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid first name!");
      return false;
    }
  }
  function validatelastname(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid last name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  const Onsubmit = async (e) => {
    e.preventDefault();
    if (!email | !password) {
      alert("Please Fill All Field");
    } else {
      try {
        const config = {
          url: "/retailer/signup",
          method: "post",
          baseURL: apiURL,
          headers: { "content-type": "application/json" },
          data: {
            firmname: firmname,
            email: email,
            password: password,
            partnership: partnership,
            partnersname: partnersname,
            gstno: gstno,
            aadharno: aadharno,
            panno: panno,
            villagename: villagename,
            taluka: taluka,
            villagecode: villagecode,
            districtname: city,
            state: statecode,
            country: countrycode,
            pincode: pincode,
            addresslandmark: addresslandmark,
            residentialaddress: residentialaddress,
            permanentno: permanentno,
            alternateno: alternateno,
            whatsappno: whatsappno,
            outlettype: outlettype,
            bankname: bankname,
            accontno: accontno,
            branch: branch,
            bankcode: bankcode,
          },
          //responseType:"application/json"
        };
        let res = await axios(config);
        console.log(res.status);
        if (res.status === 200) {
          console.log(res.data);
          alert("Account Created");
          sessionStorage.setItem("user", JSON.stringify(res.data.retailer));
          window.location.assign("/admin/approvedretailer");
          // sendotp();
          // localStorage.setItem("userdata",JSON.stringify(res.data.user));
          // localStorage.setItem("token",res.data.token);
          // window.location.assign("/signin");
          return res;
        } else {
          alert("Email or Mobile Already Exist");
          console.log(res.data.error);
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  useEffect(() => {
    // getcountry();
    getcategory();
  }, []);
  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getallpincode");
    if ((res.status = 200)) {
      console.log(res);
      setdata(res.data?.pincode);
    }
  };

  return (
    <div className="mt-1" style={{ background: "#F4F4F4" }}>
      <Row className="me-0">
        <Col lg={1} md={1}>
          {" "}
        </Col>
        <Col lg={10} md={10}>
          <Card className="mt-5 mb-5">
            <Card.Body>
              <h5 className="pl-3 pb-4">Retailer Sign Up</h5>
              <Form validated>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label>Retailer Name</label>
                    <input
                      type="text"
                      name="firm_name"
                      class="form-control"
                      placeholder="Retailer Name"
                      onChange={(e) => setfirmname(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Proprietor/Partnership</label>
                    <input
                      type="text"
                      name="partnership"
                      class="form-control"
                      placeholder="Proprietor/Partnership"
                      onChange={(e) => setpartnership(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Proprietor/Partner’s name</label>
                    <input
                      type="text"
                      name="partnername"
                      class="form-control"
                      placeholder="Proprietor/Partner’s name"
                      onChange={(e) => setpartnersname(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>GST No.</label>
                    <input
                      type="text"
                      name="gst_no"
                      class="form-control"
                      placeholder="GST No."
                      onChange={(e) => setgstno(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Aadhar No.</label>
                    <input
                      type="text"
                      name="aadhaar_no"
                      class="form-control"
                      placeholder="Aadhar No."
                      onChange={(e) => setaadharno(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Pan No</label>
                    <input
                      type="text"
                      name="pan_no"
                      class="form-control"
                      placeholder="Pan No"
                      onChange={(e) => setpanno(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Village name</label>
                    <input
                      type="text"
                      name="village_name"
                      class="form-control"
                      placeholder="Village name"
                      onChange={(e) => setvillagename(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Taluka</label>
                    <input
                      type="text"
                      name="taluka"
                      class="form-control"
                      placeholder="taluka"
                      onChange={(e) => settaluka(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Village code</label>
                    <input
                      type="text"
                      name="village_code"
                      class="form-control"
                      placeholder="Village code"
                      onChange={(e) => setvillagecode(e.target.value)}
                      required
                    />
                  </div>
                  {/* <!-- <div class="col-md-4 form-group">
          <label>State</label>
          <select name="state" class="form-control" required>
            <option value="Karnataka">Karnataka</option>
            <option value="Maharastra">Maharastra</option>
            <option value="Tamil">Tamil</option>
          </select>
        </div>
        <div class="col-md-4 form-group">
          <label>District Name</label>
          <select name="district_name" class="form-control" required>
            <option value="Bangalore">Bangalore</option>
            <option value="Mysore">Mysore</option>
          </select>
        </div> --> */}
                  <div class="col-md-4 form-group">
                    <label>
                      Country&nbsp;<span class="required">*</span>
                    </label>
                    <select
                      class="form-control"
                      name="student_country"
                      id="countryId"
                      onChange={(e) => setcountrycode(e.target.value)}
                      required=""
                    >
                      <option value="">Select Country</option>
                      {countriesList.map((country) => (
                        <option value={country.isoCode}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>State</label>
                    <select
                      name="state"
                      class="form-control"
                      id="stateId"
                      onChange={(e) => setstatecode(e.target.value)}
                      required
                    >
                      <option value="">Select State</option>
                      {statesList.map((state) => (
                        <option value={state.isoCode}>{state.name}</option>
                      ))}
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>District Name</label>
                    <select
                      name="district_name"
                      class="form-control"
                      id="cityDiv"
                      onChange={(e) => setcity(e.target.value)}
                      required
                    >
                      <option value="">Select District</option>
                      {cities.map((city) => (
                        <option value={city.name}>{city.name}</option>
                      ))}
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Pincode</label>
                    {/* <!--  <input type="text" name="pincode" class="form-control" placeholder="Pincode" required> --> */}
                    <select
                      type="text"
                      name="pincode"
                      class="form-control"
                      placeholder="Pincode"
                      onChange={(e) => setpincode(e.target.value)}
                      required
                    >
                      <option selected="" disabled="">
                        Select Post code/ zip
                      </option>
                      {data.map((e) => (
                        <option>{e.pincode}</option>
                      ))}
                      {/* <option>560001</option>
                      <option>560002</option>
                      <option>560003</option>
                      <option>560004</option>
                      <option>560005</option>
                      <option>560006</option>
                      <option>560007</option>
                      <option>560008</option>
                      <option>560009</option>
                      <option>560010</option>
                      <option>560011</option>
                      <option>560012</option>
                      <option>560013</option>
                      <option>560015</option>
                      <option>560016</option>
                      <option>560017</option>
                      <option>560018</option>
                      <option>560020</option>
                      <option>560021</option>
                      <option>560022</option>
                      <option>560023</option>
                      <option>560024</option>
                      <option>560025</option>
                      <option>560026</option>
                      <option>560027</option>
                      <option>560029</option>
                      <option>560030</option>
                      <option>560032</option>
                      <option>560033</option>
                      <option>560034</option>
                      <option>560035</option>
                      <option>560036</option>
                      <option>560037</option>
                      <option>560038</option>
                      <option>560040</option>
                      <option>560041</option>
                      <option>560042</option>
                      <option>560043</option>
                      <option>560045</option>
                      <option>560046</option>
                      <option>560047</option>
                      <option>560048</option>
                      <option>560049</option>
                      <option>560050</option>
                      <option>560051</option>
                      <option>560053</option>
                      <option>560054</option>
                      <option>560055</option>
                      <option>560056</option>
                      <option>560057</option>
                      <option>560058</option>
                      <option>560059</option>
                      <option>560060</option>
                      <option>560061</option>
                      <option>560062</option>
                      <option>560063</option>
                      <option>560064</option>
                      <option>560065</option>
                      <option>560066</option>
                      <option>560067</option>
                      <option>560068</option>
                      <option>560070</option>
                      <option>560071</option>
                      <option>560072</option>
                      <option>560073</option>
                      <option>560074</option>
                      <option>560075</option>
                      <option>560076</option>
                      <option>560077</option>
                      <option>560078</option>
                      <option>560079</option>
                      <option>560080</option>
                      <option>560081</option>
                      <option>560082</option>
                      <option>560083</option>
                      <option>560084</option>
                      <option>560085</option>
                      <option>560086</option>
                      <option>560087</option>
                      <option>560088</option>
                      <option>560089</option>
                      <option>560090</option>
                      <option>560091</option>
                      <option>560092</option>
                      <option>560093</option>
                      <option>560094</option>
                      <option>560095</option>
                      <option>560096</option>
                      <option>560097</option>
                      <option>560098</option>
                      <option>560099</option>
                      <option>560100</option>
                      <option>560102</option>
                      <option>560103</option>
                      <option>560104</option>
                      <option>560105</option>
                      <option>560107</option>
                      <option>560108</option>
                      <option>560109</option>
                      <option>560110</option>
                      <option>560111</option>
                      <option>560300</option>
                      <option>561101</option>
                      <option>561201</option>
                      <option>561202</option>
                      <option>561203</option>
                      <option>561204</option>
                      <option>561205</option>
                      <option>561206</option>
                      <option>561207</option>
                      <option>561208</option>
                      <option>561209</option>
                      <option>561210</option>
                      <option>561211</option>
                      <option>561212</option>
                      <option>561213</option>
                      <option>561228</option>
                      <option>562101</option>
                      <option>562102</option>
                      <option>562103</option>
                      <option>562104</option>
                      <option>562105</option>
                      <option>562106</option>
                      <option>562107</option>
                      <option>562108</option>
                      <option>562109</option>
                      <option>562110</option>
                      <option>562111</option> */}
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Address with Landmark</label>
                    <input
                      type="text"
                      name="addressWith_landmark"
                      class="form-control"
                      placeholder="Address with Landmark"
                      onChange={(e) => setaddresslandmark(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Residential address</label>
                    <input
                      type="text"
                      name="residential_address"
                      class="form-control"
                      placeholder="Residential address"
                      onChange={(e) => setresidentialaddress(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Permanent Contact No</label>
                    <input
                      type="text"
                      name="per_contactNo"
                      class="form-control"
                      placeholder="Permanent Contact No"
                      onChange={(e) => setpermanentno(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Alternate Contact No</label>
                    <input
                      type="text"
                      name="alt_contactNo"
                      class="form-control"
                      placeholder="Alternate Contact No"
                      onChange={(e) => setalternateno(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>WhatsApp No</label>
                    <input
                      type="text"
                      name="whatsapp_no"
                      class="form-control"
                      placeholder="WhatsApp No"
                      onChange={(e) => setwhatsappno(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Email ID</label>
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder="Mail ID"
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Password</label>
                    <input
                      type="text"
                      name="password"
                      class="form-control"
                      placeholder="Password"
                      onChange={(e) => setpassword(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Type of outlet</label>
                    <select
                      name="typeofoutlet"
                      class="form-control"
                      onChange={(e) => setoutlettype(e.target.value)}
                      required
                    >
                      <option value="">Select the outlet type</option>
                      <option value="Department Stores">
                        Department Stores
                      </option>
                      <option value="Discount Stores">Discount Stores</option>
                      <option value="Supermarket">Supermarket</option>
                    </select>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Bank Name</label>
                    <input
                      type="text"
                      name="bank_name"
                      class="form-control"
                      placeholder="Bank Name"
                      onChange={(e) => setbankname(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Account Number</label>
                    <input
                      type="text"
                      name="account_number"
                      class="form-control"
                      placeholder="Account Number"
                      onChange={(e) => setaccontno(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>IFSC Code</label>
                    <input
                      type="text"
                      name="ifsc_code"
                      class="form-control"
                      placeholder="IFSC Code"
                      onChange={(e) => setbankcode(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Branch </label>
                    <input
                      type="text"
                      name="branch"
                      class="form-control"
                      placeholder="Branch"
                      onChange={(e) => setbranch(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  {/* <div class="col-md-4 form-group">
                    <label>&nbsp;</label>
                    <input
                      type="checkbox"
                      name="term_condition"
                      value="1"
                      required
                    />
                    &nbsp;&nbsp;Term Condition
                  </div> */}
                  <div class="col-md-4 form-group">
                    <label for="">&nbsp;</label>
                    <button
                      type="submit"
                      class="form-control btn btn-primary"
                      onClick={Onsubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={1} md={1}>
          {" "}
        </Col>
      </Row>
    </div>
  );
}

export default Adminretailer;
