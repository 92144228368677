import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

import { IconButton } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import DatePicker from "react-multi-date-picker";

function Profile() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const apiURL = process.env.REACT_APP_API_URL;
  const [profilephoto, setprofilephoto] = useState();
  const [profile, setprofile] = useState();
  const [emptyprofile, setemptyprofile] = useState(
    "https://www.dgvaishnavcollege.edu.in/dgvaishnav-c/uploads/2021/01/dummy-profile-pic.jpg"
  );
  const formdata = new FormData();
  const [pincode, setpincode] = useState(user.pincode);
  const [email, setemail] = useState(user.email);
  const [name, setname] = useState(user.name);
  const [state, setstate] = useState(user.state);
  const [doorno, setdoorno] = useState(user.doorno);
  const [dateofbirth, setdateofbirth] = useState(user.dateofbirth);
  const [country, setcountry] = useState(user.country);
  const [city, setcity] = useState(user.city);
  const [area, setarea] = useState(user.area);
  const [addresstype, setaddresstype] = useState(user.addresstype);
  const [addressline, setaddressline] = useState(user.addressline);
  const [apartmentname, setapartmentname] = useState(user.apartmentname);
  const [isOpen1, setIsOpen1] = useState(false);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  useEffect(() => {
    getprofilephoto();
  }, []);

  const signout = () => {
    try {
      axios
        .get(`${apiURL}/customer/signout/${user._id}`)
        .then(function (res) {
          if (res.status === 200) {
            alert("signout Successfull");
            sessionStorage.removeItem("phoneno");
            sessionStorage.removeItem("user");
            window.location.assign("/");
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("signout not Successfull");
        });
    } catch (error) {
      console.warn(error);
      alert("signout not Successfull");
    }
  };
  useEffect(() => {
    if (profile) {
      postprofilephoto();
    }
  }, [profile]);
  const getprofilephoto = () => {
    try {
      axios
        .get(`${apiURL}/getprofile/` + user._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            if (response.data.profilephoto?.profilephoto) {
              console.log(true);
              setprofilephoto(response.data.profilephoto?.profilephoto);
            }
          }
        })
        .catch(function (error) {
          return;
        });
    } catch (error) {
      console.warn(error);

      return error;
    }
  };
  function pincodecheck(inputtxt) {
    // var phoneno = /^[6-9]\d{9}$/;
    var Pincode = /^[0-9]{6}$/;
    if (inputtxt.match(Pincode)) {
      return true;
    } else {
      alert("You have entered an invalid pincode!");
      return false;
    }
  }
  const search = async (e) => {
    try {
      if (pincodecheck(pincode)) {
        axios
          .post(`${apiURL}/searchpincode/` + pincode)
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
              editprofile();
            }
          })
          .catch(function (error) {
            console.log(error.message.data);
            alert("Service Unavailable for this pincode");
          });
      }
    } catch (error) {
      console.warn(error);
      alert("Service Unavailable");
      return error;
    }
  };
  console.log(profilephoto);
  const editprofile = async () => {
    try {
      const config = {
        url: "/customer/updatecustomer",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: {
          customerId: user._id,
          email: email,
          name: name,
          state: state,
          city: city,
          pincode: pincode,
          dateofbirth: dateofbirth,
          doorno: doorno,
          country: country,
          area: area,
          addressline: addressline,
          apartmentname: apartmentname,
          addresstype: addresstype,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        sessionStorage.setItem("user", JSON.stringify(res.data?.user));
        alert("details has been edited successfully");
        window.location.reload();
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const postprofilephoto = async () => {
    try {
      formdata.append("customerId", user._id);
      formdata.append("profilephoto", profile);
      const config = {
        url: "/postprofile",
        method: "post",
        baseURL: apiURL,
        header: { "Content-Type": "multipart/formdata" },
        data: formdata,
      };

      let res = await axios(config);

      if (res.status === 200) {
        console.log(res.data);
        setprofile();
        getprofilephoto();
      }
    } catch (error) {
      console.warn(error);

      return error;
    }
  };
  return (
    <div style={{ marginTop: "2rem" }}>
      <Container>
        <h3 className="mt-1 mb-3">
          Profile{" "}
          <span className="ms-2">
            *
            <i
              class="fa fa-pencil"
              style={{ color: "red" }}
              onClick={() => showModal1()}
            ></i>
          </span>
        </h3>
        <Row>
          <Col md={3}>
            <div style={{ marginLeft: "30px" }}>
              <img
                src={
                  profile === undefined && profilephoto === undefined
                    ? emptyprofile
                    : profilephoto !== undefined
                    ? "https://ilifeshoppie.info/customers/profile/" +
                      profilephoto
                    : URL.createObjectURL(profile)
                }
                alt="avatar"
                className=" "
                width="200px"
                height="200px"
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div style={{ marginLeft: "50px" }}>
              <div className="button">
                <input
                  accept="image/*, video/*"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => setprofile(e.target.files[0])}
                />
                <label className="mt-1" htmlFor="icon-button-file">
                  <IconButton
                    aria-label="upload picture"
                    component="span"
                    style={{ fontSize: "2rem" }}
                  >
                    <PhotoCamera />{" "}
                    <span style={{ fontSize: "20px" }}>* Choose from file</span>
                  </IconButton>
                </label>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="mt-5">
              <div>
                <div>
                  <b>Name :</b>
                  <span>{user.name}</span>
                </div>
                <div>
                  <b>D.O.B :</b>
                  <span>{user.dateofbirth}</span>
                </div>
                <div>
                  <b>Email :</b>
                  <span>{user.email}</span>
                </div>
              </div>
              <div className="mt-2">
                <div>
                  <b>Address:</b>
                  <span>
                    {user.doorno},{user.addresstype},
                    {user.addresstype === "Apartment" ? user.apartmentname : ""}
                    {user.addresstype === "Apartment" ? "," : ""}
                    {user.addressline},{user.area},{user.city},{user.state},
                    {user.country}
                  </span>
                </div>
                <div>
                  <b>Pincode :</b>
                  <span>{user.pincode}</span>
                </div>
              </div>
            </div>
          </Col>
          <Modal
            style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
            show={isOpen1}
            onHide={hideModal1}
          >
            <Modal.Header closeButton className="mt-4">
              <Modal.Title>Edit Profile </Modal.Title>
            </Modal.Header>

            <div className="ms-2 me-2">
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">Email</Form.Label>
                <Form.Control
                  type="email"
                  onChange={(e) => setemail(e.target.value)}
                  placeholder={user.email}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                <Form.Label className="mb-2 mt-2 fw-bold">name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setname(e.target.value)}
                  placeholder={user.name}
                />
              </Form.Group>

              <Form.Group controlId="formBasic">
                <Form.Label>Date of Brith</Form.Label>
                <div>
                  <DatePicker
                    style={{ width: "auto", height: "38px" }}
                    format="DD/MM/YYYY"
                    placeholder={user.dateofbirth}
                    type="input-icon"
                    maxDate={new Date()}
                    onChange={(date) =>
                      setdateofbirth(date.format("DD/MM/YYYY"))
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group controlId="formBasic">
                <Form.Label>Apartment</Form.Label>
                <Form.Control
                  as="select"
                  name="apartment"
                  onChange={(e) => setaddresstype(e.target.value)}
                  required
                >
                  {user.addresstype ? (
                    <option value="">{user.addresstype}</option>
                  ) : (
                    <option value="">Select the type</option>
                  )}
                  <option value="Individual House">Individual House</option>
                  <option value="Apartment">Apartment</option>
                </Form.Control>
              </Form.Group>
              {addresstype == "Apartment" ? (
                <Col lg={6} md={6}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Apartment Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={(e) => setapartmentname(e.target.value)}
                      required
                      placeholder={user.apartmentname}
                    />
                  </Form.Group>
                </Col>
              ) : (
                " "
              )}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Door No</Form.Label>
                <Form.Control
                  type="text"
                  name="Houseno"
                  onChange={(e) => setdoorno(e.target.value)}
                  placeholder={user.doorno}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Address </Form.Label>
                <Form.Control
                  type="text"
                  name="Addressline1"
                  onChange={(e) => setaddressline(e.target.value)}
                  placeholder={user.addressline}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  name="Area"
                  onChange={(e) => setarea(e.target.value)}
                  placeholder={user.area}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>City </Form.Label>
                <Form.Control
                  type="text"
                  name="City"
                  onChange={(e) => setcity(e.target.value)}
                  placeholder={user.city}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="State"
                  onChange={(e) => setstate(e.target.value)}
                  placeholder={user.state}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Country </Form.Label>
                <Form.Control
                  type="text"
                  name="Country"
                  onChange={(e) => setcountry(e.target.value)}
                  placeholder={user.country}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="pincode"
                  name="Pincode"
                  onChange={(e) => setpincode(e.target.value)}
                  placeholder={user.pincode}
                  required
                />
              </Form.Group>
            </div>
            <Modal.Footer>
              <Button variant="success" onClick={hideModal1}>
                Close
              </Button>
              <Button variant="danger" onClick={() => search()}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>
    </div>
  );
}

export default Profile;
