import axios from "axios";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

function DistributorTestimonials() {
  const [Testimonials, setTestimonials] = useState([]);
  const getTestimonials = async () => {
    try {
      const res = await axios.get("https://spilso.in/api/getAllTestimonial");
      if (res.status === 200) {
        console.log(res.data.success);
        setTestimonials(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("fdddddddddddddddddddddddd", Testimonials);
  useEffect(() => {
    getTestimonials();
    getTestimonials();
  }, []);
  return (
    <>
      <section
        class="latest-product spad"
        style={{ backgroundColor: " #ead2cf" }}
      >
        <div class="container">
          <div class="section-title">
            <h2>Testimonials</h2>
          </div>
          <div className="c_04">
            <div className="c_)9">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                items={3}
                nav={false}
              >
                {Testimonials?.map((items) => {
                  return (
                    <div class="item" key={items?.toString()}>
                      <div className="testi_man">
                        <div className="texti_img">
                          <img
                            src={`https://spilso.in/testimonial/${items?.Image}`}
                            alt="Testimonial_image"
                            className="testimonial_img"
                          />
                        </div>
                        <div
                          className=""
                          style={{ position: "relative", marginTop: "-15px" }}
                        >
                          <div className="testi_content">
                            <span>
                              <img src="/invertedcommas.png " alt="" />
                            </span>
                            <div className="pv_0">{items?.text}</div>
                            <div
                              className="text-center"
                              style={{ fontWeight: "600" }}
                            >
                              {items?.Name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi2.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <div className="pv_0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </div>
                      <div className="text-center"style={{fontWeight:"600"}}>Neelam Godwa</div>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi3.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <div className="pv_0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </div>
                      <div className="text-center"style={{fontWeight:"600"}}>Neelam Godwa</div>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi4.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <div className="pv_0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </div>
                      <div className="text-center"style={{fontWeight:"600"}}>Neelam Godwa</div>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi5.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <div className="pv_0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </div>
                      <div className="text-center"style={{fontWeight:"600"}}>Neelam Godwa</div>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi3.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <div className="pv_0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </div>
                      <div className="text-center"style={{fontWeight:"600"}}>Neelam Godwa</div>
                    </div>
                   </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testi_man">
                    <div className="texti_img">
                      <img src="/img/featured/testi5.jpg" alt="Testimonial_image" className="testimonial_img" />
                    </div>
                   <div className="" style={{position:"relative",marginTop:"-15px"}}>
                   <div className="testi_content">
                      <span><img src="/invertedcommas.png " alt=""/></span>
                      <div className="pv_0">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. 
                      </div>
                      <div className="text-center"style={{fontWeight:"600"}}>Neelam Godwa</div>
                    </div>
                   </div>
                  </div>
                </div>
               */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DistributorTestimonials;
