import axios from "axios";
import React from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Container,
  Row,
  InputGroup,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

function Customersignin() {
  const [number, setnumber] = useState("");
  const apiURL = process.env.REACT_APP_API_URL;
  const [term, setterm] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(false);

  function onCaptchaVerify(value) {
    setCaptchaValue(value);
  }
  const handlesignin = async (e) => {
    e.preventDefault();
    if (!term) {
      alert("Select the Term & Condition");
    } else if(!number) {
      alert("Please enter your phone number");
    } else {
      try {
        const config = {
          url: "/customer/phoneno",
          method: "post",
          baseURL: apiURL,
          headers: { "content-type": "application/json" },
          data: { phoneNumber: number },
          //responseType:"application/json"
        };
        let res = await axios(config);
        if (res.status === 200) {
          if (res.data.user.profilestatus) {
            if (res.data.user.blockstatus) {
              alert("Your account is blocked");
            } else {
              sessionStorage.setItem("phoneno", number);

              sendotp();
            }
          }
           else {
            sessionStorage.setItem("phoneno", number);

            sendotp();
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  
  };

  const sendotp = async () => {
    console.log("sendotp");
    try {
      const config = {
        url: "/sendotp",
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: { phoneNumber: number, type: "Customer" },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log("otp",res.data);
        alert("otp is send to your Phone Number");
        window.location.assign("/verify-otp");
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ background: "#f6f8fc" }}>
      <div className="container pt-5">
        <div className="row" style={{ padding: " 50px 30px" }}>
          <div className="col-md-6 ">
            <img
              src="shop.png"
              style={{ width: "100%", height: "500px" }}
            ></img>
            {/* <p className="t-c pt-3"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p> */}
          </div>
          <div className="col-md-6">
            <form validated>
              <div className="row ">
                <div className="col-md-12 t-c ">
                  <h1 className="" style={{ padding: "20px 20px 50px" }}>
                    Sign In{" "}
                  </h1>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="formBasic">
                    <Form.Label>
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>+91</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="Mobile"
                        onChange={(e) => setnumber(e.target.value)}
                        placeholder="Enter Mobile No"
                      />
                    </InputGroup>
                  </Form.Group>

                  <div>
                    <ReCAPTCHA
                      sitekey="6LdiARoaAAAAAPpRZ8n7muGi7JnbvR_JTd-eaMbx"
                      onChange={onCaptchaVerify}
                    />
                  </div>
                </div>
                <div className="form-group pb-2">
                  <input
                    type="checkbox"
                    className="ml-4 mr-2 mt-2"
                    onChange={() => setterm(!term)}
                    required
                  />
                  <label for="vehicle1"> Terms & Conditions</label>
                </div>
                <div
                  className="col-md-12 pt-2"
                  style={{ padding: "20px 20px 10px", float: "right" }}
                >
                  <div className="form-gropu pt-2">
                    <button
                      className="btn btn-primary form-control"
                      type="submit"
                      style={{
                        padding: "5px",
                        background:
                          "linear-gradient(45deg, var(--bs-pink), var(--bs-purple))",
                      }}
                      onClick={handlesignin}
                    >
                      {" "}
                      Sign In
                    </button>
                  </div>

                  {/* <p className="t-c pt-2" style={{textDecoration:" underline "}} > Don't have an account? <Link to="/signup">Sign Up</Link>  </p> */}
                  {/* <p className="t-c"> Or Continue with</p> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customersignin;
