import React, { useEffect, useState } from "react";
import "../../Components/customer/Styles/style12.css";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
function DistributorOursBrand() {
  const [Brands, setBrands] = useState([]);

  useEffect(() => {
    const allBrands = async () => {
      try {
        const res = await axios.get("https://spilso.in/api/getAllBrand");
        if (res.status === 200) {
          setBrands(res.data.success);
        }
      } catch (error) {
        console.log(error);
      }
    };
    allBrands();
    allBrands();
  }, []);
  return (
    <>
      <section class="categories" style={{ padding: "0px 40px" }}>
        <div class="container">
          <div class="section-title">
            <h2>Our Brands</h2>
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={5}
            dots={false}
            autoplay={true}
          >
            {Brands?.map((items) => {
              return (
                <div class="item" key={items?.toString()}>
                  <div class="brands_0">
                    <img
                      src={`https://spilso.in/brand/${items?.brandImage}`}
                      alt="brands"
                    />
                  </div>
                </div>
              );
            })}
            {/* <div class="item">
              <div class="brands_0">
                <img src="/img/featured/Brand3.jpg" alt="brands" />
              </div>
            </div>
            <div class="item">
              <div class="brands_0">
                <img src="/img/featured/Brand4.jpg" alt="brands" />
              </div>
            </div>
            <div class="item">
              <div class="brands_0">
                <img src="/img/featured/Brand5.jpg" alt="brands" />
              </div>
            </div>
            <div class="item">
              <div class="brands_0">
                <img src="/img/featured/Brand1.png" alt="brands" />
              </div>
            </div> */}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
}

export default DistributorOursBrand;
