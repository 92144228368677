import React, { useState } from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";

// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Card, Carousel, Container,Row,Col} from "react-bootstrap";
import {
 

  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Category from "./Category";
import Essentials from "./Essentials";
import Topdeals from "./Topdeals";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Styles/home.css";
import HighQuality from "./HighQuality"
import Slider from "./Slider"
import BestSeller from './BestSeller'


function Home(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [pincode, setpincode] = useState("");
  const [lat, setlat] = useState("");
  const [lon, setlon] = useState("");
  const [localshop, setlocalshop] = useState([]);
  const [topdeals, settopdeals] = useState([]);
  const [pincodedata, setpincodedata] = useState([]);
  const [banner, setbanner] = useState([]);

  const apiURL = process.env.REACT_APP_API_URL;
  const shop = sessionStorage.getItem("shop");
  // const reatiler = sessionStorage.getItem("shop");
  const pincodenew = sessionStorage.getItem("Pincode");
  const [slider, setslider] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [ilife, setilife] = useState("");
  const [retailer, setretailer] = useState("");
  const [colorchange, setcolorchange] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setlat(position.coords.latitude);
      setlon(position.coords.longitude);
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
    if (user) {
      if (!pincodenew) {
        showModal();
      }
    }
  }, [user]);

  useEffect(() => {
    // if (local) {
    getlocalshops();
    // }
  }, []);
  const getbanner = async () => {
    let res = await axios.get(apiURL + "/getallbanner/customer");
    if ((res.status = 200)) {
      console.log(res);
      setbanner(res.data?.banner);
    }
  };

  useEffect(() => {
    // getcountry();
    getpincode();
    getbanner()
  }, []);
  const getpincode = async () => {
    let res = await axios.get(apiURL + "/getallpincode");
    if ((res.status = 200)) {
      console.log(res);
      setpincodedata(res.data?.pincode);
    }
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const showModal1 = () => {
    setIsOpen1(true);
    setIsOpen(false);
  };

  const addtocart = (e) => {
    if (!user) {
      alert("please login");
    } else {
      props.dispatch({
        type: "addBasketItem",
        item: { product: e, quantity: 1 },
      });
    }
  };
  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const hideModal2 = () => {
    setIsOpen1(false);
    window.location.reload();
  };

  const search = async (e) => {
    e.preventDefault();
    try {
      axios
        .post(`${apiURL}/searchpincode/` + pincode)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            alert("Service Available");
            sessionStorage.setItem("Pincode", pincode);

            // sessionStorage.setItem("retailerproduct", retailer);
            //  alert("Service Available");
            hideModal();
            showModal1();
            // window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Service Unavailable");
        });
    } catch (error) {
      console.warn(error);
      alert("Service Unavailable");
      // setdatacondition(true);
      return error;
    }
  };

  const catresponsive = {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };

  const getlocalshops = async () => {
    let res = await axios.get(apiURL + "/getlocalshop/" + pincodenew);
    if ((res.status = 200)) {
      console.log(res.data);
      setlocalshop(res.data?.localshop);
    }
  };

  useEffect(() => {
    getslider();
    gettopdeals();
  }, []);

  const getslider = async () => {
    let res = await axios.get(apiURL + "/getallslider/customer");
    if ((res.status = 200)) {
      console.log(res);
      setslider(res.data?.slider);
    }
  };

  const gettopdeals = async () => {
    let res = await axios.get(apiURL + "/admin/topdeals");
    if ((res.status = 200)) {
      console.log(res);
      settopdeals(res.data?.data1);
    }
  };

  const ids = pincodedata?.map((o) => o.districtname);
  const filtered = pincodedata?.filter(
    ({ districtname }, index) => !ids.includes(districtname, index + 1)
  );
  console.log(localshop);

  return (
    <div>
       <section>
        <div className="hom-banner">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                style={{ height: "500px" }}
                src="/webImages/slider1-min.jpg"
                alt="First slide"
              />
              <Carousel.Caption className="text_he">
                <p>Summer vege sale</p>
                <h1>
                  Fresh fruits <br /> &amp; vegetable
                </h1>
                <a href="#" class="btn btn-style1">
                  Shop now
                </a>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                style={{ height: "500px" }}
                src="/webImages/slider2.webp"
                alt="Second slide"
              />

              <Carousel.Caption className="text_he second">
                <p>Organic indian masala</p>
                <h1>
                  Prod of indian <br /> 100% packaging
                </h1>
                <a href="#" class="btn btn-style1">
                  Shop now
                </a>
              </Carousel.Caption>
            </Carousel.Item>
           
          </Carousel>
        </div>
      </section>
 {/* Banner-card */}



      {/* 2nd */}
      <HighQuality/>
      {/* 3rd */}
      <Slider/>
      {/* 4th */}
      <BestSeller/>
      {/* {local ? ( */}

      {/* ) : (
        " "
      )} */}
      {/* <div className="px-3 pb-3 title d-flex align-items-center">
              <h5 className="m-0 pt-3">Top Deals</h5>{" "}
            </div>
            <div>
              <OwlCarousel
                className="owl-theme topdeals-carousel"
                loop
                margin={10}
                nav={true}
                // items={5}
                responsive={catresponsive}
                autoplay={true}
                autoHeightClass="owl-height"
              >
                {topdeals.map((e) => (
                  <div
                    class="item"
                    onClick={() =>
                      props.dispatch({
                        type: "Subscribe",
                        item: { product: e, quantity: 1 },
                      })
                    }
                  >
                    <Card sx={{ maxWidth: 300 }}>
                      <Link
                        to={{
                          pathname: "/productdetails",
                          item: { product: topdeals },
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <CardMedia
                          component="img"
                          image={`${imageURL}/admin/product/${e.productimage[0]}`}
                          alt="green iguana"
                        />
                      </Link>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          <h6>{e.productname}</h6>
                          <p>
                            <b>₹ {e.customerofferPrice} </b>{" "}
                            <span
                              style={{
                                textDecorationLine: "line-through",
                                textDecorationStyle: "solid",
                              }}
                            >
                              M.R.P: ₹ {e.productprice}
                            </span>
                          </p>
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          className="cart-btn"
                          endIcon={<AddIcon style={{ color: "white" }} />}
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#7b3ae5",
                            color: "white",
                          }}
                          onClick={() => {
                            addtocart(e);
                            alert("Product is Added to Cart");
                          }}
                        >
                          {" "}
                          Add to cart
                        </Button>
                      </CardActions>
                    </Card>
                  </div>
                ))}
              </OwlCarousel>
            
            </div>
      
      <Topdeals></Topdeals>
      <Essentials></Essentials>
      <Modal
        size="sm"
        show={isOpen}
        className="my-modal"
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        style={{
          marginTop: "280px",
          right: "200px",
          backgroundColor: "none !important",
        }}
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            Enter Pincode for Service
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "center" }}>
           

            <input
              style={{ width: "255px" }}
              className="form-control"
              placeholder="Enter the pincode"
              onChange={(e) => setpincode(e.target.value)}
            />

            <button
              className="btn btn-success"
              onClick={search}
              style={{ background: "var(--bs-pink)", marginLeft: "5px" }}
            >
              Next
            </button>
          </div>
        </Modal.Body>
      
      </Modal>
      <Modal
        show={isOpen1}
        onHide={hideModal1}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">Buy from?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          

          <input
            className="form-control"
            placeholder="Enter the pincode"
            onChange={(e) => setpincode(e.target.value)}
          /> 
          <div className="row">
            <div className="col-6">
              <div
                onClick={() => {
                  sessionStorage.setItem("shop", "ilife");
                  setcolorchange("ilife");
                }}
                style={{
                  border: `1px solid ${
                    colorchange === "ilife" ? "red" : "white"
                  }`,
                  borderRadius: "10px",
                }}
              >
                <img
                  src="logo.png"
                  style={{ width: "150px", height: "100px" }}
                ></img>
                <p
                  className="header-signin btn btn-primary"
                  style={{
                    marginTop: "15px",
                    color: "blue",
                    backgroundColor: "white",
                    border: "1px solid white",
                  }}
                >
                  Ilifeshoppie{" "}
                </p>
              </div>
            </div>

            <div className="col-6">
              <div
                onClick={() => {
                  sessionStorage.setItem("shop", "retailer");
                  setcolorchange("retailer");
                }}
                style={{
                  border: `1px solid ${
                    colorchange === "retailer" ? "red" : "white"
                  }`,
                  borderRadius: "10px",
                }}
              >
                <img
                  src="/retailerlogo.png"
                  style={{ width: "150px", height: "100px" }}
                ></img>
                <p
                  className="header-signin btn btn-primary"
                  style={{
                    marginTop: "15px",
                    color: "blue",
                    marginLeft: "10px",
                    backgroundColor: "white",
                    border: "1px solid white",
                  }}
                >
                  Near By Retailers
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            onClick={hideModal2}
            style={{
              background:
                "linear-gradient(45deg, var(--bs-pink), var(--bs-indigo))",
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    localshop: state.localshop,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Home);
