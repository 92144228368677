import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

function Retailersubcategorys() {
  const location = useLocation();
  const { data } = location.state;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [categorydata, setcategorydata] = useState([]);
  console.log(data);
  useEffect(() => {
    // getcountry();
    postallsubcategory();
  }, [data]);

  const postallsubcategory = async () => {
    let res = await axios.post(apiURL + "/admin/postsubcategory", {
      categoryName: data.categoryName,
    });
    if ((res.status = 200)) {
      console.log(res.data);
      setcategorydata(res.data?.subcategory);
    }
  };
  return (
    <div className="container allcategory">
      <Row xs={1} md={4} className="g-4 mt-4 me-0">
        {categorydata?.map((data) => (
          <Col>
            <Card>
              <Link
                to={{
                  pathname: "/retailer/products",
                  state: { data: data },
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Card.Img
                  variant="top"
                  src={imageURL + "/subcategory/" + data.subcategoryimage}
                />
                <Card.Body>
                  <Card.Title>{data.subcategoryName}</Card.Title>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Retailersubcategorys;
