import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

function Admindeliverycharge() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [isOpen, setIsOpen] = useState(false);
   const [isOpen1, setIsOpen1] = useState(false);
  const [data, setData] = useState({});
  const [category, setcategory] = useState("");
  const [categoryimage, setcategoryimage] = useState("");
  const [categorydata, setcategorydata] = useState([]);
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

    const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };

  useEffect(() => {
    // getcountry();
    getcategory();
  }, []);

  // const getcountry = async () => {
  //   let res = await axios.get(apiurl + "/admin/getcountry");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setData(res.data.country);
  //   }
  // };

  const postcategory = async (e) => {
    e.preventDefault();
   if(!categoryimage) return alert("Please enter minmum amount!");
   if(!category) return alert("Please enter delivery charge!")
    try {
      const config = {
        url: "/admin/adddeliverycharge",
        method: "post",
        baseURL: apiURL,
        // data: formdata,
        headers: { "content-type": "application/json" },
        data: { deliverycharge: category, minimumamount: categoryimage },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("Delivery charge added");
          getcategory()
          hideModal()
        }
      });
    } catch (error) {
      console.error(error);
      alert(error.response.data.error);
    }
  };

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getalldeliverycharge");
    if ((res.status = 200)) {
      console.log(res);
      setcategorydata(res.data?.deliverycharge);
    }
  };

  // const handleChange = (event) => {
  //   setCountryName(event.target.value);
  // };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  var i = 1;
  const remove = async () => {
    try {
      axios
        .post(`${apiURL}/admin/deletedeliverycharge/` + data?._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            alert("Successfully deleted")
            getcategory()
            hideModal1()
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: categorydata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/expense/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      // dataField: "minimumamount",
      text: "",
      // formatter: imageFormatter
    },
    {
      dataField: "minimumamount",
      text: "Minimum Amount",
      // formatter: imageFormatter
    },
    {
      dataField: "deliverycharge",
      text: "Delivery Charge ",
      // formatter: imageFormatter
    },
    {
      // dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-danger" onClick={()=>{
              showModal1()
              setData(row)
            }}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-3">
      <Button variant="success" onClick={showModal} className="mb-2">
        Add Delivery Charge
      </Button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Delivery Charge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              <div className="form-group">
                <label>Minimum Amount</label>
                <input
                  type="number"
                  placeholder="Enter Minimum Amount"
                  className="form-control"
                  onChange={(e) => setcategoryimage(e.target.value)}
                ></input>
                <label className="mt-2">Delivery Charge in Rupees</label>
                <input
                  type="number"
                  placeholder="Enter Delivery Charge in Rupees"
                  className="form-control"
                  onChange={(e) => setcategory(e.target.value)}
                ></input>
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postcategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* deleteMode */}
         <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Are you sure delete !</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Cancel
            </Button>
            <Button
              variant="danger"
              style={{ cursor: "pointer" }}
              onClick={() => remove()}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      <div>
        <ToolkitProvider
          keyField="id"
          data={categorydata}
          columns={columns}
          search
          exportCSV={{
            fileName: "patient.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={categorydata}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Admindeliverycharge;
