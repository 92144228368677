import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import AddIcon from "@material-ui/icons/Add";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Topdeals(props) {
  const [categorydata, setcategorydata] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [topdeals, settopdeals] = useState([]);
  const [banner, setbanner] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log(user);

  useEffect(() => {
    getbanner();
    gettopdeals();
  }, []);

  const addtocart = (e) => {
    if (!user) {
      alert("please login");
    } else {
      props.dispatch({
        type: "addBasketItem",
        item: { product: e, quantity: 1 },
      });
    }
  };

  const gettopdeals = async () => {
    let res = await axios.get(apiURL + "/admin/topdeals");
    if ((res.status = 200)) {
      console.log(res);
      settopdeals(res.data?.data1);
    }
  };

  console.log(topdeals);

  const catresponsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  };

  const getbanner = async () => {
    let res = await axios.get(apiURL + "/getallbanner/customer");
    if ((res.status = 200)) {
      console.log(res);
      setbanner(res.data?.banner);
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5,
    },
  };
  return (
    <div>
      <div className="category-container mb-5">
        {/* <h3 className="py-3">Near By Retailer </h3> */}
        {/* <div>
          <Carousel responsive={responsive} className="topdeals-carousel">
            {topdeals.map((e) => (
              <div
                class="items"
                onClick={() =>
                  props.dispatch({
                    type: "Subscribe",
                    item: { product: e, quantity: 1 },
                  })
                }
              >
                <Link
                  to={{
                    pathname: "/productdetails",
                    item: { product: topdeals },
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <img
                    src={`${imageURL}/admin/product/${e.productimage[0]}`}
                    alt=""
                  ></img>
                  <h6>{e.productname}</h6>
                  <p>
                    <b>₹ {e.customerofferPrice} </b>{" "}
                    <span
                      style={{
                        textDecorationLine: "line-through",
                        textDecorationStyle: "solid",
                      }}
                    >
                      M.R.P: ₹ {e.productprice}
                    </span>
                  </p>
                </Link>

                <Button
                  variant="contained"
                  className="cart-btn"
                  endIcon={<AddIcon />}
                  onClick={() => addtocart(e)}
                >
                  Add to cart
                </Button>
              </div>
            ))}
          </Carousel>
        </div> */}
        {/* <OwlCarousel
          className="owl-theme topdeals-carousel"
          loop
          margin={10}
          nav={true}
          // items={5}
          responsive={catresponsive}
          autoplay={true}
          autoHeightClass="owl-height"
        >
          {topdeals.map((e) => (
            <div
              class="item"
              onClick={() =>
                props.dispatch({
                  type: "Subscribe",
                  item: { product: e, quantity: 1 },
                })
              }
            >
              <Link
                to={{
                  pathname: "/productdetails",
                  item: { product: topdeals },
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={`${imageURL}/retailer/product/${e.productimage[0]}`}
                  alt=""
                ></img>
                <h6>{e.productname}</h6>
                <p>
                  <b>₹ {e.customerofferPrice} </b>{" "}
                  <span
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }}
                  >
                    M.R.P: ₹ {e.productprice}
                  </span>
                </p>
              </Link>

              <Button
                variant="contained"
                className="cart-btn"
                endIcon={<AddIcon />}
                onClick={() => addtocart(e)}
              >
                Add to cart
              </Button>
            </div>
          ))}
        </OwlCarousel> */}
      </div>
      <div className="custom-offer mb-2">
        <div className="container">
          {/* <Row xs={1} md={2}> */}
          <OwlCarousel
          className="owl-theme topdeals-carousel"
        
          loop= {true}
         
          margin={10}
          navClass
          responsive={catresponsive }

          autoPlay={true}
          autoplayTimeout={3000}
          autoHeightClass="owl-height"
        >
            {banner.map((e) => (
              <Col>
                <Card>
                  <Card.Img
                    // style={{ height: "380px" }}
                    variant="top"
                    src={`${imageURL}/banner/${e.banner}`}
                  ></Card.Img>
                </Card>
              </Col>
            ))}
            </OwlCarousel>
          {/* </Row> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    Subscribe: state.Subscribe,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Topdeals);
