import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

function Adminapprovedtransporter() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  useEffect(() => {
    gettransporters();
  }, []);

  const gettransporters = () => {
    try {
      axios.get(`${apiURL}/employee/notapproved`).then(function (response) {
        if (response.status === 200) {
          console.log(response.data);
          //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
          setdata(response.data.employee);
          return;
        }
      });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  // const remove = async () => {
  //   try {
  //     let res = await axios.delete(`${apiURL}/deleteEmployee/${selected1}`);
  //     if (res.status == 200) {
  //       alert("Succssfully deleted");
  //       gettransporters();
  //       hideModal();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const block = async (data) => {
    try {
      axios
        .post(`${apiURL}/blocktransporter/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const approve = async (data) => {
    try {
      axios
        .post(`${apiURL}/blockUnblockemployee/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            alert(response.data.success);
            gettransporters();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .post(`${apiURL}/unblocktransporter/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/employee/profile/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
        onClick={() =>
          window.location.assign(`${imageURL}/employee/profile/${cell}`)
        }
      />
    );
  }

  const columns = [
    {
      text: "Sl No",
    },
    {
      // dataField: "deid",
      text: "Distributor Id",
      sort: true,
    },
    {
      // dataField: "transportername",
      text: "Distributor Name",
      sort: true,
    },
    {
      dataField: "_id",
      text: "Employee Id",
    },
    {
      dataField: "empProfile",
      text: "Profile ",
      formatter: imageFormatter,
    },
    {
      dataField: "employeename",
      text: "Employee Name",
    },
    {
      dataField: "officalemail",
      text: "Email",
    },
    {
      dataField: "contactno",
      text: "Contact No",
      sort: true,
    },
    {
      dataField: "alternateno",
      text: "Alternet Number",
    },
    {
      dataField: "aadhaarno",
      text: "Adhar No",
    },
    {
      dataField: "",
      text: "Date of Birth",
      formatter: (cell, row) => {
        return <div>{moment(row.dob).format("DD-MMM-YYYY")}</div>;
      },
    },
    {
      dataField: "officeaddress",
      text: "Current Address",
      sort: true,
    },
    {
      dataField: "residentialaddress",
      text: "Permanent Address",
      sort: true,
    },
    {
      dataField: "highestQuli",
      text: "Heighest Qualification",
    },
    {
      dataField: "highestQuliDoc",
      text: "Education Document ",
      formatter: imageFormatter,
    },
    {
      dataField: "IDtype",
      text: "Id Proof",
    },
    {
      dataField: "IDDoc",
      text: "Id Doc",
      formatter: imageFormatter,
    },
    {
      dataField: "",
      text: "Date Of Joining",
      formatter: (cell, row) => {
        return <div>{moment(row.joinDate).format("DD-MMM-YYYY")}</div>;
      },
    },

    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {!row?.blockstatus ? (
              <button
                className="btn btn-outline-success"
                onClick={() => approve(row)}
              >
                Block
              </button>
            ) : (
              <button
                className="btn btn-outline-danger"
                onClick={() => approve(row)}
              >
                Un-Block
              </button>
            )}
          </div>
        );
      },
    },
    // {
    //   dataField: 'status',
    //   text: 'Status',
    //   sort : true
    // }
  ];

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Distributor Employee
        </h1>
        {/* <p className="end">
        <Link to="/admin/addemployeeform">
          <Button variant="primary">Add Employee</Button>
          </Link>
        </p> */}
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              search
              exportCSV={{
                fileName: "Approvedtransporters.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  <span className="pl-5 ml-5 mr-5 mt-5">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  <ClearSearchButton
                    className="btn-outline-info"
                    {...props.searchProps}
                  />

                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="transporter"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminapprovedtransporter;
