import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { RiCustomerService2Line } from 'react-icons/ri';
import StockDashboard from "./StockDashboard";

function Admindashboard() {
  const apiURL = process.env.REACT_APP_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [data, setdata] = useState({});
  useEffect(() => {
    getuserdetail();
  }, []);

  const getuserdetail = () => {
    try {
      axios
        .get(`${apiURL}/admin/dashboard`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  console.log(data);
  console.log(
    new Date(
      moment(moment("13/01/2020 2:00 AM", "DD/MM/YYYY hh:mm A")).format(
        "YYYY/MM/DD"
      )
    )
  );
  return (
    <>   
    <section className="background">
    <br/>
  <section className="container">
    <section className="grid6">
    <div className="box red center">
      <Card.Body>
        <h3 className="d-text">Customer</h3>
        <h3 className="count">{data?.customer}</h3>
        </Card.Body>
      </div>

      <div className="box green center">
      <Card.Body>
        <h3 className="d-text">Retailers</h3>
        <h3 className="count">{data?.retailer}</h3>
        </Card.Body>
      </div>

      <div className="box pink center">
      <Card.Body>
        <h3 className="d-text">Distributor</h3>
        <h3 className="count">{data?.distributor}</h3>
        </Card.Body>
      </div>
  
      <div className="box blue center">
      <Card.Body>
        <h3 className="d-text">Employee</h3>
        <h3 className="count">12</h3>
        </Card.Body>
      </div>

      <div className="box worange center">
      <Card.Body>
        <h3 className="d-text">Products</h3>
        <h3 className="count">{data?.products}</h3>
        </Card.Body>
      </div>

      <div className="box wdarkb center">
      <Card.Body>
        <h3 className="d-text">Combo Products</h3>
        <h3 className="count">18</h3>
        </Card.Body>
      </div>
     
    </section>
    <section className="grid6">
    <div className="box darkb center">
      <Card.Body>
        <h3 className="d-text">Orders</h3>
        <h3 className="count">12</h3>
        </Card.Body>
      </div>

      <div className="box orange center">
      <Card.Body>
        <h3 className="d-text">Pending Orders</h3>
        <h3 className="count">15</h3>
        </Card.Body>
      </div>

      <div className="box blue center">
      <Card.Body>
        <h3 className="d-text">Out For Delivery</h3>
        <h3 className="count">18</h3>
        </Card.Body>
      </div>
  
      <div className="box pink center">
      <Card.Body>
        <h3 className="d-text">Cancel Orders</h3>
        <h3 className="count">12</h3>
        </Card.Body>
      </div>

      <div className="box wgreen center">
      <Card.Body>
        <h3 className="d-text">Delivered Orders</h3>
        <h3 className="count">15</h3>
        </Card.Body>
      </div>

      <div className="box wred center">
      <Card.Body>
        <h3 className="d-text">Free Products</h3>
        <h3 className="count">18</h3>
        </Card.Body>
      </div>
     <br/>
     <br/>
     <br/>
    </section>
  </section>
  <StockDashboard style={{marginLeft:"-10%"}}/>
    {/*  =============================================================== */}
    </section>
    <br/>

    </>
  );
}

export default Admindashboard;
