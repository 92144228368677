import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function Distributorofferproduct(props) {
  const apiurl = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [product, setproduct] = useState();

  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let res = await axios.get(
      apiurl + "/distributor/getproduct/" + props.localshop.localshop?._id,
      {
        type: "retailer",
      }
    );
    if ((res.status = 200)) {
      console.log(res);
      setproduct(
        res.data?.products.filter(
          (item) => item.retailerdiscountpercentage >= 20
        )
      );
    }
  };
  console.log(product);
  return (
    <div className="" style={{ background: "f4f4f4", marginTop: "3rem" }}>
      <div className="px-3 pb-3 mt-2 title d-flex align-items-center">
        <h5 className="m-0 pt-3">Distributor offer Products</h5>
        {/* <a className="pt-3 font-weight-bold ml-auto" href="most_popular.html">26 places <i className="feather-chevrons-right"></i></a>  */}
      </div>
      <div className="most_popular px-3">
        <Row>
          {product?.length > 0 ? (
            <>
              {product?.map((data) => (
                <Col md={3} className=" pr-2 mt-2">
                  <Card>
                    <div className="list-card-image">
                      <Button
                        component={Link}
                        to="/retailer/productdetails"
                        onClick={() =>
                          props.dispatch({
                            type: "Subscribe",
                            item: { product: data, quantity: 1 },
                          })
                        }
                      >
                        {data.type == "admin" ? (
                          <img
                            src={
                              imageURL +
                              "/admin/product/" +
                              data.productimage[0]
                            }
                            className="img-fluid item-img w-100"
                          ></img>
                        ) : data.type == "vendor" ? (
                          <img
                            src={
                              imageURL +
                              "/vendor/product/" +
                              data.productimage[0]
                            }
                            className="img-fluid item-img w-100"
                          ></img>
                        ) : (
                          <img
                            src={
                              imageURL +
                              "/admin/product/" +
                              data.productimage[0]
                            }
                            className=" item-img "
                            style={{ width: "200px", height: "200px" }}
                          ></img>
                        )}{" "}
                      </Button>
                    </div>
                    <div className="p-3 position-relative">
                      <div className="list-card-body">
                        <h5 className="mb-1 text-center">
                          {data.productname.length > 50 ? (
                            <>{`${data.productname.substring(0, 40)}...`}</>
                          ) : (
                            data.productname
                          )}
                        </h5>
                        <div
                          className="text-center"
                          style={{ fontSize: "large" }}
                        >
                          MRP ₹{" "}
                          <span>
                            <s>{data.productprice}</s>{" "}
                          </span>
                        </div>
                        <div
                          className="text-gray mb-1  text-center"
                          style={{ fontSize: "x-large", color: "#dc3545" }}
                        >
                          ₹ {data.retailerofferPrice}
                        </div>
                        <div className="list-card-badge text-center mt-2">
                          {" "}
                          <span
                            className="badge badge-warning"
                            style={{ color: "white" }}
                          >
                            SAVE
                          </span>{" "}
                          <large>{data.retailerdiscountpercentage}% Off</large>{" "}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </>
          ) : (
            <h5 className="mt-2 text-center"> No Product is Found</h5>
          )}
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    localshop: state.localshop,
    basket: state.basket,
  };
};

export default connect(mapStateToProps)(Distributorofferproduct);
