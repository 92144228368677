import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import * as XLSX from "xlsx";
import { AiFillDelete } from "react-icons/ai";

function Adminpincode() {
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [isOpen, setIsOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [pincode, setpincode] = useState("");
  const [villagename, setvillagename] = useState("");
  const [officename, setofficename] = useState("");
  const [subdistrictname, setsubdistrictname] = useState("");
  const [districtname, setdistrictname] = useState("");
  const [statename, setstatename] = useState("");
  const [data1, setdata1] = useState([]);
  const [excel, setexcel] = useState();
  const [expecteddeliverydays, setexpecteddeliverydays] = useState("");

  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [data3, setData3] = useState({});
  
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };

  useEffect(() => {
    // getcountry();
    getcategory();
  }, []);

  // const getcountry = async () => {
  //   let res = await axios.get(apiurl + "/admin/getcountry");
  //   if ((res.status = 200)) {
  //     console.log(res);
  //     setData(res.data.country);
  //   }
  // };

  const postcategory = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/addpincode",
        method: "post",
        baseURL: apiURL,
        // data: formdata,
        headers: { "content-type": "application/json" },
        data: {
          pincode: pincode,
          villagename: villagename,
          officename: officename,
          subdistrictname: subdistrictname,
          districtname: districtname,
          statename: statename,
          expecteddeliverydays: expecteddeliverydays,
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("Pincode Added");
          getcategory();
          hideModal()
        }
      });
    } catch (error) {
      console.error(error);
      alert("category Name Not Added");
    }
  };

  if (excel && data1.length === 0) {
    readFile();
  }

  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setdata1(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }

  function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  const postmultiproduct = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/addmultipincode",
        method: "post",
        baseURL: apiURL,
        // data: formdata,
        headers: { "content-type": "application/json" },
        data: {
          adminId: user.id,
          pincodes: data1.map((datas) => ({
            pincode: datas.pincode,
            villagename: datas.villagename,
            officename: datas.officename,
            subdistrictname: datas.subdistrictname,
            districtname: datas.districtname,
            statename: datas.statename,
            expecteddeliverydays: datas.expecteddeliverydays,
          })),
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          console.log("success");
          alert("Pincode Added");
          window.location.assign("/admin/pincode");
        }
      });
    } catch (error) {
      console.error(error);
      alert("Pincode Not Added");
    }
  };

  const getcategory = async () => {
    let res = await axios.get(apiURL + "/getallpincode");
    if ((res.status = 200)) {
      console.log(res);
      setdata(res.data?.pincode);
    }
  };

  // const handleChange = (event) => {
  //   setCountryName(event.target.value);
  // };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const changemakeblock= async (id)=>{
    try {
      let res=await axios.put(`${apiURL}/admin/makeActiveDeactive/${id}`);
      if(res.status==200){
        alert(res.data.success);
        getcategory()
      }
    } catch (error) {
      console.log(error);
    }
  }

  var i = 1;
  const remove = async () => {
    try {
      axios
        .post(`${apiURL}/admin/deletepincode/` + data3._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
          alert("Successfully deleted")
          hideModal1();
          getcategory();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
   
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${imageURL}/category/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      text: "",
      sort: true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },
    {
      dataField: "pincode",
      text: "Pincode",
      sort: true,
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return <div>Dr.{`${row.firstname} ${row.lastname}`}</div>;
      // }
    },
    {
      dataField: "villagename",
      text: "Village Name",
      sort: true,
    },
    {
      dataField: "officename",
      text: "Office Name",
      sort: true,
    },
    {
      dataField: "subdistrictname",
      text: "Sub District Name",
      sort: true,
    },
    {
      dataField: "districtname",
      text: "District Name",
      sort: true,
    },
    {
      dataField: "statename",
      text: "State Name",
      sort: true,
    },
    {
      dataField: "expecteddeliverydays",
      text: "Expected Delivery Days",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },

    //   {
    //     dataField: 'categoryimage',
    //     text: 'Category Image',
    //     formatter: imageFormatter
    //   },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div>
      
            {row?.status==="Active" ? (<button type="button" class="btn btn-danger" onClick={()=>changemakeblock(row?._id)}>Deactive</button>):(<button type="button" class="btn btn-success" onClick={()=>changemakeblock(row?._id)}>Active</button>)}
           {" "} <AiFillDelete
              className="delete-button" onClick={()=>{
              showModal1()
              setData3(row)
            }}/>
           
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-3">
      <div className="d-flex">
        <Button variant="success" onClick={showModal} className=" me-2">
          Add Pincode
        </Button>
        <Button
          style={{ textDecoration: "none" }}
          href={imageURL + "/excelfile/pincode.xlsx"}
          variant="info"
          className=" me-2"
        >
          Download Excel
        </Button>
        <input
          accept=".xlsx,.xls,.csv"
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
          onChange={(e) => setexcel(e.target.files[0])}
        />
        <label className="btn btn-danger " htmlFor="icon-button-file">
          {" "}
          Import Excel
        </label>
        <Button
          className="btn btn-success ms-2 me-2"
          onClick={postmultiproduct}
        >
          Upload
        </Button>
      </div>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Pincode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              {/* <FormGroup>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={countryName}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {data?.map((name) => (
                      <MenuItem key={name._id} value={name?.countryName}>
                        <Checkbox
                          checked={countryName.indexOf(name?.countryName) > -1}
                        />
                        <ListItemText primary={name?.countryName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup> */}
              {/* <br></br> */}
              <div className="form-group">
                <label> Pincode</label>
                <input
                  type="text"
                  placeholder="Enter Pincode"
                  className="form-control"
                  onChange={(e) => setpincode(e.target.value)}
                ></input>
                <label className="mt-2"> Village Name</label>
                <input
                  type="text"
                  placeholder="Enter Village Name"
                  className="form-control"
                  onChange={(e) => setvillagename(e.target.value)}
                ></input>
                <label className="mt-2"> Office Name</label>
                <input
                  type="text"
                  placeholder="Enter Office Name"
                  className="form-control"
                  onChange={(e) => setofficename(e.target.value)}
                ></input>
                <label className="mt-2"> Subdistrict Name</label>
                <input
                  type="text"
                  placeholder="Enter Subdistrict Name"
                  className="form-control"
                  onChange={(e) => setsubdistrictname(e.target.value)}
                ></input>
                <label className="mt-2"> District Name</label>
                <input
                  type="text"
                  placeholder="Enter District Name"
                  className="form-control"
                  onChange={(e) => setdistrictname(e.target.value)}
                ></input>
                <label className="mt-2"> State Name</label>
                <input
                  type="text"
                  placeholder="Enter State Name"
                  className="form-control"
                  onChange={(e) => setstatename(e.target.value)}
                ></input>
                <label className="mt-2"> Expected Delivery Days</label>
                <input
                  type="text"
                  placeholder="Enter Expected Delivery Days"
                  className="form-control"
                  onChange={(e) => setexpecteddeliverydays(e.target.value)}
                ></input>
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={postcategory}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
        {/* deleteMode */}
        <Modal
          style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
          show={isOpen1}
          onHide={hideModal1}
          animation={false}
        >
          <Modal.Header closeButton className="mt-4">
            <Modal.Title>Are you sure delete !</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="success" onClick={hideModal1}>
              Cancel
            </Button>
            <Button
              variant="danger"
              style={{ cursor: "pointer" }}
              onClick={() => remove()}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      <div>
        {/* <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Sl. No</th>
              <th> Country Name</th>
              <th> Category Name</th>
              <th> Action</th>
            </tr>
          </thead>
          <tbody>
            {categorydata?.map((category) => (
              <tr>
                <td>{i++}</td>
                <td>
                  {category.countryName?.map((category) => (
                    <span>{category},</span>
                  ))}
                </td>
                <td>{category.categoryName}</td>
                <td>
                  <button
                    className="btn btn-success mr-5"
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => postdeleteamount(amount)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
          exportCSV={{
            fileName: "patient.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              {/* <span className="pr-5 mr-auto"> 
            <ExportCSVButton className="btn-outline-success" { ...props.csvProps }>Export to CSV</ExportCSVButton> 
            </span>   */}
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive">
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={data}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Adminpincode;
