import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./customer/components/Footer";
import Header from "./customer/components/Header";
import Home from "./customer/components/Home";
import Products from "./customer/components/Products";
import Productdetails from "./customer/components/Productdetails";
import Customersignin from "./customer/components/Customersignin";
import Customersignup from "./customer/components/Customersignup";
import Customerverifyotp from "./customer/components/Customerverifyotp";
import AdminLogin from "./admin/components/Login";
import Adminlayout from "./admin/components/layout/Layout";
import Adminuserdata from "./admin/components/Userdata";
import Category from "./admin/components/Category";
import Subcategory from "./admin/components/Subcategory";
import Adminproducts from "./admin/components/Singleproduct";
import DistributorMyWallet from "./NewDistributor/DistributorMyWallet";
import DistributorTrackOrders from "./NewDistributor/DistributorTrackOrders";
import DistributorMyShopping from "./NewDistributor/DistributorMyShopping";
import DistributorProductdetails from "./NewDistributor/DistributorProductdetails";
import DistributorProduct from "./NewDistributor/DistributorProduct";
import DistributorCart from "./NewDistributor/DistributorCart";
import DistributorWishlist from "./NewDistributor/DistributorWhislist";
import DistributorCheckout from "./NewDistributor/DistributorCheckout";
import DistibutorLogin from "./NewDistributor/DistibutorLogin";
import DistributorOtp from "./NewDistributor/DistributorOtp";
import DistributorOrders from "./NewDistributor/DistributorOrders";
import Retailerhome from "./retailer/components/Retailerhome";
import Retailersignin from "./retailer/components/Retailersignin";
import Retailersignup from "./retailer/components/Retailersignup";
import Retailerverifyotp from "./retailer/components/Retailerverifyotp";
import Adminaddproduct from "./admin/components/Adminaddproduct";
import Cart from "./customer/components/Cart";
import Adminpincode from "./admin/components/Adminpincode";
import Retailerproductdetails from "./retailer/components/Retailerproductdetails";
import Retailerproducts from "./retailer/components/Retailerproducts";
import Retailercart from "./retailer/components/Retailercart";
import DistributorStock from "./NewDistributor/DistributorStock";
import DistributorPurchase from "./NewDistributor/DistributorPurchase";
import DistributorExpense from "./NewDistributor/DistributorExpense";
import DistributorExpenseType from "./NewDistributor/DIstributorExpenseType";
import Retailerheader from "./retailer/components/Retailerheader";
import Retailerfooter from "./retailer/components/Retailerfooter";
import Localshop from "./customer/components/Localshop";
import Localshopproducts from "./customer/components/Localshopproducts";
import Customerinvoice from "./customer/components/Customerinvoice";
import Subscription from "./customer/components/Subscription";
import Customersliders from "./admin/components/Customersliders";
import Distributorsliders from "./admin/components/Distributorsliders";
import Admindashboard from "./admin/components/Admindashboard";
import Retailersliders from "./admin/components/Retailersliders";
import Retailerbanner from "./admin/components/Retailerbanner";
import Distributorbanner from "./admin/components/Distributorbanner";
import Customerbanner from "./admin/components/Customerbanner";
import Customerdailyessientials from "./admin/components/Customerdailyessientials";
import Distributordailyessientials from "./admin/components/Distributordailyessientials";
import Retailerdailyessientials from "./admin/components/Retailerdailyessientials";
import Admincustomer from "./admin/components/Admincustomer";
import Admindistributor from "./admin/components/Admindistributor";
import Adminapproveddistributor from "./admin/components/Adminapproveddistributor";
import Adminretailer from "./admin/components/Adminretailer";
import Adminapprovedretailer from "./admin/components/Adminapprovedretailer";
import Adminkeyaccount from "./admin/components/Adminkeyaccount";
import Adminapprovedkeyaccount from "./admin/components/Adminapprovedkeyaccount";
import Admincorporate from "./admin/components/Admincorporate";
import Adminapprovedcorporate from "./admin/components/Adminapprovedcorporate";
import Adminemployee from "./admin/components/Adminemployee";
import Adminapprovedemployee from "./admin/components/Adminapprovedemployee";
import Admintransporter from "./admin/components/Admintransporter";
import Adminapprovedtransporter from "./admin/components/Adminapprovedtransporter";
import Adminvendor from "./admin/components/Adminvendor";
import Adminapprovedvendor from "./admin/components/Adminapprovedvendor";
import Adminme from "./admin/components/Adminme";
import Adminapprovedme from "./admin/components/Adminapprovedme";
import Adminde from "./admin/components/Adminde";
import Adminapprovedde from "./admin/components/Adminapprovedde";
import Adminexpensetype from "./admin/components/Adminexpensetype";
import Adminexpense from "./admin/components/Adminexpense";
import Admincustomerorders from "./admin/components/Admincustomerorders";
import Admindistributororders from "./admin/components/Admindistributororders";
import Adminretailerorders from "./admin/components/Adminretailerorders";
import Admincorporateorders from "./admin/components/Admincorporateorders";
import Adminkeyaccountorders from "./admin/components/Adminkeyaccountorders";
import Adminemployeeorders from "./admin/components/Adminemployeeorders";
import Adminpromocode from "./admin/components/Adminpromocode";
import Adminpackage from "./admin/components/Adminpackage";
import Adminreferalcode from "./admin/components/Adminreferalcode";
import Creditdebitnote from "./admin/components/Creditdebitnote";
import Admindeliverycharge from "./admin/components/Admindeliverycharge";
import Admindeliveryslot from "./admin/components/Admindeliveryslot";
import Adminpickslot from "./admin/components/Adminpickslot";
import Adminlocalshoporders from "./admin/components/Adminlocalshoporders";
import Adminreturnorders from "./admin/components/Adminreturnorders";
import Adminpurchaseorder from "./admin/components/Adminpurchaseorder";
import Admindistributorretailerorders from "./admin/components/Admindistributorretailerorders";
import Vendorsignin from "./vendor/components/Vendorsignin";
import Vendorverifyotp from "./vendor/components/Vendorverifyotp";
import Vendorsignup from "./vendor/components/Vendorsignup";
import Adminpincodeblock from "./admin/components/Adminpincodeblock";
import Adminorderview from "./admin/components/Adminorderview";
import Customerorders from "./customer/components/Customerorders";
import Customersubscriptionorders from "./customer/components/Customersubscriptionorders";
import Adminbulkinvoice from "./admin/components/Adminbulkinvoice";
import Adminsubadmin from "./admin/components/Adminsubadmin";
import Addsubadmin from "./admin/components/Addsubadmin";
import Adminaccountstatement from "./admin/components/Adminaccountstatement";
import Admincustomerinvoice from "./admin/components/Admincustomerinvoice";
import Topretailer from "./admin/components/Topretailer";
import Vendorlayout from "./vendor/components/layout/Vendorlayout";
import Vendorproduct from "./vendor/components/Vendorproduct";
import Vendoraddproduct from "./vendor/components/Vendoraddproduct";
import Allcategorys from "./customer/components/Allcategorys";
import Subcategorys from "./customer/components/Subcategorys";
// import Distributoraddproduct from "./NewDistributor/components/Distributoraddproduct";
// import Distributoraddedproduct from "./NewDistributor/components/Distributoraddedproduct";
import Retaileraddproduct from "./retailer/components/Retaileraddproduct";
import Retaileraddedproduct from "./retailer/components/Retaileraddedproduct";
import Account from "./customer/components/Account";
// import Distributoraddretailer from "./NewDistributor/components/Distributoraddretailer";
// import Distributorretailerdata from "./NewDistributor/components/Distrbutorretailerdata";
import Retailersubcategorys from "./retailer/components/Retailersubcategorys";
import Retailercustomerorder from "./retailer/components/Retailercustomerorder";
import Retailerorderview from "./retailer/components/Retailerorderview";
import Retailercustomerinvoice from "./retailer/components/Retailercustomerinvoice";
import Retailerlocalshopproduct from "./retailer/components/Retailerlocalshopproduct";
// import Distributorretailerorder from "./NewDistributor/Distributorretailerorder";
import Retailermyorders from "./retailer/components/Retailermyorders";
// import Distributorretailerorderview from "./NewDistributor/Distributorretailerorderview";
import Retaileraccount from "./retailer/components/Retaileraccount";
// import Distributoraccount from "./NewDistributor/components/Distributoraccount";
import Topdistributor from "./admin/components/Topdistributor";
import Voucher from "./admin/components/Voucher";
import Transaction from "./admin/components/Transaction";
// import Distributororder from "./NewDistributor/Distributororder";
import Returnorderview from "./admin/components/Returnorderview";
import Profile from "./customer/components/Profile";
import Terms from "./customer/components/Terms";
import Policy from "./customer/components/Policy";
import Addaddress from "./customer/components/AddAddress";
// import Distributorprofile from "./NewDistributor/Distributorprofile";
// import Distributoraddaddress from "./NewDistributor/components/Distributoraddaddress";
// import Distributorterms from "./NewDistributor/components/Distributorterms";
// import DistributorPolicy from "./NewDistributor/components/DistributorPolicy";
import Retailerprofile from "./retailer/Retailerprofile";
import Retaileraddaddress from "./retailer/components/Retaileraddaddress";
import Retailerterms from "./retailer/components/Retailerterms";
import Retailerpolicy from "./retailer/components/Retailerpolicy";
import Adminnotification from "./admin/components/Adminnotification";
import Notification from "./customer/components/Notification";
import Retaileroffer from "./customer/components/Retaileroffer";
import Retailerofferproduct from "./customer/components/Retailerofferproduct";
import Favourites from "./customer/components/Favourites";
import Search from "./customer/components/Search";
import Retailerfavourite from "./retailer/components/Retailerfavourite";
import Distributoroffer from "./retailer/components/Distributoroffer";
import Distributorofferproduct from "./retailer/components/Distributorofferproduct";
import Retailersearch from "./retailer/components/Retailersearch";
import Retailernotification from "./retailer/components/Retailernotification";
import Retailerallcategory from "./retailer/components/Retailerallcategory";
import Retailerlocalshop from "./retailer/components/Retailerlocalshop";
import DistributorNotApproved from "./NewDistributor/DistributorNotApproved";
import DistributorApproved from "./NewDistributor/DistributorApproved";
import DistributorEmployer from "./NewDistributor/DistributorEmployer";
import DistributorAddRetailer from "./NewDistributor/DistributorAddRetailer";
import Customerordermap from "./customer/components/Customerordermap";
import DistributorHome from "./NewDistributor/DistributorHome";
import Retailerordermap from "./retailer/components/Retailerordermap";
import Adminretailerorderview from "./admin/components/Adminretailerorderview";
import Admindistributororderview from "./admin/components/Admindistributororderview";
import Adminretailerreturnorder from "./admin/components/Adminretailerreturnorder";
import Admindistributorreturnorder from "./admin/components/Admindistributorreturnorder";
import Admindreturnorderview from "./admin/components/Admindreturnorderview";
import Adminrreturnorderview from "./admin/components/Adminrreturnorderview";
import Adminretailerinvoice from "./admin/components/Adminretailerinvoice";
import Admindistributorinvoice from "./admin/components/Admindistributorinvoice";
import Cinvoice from "./customer/components/Cinvoice";
import DistributorOrderslist from "./NewDistributor/DistributorOrderslist";
import Rinvoice from "./retailer/components/Rinvoice";
import Admindistributorproduct from "./admin/components/Admindistributorproduct";
import Adminretailerproduct from "./admin/Adminretailerproduct";
import Paymentpolicy from "./customer/components/Paymentpolicy";
import Refundpolicy from "./customer/components/Refundpolicy";
import Header2 from "./customer/components/Header2";
import Login from "./customer/components/Login";
import Register from "./customer/components/Register";
import Pattner from "./customer/components/Pattner";
import Wishlist from "./customer/components/Wishlist";
import PattnerForm from "./customer/components/PattnerForm";
import Thanku from "./customer/components/Thanku";
// import Distributoraccount1 from "./NewDistributor/components/Distributoraccount1";
// import Distributoraddaddress1 from "./NewDistributor/components/Distributoraddaddress1";

// import Distributoraddproduct1 from "./NewDistributor/components/Distributoraddproduct1";
// import Distributorsignin1 from "./NewDistributor/components/DistributorsLogin";
// import DistributorsLogin1 from "./NewDistributor/components/DistributorsLogin";
import Checkout from "./customer/components/Checkout";

import Home1 from "./customer/components/Home1";
import Profile1 from "./customer/components/Profile1";
import MyWallet from "./customer/components/MyWallet";
import TrackOrders from "./customer/components/TrackOrders";
import FourNotFour from "./customer/components/FourNotFour";
import MyShopping from "./customer/components/MyShopping";
import ViewsOrder from "./customer/components/ViewsOrders";
import PartnerRequest from "./admin/components/PartnerRequest";
import Feedback from "./admin/components/Feedback";
import NotapprovedRetailer from "./admin/components/NotapprovedRetailer";
import AdminAddRetailer from "./admin/components/AdminAddRetailer";
import EmployeeListForm from "./admin/components/EmployeeListForm";
import DistributorSalesHistory from "./admin/components/DistributorSalesHistory";
import DistributorCashHistory from "./admin/components/DistributorCashHistory";
import DistributorAccountsHistory from "./admin/components/DistributorAccountsHistory";
import Stock from "./admin/components/Stock";
import StockDashboard from "./admin/components/StockDashboard";
import ProductVolume from "./admin/components/ProductVolume";
import HsnCode from "./admin/components/HsnCode";
import AdminWallet from "./admin/components/AdminWallet";
import AdminWalletCustomer from "./admin/components/AdminWalletCustomer";
import AdminWalletDistributor from "./admin/components/AdminWalletDistributor";
import AdminWalletRetailer from "./admin/components/AdminWalletRetailer";
import AdminAddRetailer2 from "./admin/components/AdminAddRetailer2";
import AdminEditProduct from "./admin/components/AdminEditProduct";
// import DistributorHeader from "./NewDistributor/Distributorheader";
import DistributorHeader from "./NewDistributor/DistributorHeader";
import DistributorContact from "./NewDistributor/DistributorContact";
import DistributorEmployeeListForm from "./NewDistributor/DistributorEmployeeListForm";
import PrivacyPolicy from "./PrivacyPolicy";
const Routes = () => {
  return (
    <div>
      <Router>
        <Switch>
          {/* Customer */}
          <Route exact path="/">
            <Header2 />
            <Home1 />
            <Footer />
          </Route>
          <Route exact path="/checkout">
            <Header2 />
            <Checkout />
            <Footer />
          </Route>
          <Route exact path="/contact">
            <Header2 />
            <DistributorContact />
            <Footer />
          </Route>
          <Route exact path="/wishlist">
            <Header2 />
            <Wishlist />
            <Footer />
          </Route>
          <Route exact path="/my-shopping">
            <Header2 />
            <MyShopping />
            <Footer />
          </Route>

          <Route exact path="/track-orders">
            <Header2 />
            <TrackOrders />
            <Footer />
          </Route>
          <Route exact path="/wallet">
            <Header2 />
            <MyWallet />
            <Footer />
          </Route>
          <Route exact path="/views-orders">
            <Header2 />
            <ViewsOrder />
            <Footer />
          </Route>
          <Route exact path="/login">
            <Header2 />
            <Login />
            <Footer />
          </Route>
          <Route exact path="/register">
            <Header2 />
            <Register />
            <Footer />
          </Route>
          <Route exact path="/single-pages/:id">
            <Header2 />
            <Productdetails />
            <Footer />
          </Route>
          <Route exact path="/notification">
            <Header2 />
            <Notification />
            <Footer />
          </Route>
          <Route exact path="/products">
            <Header2 />
            <Products />
            <Footer />
          </Route>
          <Route exact path="/become-a-partne-1">
            <Header2 />
            <PattnerForm />
            <Footer />
          </Route>
          <Route exact path="/thank-u">
            <Header2 />
            <Thanku />
            <Footer />
          </Route>
          <Route exact path="/Privacypolicy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/signin">
            <Header2 />
            <Customersignin />
            <Footer />
          </Route>
          <Route exact path="/cinvoice">
            <Cinvoice />
          </Route>
          <Route exact path="/signup">
            <Header2 />
            <Customersignup />
            <Footer />
          </Route>
          <Route exact path="/favourites">
            <Header2 />
            <Favourites />
            <Footer />
          </Route>
          <Route exact path="/retailerofferproduct">
            <Header2 />
            <Retailerofferproduct />
            <Footer />
          </Route>
          <Route exact path="/verify-otp">
            <Header2 />
            <Customerverifyotp />
            <Footer />
          </Route>
          <Route exact path="/search">
            <Search />
            <Footer />
          </Route>
          <Route exact path="/retaileroffer">
            <Header2 />
            <Retaileroffer />
            <Footer />
          </Route>
          <Route exact path="/cart">
            <Header2 />
            <Cart />
            <Footer />
          </Route>
          <Route exact path="/wishlist">
            <Header2 />
            <Wishlist />
            <Footer />
          </Route>
          <Route exact path="/become-a-patner">
            <Header2 />
            <Pattner />
            <Footer />
          </Route>
          <Route exact path="/localshop">
            <Header2 />
            <Localshop />
            <Footer />
          </Route>
          <Route exact path="/localshopproduct">
            <Header2 />
            <Localshopproducts />
            <Footer />
          </Route>
          <Route exact path="/addaddress">
            <Header />
            <Addaddress />
            <Footer />
          </Route>
          <Route exact path="/subscription">
            <Header />
            <Subscription />
            <Footer />
          </Route>
          <Route exact path="/orders">
            <Header />
            <Customerorders />
            <Footer />
          </Route>
          <Route exact path="/ordermap">
            <Header />
            <Customerordermap />
            <Footer />
          </Route>
          <Route exact path="/subscriptionorders">
            <Header />
            <Customersubscriptionorders />
            <Footer />
          </Route>
          <Route exact path="/allcategorys">
            <Header />
            <Allcategorys />
            <Footer />
          </Route>
          <Route exact path="/allsubcategorys">
            <Header />
            <Subcategorys />
            <Footer />
          </Route>
          <Route exact path="/invoice">
            <Customerinvoice />
          </Route>
          <Route exact path="/account">
            <Header />
            <Account />
            <Footer />
          </Route>
          <Route exact path="/terms">
            <Header />
            <Terms />
            <Footer />
          </Route>
          <Route exact path="/policy">
            <Header />
            <Policy />
            <Footer />
          </Route>
          <Route exact path="/deliverypolicy">
            <Header />
            <Paymentpolicy />
            <Footer />
          </Route>
          <Route exact path="/refundpolicy">
            <Header />
            <Refundpolicy />
            <Footer />
          </Route>
          <Route exact path="/profile">
            <Header />
            <Profile />
            <Footer />
          </Route>
          {/* Distributor */}

          {/* JAAGGEREY Desitributor */}
          <Route exact path="/distributor/distributor-home">
            <DistributorHeader />
            <DistributorHome />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-not-approved">
            <DistributorHeader />
            <DistributorNotApproved />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-approved">
            <DistributorHeader />
            <DistributorApproved />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-add-employed">
            <DistributorHeader />
            <DistributorEmployer />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-employed-formlist">
            <DistributorHeader />
            <DistributorEmployeeListForm />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-add-retailer">
            <DistributorHeader />
            <DistributorAddRetailer />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-orders-list">
            <DistributorHeader />
            <DistributorOrderslist />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-sales-history">
            <DistributorHeader />
            <DistributorSalesHistory />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-cash-history">
            <DistributorHeader />
            <DistributorCashHistory />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-accounts-history">
            <DistributorHeader />
            <DistributorAccountsHistory />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-stock">
            <DistributorHeader />
            <DistributorStock />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-purchase-entry">
            <DistributorHeader />
            <DistributorPurchase />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-expense">
            <DistributorHeader />
            <DistributorExpense />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-expensetype">
            <DistributorHeader />
            <DistributorExpenseType />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-orders">
            <DistributorHeader />
            <DistributorOrders />
            <Footer />
          </Route>
          <Route exact path="/distributor/login">
            <DistributorHeader />
            <DistibutorLogin />
            <Footer />
          </Route>
          <Route exact path="/distributor/otp">
            <DistributorHeader />
            <DistributorOtp />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-checkout">
            <DistributorHeader />
            <DistributorCheckout />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-product">
            <DistributorHeader />
            <DistributorProduct />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-product/:id">
            <DistributorHeader />
            <DistributorProductdetails />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-myshopping">
            <DistributorHeader />
            <DistributorMyShopping />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-track-orders">
            <DistributorHeader />
            <DistributorTrackOrders />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-my-wallet">
            <DistributorHeader />
            <DistributorMyWallet />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-cart">
            <DistributorHeader />
            <DistributorCart />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-whislist">
            <DistributorHeader />
            <DistributorWishlist />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-employer">
            <DistributorHeader />
            <DistributorEmployer />
            <Footer />
          </Route>
          <Route exact path="/distributor/distributor-contact">
            <DistributorHeader />
            <DistributorContact />
            <Footer />
          </Route>

          {/* JAAGGEREY Desitributor END */}

          {/* Retailer */}

          <Route exact path="/retailer">
            <Retailerheader />
            <Retailerhome />
            <Retailerfooter />
          </Route>
          <Route exact path="/retaileraccount">
            <Retailerheader />
            <Retaileraccount />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/profile">
            <Retailerheader />
            <Retailerprofile />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/rinvoice">
            <Rinvoice />
          </Route>
          <Route exact path="/retailer/localshop">
            <Retailerheader />
            <Retailerlocalshop />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/productdetails">
            <Retailerheader />
            <Retailerproductdetails />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/allcategory">
            <Retailerheader />
            <Retailerallcategory />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/products">
            <Retailerheader />
            <Retailerproducts />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/search">
            <Retailersearch />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/addaddress">
            <Retailerheader />
            <Retaileraddaddress />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/notification">
            <Retailerheader />
            <Retailernotification />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/distributoroffer">
            <Retailerheader />
            <Distributoroffer />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/distributorofferproduct">
            <Retailerheader />
            <Distributorofferproduct />
            <Retailerfooter />
          </Route>

          <Route exact path="/retailer/favourite">
            <Retailerheader />
            <Retailerfavourite />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/signin">
            <Retailerheader />
            <Retailersignin />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/terms">
            <Retailerheader />
            <Retailerterms />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/policy">
            <Retailerheader />
            <Retailerpolicy />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/retailerordermap">
            <Retailerheader />
            <Retailerordermap />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/signup">
            <Retailerheader />
            <Retailersignup />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/verify-otp">
            <Retailerheader />
            <Retailerverifyotp />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/cart">
            <Retailerheader />
            <Retailercart />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/addproducts">
            <Retailerheader />
            <Retaileraddproduct />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/myproducts">
            <Retailerheader />
            <Retaileraddedproduct />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/allsubcategorys">
            <Retailerheader />
            <Retailersubcategorys />
            <Retailerfooter />
          </Route>

          <Route exact path="/retailer/customerorder">
            <Retailerheader />
            <Retailercustomerorder />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/orderview">
            <Retailerheader />
            <Retailerorderview />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/retailercustomerinvoice">
            {/* <Retailerheader /> */}
            <Retailercustomerinvoice />
            {/* <Retailerfooter /> */}
          </Route>
          <Route exact path="/retailer/retailerlocalshopproduct">
            <Retailerheader />
            <Retailerlocalshopproduct />
            <Retailerfooter />
          </Route>
          <Route exact path="/retailer/retailermyorders">
            <Retailerheader />
            <Retailermyorders />
            <Retailerfooter />
          </Route>

          {/*vendor */}
          <Route exact path="/vendor">
            {/* <Vendorheader /> */}
            <Vendorsignin />
            {/* <Vendorfooter /> */}
          </Route>
          <Route exact path="/vendor/signup">
            {/* <Vendorheader /> */}
            <Vendorsignup />
            {/* <Vendorfooter /> */}
          </Route>
          <Route exact path="/vendor/verify-otp">
            {/* <Vendorheader /> */}
            <Vendorverifyotp />
            {/* <Vendorfooter /> */}
          </Route>
          <Route exact path="/vendor/dashboard">
            <Vendorlayout childern={<Vendorproduct />} />
          </Route>
          <Route exact path="/vendor/products">
            <Vendorlayout childern={<Vendorproduct />} />
          </Route>
          <Route exact path="/vendor/addproducts">
            <Vendorlayout childern={<Vendoraddproduct />} />
          </Route>

          {/* admin */}

          <Route exact path="/admin">
            <AdminLogin />
          </Route>
          <Route exact path="/admin/dashboard">
            <Adminlayout childern={<Admindashboard />} />
          </Route>
          <Route exact path="/admin/userdata">
            <Adminlayout childern={<Adminuserdata />} />
          </Route>
          <Route exact path="/admin/category">
            <Adminlayout childern={<Category />} />
          </Route>
          <Route exact path="/admin/subcategory">
            <Adminlayout childern={<Subcategory />} />
          </Route>
          <Route exact path="/admin/products">
            <Adminlayout childern={<Adminproducts />} />
          </Route>
          <Route exact path="/admin/editproject">
            <Adminlayout childern={<AdminEditProduct />} />
          </Route>
          <Route exact path="/admin/addproducts">
            <Adminlayout childern={<Adminaddproduct />} />
          </Route>
          <Route exact path="/admin/pincode">
            <Adminlayout childern={<Adminpincode />} />
          </Route>
          <Route exact path="/admin/customerslider">
            <Adminlayout childern={<Customersliders />} />
          </Route>
          <Route exact path="/admin/distributorslider">
            <Adminlayout childern={<Distributorsliders />} />
          </Route>
          <Route exact path="/admin/retailerslider">
            <Adminlayout childern={<Retailersliders />} />
          </Route>
          <Route exact path="/admin/retailerbanner">
            <Adminlayout childern={<Retailerbanner />} />
          </Route>
          <Route exact path="/admin/distributorbanner">
            <Adminlayout childern={<Distributorbanner />} />
          </Route>
          <Route exact path="/admin/customerbanner">
            <Adminlayout childern={<Customerbanner />} />
          </Route>
          <Route exact path="/admin/customerdailyessentials">
            <Adminlayout childern={<Customerdailyessientials />} />
          </Route>
          <Route exact path="/admin/distributordailyessentials">
            <Adminlayout childern={<Distributordailyessientials />} />
          </Route>
          <Route exact path="/admin/Retailerdailyessentials">
            <Adminlayout childern={<Retailerdailyessientials />} />
          </Route>
          <Route exact path="/admin/customer">
            <Adminlayout childern={<Admincustomer />} />
          </Route>
          <Route exact path="/admin/notapproveddistributor">
            <Adminlayout childern={<Admindistributor />} />
          </Route>
          <Route exact path="/admin/approveddistributor">
            <Adminlayout childern={<Adminapproveddistributor />} />
          </Route>

          <Route exact path="/admin/rreturnorder">
            <Adminlayout childern={<Adminretailerreturnorder />} />
          </Route>
          <Route exact path="/admin/dreturnorder">
            <Adminlayout childern={<Admindistributorreturnorder />} />
          </Route>

          <Route exact path="/admin/distributorreturnorderview">
            <Adminlayout childern={<Admindreturnorderview />} />
          </Route>
          <Route exact path="/admin/retailerreturnorderview">
            <Adminlayout childern={<Adminrreturnorderview />} />
          </Route>

          <Route exact path="/admin/notapprovedretailer">
            <Adminlayout childern={<Adminretailer />} />
          </Route>
          <Route exact path="/admin/approvedretailer">
            <Adminlayout childern={<Adminapprovedretailer />} />
          </Route>
          <Route exact path="/admin/notapprovedkeyaccount">
            <Adminlayout childern={<Adminkeyaccount />} />
          </Route>
          <Route exact path="/admin/distributororderview">
            <Adminlayout childern={<Admindistributororderview />} />
          </Route>
          <Route exact path="/admin/approvedkeyaccount">
            <Adminlayout childern={<Adminapprovedkeyaccount />} />
          </Route>
          <Route exact path="/admin/retailerorderview">
            <Adminlayout childern={<Adminretailerorderview />} />
          </Route>
          <Route exact path="/admin/notapprovedcorporate">
            <Adminlayout childern={<Admincorporate />} />
          </Route>
          <Route exact path="/admin/approvedcorporate">
            <Adminlayout childern={<Adminapprovedcorporate />} />
          </Route>
          <Route exact path="/admin/notapprovedemployee">
            <Adminlayout childern={<Adminemployee />} />
          </Route>
          <Route exact path="/admin/approvedemployee">
            <Adminlayout childern={<Adminapprovedemployee />} />
          </Route>
          <Route exact path="/admin/notapprovedtransporter">
            <Adminlayout childern={<Admintransporter />} />
          </Route>
          <Route exact path="/admin/approvedtransporter">
            <Adminlayout childern={<Adminapprovedtransporter />} />
          </Route>
          <Route exact path="/admin/notapprovedvendor">
            <Adminlayout childern={<Adminvendor />} />
          </Route>
          <Route exact path="/admin/approvedvendor">
            <Adminlayout childern={<Adminapprovedvendor />} />
          </Route>
          <Route exact path="/admin/notapprovedme">
            <Adminlayout childern={<Adminme />} />
          </Route>
          <Route exact path="/admin/approvedme">
            <Adminlayout childern={<Adminapprovedme />} />
          </Route>
          <Route exact path="/admin/notapprovedde">
            <Adminlayout childern={<Adminde />} />
          </Route>
          <Route exact path="/admin/approvedde">
            <Adminlayout childern={<Adminapprovedde />} />
          </Route>
          <Route exact path="/admin/adproductsview">
            <Adminlayout childern={<Admindistributorproduct />} />
          </Route>
          <Route exact path="/admin/arproductsview">
            <Adminlayout childern={<Adminretailerproduct />} />
          </Route>
          <Route exact path="/admin/expensetype">
            <Adminlayout childern={<Adminexpensetype />} />
          </Route>
          <Route exact path="/admin/expense">
            <Adminlayout childern={<Adminexpense />} />
          </Route>
          <Route exact path="/admin/customerorders">
            <Adminlayout childern={<Admincustomerorders />} />
          </Route>
          <Route exact path="/admin/distributororders">
            <Adminlayout childern={<Admindistributororders />} />
          </Route>
          <Route exact path="/admin/retailerorders">
            <Adminlayout childern={<Adminretailerorders />} />
          </Route>
          <Route exact path="/admin/adminnotification">
            <Adminlayout childern={<Adminnotification />} />
          </Route>
          <Route exact path="/admin/corporateorders">
            <Adminlayout childern={<Admincorporateorders />} />
          </Route>
          <Route exact path="/admin/employeeorders">
            <Adminlayout childern={<Adminemployeeorders />} />
          </Route>
          <Route exact path="/admin/keyaccountorders">
            <Adminlayout childern={<Adminkeyaccountorders />} />
          </Route>
          <Route exact path="/admin/promocode">
            <Adminlayout childern={<Adminpromocode />} />
          </Route>
          <Route exact path="/admin/package">
            <Adminlayout childern={<Adminpackage />} />
          </Route>
          <Route exact path="/admin/referalcode">
            <Adminlayout childern={<Adminreferalcode />} />
          </Route>
          <Route exact path="/admin/creditdebitnote">
            <Adminlayout childern={<Creditdebitnote />} />
          </Route>
          <Route exact path="/admin/deliverycharge">
            <Adminlayout childern={<Admindeliverycharge />} />
          </Route>
          <Route exact path="/admin/deliveryslot">
            <Adminlayout childern={<Admindeliveryslot />} />
          </Route>
          <Route exact path="/admin/pickslot">
            <Adminlayout childern={<Adminpickslot />} />
          </Route>
          <Route exact path="/admin/localshoporders">
            <Adminlayout childern={<Adminlocalshoporders />} />
          </Route>
          <Route exact path="/admin/returnorders">
            <Adminlayout childern={<Adminreturnorders />} />
          </Route>
          <Route exact path="/admin/purchaseorders">
            <Adminlayout childern={<Adminpurchaseorder />} />
          </Route>
          <Route exact path="/admin/distributorretailerorders">
            <Adminlayout childern={<Admindistributorretailerorders />} />
          </Route>
          <Route exact path="/admin/pincodeblock">
            <Adminlayout childern={<Adminpincodeblock />} />
          </Route>
          <Route exact path="/admin/retailerinvoice">
            <Adminlayout childern={<Adminretailerinvoice />} />
          </Route>
          <Route exact path="/admin/distributorinvoice">
            <Adminlayout childern={<Admindistributorinvoice />} />
          </Route>
          <Route exact path="/admin/orderview">
            <Adminlayout childern={<Adminorderview />} />
          </Route>
          <Route exact path="/admin/returnorderview">
            <Adminlayout childern={<Returnorderview />} />
          </Route>
          <Route exact path="/admin/bulkinvoice">
            <Adminlayout childern={<Adminbulkinvoice />} />
          </Route>
          <Route exact path="/admin/subadmin">
            <Adminlayout childern={<Adminsubadmin />} />
          </Route>
          <Route exact path="/admin/addsubadmin">
            <Adminlayout childern={<Addsubadmin />} />
          </Route>
          <Route exact path="/admin/accountstatement">
            <Adminlayout childern={<Adminaccountstatement />} />
          </Route>
          <Route exact path="/admin/customerinvoice">
            <Admincustomerinvoice />
          </Route>
          <Route exact path="/admin/topretailer">
            <Adminlayout childern={<Topretailer />} />
          </Route>

          <Route exact path="/admin/topdistributor">
            <Adminlayout childern={<Topdistributor />} />
          </Route>
          <Route exact path="/admin/voucher">
            <Adminlayout childern={<Voucher />} />
          </Route>
          <Route exact path="/admin/transaction">
            <Adminlayout childern={<Transaction />} />
          </Route>
          <Route exact path="/admin/partner-request">
            <Adminlayout childern={<PartnerRequest />} />
          </Route>
          <Route exact path="/admin/feedback">
            <Adminlayout childern={<Feedback />} />
          </Route>
          <Route exact path="/admin/notapprovedretailer1">
            <Adminlayout childern={<NotapprovedRetailer />} />
          </Route>
          <Route exact path="/admin/addretailer1">
            <Adminlayout childern={<AdminAddRetailer />} />
          </Route>
          <Route exact path="/admin/addretailer2">
            <Adminlayout childern={<AdminAddRetailer />} />
          </Route>
          <Route exact path="/admin/employeelistform">
            <Adminlayout childern={<EmployeeListForm />} />
          </Route>
          <Route exact path="/admin/sales-history">
            <Adminlayout childern={<DistributorSalesHistory />} />
          </Route>
          <Route exact path="/admin/cash-history">
            <Adminlayout childern={<DistributorCashHistory />} />
          </Route>
          <Route exact path="/admin/account-history">
            <Adminlayout childern={<DistributorAccountsHistory />} />
          </Route>
          <Route exact path="/admin/stock">
            <Adminlayout childern={<Stock />} />
          </Route>
          <Route exact path="/admin/stockdashboard">
            <Adminlayout childern={<StockDashboard />} />
          </Route>
          <Route exact path="/admin/addvolume">
            <Adminlayout childern={<ProductVolume />} />
          </Route>
          <Route exact path="/admin/addhsncode">
            <Adminlayout childern={<HsnCode />} />
          </Route>
          <Route exact path="/admin/wallet">
            <Adminlayout childern={<AdminWallet />} />
          </Route>
          <Route exact path="/admin/walletcustomer">
            <Adminlayout childern={<AdminWalletCustomer />} />
          </Route>
          <Route exact path="/admin/walletdistributor">
            <Adminlayout childern={<AdminWalletDistributor />} />
          </Route>
          <Route exact path="/admin/walletretailer">
            <Adminlayout childern={<AdminWalletRetailer />} />
          </Route>
          <Route exact path="*">
            <Header2 />
            <FourNotFour />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
